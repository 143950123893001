
import React, { useCallback, useEffect, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import img from 'assets/main-images/cus.png'
import img2 from 'assets/main-images/cus1.png'
import { ErrorAlert, Errors, ScrollChatBottom, siteName } from 'components/utils/functions'
import { Apis, Authposturl, Posturl, SocketConnect, geturl, imgurl } from 'components/Apis'
import ChatComponent from './ChatComponent'
import { FaArrowLeft } from 'react-icons/fa6'
import { BiSolidUser } from 'react-icons/bi'
import Imaged from 'components/utils/Imaged'


const ChatModal = ({ user, staffs }) => {
    const [screen, setScreen] = useState(1)
    const [views, setViews] = useState(false)
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})
    const [singleStaff, setSingleStaff] = useState({})
    const [typing, setTyping] = useState(false)

    const GetChats = useCallback(async (tag, item) => {
        if (item.aval === 'no') return ErrorAlert('Cannot open a channel while staff is not available to respond.')
        setSingleStaff(item)
        setLoading(true)
        try {
            const response = await geturl(`${Apis.feeds.active_chat}/${user.email}`)
            ScrollChatBottom()
            if (response.status === 200) {
                setData(response.msg)
                if (tag === 'new') {
                    setViews(true)
                }
                if (response.msg !== null) {
                    const roomdata = { room: `room-${response.msg.id}` }
                    SocketConnect.emit('join-room', roomdata)
                    if (tag === 'new') return setScreen(2)
                } else {
                    setScreen(1)
                }
            }
        } catch (error) {
            Errors(`${error.message}`)
        } finally {
            setLoading(false)
        }
    }, [data])

    const StartChating = async () => {
        const forms = {
            name: user.firstname,
            email: user.email,
            phone: user.phone,
            avalAgent: singleStaff.id
        }
        const response = await Posturl(Apis.feeds.start_chat, forms)
        ScrollChatBottom()
        if (response.status === 200) {
            setData(response.msg)
            const roomdata = { room: `room-${response.msg.id}` }
            SocketConnect.emit('join-room', roomdata)
            // send a response to the other leting them know if the connection to a room is a new connection or an existing connection
            if (response.msg?.messages?.length > 0) {
                SocketConnect.emit('connect-room', { status: 'old' })
            } else {
                SocketConnect.emit('connect-room', { status: 'new' })
            }
            return setScreen(2)
        }
    }

    const sendSignal = async () => {
        const response = await geturl(`${Apis.feeds.active_chat}/${user.email}`)
        if (response.status === 200) {
            setData(response.msg)
        }
        setScreen(2)
    }

    useEffect(() => {
        SocketConnect.on('user-currently-typing', () => setTyping(true))
        SocketConnect.on('user-currently-not-typing', () => setTyping(false))
    }, [SocketConnect])

    return (
        <>
            <button
                onClick={() => { setScreen(0); setViews(true) }}
                className="bg-main py-2 px-5 rounded-md text-white text-sm capitalize">live chat</button>
            {views && <div className='fixed top-0 left-0 w-full h-screen z-30 bg-black/40 lg:pr-3'>
                <div className="w-full lg:w-[30rem] bg-white foil h-screen lg:h-[85dvh] lg:ml-auto lg:mt-[5.5rem] lg:rounded-lg">
                    <div className="bg-main p-3 lg:rounded-tr-lg lg:rounded-tl-lg">
                        <div className="flex items-center justify-between">
                            <div className="">
                                {screen !== 0 && <div className='flex items-center gap-5'>
                                    <button
                                        onClick={() => setScreen(prev => prev - 1)}
                                        className='text-2xl text-white'> <FaArrowLeft /> </button>
                                    <div className="flex gap-2">
                                        <img src={singleStaff.image ? `${imgurl}/profile/${singleStaff.image}` : img2} alt="" className="w-10 h-10 bg-white rounded-full object-cover" />
                                        <div className="">
                                            <div className="text-white text-sm font-bold">{singleStaff.firstname}</div>
                                            <div className="text-green-300 text-xs font-extrathin">{typing ? 'typing....' : 'Available'}</div>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                            <div className="flex items-center gap-8">
                                <button
                                    title="Close Chat Window"
                                    onClick={() => setViews(prev => !prev)}
                                    className='p-2 rounded-full bg-white'> <FaTimes /> </button>
                            </div>
                        </div>
                    </div>
                    {screen === 0 && <div className='px-3 mt-5'>
                        <div className="mb-4">
                            <div className="font-bold">Start a conversation</div>
                            <div className="h-5 rounded mb-1 w-4/5 bg-slate-200"></div>
                            <div className="h-5 rounded w-2/5 bg-slate-200"></div>
                        </div>
                        {staffs.length > 0 ? staffs.map((item, index) => (
                            <div
                                onClick={() => GetChats('new', item)}
                                className={`p-2 grid grid-cols-8 cursor-pointer shadow-xl mb-2 transition-all border-l-4 ${item.aval === 'yes' ? 'border-green-500' : 'border-slate-500'}`} key={index}>
                                <div className="flex gap-2 col-span-5">
                                    {item.image ? <Imaged src={`${imgurl}/profile/${item.image}`} className="w-14 h-14 rounded-full object-cover" /> : <div className="text-2xl rounded-full p-2.5 bg-slate-200 text-slate-500 w-fit h-fit"> <BiSolidUser /></div>}
                                    <div className="text-sm">
                                        <div className="font-bold">{item.firstname} {item.lastname}</div>
                                        <div className="text-sm text-zinc-500">{item.staff?.name}</div>
                                        {item.aval === 'no' && <div className="text-sm text-sub">{item.info}</div>}
                                    </div>
                                </div>
                                <div className="col-span-3 flex flex-col gap-2">
                                    <div className="text-right text-xs"> {item.lastlogin}</div>
                                    <div className={`text-right text-sm capitalize ${item.aval === 'yes' ? 'text-green-500' : 'text-slate-500'}`}> {item.aval === 'yes' ? 'Available' : 'Not available'} </div>
                                </div>
                            </div>
                        )) : <div className="text-center mt-10">No Staff has been assigned to a department yet!...</div>}
                    </div>}
                    {screen === 1 && <>
                        <div className="flex items-center justify-center w-full h-[83dvh] lg:h-[69.8dvh] flex-col gap-10 relative">
                            <div className="absolute bottom-0 left-0 w-full text-center text-slate-500">Powered by {siteName} Chat </div>
                            <Imaged src={img} alt="" className="w-auto h-44 -mt-20" />
                            <div className="flex items-center gap-3">
                                <div className="">{data?.messages?.length > 0 ? data?.active === 'yes' && 'Chat In Progress' : 'Have a complaint?'}</div>
                                <button
                                    onClick={StartChating}
                                    className="bg-main py-3 px-5 rounded-lg text-white text-xs capitalize">{data?.messages?.length > 0 ? data?.active === 'yes' && 'Continue Chat' : 'start chat'}</button>
                            </div>
                        </div>
                    </>}
                    {screen === 2 && <ChatComponent
                        data={data}
                        email={data?.email}
                        usertype="user"
                        sendSignal={sendSignal}
                    />}
                </div>
            </div >}
        </>
    )
}

export default ChatModal