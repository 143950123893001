
import React from 'react'

const ShortletFormNavs = ({screen}) => {
  return (
    <div>
        
        <ol className="flex items-center w-full p-3 space-x-2 text-sm font-medium text-center text-gray-500 bg-white rounded-lg sm:text-base sm:p-4 sm:space-x-4 rtl:space-x-reverse">
                <li className={`flex items-center ${[1, 2, 3].includes(screen) ? ' text-blue-600 dark:text-blue-500' : ''}`}>
                    <span className={`flex items-center justify-center w-5 h-5 me-2 text-xs border ${[1, 2, 3].includes(screen) ? 'border-blue-600' : 'border-gray-500'} rounded-full shrink-0`}>
                        1
                    </span>
                    Personal <span className="hidden sm:inline-flex sm:ms-2">Info</span>
                    <svg className="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m7 9 4-4-4-4M1 9l4-4-4-4" />
                    </svg>
                </li>
                <li className={`flex items-center ${[2, 3].includes(screen) ? ' text-blue-600 dark:text-blue-500' : ''}`}>
                <span className={`flex items-center justify-center w-5 h-5 me-2 text-xs border ${[2, 3].includes(screen) ? 'border-blue-600' : 'border-gray-500'} rounded-full shrink-0`}>
                        2
                    </span>
                    Pickup <span className="hidden sm:inline-flex sm:ms-2">Info</span>
                    <svg className="w-3 h-3 ms-2 sm:ms-4 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m7 9 4-4-4-4M1 9l4-4-4-4" />
                    </svg>
                </li>
                <li className={`flex items-center ${[3].includes(screen) ? ' text-blue-600 dark:text-blue-500' : ''}`}>
                <span className={`flex items-center justify-center w-5 h-5 me-2 text-xs border ${[3].includes(screen) ? 'border-blue-600' : 'border-gray-500'} rounded-full shrink-0`}>
                        3
                    </span>
                    Review
                </li>
            </ol>

    </div>
  )
}

export default ShortletFormNavs