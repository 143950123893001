import React, { useState } from 'react'
import ALayout from 'components/admin/ALayout'
import { useQuery } from '@tanstack/react-query'
import { Apis, Authgeturl } from 'components/Apis'
import { FaArrowTrendUp } from 'react-icons/fa6'
import { VictoryBar, VictoryChart, VictoryTooltip } from 'victory'
import { BsDot } from 'react-icons/bs'
import Forminput from 'components/utils/Forminput'


const Dates = [
  "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032", "2033"
]

export default function AHome() {
  const [selectDate, setSelectDate] = useState(Dates[0])
  const { data, isLoading } = useQuery({
    queryKey: ['admin-dashboard'],
    queryFn: async () => {
      const response = await Authgeturl(Apis.user.admin_dashboard)
      return {
        records: response.msg,
        investments: response.investments,
        servicetransactions: response.servicetransactions
      }
    }
  })

  if (isLoading) {
    return (
      <ALayout>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-3">
          {new Array(12).fill(0).map((item, index) => (
            <div key={index} className='bg-zinc-300 animate-pulse rounded-lg w-full h-44'></div>
          ))}
        </div>
      </ALayout>
    )
  }


  return (
    <ALayout>
      <div className="grid grid-cols-1 gap-5 lg:grid-cols-2">
        <div className="">
          <div className="grid grid-cols-2 gap-5">
            {data.records.map((item, index) => (
              <div className="bg-white p-3 rounded-lg border" key={index}>
                <div className="flex items-center justify-between gap-2">
                  <div className="text-sm text-slate-500 capitalize">{item.tag}</div>
                  <FaArrowTrendUp className="text-gray-400" />
                </div>
                <div className="text-xl text-gray-700 font-bold">{item.total < 10 ? `0${item.total}` : item.total}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="bg-white p-4 h-fit">
          <div className="grid grid-cols-2">
            <div className="font-bold text-2xl">Investments</div>
            <Forminput formtype='select' onChange={e => setSelectDate(e.target.value)} value={selectDate}>
              {Dates.map((item, index) => (
                <option key={index} value={item}>{item}</option>
              ))}
            </Forminput>
          </div>
          <div className="text-sm flex items-center text-slate-500 gap-2">
            <div className="">Istock</div>
            <BsDot />
            <div className="">Midas</div>
            <BsDot />
            <div className="">Prime</div>
          </div>

          <div className="">
            <VictoryChart
              domainPadding={20}
              minDomain={{ x: 2 }}
            >
              <VictoryBar
                barRatio={0.8}
                alignment="start"
                style={{ data: { fill: "#c43a31" } }}
                data={data.investments}
              />
            </VictoryChart>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-5 gap-5">
        <div className="bg-white p-4 my-5 lg:col-span-3">
          <div className="font-bold text-2xl">Service Transactions</div>
          <div className="flex items-center gap-2">
            <div className="text-sm text-slate-500">Airtime</div>
            <BsDot className='text-xl' />
            <div className="text-sm text-slate-500">Data</div>
          </div>
          <VictoryChart
            domainPadding={10}
          >
            <VictoryBar
              labelComponent={<VictoryTooltip />}
              style={{ data: { fill: "#c43a31" } }}
              data={data.servicetransactions}
            />
          </VictoryChart>
        </div>
        <div className="lg:col-span-2"></div>
      </div>
      {/* <div className="">
        <div className="mb-10 font-bold text-3xl">Admin Dashboard</div>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-3">
          {data?.map((item, index) => {
            const icon = AdminDashboard.find(ele => ele.tag === item.tag)
            if (icon) return (
              <div className="bg-white rounded-lg shadow-2xl" key={index}>
                <div className={`${icon.bg} p-3 text-5xl flex justify-center rounded-tl-lg text-white rounded-tr-lg`}>
                  <icon.Icon />
                </div>
                <div className="p-3">
                  <div className="text-right capitalize text-sm">{icon.title}</div>
                  <div className="text-right font-bold text-3xl">{item.total}</div>
                  <div className="w-fit ml-auto">
                    <Link to={`${icon.url}`} className='flex items-center gap-2 text-sm text-blue-500'>view more <FaArrowRight /> </Link>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div> */}
    </ALayout>
  )
}
