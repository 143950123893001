import React, { useState } from 'react'
import Forminput from 'components/utils/Forminput'
import { ErrorAlert, SuccessAlert } from 'components/utils/functions'
import { SlPencil, SlTrash } from 'react-icons/sl'

export default function ShortletAddons({contents, setContents}) {
    const [content, setContent] = useState('')
    const [single, setSingle] = useState({})

    const handleSubmit = () => {
        if (!content) return ErrorAlert('content is required')
        const item = contents.find(ele => ele.content === content)
        if (!single?.content) {
            if (item) return ErrorAlert('Shortlet inclusion with content already exists')
            setContents([...contents, {id: '', content: content}])
            setContent('')
        } else {
            const updates = contents.map((ele) => {
                if(ele.content === single.content) {
                    return {
                       ...ele,
                       content
                    }
                }
                return ele
            })
            setContents(updates)
            setSingle({})
            setContent('')
        }
    }
    const DeleteContent = (content) => {
        const item = contents.find(ele => ele.content === content)
        if (item) {
            const filtered = contents.filter(ele => ele.content !== content)
            setContents(filtered)
            SuccessAlert('Inclusion deleted...')
        }
        setSingle({})
    }

    const UpdateContent = (item) => {
        setSingle(item)
        setContent(item.content)
    }

    return (
        <div>
            <div className="">
                <div className="">Shortlet Inclusions</div>
                <Forminput
                    formtype='textarea'
                    formName="content"
                    value={content}
                    onChange={e => setContent(e.target.value)} />
            </div>
            <div className="w-fit ml-auto">
                <button
                    onClick={handleSubmit}
                    type="button"
                    className="bg-main text-white py-3 px-5 rounded-lg text-sm capitalize">{single?.content ? 'Update content' : 'add content'}</button>
            </div>
            <div className="">
            <div className="border-b">Summary on Inclusions</div>
                            <div className="">
                                {contents.length > 0 && contents.map((ele, index) => (
                                    <div className="p-2 border rounded-lg mb-1" key={index}>
                                        <div className="grid grid-cols-6">
                                            <div className="col-span-4">
                                                <div className="font-bold text-sm truncate">{ele.content}</div>
                                            </div>
                                            <div className="col-span-2">
                                                <div className="flex items-center gap-5 justify-end h-full">
                                                    <button
                                                        onClick={() => UpdateContent(ele)}
                                                        className="rounded-full p-2 border" type="button"> <SlPencil /> </button>
                                                    <button
                                                        onClick={() => DeleteContent(ele.content)}
                                                        className="rounded-full p-2 border" type="button"> <SlTrash /> </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
            </div>
        </div>
    )
}


