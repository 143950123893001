import React from 'react'
import ModalLayout from 'components/utils/ModalLayout'
import StockDeposit from './StockDeposit'
// import StockWithdrawal from './StockWithdrawal'
import WithdrawOrder from './WithdrawOrder'

const FundsModal = ({ closeView, tag }) => {
    if(tag === 'withdraw') return <WithdrawOrder closeView={closeView} />
    return (
        <ModalLayout closeView={closeView}>
            <div className="pt-10">
            {tag === 'deposit' ? <StockDeposit handleScreen={closeView} /> : null}
            {/* {tag === 'withdraw' ? <StockWithdrawal handleScreen={closeView} /> : null} */}
            </div>
        </ModalLayout>
    )
}

export default FundsModal