import React from 'react'
import ModalLayout from './ModalLayout'
import Formbutton from './Formbutton'

const ConfirmationModal = ({ closeView, title, mainbg, handleSubmission, loading }) => {
    return (
        <ModalLayout closeView={closeView}>
            <div className="text-center p-3 mt-10 w-10/12 mx-auto">{title}</div>
            <div className="grid grid-cols-2 gap-8 w-10/12 mx-auto pb-5 pt-3">
                <button onClick={closeView} className="bg-slate-200 py-3 px-4 rounded-lg capitalize">cancel</button>
                <Formbutton handleSubmission={handleSubmission} title='proceed' bg={mainbg} loading={loading} />
            </div>
        </ModalLayout>
    )
}

export default ConfirmationModal