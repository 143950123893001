import { useState } from "react";
import ModalLayout from "components/utils/ModalLayout";
import Forminput from "components/utils/Forminput";
import { ErrorAlert, SuccessAlert } from "components/utils/functions";
import Formbutton from "components/utils/Formbutton";
import { Apis, Authposturl, Authputurl } from "components/Apis";
import { SlPencil, SlTrash } from "react-icons/sl";


export default function SharesAssetModal({ closeView, pagedata, refetchData }) {
    const [loading, setLoading] = useState(false)
    const [forms, setForms] = useState({
        pricing: pagedata?.pricing || '',
        total: pagedata?.total || '',
        authorized: pagedata?.authorized || '',
        title: pagedata?.title || '',
        min: pagedata?.min || '',
        max: pagedata?.max || '',
        authorizedText: pagedata?.authorizedText || '',
        totalText: pagedata?.totalText || '',
        shorts: pagedata?.shorts?.toUpperCase() || '',
    })
    const [views, setViews] = useState(1)
    const [contents, setContents] = useState(pagedata?.sharecontents || [])
    const [contentText, setContentText] = useState('')
    const [singleContent, setSingleContent] = useState({})

    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }

    const SubmitForm = async (e) => {
        e.preventDefault()
        if (!forms.title) return ErrorAlert('Asset title is required')
        if (!forms.shorts) return ErrorAlert('Asset Short title is required')
        if (!forms.pricing) return ErrorAlert('Asset pricing per each shares is required')
        if (!forms.total) return ErrorAlert('total Asset in treasury is required')
        if (!forms.totalText) return ErrorAlert('total Asset in treasury in words is required')
        if (!forms.min) return ErrorAlert('Minimum shares purchase is required')
        if (!forms.max) return ErrorAlert('Maximum shares purchase is required')
        if (!forms.authorized) return ErrorAlert('Asset authorized to share is required')
        if (!forms.authorizedText) return ErrorAlert('Asset authorized to share in words is required')
        setViews(prev => prev + 1)
    }

    const handleFinalSubmission = async e => {
        e.preventDefault()
        if (contents.length < 1) return ErrorAlert('Provide valid contents to display to shareholders on purchasing shares')
        setLoading(true)
        let tag = {}
        if (!pagedata?.id) { tag = { tag: 'create' } } else { tag = { tag: 'update' } }
        const formdata = {
            ...forms,
            shareid: pagedata?.id ? pagedata.id : '',
            contents,
            ...tag
        }
        try {
            const response = await Authposturl(Apis.shares.manage_share_asset, formdata)
            if (response.status === 200) {
                SuccessAlert(response.msg)
                refetchData()
                closeView()
            } else {
                ErrorAlert(`${response.msg}`)
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoading(false)
        }
    }

    const AddContent = () => {
        if (!contentText) return ErrorAlert('Provide a valid content to display')
        if (!singleContent?.content) {
            const findContent = contents.find(ele => ele.content === contentText)
            if (findContent) return ErrorAlert(`Content already added`)
            const dataSet = {
                content: contentText,
                id: ''
            }
            setContents([dataSet, ...contents])
            setContentText('')
            SuccessAlert('Content Added')
        } else {
            //update the content
            const findContent = contents.find(ele => ele.content === singleContent.content)
            if (!findContent) return ErrorAlert('Content not found')
            const newContents = contents.map(ele => {
                if (ele.content === singleContent.content) {
                    return { ...ele, content: contentText }
                }
                return ele
            })
            setContents(newContents)
            setContentText('')
            SuccessAlert('Content Updated')
            setSingleContent({})
        }
    }

    const UpdateContents = (item) => {
        setContentText(item.content)
        setSingleContent(item)
    }

    return (
        <ModalLayout closeView={closeView}>
            <div className="p-6">
                {views === 1 && <form onSubmit={SubmitForm}>
                    <div className="">
                        <div className="">Asset Title</div>
                        <Forminput type="text" formName="title" value={forms.title} onChange={handleForms} />
                    </div>
                    <div className="">
                        <div className="">Asset title short name</div>
                        <Forminput type="text" formName="shorts" value={forms.shorts} onChange={handleForms} />
                    </div>
                    <div className="">
                        <div className="">Base Pricing per shares</div>
                        <Forminput type="number" formName="pricing" value={forms.pricing} onChange={handleForms} />
                    </div>
                    <div className="">
                        <div className="">Total Assets</div>
                        <Forminput type="number" formName="total" value={forms.total} onChange={handleForms} />
                    </div>
                    <div className="">
                        <div className="">Total Assets in words</div>
                        <Forminput type="text" formName="totalText" value={forms.totalText} onChange={handleForms} />
                    </div>
                    <div className="">
                        <div className="">Authorized Assets to Sell out</div>
                        <Forminput type="number" formName="authorized" value={forms.authorized} onChange={handleForms} />
                    </div>
                    <div className="">
                        <div className="">Authorized Assets to Sell out in words</div>
                        <Forminput type="text" formName="authorizedText" value={forms.authorizedText} onChange={handleForms} />
                    </div>
                    <div className="">
                        <div className="">Minimum Purchase</div>
                        <Forminput type="number" formName="min" value={forms.min} onChange={handleForms} />
                    </div>
                    <div className="">
                        <div className="">Maximum Purchase</div>
                        <Forminput type="number" formName="max" value={forms.max} onChange={handleForms} />
                    </div>
                    <div className="mt-10 ml-auto w-fit">
                        <Formbutton title="Continue" loading={loading} />
                    </div>
                </form>}
                {views === 2 && <form onSubmit={handleFinalSubmission}>
                    <div className="">
                        <div className="">Provide contents to be displayed on the shares page to visitors</div>
                        <div className="flex items-center gap-5">
                            <div className="w-11/12">
                                <Forminput formName="contentText" value={contentText} onChange={e => setContentText(e.target.value)} formtype="textarea" />
                            </div>
                            <button
                                onClick={AddContent}
                                type="button" className={`${singleContent.content ? 'bg-sub' : 'bg-main'} text-white text-xs py-2.5 px-4 rounded-lg capitalize`}>{singleContent.content ? 'Update' : 'Add'}</button>
                        </div>
                    </div>

                    <div className="">
                        {contents.map((item, index) => (
                            <div className="flex items-center justify-between p-2 border rounded-lg mb-1" key={index}>
                                <div className="truncate text-sm text-zinc-600">{item.content}</div>
                                <div className="flex items-center justify-end gap-4">
                                    <button
                                        onClick={() => UpdateContents(item)}
                                        type="button" className="rounded-full border p-2 text-xs"> <SlPencil /> </button>
                                    {/* <button type="button" className="rounded-full border p-2 text-xs"> <SlTrash /> </button> */}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="mt-10 flex items-center justify-end gap-10">
                        <Formbutton type="button" title="Back" bg="bg-slate-400" handleSubmission={() => setViews(1)} />
                        <Formbutton title="Submit" loading={loading} />
                    </div>
                </form>}
            </div>
        </ModalLayout>
    )
}