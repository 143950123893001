import React from 'react'
import Footer from 'components/general/Footer'
import Header from 'components/general/Header'
import { Helmet } from 'react-helmet-async'

export default function PrivacyPolicy() {
    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>Quick Direct | Privacy Policy</title>
                <link rel="notImportant" href="https://www.quickdirect.com.ng/privacy-policy" />
                <meta name="whatever" value="notImportant" />
                <link rel="canonical" href="https://www.quickdirect.com.ng/privacy-policy" />
                <meta property="og:title" content="Quick Direct | Privacy Policy" />
                <meta property="og:url" content="https://www.quickdirect.com.ng/privacy-policy" />
                <meta property="og:image" content="https://www.quickdirect.com.ng/main.png" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="At Quick Direct, we value the trust our clients and website visitors place in us. This privacy policy explains how we collect, use, and protect personal information." />
            </Helmet>

            <div className='bg-slate-50'>
                <Header />
                <div className="w-11/12 mx-auto mb-20 mt-10 lg:w-10/12 bg-white p-5 lg:p-10">
                    <div className="font-bold text-4xl mb-5">Privacy Policy</div>

                    <div className="font-light mb-5">At Quick Direct, we value the trust our clients and website visitors place in us. This privacy policy explains how we collect, use, and protect personal information.</div>

                    <div className="text-2xl mt-5 font-bold">Information We Collect</div>

                    <p className='ml-7'> - Personal information: name, address, phone number, email, and other identifiable details.</p>
                    <p className='ml-7'>- Financial information: investment accounts, transaction history, and financial goals.</p>
                    <p className='ml-7'>- Online activity: website interactions, browsing history, and search queries.</p>
                    <p className='ml-7'>- Cookies and tracking technologies: to enhance user experience and analyze website usage.</p>

                    <div className="font-bold mt-5 text-2xl">How We Use Your Information</div>

                    <p className='ml-7'> - Provide investment management and advisory services.</p>
                    <p className='ml-7'>- Process transactions and manage accounts.</p>
                    <p className='ml-7'>- Offer personalized financial solutions and recommendations.</p>
                    <p className='ml-7'>- Communicate market insights, news, and updates.</p>
                    <p className='ml-7'>- Comply with legal and regulatory requirements.</p>
                    <p className='ml-7'>- Improve our services and website experience.</p>

                    <div className="font-bold mt-5 text-2xl">Information Sharing</div>

                    <p className='ml-7'>- We share personal information with:</p>
                    <p className='ml-7'>- Affiliated companies and subsidiaries.</p>
                    <p className='ml-7'>- Third-party service providers (e.g., custodians, brokers).</p>
                    <p className='ml-7'>- Regulatory bodies and law enforcement (as required).</p>
                    <p className='ml-7'>- We do not sell or rent personal information to third parties.</p>

                    <div className="font-bold mt-5 text-2xl">Data Security</div>

                    <p className='ml-7'>- We implement robust security measures to protect personal information:</p>
                    <p className='ml-7'>- Encryption.</p>
                    <p className='ml-7'>- Firewalls.</p>
                    <p className='ml-7'>- Access controls.</p>
                    <p className='ml-7'>- Regular security testing and updates.</p>

                    <div className="font-bold text-2xl mt-5"> Data Retention</div>

                    <p className='ml-7'>- We retain personal information for:</p>
                    <p className='ml-7'>- As long as required to provide services.</p>
                    <p className='ml-7'>- As needed to comply with legal and regulatory requirements.</p>
                    <p className='ml-7'>- As necessary to resolve disputes or investigate incidents.</p>

                    <div className="font-bold mt-5 text-2xl">Your Rights</div>

                    <p className='ml-7'>- Access and correct personal information.</p>
                    <p className='ml-7'>- Request deletion or restriction of processing.</p>
                    <p className='ml-7'>- Object to processing for legitimate reasons.</p>
                    <p className='ml-7'>- Lodge a complaint with a supervisory authority.</p>

                    <div className="font-bold text-2xl mt-5"> Changes to This Privacy Policy</div>

                    <p className='ml-7'>- We will notify you of significant changes by email or website updates.</p>

                    <div className="font-bold text-2xl mt-5">Contact Us</div>

                    <p className='ml-7'>If you have questions or concerns about our privacy policy, please contact our Data Protection Officer at [quickdirect.com.ng].</p>

                    <p className='ml-7'>By using our services or website, you consent to this privacy policy. We are committed to protecting your personal information and maintaining your trust.</p>
                </div>
                <Footer />
            </div>
        </>
    )
}
