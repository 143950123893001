import React from 'react'
import { Link } from 'react-router-dom'
import { FaTimes } from 'react-icons/fa'

const links = [
]
const SSidebar = ({ handleToggle }) => {
    return (
        <div>
            <div className="relative h-screen py-10 overflow-x-hidden overflow-y-auto bg-slate-700 scrolls">
                <div onClick={handleToggle} className="absolute p-2 text-xl text-white border rounded-lg cursor-pointer lg:hidden top-3 right-3 w-fit">
                    <FaTimes />
                </div>
                <div className="flex flex-col items-start mt-10">
                    {links.map((data, i) => (
                        <div className="px-3 py-2.5 w-full" key={i}>
                            <div className="font-medium capitalize text-slate-300 ">{data.title}</div>
                            <div className="flex flex-col items-center w-full">
                                {data.subs.map((item, k) => (
                                    <Link to={`${item.url}`} className='w-full px-5 py-2 text-sm capitalize text-slate-100 hover:bg-slate-500' key={k}>{item.title}</Link>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default SSidebar