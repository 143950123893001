
import React, { useState } from 'react'
import ModalLayout from 'components/utils/ModalLayout'
import { ErrorAlert, Errors, NairaSign, SingleServiceStatus, SuccessAlert } from 'components/utils/functions'
import moment from 'moment'
import Formbutton from 'components/utils/Formbutton'
import { Apis, Authputurl } from 'components/Apis'
import Forminput from 'components/utils/Forminput'


const WithdrawOrderStatus = [
    'processing',
    'cancelled',
    'successful',
]


export default function WithdrawRequestModal({ data, closeView, refetch }) {
    const [screen, setScreen] = useState(1)
    const [loading, setLoading] = useState(false)
    const [forms, setForms] = useState({
        status: data.status || '',
        note: data.note || '',
    })

    const handleForms = e => {
        setForms({
           ...forms,
            [e.target.name]: e.target.value
        })
    }

    const SubmitUpdate = async (e) => {
        e.preventDefault()
        if(!forms.status) return ErrorAlert('provide a valid status')
        if(forms.status === WithdrawOrderStatus[1] && !forms.note) return ErrorAlert('provide a reason for cancellation')
        setLoading(true)
        try {
            const response = await Authputurl(Apis.user.update_withdrawals, {
                id: data.id,
                ...forms
            })
            if (response.status === 200) {
                SuccessAlert(response.msg)
                refetch()
                closeView()
            }else {
                ErrorAlert(response.msg)
            }
        } catch (error) {
            Errors(error)
        } finally {
            setLoading(false)
        }
    }
    return (
        <ModalLayout closeView={closeView}>
            {screen === 1 && <>
                <div className="font-bold mb-3 py-3 border-b px-4 text-sm">
                    <div className="">Withdrawal Details</div>
                    <div className="font-light text-sm">Ref: {data.ref}</div>
                </div>
                <div className="grid grid-cols-2 gap-5 w-11/12 mx-auto">
                    <div className="border rounded-lg p-2">
                        <div className="font-bold">User</div>
                        <div className="font-light">{data.user_file?.firstname} {data.user_file?.lastname}</div>
                    </div>
                    <div className="border rounded-lg p-2">
                        <div className="font-bold">Amount</div>
                        <div className="font-light">{NairaSign}{data.amount?.toLocaleString()}</div>
                    </div>
                    <div className="border rounded-lg p-2">
                        <div className="font-bold">Tag</div>
                        <div className="font-light">{data.title}</div>
                    </div>
                    <div className="border rounded-lg p-2">
                        <div className="font-bold">Status</div>
                        <div className={`font-light uppercase ${SingleServiceStatus(data.status)} `}>{data.status}</div>
                    </div>
                    <div className="border rounded-lg p-2 col-span-2">
                        <div className="font-bold">Bank Details</div>
                        <div className="font-light">{data.bank}</div>
                        <div className="font-light">{data.accname}</div>
                        <div className="font-light">{data.accnumber}</div>
                    </div>
                    <div className="border rounded-lg p-2 col-span-2">
                        <div className="font-bold">Withdrawal Details</div>
                        <div className="font-light">{data.content}</div>
                    </div>
                    <div className="border rounded-lg p-2 col-span-2">
                        <div className="font-bold">Reason for withdrawal</div>
                        <div className="font-light">{data.reason || 'NIL'}</div>
                    </div>
                </div>
                <div className="border-t p-3 grid grid-cols-2 mt-5">
                    <div className="">
                        <div className="font-bold">Withdrawal Date</div>
                        <div className="">{moment(data.createdAt).format('DD-MM-YYYY hh:mma')}</div>
                    </div>
                    <div className="w-fit ml-auto"> <button onClick={() => setScreen(2)} className="bg-main py-3 px-5 rounded-lg text-sm text-white capitalize">Update</button> </div>
                </div>
            </>}
            {screen === 2 && <>
                <div className="font-bold text-lg mb-3 py-3 border-b px-4">
                    <div className="">Update Withdrawal</div>
                    <div className="font-light text-sm">Kindly select and confirm status on this withdrawal</div>
                </div>
                <form onSubmit={SubmitUpdate}>
                    <div className="w-11/12 mx-auto">
                        <div className="">Withdrawal Status</div>
                        <Forminput formtype='select' formName={'status'} value={forms.status} onChange={handleForms}>
                            {WithdrawOrderStatus.map((ele, index) => (
                                <option value={ele} key={index}>{ele}</option>
                            ))}
                        </Forminput>
                       {forms.status === WithdrawOrderStatus[1] && <>
                       <div className="">Reason for cancellation</div>
                       <Forminput  value={forms.note} formName="note" onChange={handleForms} />
                       <div className="text-sm text-red-700">Cancellation will automatically refund the user back into the user's account {NairaSign}{data.amount?.toLocaleString()}</div>
                       </>}
                    </div>
                    <div className="border-t p-3 grid grid-cols-2 mt-5">
                        <div className="w-fit ml-auto"> <button type="button" onClick={() => setScreen(1)} className="bg-slate-300 py-3 px-5 rounded-lg text-sm capitalize">cancel</button> </div>
                        <div className="w-fit ml-auto"> <Formbutton title="Submit Update" loading={loading} /> </div>
                    </div>
                </form>
            </>}
        </ModalLayout>
    )
}

