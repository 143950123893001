

import React, { useState } from 'react'
import logo from 'assets/main-images/logo.png'
import Formbutton from 'components/utils/Formbutton'
import { Link, useNavigate } from 'react-router-dom'
import Forminput from 'components/utils/Forminput'
import { ErrorAlert, Errors, SuccessAlert } from 'components/utils/functions'
import { Apis, Posturl } from 'components/Apis'
import GeneratePin from './GeneratePin'
import Imaged from 'components/utils/Imaged'


const ForgotPassword = () => {
    const [loading, setLoading] = useState(false)
    const [screen, setScreen] = useState(1)
    const [, setLoads] = useState(false)
    const navigate = useNavigate()
    const [pass, setPass] = useState({
        password: '',
        confirm_password: ''
    })
    const [otpmsg, setOtpmsg] = useState('Resend OTP')
    const [forms, setForms] = useState({
        email: '',
    })

    const handleForms = e => {
        setForms({
            ...forms,
            [e.target.name]: e.target.value
        })
    }
    const handlePass = e => {
        setPass({
            ...pass,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmission = async () => {
        if (!forms.email) return ErrorAlert('provide your email address')
        const data = {
            ...forms
        }
        setLoading(true)
        try {
            const res = await Posturl(Apis.user.find_account_by_email, data)
            if (res.status === 200) {
                SuccessAlert(res.msg)
                setScreen(2)
            } else {
                ErrorAlert(res.msg)
            }
        } catch (error) {
            Errors(error)
        } finally {
            setLoading(false)
        }
    }

    const recoverAccount = async () => {
        if (!forms.email) return ErrorAlert('provide your email address')
        if (!pass.password) return ErrorAlert('provide your password')
        if (!pass.confirm_password) return ErrorAlert('confirm your password')
        const data = {
            email: forms.email,
            ...pass
        }
        setLoading(true)
        try {
            const res = await Posturl(Apis.user.password_request, data)
            if (res.status === 200) {
                SuccessAlert(res.msg)
                setTimeout(() => {
                    navigate('/login')
                }, 1500)
            } else {
                ErrorAlert(res.msg)
            }
        } catch (error) {
            Errors(error)
        } finally {
            setLoading(false)
        }
    }

    const handleEmailVerificaton = async (vals) => {
        const data = { code: vals }
        setLoading(true)
        try {
            const res = await Posturl(Apis.user.accept_registration_code, data)
            if (res.status === 200) {
                setScreen(3)
                SuccessAlert(res.msg)
            } else {
                ErrorAlert(res.msg)
            }
        } catch (error) {
            Errors(`${error}`)
        } finally {
            setLoading(false)
        }
    }
    const ResendCode = async () => {
        if (!forms.email) return ErrorAlert('provide a valid Email Address')
        const data = {
            email: forms.email
        }
        setLoads(true)
        try {
            setOtpmsg('sending...')
            const res = await Posturl(Apis.user.resend_otp, data)
            if (res.status === 200) {
                setOtpmsg('code sent!.')
                SuccessAlert(res.msg)
                setTimeout(() => {
                    setOtpmsg('Resend OTP')
                }, 1500);
            } else {
                Errors(res.msg)
            }
        } catch (error) {
            Errors(`${error}`)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className='bg-zinc-50'>
            <div className="h-screen">
                <div className="grid grid-cols-1 lg:grid-cols-5">
                    <div className="bg-main hidden lg:block h-screen col-span-2"></div>
                    <div className="flex items-center bg-gradient-to-b to-white from-blue-200 lg:from-white lg:to-white flex-col justify-center h-screen col-span-3">
                        <div className="w-11/12 max-w-xl mx-auto shadow-2xl rounded-lg p-6 bg-white text-sm">
                            <div className="w-fit ml-auto p-2 mb-5 rounded-lg">
                                <Imaged src={logo} alt="" className="h-10" />
                            </div>
                            <form className={`${screen === 1 ? '' : 'hidden'}`}>
                                <div className="mb-6">
                                    <Forminput
                                        label="Email Address"
                                        type="email"
                                        formName="email"
                                        value={forms.email}
                                        onChange={handleForms}
                                    />
                                </div>
                                <Formbutton handleSubmission={handleSubmission} title='find account' loading={loading} />
                            </form>
                            <form className={`${screen === 2 ? '' : 'hidden'}`}>
                                <GeneratePin
                                    title="Confirm Email Address"
                                    buttonText="continue"
                                    continueSetup={handleEmailVerificaton}
                                    loading={loading}
                                />
                                <div className="my-5 text-center flex items-center justify-center gap-3">Didn't recieve any code? <div onClick={ResendCode} className="text-sub cursor-pointer">{otpmsg}</div> </div>
                            </form>
                            <form className={`${screen === 3 ? '' : 'hidden'}`}>
                                <div className="text-lg font-medium mb-4">Recover Account password</div>
                                <div className="mb-5">
                                    <Forminput
                                        label="Password"
                                        type="password"
                                        onChange={handlePass}
                                        value={pass.password}
                                        formName={'password'}
                                    />
                                </div>
                                <div className="mb-5">
                                    <Forminput
                                        label="Confirm Password"
                                        type="password"
                                        onChange={handlePass}
                                        value={forms.confirm_password}
                                        formName={'confirm_password'}
                                    />
                                </div>
                                <Formbutton handleSubmission={recoverAccount} title='change password' loading={loading} />
                            </form>
                            <div className="text-sm font-medium text-gray-500 dark:text-gray-300 mt-10">
                                Not registered? <Link to={`/signup`} className="text-blue-700 hover:underline dark:text-blue-500">create account</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword