import React, { useState } from 'react'
import { BiDislike, BiLike } from 'react-icons/bi'
import { ErrorAlert, SuccessAlert } from 'components/utils/functions'
import Forminput from 'components/utils/Forminput'
import Formbutton from 'components/utils/Formbutton'
import { Apis, Authposturl, SocketConnect } from 'components/Apis'

export default function ChatFeedback({ messages, setViews}) {
    const [loading, setLoading] = useState(false)
    const [forms, setForms] = useState({
        feedstatus: '',
        feedcontent: '',
        roomid: messages.id
    })

    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }

    const handleSubmission = async e => {
        e.preventDefault()
        if (!forms.feedstatus) return ErrorAlert('Kindly select an icon based on your satisfaction')
        setLoading(true)
        try {
            const response = await Authposturl(Apis.feeds.submit_chat_feedback, forms)
            if (response.status === 200) {
                SuccessAlert(response.msg)
                setViews(false)
            } else {
                ErrorAlert(response.msg)
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoading(false)
        }
    }

    const SelectionIcon = (val) => {
        if (forms.feedstatus !== val) {
            setForms({
                ...forms,
                feedstatus: val
            })
        } else {
            setForms({
                ...forms, feedstatus: ''
            })
        }
    }
    return (
        <div className='flex flex-col items-center justify-center w-3/4 mx-auto mb-10'>
            <div className="font-bold text-xl">Did we help you?</div>
            <div className="">Your feedback matters.</div>
            <form onSubmit={handleSubmission} className="w-full flex flex-col gap-3 mt-4">
                <div className="flex items-center gap-10 w-fit mx-auto">
                    <button type="button" onClick={() => SelectionIcon('like')} className={`p-2 rounded-full text-3xl ${forms.feedstatus === 'like' ? 'bg-green-400/30 text-green-700' : 'bg-slate-400/30 text-slate-700'}`}> <BiLike /> </button>
                    <button type="button" onClick={() => SelectionIcon('dislike')} className={`p-2 rounded-full text-3xl ${forms.feedstatus === 'dislike' ? 'bg-red-400/30 text-red-700' : 'bg-slate-400/30 text-slate-700'}`}> <BiDislike /> </button>
                </div>
                <Forminput formtype='textarea' label="Tell us about your experience (Optional)" formName={'feedcontent'} value={forms.feedcontent} onChange={handleForms}></Forminput>
                <div className="-mt-4">
                    <Formbutton title="Submit" loading={loading} />
                </div>
            </form>
        </div>
    )
}
