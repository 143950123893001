import React from 'react'
import { FaCheck, FaTimes } from 'react-icons/fa'

const ServiceTag = ({ tag }) => {
    return (

        tag === 'true' ? <div className='rounded-full p-1.5 bg-green-300 w-fit h-fit text-green-600 text-xs'> <FaCheck /> </div> :
            <div className='rounded-full p-1.5 bg-red-300 w-fit h-fit text-red-600 text-xs'> <FaTimes /> </div>

    )
}

export default ServiceTag
