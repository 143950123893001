import React from 'react'
import ModalLayout from 'components/utils/ModalLayout'
import img from 'assets/images/img11.png'
import Imaged from 'components/utils/Imaged'

const Confirmation = ({title, texts, closeView}) => {
    return (
        <ModalLayout closeView={closeView}>
            <div className="dark:text-slate-100 px-3 py-5 ">
                <div className="w-fit mx-auto">
                    <div className="rounded-full p-4 animate-bounce text-4xl mb-55 mt-5"> <Imaged src={img} className='w-[5rem]' /> </div>
                </div>
                <div className="text-lg text-center font-medium">{title}</div>
                <div className="text-sm text-center">{texts}</div>
            </div>
        </ModalLayout>
    )
}

export default Confirmation

