import { useCallback, useEffect, useState } from "react";
import { ErrorAlert } from "components/utils/functions";
import AssetModal from "./AssetModal";
import { Apis, Authgeturl } from "components/Apis";
import moment from "moment";
import ALayout from "components/admin/ALayout";


export default function ManageAsset() {
    const [loading, setLoading] = useState(true)
    const [view, setView] = useState({
        status: false,
        data: {}
    })
    const [items, setItems] = useState([])
    const FetchAsset = useCallback(async () => {
        try {
            const response = await Authgeturl(Apis.investments.all_asset)
            if(response.status === 200) return setItems(response.msg)
            ErrorAlert(`${response.msg}`)
        } catch (error) {
            ErrorAlert(`${error.message}`)
        }finally {
            setLoading(false)
        }
    }, [])
    useEffect(() => {FetchAsset()}, [FetchAsset])
    return (
        <ALayout>
            {view.status && <AssetModal 
            refetchData={() => FetchAsset()}
            pagedata={view.data}
            closeView={() => setView({...view, status: false})}
            /> }
            <div className="mb-5 text-xl text-center">Manage Quick Direct Coin Asset</div>
            {!loading && items.length < 1 && 
             <div className="mx-auto mt-10 w-fit">
                <button
                onClick={() => setView({status: true, data: {}})}
                className="px-5 py-3 text-sm text-white capitalize rounded-lg bg-main">create asset</button>
             </div> }
            {!loading && items.length < 1 && <div className="mt-10 text-center text-slate-500">You have not added any asset to manage yet</div> }
           {!loading && items.map((item, index) => (
             <div key={index} className="max-w-xl p-4 mx-auto bg-white shadow-2xl">
             <div className="grid grid-cols-2 gap-3 p-3 border-b">
                 <div className="font-light">Asset</div>
                 <div className="font-bold text-right">{item.title}</div>
             </div>
             <div className="grid grid-cols-2 gap-3 p-3 border-b">
                 <div className="font-light">Base Price</div>
                 <div className="font-bold text-right">{item.baseprice}</div>
             </div>
             <div className="grid grid-cols-2 gap-3 p-3 border-b">
                 <div className="font-light">Base percent</div>
                 <div className="font-bold text-right">{item.basepercent}%</div>
             </div>
             <div className="grid grid-cols-2 gap-3 p-3 border-b">
                 <div className="font-light">Selling Price</div>
                 <div className="font-bold text-right">{item.sellingprice}</div>
             </div>
             <div className="grid grid-cols-2 gap-3 p-3 border-b">
                 <div className="font-light">Selling percent</div>
                 <div className="font-bold text-right">{item.sellingpercent}%</div>
             </div>
             <div className="grid grid-cols-2 gap-3 p-3 border-b">
                 <div className="font-light">Trend Movement</div>
                 <div className={`font-bold text-right ${item.trend?.startsWith('-') ? 'text-red-600' : 'text-green-600'}`}>{item.trend?.startsWith('-') ? '' : '+'}{item.trend}%</div>
             </div>
             <div className="grid grid-cols-2 gap-3 p-3 border-b">
                 <div className="font-light">Date Created</div>
                 <div className="font-bold text-right">{moment(item.createdAt).format('DD/MM/YYYY h:mm:sA')}</div>
             </div>
             <div className="grid grid-cols-2 gap-3 p-3">
                 <div className="font-light">Date Last Updated</div>
                 <div className="font-bold text-right">{moment(item.updatedAt).format('DD/MM/YYYY h:mm:sA')}</div>
             </div>
             <div className="mt-10 ml-auto w-fit">
                <button
                onClick={() => setView({status: true, data: item})}
                className="px-5 py-3 text-sm text-white capitalize rounded-lg bg-main">manage</button>
             </div>
         </div>
           ))}
        </ALayout>
    )
}