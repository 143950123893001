import UserLayout from 'components/user/UserLayout'
import { useNavigate } from 'react-router-dom'
import { SlArrowRight } from 'react-icons/sl'
import { PiChartLineUpLight } from 'react-icons/pi'
import { Errors } from 'components/utils/functions'
import { Apis, Authposturl } from 'components/Apis'
import {useSelector} from 'react-redux'
import Imaged from 'components/utils/Imaged'
import emptyImage from "assets/main-images/empty.png"


const Invests = () => {
    const {plans} = useSelector(state => state.data)
    const navigate = useNavigate()

    const handleStock = async ({data, dataid}) => {
        try {
            const res = await Authposturl(`${Apis.investments.add_stock}/${dataid}`)
            if(res.status === 200) {
                navigate(`/investment/${res.msg}/${data}`)
            }
        } catch (error) {
            Errors(error)
        }
    }

    return (
        <UserLayout title="Investments">
            <div className="flex flex-col w-11/12 mx-auto gap-4 mt-5">
                {plans.length < 1 && 
                    <div className="flex items-center justify-center flex-col">
                        <Imaged src={emptyImage} className="size-20" />
                        <div className="text-slate-500 text-sm">No records found</div>
                    </div>}
                {plans.length > 0 && plans.map((item, k) => (
                    <div onClick={() => handleStock({data: item.title, dataid: item.id})} key={k}>
                        <div className="border cursor-pointer rounded-lg shadow-lg p-2 bg-white">
                            <div className="grid grid-cols-5">
                                <div className="capitalize flex items-center gap-2 col-span-4 font-bold"> <PiChartLineUpLight /> {item.title} investment</div>
                                <div className="col-span-1 w-fit ml-auto text-xs mt-2"> <SlArrowRight /> </div>
                            </div>
                            <div className="text-xs ml-6">{item.subTitle}</div>
                        </div>
                    </div>
                ))}
            </div>
        </UserLayout>
    )
}

export default Invests