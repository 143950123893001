import React from 'react'
import Header from 'components/general/Header'
import Footer from 'components/general/Footer'
import { Helmet } from 'react-helmet-async'

export default function Aboutus() {
    return (
        <>
            <Helmet prioritizeSeoTags>
                <title>Quick Direct | About Us</title>
                <link rel="notImportant" href="https://www.quickdirect.com.ng/about-us" />
                <meta name="whatever" value="notImportant" />
                <link rel="canonical" href="https://www.quickdirect.com.ng/about-us" />
                <meta property="og:title" content="Quick Direct | About Us" />
                <meta property="og:url" content="https://www.quickdirect.com.ng/about-us" />
                <meta property="og:image" content="https://www.quickdirect.com.ng/main.png" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Our mission is to build long-term relationships with our clients, helping them navigate the complex financial landscape and achieve success." />
            </Helmet>
            <div className='bg-slate-50'>
                <Header />
                <div className="w-11/12 mx-auto mb-20 mt-10 lg:w-10/12 bg-white p-5 lg:p-10">
                    <div className="font-bold text-4xl mb-5">About Us</div>

                    <p className='ml-7'>At Quick Direct, we're passionate about empowering individuals and institutions to achieve their financial goals. Our story began with a vision to revolutionize the financial industry by harnessing the power of innovation, expertise, and technology.</p>

                    <div className="font-bold text-2xl mt-5">Our Mission</div>

                    <p className='ml-7'>We aim to provide cutting-edge investment solutions, exceptional client service, and unparalleled transparency. Our mission is to build long-term relationships with our clients, helping them navigate the complex financial landscape and achieve success.</p>

                    <div className="font-bold text-2xl mt-5">Our Values</div>

                    <p className='ml-7'>- Innovation: We embrace the latest technologies and strategies to stay ahead of the curve.</p>
                    <p className='ml-7'>- Expertise: Our team of seasoned professionals brings extensive knowledge and experience to the table.</p>
                    <p className='ml-7'>- Transparency: We believe in clear and open communication, ensuring our clients are always informed.</p>
                    <p className='ml-7'>- Integrity: We operate with the highest ethical standards, prioritizing our clients' interests.</p>
                    <p className='ml-7'>- Client-centricity: Our focus is on delivering personalized solutions tailored to each client's unique needs.</p>

                    <div className="font-bold text-2xl mt-5">Our Team</div>

                    <div className="font-bold text-2xl mt-5">Meet our leadership team:</div>

                    <p className='ml-7'>- [Name], CEO & Founder: A seasoned investment professional with [number] years of experience.</p>
                    <p className='ml-7'>- [Name], CIO: A renowned expert in investment strategy and portfolio management.</p>
                    <p className='ml-7'>- [Name], COO: A seasoned operational executive with a proven track record in financial services.</p>

                    <div className="font-bold text-2xl mt-5">Our History</div>

                    <p className='ml-7'>Quick Direct was founded in [2022] by a team of experienced financial professionals who shared a vision of creating a better investment experience. Since our inception, we've grown into a leading financial services firm, serving a diverse range of clients.</p>

                    <div className="font-bold text-2xl mt-5">Our Commitment</div>

                    <p className='ml-7'>We're dedicated to making a positive impact in our community and the environment. We support initiatives promoting financial literacy, education, and sustainability.</p>

                    <p className='ml-7'>Join Us</p>

                    <p className='ml-7'>If you share our passion for innovation and client-centricity, explore our career opportunities. Let's work together to shape the future of finance.</p>

                </div>
                <Footer />
            </div>
        </>
    )
}
