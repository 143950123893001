import React, { useEffect, useRef, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import ALayout from 'components/admin/ALayout'
import Forminput from 'components/utils/Forminput'
import Formbutton from 'components/utils/Formbutton'
import { ErrorAlert, Errors, SuccessAlert } from 'components/utils/functions'
import { Apis, Authgeturl, Authposturl } from 'components/Apis'
import { SlArrowDown, SlArrowUp, SlCheck, SlTrash } from 'react-icons/sl'
import Backbutton from 'components/utils/Backbutton'
import { FaCheck, FaTimes } from 'react-icons/fa'
import ServiceTag from 'components/admin/ServiceTag'

const SetupMargin = () => {
    const { tag } = useParams()
    const [loading, setLoading] = useState(true)
    const [loads, setLoads] = useState(false)
    const [headers, setHeaders] = useState([])
    const [services, setServices] = useState([])
    const navigate = useNavigate()
    const [main, setMain] = useState([])
    const [open, setOpen] = useState(false)
    const [forms, setForms] = useState({
        cashback: '',
        fee: '',
        tagid: tag
    })
    const [plans, setPlans] = useState([])
    const [planform, setPlanForm] = useState({
        servicetype: '',
        serviceid: '',
        aval: 'true'
    })
    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }
    const handlePlanForms = e => {
        setPlanForm({ ...planform, [e.target.name]: e.target.value })
    }
    const Icon = open ? SlArrowUp : SlArrowDown
    useEffect(() => {
        const FetchAllServices = async () => {
            setLoading(true)
            const finals = []
            try {
                const response = await Authgeturl(`${Apis.serviceapi.service_details}/${tag}`)
                if (response.status === 200) {
                    response.msg.map(ele => {
                        const data = {
                            keys: [...Object.keys(ele), 'fee', 'amount to be shown'],
                            values: [...Object.values(ele), 0, 0]
                        }
                        finals.push(data)
                    })
                    setHeaders(finals[0]?.keys)
                    setMain(finals)
                    return setServices(finals)
                    // return services.current = finals
                }
            } catch (error) {
                Errors(`${error.message}`)
            } finally {
                setLoading(false)
            }
        }
        FetchAllServices()
    }, [])

    const handleFees = (e) => {
        const val = e.target.value
        let originalLength = 0
        main.map(ele => {
            originalLength = ele.values.length
        })
        const finals = services.map(ele => {
            // const finals = services.current.map(ele => {
            const pricing = ele.values[originalLength - 3]
            const percent = pricing !== '' ? (val * parseFloat(pricing)) / 100 : val
            const sum = pricing !== '' ? parseFloat(pricing) + percent : '---'
            ele.values[originalLength - 2] = percent
            ele.values[originalLength - 1] = sum
            return ele

        })
        setServices(finals)

    }

    const handleSubmission = async e => {
        e.preventDefault()
        if (!forms.fee) return ErrorAlert('Provide Fee to be charged')
        if (!forms.cashback) return ErrorAlert('Provide Cashback to be supplied upon purchase')
        const formdata = {
            cashback: forms.cashback,
            charges: forms.fee,
            servicename: tag,
            serviceplans: plans
        }
        setLoads(true)
        try {
            const response = await Authposturl(Apis.serviceapi.add_service, formdata)
            if (response.status === 200) {
                SuccessAlert(response.msg)
                navigate(`/admin/services`)
            } else {
                return ErrorAlert(`${response.msg}`)
            }
        } catch (error) {
            Errors(`${error}`)
        } finally {
            setLoads(false)
        }
    }

    const AddupPlan = () => {
        if (!planform.servicetype) return ErrorAlert('Provide a valid plan name')
        if (!planform.serviceid) return ErrorAlert('Provide a valid plan ID')
        if (!planform.aval) return ErrorAlert('Provide a plan availability status')
        setPlans([
            ...plans,
            planform
        ])
        SuccessAlert('Plan Added successfully')
        setPlanForm({
            servicetype: '',
            serviceid: '',
            aval: 'true'
        })
    }
    return (
        <ALayout>
            <Backbutton link="/admin/services/new" />
            <form onSubmit={handleSubmission} className="grid grid-cols-1 gap-5">
                <div className="bg-white rounded-lg">
                    <div className="border-b p-4">
                        <div className="">Set up Margin</div>
                    </div>
                    <div className='p-4'>
                        <div className="mb-3">
                            <div className="">Setup Purchase fee (%)</div>
                            <input name="fee" value={forms.fee} onChange={handleForms} type="number" onKeyUp={handleFees} className='w-full border p-3 rounded border-zinc-300' />
                        </div>
                        <div className="mb-3">
                            <div className="">Cashback returned (%)</div>
                            <Forminput formName="cashback" value={forms.cashback} onChange={handleForms} type="number" />
                        </div>
                    </div>
                </div>
                <div className="bg-white">
                    <div className="border-b p-4">
                        <div className="">Set up Service plans</div>
                    </div>
                    <div className="p-4 grid grid-cols-1 lg:grid-cols-2 gap-5">
                        <div className="">
                            <div className="mb-4">
                                <div className="">Service type</div>
                                <Forminput formName={'servicetype'} value={planform.servicetype} onChange={handlePlanForms} type="text" />
                            </div>
                            <div className="mb-4">
                                <div className="">Service ID</div>
                                <Forminput formName={'serviceid'} value={planform.serviceid} onChange={handlePlanForms} type="text" />
                            </div>
                            <div className="mb-4">
                                <div className="">Service Availability</div>
                                <Forminput formName={'aval'} value={planform.aval} formtype='select' onChange={handlePlanForms}>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                    </Forminput>
                            </div>
                            <div className="w-fit ml-auto">
                                <button type="button" onClick={AddupPlan} className="bg-main py-3 px-5 rounded-lg capitalize text-white">add plan</button>
                            </div>
                        </div>
                        <div className="">
                            {plans.length > 0 ? <>
                                <div className="border-b">
                                    <div className="">Service plan Summary</div>
                                </div>
                                <div className="flex flex-col">
                                    {plans.map((ele, index) => (
                                        <div className="border-b last:border-none p-2.5" key={index}>
                                            <div className="grid grid-cols-5">
                                                <div className="col-span-4">
                                                    <div className="grid grid-cols-2">
                                                       <div className="flex items-center gap-2">
                                                      <ServiceTag tag={ele.aval} />
                                                        <div className="">{ele.servicetype}</div>
                                                       </div>
                                                        <div className="text-right">{ele.serviceid}</div>
                                                    </div>
                                                </div>
                                                <div className="col-span-1 p-2">
                                                    <div className="flex flex-col w-fit ml-auto gap-5">
                                                    <button type="button" onClick={() => setPlans(plans.filter((e, i) => i!== index))} className="text-red-600"> <SlTrash /> </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </> : 
                            <div className="text-center text-zinc-500">Add Plans to see your summary</div>
                            }
                        </div>
                    </div>
                </div>
                        <div className="w-fit ml-auto">
                            <Formbutton loading={loads}  title="Save Margin" />
                        </div>
                <div className="bg-white rounded-lg">
                    <div onClick={() => setOpen(!open)} className="border-b p-4 grid grid-cols-2 cursor-pointer">
                        <div className="">View Services and Pricing ({services.length})</div>
                        {/* <div className="">View Services and Pricing ({services.current.length})</div> */}
                        <div className="w-fit ml-auto">  <Icon /> </div>
                    </div>
                    {open && <div className="p-3">
                        <table className='table table-auto w-full'>
                            <thead>
                                <tr className='bg-slate-700 text-white'>
                                    {headers.map((ele, i) => (
                                        <td className='capitalize p-3 text-sm' key={i}>{ele}</td>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {services.map((ele, index) => (
                                    // {services.current.map((ele, index) => (
                                    <tr className='border-b' key={index}>
                                        {ele.values.map((item, i) => (
                                            <td className='capitalize p-3 text-sm' key={i}>{item}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                </div>
            </form>
        </ALayout>
    )
}

export default SetupMargin
