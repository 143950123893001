import React, { useEffect, useRef, useState } from 'react'
import UserLayout from 'components/user/UserLayout'
import { ClientInvestmentStatus, NairaSign, SuccessAlert } from 'components/utils/functions'
import { FaEllipsisV, FaSignInAlt, FaSignOutAlt } from 'react-icons/fa'
import { SlClock, SlOptions } from 'react-icons/sl'
import { BsBarChartFill } from 'react-icons/bs'
import { Link, useParams } from 'react-router-dom'
import InvestLayout from '../../Compos/InvestLayout'
import { useSelector } from 'react-redux'
import FundsModal from '../FundsModal'
import InvestAgreeSlip from '../../Compos/InvestAgreeSlip'
import { useQuery } from '@tanstack/react-query'
import { Apis, Authgeturl } from 'components/Apis'
import { MdHeadsetMic } from 'react-icons/md'
import InvestManager from '../../Compos/InvestManager'
import moment from 'moment'
import IstockBoard from './IstockBoard'

const Istock = () => {
    const [screen, setScreen] = useState({
        status: false,
        tag: null
    })
    const { plan, activeplan } = useSelector(state => state.data)
    const [stat, setStat] = useState(false)
    const [md, setMd] = useState(false)
    const { id } = useParams()

    const { isLoading, refetch, isError, error } = useQuery({
        queryKey: [`get-investment-agree-${id}`],
        queryFn: async () => {
            const res = await Authgeturl(`${Apis.investments.stock}/${id}`)
            if (res.status === 200) {
                if (res.msg.agree === 'no') {
                    setStat(true)
                } else {
                    setStat(false)
                }
            }
            return res.msg
        }
    })


    const handleScreens = (val) => {
        setScreen({
            status: true,
            tag: val
        })
    }

    if (isLoading) return (
        <div></div>
    )
    if(isError) return (<div>{error}</div>)
    
    return (
        <UserLayout title="Istock Funds" paddings={false}>
            {screen.status && <FundsModal
                tag={screen.tag}
                closeView={() => setScreen({ ...screen, status: false })}
            />}
            {stat && <InvestAgreeSlip refetch={refetch} closeView={() => SuccessAlert('agree to our terms of usage')} tag={'prime'} id={plan.id} />}

            <InvestLayout>
                <div className="mainbg py-5 relative">
                    <div className="flex items-center justify-end gap-5 w-11/12 mx-auto">
                        <div className="">
                            <div className="text-white text-2xl cursor-pointer" onClick={() => setMd(prev => !prev)}>
                                <MdHeadsetMic />
                            </div>
                           {md && <InvestManager manager={plan.plans?.md[0]} closeView={() => setMd(false)} /> }
                        </div>
                        <div className="">
                            <Link to={`/investment/${plan.id}/istock/manage`}
                                className="text-white text-xl"> <FaEllipsisV /> </Link>
                        </div>
                    </div>
                    <IstockBoard 
                    activeplan={activeplan}
                    plan={plan}
                    />
                </div>
                <div className="">
                    <div className="">

                        <div className=" dark:bg-slate-600 bg-white rounded-lg px-3 pb-3 pt-10">
                            <div className="flex items-center justify-end gap-5">
                                <button
                                    onClick={() => handleScreens('deposit')}
                                    className="py-2 px-4 rounded-lg border text-sm capitalize font-bold flex items-center gap-3"> <FaSignInAlt /> deposit</button>
                                <button
                                    onClick={() => handleScreens('withdraw')}
                                    className="py-2 px-4 rounded-lg border text-sm capitalize font-bold flex items-center gap-3"> <FaSignOutAlt /> withdraw</button>
                            </div>
                            <div className="flex items-center justify-between border-b py-2">
                                <div className="text-sm capitalize">financial statics</div>
                                <div className=""> <SlOptions /> </div>
                            </div>
                            <div className="flex items-center justify-between border-b py-2">
                                <div className="text-sm capitalize">available balance</div>
                                <div className="text-right text-sm"> {NairaSign}{plan.balance?.toLocaleString() || 0}</div>
                            </div>
                            <div className="grid grid-cols-2 py-3 border-b">
                                <div className="text-sm capitalize">total income</div>
                                <div className="text-right text-sm"> {NairaSign}{plan.totalEarned?.toLocaleString() || 0}</div>
                            </div>
                            <div className="grid grid-cols-2 py-3 border-b">
                                <div className="text-sm capitalize">total stocks bought</div>
                                <div className="text-right text-sm"> {plan.stocksBought?.toLocaleString() || 0} </div>
                            </div>
                            <div className="grid grid-cols-2 py-3 border-b">
                                <div className="text-sm capitalize">total stocks sold</div>
                                <div className="text-right text-sm"> {plan.stocksSold?.toLocaleString() || 0} </div>
                            </div>
                            <div className="grid grid-cols-2 py-3 border-b">
                                <div className="text-sm capitalize">total deposited</div>
                                <div className="text-right text-sm"> {NairaSign}{plan.totalDept?.toLocaleString() || 0} </div>
                            </div>
                            <div className="grid grid-cols-2 py-3 border-b">
                                <div className="text-sm capitalize">total withdrawn</div>
                                <div className="text-right text-sm"> {NairaSign}{plan.totalWithd?.toLocaleString() || 0} </div>
                            </div>
                        </div>
                        <div className="mt-5 mb-10 flex text-white items-center justify-center w-10/12 mx-auto shadow-xl">
                            <Link to={`/investment/${id}/istock/trade`} className='w-full bg-slate-700 cursor-pointer text-center py-3 rounded-lg shadow-xl uppercase flex items-center justify-center text-sm gap-3 px-6'>trade / track stock <BsBarChartFill /> </Link>
                        </div>
                    </div>
                </div>
            </InvestLayout>
        </UserLayout>
    )
}

export default Istock