
import ModalLayout from 'components/utils/ModalLayout'
import { NairaSign, SingleServiceStatus } from 'components/utils/functions'
import moment from 'moment'

export default function HistoryModal({ data, closeView, tag }) {
    return (
        <ModalLayout closeView={closeView}>
            {tag === 'deposit' && <>

                <div className="font-bold mb-3 py-3 border-b px-4 text-sm">
                    <div className="">Deposit Details</div>
                    <div className="font-light text-sm">Ref: {data.ref}</div>
                </div>
                <div className="grid grid-cols-2 gap-5 w-11/12 mx-auto">
                    <div className="border rounded-lg p-2">
                        <div className="font-bold">User</div>
                        <div className="font-light">{data.user_filed?.firstname} {data.user_filed?.lastname}</div>
                    </div>
                    <div className="border rounded-lg p-2">
                        <div className="font-bold">Amount</div>
                        <div className="font-light">{NairaSign}{data.amount?.toLocaleString()}</div>
                    </div>
                    <div className="border rounded-lg p-2">
                        <div className="font-bold">Tag</div>
                        <div className="font-light">{data.title}</div>
                    </div>
                    <div className="border rounded-lg p-2">
                        <div className="font-bold">Status</div>
                        <div className={`font-light uppercase ${SingleServiceStatus(data.status)} `}>{data.status}</div>
                    </div>
                    <div className="border rounded-lg p-2 col-span-2">
                        <div className="font-bold">Deposit Details</div>
                        <div className="font-light">{data.content}</div>
                    </div>
                </div>
                <div className="border-t p-3 grid grid-cols-2 mt-5">
                    <div className="">
                        <div className="font-bold">Deposit Date</div>
                        <div className="">{moment(data.createdAt).format('DD-MM-YYYY hh:mma')}</div>
                    </div>
                </div>
            </>}
            {tag === 'withdraw' && <>

                <div className="font-bold mb-3 py-3 border-b px-4 text-sm">
                    <div className="">Withdrawal Details</div>
                    <div className="font-light text-sm">Ref: {data.ref}</div>
                </div>
                <div className="grid grid-cols-2 gap-5 w-11/12 mx-auto">
                    <div className="border rounded-lg p-2">
                        <div className="font-bold">User</div>
                        <div className="font-light">{data.user_file?.firstname} {data.user_file?.lastname}</div>
                    </div>
                    <div className="border rounded-lg p-2">
                        <div className="font-bold">Amount</div>
                        <div className="font-light">{NairaSign}{data.amount?.toLocaleString()}</div>
                    </div>
                    <div className="border rounded-lg p-2">
                        <div className="font-bold">Tag</div>
                        <div className="font-light">{data.title}</div>
                    </div>
                    <div className="border rounded-lg p-2">
                        <div className="font-bold">Status</div>
                        <div className={`font-light uppercase ${SingleServiceStatus(data.status)} `}>{data.status}</div>
                    </div>
                    <div className="border rounded-lg p-2 col-span-2">
                        <div className="font-bold">Bank Details</div>
                        <div className="font-light">{data.bank}</div>
                        <div className="font-light">{data.accname}</div>
                        <div className="font-light">{data.accnumber}</div>
                    </div>
                    <div className="border rounded-lg p-2 col-span-2">
                        <div className="font-bold">Withdrawal Details</div>
                        <div className="font-light">{data.content}</div>
                    </div>
                    <div className="border rounded-lg p-2 col-span-2">
                        <div className="font-bold">Reason for withdrawal</div>
                        <div className="font-light">{data.reason || 'NIL'}</div>
                    </div>
                </div>
                <div className="border-t p-3 grid grid-cols-2 mt-5">
                    <div className="">
                        <div className="font-bold">Withdrawal Date</div>
                        <div className="">{moment(data.createdAt).format('DD-MM-YYYY hh:mma')}</div>
                    </div>
                </div>
            </>}
        </ModalLayout>
    )
}

