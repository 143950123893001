
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const Loading = () => {
    const [text, setText] = useState('')
    const {user} = useSelector(state => state.data)

    useEffect(() => {
        (() => {
            setText(`${user?.firstname ? `Hi, ${user.firstname}` : ''} Loading your request...`)
            setTimeout(() => {
                setText('Do not reload the page, still working on your request...')
                setTimeout(() => {
                    setText('Request almost completed, kindly hold on...')
                }, 4000);
            }, 4000);
        })()
    }, [user])
    return (
        <div className="absolute top-0 left-0 bg-white/50 backdrop-blur-sm w-full h-full flex flex-col items-center z-[999] justify-center">
            <div className="loader"></div>
            <div className="animate-pulse font-medium">{text}</div>
        </div>
    )
}

export default Loading