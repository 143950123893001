
import React from 'react'
import UserFooter from './UserFooter'
import Usernavbar from './Usernavbar'
import UserSidenavs from './UserSidenavs'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Imaged from 'components/utils/Imaged'
import bn from "assets/main-images/bn.png"

const UserLayout = ({ children, footer = true, title, navlink, paddings = true, bgmain }) => {
  const { user } = useSelector(state => state.data)
  return (
    <>
      <div className="bg-zinc-100/60">
        <div className="flex items-center">
          <div className="hidden h-screen border-r lg:w-[20%] bg-white lg:block">
            <UserSidenavs />
          </div>
          <div className="h-screen overflow-hidden w-full lg:w-[80%] ml-auto">
            <div className="">
              <Usernavbar bgmain={bgmain} title={title} navlink={navlink} />
            </div>
            <div className="h-[92dvh] overflow-y-auto scrolls overflow-x-hidden">
              <div className={`w-full h-full pb-5`}>
                <div className={paddings ? 'pb-20' : ''}>
                 {user?.firstname && !user.account?.status && <div className="w-11/12 mx-auto p-2 flex items-center justify-between rounded-md border border-sub bg-sub/10 text-sub my-2 text-xs md:text-base">
                    <div className="flex items-center gap-1">
                      <Imaged src={bn} className="size-10" />
                      <div className="font-semibold text-sm">Your account is not activated yet</div>
                    </div>
                    <Link to="/upgrade" className='bg-sub text-white py-1.5 px-3 rounded-md shadow-xl animate-bounce'>Activate</Link>
                  </div>}
                  {children}
                </div>
                {footer && <div className="lg:hidden h-[10dvh] pt-2">
                  <div className="fixed bottom-0 left-0 w-full">
                    <div className="grid grid-cols-1 lg:grid-cols-4">
                      <div className="lg:block"></div>
                      <div className="col-span-2">
                        <UserFooter />
                      </div>
                      <div className="lg:block"></div>
                    </div>
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserLayout
