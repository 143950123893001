import moment from "moment";
import ModalLayout from "components/utils/ModalLayout";
import { ErrorAlert, SuccessAlert } from "components/utils/functions";
import { Apis, Authposturl } from "components/Apis";
import { useNavigate } from "react-router-dom";
import Formbutton from "components/utils/Formbutton";
import { useState } from "react";
import { FormHeader } from "components/utils/FormHeader";


export default function ShareRequestModal({ closeView, single }) {
    const [loading, setLoading] = useState(false)
    const [screen, setScreen] = useState(1)
    const navigate = useNavigate()

    const ApproveRequest = async () => {
        setLoading(true)
        const forms = {
            requestid: single.id
        }
        try {
            const res = await Authposturl(Apis.shares.approve_shareholder_request, forms)
            if (res.status === 200) {
                SuccessAlert(res.msg)
                return navigate('/superadmin/share/holders')
            } else {
                return ErrorAlert(res.msg)
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoading(false)
        }
    }
    return (
        <ModalLayout closeView={closeView}>
            {screen === 2 && <>
            <div className="p-4 border-b"><FormHeader title="Confirm Approval" back={true} setScreen={setScreen} /> </div>
                <div className="p-4">
                <div className="text-center">Kindly confirm your request to approve {single.firstname} {single.lastname} as a shareholder!</div>
                
                <div className="mt-10">
                        <Formbutton title="Approve" loading={loading} handleSubmission={ApproveRequest} />
                        </div>
                </div>
            </>}
            {screen === 1 && <>
                <div className="p-4 mb-4 border-b">Request Details</div>
                <div className="grid grid-cols-2 gap-4 p-2">
                    <div className="">Full Name</div>
                    <div className="text-right">{single.firstname} {single.lastname}</div>
                </div>
                <div className="grid grid-cols-2 gap-4 p-2">
                    <div className="">Email Address</div>
                    <div className="text-right">{single.email}</div>
                </div>
                <div className="grid grid-cols-2 gap-4 p-2">
                    <div className="">Phone Number</div>
                    <div className="text-right">{single.phone}</div>
                </div>
                <div className="grid grid-cols-2 gap-4 p-2">
                    <div className="">Nationality</div>
                    <div className="text-right">{single.nationality}, {single.state}</div>
                </div>
                <div className="grid grid-cols-2 gap-4 p-2">
                    <div className="">Address</div>
                    <div className="text-right">{single.address}</div>
                </div>
                <div className="grid grid-cols-2 gap-4 p-2">
                    <div className="">Request Status</div>
                    <div className="text-right">{single.status}</div>
                </div>
                <div className="grid grid-cols-2 gap-4 p-2 border-t">
                    <div className="">
                        <div className="">Date Requested</div>
                        <div className="">{moment(single.createdAt).format('DD-MM-YYYY')}</div>
                    </div>
                    {single.status === 'pending' && <div className="ml-auto w-fit">
                        <Formbutton title="Approve" handleSubmission={() => setScreen(2)} />
                    </div>}
                </div>
            </>}
        </ModalLayout>
    )
}