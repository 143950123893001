
import React, { useState } from 'react'
import ModalLayout from 'components/utils/ModalLayout'
import moment from 'moment'
import { ErrorAlert, NairaSign, SuccessAlert } from 'components/utils/functions'
import Formbutton from 'components/utils/Formbutton'
import Forminput from 'components/utils/Forminput'
import { Apis, Authputurl } from 'components/Apis'


export default function AllUserModal({ data, closeView, refetch }) {
    const [screen, setScreen] = useState(1)
    const [forms, setForms] = useState({
        firstname: data.firstname,
        lastname: data.lastname,
        middlename: data.middlename,
        currbal: data.currbal,
        coinbal: data.coinbal,
        suspended: data.suspended || 'false',
        userid: data.id,
    })
    const [loading, setLoading] = useState(false)

    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }

    const handleUpdate = async e => {
        e.preventDefault()
        setLoading(true)
        try {
            const response = await Authputurl(Apis.user.admin_update_user_info, forms)
            if(response.status !== 200) return ErrorAlert(`${response.msg}`)
                SuccessAlert(response.msg)
            return refetch()
        } catch (error) {
            ErrorAlert(`${error.message}`)
        }finally {
            setLoading(false)
        }
    }
    return (
        <ModalLayout closeView={closeView}>
            {screen === 2 && <>
                <form onSubmit={handleUpdate} className='w-11/12 mx-auto mt-5 mb-10'>
                    <div className="font-bold text-lg mb-3 py-3 border-b px-4">
                        <div className="">Update {data.firstname} Account</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3">
                        <Forminput formName={'firstname'} value={forms.firstname} onChange={handleForms} content="First Name" />
                        <Forminput formName={'middlename'} value={forms.middlename} onChange={handleForms} content="Middle Name" />
                    </div>
                    <div className="grid grid-cols-2 gap-3">
                        <Forminput formName={'lastname'} value={forms.lastname} onChange={handleForms} content="Last Name" />
                        <Forminput formName={'currbal'} value={forms.currbal} onChange={handleForms} content="Current Balance" />
                    </div>
                    <div className="grid grid-cols-2 gap-3">
                        <Forminput formName={'coinbal'} value={forms.coinbal} onChange={handleForms} content="GB Reward balance" />
                        <Forminput formtype='select' formName="suspended" value={forms.suspended} onChange={handleForms} content="Account Suspended">
                            <option value={"true"}>TRUE</option>
                            <option value={"false"}>FALSE</option>
                        </Forminput>
                    </div>
                    <div className="grid grid-cols-2 gap-16 mt-3 pt-3 border-t">
                    <Formbutton handleSubmission={() => setScreen(1)} type="button" bg="bg-slate-400" title="Back" />
                    <Formbutton loading={loading} title="Submit" />
                    </div>
                </form>
            </>}
            {screen === 1 && <>
                <div className="font-bold text-lg mb-3 py-3 border-b px-4">
                    <div className="">User Information</div>
                </div>
                <div className="grid grid-cols-2 gap-5 w-11/12 mx-auto">
                    <div className="border rounded-lg p-2">
                        <div className="font-bold">Full Name</div>
                        <div className="font-light">{data.firstname} {data.lastname}</div>
                    </div>
                    <div className="border rounded-lg p-2">
                        <div className="font-bold">Email</div>
                        <div className="font-light">{data.email}</div>
                    </div>
                    <div className="border rounded-lg p-2">
                        <div className="font-bold">Phone Number</div>
                        <div className="font-light">{data.phone}</div>
                    </div>
                    <div className="border rounded-lg p-2">
                        <div className="font-bold">Last Login</div>
                        <div className="font-light">{data.lastlogin}</div>
                    </div>
                    <div className="border rounded-lg p-2">
                        <div className="font-bold">Current Balance</div>
                        <div className="font-light">{NairaSign}{data.currbal?.toLocaleString()}</div>
                    </div>
                    <div className="border rounded-lg p-2">
                        <div className="font-bold">Previous Balance</div>
                        <div className="font-light">{NairaSign}{data.prevbal?.toLocaleString()}</div>
                    </div>
                    <div className="border rounded-lg p-2">
                        <div className="font-bold">Cashback Balance</div>
                        <div className="font-light">{NairaSign}{data.coinbal?.toLocaleString()}</div>
                    </div>
                    <div className="border rounded-lg p-2">
                        <div className="font-bold">Account Suspended</div>
                        <div className="font-light uppercase">{data.suspended}</div>
                    </div>
                </div>
                <div className="border-t p-3 grid grid-cols-2 mt-5">
                    <div className="">
                        <div className="font-bold">Registeration Date</div>
                        <div className="">{moment(data.createdAt).format('DD-MM-YYYY hh:mma')}</div>
                    </div>
                    <div className="">
                        <Formbutton title="Update" handleSubmission={() => setScreen(2)} />
                    </div>
                </div>
            </>}
        </ModalLayout>
    )
}

