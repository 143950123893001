import React, { useState } from 'react'
import ModalLayout from 'components/utils/ModalLayout'
import Forminput from 'components/utils/Forminput'
import Formbutton from 'components/utils/Formbutton'
import { ErrorAlert, SuccessAlert } from 'components/utils/functions'
import { Apis, Authposturl } from 'components/Apis'

export default function ManageDepartmentModal({ closeView, refetchSignal, single, screenTag, depts }) {
    const [name, setName] = useState(single?.name || '')
    const [loading, setLoading] = useState(false)
    const [screen, setScreen] = useState(1)
    const [forms, setForms] = useState({
        deptid: "",
        userid: ""
    })

    const handleForms = (e) => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }

    const handleAssignment = async (e) => {
        e.preventDefault()
        if (!forms.deptid) return ErrorAlert('Select a department')
        const formdata = {
            deptid: forms.deptid,
            userid: single.id
        }
        setLoading(true)
        try {
            const response = await Authposturl(`${Apis.user.assign_admin_staffs}/assign`, formdata)
            if (response.status === 200) {
                SuccessAlert(`${response.msg}`)
                closeView()
                refetchSignal()
            } else {
                ErrorAlert(`${response.msg}`)
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoading(false)
        }
    }

    const handleSubmission = async (e) => {
        e.preventDefault()
        if (!name) return ErrorAlert('Department name is required')
        setLoading(true)
        try {
            const response = await Authposturl(`${Apis.user.manage_department}/${single?.name ? 'update' : 'create'}`, { name, id: single?.id || "" })
            if (response.status === 200) {
                SuccessAlert(`${response.msg}`)
                closeView()
                refetchSignal()
            } else {
                ErrorAlert(`${response.msg}`)
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoading(false)
        }
    }

    const DeleteDepartment = async () => {
        setLoading(true)
        try {
            const response = await Authposturl(`${Apis.user.manage_department}/delete`, { id: single?.id || "" })
            if (response.status === 200) {
                SuccessAlert(`${response.msg}`)
                closeView()
                refetchSignal()
            } else {
                ErrorAlert(`${response.msg}`)
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoading(false)
        }
    }

    const UnassignStaffCall = async () => {
        setLoading(true)
        try {
            const response = await Authposturl(`${Apis.user.assign_admin_staffs}/unassign`, { userid: single?.id || "" })
            if (response.status === 200) {
                SuccessAlert(`${response.msg}`)
                closeView()
                refetchSignal()
            } else {
                ErrorAlert(`${response.msg}`)
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoading(false)
        }
    }
    return (
        screenTag === 1 ? <ModalLayout closeView={closeView}>
            {screen === 1 && <div className="p-3">
                <div className="font-bold text-lg mb-10 border-b pb-3 flex items-center gap-5">{single?.name ? 'Update Department' : 'Add New Department'}
                    <div className="">
                        {single?.name && <Formbutton padding='py-2 px-7' title="Delete" bg="bg-red-600" type="button" handleSubmission={() => setScreen(2)} />}
                    </div>
                </div>
                <form onSubmit={handleSubmission}>
                    <div className="">Department Name</div>
                    <Forminput
                        onChange={e => setName(e.target.value)}
                        value={name}
                    />
                    <div className="">
                        <Formbutton title="Submit Data" loading={loading} />
                    </div>
                </form>
            </div>}
            {screen === 2 && <div className="p-3">
                <div className="font-bold text-lg mb-6 border-b pb-3 flex items-center gap-5">Delete Department</div>
                <div className="text-center mb-10">Are you sure you want to delete this department?</div>
                <Formbutton title="Delete" bg="bg-red-600" loading={loading} type="button" handleSubmission={DeleteDepartment} />
            </div>}
        </ModalLayout> :
            <ModalLayout closeView={closeView}>
                {screen === 1 && <div className="p-3">
                    <div className="font-bold text-lg mb-10 border-b pb-3 flex items-center gap-5">Assign Staff to a Department
                        <div className="">
                            {single?.staff?.name && <Formbutton padding='py-2 px-7' title="Unassign" bg="bg-red-600" type="button" handleSubmission={() => setScreen(2)} />}
                        </div>
                    </div>
                    <form onSubmit={handleAssignment}>
                        <div className="mb-3">
                            <div className="">Department</div>
                            <Forminput
                                formtype='select'
                                onChange={handleForms}
                                formName={"deptid"}
                                value={forms.deptid}
                            >
                                {depts.map((ele, i) => (
                                    <option value={ele.id} key={i}>{ele.name}</option>
                                ))}
                            </Forminput>
                        </div>
                        <div className="">
                            <Formbutton title="Submit Preference" loading={loading} />
                        </div>
                    </form>
                </div>}
                {screen === 2 && <div className="p-3">
                    <div className="font-bold text-lg mb-6 border-b pb-3 flex items-center gap-5">Unassign Staff</div>
                    <div className="text-center mb-10">Are you sure you want to un-assign <span className="text-blue-600">{single.firstname} {single.lastname}</span> from this department?</div>
                    <Formbutton title="Unassign" bg="bg-red-600" loading={loading} type="button" handleSubmission={UnassignStaffCall} />
                </div>}
            </ModalLayout>
    )
}
