import React, { useRef, useState } from 'react'
import Formbutton from 'components/utils/Formbutton';

const GeneratePin = ({title, buttonText, continueSetup, loading}) => {
  const [pinParts, setPinParts] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef([]);

  const handleSubmission = () => {
    //after validating all fields
    let values = ''
    pinParts.map((ele) => {
      return values += ele
    })
    continueSetup(values) //pass in the data values
  }

  const handleInputChange = (index, value) => {
    const newPinParts = [...pinParts];
    newPinParts[index] = value;

    if (index < 5 && value !== '') {
      inputRefs.current[index + 1].focus();
    }

    setPinParts(newPinParts);
  };

  const handleInputBackspace = (index, event) => {
    if (event.key === 'Backspace' && index > 0 && pinParts[index] === '') {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text/plain').slice(0, 6);
    const newPinParts = pastedText.split('').concat(['', '', '', '', '', '']).slice(0, 6);
    setPinParts(newPinParts);

    for (let i = 0; i < newPinParts.length; i++) {
      if (newPinParts[i]) {
        inputRefs.current[i].value = newPinParts[i];
        if (i < 5) {
          inputRefs.current[i + 1].focus();
        }
      }
    }
  };



  return (
    <div>
      <div className="text-xl mb-4">{title}</div>
      <div className='grid grid-cols-6 gap-3'>
        {pinParts.map((part, index) => (
          <input
            key={index}
            type="password"
            maxLength="1"
            className='input'
            defaultValue={part}
            onChange={(e) => handleInputChange(index, e.target.value)}
            onKeyDown={(e) => handleInputBackspace(index, e)}
            ref={(input) => (inputRefs.current[index] = input)}
            onPaste={(e) => handlePaste(e)}
          />
        ))}
      </div>
      <div className=" mt-10">
        <Formbutton handleSubmission={handleSubmission} title={buttonText} loading={loading} />
      </div>
    </div>
  )
}

export default GeneratePin