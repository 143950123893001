
import React, { useEffect, useState } from 'react'
import UserLayout from 'components/user/UserLayout'
import SelectOptions from 'components/utils/SelectOptions'
import Forminput from 'components/utils/Forminput'
import Formbutton from 'components/utils/Formbutton'
import { ErrorAlert, Errors, NairaSign, SuccessAlert, Swals, generateRandomNumbersAndAlphabets } from 'components/utils/functions'
import { Apis, Authgeturl, Authposturl } from 'components/Apis'
import { useLocation, useNavigate } from 'react-router-dom'
import ConfirmationModal from 'components/utils/ConfirmationModal'
import { SlCheck } from 'react-icons/sl'
import { dispatchUser } from 'app/reducer'
import {useDispatch, useSelector} from 'react-redux'
import ServiceConfirmation from './ServiceConfirmation'

export default function Electric(){
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const {user} = useSelector(state => state.data)
    const dispatch = useDispatch()
    const tag = pathname.split('/')[1]
    const [plans, setPlans] = useState([])
    const [proceed, setProceed] = useState({
        status: false,
        message: ''
    })
    const [loading, setLoading] = useState(false)
    const [loader, setLoader] = useState(false)
    const [single, setSingle] = useState({
        status: false,
        cashback: 0,
        amount: 0,
        paymethod: '',
        service: '',
        data: {},
        product: '',
        amountToPay: 0,
    })
    const [data, setData] = useState({})
    const [forms, setForms] = useState({
        Service: "",
        Serviceid: "",
        Plan: "",
        Number: "",
        Amount: "",
    })
    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }
    useEffect(() => {
        const fetchService = async () => {
            try {
                const response = await Authgeturl(`${Apis.serviceapi.display}/ele`)
                if (response.status === 200) {
                    return setData(response.msg)
                }

            } catch (error) {
                Errors(`${error.message}`)
            } finally {
                //
            }
        }
        fetchService()
    }, [])
    const handlePlan = e => {
        const val = e.target.value
        setForms({...forms, Plan: val })
        const item = data.services.find(ele => ele.planID === val)
        setSingle({
            ...single,
            title: item.name
        })
    }
    const handlePriceFee = e => {
        const val = parseFloat(e)
        if (e.length > 0) {
            const sum = (val * data.tag.charges) / 100 + val
            const priced = (val * data.tag.cashback) / 100
            setSingle({
                ...single,
                amount: sum,
                amountToPay: sum,
                cashback: priced
            })
        } else {
            setSingle({
                ...single,
                status: false,
                amount: '',
                amountToPay: '',
            })
        }
    }

    const handleSubmission = async e => {
        e.preventDefault()
        if (!forms.Service) return ErrorAlert('Select your preferred network')
        if (!forms.Plan) return ErrorAlert('Select a Bundle to purchase')
        if (!forms.Number) return ErrorAlert('Provide a valid phone number')
        if (!proceed.status) {
            setLoading(true)
            try {
                const response = await Authgeturl(`${Apis.serviceapi.validate_electricity}?Plan=${forms.Plan}&Number=${forms.Number}`)
                if (response.msg.status !== "Failed") {
                    setProceed({
                        status: true,
                        message: response.msg.name
                    })
                }else {
                    ErrorAlert(`${response.msg.response}`)
                }
            } catch (error) {
                Errors(`${error.message}`)
            } finally {
                setLoading(false)
            }
        } else {
            const tag = data?.tag?.plans.find(ele => ele.serviceid === forms.Service)
            // const title = ` Kindly confirm your request to purchase ${single.title} ${tag.servicetype} at -${NairaSign}${single.amount?.toLocaleString()}`
            setSingle({
                ...single,
                status: true,
                serv: forms.Serviceid,
                servid: forms.Service,
                pplan: forms.Plan,
                Number: forms.Number,
                Amount: forms.Amount,
                product: `${single.title} ${tag.servicetype}`
            })
        }
    }
    const submitRequest = async (values) => {
        const formbody = {
            ...values
        }
        setLoader(true)
        try {
            const response = await Authposturl(Apis.serviceapi.generate_invoice, formbody)
            if (response.status === 200) {
                const invoicebody = {
                    invoiceid: response.msg
                }
                const result = await Authposturl(Apis.serviceapi.purchase_electric, invoicebody)
                if (result.status === 200) {
                    dispatch(dispatchUser(result.user))
                    Swals('Successful purchase', result.msg, 'success')
                    return navigate('/service_transactions')
                } else {
                    Swals('Error Purchasing Electricity', `${result.msg}`, 'error')
                }
            } else {
                Swals('Error Purchasing Electricity', `${response.msg}`, 'error')
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoader(false)
        }
    }
    const DirectServicePurchase = async (invoiceid) => {
        setLoader(true)
        try {
            const invoicebody = {
                invoiceid
            }
            const result = await Authposturl(Apis.serviceapi.purchase_electric, invoicebody)
            if (result.status === 200) {
                dispatch(dispatchUser(result.user))
                Swals('Successful purchase', result.msg, 'success')
                return navigate('/service_transactions')
            } else {
                Swals('Error Purchasing Electricity', `${result.msg}`, 'error')
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoader(false)
        }
    }

    const handleService = (e) => {
        const tag = e.target.value 
        const finals = data.tag.plans.find(ele => ele.serviceid === tag)
        setForms({
            ...forms,
            Service: finals.serviceid,
            Serviceid: finals.service
        })
    }
    return (
        <UserLayout title="Electricity Purchase">
        {single.status && <ServiceConfirmation
            loading={loader}
            setLoading={setLoader}
            titletag="electricity"
            single={single}
            setSingle={setSingle}
            user={user}
            handleSubmission={submitRequest}
            DirectServicePurchase={DirectServicePurchase}
            closeView={() => setSingle({ ...single, status: false })}
        />}
            <div className="w-11/12 mx-auto">
                <div className="p-3 bg-white">
                    <form onSubmit={handleSubmission}>
                        <div className="relative mb-4">
                            <div className="">Select Meter Type</div>
                            <Forminput value={forms.Service} formName={'Service'} onChange={handleService} formtype='select'>
                                {data.tag?.plans?.map((ele, index) => (
                                    <option value={ele.serviceid} key={index}>{ele.servicetype}</option>
                                ))}
                            </Forminput>
                        </div>
                        <div className="relative mb-4">
                            <div className="">Select Electricity Vendor</div>
                            <Forminput value={forms.Plan} formName={'Plan'} onChange={handlePlan} formtype='select'>
                                {data.services?.map((ele, index) => (
                                    <option value={ele.planID} key={index}>{ele.name}</option>
                                ))}
                            </Forminput>
                        </div>
                        <div className="mb-4">
                            <div className="">Meter Number</div>
                            <Forminput formName={'Number'} value={forms.Number} onChange={handleForms} />
                        </div>
                        <div className="mb-2">
                            <div className="">Amount ({NairaSign})</div>
                            <Forminput onKeyup={e => handlePriceFee(e.target.value)} label={'0.00'} formName={'Amount'} value={forms.Amount} onChange={handleForms} />
                        </div>
                        {proceed.status && <div className="mb-4">
                            <div className="">Verified Meter Holder</div>
                            <div className="flex items-center gap-3 p-3 border rounded-md border-slate-300 bg-green-300/20"> <SlCheck className='text-xl text-green-500' /> {proceed.message}</div>
                        </div>}
                        {!proceed.status ? <Formbutton title="proceed" loading={loading} /> : <Formbutton title="continue" loading={loading} />}
                    </form>
                </div>
            </div>
        </UserLayout>
    )
}


