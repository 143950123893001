
import React, { useState } from 'react'
import { Apis, Authgeturl } from 'components/Apis'
import { NairaSign, SingleServiceStatus } from 'components/utils/functions'
import Spinner from 'components/utils/Spinner'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'
import ALayout from 'components/admin/ALayout'
import Pagination from 'components/utils/Pagination'
import AHeader from 'components/admin/AHeader'
import CashbackWithdrawModal from './CashbackWithdrawModal'
import { useQuery } from '@tanstack/react-query'

const Tableheaders = [
    "User",
    "Amount",
    "Status",
    "Reference",
    "Message",
    "date initiated",
  ]

  export default function ManageCashbackWithdraws() {
    const [query, setQuery] = useSearchParams()
    const [views, setViews] = useState({
        status: false,
        data: {}
    })

    const {data, isLoading, refetch} = useQuery({
        queryKey: ['admin-fetch-all-cashbacks'],
        queryFn: async () => {
            const response = await Authgeturl(`${Apis.mobilenig.all_cashback_withdrawals}?p=${query.get('p') ?? 1}`)
            return response
        }
    })

    const ChangePage = num => {
        setQuery({ ...query, p: num })
    }

    const handleSingle = (val) => {
        setViews({...views, status: true, data: val })
    }
    if (isLoading) return (
        <ALayout title="Cashbacks Transactions">
            <div className="">
                <div className="w-11/12 mx-auto mb-5 text-3xl font-bold">All Cashbacks Transactions</div>
                <div className="mx-auto mt-20 w-fit"> <Spinner /> </div>
            </div>
        </ALayout>
    )
    return (
        <ALayout title="Cashbacks Transactions">
          {views.status &&  <CashbackWithdrawModal 
           data={views.data}
            closeView={() => setViews({...views, status: false})}
            refetch={() => refetch()}
            />}
                <div className="">
                    <div className="mb-5 text-xl font-bold">All Cashbacks Transactions</div>
                    <AHeader
                        total={`${data.data?.length} of ${data.total}`}
                        tag=""
                        url={false}
                    />
                    <div className="mt-10 overflow-x-auto scrollsdown">
                        <table className='table w-full p-3 text-sm bg-white table-auto'>
                            <thead>
                                <tr className='text-white bg-slate-700'>
                                    {Tableheaders.map((ele, i) => (
                                        <td className='p-3 capitalize' key={i}>{ele}</td>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data.data.length > 0 && data.data.map((item, i) => (
                                    <tr
                                        key={i}
                                        className='border-b border-l border-r cursor-pointer'
                                        onClick={() => handleSingle(item)}
                                    >
                                        <td className='p-3 truncate'>{item.owner?.firstname} {item.owner?.lastname}</td>
                                        <td className='p-3'>{NairaSign}{item.amount?.toLocaleString()}</td>
                                        <td className={`p-3 ${SingleServiceStatus(item.status)} uppercase`}>{item.status}</td>
                                        <td className='p-3 uppercase'>{item.ref}</td>
                                        <td className='p-3 truncate'>{item.content?.slice(0, 50)}..</td>
                                        <td className='p-3 truncate'>{moment(item.createdAt).format('DD-MM-YYYY hh:ss A')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                        <Pagination
                            total={data.total}
                            perPage={data.page_size}
                            page={data.p}
                            onChange={ChangePage}
                        />
                </div>
        </ALayout>
    )
}

