export const Banks = [
    {
        "bank_name": "3Line",
        "bank_code": "110005"
    },
    {
        "bank_name": "Ab MFB",
        "bank_code": "090270"
    },
    {
        "bank_name": "Abbey Mortgage Bank",
        "bank_code": "070010"
    },
    {
        "bank_name": "Above Only MFB",
        "bank_code": "090260"
    },
    {
        "bank_name": "Abu MFB",
        "bank_code": "090197"
    },
    {
        "bank_name": "Access Bank Nigeria",
        "bank_code": "000014"
    },
    {
        "bank_name": "Access Yello and Beta",
        "bank_code": "100052"
    },
    {
        "bank_name": "Accessmoney",
        "bank_code": "100013"
    },
    {
        "bank_name": "Accion MFB",
        "bank_code": "090134"
    },
    {
        "bank_name": "Addosser MFB",
        "bank_code": "090160"
    },
    {
        "bank_name": "Adeyemi College Staff MFB",
        "bank_code": "090268"
    },
    {
        "bank_name": "Afekhafe MFB",
        "bank_code": "090292"
    },
    {
        "bank_name": "Ag Mortgage Bank",
        "bank_code": "100028"
    },
    {
        "bank_name": "Al-Barkah MFB",
        "bank_code": "090133"
    },
    {
        "bank_name": "Al-Hayat MFB",
        "bank_code": "090277"
    },
    {
        "bank_name": "Alekun MFB",
        "bank_code": "090259"
    },
    {
        "bank_name": "Alert MFB",
        "bank_code": "090297"
    },
    {
        "bank_name": "Allworkers MFB",
        "bank_code": "090131"
    },
    {
        "bank_name": "Alpha Kapital MFB",
        "bank_code": "090169"
    },
    {
        "bank_name": "Amju Unique MFB",
        "bank_code": "090180"
    },
    {
        "bank_name": "AMML MFB",
        "bank_code": "090116"
    },
    {
        "bank_name": "Apeks MFB",
        "bank_code": "090143"
    },
    {
        "bank_name": "Arise MFB",
        "bank_code": "090282"
    },
    {
        "bank_name": "Aso Savings And Loans",
        "bank_code": "090001"
    },
    {
        "bank_name": "Asset Matrix MFB-WBA",
        "bank_code": "090287"
    },
    {
        "bank_name": "Astrapolaris MFB",
        "bank_code": "090172"
    },
    {
        "bank_name": "Auchi MFB",
        "bank_code": "090264"
    },
    {
        "bank_name": "Baines Credit MFB",
        "bank_code": "090188"
    },
    {
        "bank_name": "Balogun Gambari MFB",
        "bank_code": "090326"
    },
    {
        "bank_name": "Baobab MFB",
        "bank_code": "090136"
    },
    {
        "bank_name": "BC Kash MFB",
        "bank_code": "090127"
    },
    {
        "bank_name": "Boctrust MFB",
        "bank_code": "090117"
    },
    {
        "bank_name": "Bosak MFB",
        "bank_code": "090176"
    },
    {
        "bank_name": "Bowen MFB",
        "bank_code": "090148"
    },
    {
        "bank_name": "Brent Mortgage Bank",
        "bank_code": "070015"
    },
    {
        "bank_name": "Brethren MFB",
        "bank_code": "090293"
    },
    {
        "bank_name": "Brightway MFB",
        "bank_code": "090308"
    },
    {
        "bank_name": "Cellulant",
        "bank_code": "100005"
    },
    {
        "bank_name": "CEMCS MFB",
        "bank_code": "090154"
    },
    {
        "bank_name": "Chikum MFB",
        "bank_code": "090141"
    },
    {
        "bank_name": "CIT MFB",
        "bank_code": "090144"
    },
    {
        "bank_name": "Citibank Nigeria",
        "bank_code": "000009"
    },
    {
        "bank_name": "Consumer MFB",
        "bank_code": "090130"
    },
    {
        "bank_name": "Contec Global Infotech",
        "bank_code": "100032"
    },
    {
        "bank_name": "Coronation Merchant Bank",
        "bank_code": "060001"
    },
    {
        "bank_name": "Covenant",
        "bank_code": "070006"
    },
    {
        "bank_name": "Credit Afrique MFB",
        "bank_code": "090159"
    },
    {
        "bank_name": "Daylight MFB",
        "bank_code": "090167"
    },
    {
        "bank_name": "E-Barcs MFB",
        "bank_code": "090156"
    },
    {
        "bank_name": "Eagle Flight MFB",
        "bank_code": "090294"
    },
    {
        "bank_name": "Eartholeum (Qik Qik)",
        "bank_code": "100021"
    },
    {
        "bank_name": "Ecobank Bank",
        "bank_code": "000010"
    },
    {
        "bank_name": "Ecobank Xpress",
        "bank_code": "100008"
    },
    {
        "bank_name": "EdFin MFB",
        "bank_code": "090310"
    },
    {
        "bank_name": "Ekondo MFB",
        "bank_code": "090097"
    },
    {
        "bank_name": "Emeralds MFB",
        "bank_code": "090273"
    },
    {
        "bank_name": "Empire Trust MFB",
        "bank_code": "090114"
    },
    {
        "bank_name": "Esan MFB",
        "bank_code": "090189"
    },
    {
        "bank_name": "Eso-E MFB",
        "bank_code": "090166"
    },
    {
        "bank_name": "Etranzact",
        "bank_code": "100006"
    },
    {
        "bank_name": "Evangel MFB",
        "bank_code": "090304"
    },
    {
        "bank_name": "Fast MFB",
        "bank_code": "090179"
    },
    {
        "bank_name": "FBNQuest Merchant Bank",
        "bank_code": "060002"
    },
    {
        "bank_name": "FCMB Easy Account",
        "bank_code": "100031"
    },
    {
        "bank_name": "FCT MFB",
        "bank_code": "090290"
    },
    {
        "bank_name": "Federal University Dutse MFB",
        "bank_code": "090318"
    },
    {
        "bank_name": "Fedpoly Nasarawa MFB",
        "bank_code": "090298"
    },
    {
        "bank_name": "FETS (My Wallet)",
        "bank_code": "100001"
    },
    {
        "bank_name": "FFS Microfinance",
        "bank_code": "090153"
    },
    {
        "bank_name": "Fidelity Bank",
        "bank_code": "000007"
    },
    {
        "bank_name": "Fidelity Mobile",
        "bank_code": "100019"
    },
    {
        "bank_name": "Fidfund MFB",
        "bank_code": "090126"
    },
    {
        "bank_name": "Finatrust MFB",
        "bank_code": "090111"
    },
    {
        "bank_name": "First Apple",
        "bank_code": "110004"
    },
    {
        "bank_name": "First Bank of Nigeria",
        "bank_code": "000016"
    },
    {
        "bank_name": "First City Monument Bank",
        "bank_code": "000003"
    },
    {
        "bank_name": "First Gen Mortgage Bank",
        "bank_code": "070014"
    },
    {
        "bank_name": "First Multiple MFB",
        "bank_code": "090163"
    },
    {
        "bank_name": "First Option MFB",
        "bank_code": "090285"
    },
    {
        "bank_name": "First Royal MFB",
        "bank_code": "090164"
    },
    {
        "bank_name": "First Trust Mortgage Bank",
        "bank_code": "090005"
    },
    {
        "bank_name": "First Trust Mortgage Bank",
        "bank_code": "090107"
    },
    {
        "bank_name": "Firstmonie Wallet",
        "bank_code": "100014"
    },
    {
        "bank_name": "Flutterwave Tech Solutions",
        "bank_code": "110002"
    },
    {
        "bank_name": "Fortis MFB",
        "bank_code": "070002"
    },
    {
        "bank_name": "Fortis Mobile",
        "bank_code": "100016"
    },
    {
        "bank_name": "FSDH Merchant Bank",
        "bank_code": "400001"
    },
    {
        "bank_name": "Full Range MFB",
        "bank_code": "090145"
    },
    {
        "bank_name": "Futo MFB",
        "bank_code": "090158"
    },
    {
        "bank_name": "Gashua MFB",
        "bank_code": "090168"
    },
    {
        "bank_name": "Gateway Mortgage Bank",
        "bank_code": "070009"
    },
    {
        "bank_name": "Globus Bank",
        "bank_code": "000027"
    },
    {
        "bank_name": "Glory MFB",
        "bank_code": "090278"
    },
    {
        "bank_name": "Gomoney",
        "bank_code": "100022"
    },
    {
        "bank_name": "Gowans MFB",
        "bank_code": "090122"
    },
    {
        "bank_name": "Greenbank MFB",
        "bank_code": "090178"
    },
    {
        "bank_name": "Greenville MFB",
        "bank_code": "090269"
    },
    {
        "bank_name": "Grooming MFB",
        "bank_code": "090195"
    },
    {
        "bank_name": "GT Mobile Money",
        "bank_code": "100009"
    },
    {
        "bank_name": "Guaranty Trust Bank ( GTB)",
        "bank_code": "000013"
    },
    {
        "bank_name": "Hackman MFB",
        "bank_code": "090147"
    },
    {
        "bank_name": "Haggai Mortgage Bank",
        "bank_code": "070017"
    },
    {
        "bank_name": "Hala MFB",
        "bank_code": "090291"
    },
    {
        "bank_name": "Hasal MFB",
        "bank_code": "090121"
    },
    {
        "bank_name": "Hedonmark",
        "bank_code": "100017"
    },
    {
        "bank_name": "Heritage Bank",
        "bank_code": "000020"
    },
    {
        "bank_name": "Ibile MFB",
        "bank_code": "090118"
    },
    {
        "bank_name": "Ikire MFB",
        "bank_code": "090275"
    },
    {
        "bank_name": "Ikire MFB",
        "bank_code": "090279"
    },
    {
        "bank_name": "Imo State MFB",
        "bank_code": "090258"
    },
    {
        "bank_name": "Imperial Homes Mortgage Bank",
        "bank_code": "100024"
    },
    {
        "bank_name": "Infinity MFB",
        "bank_code": "090157"
    },
    {
        "bank_name": "Infinity Trust Mortgage Bank",
        "bank_code": "070016"
    },
    {
        "bank_name": "Innovectives Kesh",
        "bank_code": "100029"
    },
    {
        "bank_name": "Interswitch",
        "bank_code": "110003"
    },
    {
        "bank_name": "IRL MFB",
        "bank_code": "090149"
    },
    {
        "bank_name": "Itex Integrated Services",
        "bank_code": "090211"
    },
    {
        "bank_name": "Jaiz Bank",
        "bank_code": "000006"
    },
    {
        "bank_name": "Jubilee Life",
        "bank_code": "090003"
    },
    {
        "bank_name": "Kadpoly MFB",
        "bank_code": "090320"
    },
    {
        "bank_name": "KCMB MFB",
        "bank_code": "090191"
    },
    {
        "bank_name": "Kegow",
        "bank_code": "100015"
    },
    {
        "bank_name": "Keystone Bank",
        "bank_code": "000002"
    },
    {
        "bank_name": "Kontagora MFB",
        "bank_code": "090299"
    },
    {
        "bank_name": "Kredi Bank",
        "bank_code": "090380"
    },
    {
        "bank_name": "Kuda MFB",
        "bank_code": "090267"
    },
    {
        "bank_name": "La Fayette MFB",
        "bank_code": "090155"
    },
    {
        "bank_name": "Lagos Building Investment Company",
        "bank_code": "070012"
    },
    {
        "bank_name": "LAPO MFB",
        "bank_code": "090177"
    },
    {
        "bank_name": "Lavender MFB",
        "bank_code": "090271"
    },
    {
        "bank_name": "Lovonus MFB",
        "bank_code": "090265"
    },
    {
        "bank_name": "M-Kudi",
        "bank_code": "100011"
    },
    {
        "bank_name": "Mainstreet MFB",
        "bank_code": "090171"
    },
    {
        "bank_name": "Malachy MFB",
        "bank_code": "090174"
    },
    {
        "bank_name": "Megapraise MFB",
        "bank_code": "090280"
    },
    {
        "bank_name": "Microvis MFB",
        "bank_code": "090113"
    },
    {
        "bank_name": "Midland MFB",
        "bank_code": "090192"
    },
    {
        "bank_name": "Mimoney Intellifin Solutions",
        "bank_code": "100027"
    },
    {
        "bank_name": "Mint-Finex MFB",
        "bank_code": "090281"
    },
    {
        "bank_name": "Money Trust MFB",
        "bank_code": "090129"
    },
    {
        "bank_name": "Moneybox",
        "bank_code": "100020"
    },
    {
        "bank_name": "Mutual Benefits Mifb",
        "bank_code": "090190"
    },
    {
        "bank_name": "Mutual Trust MFB",
        "bank_code": "090151"
    },
    {
        "bank_name": "Nargata MFB",
        "bank_code": "090152"
    },
    {
        "bank_name": "Ndiorah MFB",
        "bank_code": "090128"
    },
    {
        "bank_name": "New Dawn MFB",
        "bank_code": "090205"
    },
    {
        "bank_name": "New Prudential Bank",
        "bank_code": "090108"
    },
    {
        "bank_name": "Nigerian Navy MFB",
        "bank_code": "090263"
    },
    {
        "bank_name": "Nigerian Police Force MFB",
        "bank_code": "070001"
    },
    {
        "bank_name": "Nirsal MFB",
        "bank_code": "090194"
    },
    {
        "bank_name": "Nnew Women MFB",
        "bank_code": "090283"
    },
    {
        "bank_name": "Nova Merchant Bank",
        "bank_code": "060003"
    },
    {
        "bank_name": "Ohafia MFB",
        "bank_code": "090119"
    },
    {
        "bank_name": "Okpoga MFB",
        "bank_code": "090161"
    },
    {
        "bank_name": "Olabisi Onabanjo University MFB",
        "bank_code": "090272"
    },
    {
        "bank_name": "Omiye MFB",
        "bank_code": "090295"
    },
    {
        "bank_name": "Omoluabi Mortgage Bank",
        "bank_code": "070007"
    },
    {
        "bank_name": "One Finance",
        "bank_code": "100026"
    },
    {
        "bank_name": "Paga",
        "bank_code": "100002"
    },
    {
        "bank_name": "Page Financials",
        "bank_code": "070008"
    },
    {
        "bank_name": "Palmpay",
        "bank_code": "100033"
    },
    {
        "bank_name": "Parallex MFB",
        "bank_code": "090004"
    },
    {
        "bank_name": "Parkway-Readycash",
        "bank_code": "100003"
    },
    {
        "bank_name": "Patrickgold MFB",
        "bank_code": "090317"
    },
    {
        "bank_name": "Payattitude Online",
        "bank_code": "110001"
    },
    {
        "bank_name": "Paycom (Opay)",
        "bank_code": "100004"
    },
    {
        "bank_name": "Paystack Payments",
        "bank_code": "110006"
    },
    {
        "bank_name": "Pecantrust MFB",
        "bank_code": "090137"
    },
    {
        "bank_name": "Pennywise MFB",
        "bank_code": "090196"
    },
    {
        "bank_name": "Personal Trust MFB",
        "bank_code": "090135"
    },
    {
        "bank_name": "Petra MFB",
        "bank_code": "090165"
    },
    {
        "bank_name": "Pillar MFB",
        "bank_code": "090289"
    },
    {
        "bank_name": "Platinum Mortgage Bank",
        "bank_code": "070013"
    },
    {
        "bank_name": "Polaris Bank",
        "bank_code": "000008"
    },
    {
        "bank_name": "Polyuwana MFB",
        "bank_code": "090296"
    },
    {
        "bank_name": "Prestige MFB",
        "bank_code": "090274"
    },
    {
        "bank_name": "Providus Bank",
        "bank_code": "000023"
    },
    {
        "bank_name": "Purplemoney MFB",
        "bank_code": "090303"
    },
    {
        "bank_name": "Quickfund MFB",
        "bank_code": "090261"
    },
    {
        "bank_name": "Rahama MFB",
        "bank_code": "090170"
    },
    {
        "bank_name": "Rand Merchant Bank",
        "bank_code": "000024"
    },
    {
        "bank_name": "Refuge Mortgage Bank",
        "bank_code": "070011"
    },
    {
        "bank_name": "Regent MFB",
        "bank_code": "090125"
    },
    {
        "bank_name": "Reliance MFB",
        "bank_code": "090173"
    },
    {
        "bank_name": "Renmoney MFB",
        "bank_code": "090198"
    },
    {
        "bank_name": "Richway MFB",
        "bank_code": "090132"
    },
    {
        "bank_name": "Rolez Bank",
        "bank_code": "090405"
    },
    {
        "bank_name": "Royal Exchange MFB",
        "bank_code": "090138"
    },
    {
        "bank_name": "Rubies MFB",
        "bank_code": "090175"
    },
    {
        "bank_name": "Safe Haven MFB",
        "bank_code": "090286"
    },
    {
        "bank_name": "Safetrust Mortgage Bank",
        "bank_code": "090006"
    },
    {
        "bank_name": "Sagamu MFB",
        "bank_code": "090140"
    },
    {
        "bank_name": "Seed Capital MFB",
        "bank_code": "090112"
    },
    {
        "bank_name": "Sparkle Bank",
        "bank_code": "090325"
    },
    {
        "bank_name": "Stanbic IBTC",
        "bank_code": "000012"
    },
    {
        "bank_name": "Stanbic IBTC @Ease Wal",
        "bank_code": "100007"
    },
    {
        "bank_name": "Standard Chartered Bank",
        "bank_code": "000021"
    },
    {
        "bank_name": "Stanford MFB",
        "bank_code": "090162"
    },
    {
        "bank_name": "Stellas MFB",
        "bank_code": "090262"
    },
    {
        "bank_name": "Sterling Bank",
        "bank_code": "000001"
    },
    {
        "bank_name": "Sulspap MFB",
        "bank_code": "090305"
    },
    {
        "bank_name": "Suntrust Bank",
        "bank_code": "000022"
    },
    {
        "bank_name": "Tagpay",
        "bank_code": "100023"
    },
    {
        "bank_name": "Taj Bank",
        "bank_code": "000026"
    },
    {
        "bank_name": "TCF MFB",
        "bank_code": "090115"
    },
    {
        "bank_name": "TeamApt",
        "bank_code": "110007"
    },
    {
        "bank_name": "Teasy Mobile",
        "bank_code": "100010"
    },
    {
        "bank_name": "Titan Trust Bank",
        "bank_code": "000025"
    },
    {
        "bank_name": "Trident MFB",
        "bank_code": "090146"
    },
    {
        "bank_name": "Trustbanc J6 MFB",
        "bank_code": "090123"
    },
    {
        "bank_name": "Trustfund MFB",
        "bank_code": "090276"
    },
    {
        "bank_name": "UNIBEN MFB",
        "bank_code": "090266"
    },
    {
        "bank_name": "UNICAL MFB",
        "bank_code": "090193"
    },
    {
        "bank_name": "Union Bank of Nigeria",
        "bank_code": "000018"
    },
    {
        "bank_name": "United Bank for Africa (UBA)",
        "bank_code": "000004"
    },
    {
        "bank_name": "Unity Bank",
        "bank_code": "000011"
    },
    {
        "bank_name": "Unn MFB",
        "bank_code": "090251"
    },
    {
        "bank_name": "VFD MFB",
        "bank_code": "090110"
    },
    {
        "bank_name": "Virtue MFB",
        "bank_code": "090150"
    },
    {
        "bank_name": "Visa MFB",
        "bank_code": "090139"
    },
    {
        "bank_name": "Vtnetwork",
        "bank_code": "100012"
    },
    {
        "bank_name": "Wema Bank",
        "bank_code": "000017"
    },
    {
        "bank_name": "Wetland MFB",
        "bank_code": "090120"
    },
    {
        "bank_name": "Xslnce MFB",
        "bank_code": "090124"
    },
    {
        "bank_name": "Yes MFB",
        "bank_code": "090142"
    },
    {
        "bank_name": "Zenith Bank",
        "bank_code": "000015"
    },
    {
        "bank_name": "Zenith Mobile",
        "bank_code": "100018"
    },
    {
        "bank_name": "Zinternet",
        "bank_code": "100025"
    },
    {
        "bank_name": "Moniepoint MFB",
        "bank_code": "090405"
    },
    {
        "bank_name": "FairMoney MFB",
        "bank_code": "090551"
    },
    {
        "bank_name": "9 Payment Service Bank",
        "bank_code": "120001"
    }
  ]
  
  export const BanksLogo = [
    {
    "name": "Access Bank",
    "slug": "access-bank",
    "code": "044",
    "ussd": "*901#",
    "logo": "https://nigerianbanks.xyz/logo/access-bank.png"
    },
    {
    "name": "Access Bank (Diamond)",
    "slug": "access-bank-diamond",
    "code": "063",
    "ussd": "*426#",
    "logo": "https://nigerianbanks.xyz/logo/access-bank-diamond.png"
    },
    {
    "name": "ALAT by WEMA",
    "slug": "alat-by-wema",
    "code": "035A",
    "ussd": "*945*100#",
    "logo": "https://nigerianbanks.xyz/logo/alat-by-wema.png"
    },
    {
    "name": "ASO Savings and Loans",
    "slug": "asosavings",
    "code": "401",
    "ussd": "",
    "logo": "https://nigerianbanks.xyz/logo/asosavings.png"
    },
    {
    "name": "Bowen Microfinance Bank",
    "slug": "bowen-microfinance-bank",
    "code": "50931",
    "ussd": "",
    "logo": "https://nigerianbanks.xyz/logo/default-image.png"
    },
    {
    "name": "CEMCS Microfinance Bank",
    "slug": "cemcs-microfinance-bank",
    "code": "50823",
    "ussd": "",
    "logo": "https://nigerianbanks.xyz/logo/cemcs-microfinance-bank.png"
    },
    {
    "name": "Citibank Nigeria",
    "slug": "citibank-nigeria",
    "code": "023",
    "ussd": "",
    "logo": "https://nigerianbanks.xyz/logo/citibank-nigeria.png"
    },
    {
    "name": "Ecobank Nigeria",
    "slug": "ecobank-nigeria",
    "code": "050",
    "ussd": "*326#",
    "logo": "https://nigerianbanks.xyz/logo/ecobank-nigeria.png"
    },
    {
    "name": "Ekondo Microfinance Bank",
    "slug": "ekondo-microfinance-bank",
    "code": "562",
    "ussd": "*540*178#",
    "logo": "https://nigerianbanks.xyz/logo/ekondo-microfinance-bank.png"
    },
    {
    "name": "Fidelity Bank",
    "slug": "fidelity-bank",
    "code": "070",
    "ussd": "*770#",
    "logo": "https://nigerianbanks.xyz/logo/fidelity-bank.png"
    },
    {
    "name": "First Bank of Nigeria",
    "slug": "first-bank-of-nigeria",
    "code": "011",
    "ussd": "*894#",
    "logo": "https://nigerianbanks.xyz/logo/first-bank-of-nigeria.png"
    },
    {
    "name": "First City Monument Bank",
    "slug": "first-city-monument-bank",
    "code": "214",
    "ussd": "*329#",
    "logo": "https://nigerianbanks.xyz/logo/first-city-monument-bank.png"
    },
    {
    "name": "Globus Bank",
    "slug": "globus-bank",
    "code": "00103",
    "ussd": "*989#",
    "logo": "https://nigerianbanks.xyz/logo/globus-bank.png"
    },
    {
    "name": "Guaranty Trust Bank",
    "slug": "guaranty-trust-bank",
    "code": "058",
    "ussd": "*737#",
    "logo": "https://nigerianbanks.xyz/logo/guaranty-trust-bank.png"
    },
    {
    "name": "Hasal Microfinance Bank",
    "slug": "hasal-microfinance-bank",
    "code": "50383",
    "ussd": "*322*127#",
    "logo": "https://nigerianbanks.xyz/logo/default-image.png"
    },
    {
    "name": "Heritage Bank",
    "slug": "heritage-bank",
    "code": "030",
    "ussd": "*322#",
    "logo": "https://nigerianbanks.xyz/logo/heritage-bank.png"
    },
    {
    "name": "Jaiz Bank",
    "slug": "jaiz-bank",
    "code": "301",
    "ussd": "*389*301#",
    "logo": "https://nigerianbanks.xyz/logo/default-image.png"
    },
    {
    "name": "Keystone Bank",
    "slug": "keystone-bank",
    "code": "082",
    "ussd": "*7111#",
    "logo": "https://nigerianbanks.xyz/logo/keystone-bank.png"
    },
    {
    "name": "Kuda Bank",
    "slug": "kuda-bank",
    "code": "50211",
    "ussd": "",
    "logo": "https://nigerianbanks.xyz/logo/kuda-bank.png"
    },
    {
    "name": "One Finance",
    "slug": "one-finance",
    "code": "565",
    "ussd": "*1303#",
    "logo": "https://nigerianbanks.xyz/logo/default-image.png"
    },
    {
    "name": "Paga",
    "slug": "paga",
    "code": "327",
    "ussd": "",
    "logo": "https://nigerianbanks.xyz/logo/paga.png"
    },
    {
    "name": "Parallex Bank",
    "slug": "parallex-bank",
    "code": "526",
    "ussd": "*322*318*0#",
    "logo": "https://nigerianbanks.xyz/logo/default-image.png"
    },
    {
    "name": "PayCom",
    "slug": "paycom",
    "code": "100004",
    "ussd": "*955#",
    "logo": "https://nigerianbanks.xyz/logo/default-image.png"
    },
    {
    "name": "Polaris Bank",
    "slug": "polaris-bank",
    "code": "076",
    "ussd": "*833#",
    "logo": "https://nigerianbanks.xyz/logo/polaris-bank.png"
    },
    {
    "name": "Providus Bank",
    "slug": "providus-bank",
    "code": "101",
    "ussd": "",
    "logo": "https://nigerianbanks.xyz/logo/default-image.png"
    },
    {
    "name": "Rubies MFB",
    "slug": "rubies-mfb",
    "code": "125",
    "ussd": "*7797#",
    "logo": "https://nigerianbanks.xyz/logo/default-image.png"
    },
    {
    "name": "Sparkle Microfinance Bank",
    "slug": "sparkle-microfinance-bank",
    "code": "51310",
    "ussd": "",
    "logo": "https://nigerianbanks.xyz/logo/sparkle-microfinance-bank.png"
    },
    {
    "name": "Stanbic IBTC Bank",
    "slug": "stanbic-ibtc-bank",
    "code": "221",
    "ussd": "*909#",
    "logo": "https://nigerianbanks.xyz/logo/stanbic-ibtc-bank.png"
    },
    {
    "name": "Standard Chartered Bank",
    "slug": "standard-chartered-bank",
    "code": "068",
    "ussd": "",
    "logo": "https://nigerianbanks.xyz/logo/standard-chartered-bank.png"
    },
    {
    "name": "Sterling Bank",
    "slug": "sterling-bank",
    "code": "232",
    "ussd": "*822#",
    "logo": "https://nigerianbanks.xyz/logo/sterling-bank.png"
    },
    {
    "name": "Suntrust Bank",
    "slug": "suntrust-bank",
    "code": "100",
    "ussd": "*5230#",
    "logo": "https://nigerianbanks.xyz/logo/default-image.png"
    },
    {
    "name": "TAJ Bank",
    "slug": "taj-bank",
    "code": "302",
    "ussd": "*898#",
    "logo": "https://nigerianbanks.xyz/logo/taj-bank.png"
    },
    {
    "name": "TCF MFB",
    "slug": "tcf-mfb",
    "code": "51211",
    "ussd": "*908#",
    "logo": "https://nigerianbanks.xyz/logo/default-image.png"
    },
    {
    "name": "Titan Trust Bank",
    "slug": "titan-trust-bank",
    "code": "102",
    "ussd": "*922#",
    "logo": "https://nigerianbanks.xyz/logo/default-image.png"
    },
    {
    "name": "Union Bank of Nigeria",
    "slug": "union-bank-of-nigeria",
    "code": "032",
    "ussd": "*826#",
    "logo": "https://nigerianbanks.xyz/logo/union-bank-of-nigeria.png"
    },
    {
    "name": "United Bank For Africa",
    "slug": "united-bank-for-africa",
    "code": "033",
    "ussd": "*919#",
    "logo": "https://nigerianbanks.xyz/logo/united-bank-for-africa.png"
    },
    {
    "name": "Unity Bank",
    "slug": "unity-bank",
    "code": "215",
    "ussd": "*7799#",
    "logo": "https://nigerianbanks.xyz/logo/default-image.png"
    },
    {
    "name": "VFD",
    "slug": "vfd",
    "code": "566",
    "ussd": "",
    "logo": "https://nigerianbanks.xyz/logo/default-image.png"
    },
    {
    "name": "Wema Bank",
    "slug": "wema-bank",
    "code": "035",
    "ussd": "*945#",
    "logo": "https://nigerianbanks.xyz/logo/wema-bank.png"
    },
    {
    "name": "Zenith Bank",
    "slug": "zenith-bank",
    "code": "057",
    "ussd": "*966#",
    "logo": "https://nigerianbanks.xyz/logo/zenith-bank.png"
    }
    ]