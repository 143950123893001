
import React, { useState } from 'react'
import ModalLayout from 'components/utils/ModalLayout'
import Forminput from 'components/utils/Forminput'
import Formbutton from 'components/utils/Formbutton'
import { ErrorAlert, SuccessAlert } from 'components/utils/functions'
import { Apis, Authposturl, Authputurl } from 'components/Apis'

const PlanTitles = [
    { title: 'bronze', content: 'Bronze' },
    { title: 'leader', content: 'Leader' },
    { title: 'elite', content: 'Elite' },
]

const PlanStatus = ["true", "false"]


export default function AccountPlanModal({ data, closeView, refetch }) {
    const [loading, setLoading] = useState(false)
    const [forms, setForms] = useState({
        title: data?.title || '',
        fee: data?.fee || '',
        directDiscount: data?.directDiscount || '',
        airtimeDiscount: data?.airtimeDiscount || '',
        dataDiscount: data?.dataDiscount || '',
        com1data: data?.com1data || '',
        com1airtime: data?.com1airtime || '',
        com2airtime: data?.com2airtime || '',
        com2data: data?.com2data || '',
        com3airtime: data?.com3airtime || '',
        com3data: data?.com3data || '',
        status: data?.status || 'true',
    })
    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }
    const handleSubmission = async e => {
        e.preventDefault()
        console.log(forms)
        if (!forms.title) return ErrorAlert(`Select an account plan`)
        if (!forms.fee) return ErrorAlert(`provide registration fee`)
        if (!forms.directDiscount) return ErrorAlert(`provide discount for registration`)
        if (forms.title === PlanTitles[2].title && !forms.airtimeDiscount) return ErrorAlert(`provide discount on airtime purchase`)
        if (forms.title === PlanTitles[2].title && !forms.dataDiscount) return ErrorAlert(`provide discount on data purchase`)
        if (!forms.com1airtime) return ErrorAlert(`provide percentage from first commission on Airtime`)
        if (!forms.com1data) return ErrorAlert(`provide percentage from first commission on Data`)
        if (!forms.com2airtime) return ErrorAlert(`provide percentage from second commission on Airtime`)
        if (!forms.com2data) return ErrorAlert(`provide percentage from second commission on Data`)
        if (!forms.com3airtime) return ErrorAlert(`provide percentage from third commission on Airtime`)
        if (!forms.com3data) return ErrorAlert(`provide percentage from third commission on Data`)
        setLoading(true)
        try {
            const response = !data?.id ? await Authposturl(Apis.user.create_account_plan, forms) : await Authputurl(`${Apis.user.update_account_plan}/${data.id}`, forms)
            if (response.status !== 200) return ErrorAlert(`${response.message}`)
            closeView()
            SuccessAlert(`${response.message}`)
            return refetch()
        } catch (error) {
            ErrorAlert(`${error}`)
        } finally {
            setLoading(false)
        }
    }

    return (
        <ModalLayout closeView={closeView}>
            <div className="font-bold text-lg mb-3 py-3 border-b px-4">
                <div className="">{data?.id ? 'Update Account Plan' : 'Create Account Plan'}</div>
            </div>
            <form onSubmit={handleSubmission} className='p-4'>
                <div className="">
                    <div className="">Account Plan</div>
                    <Forminput onChange={handleForms} formName={'title'} value={forms.title} formtype='select'>
                        {PlanTitles.map((item, index) => (
                            <option value={item.title} key={index}>{item.content}</option>
                        ))}
                    </Forminput>
                </div>
                <div className="">Registration Fee</div>
                <Forminput label="Fee" onChange={handleForms} value={forms.fee} formName="fee" />
                <div className="">Direct discount for registration (%)</div>
                <Forminput label="Direct Discount (%)" onChange={handleForms} value={forms.directDiscount} formName="directDiscount" />
                {forms.title === PlanTitles[2].title && <div className="grid grid-cols-2 gap-5">
                    <div className="">
                        <div className="">Discount on Airtime (%)</div>
                        <Forminput label="Airtime Discount (%)" onChange={handleForms} value={forms.airtimeDiscount} formName="airtimeDiscount" />
                    </div>
                    <div className="">
                        <div className="">Discount on Data (%)</div>
                        <Forminput label="Data Discount (%)" onChange={handleForms} value={forms.dataDiscount} formName="dataDiscount" />
                    </div>
                </div>}
                <div className="">Discount on first commission</div>
                <div className="grid grid-cols-2 gap-5">
                    <Forminput content="Airtime" label="Commission 1 Airtime (%)" onChange={handleForms} value={forms.com1airtime} formName="com1airtime" />
                    <Forminput content="Data" label="Commission 1 Data (%)" onChange={handleForms} value={forms.com1data} formName="com1data" />
                </div>
                <div className="">Discount on second commission</div>
                <div className="grid grid-cols-2 gap-5">
                    <Forminput content="Airtime" label="Commission 2 Airtime (%)" onChange={handleForms} value={forms.com2airtime} formName="com2airtime" />
                    <Forminput content="Data" label="Commission 2 Data (%)" onChange={handleForms} value={forms.com2data} formName="com2data" />
                </div>
                <div className="">Discount on third commission</div>
                <div className="grid grid-cols-2 gap-5">
                    <Forminput content="Airtime" label="Commission 3 Airtime (%)" onChange={handleForms} value={forms.com3airtime} formName="com3airtime" />
                    <Forminput content="Data" label="Commission 3 Data (%)" onChange={handleForms} value={forms.com3data} formName="com3data" />
                </div>
                <div className="">
                    <div className="">Account Status</div>
                    <Forminput onChange={handleForms} formName={'status'} value={forms.status} formtype='select'>
                        {PlanStatus.map((item, index) => (
                            <option value={item} key={index}>{item}</option>
                        ))}
                    </Forminput>
                </div>
                <Formbutton title="Submit" loading={loading} />
            </form>
        </ModalLayout>
    )
}

