

import React, { useState } from 'react'
import { ErrorAlert, Errors, NairaSign, SuccessAlert } from 'components/utils/functions'
import Formbutton from 'components/utils/Formbutton'
import Forminput from 'components/utils/Forminput'
import { useDispatch, useSelector } from 'react-redux'
import { Apis, Authposturl } from 'components/Apis'
import { dispatchPlan, dispatchUser } from 'app/reducer'

const StockDeposit = ({handleScreen}) => {
    const [amount, setAmount] = useState('')
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const {plan, user} = useSelector(state => state.data)

    const SpendCash = async () => {
        if (!amount) return ErrorAlert('provide amount to spend')
        const data = {
            // amount: amount,
            amt: amount,
            stock: plan.id
        }
        setLoading(true)
        try {
            const res = await Authposturl(Apis.investments.topup_stock_account, data)
            // const res = await Authposturl(Apis.investments.topup_stock_account, data)
            if (res.status === 200) {
                SuccessAlert(res.msg)
                setAmount('')
                dispatch(dispatchPlan(res.wallet))
                dispatch(dispatchUser(res.user))
                handleScreen()
            } else {
                ErrorAlert(res.msg)
            }
        } catch (error) {
            Errors(error)
        } finally {
            setLoading(false)
        }
    }
    return (
        <div className='mb-6 rounded-lg p-3'>
            <div className="mb-3">
                <div className="text-sm">Amount to spend ({NairaSign})</div>
                <Forminput
                    value={amount}
                    onChange={e => setAmount(e.target.value)}
                    label={'0.00'}
                    type="number"
                />
                <div className='flex items-center -mt-5 mb-5 justify-between gap-5 text-sm text-slate-500'>Available Account balance: <span className='text-black font-semibold text-lg'>{NairaSign}{user.currbal?.toLocaleString()}</span> </div>
            </div>
            <div className="">
                <Formbutton
                    handleSubmission={SpendCash}
                    loading={loading}
                    title={'spend'}
                />
            </div>
        </div>
    )
}

export default StockDeposit