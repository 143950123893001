import UserLayout from 'components/user/UserLayout'
import { Apis, Authgeturl } from 'components/Apis'
import { NairaSign, SingleServiceStatus } from 'components/utils/functions'
import { useParams } from 'react-router-dom'
import Spinner from 'components/utils/Spinner'
import Backbutton from 'components/utils/Backbutton'
import { useQuery } from '@tanstack/react-query'

export default function STransactionPage () {
    const {id} = useParams()

    const {data, isLoading} = useQuery({
        queryKey: ['mobile-nig-single-transaction'],
        queryFn: async () => {
            const response = await Authgeturl(`${Apis.mobilenig.single_transaction}/${id}`)
            return response.msg
        }
    })
    if(isLoading) return (
        <UserLayout title="">
            <Spinner />
        </UserLayout>
        )
  return (
    <UserLayout title={data.action}>
        <div className="pl-5 mb-5">
            <Backbutton link="/service_transactions" />
            <div className="text-2xl font-bold">Transaction Details</div>
        </div>
        <div className={` p-4 border-l-8  ${SingleServiceStatus(data.status)}`}>
            <div className="grid grid-cols-2 gap-5 p-2">
                <div className="font-bold">Purchase:</div>
                <div className="text-right ">{data.action === 'ELE' ? 'Electricity' : data.action}</div>
            </div>
            <div className="grid grid-cols-2 gap-5 p-2">
                <div className="font-bold">Status:</div>
                <div className="text-right ">{data.status}</div>
            </div>
            <div className="grid grid-cols-2 gap-5 p-2">
                <div className="font-bold">Amount:</div>
                <div className="text-right ">{NairaSign}{data.amount?.toLocaleString()}</div>
            </div>
            <div className="grid grid-cols-2 gap-5 p-2">
                <div className="font-bold">Pre. Balance:</div>
                <div className="text-right ">{NairaSign}{data.prebal?.toLocaleString()}</div>
            </div>
            <div className="grid grid-cols-2 gap-5 p-2">
                <div className="font-bold">Post Balance:</div>
                <div className="text-right ">{NairaSign}{data.postbal?.toLocaleString()}</div>
            </div>
            <div className="grid grid-cols-2 gap-5 p-2">
                <div className="font-bold">Payment Method:</div>
                <div className="text-right uppercase">{data.paymethod}</div>
            </div>
           {data.cashback && <div className="grid grid-cols-2 gap-5 p-2">
                <div className="font-bold">Cashback:</div>
                <div className="text-right ">{NairaSign}{data.cashback}</div>
            </div>}
           {data.token && <div className="grid grid-cols-2 gap-5 p-2">
                <div className="font-bold">Token:</div>
                <div className="text-right ">{data.token}</div>
            </div>}
            <div className="grid grid-cols-2 gap-5 p-2">
                <div className="font-bold">Description:</div>
                <div className="text-sm text-right">{data.desc}</div>
            </div>
            <div className="grid grid-cols-2 gap-5 p-2 break-words">
                <div className="font-bold">Reference:</div>
                <div className="text-sm text-right">{data.ref}</div>
            </div>
            <div className="grid grid-cols-2 gap-5 p-2 break-words">
                <div className="font-bold">Date Initiated:</div>
                <div className="text-sm text-right">{data.date} {data.time}</div>
            </div>
        </div>
    </UserLayout>
  )
}

