import { useEffect, useState } from "react";
import { Apis, SocketConnect, geturl } from "components/Apis";
import ChatForm from "../Support/ChatForm";
import ChatMessages from "../Support/ChatMessages";
import { Errors, ScrollChatBottom } from "components/utils/functions";


export default function ChatComponent({data, email, usertype, sendSignal}) {
    const [items, setItems] = useState(data || {})
    const [showForms, setShowForms] = useState(data?.active === 'yes' ? true : false)


    useEffect(() => {
        if(items.agent === null) {
            if(items.messages?.length < 1) {
                //
            }
        }
        ScrollChatBottom()
    }, [items])


    const GetRoomMessages = async (id) => {
        try {
            const response = await geturl(`${Apis.feeds.room_messages}/${id}`)
            if(response.status === 200) {
                 setItems(response.msg)
                 return ScrollChatBottom()
            }
        } catch (error) {
            Errors(`${error.message}`)
        }
    }
    useEffect(() => {
        SocketConnect.on('chat-sent', (value) => {
            const getId = value.roomid.replace('room-', '')
            GetRoomMessages(parseInt(getId))
            ScrollChatBottom()
        })
        SocketConnect.on('disconnected-room', (value) => {
            const getId = value.roomid.replace('room-', '')
            GetRoomMessages(parseInt(getId))
            sendSignal()
            setShowForms(false)
            ScrollChatBottom()
        })
    }, [SocketConnect])

    const sendMessage = val => {
        const data = {
            roomid: `room-${val.roomid}`
        }
        SocketConnect.emit('send-chat-message', data)
        SocketConnect.emit('user-not-typing')
        GetRoomMessages(val.roomid)
        sendSignal(val.roomid)
    }
    return (
        <>
            <div className="h-[83dvh] bg-white lg:h-[69.8dvh] pb-5 pt-3 border-b overflow-y-auto msgbox scrolls overflow-x-hidden px-3">
                <ChatMessages messages={items} />
            </div>
           {showForms && <ChatForm roomid={data?.id} email={email} sendMessage={sendMessage} usertype={usertype} />}
        </>
    )
}

