import React, { useRef, useState } from 'react'
import { SlCloudUpload, SlPencil, SlTrash } from 'react-icons/sl'
import ReactQuill from 'react-quill'
import { EditorModules, ErrorAlert, Errors, NairaSign, SuccessAlert } from 'components/utils/functions'
import Forminput from 'components/utils/Forminput'
import Formbutton from 'components/utils/Formbutton'
import { FaArrowLeft, FaTimes } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import { Apis, Authgeturl, Authposturl, Authputurl } from 'components/Apis'
import Loading from 'components/utils/Loading'
import { useQuery } from '@tanstack/react-query'
import ShortletAddons from './ShortletAddons'
import Imaged from 'components/utils/Imaged'

const ShortletForm = ({ itemdata, imgs }) => {
    const [images, setImages] = useState(imgs?.length > 0 ? imgs : [])
    const [cats, setCats] = useState([])
    const [description, setDescription] = useState(itemdata?.description || '')
    const [loading, setLoading] = useState(false)
    const [contents, setContents] = useState(itemdata?.addons || [])
    const [loads, setLoads] = useState(false)
    const [single, setSingle] = useState({})
    const imgref = useRef()
    const navigate = useNavigate()
    const [forms, setForms] = useState({
        title: itemdata?.title || '',
        category: itemdata?.category || '',
        price: itemdata?.price || '',
        pricetag: itemdata?.pricetag || '',
        country: itemdata?.country || '',
        pickup: itemdata?.pickup || '',
        state: itemdata?.state || '',
        discount: itemdata?.discount || '',
        aval: itemdata?.aval || '',
    })
    const [pickupform, setPickupform] = useState({
        title: '',
        price: '',
        content: '',
        id: ''
    })
    const [pickups, setPickups] = useState(itemdata?.levels || [])

    const handlePickupForm = e => {
        setPickupform({
            ...pickupform,
            [e.target.name]: e.target.value
        })
    }

    const handleForms = e => {
        setForms({
            ...forms,
            [e.target.name]: e.target.value
        })
    }

    const AddPickupLevel = () => {
        if (!pickupform.title) return ErrorAlert(`title field is required`)
        if (!pickupform.price) return ErrorAlert(`price fee field is required`)
        if (!pickupform.content) return ErrorAlert(`content field is required`)
        const item = pickups.find(ele => ele.title === pickupform.title)
        if (!single?.title) {
            if (item) return ErrorAlert('Pickup level with title already exists')
            setPickups([...pickups, pickupform])
            setPickupform({
                title: '',
                price: '',
                content: ''
            })
        } else {
            const updates = pickups.map((ele) => {
                if(ele.title === single.title) {
                    return {
                       ...ele,
                        title: pickupform.title,
                        price: pickupform.price,
                        content: pickupform.content
                    }
                }
                return ele
            })
            setPickups(updates)
            setSingle({})
            setPickupform({
                title: '',
                price: '',
                content: ''
            })
        }
    }

    const DeletePickupLevel = (title) => {
        const item = pickups.find(ele => ele.title === title)
        if (item) {
            const filtered = pickups.filter(ele => ele.title !== title)
            setPickups(filtered)
        }
        setSingle({})
    }

    const UpdatePickupLevel = (item) => {
        setSingle(item)
        setPickupform({
            title: item.title,
            price: item.price,
            content: item.content,
        })
    }

    const handleFileUpload = async (e) => {
        const file = e.target.files[0]
        if (!file.type.startsWith('image/')) { imgref.current = null; return ErrorAlert(`Image must not be more than 1MB`) }
        setLoads(true)
        try {
            const formdata = new FormData()
            formdata.append('image', file)
            const response = await Authposturl(Apis.feeds.upload_shortlet_image, formdata)
            if (response.status === 200) {
                const imageData = {
                    name: response.msg,
                    img: URL.createObjectURL(file)
                }
                setImages([...images, imageData])
                imgref.current = null
            } else {
                ErrorAlert(`${response.msg}`)
            }
        } catch (error) {
            Errors(`${error.message}`)
        } finally {
            setLoads(false)
        }
    }
    const DeleteImage = async (item, i) => {
        const findImage = images.find((ele, index) => index === i)
        if (findImage) {
            const filterOut = images.filter((ele, index) => index !== i)
            setLoads(true)
            try {
                const data = {
                    image: item.image
                }
                const response = await Authposturl(Apis.feeds.delete_shortlet_image, data)
                if (response.status === 200) {
                    SuccessAlert(response.msg)
                    setImages(filterOut)
                } else {
                    ErrorAlert(`${response.msg}`)
                }
            } catch (error) {
                Errors(`${error.message}`)
            } finally {
                setLoads(false)
            }
        }
    }
    const valed = useQuery({
        queryKey: [`in-create-shortlet`],
        queryFn: async () => {
            const res = await Authgeturl(Apis.feeds.all_category)
            if (res.status === 200) {
                setCats(res.msg)
                return res.msg
            }
        }
    })

    const handleSubmission = async (e) => {
        e.preventDefault()
        if (!forms.title) return ErrorAlert('Title is required')
        if (!forms.category) return ErrorAlert('Category is required')
        if (!forms.price) return ErrorAlert('Pricing is required')
        if (!forms.discount) return ErrorAlert('Pricing Discount is required')
        if (!forms.pricetag) return ErrorAlert('Pricing Tag is required')
        if (!forms.country) return ErrorAlert('Country is required')
        if (!forms.state) return ErrorAlert('State is required')
        if (!forms.aval) return ErrorAlert('Shortlet availability is required')
        if (!description) return ErrorAlert('Description is required')
        if (images.length < 1) return ErrorAlert('Image is required')
        if(contents.length < 1) return ErrorAlert('Shortlet inclusions are required')

        const imgArr = []
        if (images.length > 0) {
            images.map((item) => {
                if (item.name !== null) {
                    const data = {
                        id: item?.id ? item.id : '',
                        name: item.name
                    }
                    return imgArr.push(data)
                }
                return item
            })
        }
        const formdata = {
            ...forms,
            inclusions: contents,
            pickups,
            description: description,
            media: imgArr,
            id: itemdata?.id ? itemdata?.id : ''
        }
        setLoading(true)
        try {
            const response = itemdata?.id ? await Authputurl(Apis.feeds.update_shortlet, formdata) : await Authposturl(Apis.feeds.create_shortlet, formdata)
            if (response.status === 200) {
                setForms({
                    title: '',
                    pickupfee: 0,
                    category: '',
                    price: '',
                    pricetag: '',
                    country: '',
                    state: '',
                })
                setDescription('')
                setImages([])
                SuccessAlert(response.msg)
                navigate('/admin/shortlets')
            } else {
                ErrorAlert(`${response.msg}`)
            }
        } catch (error) {
            Errors(`${error}`)
        } finally {
            setLoading(false)
        }
    }
    return (
        <div>
            <div className="p-3">
                <Link to="/admin/shortlets" className="flex items-center gap-3">
                    <FaArrowLeft /> Back
                </Link>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-20">
                <div>
                    <div className="bg-white h-fit p-3 rounded-lg relative">
                        {loads && <Loading />}
                        <div className="py-3 mb-3 border-b font-bold">Upload Images</div>
                        {images.length < 5 && <div className="mb-3">
                            <label className='cursor-pointer'>
                                <div className="bg-slate-200 rounded-lg flex items-center justify-center h-44 flex-col gap-3">
                                    <SlCloudUpload /> <span className="">Uplaod Image ({images.length}/5)</span>
                                </div>
                                <input ref={imgref} onChange={handleFileUpload} type="file" hidden />
                            </label>
                        </div>}
                        <div className="w-full overflow-x-auto scrollsdown">
                            <div className="w-fit flex items-center gap-3">
                                {images.map((item, i) => (
                                    <div className="w-44 h-44 relative" key={i}>
                                        <div
                                            onClick={() => DeleteImage(item, i)}
                                            className="absolute top-0 left-0 w-full bg-black/50 text-white text-xl p-2 cursor-pointer flex justify-end">
                                            <FaTimes />
                                        </div>
                                        <Imaged src={item.img} alt="" className="w-44 h-44 object-cover" />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="border-t pt-3 mt-3">
                            <ShortletAddons contents={contents} setContents={setContents} />
                        </div>
                    </div>
                    <div className="bg-white p-3 rounded-lg mt-5 h-fit">
                        <div className="mb-3">
                            <div className="">Title</div>
                            <Forminput
                                type="text"
                                formName="title"
                                value={forms.title}
                                label="Title"
                                onChange={handleForms} />
                        </div>
                        <div className="">
                            <div className="">Category</div>
                            <Forminput
                                formtype='select'
                                formName="category"
                                value={forms.category}
                                onChange={handleForms}>
                                {cats.map((item, i) => (
                                    <option value={item.id} key={i}>{item.title}</option>
                                ))}
                            </Forminput>
                        </div>
                        <div className="">
                            <div className="">Pricing</div>
                            <Forminput
                                type="number"
                                formName="price"
                                value={forms.price}
                                label="Pricing"
                                onChange={handleForms} />
                        </div>
                        <div className="">
                            <div className="">Discount (%)</div>
                            <Forminput
                                type="number"
                                formName="discount"
                                value={forms.discount}
                                label="Discount"
                                onChange={handleForms} />
                        </div>
                        <div className="">
                            <div className="">Price Tag</div>
                            <Forminput
                                type="text"
                                formName="pricetag"
                                value={forms.pricetag}
                                label="Price Tag"
                                onChange={handleForms} />
                        </div>
                        <div className="">
                            <div className="">Availability</div>
                            <Forminput
                                formtype='select'
                                formName="aval"
                                value={forms.aval}
                                label="Availability"
                                onChange={handleForms}>
                                <option value="yes">YES</option>
                                <option value="no">NO</option>
                            </Forminput>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="bg-white p-3 rounded-lg h-fit">
                        <div className="grid grid-cols-2 gap-3">
                            <div className="">
                                <div className="">Country</div>
                                <Forminput
                                    type="text"
                                    formName="country"
                                    value={forms.country}
                                    label="Country"
                                    onChange={handleForms} />
                            </div>
                            <div className="">
                                <div className="">State</div>
                                <Forminput
                                    type="text"
                                    formName="state"
                                    value={forms.state}
                                    label="State"
                                    onChange={handleForms} />
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="">Description</div>
                            <ReactQuill
                                theme="snow"
                                value={description}
                                onChange={setDescription}
                                modules={EditorModules}
                            />
                        </div>
                        <div className="">
                            <div className="">Require Pickup</div>
                            <Forminput
                                formtype='select'
                                formName="pickup"
                                value={forms.pickup}
                                onChange={handleForms}>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </Forminput>
                        </div>
                        {forms.pickup === 'yes' && <>
                            <div className="border-b pb-3">Provide pickup levels</div>
                            <div className="">
                                <div className="">
                                    <div className="">Title</div>
                                    <Forminput
                                        type="text"
                                        formName="title"
                                        value={pickupform.title}
                                        onChange={handlePickupForm} />
                                </div>
                                <div className="">
                                    <div className="">Pickup Fee</div>
                                    <Forminput
                                        type="number"
                                        formName="price"
                                        value={pickupform.price}
                                        onChange={handlePickupForm} />
                                </div>
                                <div className="">
                                    <div className="">Details (Needed to let the customers know why they will need this)</div>
                                    <Forminput
                                        formtype='textarea'
                                        formName="content"
                                        value={pickupform.content}
                                        onChange={handlePickupForm} />
                                </div>
                                <div className="w-fit ml-auto">
                                    <button
                                        onClick={AddPickupLevel}
                                        type="button"
                                        className="bg-main text-white py-3 px-5 rounded-lg text-sm capitalize">{single?.title ? 'Update level' : 'add level'}</button>
                                </div>
                            </div>
                            <div className="border-b">Summary on pickup levels</div>
                            <div className="">
                                {pickups.length > 0 && pickups.map((ele, index) => (
                                    <div className="p-2 border rounded-lg mb-1" key={index}>
                                        <div className="grid grid-cols-6">
                                            <div className="col-span-4">
                                                <div className="font-bold text-sm">{ele.title}</div>
                                                <div className="font-bold text-sm">{NairaSign}{ele.price}</div>
                                                <div className="text-sm">{ele.content.slice(0, 30)}</div>
                                            </div>
                                            <div className="col-span-2">
                                                <div className="flex items-center gap-5 justify-end h-full">
                                                    <button
                                                        onClick={() => UpdatePickupLevel(ele)}
                                                        className="rounded-full p-2 border" type="button"> <SlPencil /> </button>
                                                    <button
                                                        onClick={() => DeletePickupLevel(ele.title)}
                                                        className="rounded-full p-2 border" type="button"> <SlTrash /> </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>}
                    </div>
                    <div className=" mt-10">
                        <Formbutton
                            font={'text-sm py-3 px-5 capitalize'}
                            loading={loading}
                            title={`${itemdata?.id ? 'update feed' : 'create feed'}`}
                            handleSubmission={handleSubmission}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShortletForm
