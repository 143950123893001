
import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment';
import AHeader from 'components/admin/AHeader';
import Spinner from 'components/utils/Spinner';
import { Errors, NairaSign } from 'components/utils/functions';
import { Apis, Authgeturl } from 'components/Apis';
import ShareholdersModal from './ShareholdersModal';
import ALayout from 'components/admin/ALayout';

const Tableheaders = [
  "Full Name",
  "Certificate No.",
  "Total Shares",
  "Total Share Amount",
  "Approved",
  "date Issued",
]

export default function ManageShareholders() {
  const [plans, setPlans] = useState([])
  const [loading, setLoading] = useState(true)
  const [asset, setAsset] = useState({})
  const [single, setSingle] = useState({})
  const [view, setView] = useState(false)

  const FetchData = useCallback(async () => {
    setLoading(true)
      try {
          const res = await Authgeturl(Apis.shares.all_shareholders)
          if (res.status === 200) {
            setPlans(res.msg)
            setAsset(res.asset)
          }
      } catch (error) {
          Errors(`${error}`)
      } finally {
          setLoading(false)
      }
  }, [])
  useEffect(() => { FetchData() }, [FetchData])

  const handleSingle = val => {
      setSingle(val)
      setView(!view)
  }
  return (
    <ALayout>
        {/* {view &&  <ShareholdersModal 
        closeView={() => setView(!view)}
        refetch={() => FetchData()}
        pageData={single}
        asset={asset}
        /> } */}
        <div className="">
            <div className="mb-5 text-xl font-bold">Shares Holders</div>
            <AHeader
                tagtitle="allot Share holders"
                total={plans.length}
                tag=""
                url={false}
            />
            <div className="mt-10">
                {!loading && <table className='table w-full p-3 text-sm bg-white table-auto'>
                    <thead>
                        <tr className='text-white bg-slate-700'>
                            {Tableheaders.map((ele, i) => (
                                <td className='p-3 capitalize' key={i}>{ele}</td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {plans.length > 0 && plans.map((item, i) => (
                            <tr 
                            key={i}
                            className='border-b border-l border-r cursor-pointer'
                            onClick={() => handleSingle(item)}
                            >
                                <td className='p-3'>{item.firstname} {item.lastname}</td>
                                <td className='p-3'>{item.certno || '--'}</td>
                                <td className='p-3'>{item.totalshares || '--'}</td>
                                <td className='p-3'>{NairaSign}{item.paid || '--'}</td>
                                <td className='p-3 uppercase'>{item.approved}</td>
                                <td className='p-3'>{moment(item.dateissued).format('DD-MM-YYYY hh:ss A')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>}
                {loading && <Spinner /> }
            </div>
        </div>
    </ALayout>
  )
}


