import React, { useEffect, useRef } from 'react'
import { BiLogoWhatsapp } from 'react-icons/bi'
import { SlEnvolope, SlPhone, SlUser } from 'react-icons/sl'

export default function InvestManager({ closeView, manager }) {
    const togref = useRef()
    useEffect(() => {
        if (togref) {
            window.addEventListener('click', e => {
                togref.current !== null && !togref.current.contains(e.target) && closeView()
            }, true)
        }
    })
    return (
        <div ref={togref} className={`fixed top-32 right-10 shadow-2xl foil bg-white pt-2 rounded-lg min-w-[10rem]`}>
            <div className="px-2 flex-col bg-slate-200 w-11/12 mx-auto text-sm mb-3 cursor-pointer transition-all rounded-lg flex items-center justify-center py-3">
                <div className="border border-zinc-400 rounded-full p-3"> <SlUser className='text-3xl' /> </div>
                <div className="font-medium border-t pt-2 mt-2 border-zinc-400">{manager?.name}</div>
            </div>
            <div className="p-2.5 text-sm hover:bg-slate-100 cursor-pointer transition-all rounded-lg">
                <a href={`https://wa.me/${manager?.dailcode}${manager?.whatsapp}`} target="_blank" className="flex items-center gap-1 text-green-400 text-xs">
                    <BiLogoWhatsapp className='text-xl' /> Whatsapp Chat
                </a>
            </div>
            <div className="p-2.5 text-sm hover:bg-slate-100 cursor-pointer transition-all rounded-lg">
                <a href={`tel:${manager?.phone}`} target="_blank" className="flex items-center gap-1 text-blue-400 text-xs">
                    <SlPhone className='text-lg' /> Direct call
                </a>
            </div>
            <div className="p-2.5 text-sm hover:bg-slate-100 cursor-pointer transition-all rounded-lg">
                <a href={`mailto:${manager?.email}`} target="_blank" className="flex items-center gap-1  text-xs">
                    <SlEnvolope className='text-lg' /> Email
                </a>
                </div>
        </div>
    )
}


