import React, { useCallback, useEffect, useState } from 'react'
import ALayout from 'components/admin/ALayout'
import { Apis, Authgeturl, Authposturl } from 'components/Apis'
import SinglePerson from './SinglePerson'
import Formbutton from 'components/utils/Formbutton'
import Forminput from 'components/utils/Forminput'
import { ErrorAlert, Errors, SuccessAlert } from 'components/utils/functions'

const SendNotification = () => {
    const [people, setPeople] = useState([])
    const [selected, setSelected] = useState([])
    const [loading, setLoading] = useState(false)
    const [used, setUsed] = useState(false)
    const [forms, setForms] = useState({
        title: '',
        content: ''
    })

    const handleForms = e => {
        setForms({
            ...forms,
            [e.target.name]: e.target.value
        })
    }

    const FetchUsers = useCallback(async () => {
        const res = await Authgeturl(Apis.user.all)
        if (res.status === 200) {
            setPeople(res.msg)
        }
    }, [])

    useEffect(() => { FetchUsers() }, [FetchUsers])

    const ToggleSelections = () => {
        setUsed(!used)
        if (!used) {
            const sets = []
            people.map((item) => {
                sets.push(item.id)
            })
            setSelected(sets)
        } else {
            setSelected([])
        }
    }

    const handleSelection = val => {
        const findData = selected.find(ele => ele === val)
        if (!findData) {
            setSelected([...selected, val])
        } else {
            const filtered = selected.filter(ele => ele !== val)
            setSelected(filtered)
        }
        setUsed(false)
    }

    const submitNotification = async () => {
        if (!forms.title) return ErrorAlert('provide a notification title')
        if (!forms.content) return ErrorAlert('provide content of notification')
        if (selected.length < 1) return ErrorAlert('Specify user(s) to recieve the notification')
        const data = {
            ...forms,
            users: selected
        }
        setLoading(true)
        try {
            const res = await Authposturl(Apis.notify.create_notify, data)
            setLoading(false)
            if(res.status === 200) {
                SuccessAlert(res.msg)
                setSelected([])
                setUsed(false)
                setForms({
                    title: '',
                    content: ''
                })
            }else {
                ErrorAlert(res.msg)
            }
        } catch (error) {
            Errors(error)
        }
    }
    return (
        <ALayout>
            <div className="">
                <div className="bg-white p-4">
                    <div className="mb-4">
                        <div className="">header title</div>
                        <Forminput
                            formName={'title'}
                            onChange={handleForms}
                            value={forms.title}
                            type={'text'}
                            label={'title of notification'}
                        />
                    </div>
                    <div className="mb-4">
                        <div className="">content</div>
                        <Forminput
                            formtype="textarea"
                            formName={'content'}
                            onChange={handleForms}
                            value={forms.content}
                            label={'content of notification'}
                        />
                    </div>
                    <div className="">
                        <div>
                            <div className="grid grid-cols-2 mb-5 border-b pb-3">
                                <div className="">
                                    <div className="text-sm">All</div>
                                </div>
                                <div onClick={ToggleSelections} className={`w-5 h-5 rounded-lg ${used ? 'bg-blue-700' : 'bg-slate-200'} ml-auto cursor-pointer`}></div>
                            </div>
                        </div>
                        {people.map((item, i) => (
                            <SinglePerson
                                key={i}
                                data={item}
                                handleSelection={handleSelection}
                                selected={selected}
                            />
                        ))}
                    </div>
                    <div className="border-t mt-3">
                        <div className="mt-6 w-fit ml-auto">
                            <Formbutton
                                title="send"
                                loading={loading}
                                bg="bg-blue-700"
                                handleSubmission={submitNotification}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ALayout>
    )
}

export default SendNotification