import React, { useState } from 'react'
import LoadImage from 'components/utils/LoadImage'
import { imgurl } from 'components/Apis'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { NairaSign } from 'components/utils/functions'
import { Link } from 'react-router-dom'

const SingleShortletData = ({ item, url, OpenImageModal }) => {
    const finals = item.media[0]?.image
    const [activeimg, setActiveImage] = useState(finals)

    const handleClicking = (data) => {
        setActiveImage(data)
    }

    return (
        <div className="border hover:shadow-2xl w-full bg-white mb-3 hover:scale-105">
            <div className="grid grid-cols-1 lg:grid-cols-8 gap-3">
                <div className="col-span-3 p-3">
                    <LoadImage handleActive={() => OpenImageModal(`${activeimg}`)} src={`${activeimg}`} width={'100%'} height={'3rem'} className="w-full h-28 object-cover" />
                    <div className="w-full overflow-x-auto mt-3 scrollsdown">
                        <div className="w-fit flex gap-3 items-center justify-center mx-auto">
                            {item.media?.length > 0 && item.media?.map((ele, index) => (
                                <div className="w-20 h-12" key={index}>
                                    <LoadImage src={ele.image} width={'100%'} height={'7rem'} className="w-20 h-12 object-cover" handleActive={handleClicking} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="col-span-5">
                    <div className="grid grid-cols-1 lg:grid-cols-5 h-full">
                        <div className="lg:col-span-3 mb-5 p-5">
                            <div className="font-bold text-xl">{item.title?.slice(0, 40)}...</div>
                            <div className="">category: <span className="font-bold text-sub">{item.cats.title}</span> </div>
                            <div className="flex items-center gap-2 text-sm">
                                <FaMapMarkerAlt />
                                <div className="capitalize"> {item.country} , {item.state}</div>
                            </div>
                        </div>
                        <div className="lg:col-span-2">
                            <div className="flex flex-col items-center justify-center w-full h-full bg-sub/20 p-5">
                                <div className="capitalize py-1 px-5 rounded-sm bg-main/60 text-white text-sm shadow-2xl">{item.aval === 'yes' ? 'Available' : 'Not Available'}</div>
                                <div className="capitalize">{item.pricetag}</div>
                                <div className="text-2xl font-bold mb-3">{NairaSign}{item.price?.toLocaleString()}</div>
                                <Link to={`${url}/${item.slug}/${item.id}`} className="bg-main py-3 px-4 w-3/4 mx-auto text-white rounded-lg capitalize text-lg text-center">view</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SingleShortletData