
import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment';
import Spinner from 'components/utils/Spinner';
import { Errors } from 'components/utils/functions';
import { Apis, Authgeturl } from 'components/Apis';
import ManageDepartmentModal from './ManageDepartmentModal';
import AHeader from 'components/admin/AHeader';
import ALayout from 'components/admin/ALayout';

const Tableheaders = [
  "Department Name",
  "date Created",
]

const StaffHeaders = [
    "Staff",
    "Email",
    "Phone",
    "Department",
    "Assign",
    "date Created",
]

const DeptSection = [
    {title: "departments"},
    {title: "staffs"},
]

export default function ManageDepartment() {
  const [plans, setPlans] = useState([])
  const [staffs, setStaffs] = useState([])
  const [loading, setLoading] = useState(true)
  const [single, setSingle] = useState({})
  const [view, setView] = useState(false)
  const [screen, setScreen] = useState(1)

  const FetchDepartments = useCallback(async () => {
    setLoading(true)
      try {
          const res = await Authgeturl(Apis.user.all_departments)
          const response = await Authgeturl(Apis.user.all_admin_staffs)
          if (res.status === 200) {
            setPlans(res.msg)
            setStaffs(response.msg)
          }
      } catch (error) {
          Errors(`${error}`)
      } finally {
          setLoading(false)
      }
  }, [])
  useEffect(() => { FetchDepartments() }, [FetchDepartments])

  const handleSingle = val => {
      setSingle(val)
      setView(!view)
  }

  return (
    <ALayout>
        {view && <ManageDepartmentModal 
        screenTag={screen}
        depts={plans}
        single={single}
        closeView={() => setView(false)}
        refetchSignal={() => { FetchDepartments(); setView(false) }}
        /> }
        <div className="mb-6 bg-white w-fit p-3 rounded-lg shadow-xl flex flex-row items-center gap-4">
            {DeptSection.map((ele, index) => (
                <button 
                onClick={() => setScreen(index + 1)}
                key={index} className={`py-3 px-5 ${screen === index + 1 ? 'bg-main text-white' : 'bg-slate-200'} rounded-lg font-bold capitalize transition-all`}>{ele.title}</button>
            ))}
        </div>
       {screen === 1 && <div className="">
            <div className="mb-5 text-xl font-bold">Departments</div>
            <AHeader
                total={plans.length}
                tag="button"
                tagtitle='Add Department'
                handleClick={() => {setView(true); setSingle({})}}
                url={false}
            />
            <div className="mt-10">
                {!loading && <table className='table w-full p-3 text-sm bg-white table-auto'>
                    <thead>
                        <tr className='text-white bg-slate-700'>
                            {Tableheaders.map((ele, i) => (
                                <td className='p-3 capitalize' key={i}>{ele}</td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {plans.length > 0 && plans.map((item, i) => (
                            <tr 
                            key={i}
                            className='border-b border-l border-r cursor-pointer'
                            onClick={() => handleSingle(item)}
                            >
                                <td className='p-3'>{item.name}</td>
                                <td className='p-3'>{moment(item.createdAt).format('DD-MM-YYYY hh:ss A')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>}
                {loading && <Spinner /> }
            </div>
        </div>}
       {screen === 2 && <div className="">
            <div className="mb-5 text-xl font-bold">Admin Staffs</div>
            <AHeader
                total={staffs.length}
                tag=""
                url={false}
            />
            <div className="mt-10">
                {!loading && <table className='table w-full p-3 text-sm bg-white table-auto'>
                    <thead>
                        <tr className='text-white bg-slate-700'>
                            {StaffHeaders.map((ele, i) => (
                                <td className='p-3 capitalize' key={i}>{ele}</td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {staffs.length > 0 && staffs.map((item, i) => (
                            <tr 
                            key={i}
                            className='border-b border-l border-r cursor-pointer'
                            >
                                <td className='p-3'>{item.firstname} {item.lastname}</td>
                                <td className='p-3'>{item.email}</td>
                                <td className='p-3'>{item.phone}</td>
                                <td className='p-3'>{item.staff?.name || "Nil"}</td>
                                <td className='p-3'>
                                    <button 
                            onClick={() => handleSingle(item)}
                            className="bg-main text-white text-xs py-3 px-5 rounded-lg capitalize">Assign</button>
                                    </td>
                                <td className='p-3'>{moment(item.createdAt).format('DD-MM-YYYY hh:ss A')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>}
                {loading && <Spinner /> }
            </div>
        </div>}
    </ALayout>
  )
}


