
import React, { useEffect } from 'react'
import UserLayout from 'components/user/UserLayout'
import { useQuery } from '@tanstack/react-query'
import { Apis, Authgeturl, Authposturl } from 'components/Apis'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ErrorAlert, SuccessAlert, Swals } from 'components/utils/functions'
import loads from 'assets/main-images/loads.gif'
import failed from 'assets/main-images/failed.gif'
import cards from 'assets/main-images/cards.gif'
import { dispatchPlan, dispatchUser } from 'app/reducer'
import { useDispatch } from 'react-redux'
import Imaged from 'components/utils/Imaged'

const ServiceTags = [
  "midas",
  "prime",
]

export default function VerifyPlanPay() {
  const [search, setSearch] = useSearchParams()
  const params = search.get('transRef')
  const status = search.get('status')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id, wallet, invoiceid, walletid } = useParams()
  useEffect(() => {
    if (!ServiceTags.includes(wallet)) return navigate('/dashboard')
    if (!walletid) return navigate('/dashboard')
    if (!invoiceid) return navigate('/dashboard')
  }, [])

  const { data, isLoading, isError, error } = useQuery({
    queryKey: [`verify-investment-direct-funding`],
    queryFn: async () => {
      if (status !== 'PAID') return { status: 400, msg: `Unable to verify transaction` }
      const response = await Authgeturl(`${Apis.ercas.verify_account_deposit}/${params}`)
      if (response.status === 200) {
        try {
            const pkdata = {
                invoice: parseInt(invoiceid)
            }
            const res = await Authposturl(Apis.investments.purchase_investment, pkdata )
            if (res.status === 200) {
                dispatch(dispatchPlan(res.stock))
                dispatch(dispatchUser(res.user))
                SuccessAlert(res.msg)
                navigate(`/investment/${walletid}/${wallet}`)
            } else {
                ErrorAlert(res.msg)
            }
        } catch (error) {
          ErrorAlert(`${error.message}`)
        }
        return response.msg
      }
    }
  })
  if (data?.status === 400) {
    return (
      <UserLayout title="Verification failed">
        <div className="w-11/12 mx-auto py-10 foil lg:py-20 flex items-center justify-center flex-col gap-3">
          <div className="">
            <Imaged src={failed} alt="" className="w-auto h-[20rem]" />
          </div>
          <div className="font-bold text-2xl text-red-600">Verification failed</div>
          <div className="font-light text-center w-4/5">We are unable to complete your request, kindly try again later</div>
        </div>
      </UserLayout>
    )
  }
  if (isLoading) return <UserLayout title="Verifying Payment...">
    <div className="w-11/12 mx-auto py-10 foil lg:py-20 flex items-center justify-center flex-col gap-3">
      <div className="">
        <Imaged src={loads} alt="" className="w-auto h-[20rem]" />
      </div>
      <div className="font-bold text-2xl text-sub">Verification in progress...</div>
      <div className="font-light text-center w-4/5">Please wait while we verify your payment...</div>
    </div>
  </UserLayout>
  if (isError) {
    return (
      <UserLayout title="Verification Error">
        <div className="w-11/12 mx-auto py-10 foil lg:py-20 flex items-center justify-center flex-col gap-3">
          <div className="">
            <Imaged src={failed} alt="" className="w-auto h-[20rem]" />
          </div>
          <div className="font-bold text-2xl text-red-600">Verification failed</div>
          <div className="font-light text-center w-4/5">{error}</div>
        </div>
      </UserLayout>
    )
  }

  return (
    <UserLayout title="Verify Payment">
      <div className="w-11/12 mx-auto bg-white py-10 lg:py-20 foil shadow-2xl flex items-center justify-center flex-col gap-3">
        <div className="">
          <Imaged src={cards} alt="" className="w-auto h-[20rem]" />
        </div>
        <div className="font-bold text-2xl text-green-600">Verification Completed</div>
        <div className="font-light text-center w-4/5">Payment completed, redirecting you shortly...</div>
      </div>
    </UserLayout>
  )
}

