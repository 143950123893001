
import React, { Suspense, useState } from 'react'
import { SlArrowDown } from 'react-icons/sl'
import PriceFilter from './PriceFilter'
import FilterByCity from './FilterByCity'
import FilterByCategory from './FilterByCategory'
import { MdOutlineFilterList } from 'react-icons/md'
import FilterAvailable from './FilterAvailable'
import FilterByDiscount from './FilterByDiscount'
import Forminput from 'components/utils/Forminput'
import { ErrorAlert } from 'components/utils/functions'
import { Apis, geturl } from 'components/Apis'

const ShortletFilter = ({ shortlets, border, stats, Signal }) => {
    const [title, setTitle] = useState('')
    const [views, setViews] = useState({
        pricing: true,
        country: true,
        category: true,
        aval: true,
        discount: true,
        name: true,
    })

    const handleViews = tag => {
        setViews({
            ...views,
            [tag]: !views[tag]
        })
    }

    const SearchByTitle = async (val) => {
        if(val.length > 2) {
            try {
                const response = await geturl(`${Apis.feeds.filter_shortlet}?title=${val}`)
                if (response.status === 200) {
                    Signal(response.msg)
                }
            } catch (error) {
                ErrorAlert(`${error.message}`)
            }
        }else {
            Signal(shortlets)
        }
    }

    return (
        <div className="">
            <div className="bg-main text-white mb-3 p-4 flex items-center justify-between">
                <div className="text-xl">Filter</div>
                <MdOutlineFilterList className='text-2xl' />
            </div>
            <div className={` bg-white w-full h-fit ${border ? 'border' : ''}`}>
                <div className="border-b pb-3">
                    <div
                        onClick={() => handleViews('name')}
                        className="flex items-center justify-between p-2.5 cursor-pointer">
                        <div className="font-semibold">Search By Title</div>
                        <div className=""> <SlArrowDown /> </div>
                    </div>
                    {views.name && <div className="px-4 py-2">
                        <Forminput 
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        onKeyup={e => SearchByTitle(e.target.value)}
                        label={'Enter Shortlet title...'} />
                    </div>}
                </div>
                <div className="border-b pb-3">
                    <div
                        onClick={() => handleViews('pricing')}
                        className="flex items-center justify-between p-2.5 cursor-pointer">
                        <div className="font-semibold">Price</div>
                        <div className=""> <SlArrowDown /> </div>
                    </div>
                    {views.pricing && <div className="px-4 py-2">
                        <Suspense fallback={'loading...'}>
                            <PriceFilter
                                maxprice={stats.maxprice}
                                minprice={stats.minprice}
                                Signal={Signal}
                                shortlets={shortlets}
                            />
                        </Suspense>
                    </div>}
                </div>
                <div className="border-b pb-3">
                    <div
                        onClick={() => handleViews('aval')}
                        className="flex items-center justify-between p-2.5 cursor-pointer">
                        <div className="font-semibold">Availability</div>
                        <div className=""> <SlArrowDown /> </div>
                    </div>
                    {views.aval && <div className="px-4 py-2">
                        <Suspense fallback={'loading...'}>
                            <FilterAvailable
                                shortlets={shortlets}
                                Signal={Signal}
                            />
                        </Suspense>
                    </div>}
                </div>
                <div className="border-b pb-3">
                    <div
                        onClick={() => handleViews('discount')}
                        className="flex items-center justify-between p-2.5 cursor-pointer">
                        <div className="font-semibold">Discount</div>
                        <div className=""> <SlArrowDown /> </div>
                    </div>
                    {views.discount && <div className="px-4 py-2">
                        <Suspense fallback={'loading...'}>
                            <FilterByDiscount
                                shortlets={shortlets}
                                Signal={Signal}
                            />
                        </Suspense>
                    </div>}
                </div>
                <div className="border-b pb-3">
                    <div
                        onClick={() => handleViews('category')}
                        className="flex items-center justify-between p-2.5 cursor-pointer">
                        <div className="font-semibold">Category</div>
                        <div className=""> <SlArrowDown /> </div>
                    </div>
                    {views.category && <div className="px-4 py-2">
                        <Suspense fallback={'loading...'}>
                            <FilterByCategory
                                shortlets={shortlets}
                                Signal={Signal}
                            />
                        </Suspense>
                    </div>}
                </div>
                <div className="border-b pb-3">
                    <div
                        onClick={() => handleViews('country')}
                        className="flex items-center justify-between p-2.5 cursor-pointer">
                        <div className="font-semibold">City</div>
                        <div className=""> <SlArrowDown /> </div>
                    </div>
                    {views.country && <div className="px-4 py-2">
                        <Suspense fallback={'loading...'}>
                            <FilterByCity
                                shortlets={shortlets}
                                Signal={Signal}
                            />
                        </Suspense>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default ShortletFilter