import { useQuery } from '@tanstack/react-query'
import { Apis, Authgeturl, imgurl } from '../Apis'
import { Swiper, SwiperSlide } from 'swiper/react';

import { Pagination, Autoplay, Navigation } from 'swiper/modules';
import Imaged from '../utils/Imaged';

export default function AllSponsors() {
    const { data, isLoading } = useQuery({
        queryKey: ['all-sponsors-homwe'],
        queryFn: async () => {
            const res = await Authgeturl(Apis.feeds.all_partners_logo)
            return res.msg
        }
    })
    if (isLoading) return null
    return (
        <div className='w-11/12 mx-auto lg:w-10/12 mb-10'>
            <Swiper
                spaceBetween={3}
                slidesPerView={4}
                slidesPerGroup={1}
                centeredSlides={true}
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                breakpoints={{
                  769: {
                    slidesPerView: 3,
                    slidesPerGroup: 1,
                  },
                  1024: {
                    slidesPerView: 4,
                    slidesPerGroup: 1,
                  },
                }}
                navigation={false}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                {data.map((item, index) => (
                    <SwiperSlide key={index}>
                        <Imaged src={`${imgurl}/logos/${item.image}`} className="w-auto h-20" />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
