
import React, { useRef, useState } from 'react'
import ReactQuill from 'react-quill';
import { EditorModules, ErrorAlert, Errors, SuccessAlert } from 'components/utils/functions';
import { FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Forminput from 'components/utils/Forminput';
import Formbutton from 'components/utils/Formbutton';
import { Apis, Authposturl } from 'components/Apis';
import Backbutton from 'components/utils/Backbutton';

const FeedForm = ({feed}) => {
    const [text, setText] = useState(feed?.content || '')
    const [loading, setLoading] = useState(false)
    const [id,] = useState(feed?.id || null)
    const imgref = useRef()
    const [forms, setForms] = useState({
        title: feed?.title || ''
    })
    const navigate = useNavigate()
    const [image, setImage] = useState(null)

    const handleForms = e => {
        setForms({
            ...forms,
            [e.target.name]: e.target.value
        })
    }

    const handleUpload = e => {
        const file = e.target.files[0]
        setImage(file)
    }

    const handleSubmission = async () => {
        if (!forms.title) return ErrorAlert('provide feed title')
        if (!text) return ErrorAlert('provide feed content')
        const data = new FormData()
        data.append('title', forms.title)
        data.append('content', text)
        if (image) {
            data.append('image', image)
        }
        if(id) {
            data.append('id', feed.id)
        }
        setLoading(true)
        const res = id ? await Authposturl(Apis.feeds.update_feed, data)  : await Authposturl(Apis.feeds.create_feeds, data)
        try {
            setLoading(false)
            if (res.status === 200) {
                SuccessAlert(res.msg)
                navigate('/admin/feeds')
            } else {
                ErrorAlert(res.msg)
            }
        } catch (error) {
            setLoading(false)
            Errors(error)
        }
    }
    return (
        <div className="">
            <Backbutton link="/admin/feeds" />
            <div className="mt-10 bg-white px-3 py-7">
                <form>
                    <div className="mb-3">
                        <div className="">Title of feed</div>
                        <Forminput
                            type="text"
                            formName="title"
                            value={forms.title}
                            label="Enter feed title"
                            onChange={handleForms}
                        />
                    </div>
                    <div className="mb-3">
                        <div className="">Upload image (optional)</div>
                        <div className="flex items-center gap-4">
                            <div className="w-full">
                                <Forminput
                                    refid={imgref}
                                    type="file"
                                    onChange={handleUpload}
                                />
                            </div>
                            {image && <button onClick={() => { setImage(null); imgref.current.value = null }} className="text-xl text-red-600"> <FaTimes /> </button>}
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="">Content of feed</div>
                        <ReactQuill
                            theme="snow"
                            value={text}
                            onChange={setText}
                            modules={EditorModules}
                        />
                    </div>
                    <div className="w-fit ml-auto mt-10">
                        <Formbutton
                            font={'text-sm py-3 px-5 capitalize'}
                            loading={loading}
                            title={`${id ? 'update feed' : 'create feed'}`}
                            bg="bg-blue-800"
                            handleSubmission={handleSubmission}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}

export default FeedForm