
import UserLayout from 'components/user/UserLayout'
import { Apis, Authgeturl } from 'components/Apis'
import { SingleServiceStatus, SuccessAlert } from 'components/utils/functions'
import Spinner from 'components/utils/Spinner'
import { BiSolidBellRing } from 'react-icons/bi'
import moment from 'moment'
import { BsCopy } from 'react-icons/bs'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useQuery } from '@tanstack/react-query'

export default function CashbackHistory() {

    const {data, isLoading} = useQuery({
        queryKey: ['user-fetch-cashback-history'],
        queryFn: async () => {
            const res = await Authgeturl(Apis.mobilenig.all_cashback_transactions)
            return res
        }
    })

    if (isLoading) return (
        <UserLayout title={'cashbacks'}>
            <div className="dark:text-blue-100">
                <div className="text-xl pt-3 px-4 font-bold">Latest Cashback Transactions</div>
                <div className="text-sm px-4 pb-4">Showing (0) in total</div>
                <div className="w-fit mx-auto"> <Spinner /> </div>
            </div>
        </UserLayout>
    )

    return (
        <UserLayout title={'cashbacks'}>
            <div className="dark:text-blue-100 w-11/12 mx-auto">
                <div className="text-xl pt-3 px-4 font-bold">Latest Cashback Notifications</div>
                <div className="text-sm px-4 pb-4">Showing ({data.data.length}) of {data.total}</div>
                <div className="bg-white px-5 mx-auto dark:bg-slate-600 py-3 rounded-lg border">
                    <div className=" mx-auto">
                        <ol className="relative border-l border-gray-200 dark:border-gray-700">
                            {data.total > 0 && data.data.map((item, i) => (
                                <li key={i} className={`mb-10 ml-6`}>
                                    <span className={`absolute flex items-center text-sm text-blue-100 dark:text-white bg-blue-500 ring-white dark:ring-blue-600 justify-center w-8 h-8  rounded-full -left-4 ring-4 dark:bg-green-400`}>
                                        <BiSolidBellRing />
                                    </span>
                                    <div className="grid grid-cols-2">
                                        <div className="">
                                            <h3 className="leading-tight font-bold text-sm capitalize"> {item.title} </h3>
                                            <CopyToClipboard text={item.ref}
                                                onCopy={() => SuccessAlert('Reference ID copied')}>
                                                <div className="flex items-center">
                                                    <div className="text-sm text-slate-500 truncate">REF: <span className="font-bold text-black">{item.ref}</span> </div>
                                                    <div className="cursor-pointer"><BsCopy /></div>
                                                </div>
                                            </CopyToClipboard>
                                        </div>
                                        <div className="">
                                            <div className={`text-right text-sm capitalize ${SingleServiceStatus(item.status)} w-fit ml-auto font-bold`}>{item.status}</div>
                                            <div className="text-xs text-right font-medium">{moment(item.createdAt).format('DD-MM-YYYY h:sA')}</div>
                                        </div>
                                    </div>
                                    <div className="text-xs text-left mr-2"> {item.content}</div>
                                </li>
                            ))}
                        </ol>
                    </div>
                </div>
            </div>
        </UserLayout>
    )
}
