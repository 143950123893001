import React from 'react'


const Formbutton = ({loading, title, handleSubmission, disable=false, type="submit", padding='py-4 px-5', bg="bg-main"}) => {
  return (
    <button type={type} onClick={handleSubmission} disabled={loading || disable ? true : false} className={`${padding} rounded-lg text-sm relative font-light border-none outline-none shadow-md ${loading || disable ? 'cursor-not-allowed' : 'cursor-pointer'} capitalize ${bg} text-white flex items-center flex-row gap-4 w-full justify-center transition-all`}>{title} 
    {loading && <>
    <div className="lds-hourglass"></div>  
    <div className="absolute top-0 left-0 w-full h-full bg-white/40 rounded-lg"></div>
    </>}
    {disable && <div className="absolute top-0 left-0 w-full h-full bg-white/40 rounded-lg"></div>}
    </button>
  )
}


export default Formbutton