

import React, { useState } from 'react'
import { Apis, Posturl } from 'components/Apis'
import { ErrorAlert, Errors, SuccessAlert } from 'components/utils/functions'
import Forminput from 'components/utils/Forminput'
import Formbutton from 'components/utils/Formbutton'
import { Link, useNavigate } from 'react-router-dom'
import GeneratePin from './GeneratePin'
import emailimg from 'assets/images/mailc.png'
import logo from 'assets/main-images/logo.png'
import DailOptions from 'components/utils/DailOption'
import Imaged from 'components/utils/Imaged'
import { Helmet } from 'react-helmet-async'

export default function RegisterPage() {
  const [loading, setLoading] = useState(false)
  const [, setLoads] = useState(false)
  const [otpmsg, setOtpmsg] = useState('Resend OTP')
  const [screen, setScreen] = useState(1)
  const [pins, setPins] = useState('')
  const navigate = useNavigate()
  const [forms, setForms] = useState({
    firstname: '',
    middlename: '',
    lastname: '',
    dailcode: "+234",
    phone: '',
    email: '',
    password: '',
    confirm_password: '',
    upline: ''
  })
  const [code, setCode] = useState('')
  const [agree, setAgree] = useState(false)

  const handleForms = e => {
    setForms({
      ...forms,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmission = async (e) => {
    e.preventDefault()
    try {
      if (!forms.firstname) return ErrorAlert('provide your firstname')
      if (!forms.middlename) return ErrorAlert('provide your middle name')
      if (!forms.lastname) return ErrorAlert('provide your last name')
      if (!forms.email) return ErrorAlert('provide your email')
      if (!forms.dailcode) return ErrorAlert('provide your dailcode')
      if (!forms.phone) return ErrorAlert('provide your phone')
      if (!forms.password) return ErrorAlert('provide your password')
      if (!forms.confirm_password) return ErrorAlert('confirm your password')
      if (forms.confirm_password !== forms.password) return ErrorAlert('password(2) mismatched')
      if (!agree) return ErrorAlert('You have to agree to our terms of condition and prvacy')

      const data = {
        ...forms,
        agree
      }
      setLoading(true)
      const res = await Posturl(Apis.user.create_account, data)
      if (res.status === 200) {
        setScreen(2)
      } else {
        ErrorAlert(res.msg)
      }
    } catch (error) {
      Errors(`${error}`)
    } finally {
      setLoading(false)
    }
  }

  const handleEmailVerificaton = async () => {
    const data = { code }
    setLoading(true)
    try {
      const res = await Posturl(Apis.user.accept_registration_code, data)
      if (res.status === 200) {
        setScreen(3)
        SuccessAlert(res.msg)
      } else {
        ErrorAlert(res.msg)
      }
    } catch (error) {
      Errors(`${error}`)
    } finally {
      setLoading(false)
    }
  }

  const ResendCode = async () => {
    if (!forms.email) return ErrorAlert('provide a valid Email Address')
    const data = {
      email: forms.email
    }
    setLoads(true)
    try {
      setOtpmsg('sending...')
      const res = await Posturl(Apis.user.resend_otp, data)
      if (res.status === 200) {
        setOtpmsg('code sent!.')
        SuccessAlert(res.msg)
        setTimeout(() => {
          setOtpmsg('Resend OTP')
        }, 1500);
      } else {
        Errors(res.msg)
      }
    } catch (error) {
      Errors(`${error}`)
    } finally {
      setLoading(false)
    }
  }

  const firstConfirmation = (vals) => {
    setLoading(true)
    setPins(vals)
    setTimeout(() => {
      setLoading(false)
      setScreen(4)
    }, 2000);
  }

  const secondConfirmation = async (vals) => {
    if (vals !== pins) return ErrorAlert('Wrong Pins detected!..')
    setLoading(true)
    const data = {
      pin: pins,
      email: forms.email
    }
    try {
      const res = await Posturl(Apis.user.create_account_pin, data)
      if (res.status === 200) {
        navigate('/welcome')
        SuccessAlert(res.msg)
      } else {
        ErrorAlert(`${res.msg}`)
      }
    } catch (error) {
      Errors(`${error}`)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
    <Helmet prioritizeSeoTags>
        <title>Quick Direct | Create Account</title>
        <link rel="notImportant" href="https://www.quickdirect.com.ng/signp" />
        <meta name="whatever" value="notImportant" />
        <link rel="canonical" href="https://www.quickdirect.com.ng/signp" />
        <meta property="og:title" content="Quick Direct | Create Account" />
        <meta property="og:url" content="https://www.quickdirect.com.ng/signp" />
        <meta property="og:image" content="https://www.quickdirect.com.ng/main.png" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Create an account and seamlessly manage your Quick Direct assets" />
    </Helmet>
    <div className='bg-zinc-50'>
      <div className="h-screen">
        <div className="grid grid-cols-1 lg:grid-cols-5">
          <div className="bg-main hidden lg:block h-screen col-span-2"></div>
          <div className="bg-gradient-to-b to-white from-blue-200 lg:from-white lg:to-white py-20 overflow-y-auto scrolls flex-col h-screen col-span-3">
            <div className="w-11/12 max-w-xl  mx-auto shadow-2xl rounded-lg p-6 bg-white text-sm">
              <div className="w-fit ml-auto p-2 mb-5 rounded-lg">
                <Imaged src={logo} alt="" className="h-10" />
              </div>
              {screen === 1 && <form onSubmit={handleSubmission}>
                <div className="text-xl mb-5 border-b font-semibold pb-3">Create your Ner<span className='text-sub'>ita account</span> </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-2 mb-4">
                  <div className="relative">
                    <Forminput label="Enter FirstName" placeholder='' title='text' value={forms.firstname} formName="firstname" onChange={handleForms} />
                  </div>
                  <div className="relative">
                    <Forminput label="Middle Name" placeholder='' title='text' value={forms.middlename} formName="middlename" onChange={handleForms} />
                  </div>
                  <div className="relative">
                    <Forminput label="Last Name" placeholder='' title='text' value={forms.lastname} formName="lastname" onChange={handleForms} />
                  </div>
                </div>
                <Forminput label="Enter Email Address" placeholder='name@example.com' title='email' value={forms.email} formName="email" onChange={handleForms} />
                <div className="">Phone Number</div>
                <div className="grid grid-cols-7 gap-3">
                  <div className="col-span-2">
                    <DailOptions
                      defaultValue={forms.dailcode}
                      setup={(val) => setForms({ ...forms, dailcode: val.dial_code })}
                    />
                  </div>
                  <div className="col-span-5">
                    <Forminput label="" placeholder='' title='number' value={forms.phone} formName="phone" onChange={handleForms} />
                  </div>
                </div>
                <Forminput label="Enter Password" placeholder='********' title='password' value={forms.password} formName="password" onChange={handleForms} type="password" />
                <Forminput label="Confirm Password" type="password" placeholder='Re-type password' title='password' value={forms.confirm_password} formName="confirm_password" onChange={handleForms} />
                <Forminput label="Referral ID (Optional)" placeholder='your referral' title='text' value={forms.upline} formName="upline" onChange={handleForms} />

                <div className="flex items-start mb-5">
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <label className='flex items-center gap-3'>
                        <input onChange={e => setAgree(e.target.checked)} checked={agree} id="remember" type="checkbox" value="" className="w-4 h-4 border border-gray-300  rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800" />
                        <div className="text-sm">By continuing you agree to our terms of service and privacy policy</div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="">
                  <Formbutton loading={loading} title='Create Account' />
                </div>
                <div className="mt-5">
                  <div className="">Already have an account? <Link to="/login" className="text-blue-600">Login account.</Link> </div>
                  <div className="mt-3"> <Link className='text-blue-600' to="/">Back home</Link> </div>
                </div>
              </form>}

              {screen === 2 && <form className="space-y-6 relative">
                <div className="w-28 mx-auto">
                  <Imaged
                    src={emailimg}
                    alt="Quick Direct"
                    width={150}
                    height={100}
                  />
                </div>
                <div className="text-center">We sent a 6 digit code to <span className="text-sub">{forms.email.slice(0, 3)}******{forms.email.slice(-10)}</span> please enter the code and paste here to complete your verification </div>
                <div className="mt-6 w-3/5 mx-auto">
                  <Forminput
                    label="Verification Code"
                    title="text"
                    formName="code"
                    value={code}
                    onChange={e => setCode(e.target.value)}
                  />
                </div>
                <div className="my-5 text-center flex items-center justify-center gap-3">Didn't recieve any code? <div onClick={ResendCode} className="text-sub cursor-pointer">{otpmsg}</div> </div>
                <div className="w-fit mx-auto">
                  <Formbutton handleSubmission={handleEmailVerificaton} title='verify email' loading={loading} />
                </div>
              </form>}
              {screen === 3 && <GeneratePin
                title="Setup your Account Pin"
                buttonText="continue"
                continueSetup={firstConfirmation}
                loading={loading}
              />}

              {screen === 4 && <GeneratePin
                title="Confirm Account Pin"
                buttonText="Confirm Pin"
                loading={loading}
                continueSetup={secondConfirmation}
              />}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

