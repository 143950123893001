
import moment from "moment";
import ModalLayout from "components/utils/ModalLayout";
import { ErrorAlert, SuccessAlert } from "components/utils/functions";
import { Apis, Authputurl } from "components/Apis";
import Formbutton from "components/utils/Formbutton";
import { useState } from "react";
import { FormHeader } from "components/utils/FormHeader";


export default function AssignUserModal({ closeView, single ,refetchSignal }) {
    const [loading, setLoading] = useState(false)
    const [screen, setScreen] = useState(1)

    const ApproveRequest = async () => {
        setLoading(true)
        const forms = {
            id: single.id,
            role: single.role === 'user' ? 'admin' : 'user'
        }
        try {
            const res = await Authputurl(Apis.user.update_user_role, forms)
            if (res.status === 200) {
                SuccessAlert(res.msg)
                refetchSignal()
            } else {
                return ErrorAlert(res.msg)
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoading(false)
        }
    }
    return (
        <ModalLayout closeView={closeView}>
            {screen === 2 && <>
            <div className="p-4 border-b"><FormHeader title="Confirm Approval" back={true} setScreen={setScreen} /> </div>
                <div className="p-4">
                <div className="text-center">Kindly confirm your request to {single.role === 'user' ? 'upgrade' : 'downgrade'} {single.firstname} {single.lastname}'s account</div>
                
                <div className="mt-10">
                        <Formbutton title={single.role === 'user' ? 'Upgrade' : 'Downgrade'} loading={loading} handleSubmission={ApproveRequest} />
                        </div>
                </div>
            </>}
            {screen === 1 && <>
                <div className="p-4 mb-4 border-b font-bold">Request Details</div>
                <div className="grid grid-cols-2 gap-4 p-2">
                    <div className="">Full Name</div>
                    <div className="text-right">{single.firstname} {single.lastname}</div>
                </div>
                <div className="grid grid-cols-2 gap-4 p-2">
                    <div className="">Email Address</div>
                    <div className="text-right">{single.email}</div>
                </div>
                <div className="grid grid-cols-2 gap-4 p-2">
                    <div className="">Phone Number</div>
                    <div className="text-right">{single.phone}</div>
                </div>
                <div className="grid grid-cols-2 gap-4 p-2">
                    <div className="">Account Role</div>
                    <div className="text-right uppercase">{single.role}</div>
                </div>
                <div className="grid grid-cols-2 gap-4 p-2 border-t">
                    <div className="">
                        <div className="">Date Registered</div>
                        <div className="">{moment(single.createdAt).format('DD-MM-YYYY')}</div>
                    </div>
                     <div className="ml-auto w-fit">
                        <Formbutton title={single.role === 'user'? 'Upgrade to Admin' : 'Downgrade to User'} handleSubmission={() => setScreen(2)} />
                    </div>
                </div>
            </>}
        </ModalLayout>
    )
}