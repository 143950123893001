import React, { useState } from 'react'
import Backbutton from 'components/utils/Backbutton'
import Forminput from 'components/utils/Forminput'
import Formbutton from 'components/utils/Formbutton'
import { ErrorAlert, Errors, SuccessAlert } from 'components/utils/functions'
import { Apis, Authposturl } from 'components/Apis'
import { useNavigate } from 'react-router-dom'
import Subplan from './Subplan'

export const InvestmentPlans = [
    'istock',
    'midas',
    'prime'
]

const PlanForm = ({ plan = null }) => {
    const [id,] = useState(plan?.id ? plan.id : null)
    const [allsubs, setAllsubs] = useState(id ? plan.subplan : [])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [forms, setForms] = useState({
        title: id ? plan.title : '',
        subTitle: id ? plan.subTitle : '',
        unitPrice: id ? plan.unitPrice : '',
        balanceName: id ? plan.balanceName : '',
        profitName: id ? plan.profitName : '',
        minDept: id ? plan.minDept : '',
        maxDept: id ? plan.maxDept : '',
        minWithd: id ? plan.minWithd : '',
        maxWithd: id ? plan.maxWithd : '',
        percent: id ? plan.percent : '',
        duration: id ? plan.duration : 0,
        isDuration: id ? plan.isDuration : '',
        refbonus: id ? plan.refbonus : '',
        returnCapital: id ? plan.returnCapital : '',
        durationType: id ? plan.durationType : '',
        stockLimit: id ? plan?.stockLimit : '',
        stockLimitDuration: id ? plan?.stockLimitDuration : '',
        stockLimitDurationType: id ? plan?.stockLimitDurationType : '',
        sellDuration: id ? plan?.sellDuration : '',
        sellDurationType: id ? plan?.sellDurationType : '',
    })
    const handleForms = e => {
        setForms({
            ...forms,
            [e.target.name]: e.target.value
        })
    }

    const HandleSubmit = async (e) => {
        e.preventDefault()
        if (!forms.title) return ErrorAlert('provide plan title')
        if (!forms.subTitle) return ErrorAlert('provide plan sub title')
        if (forms.title === InvestmentPlans.at(0) && !forms.unitPrice) return ErrorAlert('provide unit price')
        if (forms.title === InvestmentPlans.at(0) && !forms.stockLimit) return ErrorAlert('provide stock limit')
        if (forms.title === InvestmentPlans.at(0) && !forms.stockLimitDuration) return ErrorAlert('provide stock limit duration')
        if (forms.title === InvestmentPlans.at(0) && !forms.stockLimitDurationType) return ErrorAlert('provide stock limit duration type')
        if (forms.title === InvestmentPlans.at(0) && !forms.sellDuration) return ErrorAlert('provide the duration before a stock can be sold')
        if (forms.title === InvestmentPlans.at(0) && !forms.sellDurationType) return ErrorAlert('provide the duration type before a stock can be sold')
        if (!forms.balanceName) return ErrorAlert('provide plan balance Name')
        if (!forms.profitName) return ErrorAlert('provide plan profit Name')
        if (!forms.minDept) return ErrorAlert('provide minimum deposit limit')
        if (!forms.maxDept) return ErrorAlert('provide maximum deposit limit')
        if (!forms.minWithd) return ErrorAlert('provide minimum withdrawal limit')
        if (!forms.maxWithd) return ErrorAlert('provide maximum withdrawal limit')
        if (!forms.percent) return ErrorAlert('provide plan percent after purchase')
        if (!forms.returnCapital) return ErrorAlert('provide plan capital return scheme after purchase')
        if (forms.title !== InvestmentPlans.at(1) && !forms.duration) return ErrorAlert('provide plan duration')
        if (forms.title !== InvestmentPlans.at(1) && !forms.durationType) return ErrorAlert('provide plan duration type')
        if (!forms.isDuration) return ErrorAlert('Specify if you want to apply duration to plan')
        if (!forms.refbonus) return ErrorAlert('provide referral bonus amount')
        let planid = {}
        id ? planid = { id } : planid = {}
        const data = {
            ...forms,
            ...planid,
            subplans: allsubs
        }

        setLoading(true)
        try {
            const res = id ? await Authposturl(Apis.investments.update_plan, data) : await Authposturl(Apis.investments.create_plan, data)
            if (res.status === 200) {
                SuccessAlert(res.msg)
                setTimeout(() => {
                    navigate('/admin/plans')
                }, 1500);
            } else {
                ErrorAlert(res.msg)
            }
        } catch (error) {
            Errors(error)
        } finally {
            setLoading(false)
        }
    }
    return (
        <div>
            <div className="">
                <div className="w-fit">
                    <Backbutton link="/admin/plans" />
                </div>
                <div className="mt-10 p-4 bg-white">
                    <form>
                        <div className="grid grid-cols-2 md:grid-cols-3 gap-6 mb-4">
                            <div className="">
                                <div className="text-sm"> title</div>
                                <Forminput
                                    formName="title"
                                    label="Plan Name"
                                    type="text"
                                    onChange={handleForms}
                                    value={forms.title}
                                    formtype="select"
                                >
                                    {InvestmentPlans.map((item, i) => (
                                        <option value={item} key={i}>{item}</option>
                                    ))}
                                </Forminput>
                            </div>
                            <div className="">
                                <div className="text-sm"> sub title</div>
                                <Forminput
                                    formName="subTitle"
                                    label="Sub title"
                                    type="text"
                                    onChange={handleForms}
                                    value={forms.subTitle}
                                />
                            </div>
                            {forms.title === InvestmentPlans.at(0) && <>
                                <div className="">
                                    <div className="text-sm">unit price</div>
                                    <Forminput
                                        formName="unitPrice"
                                        label="Unit Price"
                                        type="text"
                                        onChange={handleForms}
                                        value={forms.unitPrice}
                                    />
                                </div>
                                <div className="">
                                    <div className="text-sm">Stock Limit</div>
                                    <Forminput
                                        formName="stockLimit"
                                        label="Stock Limit"
                                        type="number"
                                        onChange={handleForms}
                                        value={forms.stockLimit}
                                    />
                                </div>
                                <div className="">
                                    <div className="text-sm">Stock Limit Duration</div>
                                    <Forminput
                                        formName="stockLimitDuration"
                                        label="Stock Limit Duration"
                                        type="number"
                                        onChange={handleForms}
                                        value={forms.stockLimitDuration}
                                    />
                                </div>
                                <div className="">
                                    <div className="text-sm">stock limit duration Type</div>
                                    <Forminput
                                        formName="stockLimitDurationType"
                                        label="stock limit duration type"
                                        onChange={handleForms}
                                        value={forms.stockLimitDurationType}
                                        formtype='select'
                                    >
                                        <option value="minutes">In Minutes</option>
                                        <option value="hours">In Hours</option>
                                        <option value="days">In Days</option>
                                    </Forminput>
                                </div>
                                <div className="">
                                    <div className="text-sm">Sell Stock Duration</div>
                                    <Forminput
                                        formName="sellDuration"
                                        label="Sell Stock Duration"
                                        type="number"
                                        onChange={handleForms}
                                        value={forms.sellDuration}
                                    />
                                </div>
                                <div className="">
                                    <div className="text-sm">Sell stock duration Type</div>
                                    <Forminput
                                        formName="sellDurationType"
                                        label="Sell stock duration type"
                                        onChange={handleForms}
                                        value={forms.sellDurationType}
                                        formtype='select'
                                    >
                                        <option value="minutes">In Minutes</option>
                                        <option value="hours">In Hours</option>
                                        <option value="days">In Days</option>
                                    </Forminput>
                                </div>
                            </>}
                        </div>
                        <div className="grid grid-cols-3 md:grid-cols-5 gap-6 mb-4">
                            <div className="">
                                <div className="text-sm">Balance Name</div>
                                <Forminput
                                    formName="balanceName"
                                    label="Balance Name"
                                    type="text"
                                    onChange={handleForms}
                                    value={forms.balanceName}
                                />
                            </div>
                            <div className="">
                                <div className="text-sm">Profit Name</div>
                                <Forminput
                                    formName="profitName"
                                    label="Profit Name"
                                    type="text"
                                    onChange={handleForms}
                                    value={forms.profitName}
                                />
                            </div>
                            <div className="">
                                <div className="text-sm">min Deposit</div>
                                <Forminput
                                    formName="minDept"
                                    label="min Deposit"
                                    type="number"
                                    onChange={handleForms}
                                    value={forms.minDept}
                                />
                            </div>
                            <div className="">
                                <div className="text-sm">max Deposit</div>
                                <Forminput
                                    formName="maxDept"
                                    label="max Deposit"
                                    type="number"
                                    onChange={handleForms}
                                    value={forms.maxDept}
                                />
                            </div>
                            <div className="">
                                <div className="text-sm">min Withd.</div>
                                <Forminput
                                    formName="minWithd"
                                    label="min Withd"
                                    type="number"
                                    onChange={handleForms}
                                    value={forms.minWithd}
                                />
                            </div>
                            <div className="">
                                <div className="text-sm">max Withd. (%)</div>
                                <Forminput
                                    formName="maxWithd"
                                    label="max Withd"
                                    type="number"
                                    onChange={handleForms}
                                    value={forms.maxWithd}
                                />
                            </div>
                            <div className="">
                                <div className="text-sm">percent (%)</div>
                                <Forminput
                                    formName="percent"
                                    label="percent"
                                    type="number"
                                    onChange={handleForms}
                                    value={forms.percent}
                                />
                            </div>
                            <div className="">
                                <div className="text-sm">return capital</div>
                                <Forminput
                                    formtype="select"
                                    formName="returnCapital"
                                    value={forms.returnCapital}
                                    onChange={handleForms}
                                >
                                    <option value="no">NO</option>
                                    <option value="yes">YES</option>
                                </Forminput>
                            </div>
                            {forms.title !== InvestmentPlans.at(1) && <>
                                <div className="">
                                    <div className="text-sm">duration</div>
                                    <Forminput
                                        formName="duration"
                                        label="duration"
                                        type="number"
                                        onChange={handleForms}
                                        value={forms.duration}
                                    />
                                </div>
                                <div className="">
                                    <div className="text-sm">duration Type</div>
                                    <Forminput
                                        formName="durationType"
                                        label="duration"
                                        onChange={handleForms}
                                        value={forms.durationType}
                                        formtype='select'
                                    >
                                        <option value="minutes">In Minutes</option>
                                        <option value="hours">In Hours</option>
                                        <option value="days">In Days</option>
                                        <option value="months">In Months</option>
                                        <option value="years">In Years</option>
                                    </Forminput>
                                </div>
                            </>}
                            <div className="col-span-2">
                                <div className="text-sm">Apply Duration</div>
                                <Forminput
                                    formtype="select"
                                    formName="isDuration"
                                    value={forms.isDuration}
                                    onChange={handleForms}
                                >
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </Forminput>
                                <div className="text-xs text-red-600">When duration is applied it will cause plan to end after a specified duration</div>
                            </div>
                            <div className="">
                                <div className="text-sm">ref. bonus</div>
                                <Forminput
                                    formName="refbonus"
                                    label="ref bonus"
                                    type="number"
                                    onChange={handleForms}
                                    value={forms.refbonus}
                                />
                            </div>
                        </div>
                        {/* if plan has sub plans duration like midas */}
                        {forms.title === InvestmentPlans.at(1) && <Subplan allsubs={allsubs} setAllsubs={setAllsubs} />}
                        <div className="mt-7 w-fit ml-auto">
                            <Formbutton
                                bg="bg-blue-700"
                                handleSubmission={HandleSubmit}
                                title={`${id ? 'update plan' : 'create plan'}`}
                                loading={loading}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default PlanForm