import React, { useState } from "react";
import avatar from "assets/images/avatar.png";
import { useDispatch, useSelector } from "react-redux";
import { FaCameraRetro } from "react-icons/fa6";
import { Errors, SuccessAlert } from "components/utils/functions";
import { Apis, Authposturl, imgurl } from "components/Apis";
import { dispatchUser } from "app/reducer";
import Imaged from "./Imaged";
import CopyToClipboard from "react-copy-to-clipboard";
import { BsCopy } from "react-icons/bs";

const ProfileComponent = () => {
  const { user } = useSelector((state) => state.data);
  const [image, setImage] = useState(
    user.image ? `${imgurl}/profile/${user.image}` : null
  );
  const dispatch = useDispatch();

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      const formdata = new FormData();
      formdata.append("image", file);
      try {
        const response = await Authposturl(
          Apis.user.upload_profile_photo,
          formdata
        );
        if (response.status === 200) {
          SuccessAlert(response.msg);
          return dispatch(dispatchUser(response.details));
        }
      } catch (error) {
        Errors(`${error.message}`);
      }
    }
  };

  return (
    <div>
      <div className="w-11/12 mx-auto mt-5">
        <div className="w-fit h-fit mx-auto">
          <label className="cursor-pointer">
            <div className="w-fit mx-auto relative flex items-center flex-col">
              <Imaged
                src={image ? image : avatar}
                alt=""
                className="w-32 h-32 rounded-full object-cover"
              />
              <div className="absolute bottom-5 right-0">
                <div className="bg-white p-2 rounded-full shadow-2xl border">
                  <FaCameraRetro />
                  <input type="file" onChange={handleFileUpload} hidden />
                </div>
              </div>
              <div className="text-sm items-center font-medium text-slate-500">Upload profile</div>
            </div>
          </label>
        </div>
        <div className="text-center font-bold uppercase text-sm">
          {user.firstname} {user.middlename} {user.lastname}
        </div>
        <div className="w-fit mx-auto mb-5">
          <CopyToClipboard
            text={user.refid}
            onCopy={() => SuccessAlert("Referral code copied")}
          >
            <div className="flex cursor-pointer items-center gap-2">
              <span>Referral ID: {user.refid}</span> <BsCopy />
            </div>
          </CopyToClipboard>
        </div>
        <div className="bg-white p-2 rounded-lg mb-1.5">
          <div className="text-slate-500">Full Name</div>
          <div className="font-bold">{user.firstname} {user.middlename} {user.lastname}</div>
        </div>
        <div className="bg-white p-2 rounded-lg mb-1.5">
          <div className="text-slate-500">Email</div>
          <div className="font-bold">{user.email}</div>
        </div>
        <div className="bg-white p-2 rounded-lg mb-1.5">
          <div className="text-slate-500">Phone Number</div>
          <div className="font-bold">{user.phone}</div>
        </div>
        <div className="bg-white p-2 rounded-lg mb-1.5">
          <div className="text-slate-500">Last Logged in</div>
          <div className="font-bold">{user.lastlogin}</div>
        </div>
      </div>
    </div>
  );
};

export default ProfileComponent;
