
import React, { useState } from 'react'
import { SlEarphonesAlt, SlUserFollow } from 'react-icons/sl'
import { GetAmountMinusPercent, NairaSign } from 'components/utils/functions'
import { FaMapMarkerAlt, FaTimes } from 'react-icons/fa'
import LoadImage from 'components/utils/LoadImage'
import { Apis, geturl } from 'components/Apis'
import { useParams } from 'react-router-dom'
import HomeShortletForm from './HomeShortletForm'
import ImageModal from 'components/user/ImageModal'
import { useQuery } from '@tanstack/react-query'
import { Image } from 'antd'
import { Helmet } from 'react-helmet-async'
import { convert } from 'html-to-text'

const PickUpPage = ({ usertag, tagged }) => {
    const [views, setViews] = useState(false)
    const [opens, setOpens] = useState({
        status: false,
        img: null
    })
    const [activeimg, setActiveImage] = useState('')
    const { id } = useParams()

    const { data, isLoading } = useQuery({
        queryKey: [`pickup-page-${id}`],
        queryFn: async () => {
            const response = await geturl(`${Apis.feeds.single_shortlet}/${id}`)
            if (response.status === 200) {
                const data = response.msg
                const finals = data.media[0]?.image
                setActiveImage(finals)
                return response.msg
            }
        }
    })

    if (isLoading) {
        return (
            <div>
                <div className="bg-slate-200 h-[16rem]"></div>
                <div className="w-full overflow-x-auto mt-3">
                    <div className="w-fit flex gap-3 items-center justify-center mx-auto">
                        {new Array(5).fill(0).map((item, i) => (
                            <div className="h-[10rem] w-[10rem] bg-slate-200 animate-pulse" key={i}></div>
                        ))}
                    </div>
                </div>
                <div className="bg-slate-200 h-10 w-3/4 mt-3"></div>
                <div className="bg-slate-200 h-10 mt-3"></div>
            </div>
        )
    }

    const handleClicking = (data) => {
        setActiveImage(data)
    }
    
    return (
        <div>
        <Helmet prioritizeSeoTags>
            <title>Quick Direct | {data.title}</title>
            <link rel="notImportant" href={`https://www.quickdirect.com.ng/shortlets/${data.slug}/${data.id}`} />
            <meta name="whatever" value="notImportant" />
            <link rel="canonical" href={`https://www.quickdirect.com.ng/shortlets/${data.slug}/${data.id}`} />
            <meta property="og:title" content={`Quick Direct | ${data.title}`} />
            <meta property="og:url" content={`https://www.quickdirect.com.ng/shortlets/${data.slug}/${data.id}`} />
            <meta property="og:image" content="https://www.quickdirect.com.ng/main.png" />
            <meta property="og:type" content="website" />
            <meta property="og:description" content={`${convert(data.description)}`} />
        </Helmet>
            {opens.status && <ImageModal
                img={opens.img}
                closeView={() => setOpens({ ...opens, status: false })}
            />}
            <div className="">

                <div className={`fixed top-0 left-0 w-full h-screen bg-black/30 ${views ? 'flex' : 'hidden'} items-center justify-center z-50`}>
                    <div className="bg-white w-11/12 max-w-lg p-4 rounded-xl relative foil">
                        <div
                            onClick={() => { setViews(!views) }}
                            className="absolute top-2 right-2 cursor-pointer p-2 rounded-full bg-slate-200"> <FaTimes /> </div>
                        <div className="">
                            <HomeShortletForm
                                shortlet={data}
                                usertag={usertag}
                                tagged={tagged}
                                closeView={() => { setViews(!views) }}
                            />
                        </div>
                    </div>
                </div>
                <div className="w-11/12 mx-auto max-w-[1160px]">
                    <div>
                        <div className="relative">
                            {/* <LoadImage src={`${activeimg}`} width={'full'} height={'20rem'} className="w-full h-[30rem] object-cover" handleActive={() => handleOpensModal(activeimg)} /> */}
                            <div className="">
                                <Image src={`${activeimg}`} className="!w-[100dvw] !h-[30rem] object-cover" />
                            </div>
                            <div className="absolute top-2 rounded-md right-3 text-xs bg-sub/80 text-white backdrop-blur-md py-2 px-3 capitalize">{data.aval === 'yes' ? 'available' : 'not available'}</div>
                        </div>
                        <div className="w-full overflow-x-auto mt-3 scrollsdown">
                            <div className="w-fit flex gap-3 items-center justify-center mx-auto">
                                {data.media?.length > 0 && data.media?.map((ele, index) => (
                                    <div className="w-28 h-28" key={index}>
                                        <LoadImage src={ele.image} width={'100%'} height={'7rem'} className="w-28 h-28 object-cover cursor-pointer" handleActive={handleClicking} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-6 gap-10">
                            <div className="lg:col-span-4">
                                <div className="font-bold text-2xl mt-3">{data.title}</div>
                                <div className="flex items-center gap-2 text-slate-500 mb-5">
                                    <div className="flex items-center gap-2"> <FaMapMarkerAlt /> {data.country}, {data.state} </div>
                                </div>
                                <div className='whitespace-pre-wrap break-words font-light mb-10' dangerouslySetInnerHTML={{ __html: data.description }} />
                                {data.addons?.length > 0 && <>
                                    <div className="font-bold text-2xl mb-5 cursive">Inclusions</div>
                                    <div className="flex items-center flex-wrap gap-2 mb-6">
                                        {data.addons?.map((ele, index) => (
                                            <div className="flex gap-2" key={index}>
                                                <div className="font-bold text-3xl cursive">{index + 1}.</div>
                                                <div className="bg-white whitespace-pre-wrap leading-6 py-3 px-5 text-sm shadow-xl rounded-lg">{ele.content}</div>
                                            </div>
                                        ))}
                                    </div>
                                </>}
                                <div className="">
                                    <div className="text-xl mb-3 font-bold"> Reservation Terms and <br /> Cancellation Policy</div>
                                    <div className="font-light mb-5">
                                        For refundable shortlets, cancellation deadline is 7 days before check-in date. 50% will be chargeable as cancellation fee. 7 days notice must be given for all cancellation requests and amendment will only be possible if same apartments are available and initial apartment rates are still the same. For non-refundable shortlets, bookings are non-refundable/non-amendable once payment is made, hence, no amendment or cancellation is permitted.
                                    </div>
                                </div>
                            </div>
                            <div className="lg:col-span-2">
                                <div className="mt-5 border p-5 shadow-2xl bg-white">
                                    <div className="font-bold text-2xl border-b py-3">Shortlet Base Fare</div>
                                    <div className="grid grid-cols-2 p-3">
                                        <div className="font-semibold">{data.pricetag}</div>
                                        <div className="font-thin text-right">{NairaSign}{data.price?.toLocaleString()}</div>
                                    </div>
                                    <div className="grid grid-cols-2 p-3">
                                        <div className="font-semibold">Discount</div>
                                        <div className="font-thin text-right">{data.discount}%</div>
                                    </div>
                                    <div className="grid grid-cols-2 p-3">
                                        <div className="font-semibold">Availability</div>
                                        <div className="font-thin text-right">{data.aval ? 'Available' : 'Not Available'}</div>
                                    </div>
                                    <div className="grid grid-cols-2 p-3 border-t text-xl">
                                        <div className="font-semibold">Total</div>
                                        <div className="font-semibold text-right">{NairaSign}{GetAmountMinusPercent(data.price, data.discount)?.toLocaleString()}</div>
                                    </div>
                                    <div className="grid grid-cols-1 gap-6 mt-5">
                                        <div className="">
                                            <button
                                                onClick={() => setViews(!views)}
                                                className="border border-sub py-3 px-4 w-full text-sm capitalize text-sub rounded-md flex items-center gap-2 justify-center"> <SlUserFollow /> Make reservation</button>
                                        </div>
                                        <div className="">
                                            <button onClick={() => window.open(`https://wa.me/+2347015582135`, "_blank")} className="border border-sub py-3 px-4 w-full justify-center text-sm capitalize text-sub rounded-md flex items-center gap-2"> <SlEarphonesAlt />  show contact </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PickUpPage