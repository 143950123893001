import { useEffect, useState } from "react"
import Forminput from "../utils/Forminput"
import Formbutton from "../utils/Formbutton"
import { FaArrowLeft } from "react-icons/fa6"
import { ErrorAlert, NairaSign, siteMail } from "../utils/functions"
import { Apis, Posturl } from "../Apis"
import haze from 'assets/main-images/haze.png'
import { FormHeader } from "../utils/FormHeader"
import Imaged from "../utils/Imaged"
import { useNavigate, useSearchParams } from "react-router-dom"

const LocalKey = 'sts'
export default function GeneralSharesForm({shares}) {
    const [search, setSearch] = useSearchParams('steps')
    const [screen, setScreen] = useState(parseInt(search.get('steps')) || 1)
    const locals = JSON.parse(localStorage.getItem(LocalKey))
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [forms, setForms] = useState({
        email: locals?.email || '',
        firstname: locals?.lastname || '',
        lastname: locals?.email || '',
        phone: locals?.phone || '',
        address: locals?.address || '',
        nationality: locals?.nationality || '',
        state: locals?.state || '',
        code: locals?.code || '',
        amount: locals?.amount || '',
        quantity: locals?.quantity || 0
    })

    useEffect(() => {
        if(!locals) {
            localStorage.setItem(LocalKey, JSON.stringify({}))
        }
    }, [])

    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }

    const VerifyEmail = async (e) => {
        e.preventDefault()
        if (!forms.email) return ErrorAlert('Email address is required')
        const data = {
            email: forms.email
        }
        setLoading(true)
        try {
            const response = await Posturl(Apis.shares.send_email_verification, data)
            if (response.status === 200) {
                navigate(`?steps=${screen + 1}`)
                return setScreen(prev => prev + 1)
            }
            return ErrorAlert(`${response.msg}`)
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoading(false)
        }
    }
    const ConfirmEmail = async (e) => {
        if (!forms.code) return ErrorAlert(`Provide your email verification code`)
        e.preventDefault()
        const data = {
            email: forms.email,
            code: forms.code
        }
        setLoading(true)
        try {
            const response = await Posturl(Apis.shares.confirm_send_email_verification, data)
            if (response.status === 200) {
                navigate(`?steps=${screen + 1}`)
                const storeData = {
                    ...locals,
                    email: forms.email
                }
                localStorage.setItem(LocalKey, JSON.stringify(storeData))
                return setScreen(prev => prev + 1)
            }
            return ErrorAlert(`${response.msg}`)
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoading(false)
        }
    }
    const InfoDetails = async (e) => {
        e.preventDefault()
        if (!forms.firstname) return ErrorAlert('First Name is required')
        if (!forms.lastname) return ErrorAlert('Last Name is required')
        if (!forms.phone) return ErrorAlert('Phone Number is required')
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            const storeData = {
                ...locals,
                firstname: forms.firstname,
                lastname: forms.lastname,
                phone: forms.phone,
            }
            localStorage.setItem(LocalKey, JSON.stringify(storeData))
            navigate(`?steps=${screen + 1}`)
            setScreen(prev => prev + 1)
        }, 3000);
    }
    const FinalSubmission = async e => {
        e.preventDefault()
        if (!forms.address) return ErrorAlert('Official Address is required')
        if (!forms.nationality) return ErrorAlert('Nationality is required')
        if (!forms.state) return ErrorAlert('State is required')
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            const storeData = {
                ...locals,
                address: forms.address,
                nationality: forms.nationality,
                state: forms.state,
            }
            localStorage.setItem(LocalKey, JSON.stringify(storeData))
            navigate(`?steps=${screen + 1}`)
            setScreen(prev => prev + 1)
        }, 3000);
    }

    const CalculateAmountToShares = e => {
        const val = e.target.value
        if(val.length > 0) {
            setForms({
                ...forms,
                quantity: val * shares.pricing
            })
        }else {
            setForms({
                ...forms,
                quantity: 0
            })
        }
    }

    const ProcessAmountToPay = e => {
        e.preventDefault()
        if(!forms.amount) return ErrorAlert('Provide amount of purchase')
        if(parseFloat(forms.quantity) < shares.min) return ErrorAlert(`Cannot purchase less than ${shares.min?.toLocaleString()} quantities of shares`)
        if(parseFloat(forms.quantity) > shares.max) return ErrorAlert(`Cannot purchase more than ${shares.max?.toLocaleString()} quantities of shares`)
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            const storeData = {
                ...locals,
                amount: forms.amount,
                quantity: forms.quantity,
            }
            localStorage.setItem(LocalKey, JSON.stringify(storeData))
            navigate(`?steps=${screen + 1}`)
            setScreen(prev => prev + 1)
        }, 3000);
    }

    const ProceedToPayment = async e => {
        e.preventDefault();

        setLoading(true)
        try {
            const response = await Posturl(Apis.shares.save_details, forms)
            if (response.status === 200){
                const url = window.location.origin
                const formdata = {
                    url: `${url}/verify-partnership`,
                    ...response.msg
                }
                const res = await Posturl(Apis.ercas.initiate_client_account_deposit, formdata)
                localStorage.removeItem(LocalKey)
                if (res.msg.requestSuccessful) {
                    window.location = res.msg.responseBody.checkoutUrl
                } else {
                    setLoading(false)
                    ErrorAlert(`${res.msg.responseMessage}`)
                }
            }else {
            return ErrorAlert(`${response.msg}`)
            }
        } catch (error) {
            setLoading(false)
            ErrorAlert(`${error.message}`)
        }
    }
    return (
        <>
            {screen === 1 && <div className="foil bg-slate-700/50 p-4 rounded-lg border">
                <form onSubmit={VerifyEmail} className="">
                    <FormHeader title="Verify My Email Address" setScreen={setScreen} />
                    <div className="mb-5 text-zinc-300">Kindly provide your email address for confirmation before we can proceed</div>
                    <Forminput formName="email" value={forms.email} onChange={handleForms} label={'Email Address'} />
                    <div className="ml-auto w-fit">
                        <Formbutton bg="bg-sub" title="Verify Email" loading={loading} />
                    </div>
                </form>
            </div>}
            {screen === 2 && <div className="foil bg-slate-700/50 p-4 rounded-lg border">
                <form onSubmit={ConfirmEmail} className="">
                    <FormHeader title="Confirm My Email Address" back={true} setScreen={setScreen} />
                    <div className="mb-5 text-zinc-300">Kindly provide the Confirmation code sent to your {forms.email?.slice(0, 3)}******{forms.email?.slice(-10)}</div>
                    <Forminput formName="code" value={forms.code} onChange={handleForms} label={'Confrimation Code'} />
                    <div className="ml-auto w-fit">
                        <Formbutton bg="bg-sub" title="Confirm Email" loading={loading} />
                    </div>
                </form>
            </div>}
            {screen === 3 && <div className="foil bg-slate-700/50 p-4 rounded-lg border">
                <form onSubmit={InfoDetails} className="">
                    <FormHeader title="Personal Information" back={true} setScreen={setScreen} />
                    <div className="mb-5 text-sm text-zinc-300">Kindly fill out the information below</div>
                    <Forminput formName={'firstname'} value={forms.firstname} onChange={handleForms} label={'First Name'} />
                    <Forminput formName={'lastname'} value={forms.lastname} onChange={handleForms} label={'Last Name'} />
                    <Forminput formName={'phone'} value={forms.phone} onChange={handleForms} label={'Phone Number'} />
                    <div className="ml-auto w-fit">
                        <Formbutton bg="bg-sub" title="Continue" loading={loading} />
                    </div>
                </form>
            </div>}
            {screen === 4 && <div className="foil bg-slate-700/50 p-4 rounded-lg border">
                <form onSubmit={FinalSubmission} className="">
                    <FormHeader title="Official Information" back={true} setScreen={setScreen} />
                    <div className="mb-5 text-sm text-zinc-300">Kindly fill out the information below</div>
                    <Forminput formName={'address'} value={forms.address} onChange={handleForms} label={'Official Address'} formtype="textarea" />
                    <Forminput formName={'nationality'} value={forms.nationality} onChange={handleForms} label={'Nationality'} />
                    <Forminput formName={'state'} value={forms.state} onChange={handleForms} label={'State'} />
                    <div className="ml-auto w-fit">
                        <Formbutton bg="bg-sub" title="Submit request" loading={loading} />
                    </div>
                </form>
            </div>}
            {screen === 5 && <div className="foil bg-slate-700/50 p-4 rounded-lg border">
                <form onSubmit={ProcessAmountToPay} className="">
                    <FormHeader title="Purchase Information" back={true} setScreen={setScreen} />
                    <div className="mb-5 text-sm text-zinc-300">Kindly fill out the information below</div>
                    <div className="">Amount to purchase</div>
                    <Forminput onKeyup={CalculateAmountToShares} type="number" formName={'amount'} value={forms.amount} onChange={handleForms} label={'Amount to purchase'} />
                    <div className="">Quantity of shares to recieve</div>
                    <Forminput formName={'quantity'} value={forms.quantity} readOnly onChange={handleForms} label={'Quantity of shares to recieve'} />
                    <div className="ml-auto w-fit">
                        <Formbutton bg="bg-sub" title="Submit request" loading={loading} />
                    </div>
                </form>
            </div>}
            {screen === 6 && <div className="foil bg-slate-700/50 p-4 rounded-lg border">
                <form onSubmit={ProceedToPayment} className="">
                    <div className="mb-5 text-lg">Summary of Purchase</div>
                    <div className="grid grid-cols-2 gap-5 py-2 border-b border-slate-700">
                        <div className="">Full Name</div>
                        <div className="text-right">{forms.firstname} {forms.lastname}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-5 py-2 border-b border-slate-700">
                        <div className="">Email Address</div>
                        <div className="text-right">{forms.email}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-5 py-2 border-b border-slate-700">
                        <div className="">Contact</div>
                        <div className="text-right">{forms.phone}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-5 py-2 border-b border-slate-700">
                        <div className="">Address</div>
                        <div className="text-right">{forms.address}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-5 py-2 border-b border-slate-700">
                        <div className="">Nationality</div>
                        <div className="text-right">{forms.nationality}, {forms.state}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-5 py-2 border-b border-slate-700">
                        <div className="">Purchasing Amount</div>
                        <div className="text-right">{NairaSign}{forms.amount}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-5 py-2  mb-10">
                        <div className="">Shares to accumulate</div>
                        <div className="text-right">{forms.quantity?.toLocaleString()}</div>
                    </div>
                    <div className="ml-auto w-fit">
                        <Formbutton bg="bg-sub" title="Proceed to Payment" loading={loading} />
                    </div>
                </form>
            </div>}

            {screen === 7 && <div className="foil bg-slate-700/50 p-4 rounded-lg border">
                <div className="mx-auto w-fit">
                    <Imaged src={haze} alt="" className="w-[15rem]" />
                </div>
                <div className="text-xl font-bold text-center">Request Recieved Successfully!.</div>
                <div className="mt-5 text-center text-zinc-300">Thnak you for indicating your interest in partnering with us. An agent will reach out to you shortly for further walk through.</div>
                <div className="mt-5 text-center text-zinc-300">you can also reach out to us @ <a className="text-blue-600" href={`mailto:${siteMail}`}>{siteMail}</a> </div>
            </div>}
        </>
    )
}