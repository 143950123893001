import { BanksLogo } from 'components/utils/Banks'
import Formbutton from 'components/utils/Formbutton'
import { ErrorAlert, NairaSign } from 'components/utils/functions'
import Imaged from 'components/utils/Imaged'
import ModalLayout from 'components/utils/ModalLayout'
import logs from "assets/main-images/fav.png"
import arrs from "assets/main-images/up-arrow.png"
import React from 'react'
import Forminput from 'components/utils/Forminput'

export default function ConfirmTransaction(props) {
    const { data } = props
    const Logo = BanksLogo.find(ele => data.bank_name?.toLowerCase().startsWith(ele.name?.toLowerCase()))
    const [pin, setPin] = React.useState("")

    function HandleVerification() {
        if (!pin) return ErrorAlert('Provide your transaction PIN to proceed')
        const dataValues = {
            ...data,
            pin: pin,

        }
        return props.onClick(dataValues)
    }
    return (
        <ModalLayout closeView={props.closeview}>
            <div className="text-sm p-3 text-main">
                {Logo?.name && <div className="flex items-center justify-center gap-5 mb-5">
                    <Imaged src={logs} className="h-6 w-auto" />
                    <Imaged src={arrs} className="h-8 w-auto rotate-90" />
                    <Imaged src={Logo.logo} className="h-10 w-auto" />
                </div>}
                <div className={`font-bold text-4xl text-center ${Logo?.name ? ' pb-5 -mt-5' : 'py-5'}`}> <sup className="font-medium text-xl">{NairaSign}</sup> {(parseFloat(data.amount) + data.fee).toLocaleString()}</div>
                <div className="grid grid-cols-5 py-1.5">
                    <div className="col-span-2 text-slate-500">Bank</div>
                    <div className="col-span-3 text-right font-medium">{data.bank_name}</div>
                </div>
                <div className="grid grid-cols-5 py-1.5">
                    <div className="col-span-2 text-slate-500">Account Number</div>
                    <div className="col-span-3 text-right font-medium">{data.bank_account_number}</div>
                </div>
                <div className="grid grid-cols-5 py-1.5">
                    <div className="col-span-2 text-slate-500">Name</div>
                    <div className="col-span-3 text-right font-medium">{data.account_name}</div>
                </div>
                <div className="grid grid-cols-5 py-1.5">
                    <div className="col-span-2 text-slate-500">Amount</div>
                    <div className="col-span-3 text-right font-medium">{NairaSign}{data.amount.toLocaleString()}</div>
                </div>
                <div className="grid grid-cols-5 py-1.5 mb-5">
                    <div className="col-span-2 text-slate-500">Narration</div>
                    <div className="col-span-3 text-right font-medium"> {data.narration} </div>
                </div>
                <div className="">
                    <Forminput content="Transaction PIN" formName="pin" type="password" value={pin} onChange={e => setPin(e.target.value)} />
                </div>
                <Formbutton handleSubmission={HandleVerification} title="Proceed to pay" />
            </div>
        </ModalLayout>
    )
}
