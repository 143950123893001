import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import UserLayout from "components/user/UserLayout";
import { useEffect, useState } from "react";
import img from 'assets/main-images/img8.jpeg'
import { BsEnvelope, BsHeadset, BsWhatsapp } from "react-icons/bs";
import { siteContact, siteMail } from "components/utils/functions";
import { FaEnvelope } from "react-icons/fa6";
import ChatModal from "../ChatSupport/ChatModal";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { Apis, Authgeturl, SocketConnect } from "components/Apis";
import FaqComponent from "components/general/FaqComponent";
import Imaged from "components/utils/Imaged";


const Staffs = [
    { img: img },
    { img: img },
    { img: img },
    { img: img },
]

export default function SupportPage() {
    const { user } = useSelector(state => state.data)
    const OpenWhatsapp = () => {
        window.open(`https://wa.me/${siteContact}`, '_blank')
    }

    const { data, refetch } = useQuery({
        queryKey: ['user-get-all-admin'],
        queryFn: async () => {
            const res = await Authgeturl(Apis.user.all_admin_staffs)
            return res.msg
        }
    })

    useEffect(() => {
        SocketConnect.on('admin-activity-staff', () => refetch())
    }, [SocketConnect])
    return (
        <UserLayout title="Support">
            <div className="w-11/12 mx-auto max-w-2xl">
                <div className="flex items-center justify-center relative">
                    {Staffs.map((item, index) => (
                        <Imaged src={item.img} alt="" className={`w-14 h-14 -ml-6 rounded-full border-2 border-white shadow-xl`} style={{ zIndex: `${20 - index}` }} key={index} />
                    ))}
                </div>
                <div className="bg-white mt-5 shadow-2xl py-3 px-4 rounded-lg mb-10 grid grid-cls-1 lg:grid-cols-5 gap-3">
                    <div className="lg:col-span-3">
                        <div className="font-semibold">Speck directly to one of our agents</div>
                        <div className=" mb-3"> <a href={`mailto:${siteMail}`} target="_blank" rel="no-referrer" className="flex items-center gap-2"> <FaEnvelope /> {siteMail} </a> </div>
                        <div onClick={OpenWhatsapp} className="flex items-center w-fit gap-2 text-green-500 cursor-pointer"> <BsWhatsapp /> <span>{siteContact}</span> </div>
                    </div>
                    <div className="lg:col-span-2 border-t pt-2 lg:border-none">
                        <div className="flex items-center justify-center flex-col gap-2">
                            <BsHeadset className="text-4xl" />
                            <ChatModal
                                staffs={data}
                                user={user} />
                        </div>
                    </div>
                </div>
                <div className="font-bold text-xl lg:text-3xl my-5 text-center">Frequently Asked Questions</div>
                <div className="bg-white shadow-xl ">
                    <FaqComponent />
                </div>
            </div>
        </UserLayout>
    )
}