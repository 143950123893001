
import ALayout from 'components/admin/ALayout'
import React, { useEffect, useState } from 'react'
import ServiceForm from './ServiceForm'
import { Apis, Authgeturl } from 'components/Apis'
import { Link, useParams } from 'react-router-dom'
import { FaArrowLeftLong } from 'react-icons/fa6'
import { ErrorAlert } from 'components/utils/functions'

export default function EditNcwalletServices() {
    const {id} = useParams()
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        (async () => {
            setLoading(true)
            try {
                const res = await Authgeturl(`${Apis.ncwallet.service}/${id}`)
                return setData(res.data)
            } catch (error) {
                ErrorAlert(`${error.message}`)
            }finally {
                setLoading(false)
            }
        })()
    }, [id])

    if(loading) return (
        <ALayout>
        <div className="font-bold text-xl flex items-center gap-3"> <Link to="/admin/ncwallet-services"><FaArrowLeftLong /></Link> Update Service</div>
        <div className="tex-center">Loading Data....</div>
        </ALayout>
    )

  return (
    <ALayout>
      <div className="font-bold text-xl flex items-center gap-3"> <Link to="/admin/ncwallet-services"><FaArrowLeftLong /></Link> Update Service</div>
      <ServiceForm singleData={data} />
    </ALayout>
  )
}
