import Footer from "components/general/Footer"
import Header from "components/general/Header"
import { PickupUsersFront } from "components/utils/functions"
import PickUpPage from "./PickUpPage"

const HomeShortletPage = () => {
    return (
        <div>
            <Header />
            <div className="my-10">
                <PickUpPage 
                usertag={PickupUsersFront.general}
                tagged={null}
                />
            </div>
            <Footer />
        </div>
    )
}

export default HomeShortletPage