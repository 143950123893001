
// import React, { useState } from 'react'
// import SideViewModalLayout from 'components/utils/SideViewModalLayout'
// import { Apis, Authposturl, Authputurl } from 'components/Apis'
// import { Link } from 'react-router-dom'
// import { ErrorAlert, Errors, SuccessAlert } from 'components/utils/functions'
// import Formbutton from 'components/utils/Formbutton'
// import Forminput from 'components/utils/Forminput'
// import { SlPencil, SlTrash } from 'react-icons/sl'
// import ServiceTag from 'components/admin/ServiceTag'

// export default function ServicesModal({ closeView, item, refetch }) {
//     const [screen, setScreen] = useState(1)
//     const [loading, setLoading] = useState(false)
//     const [single, setSingle] = useState({
//         status: false,
//         id: null
//     })
//     const [forms, setForms] = useState({
//         charges: item?.charges || '',
//         cashback: item?.cashback || '',
//         servicename: item?.servicename || '',
//         id: item?.id || null,
//     })
//     const [plans, setPlans] = useState(item?.plans || [])
//     const [planform, setPlanForm] = useState({
//         servicetype: '',
//         serviceid: '',
//         aval: 'true',
//         id: null
//     })

//     const handlePlanForms = e => {
//         setPlanForm({ ...planform, [e.target.name]: e.target.value })
//     }

//     const handleForms = e => {
//         setForms({ ...forms, [e.target.name]: e.target.value })
//     }

//     const handleSubmission = async (e) => {
//         e.preventDefault()
//         setLoading(true)
//         try {
//             const response = await Authposturl(Apis.serviceapi.delete_service, { id: item.id })
//             if (response.status === 200) {
//                 SuccessAlert(response.msg)
//                 refetch()
//                 return closeView()
//             } else {
//                 ErrorAlert(response.msg)
//             }
//         } catch (e) {
//             Errors(`${e}`)
//         } finally {
//             setLoading(false)
//         }
//     }

//     const handleUpdating = async e => {
//         e.preventDefault()
//         const formdata = {
//             charges: forms.charges,
//             cashback: forms.cashback,
//             servicename: forms.servicename,
//             id: item.id,
//             serviceplans: plans
//         }
//         setLoading(true)
//         try {
//             const response = await Authputurl(Apis.serviceapi.update_service, formdata)
//             if (response.status === 200) {
//                 SuccessAlert(response.msg)
//                 refetch()
//                 return closeView()
//             } else {
//                 ErrorAlert(response.msg)
//             }
//         } catch (e) {
//             Errors(`${e}`)
//         } finally {
//             setLoading(false)
//         }
//     }
//     const AddupPlan = () => {
//         if (!planform.servicetype) return ErrorAlert('Provide a valid plan name')
//         if (!planform.serviceid) return ErrorAlert('Provide a valid plan ID')
//         if (!planform.aval) return ErrorAlert('Provide a plan availability status')
//         if(!single.id) {
//             setPlans([
//                 ...plans,
//                 planform
//             ])
//             SuccessAlert('Plan Added successfully')
//             setPlanForm({
//                 servicetype: '',
//                 serviceid: '',
//                 aval: 'true,'
//             })
//         }else {
//             const allplans = plans.map(ele => {
//                 if(ele.serviceid === single.id) {
//                     return {
//                      ...ele,
//                         servicetype: planform.servicetype,
//                         serviceid: planform.serviceid,
//                         aval: planform.aval
//                     }
//                 }
//                 return ele
//             })
//             setPlans(allplans)
//             SuccessAlert('Plan Added successfully')
//             setPlanForm({
//                 servicetype: '',
//                 serviceid: '',
//                 aval: 'true,'
//             })
//             setSingle({
//                 status: false,
//                 id: null
//             })
//         }
//     }

//     const UpdatePlan = ele => {
//         setPlanForm({
//             servicetype: ele.servicetype,
//             serviceid: ele.serviceid,
//             aval: ele.aval
//         })
//         setSingle({
//             status: true,
//             id: ele.serviceid
//         })
//     }
//     return (
//         <SideViewModalLayout closeView={closeView}>
//             {screen === 1 && <div className="p-4 mt-10">
//                 <div className="mt-5">
//                     <div className="grid grid-cols-2 gap-5 mb-6">
//                         <button onClick={() => setScreen(3)} className="bg-main capitalize py-2 px-5 text-sm text-white">update</button>
//                         <button
//                             onClick={() => setScreen(2)}
//                             className="bg-red-600 capitalize py-2 px-5 text-sm text-white">delete</button>
//                     </div>
//                 </div>
//                 <div className="mb-3 border-b pb-3">
//                     <div className="font-bold text-lg">Service</div>
//                     <div className="">{item.servicename}</div>
//                 </div>
//                 <div className="mb-3 border-b pb-3">
//                     <div className="font-bold text-lg">Charges</div>
//                     <div className="">{item.charges}%</div>
//                 </div>
//                 <div className="mb-3 border-b pb-3">
//                     <div className="font-bold text-lg">Cashback</div>
//                     <div className="">{item.cashback}%</div>
//                 </div>
//                 <div className="mt-5 border-b">Service Plans</div>
//                 <div className="flex flex-col gap-3">
//                     {item.plans.map((ele, index) => (
//                         <div className="grid grid-cols-2 gap-3" key={index}>
//                             <div className="flex items-center gap-2">
//                                 <ServiceTag tag={ele.aval} />
//                             <div className="">{ele.servicetype}</div>
//                             </div>
//                             <div className="text-right">{ele.serviceid}</div>
//                         </div>
//                     ))}
//                 </div>
//             </div>}
//             {screen === 2 && <div className="p-4 mt-10">
//                 <div className="mb-5">Delete <span className="text-red-500">{item.title}</span> </div>
//                 <div className="text-center mb-10">Are you sure you want to delete this service</div>
//                 <Formbutton
//                     title="Delete"
//                     loading={loading}
//                     handleSubmission={handleSubmission}
//                 />
//             </div>}

//             {screen === 3 && <div className="p-4 mt-10">
//                 <form onSubmit={handleUpdating}>
//                     <div className="mb-5">
//                         <div className="font-bold text-xl">Update Service</div>
//                     </div>
//                     <div className="mb-3">
//                         <div className="">Service ID</div>
//                         <Forminput formName="servicename" value={forms.servicename} onChange={handleForms} type="text" />
//                     </div>
//                     <div className="mb-3">
//                         <div className="">Fee</div>
//                         <Forminput formName="charges" value={forms.charges} onChange={handleForms} type="number" />
//                     </div>
//                     <div className="mb-3">
//                         <div className="">Cashback (%)</div>
//                         <Forminput formName="cashback" value={forms.cashback} onChange={handleForms} type="number" />
//                     </div>
//                     <div className="bg-white">
//                         <div className="border-b">
//                             <div className="">Update Service plans</div>
//                         </div>
//                         <div className="grid grid-cols-1 gap-5">
//                             <div className="">
//                                 <div className="mb-4">
//                                     <div className="">Service type</div>
//                                     <Forminput formName={'servicetype'} value={planform.servicetype} onChange={handlePlanForms} type="text" />
//                                 </div>
//                                 <div className="mb-4">
//                                     <div className="">Service ID</div>
//                                     <Forminput formName={'serviceid'} value={planform.serviceid} onChange={handlePlanForms} type="text" />
//                                 </div>
//                                 <div className="mb-4">
//                                     <div className="">Service Availability</div>
//                                     <Forminput formName={'aval'} value={planform.aval} formtype='select' onChange={handlePlanForms}>
//                                     <option value="true">Yes</option>
//                                     <option value="false">No</option>
//                                     </Forminput>
//                                 </div>
//                                 <div className="w-fit ml-auto">
//                                     <button type="button" onClick={AddupPlan} className={`${!single.id ? 'bg-main' : 'bg-blue-500'} py-3 px-5 rounded-lg capitalize text-white`}>{!single.id ? 'add' : 'update'} plan</button>
//                                 </div>
//                             </div>
//                             <div className="">
//                                 {plans.length > 0 ? <>
//                                     <div className="border-b">
//                                         <div className="">Service plan Summary</div>
//                                     </div>
//                                     <div className="flex flex-col">
//                                         {plans.map((ele, index) => (
//                                             <div className="border-b last:border-none p-2.5" key={index}>
//                                                 <div className="grid grid-cols-6">
//                                                     <div className="col-span-4">
//                                                         <div className="grid grid-cols-2">
//                                                            <div className="flex items-center gap-2">
//                                                             <ServiceTag tag={ele.aval} />
//                                                            <div className="">{ele.servicetype}</div>
//                                                            </div>
//                                                             <div className="text-right">{ele.serviceid}</div>
//                                                         </div>
//                                                     </div>
//                                                     <div className="col-span-2 p-2">
//                                                         <div className="flex w-fit ml-auto gap-10">
//                                                             <button type="button" onClick={() => UpdatePlan(ele)} className="text-blue-600"> <SlPencil /> </button>
//                                                             <button type="button" onClick={() => setPlans(plans.filter((e, i) => i !== index))} className="text-red-600"> <SlTrash /> </button>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         ))}
//                                     </div>
//                                 </> :
//                                     <div className="text-center text-zinc-500">Add Plans to see your summary</div>
//                                 }
//                             </div>
//                         </div>
//                     </div>
//                     <div className="w-fit ml-auto mt-12">
//                         <Formbutton loading={loading} title="Update Service" />
//                     </div>
//                 </form>
//             </div>}
//         </SideViewModalLayout>
//     )
// }



import React, { useState } from 'react'
import SideViewModalLayout from 'components/utils/SideViewModalLayout'
import { Apis, Authposturl, Authputurl } from 'components/Apis'
import { ErrorAlert, Errors, SuccessAlert } from 'components/utils/functions'
import Formbutton from 'components/utils/Formbutton'
import Forminput from 'components/utils/Forminput'
import ServiceTag from 'components/admin/ServiceTag'
import ModalLayout from 'components/utils/ModalLayout'

export default function ServicesModal({ closeView, item, refetch }) {
    const [screen, setScreen] = useState(1)
    const [loading, setLoading] = useState(false)
    
    const [forms, setForms] = useState({
        charges: item?.charges || '',
        cashback: item?.cashback || '',
        servicename: item?.servicename || '',
        id: item?.id || null,
    })

    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }

    const handleSubmission = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const response = await Authposturl(Apis.serviceapi.delete_service, { id: item.id })
            if (response.status === 200) {
                SuccessAlert(response.msg)
                refetch()
                return closeView()
            } else {
                ErrorAlert(response.msg)
            }
        } catch (e) {
            Errors(`${e}`)
        } finally {
            setLoading(false)
        }
    }

    const handleUpdating = async e => {
        e.preventDefault()
        const formdata = {
            charges: forms.charges,
            cashback: forms.cashback,
            servicename: forms.servicename,
            id: item.id,
            tag: 'update'
        }
        setLoading(true)
        try {
            const response = await Authputurl(Apis.serviceapi.update_service, formdata)
            if (response.status === 200) {
                SuccessAlert(response.msg)
                refetch()
                return closeView()
            } else {
                ErrorAlert(response.msg)
            }
        } catch (e) {
            Errors(`${e}`)
        } finally {
            setLoading(false)
        }
    }
    
    return (
        <ModalLayout closeView={closeView}>
            {screen === 1 && <div className="p-4 mt-10">
                <div className="mt-5">
                    <div className="grid grid-cols-2 gap-5 mb-6">
                        <button onClick={() => setScreen(3)} className="bg-main capitalize rounded-lg py-2 px-5 text-sm text-white">update</button>
                        {/* <button
                            onClick={() => setScreen(2)}
                            className="bg-red-600 capitalize py-2 px-5 text-sm text-white">delete</button> */}
                    </div>
                </div>
                <div className="mb-3 border-b pb-3">
                    <div className="font-bold text-lg">Service</div>
                    <div className="uppercase">{item.servicename}</div>
                </div>
                <div className="mb-3 border-b pb-3">
                    <div className="font-bold text-lg">Charges</div>
                    <div className="">{item.charges}%</div>
                </div>
                <div className="mb-3 border-b pb-3">
                    <div className="font-bold text-lg">Cashback</div>
                    <div className="">{item.cashback}%</div>
                </div>
            </div>}
            {screen === 2 && <div className="p-4 mt-10">
                <div className="mb-5">Delete <span className="text-red-500">{item.title}</span> </div>
                <div className="text-center mb-10">Are you sure you want to delete this service</div>
                <Formbutton
                    title="Delete"
                    loading={loading}
                    handleSubmission={handleSubmission}
                />
            </div>}

            {screen === 3 && <div className="p-4 mt-10">
                <form onSubmit={handleUpdating}>
                    <div className="mb-5">
                        <div className="font-bold text-xl">Update Service</div>
                    </div>
                    <div className="mb-3">
                        <div className="">Service ID</div>
                        <Forminput formName="servicename" value={forms.servicename} onChange={handleForms} type="text" />
                    </div>
                    <div className="mb-3">
                        <div className="">Fee</div>
                        <Forminput formName="charges" value={forms.charges} onChange={handleForms} type="number" />
                    </div>
                    <div className="mb-3">
                        <div className="">Cashback (%)</div>
                        <Forminput formName="cashback" value={forms.cashback} onChange={handleForms} type="number" />
                    </div>
                    <div className="w-fit ml-auto mt-12">
                        <Formbutton loading={loading} title="Update Service" />
                    </div>
                </form>
            </div>}
        </ModalLayout>
    )
}

