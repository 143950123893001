import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: {},
  plan: {},
  profile: {},
  account: {},
  services: [],
  plans: [],
  activeplan: {},
  alerts: {},
  homechat: false
}

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    dispatchHomeChat: (state, action) => {
      state.homechat = action.payload
    },
    dispatchActivePlan: (state, action) => {
      state.activeplan = action.payload
    },
    dispatchUser: (state, action) => {
        state.user = action.payload
    },
    dispatchAlerts: (state, action) => {
        state.alerts = action.payload
    },
    dispatchAccount: (state, action) => {
        state.account = action.payload
    },
    dispatchProfile: (state, action) => {
      state.profile = action.payload
    },
    dispatchPlan: (state, action) => {
      state.plan = action.payload
    },
    dispatchServices: (state, action) => {
      state.services = action.payload
    },
    dispatchPlans: (state, action) => {
      state.plans = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { dispatchUser, dispatchHomeChat, dispatchActivePlan, dispatchPlan, dispatchAlerts, dispatchAccount, dispatchPlans, dispatchServices, dispatchProfile } = counterSlice.actions

export default counterSlice.reducer