import { dispatchUser } from 'app/reducer'
import { Apis, Authgeturl, Authposturl } from 'components/Apis'
import UserLayout from 'components/user/UserLayout'
import BankOptions from 'components/utils/BankOptions'
import Formbutton from 'components/utils/Formbutton'
import Forminput from 'components/utils/Forminput'
import { ErrorAlert, NairaSign, Swals } from 'components/utils/functions'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import RedeemData from './RedeemData'
import Selectinput from 'components/utils/Selectinput'
import { MenuItem } from '@mui/material'
import Spinner from 'components/utils/Spinner'
import { PiSealCheckFill } from 'react-icons/pi'
import { usePDF } from 'react-to-pdf'
import RedeemConfirmation from './Compos/RedeemConfirmation'
import SuccessModal from 'components/utils/SuccessModal'

export default function RedeemReward() {
  const [active1, setActive1] = useState(true)
  const [active2, setActive2] = useState(false)
  const { user } = useSelector(state => state.data)
  const [amountError, setAmountError] = useState('')
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [rewards, setRewards] = useState([])
  const [confirms, setConfirms] = useState({ status: false, data: {} })
  const [open, setOpen] = useState({ status: false, data: {} })
  const [forms, setForms] = useState({
    bank: '',
    bank_code: '',
    accnumber: '',
    network: '',
    bundle: '',
    phone: '',
    fee: 20,
    amount: '',
  })
  const [info, setInfo] = useState({})
  const [load3, setLoad3] = useState(false)

  const Options = [
    "Redeem to Data",
    "Redeem to Cash",
  ]

  const date = new Date()

  const { toPDF, targetRef } = usePDF({
      filename: `${date.getFullYear()}_${date.getTime()}.pdf`,
      canvas: {
          useCORS: true,
      },
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await Authgeturl(Apis.mobilenig.all_datarewards)
        return setRewards(response.data)
      } catch (error) {
        ErrorAlert(`${error.message}`)
      }
    })()
  }, [])

  const handleActive1 = () => {
    if (!active1) {
      setActive1(true)
      setActive2(false)
    } else {
      setActive1(false)
    }
  }

  const handleActive2 = () => {
    if (!active2) {
      setActive2(true)
      setActive1(false)
    } else {
      setActive2(false)
    }
  }
  const handleAmountCheck = e => {
    const value = e.target.value
    if (value) {
      const item = rewards.find(ele => ele.amount === value)
      if (item.bundle > user.coinbal) {
        return setAmountError(`Insufficient data balance to continue`)
      }
      setForms({ ...forms, amount: item.amount, bundle: item.bundle })
      return setAmountError('')
    }
  }

  const setup = (val) => {
    setForms({ ...forms, bank: val.bank_name, bank_code: val.bank_code })
  }

  const SubmitRequest = async (values) => {
    setLoading(true)
    const sendData = {
      ...values,
      accname: info.account_name
    }
    try {
      const response = active1 ? null : await Authposturl(Apis.wireless.redeem_to_bank, sendData)
      if (![200, 201].includes(response.status)) return ErrorAlert(`${response.msg}`)
      Swals('Request Success', response.msg, `${response.status === 201 ? 'info' : 'success'}`)
      if (response.status === 200) {
        dispatch(dispatchUser(response.user))
      }
      return navigate('/transactions')
    } catch (error) {
      ErrorAlert(`${error.message}`)
    } finally {
      setLoading(false)
    }
  }

  function OpenNextModal(values) {
    setConfirms({
        ...confirms,
        status: false
    })
    setOpen({ ...open, data: values })
    SubmitRequest(values)
}

  async function handleAccountNumber(e) {
    const value = e.target.value
    setForms({ ...forms, accnumber: value })
    setInfo({})
    setLoad3(true)
    try {
      if (value.length === 10) {

        const sendForm = {
          bank_code: forms.bank_code, account_number: value
        }
        const response = await Authposturl(Apis.wireless.verify_external_bank, sendForm)
        if (response.status !== 200) return ErrorAlert(`${response.msg}`)
        setInfo(response.msg)
      }
    } catch (error) {
      ErrorAlert(`Wrong account details supplied, check and try again`)
    } finally {
      setLoad3(false)
    }
  }

  function ConfirmSubmitRequest(e) {
    e.preventDefault()

    if (!forms.bank) return ErrorAlert('Select a valid bank')
    if (!forms.accnumber) return ErrorAlert('Account number is required')
    if (!forms.amount) return ErrorAlert('Amount to convert is required')
    if (amountError) return ErrorAlert(amountError)
    const sendData = {
        ...forms,
        account_name: info?.account_name,
        title: "Transaction Successful",
        content: `<div>Congratulations your transfer of <span style="color: #16a34a;font-weight:bold;">${forms.bundle.toLocaleString()}MB</span> to <span style="color: #16a34a;font-weight:bold;">${info.account_name}</span>  was successful</div>`,
        content2: `Congratulations your transfer of ${forms.bundle.toLocaleString()}MB to ${info.account_name}  was successful`
    }
    setConfirms({
        status: true,
        data: sendData
    })
  }

  return (
    <UserLayout title="Redeem Reward">
    {open.status && <SuccessModal
        closeview={() => setOpen({ status: false, data: {} })}
        title={confirms.data.title}
        content2={confirms.data.content2}
        content={confirms.data.content}
        cashback={false}
        Dismiss={'/transactions'}
        DownloadPDF={() => toPDF()}
    />}
    {confirms.status && <RedeemConfirmation
        data={confirms.data}
        onClick={OpenNextModal}
        closeview={() => setConfirms({ status: false, data: {} })}
    />}

    <div ref={targetRef}>
        {open.status ? <div>
            <div className="grid grid-cols-5 py-1.5">
                <div className="col-span-2 text-slate-500">Bank</div>
                <div className="col-span-3 text-right font-medium">{confirms.data.bank_name}</div>
            </div>
            <div className="grid grid-cols-5 py-1.5">
                <div className="col-span-2 text-slate-500">Account Number</div>
                <div className="col-span-3 text-right font-medium">{confirms.data.bank_account_number}</div>
            </div>
            <div className="grid grid-cols-5 py-1.5">
                <div className="col-span-2 text-slate-500">Name</div>
                <div className="col-span-3 text-right font-medium">{confirms.data.account_name}</div>
            </div>
            <div className="grid grid-cols-5 py-1.5">
                <div className="col-span-2 text-slate-500">Amount</div>
                <div className="col-span-3 text-right font-medium">{NairaSign}{confirms.data.amount.toLocaleString()}</div>
            </div>
            <div className="grid grid-cols-5 py-1.5">
                <div className="col-span-2 text-slate-500">Converting Bundle</div>
                <div className="col-span-3 text-right font-medium">{NairaSign}{confirms.data.bundle.toLocaleString()}</div>
            </div>
            <div className="grid grid-cols-5 py-1.5 mb-5">
                <div className="col-span-2 text-slate-500">Narration</div>
                <div className="col-span-3 text-right font-medium"> {confirms.data.narration} </div>
            </div>
            <div className="grid grid-cols-5 py-1.5 mb-5">
                <div className="col-span-2 text-slate-500">Status</div>
                <div className="col-span-3 text-right font-medium"> Successful </div>
            </div>
        </div> : ''}
    </div>
      <div className="w-11/12 mx-auto mt-5 bg-white p-4 rounded-md">
        <div className="py-4"> <span className="text-sub">NOTE:</span> You can convert your GB reward to cash instantly into your bank account</div>
        <div className="grid grid-cols-1 gap-8">
          <div>
            <div onClick={handleActive1}
              className="cursor-pointer flex items-center gap-3 bg-sub py-5 px-3 rounded-lg mb-5 text-white">
              <div className={`border-2 border-white rounded-full size-7 ${active1 ? 'bg-white' : ''}`}></div>
              {Options[0]}
            </div>
            {active1 && <>
              <RedeemData />
            </>}
          </div>

          {rewards.length > 0 && <div>
            <div onClick={handleActive2}
              className="cursor-pointer flex items-center gap-3 bg-sub py-5 px-3 rounded-lg text-white mb-5">
              <div className={`border-2 border-white rounded-full size-7 ${active2 ? 'bg-white' : ''}`}></div>
              {Options[1]}
            </div>
            {active2 && <>
              <div className="mb-3 relative">
                <div className="">--Select Bank--</div>
                <BankOptions title="Select Bank" setup={setup} />
              </div>
              <Forminput formName={'accnumber'} value={forms.accnumber} onChange={handleAccountNumber} type="number" content={'Account Number'} lable="Account Number" />

              {load3 && <div className=""> <Spinner /> </div>}
              {info?.account_name && <div className="text-sm text-green-800 uppercase py-2 px-3 rounded-lg bg-green-200/40 border border-green-500 flex mb-3 items-center gap-2"> <PiSealCheckFill /> {info.account_name}</div>}

              <Selectinput content="Data to convert" value={forms.amount} onChange={handleAmountCheck} formName={'amount'}>
                {rewards.map((item, index) => (
                  <MenuItem key={index} value={item.amount}>{item.bundle}MB</MenuItem>
                ))}
              </Selectinput>
              {amountError && <div className="text-sm text-red-700">{amountError}</div>}

              {!amountError && forms.amount && <div className="">Amount to receive: <span className="font-bold">{NairaSign}{forms.amount?.toLocaleString()}</span> </div>}
              <div className="">Current Reward balance: <span className="text-sub font-bold">{user.coinbal?.toLocaleString()}MB</span> </div>
              <div className=" mt-10">
                <Formbutton
                  disable={forms.accnumber.length === 10 ? false : true}
                  handleSubmission={ConfirmSubmitRequest} loading={loading} title="Sumbit request" />
              </div>
            </>}
          </div>}
        </div>
      </div>
    </UserLayout>
  )
}
