

import React, { useCallback, useEffect, useState } from 'react'
import AHeader from 'components/admin/AHeader'
import { Apis, Authgeturl } from 'components/Apis'
import { Errors } from 'components/utils/functions'
import moment from 'moment'
import Spinner from 'components/utils/Spinner'
import UserLayout from 'components/user/UserLayout'
import Imaged from 'components/utils/Imaged'
import emptyImage from "assets/main-images/empty.png"


const Tableheaders = [
    "Full Name",
    "Account Plan",
    "Status",
    "Suspended",
    "Verified",
    "Last Login",
    "date created",
]

const c1 = 'bg-green-300/20 text-green-700 text-center p-1.5 truncate rounded-md capitalize text-sm font-medium'
const c2 = 'bg-red-300/20 text-red-700 text-center p-1.5 truncate rounded-md capitalize text-sm font-medium'

export default function Referrals() {
    const [plans, setPlans] = useState({})
    const [loading, setLoading] = useState(false)

    const FetchAllPlans = useCallback(async () => {
        setLoading(true)
        try {
            const res = await Authgeturl(Apis.user.my_referrals)
            if (res.status === 200) return setPlans(res)
        } catch (error) {
            Errors(error)
        } finally {
            setLoading(false)
        }
    }, [])
    useEffect(() => { FetchAllPlans() }, [FetchAllPlans])


    if (loading) return (
        <UserLayout>
            <div className="">
                <AHeader
                    title="total Account Plans"
                    total={0}
                    url={false}
                />
                <div className="overflow-x-auto scrollsdown">
                    <div className="mt-10 w-full">
                        {loading && <Spinner />}
                    </div>
                </div>
            </div>
        </UserLayout>
    )
    return (
        <UserLayout>
            <div className="w-11/12 mx-auto mt-10">
                <AHeader
                    title="total Referred"
                    total={plans.total}
                    url={false}
                />
                <div className="overflow-x-auto scrollsdown">
                    <div className="mt-10 w-full">
                        <table className='table table-auto w-full bg-white p-3 text-sm'>
                            <thead>
                                <tr className='bg-slate-700 text-white'>
                                    {Tableheaders.map((ele, i) => (
                                        <td className='capitalize p-3 truncate' key={i}>{ele}</td>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {plans.data?.map((item, i) => (
                                    <tr
                                        key={i}
                                        className='cursor-pointer border-b border-r border-l'
                                    >
                                        <td className='p-3 truncate'>{item.firstname} {item.lastname}</td>
                                        <td className='p-3 truncate'>{item.accountplan}</td>
                                        <td className='p-3'><div className={`${item.status === 'online' ? c1 : c2}`}>{item.status}</div> </td>
                                        <td className='p-3'> <div className={`${item.suspended === 'true' ? c2 : c1}`}>{item.suspended === 'true' ? 'Suspended' : 'Not Suspended'}</div> </td>
                                        <td className='p-3'> <div className={`${item.verified === 'true' ? c1 : c2}`}>{item.verified === 'true' ? 'Verified' : 'Not Verified'}</div> </td>
                                        <td className='p-3 truncate'>{item.lastlogin}</td>
                                        <td className='p-3 truncate'>{moment(item.createdAt).format('DD-MM-YYYY hh:ss A')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {plans.total < 1 && 
                                <div className="flex items-center justify-center flex-col">
                                    <Imaged src={emptyImage} className="size-20" />
                                    <div className="text-slate-500 text-sm">No records found</div>
                                </div>}
                    </div>
                </div>
            </div>
        </UserLayout>
    )
}


