
import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment';
import AHeader from 'components/admin/AHeader';
import Spinner from 'components/utils/Spinner';
import { Errors } from 'components/utils/functions';
import { Apis, Authgeturl } from 'components/Apis';
import ShareRequestModal from './ShareRequestModal';
import ALayout from 'components/admin/ALayout';

const Tableheaders = [
  "Full Name",
  "Email Address",
  "Phone",
  "Nationality",
  "Email Verified",
  "Request Status",
  "date requested",
]
// https://admin.wakacommunity.com

export default function SharesRequests() {
  const [plans, setPlans] = useState([])
  const [loading, setLoading] = useState(true)
  const [, setShareclass] = useState([])
  const [, setAsset] = useState({})
  const [single, setSingle] = useState({})
  const [view, setView] = useState(false)

  const FetchShareClasses = useCallback(async () => {
    setLoading(true)
      try {
          const res = await Authgeturl(Apis.shares.all_shareholder_requests)
          if (res.status === 200) {
            setPlans(res.msg)
            setShareclass(res.shareclass)
            setAsset(res.asset)
          }
      } catch (error) {
          Errors(`${error}`)
      } finally {
          setLoading(false)
      }
  }, [])
  useEffect(() => { FetchShareClasses() }, [FetchShareClasses])

  const handleSingle = val => {
      setSingle(val)
      setView(!view)
  }
  return (
    <ALayout>
        {view && <ShareRequestModal 
        single={single}
        closeView={() => setView(false)}
        /> }
        <div className="">
            <div className="mb-5 text-xl font-bold">Shares Requests</div>
            <AHeader
                total={plans.length}
                tag=""
                url={false}
            />
            <div className="mt-10">
                {!loading && <table className='table w-full p-3 text-sm bg-white table-auto'>
                    <thead>
                        <tr className='text-white bg-slate-700'>
                            {Tableheaders.map((ele, i) => (
                                <td className='p-3 capitalize' key={i}>{ele}</td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {plans.length > 0 && plans.map((item, i) => (
                            <tr 
                            key={i}
                            className='border-b border-l border-r cursor-pointer'
                            onClick={() => handleSingle(item)}
                            >
                                <td className='p-3'>{item.firstname} {item.lastname}</td>
                                <td className='p-3'>{item.email}</td>
                                <td className='p-3'>{item.phone}</td>
                                <td className='p-3'>{item.nationality}, {item.state}</td>
                                <td className='p-3 uppercase'>{item.email_verified}</td>
                                <td className='p-3 uppercase'>{item.status}</td>
                                <td className='p-3'>{moment(item.createdAt).format('DD-MM-YYYY hh:ss A')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>}
                {loading && <Spinner /> }
            </div>
        </div>
    </ALayout>
  )
}


