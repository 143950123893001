import React, { useState } from 'react'
import Forminput from 'components/utils/Forminput'
import Formbutton from 'components/utils/Formbutton'
import { ErrorAlert, SuccessAlert } from 'components/utils/functions'
import { SlPencil, SlTrash } from 'react-icons/sl'

const Subplan = ({ allsubs, setAllsubs }) => {
    const [subs, setSubs] = useState({
        id: ``,
        title: ``,
        percent: ``,
        duration: ``,
        durationType: ``,
        topup: ``,
        topupDuration: ``,
        topupDurationType: ``,
    })
    const [loading, setLoading] = useState(false)
    const [singles, setSingles] = useState({
        status: false,
        data: {}
    })

    const handleSubs = e => {
        setSubs({
            ...subs,
            [e.target.name]: e.target.value
        })
    }

    const handleAddSub = () => {
        if (!subs.title) return ErrorAlert(`Provide title for this sub plan`)
        if (!subs.percent) return ErrorAlert(`Provide percentage returns`)
        if (!subs.duration) return ErrorAlert(`Duration is required`)
        if (!subs.durationType) return ErrorAlert(`Duration type is required`)
        if (!subs.topup) return ErrorAlert(`topup percentage is required`)
        if (!subs.topupDuration) return ErrorAlert(`Topup Duration is required`)
        if (!subs.topupDurationType) return ErrorAlert(`Topup Duration type is required`)
        //find if a sub already exists with the same duration and duration type
        const findSub = allsubs.find(ele => ele.title?.toLowerCase() === subs.title.toLowerCase() || ele.duration === subs.duration && ele.durationType === subs.durationType)
        if (!singles.status) {
            if (findSub) return ErrorAlert(`Sub plan already exists with the same duration / title`)
            setAllsubs([
                subs,
                ...allsubs
            ])
            SuccessAlert(`sub plan added!...`)
        } else {
            const updates = allsubs.map((ele, i) => {
                if (i === singles.data.index) {
                    return {
                        ...ele,
                        title: subs.title,
                        percent: subs.percent,
                        duration: subs.duration,
                        durationType: subs.durationType,
                        topup: subs.topup,
                        topupDuration: subs.topupDuration,
                        topupDurationType: subs.topupDurationType,
                    }
                }
                return ele
            })
            setAllsubs(updates)
            setSingles({
                status: false,
                data: {}
            })
        }
        return setSubs({
            title: '',
            percent: '',
            duration: '',
            durationType: '',
            topup: '',
            topupDuration: '',
            topupDurationType: '',
        })
    }

    const DeleteSubPlan = title => {
        const findSub = allsubs.find(ele => ele.title === title)
        if (findSub) {
            const filtered = allsubs.filter(ele => ele.title !== title)
            setAllsubs(filtered)
        }
    }

    const EditSingleSub = (value, index) => {
        setSingles({
            status: true,
            data: {
                ...value,
                index
            },
        })
        setSubs({
            title: value.title,
            percent: value.percent,
            duration: value.duration,
            durationType: value.durationType,
            topup: value.topup,
            topupDuration: value.topupDuration,
            topupDurationType: value.topupDurationType,
        })
    }

    return (
        <div>

            <div className="border-t pt-5 mb-5">Expand plan Duration</div>
            <div className="grid grid-cols-3 md:grid-cols-5 gap-6 mb-4">
                <div className="">
                    <div className="text-sm">Sub Plan Name</div>
                    <Forminput
                        formName="title"
                        value={subs.title}
                        onChange={handleSubs}
                    />
                </div>
                <div className="">
                    <div className="text-sm">Sub Plan Percentage</div>
                    <Forminput
                        formName="percent"
                        value={subs.percent}
                        type={'number'}
                        onChange={handleSubs}
                    />
                </div>
                <div className="">
                    <div className="text-sm">Duration</div>
                    <Forminput
                        formName="duration"
                        value={subs.duration}
                        onChange={handleSubs}
                        type={'number'}
                    />
                </div>
                <div className="">
                    <div className="text-sm">Duration Type</div>
                    <Forminput
                        formName="durationType"
                        onChange={handleSubs}
                        value={subs.durationType}
                        formtype='select'
                    >
                        <option value="minutes">In Minutes</option>
                        <option value="hours">In Hours</option>
                        <option value="months">In Months</option>
                    </Forminput>
                </div>
                <div className="">
                    <div className="text-sm">topup (%)</div>
                    <Forminput
                        formName="topup"
                        label="topup"
                        type="number"
                        onChange={handleSubs}
                        value={subs.topup}
                    />
                </div>
                <div className="">
                    <div className="text-sm">topup duration</div>
                    <Forminput
                        formName="topupDuration"
                        label="topup Duration"
                        type="number"
                        onChange={handleSubs}
                        value={subs.topupDuration}
                    />
                </div>
                <div className="">
                    <div className="text-sm">topup duration Type</div>
                    <Forminput
                        formName="topupDurationType"
                        label="duration"
                        onChange={handleSubs}
                        value={subs.topupDurationType}
                        formtype='select'
                    >
                        <option value="minutes">In Minutes</option>
                        <option value="hours">In Hours</option>
                        <option value="days">In Days</option>
                        <option value="months">In Months</option>
                        <option value="years">In Years</option>
                    </Forminput>
                </div>
                <div className="flex items-center justify-center">
                    <Formbutton
                        handleSubmission={handleAddSub}
                        loading={loading}
                        type="button"
                        title={!singles.status ? "Add Plan" : "Update Plan"}
                    />
                </div>
            </div>
            <div className="mt-5">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                    {allsubs.map((item, i) => (
                        <div className="border rounded-lg p-2 grid grid-cols-8" key={i}>
                            <div className="col-span-7 border-r pr-5">
                                <div className="grid grid-cols-2 p-1">
                                    <div className="text-sm">Name:</div>
                                    <div className="text-sm text-right">{item.title}</div>
                                </div>
                                <div className="grid grid-cols-2 p-1">
                                    <div className="text-sm">Percent:</div>
                                    <div className="text-sm text-right">{item.percent}%</div>
                                </div>
                                <div className="grid grid-cols-2 p-1">
                                    <div className="text-sm">Duration:</div>
                                    <div className="text-sm text-right">{item.duration} {item.duration === "1" ? item.durationType.slice(0, item.durationType.length - 1) : item.durationType}</div>
                                </div>
                                <div className="grid grid-cols-2 p-1">
                                    <div className="text-sm">Topup Duration:</div>
                                    <div className="text-sm text-right">{item.topupDuration} {item.topupDuration === "1" ? item.topupDurationType.slice(0, item.topupDurationType.length - 1) : item.topupDurationType}</div>
                                </div>
                                <div className="grid grid-cols-2 p-1">
                                    <div className="text-sm">Topup:</div>
                                    <div className="text-sm text-right">{item.topup}%</div>
                                </div>
                            </div>
                            <div className="w-fit ml-auto flex flex-col items-center gap-5">
                                <button onClick={() => DeleteSubPlan(item.title)} type="button" className="p-2 w-fit ml-auto border rounded-full"> <SlTrash /> </button>
                                <button onClick={() => EditSingleSub(item, i)} type="button" className="p-2 w-fit ml-auto border rounded-full"> <SlPencil /> </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Subplan