import React, { useEffect, useState } from 'react'
import UserLayout from 'components/user/UserLayout'
import Forminput from 'components/utils/Forminput'
import { ErrorAlert, Errors, NairaSign, SuccessAlert, Swals, generateRandomNumbersAndAlphabets } from 'components/utils/functions'
import Formbutton from 'components/utils/Formbutton'
import SelectOptions from 'components/utils/SelectOptions'
import { Apis, Authgeturl, Authposturl } from 'components/Apis'
import {useDispatch} from 'react-redux'
import { useLocation } from 'react-router-dom'
import ConfirmationModal from 'components/utils/ConfirmationModal'
import { dispatchUser } from 'app/reducer'

const ExamPin = () => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [loads, setLoads] = useState(true)
    const { pathname } = useLocation()
    const tag = pathname.split('/')[1]
    const [data, setData] = useState({})
    const [forms, setForms] = useState({
        Service: "",
        Amount: "",
        Number: "",
        actual: "",
    })
    const [view, setView] = useState(false)
    const [single, setSingle] = useState({
        status: false,
        amount: '',
        actual: '',
        title: '',
        cashback: '',
    })

    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        const fetchService = async () => {
            try {
                const response = await Authgeturl(`${Apis.serviceapi.display}/exam`)
                if (response.status === 200) return setData(response.msg)
            } catch (error) {
                Errors(`${error.message}`)
            } finally {
                setLoads(false)
            }
        }
        fetchService()
    }, [])

    const handleSubmission = async (e) => {
        e.preventDefault()
        if (!forms.Service) return ErrorAlert('Select your preferred network')
        if (!forms.Number) return ErrorAlert('Provide a valid phone number')
        if (!forms.Amount) return ErrorAlert('Provide a valid amount')
        const tag = data?.tag?.plans.find(ele => ele.serviceid === forms.Service)
        const title = ` Kindly confirm your request to purchase ${tag.servicetype} at -${NairaSign}${single.amount?.toLocaleString()}`
        setSingle({
            ...single,
            title
        })
        setView(!view)
    }
    const submitRequest = async () => {
        setLoader(true)
        const formbody = {
            ...forms,
            serv: forms.Service,
            pservice: data.tag?.id,
            Amount: single.actual,
        }
        try {
            const response = await Authposturl(Apis.serviceapi.purchase_airtime, formbody)
            if(response.status === 200) {
                dispatch(dispatchUser(response.user))
                return Swals('Successful purchase', response.msg, 'success')
            }else {
                return Swals('Error Purchasing Airtime', response.msg, 'error')
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        }finally {
            setLoader(false)
        }
    }
    const handlePriceFee = e => {
        const val = parseFloat(e)
        if (e.length > 0) {
            const actual = val
            const sum = (val * data.tag.charges) / 100 + val
            const priced = (val * data.tag.cashback) / 100
            setSingle({
                ...single,
                status: true,
                amount: sum,
                actual,
                cashback: priced
            })
        } else {
            setSingle({
                ...single,
                status: false,
                amount: ''
            })
        }
    }
    return (
        <UserLayout title="Purchase Exam Pin">
            <div className="w-11/12 mx-auto">
                {view && <ConfirmationModal
                loading={loader}
                title={single.title}
                handleSubmission={submitRequest}
                    closeView={() => setView(!view)}
                />}
                <div className="bg-white p-3">
                    <form onSubmit={handleSubmission}>
                        <div className="mb-4 relative">
                            <div className="">Select Exam Type</div>
                            <Forminput value={forms.Service} formName={'Service'} onChange={handleForms} formtype='select'>
                                {data.tag?.plans?.map((ele, index) => (
                                    <option value={ele.serviceid} key={index}>{ele.servicetype}</option>
                                ))}
                            </Forminput>
                        </div>
                        <div className="mb-4">
                            <div className="">Quantity of scratch card pins</div>
                            <Forminput formName={'Number'} value={forms.Number} onChange={handleForms} />
                        </div>
                        {single.status &&
                            <div className='mb-10'>
                                <div className="grid grid-cols-2 p-2">
                                    <div className="">You will be charged</div>
                                    <div className="text-right text-red-600">-{NairaSign}{single.amount?.toLocaleString()}</div>
                                </div>
                                <div className="grid grid-cols-2 p-2">
                                    <div className="">Cashback</div>
                                    <div className="text-right text-green-400">+{NairaSign}{single.cashback?.toLocaleString()}</div>
                                </div>
                            </div>
                        }
                        <Formbutton title="subscribe now" loading={loading} />
                    </form>
                </div>
            </div>
        </UserLayout>
    )
}

export default ExamPin
