
import React, { useState } from 'react'
import SideViewModalLayout from 'components/utils/SideViewModalLayout'
import { MdPerson } from 'react-icons/md'
import { Apis, Authputurl, imgurl } from 'components/Apis'
import Forminput from 'components/utils/Forminput'
import { ErrorAlert, SuccessAlert } from 'components/utils/functions'
import Formbutton from 'components/utils/Formbutton'
import Imaged from 'components/utils/Imaged'


const StatusOptions = [
    'pending',
    'suspended',
    'retry',
    'approved',
]

export default function KycModal({ closeView, item, refetch }) {
    const [img, setImg] = useState(false)
    const [screen, setScreen] = useState(1)
    const [forms, setForms] = useState({
        status: item.status || '',
        note: item.note || ''
    })
    const [loading, setLoading] = useState(false)

    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }

    const handleUpdating = async () => {
        if (!forms.status) return ErrorAlert(`Provide a valid status`)
        if ([StatusOptions[1], StatusOptions[2]].includes(forms.status)) {
            if (!forms.note) return ErrorAlert(`Provide a reason for taking this action`)
        }
        setLoading(true)
        try {
            const formdata = {
                ...forms,
                id: item.id,
                tag: item.acc?.id
            }
            const response = await Authputurl(Apis.user.update_kyc_status, formdata)
            if (response.status === 200) {
                SuccessAlert(`${response.msg}`)
                refetch()
            } else {
                return ErrorAlert(`${response.msg}`)
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoading(false)
        }
    }
    return (
        <SideViewModalLayout closeView={closeView}>
            {screen === 2 && <div className="p-3 mt-10">
                <div className="">Kindly confirm your request to update this kyc information!.</div>
                <div className="mb-4">
                    <Forminput formtype='select' value={forms.status} onChange={handleForms} formName={'status'}>
                        {StatusOptions.map((ele, index) => (
                            <option value={ele} key={index}>{ele}</option>
                        ))}
                    </Forminput>
                </div>
                {[StatusOptions[1], StatusOptions[2]].includes(forms.status) && <div className="mb-4">
                    <div className="">Give Reason for taking this action</div>
                    <Forminput type="text" formName={'note'} value={forms.note} onChange={handleForms} />
                </div>}
                <div className="w-fit mt-5 ml-auto">
                    <Formbutton title="update" loading={loading} handleSubmission={handleUpdating} />
                </div>
            </div>}
            {screen === 1 && <div className="p-3 mt-10">
                <div className="flex gap-2 border-b py-2">
                    <div className="text-2xl border self-start rounded-full p-2 border-slate-400 text-slate-400"> <MdPerson /> </div>
                    <div className="">
                        <div className="font-semibold">{item.acc?.firstname} {item.acc?.middlename} {item.acc?.lastname}</div>
                        <div className="font-light">{item.acc?.dailcode}{item.acc?.phone}</div>
                        <div className="font-light">{item.acc?.email}</div>
                    </div>
                </div>
                <div className="p-3 bg-slate-100 mt-3">
                    <div className="font-semibold">BVN Details</div>
                    <div className="grid grid-cols-2 gap-3 text-sm p-2">
                        <div className="">BVN Number</div>
                        <div className="text-right break-words">{item.bvn_number}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 text-sm p-2">
                        <div className="">BVN Registered Bank</div>
                        <div className="text-right break-words">{item.bank_name}</div>
                    </div>
                </div>
                <div className="p-3 bg-slate-100 mt-3">
                    <div className="font-semibold">NIN Details</div>
                    <div className="grid grid-cols-2 gap-3 text-sm p-2">
                        <div className="">NIN Number</div>
                        <div className="text-right break-words">{item.nin_number}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 text-sm p-2">
                        <div className="">NIN front cover</div>
                        <div
                            onClick={() => setImg(!img)}
                            className="text-right text-blue-600 cursor-pointer capitalize">{img ? 'close' : 'view'}</div>
                    </div>
                    <div className={`h-44 bg-slate-200 ${img ? '' : 'hidden'}`}>
                        <Imaged src={`${imgurl}/documents/${item.nin_image}`} alt="" className="w-full h-full object-contain" />
                    </div>
                </div>
                <div className="p-3 bg-slate-100 mt-3">
                    <div className="font-semibold">Next of KIN Details</div>
                    <div className="grid grid-cols-2 gap-3 text-sm p-2">
                        <div className="">Full Name</div>
                        <div className="text-right break-words">{item.kin_name}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 text-sm p-2">
                        <div className="">Email Address</div>
                        <div className="text-right break-words">{item.kin_email}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 text-sm p-2">
                        <div className="">Phone Number</div>
                        <div className="text-right break-words">{item.kin_phone}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 text-sm p-2">
                        <div className="">Address</div>
                        <div className="text-right break-words">{item.kin_address}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 text-sm p-2">
                        <div className="">Nationality</div>
                        <div className="text-right break-words">{item.kin_country}, {item.kin_state}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 text-sm p-2">
                        <div className="">City</div>
                        <div className="text-right break-words">{item.kin_city}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 text-sm p-2">
                        <div className="">Zip code</div>
                        <div className="text-right break-words">{item.kin_zip}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 text-sm p-2">
                        <div className="">Relationship with User</div>
                        <div className="text-right break-words">{item.kin_relationship}</div>
                    </div>
                </div>
                <div className="mt-5 w-fit ml-auto">
                    <button
                        onClick={() => setScreen(prev => prev + 1)}
                        className="bg-main text-white py-3 px-5 rounded-lg capitalize">update</button>
                </div>
            </div>}
        </SideViewModalLayout>
    )
}


