import img6 from 'assets/main-images/sr.svg'
import img7 from 'assets/main-images/dp.svg'
import img8 from 'assets/main-images/tw.svg'
import img9 from 'assets/main-images/cl.svg'
import img10 from 'assets/main-images/mtr.svg'
import img11 from 'assets/main-images/qsd.svg'
import img12 from 'assets/main-images/airtime.svg'
import img13 from 'assets/main-images/buy_data.png'
import img14 from 'assets/main-images/elec.png'
import img15 from 'assets/main-images/cable.jpg'
import img16 from 'assets/main-images/lets.png'
import img17 from 'assets/main-images/airtime.png'
import img18 from 'assets/main-images/pin.png'
import img19 from 'assets/main-images/th.png'
import img20 from 'assets/main-images/crp.png'
import img21 from 'assets/main-images/ots.png'
import works from 'assets/images/works.webp'
import calc from 'assets/images/calculator.webp'
import model from 'assets/images/model.webp'
import returns from 'assets/images/returns.svg'
import compound from 'assets/images/compound.svg'
import safety from 'assets/images/safety.svg'
import dividends from 'assets/images/dividends.svg'
import max from 'assets/images/max.svg'
import lints from 'assets/main-images/lints.png'
import lil1 from 'assets/images/lil1.jpg'
import lil3 from 'assets/images/lil3.png'
import lil4 from 'assets/images/lil4.jpg'
import { siteNameShort } from './functions'
import { MdFolderOpen, MdPeopleAlt } from 'react-icons/md'
import { BiBarChartAlt, BiCar } from 'react-icons/bi'
import { SlBell, SlCallIn, SlFolderAlt, SlHome } from 'react-icons/sl'
import { FaChartLine } from 'react-icons/fa'
import img22 from 'assets/main-images/MTN.png'
import img23 from 'assets/main-images/Airtel.png'
import img24 from 'assets/main-images/Glo.png'
import img25 from 'assets/main-images/9mobile.png'
import img26 from 'assets/main-images/fund.png'
import img27 from 'assets/main-images/redeem.png'
import img28 from 'assets/main-images/transfer.png'
import img29 from 'assets/main-images/b1.png'
import img30 from 'assets/main-images/b2.png'
import img31 from 'assets/main-images/b3.png'
import img32 from 'assets/main-images/b4.png'
import img33 from 'assets/main-images/b5.png'
import img34 from 'assets/main-images/b6.png'
import showmax from 'assets/main-images/SHOWMAX.png'
import dstv from 'assets/main-images/dstv.png'
import gotv from 'assets/main-images/gotv.png'
import startimes from 'assets/main-images/startimes.png'

const PlanNames = {
    mini: "mini plan",
    mega: "mega plan",
    hi_fi: "hi-fi plan",
    wafo: "wafo plan",
    magic: "magic plan",
    bizpro: "bizpro plan",
    data: "data plan",
}

export const CableImages = [
    {
        cable_id: "1",
        cable_name: "GOTV",
        image: gotv,
    },
    {
        cable_id: "2",
        cable_name: "DSTV",
        image: dstv,
    },
    {
        cable_id: "3",
        cable_name: "STARTIMES",
        image: startimes,
    },
    {
        cable_id: "4",
        cable_name: "SHOWMAX",
        image: showmax,
    },
]
export const ServiceImages = [
    {
        "network_id": "1",
        "network": "MTN",
        image: img22
    },
    {
        "network_id": "2",
        "network": "AIRTEL",
        image: img23
    },
    {
        "network_id": "3",
        "network": "GLO",
        image: img24
    },
    {
        "network_id": "4",
        "network": "9MOBILE",
        image: img25
    }
]
export const NCWALLETAFRICA_DATA = [
    {
        "plan_id": 8,
        "network": "MTN",
        "data_type": "SME",
        "plan_size": "500 MB",
        "plan_price": "NGN 131.00",
        "validity": "1 Month",
        "custom": PlanNames.mini
    },
    {
        "plan_id": 9,
        "network": "MTN",
        "data_type": "SME",
        "plan_size": "1 GB",
        "plan_price": "NGN 260.00",
        "validity": "1 Month",
        "custom": PlanNames.mega
    },
    {
        "plan_id": 10,
        "network": "MTN",
        "data_type": "SME",
        "plan_size": "2 GB",
        "plan_price": "NGN 520.00",
        "validity": "1 Month",
        "custom": PlanNames.hi_fi
    },
    {
        "plan_id": 11,
        "network": "MTN",
        "data_type": "SME",
        "plan_size": "3 GB",
        "plan_price": "NGN 780.00",
        "validity": "1 Month",
        "custom": PlanNames.wafo
    },
    {
        "plan_id": 12,
        "network": "MTN",
        "data_type": "SME",
        "plan_size": "5 GB",
        "plan_price": "NGN 1,300.00",
        "validity": "1 Month",
        "custom": PlanNames.magic
    },
    {
        "plan_id": 13,
        "network": "MTN",
        "data_type": "SME",
        "plan_size": "10 GB",
        "plan_price": "NGN 2,600.00",
        "validity": "1 Month",
        "custom": PlanNames.bizpro
    },
    {
        "plan_id": 14,
        "network": "MTN",
        "data_type": "GIFTING",
        "plan_size": "600MB Weekly MB",
        "plan_price": "NGN 485.00",
        "validity": "7 Days"
    },
    {
        "plan_id": 15,
        "network": "MTN",
        "data_type": "GIFTING",
        "plan_size": "1.5GB Weekly GB",
        "plan_price": "NGN 970.00",
        "validity": "7 Days",
        "custom": PlanNames.data
    },
    {
        "plan_id": 16,
        "network": "MTN",
        "data_type": "GIFTING",
        "plan_size": "1GB - Weekly GB",
        "plan_price": "NGN 570.00",
        "validity": "7 Days",
        "custom": PlanNames.mega
    },
    {
        "plan_id": 17,
        "network": "MTN",
        "data_type": "GIFTING",
        "plan_size": "7GB - Weekly GB",
        "plan_price": "NGN 1,900.00",
        "validity": "7 Days",
        "custom": PlanNames.data
    },
    {
        "plan_id": 18,
        "network": "MTN",
        "data_type": "GIFTING",
        "plan_size": "2GB - Daily GB",
        "plan_price": "NGN 475.00",
        "validity": "2 Days",
        "custom": PlanNames.hi_fi
    },
    {
        "plan_id": 19,
        "network": "MTN",
        "data_type": "GIFTING",
        "plan_size": "1.2GB - Monthly GB",
        "plan_price": "NGN 970.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 20,
        "network": "MTN",
        "data_type": "GIFTING",
        "plan_size": "1.5GB - Monthly GB",
        "plan_price": "NGN 1,164.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 21,
        "network": "MTN",
        "data_type": "GIFTING",
        "plan_size": "3GB - Monthly GB",
        "plan_price": "NGN 1,520.00",
        "validity": "1 Month",
        "custom": PlanNames.wafo
    },
    {
        "plan_id": 22,
        "network": "MTN",
        "data_type": "GIFTING",
        "plan_size": "4GB - Monthly GB",
        "plan_price": "NGN 1,940.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 24,
        "network": "MTN",
        "data_type": "GIFTING",
        "plan_size": "8GB - Monthly GB",
        "plan_price": "NGN 2,930.00",
        "validity": "1 Month",
        "custom": PlanNames.data,
    },
    {
        "plan_id": 25,
        "network": "MTN",
        "data_type": "GIFTING",
        "plan_size": "10GB - Monthly GB",
        "plan_price": "NGN 3,395.00",
        "validity": "1 Month",
        "custom": PlanNames.bizpro
    },
    {
        "plan_id": 26,
        "network": "MTN",
        "data_type": "GIFTING",
        "plan_size": "12GB - Monthly GB",
        "plan_price": "NGN 3,880.00",
        "validity": "1 Month",
        "custom": PlanNames.data,
    },
    {
        "plan_id": 27,
        "network": "MTN",
        "data_type": "GIFTING",
        "plan_size": "20GB - Monthly GB",
        "plan_price": "NGN 5,335.00",
        "validity": "1 Month",
        "custom": PlanNames.data,
    },
    {
        "plan_id": 28,
        "network": "MTN",
        "data_type": "GIFTING",
        "plan_size": "40GB - Monthly GB",
        "plan_price": "NGN 10,670.00",
        "validity": "1 Month",
        "custom": PlanNames.data,
    },
    {
        "plan_id": 29,
        "network": "MTN",
        "data_type": "GIFTING",
        "plan_size": "75 - Monthly GB",
        "plan_price": "NGN 15,520.00",
        "validity": "1 Month",
        "custom": PlanNames.data,
    },
    {
        "plan_id": 30,
        "network": "MTN",
        "data_type": "GIFTING",
        "plan_size": "120GB - Monthly GB",
        "plan_price": "NGN 21,340.00",
        "validity": "1 Month",
        "custom": PlanNames.data,
    },
    {
        "plan_id": 31,
        "network": "MTN",
        "data_type": "GIFTING",
        "plan_size": "200GB - Monthly GB",
        "plan_price": "NGN 29,100.00",
        "validity": "1 Month",
        "custom": PlanNames.data,
    },
    {
        "plan_id": 75,
        "network": "GLO",
        "data_type": "GIFTING",
        "plan_size": "800MB + 1 GB",
        "plan_price": "NGN 460.00",
        "validity": "14 Days",
        "custom": PlanNames.mega
    },
    {
        "plan_id": 76,
        "network": "GLO",
        "data_type": "GIFTING",
        "plan_size": "1.9GB + 2 GB",
        "plan_price": "NGN 930.00",
        "validity": "1 Month",
        "custom": PlanNames.hi_fi
    },
    {
        "plan_id": 77,
        "network": "GLO",
        "data_type": "GIFTING",
        "plan_size": "3.5GB + 4 GB",
        "plan_price": "NGN 1,395.00",
        "validity": "1 Month",
        "custom": PlanNames.data,
    },
    {
        "plan_id": 78,
        "network": "GLO",
        "data_type": "GIFTING",
        "plan_size": "5.2GB + 4 GB",
        "plan_price": "NGN 1,860.00",
        "validity": "1 Month",
        "custom": PlanNames.data,
    },
    {
        "plan_id": 79,
        "network": "GLO",
        "data_type": "GIFTING",
        "plan_size": "6.8GB + 4 GB",
        "plan_price": "NGN 2,325.00",
        "validity": "1 Month",
        "custom": PlanNames.data,
    },
    {
        "plan_id": 80,
        "network": "GLO",
        "data_type": "GIFTING",
        "plan_size": "10GB + 4 GB",
        "plan_price": "NGN 2,790.00",
        "validity": "1 Month",
        "custom": PlanNames.bizpro
    },
    {
        "plan_id": 81,
        "network": "GLO",
        "data_type": "GIFTING",
        "plan_size": "14GB + 4 GB",
        "plan_price": "NGN 3,720.00",
        "validity": "1 Month",
        "custom": PlanNames.data,
    },
    {
        "plan_id": 82,
        "network": "GLO",
        "data_type": "GIFTING",
        "plan_size": "20GB + 4 GB",
        "plan_price": "NGN 4,650.00",
        "validity": "1 Month",
        "custom": PlanNames.data,
    },
    {
        "plan_id": 83,
        "network": "GLO",
        "data_type": "GIFTING",
        "plan_size": "27.5GB + 2 GB",
        "plan_price": "NGN 7,360.00",
        "validity": "1 Month",
        "custom": PlanNames.hi_fi
    },
    {
        "plan_id": 84,
        "network": "GLO",
        "data_type": "GIFTING",
        "plan_size": "46GB + 4 GB",
        "plan_price": "NGN 9,300.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 85,
        "network": "GLO",
        "data_type": "GIFTING",
        "plan_size": "86GB + 7 GB",
        "plan_price": "NGN 13,950.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 119,
        "network": "AIRTEL",
        "data_type": "GIFTING",
        "plan_size": "2 GB",
        "plan_price": "NGN 1,080.00",
        "validity": "1 Month",
        "custom": PlanNames.hi_fi
    },
    {
        "plan_id": 120,
        "network": "AIRTEL",
        "data_type": "GIFTING",
        "plan_size": "6 GB",
        "plan_price": "NGN 2,250.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 121,
        "network": "AIRTEL",
        "data_type": "GIFTING",
        "plan_size": "1.5 GB",
        "plan_price": "NGN 900.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 123,
        "network": "AIRTEL",
        "data_type": "GIFTING",
        "plan_size": "3 GB",
        "plan_price": "NGN 1,350.00",
        "validity": "1 Month",
        "custom": PlanNames.wafo
    },
    {
        "plan_id": 124,
        "network": "AIRTEL",
        "data_type": "GIFTING",
        "plan_size": "4.5 GB",
        "plan_price": "NGN 1,800.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 126,
        "network": "AIRTEL",
        "data_type": "GIFTING",
        "plan_size": "10 GB",
        "plan_price": "NGN 2,700.00",
        "validity": "1 Month",
        "custom": PlanNames.bizpro
    },
    {
        "plan_id": 127,
        "network": "AIRTEL",
        "data_type": "GIFTING",
        "plan_size": "11 GB",
        "plan_price": "NGN 3,600.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 128,
        "network": "AIRTEL",
        "data_type": "GIFTING",
        "plan_size": "20 GB",
        "plan_price": "NGN 4,500.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 130,
        "network": "AIRTEL",
        "data_type": "GIFTING",
        "plan_size": "40 GB",
        "plan_price": "NGN 9,000.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 131,
        "network": "AIRTEL",
        "data_type": "GIFTING",
        "plan_size": "75 GB",
        "plan_price": "NGN 13,500.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 132,
        "network": "AIRTEL",
        "data_type": "GIFTING",
        "plan_size": "120 GB",
        "plan_price": "NGN 18,000.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 140,
        "network": "9MOBILE",
        "data_type": "GIFTING",
        "plan_size": "2 GB",
        "plan_price": "NGN 1,116.00",
        "validity": "1 Month",
        "custom": PlanNames.hi_fi
    },
    {
        "plan_id": 141,
        "network": "9MOBILE",
        "data_type": "GIFTING",
        "plan_size": "4.5 GB",
        "plan_price": "NGN 1,860.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 142,
        "network": "9MOBILE",
        "data_type": "SME",
        "plan_size": "4 GB",
        "plan_price": "NGN 560.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 143,
        "network": "9MOBILE",
        "data_type": "GIFTING",
        "plan_size": "11 GB",
        "plan_price": "NGN 3,720.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 144,
        "network": "9MOBILE",
        "data_type": "GIFTING",
        "plan_size": "40 GB",
        "plan_price": "NGN 9,300.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 151,
        "network": "9MOBILE",
        "data_type": "GIFTING",
        "plan_size": "1.5 GB",
        "plan_price": "NGN 930.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 152,
        "network": "9MOBILE",
        "data_type": "GIFTING",
        "plan_size": "7 GB",
        "plan_price": "NGN 1,395.00",
        "validity": "7 Days",
        "custom": PlanNames.data
    },
    {
        "plan_id": 153,
        "network": "9MOBILE",
        "data_type": "GIFTING",
        "plan_size": "15 GB",
        "plan_price": "NGN 4,650.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 154,
        "network": "9MOBILE",
        "data_type": "GIFTING",
        "plan_size": "75 GB",
        "plan_price": "NGN 13,950.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 155,
        "network": "MTN",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "500 MB",
        "plan_price": "NGN 135.00",
        "validity": "1 Month",
        "custom": PlanNames.mini
    },
    {
        "plan_id": 156,
        "network": "MTN",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "1 GB",
        "plan_price": "NGN 261.00",
        "validity": "1 Month",
        "custom": PlanNames.mega
    },
    {
        "plan_id": 157,
        "network": "MTN",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "2 GB",
        "plan_price": "NGN 522.00",
        "validity": "1 Month",
        "custom": PlanNames.hi_fi
    },
    {
        "plan_id": 158,
        "network": "MTN",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "3 GB",
        "plan_price": "NGN 783.00",
        "validity": "1 Month",
        "custom": PlanNames.wafo
    },
    {
        "plan_id": 159,
        "network": "MTN",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "5 GB",
        "plan_price": "NGN 1,305.00",
        "validity": "1 Month",
        "custom": PlanNames.magic
    },
    {
        "plan_id": 160,
        "network": "MTN",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "10 GB",
        "plan_price": "NGN 2,610.00",
        "validity": "1 Month",
        "custom": PlanNames.bizpro
    },
    {
        "plan_id": 161,
        "network": "MTN",
        "data_type": "SME2",
        "plan_size": "500 MB",
        "plan_price": "NGN 131.00",
        "validity": "1 Month",
        "custom": PlanNames.mini
    },
    {
        "plan_id": 162,
        "network": "MTN",
        "data_type": "SME2",
        "plan_size": "1 GB",
        "plan_price": "NGN 256.00",
        "validity": "1 Month",
        "custom": PlanNames.mega
    },
    {
        "plan_id": 163,
        "network": "MTN",
        "data_type": "SME2",
        "plan_size": "2 GB",
        "plan_price": "NGN 512.00",
        "validity": "1 Month",
        "custom": PlanNames.hi_fi
    },
    {
        "plan_id": 165,
        "network": "MTN",
        "data_type": "SME2",
        "plan_size": "3 GB",
        "plan_price": "NGN 768.00",
        "validity": "1 Month",
        "custom": PlanNames.wafo
    },
    {
        "plan_id": 166,
        "network": "MTN",
        "data_type": "SME2",
        "plan_size": "5 GB",
        "plan_price": "NGN 1,280.00",
        "validity": "1 Month",
        "custom": PlanNames.magic
    },
    {
        "plan_id": 167,
        "network": "MTN",
        "data_type": "SME2",
        "plan_size": "10 GB",
        "plan_price": "NGN 2,560.00",
        "validity": "1 Month",
        "custom": PlanNames.bizpro
    },
    {
        "plan_id": 169,
        "network": "MTN",
        "data_type": "CG2",
        "plan_size": "2 GB",
        "plan_price": "NGN 490.00",
        "validity": "1 Month",
        "custom": PlanNames.hi_fi
    },
    {
        "plan_id": 170,
        "network": "MTN",
        "data_type": "CG2",
        "plan_size": "3 GB",
        "plan_price": "NGN 735.00",
        "validity": "1 Month",
        "custom": PlanNames.wafo
    },
    {
        "plan_id": 171,
        "network": "MTN",
        "data_type": "CG2",
        "plan_size": "5 GB",
        "plan_price": "NGN 1,225.00",
        "validity": "1 Month",
        "custom": PlanNames.magic
    },
    {
        "plan_id": 172,
        "network": "GLO",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "500 MB",
        "plan_price": "NGN 140.00",
        "validity": "1 Month",
        "custom": PlanNames.mini
    },
    {
        "plan_id": 173,
        "network": "GLO",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "1 GB",
        "plan_price": "NGN 265.00",
        "validity": "1 Month",
        "custom": PlanNames.mega
    },
    {
        "plan_id": 174,
        "network": "GLO",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "2 GB",
        "plan_price": "NGN 530.00",
        "validity": "1 Month",
        "custom": PlanNames.hi_fi
    },
    {
        "plan_id": 175,
        "network": "MTN",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "75 GB",
        "plan_price": "NGN 19,575.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 176,
        "network": "GLO",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "3 GB",
        "plan_price": "NGN 795.00",
        "validity": "1 Month",
        "custom": PlanNames.wafo
    },
    {
        "plan_id": 177,
        "network": "GLO",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "5 GB",
        "plan_price": "NGN 1,325.00",
        "validity": "1 Month",
        "custom": PlanNames.magic
    },
    {
        "plan_id": 178,
        "network": "GLO",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "10 GB",
        "plan_price": "NGN 2,650.00",
        "validity": "1 Month",
        "custom": PlanNames.bizpro
    },
    {
        "plan_id": 179,
        "network": "AIRTEL",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "100 MB",
        "plan_price": "NGN 71.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 180,
        "network": "AIRTEL",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "300 MB",
        "plan_price": "NGN 101.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 181,
        "network": "AIRTEL",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "500 MB",
        "plan_price": "NGN 140.00",
        "validity": "1 Month",
        "custom": PlanNames.mini
    },
    {
        "plan_id": 182,
        "network": "AIRTEL",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "1 GB",
        "plan_price": "NGN 274.00",
        "validity": "1 Month",
        "custom": PlanNames.mega
    },
    {
        "plan_id": 183,
        "network": "AIRTEL",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "2 GB",
        "plan_price": "NGN 548.00",
        "validity": "1 Month",
        "custom": PlanNames.hi_fi
    },
    {
        "plan_id": 185,
        "network": "AIRTEL",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "5 GB",
        "plan_price": "NGN 1,370.00",
        "validity": "1 Month",
        "custom": PlanNames.magic
    },
    {
        "plan_id": 186,
        "network": "AIRTEL",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "10 GB",
        "plan_price": "NGN 2,740.00",
        "validity": "1 Month",
        "custom": PlanNames.bizpro
    },
    {
        "plan_id": 189,
        "network": "9MOBILE",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "500 MB",
        "plan_price": "NGN 5.00",
        "validity": "1 Month",
        "custom": PlanNames.mini
    },
    {
        "plan_id": 190,
        "network": "9MOBILE",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "1 GB",
        "plan_price": "NGN 5.00",
        "validity": "1 Month",
        "custom": PlanNames.mega
    },
    {
        "plan_id": 191,
        "network": "9MOBILE",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "1.5 GB",
        "plan_price": "NGN 5.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 192,
        "network": "9MOBILE",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "2 GB",
        "plan_price": "NGN 5.00",
        "validity": "1 Month",
        "custom": PlanNames.hi_fi
    },
    {
        "plan_id": 193,
        "network": "9MOBILE",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "2.5 GB",
        "plan_price": "NGN 5.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 194,
        "network": "9MOBILE",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "3 GB",
        "plan_price": "NGN 5.00",
        "validity": "1 Month",
        "custom": PlanNames.wafo
    },
    {
        "plan_id": 195,
        "network": "9MOBILE",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "4 GB",
        "plan_price": "NGN 5.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 196,
        "network": "9MOBILE",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "4.5 GB",
        "plan_price": "NGN 5.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 197,
        "network": "9MOBILE",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "10 GB",
        "plan_price": "NGN 5.00",
        "validity": "1 Month",
        "custom": PlanNames.bizpro
    },
    {
        "plan_id": 198,
        "network": "9MOBILE",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "15 GB",
        "plan_price": "NGN 5.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 199,
        "network": "9MOBILE",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "20 GB",
        "plan_price": "NGN 5.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 200,
        "network": "9MOBILE",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "30 GB",
        "plan_price": "NGN 5.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 201,
        "network": "9MOBILE",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "50 GB",
        "plan_price": "NGN 5.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 202,
        "network": "9MOBILE",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "75 GB",
        "plan_price": "NGN 5.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 203,
        "network": "9MOBILE",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "100 GB",
        "plan_price": "NGN 5.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 204,
        "network": "MTN",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "15 GB",
        "plan_price": "NGN 3,915.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 205,
        "network": "MTN",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "20 GB",
        "plan_price": "NGN 5,220.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 206,
        "network": "MTN",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "40 GB",
        "plan_price": "NGN 10,440.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 207,
        "network": "9MOBILE",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "5 GB",
        "plan_price": "NGN 5.00",
        "validity": "1 Month",
        "custom": PlanNames.magic
    },
    {
        "plan_id": 209,
        "network": "MTN",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "100 GB",
        "plan_price": "NGN 26,100.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 210,
        "network": "9MOBILE",
        "data_type": "GIFTING",
        "plan_size": "3 GB",
        "plan_price": "NGN 1,395.00",
        "validity": "1 Month",
        "custom": PlanNames.wafo
    },
    {
        "plan_id": 213,
        "network": "9MOBILE",
        "data_type": "GIFTING",
        "plan_size": "1 GB",
        "plan_price": "NGN 465.00",
        "validity": "7 Days",
        "custom": PlanNames.mega
    },
    {
        "plan_id": 214,
        "network": "9MOBILE",
        "data_type": "GIFTING",
        "plan_size": "500 MB",
        "plan_price": "NGN 465.00",
        "validity": "7 Days",
        "custom": PlanNames.mini
    },
    {
        "plan_id": 216,
        "network": "9MOBILE",
        "data_type": "SME",
        "plan_size": "500 MB",
        "plan_price": "NGN 75.00",
        "validity": "1 Month",
        "custom": PlanNames.mini
    },
    {
        "plan_id": 220,
        "network": "9MOBILE",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "11 GB",
        "plan_price": "NGN 5.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 221,
        "network": "9MOBILE",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "40 GB",
        "plan_price": "NGN 5.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 222,
        "network": "9MOBILE",
        "data_type": "CORPORATE GIFTING",
        "plan_size": "100 GB",
        "plan_price": "NGN 5.00",
        "validity": "3 Months",
        "custom": PlanNames.data
    },
    {
        "plan_id": 257,
        "network": "MTN",
        "data_type": "CG2",
        "plan_size": "500 MB",
        "plan_price": "NGN 125.00",
        "validity": "1 Month",
        "custom": PlanNames.mini
    },
    {
        "plan_id": 258,
        "network": "MTN",
        "data_type": "CG2",
        "plan_size": "1 GB",
        "plan_price": "NGN 245.00",
        "validity": "1 Month",
        "custom": PlanNames.mega
    },
    {
        "plan_id": 278,
        "network": "9MOBILE",
        "data_type": "SME",
        "plan_size": "2 GB",
        "plan_price": "NGN 280.00",
        "validity": "1 Month",
        "custom": PlanNames.hi_fi
    },
    {
        "plan_id": 282,
        "network": "9MOBILE",
        "data_type": "SME",
        "plan_size": "1 GB",
        "plan_price": "NGN 140.00",
        "validity": "1 Month",
        "custom": PlanNames.mega
    },
    {
        "plan_id": 291,
        "network": "9MOBILE",
        "data_type": "SME",
        "plan_size": "3 GB",
        "plan_price": "NGN 420.00",
        "validity": "1 Month",
        "custom": PlanNames.wafo
    },
    {
        "plan_id": 292,
        "network": "9MOBILE",
        "data_type": "SME",
        "plan_size": "5 GB",
        "plan_price": "NGN 700.00",
        "validity": "1 Month",
        "custom": PlanNames.magic
    },
    {
        "plan_id": 293,
        "network": "9MOBILE",
        "data_type": "SME",
        "plan_size": "10 GB",
        "plan_price": "NGN 1,400.00",
        "validity": "1 Month",
        "custom": PlanNames.bizpro
    },
    {
        "plan_id": 308,
        "network": "AIRTEL",
        "data_type": "SME",
        "plan_size": "100 MB",
        "plan_price": "NGN 70.00",
        "validity": "1 Day",
        "custom": PlanNames.data
    },
    {
        "plan_id": 309,
        "network": "MTN",
        "data_type": "GIFTING",
        "plan_size": "300 MB",
        "plan_price": "NGN 130.00",
        "validity": "1 Day",
        "custom": PlanNames.data
    },
    {
        "plan_id": 310,
        "network": "AIRTEL",
        "data_type": "SME",
        "plan_size": "300 MB",
        "plan_price": "NGN 130.00",
        "validity": "2 Days",
        "custom": PlanNames.data
    },
    {
        "plan_id": 311,
        "network": "AIRTEL",
        "data_type": "SME",
        "plan_size": "3 GB",
        "plan_price": "NGN 540.00",
        "validity": "7 Days",
        "custom": PlanNames.wafo
    },
    {
        "plan_id": 312,
        "network": "AIRTEL",
        "data_type": "SME",
        "plan_size": "4 GB",
        "plan_price": "NGN 1,070.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 313,
        "network": "AIRTEL",
        "data_type": "SME",
        "plan_size": "10 GB",
        "plan_price": "NGN 2,100.00",
        "validity": "1 Month",
        "custom": PlanNames.bizpro
    },
    {
        "plan_id": 314,
        "network": "AIRTEL",
        "data_type": "SME",
        "plan_size": "1 GB",
        "plan_price": "NGN 233.00",
        "validity": "2 Days",
        "custom": PlanNames.mega
    },
    {
        "plan_id": 315,
        "network": "AIRTEL",
        "data_type": "SME",
        "plan_size": "2 GB",
        "plan_price": "NGN 340.00",
        "validity": "2 Days",
        "custom": PlanNames.hi_fi
    },
    {
        "plan_id": 316,
        "network": "AIRTEL",
        "data_type": "SME",
        "plan_size": "15 GB",
        "plan_price": "NGN 3,080.00",
        "validity": "1 Month",
        "custom": PlanNames.data
    },
    {
        "plan_id": 317,
        "network": "GLO",
        "data_type": "SME",
        "plan_size": "1 GB",
        "plan_price": "NGN 200.00",
        "validity": "1 Day",
        "custom": PlanNames.mega
    },
    {
        "plan_id": 318,
        "network": "GLO",
        "data_type": "SME",
        "plan_size": "2 GB",
        "plan_price": "NGN 300.00",
        "validity": "1 Day",
        "custom": PlanNames.hi_fi
    },
    {
        "plan_id": 319,
        "network": "GLO",
        "data_type": "SME",
        "plan_size": "5 GB",
        "plan_price": "NGN 2,000.00",
        "validity": "7 Days",
        "custom": PlanNames.magic
    },
    {
        "plan_id": 320,
        "network": "GLO",
        "data_type": "SME",
        "plan_size": "3.5 GB",
        "plan_price": "NGN 500.00",
        "validity": "2 Days",
        "custom": PlanNames.data
    }


]

export const NCWALLETAFRICA_CABLE_PLANS = [
    {
        cable_plan: 5,
        plan_name: "GOtv Max",
        cable: "GOTV",
        price: "7,200.00",
    },
    {
        cable_plan: 6,
        plan_name: "GOtv Jolli",
        cable: "GOTV",
        price: "4,850.00",
    },
    {
        cable_plan: 7,
        plan_name: "GOtv Jinja",
        cable: "GOTV",
        price: "3,300.00",
    },
    {
        cable_plan: 8,
        plan_name: "GOtv Smallie - monthly",
        cable: "GOTV",
        price: "1,575.00",
    },
    {
        cable_plan: 9,
        plan_name: "GOtv Smallie - quarterly",
        cable: "GOTV",
        price: "4,175.00",
    },
    {
        cable_plan: 10,
        plan_name: "GOtv Smallie - yearly",
        cable: "GOTV",
        price: "12,300.00",
    },
    {
        cable_plan: 11,
        plan_name: "GOtv Supa - monthly",
        cable: "GOTV",
        price: "9,600.00",
    },
    {
        cable_plan: 12,
        plan_name: "GOtv Supa Plus - monthly",
        cable: "GOTV",
        price: "15,700.00",
    },
    {
        cable_plan: 14,
        plan_name: "Nova (Dish) - 1 Month",
        cable: "STARTIMES",
        price: "1,700.00",
    },
    {
        cable_plan: 15,
        plan_name: "Basic (Antenna) - 1 Month",
        cable: "STARTIMES",
        price: "3,300.00",
    },
    {
        cable_plan: 16,
        plan_name: "Smart (Dish) - 1 Month",
        cable: "STARTIMES",
        price: "4,200.00",
    },
    {
        cable_plan: 17,
        plan_name: "Classic (Antenna) - 1 Month",
        cable: "STARTIMES",
        price: "5,000.00",
    },
    {
        cable_plan: 18,
        plan_name: "Super (Dish) - 1 Month",
        cable: "STARTIMES",
        price: "8,200.00",
    },
    {
        cable_plan: 19,
        plan_name: "Nova (Antenna) - 1 Week",
        cable: "STARTIMES",
        price: "500.00",
    },
    {
        cable_plan: 20,
        plan_name: "Basic (Antenna)  - 1 Week",
        cable: "STARTIMES",
        price: "1,100.00",
    },
    {
        cable_plan: 21,
        plan_name: "Smart (Dish) - 1 Week",
        cable: "STARTIMES",
        price: "1,400.00",
    },
    {
        cable_plan: 22,
        plan_name: "Classic (Antenna) - 1 Week",
        cable: "STARTIMES",
        price: "1,700.00",
    },
    {
        cable_plan: 23,
        plan_name: "Super (Dish) - 1 Week",
        cable: "STARTIMES",
        price: "2,800.00",
    },
    {
        cable_plan: 24,
        plan_name: "Chinese (Dish)  - 1 month",
        cable: "STARTIMES",
        price: "16,000.00",
    },
    {
        cable_plan: 25,
        plan_name: "Nova (Antenna) - 1 Month",
        cable: "STARTIMES",
        price: "1,700.00",
    },
    {
        cable_plan: 26,
        plan_name: "Special (Dish) - 1 Week",
        cable: "STARTIMES",
        price: "2,100.00",
    },
    {
        cable_plan: 27,
        plan_name: "Special (Dish) - 1 Month",
        cable: "STARTIMES",
        price: "6,200.00",
    },
    {
        cable_plan: 28,
        plan_name: "Nova (Dish) - 1 Week",
        cable: "STARTIMES",
        price: "600.00",
    },
    {
        cable_plan: 30,
        plan_name: "Super (Antenna) - 1 Month",
        cable: "STARTIMES",
        price: "7,500.00",
    },
    {
        cable_plan: 31,
        plan_name: "Special (Dish) - 1 Week",
        cable: "STARTIMES",
        price: "1,300.00",
    },
    {
        cable_plan: 35,
        plan_name: "DStv Padi",
        cable: "DSTV",
        price: "3,600.00",
    },
    {
        cable_plan: 36,
        plan_name: "DStv Yanga",
        cable: "DSTV",
        price: "5,100.00",
    },
    {
        cable_plan: 37,
        plan_name: "Dstv Confam ",
        cable: "DSTV",
        price: "9,300.00",
    },
    {
        cable_plan: 38,
        plan_name: "DStv  Compact",
        cable: "DSTV",
        price: "15,700.00",
    },
    {
        cable_plan: 39,
        plan_name: "DStv Premium",
        cable: "DSTV",
        price: "37,000.00",
    },
    {
        cable_plan: 40,
        plan_name: "DStv Asia",
        cable: "DSTV",
        price: "12,400.00",
    },
    {
        cable_plan: 41,
        plan_name: "DStv Compact Plus",
        cable: "DSTV",
        price: "25,000.00",
    },
    {
        cable_plan: 42,
        plan_name: "DStv Premium-French",
        cable: "DSTV",
        price: "57,500.00",
    },
    {
        cable_plan: 43,
        plan_name: "DStv Premium-Asia",
        cable: "DSTV",
        price: "42,000.00",
    },
    {
        cable_plan: 44,
        plan_name: "DStv Confam + ExtraView",
        cable: "DSTV",
        price: "14,300.00",
    },
    {
        cable_plan: 45,
        plan_name: "DStv Yanga + ExtraView",
        cable: "DSTV",
        price: "10,100.00",
    },
    {
        cable_plan: 46,
        plan_name: "DStv Padi + ExtraView",
        cable: "DSTV",
        price: "8,600.00",
    },
    {
        cable_plan: 47,
        plan_name: "DStv Compact + Asia",
        cable: "DSTV",
        price: "28,100.00",
    },
    {
        cable_plan: 48,
        plan_name: "DStv Compact + Extra View",
        cable: "DSTV",
        price: "20,700.00",
    },
    {
        cable_plan: 49,
        plan_name: "DStv Compact + French Touch",
        cable: "DSTV",
        price: "21,500.00",
    },
    {
        cable_plan: 50,
        plan_name: "DStv Premium + Extra View",
        cable: "DSTV",
        price: "42,000.00",
    },
    {
        cable_plan: 51,
        plan_name: "DStv Compact Plus + Asia",
        cable: "DSTV",
        price: "37,400.00",
    },
    {
        cable_plan: 52,
        plan_name: "DStv Compact + French Touch + ExtraView",
        cable: "DSTV",
        price: "26,500.00",
    },
    {
        cable_plan: 53,
        plan_name: "DStv Compact + Asia + ExtraView",
        cable: "DSTV",
        price: "33,100.00",
    },
    {
        cable_plan: 54,
        plan_name: "DStv Compact Plus + French Plus",
        cable: "DSTV",
        price: "45,500.00",
    },
    {
        cable_plan: 55,
        plan_name: "DStv Compact Plus + French Touch",
        cable: "DSTV",
        price: "30,800.00",
    },
    {
        cable_plan: 56,
        plan_name: "DStv Compact Plus + Extra View ",
        cable: "DSTV",
        price: "30,000.00",
    },
    {
        cable_plan: 57,
        plan_name: "DStv Compact Plus + FrenchPlus + Extra View",
        cable: "DSTV",
        price: "50,500.00",
    },
    {
        cable_plan: 58,
        plan_name: "DStv Compact + French Plus",
        cable: "DSTV",
        price: "36,200.00",
    },
    {
        cable_plan: 59,
        plan_name: "DStv Compact Plus + Asia + ExtraView",
        cable: "DSTV",
        price: "42,400.00",
    },
    {
        cable_plan: 60,
        plan_name: "DStv Premium + Asia + Extra View",
        cable: "DSTV",
        price: "54,400.00",
    },
    {
        cable_plan: 61,
        plan_name: "DStv Premium + French + Extra View",
        cable: "DSTV",
        price: "51,000.00",
    },
    {
        cable_plan: 62,
        plan_name: "DStv French Plus Add-on",
        cable: "DSTV",
        price: "20,500.00",
    },
    {
        cable_plan: 63,
        plan_name: "DStv Asian Add-on",
        cable: "DSTV",
        price: "12,400.00",
    },
    {
        cable_plan: 64,
        plan_name: "DStv Great Wall Standalone Bouquet",
        cable: "DSTV",
        price: "2,500.00",
    },
    {
        cable_plan: 66,
        plan_name: "Full",
        cable: "SHOWMAX",
        price: "3,500.00",
    },
    {
        cable_plan: 67,
        plan_name: "Mobile Only",
        cable: "SHOWMAX",
        price: "1,600.00",
    },
    {
        cable_plan: 68,
        plan_name: "Sports Mobile Only",
        cable: "SHOWMAX",
        price: "4,000.00",
    },
    {
        cable_plan: 69,
        plan_name: "Full Sports Mobile Only",
        cable: "SHOWMAX",
        price: "5,400.00",
    },
    {
        cable_plan: 70,
        plan_name: "Full - 3 Months",
        cable: "SHOWMAX",
        price: "8,400.00",
    },
    {
        cable_plan: 71,
        plan_name: "Mobile Only - 3 Months",
        cable: "SHOWMAX",
        price: "3,800.00",
    },
    {
        cable_plan: 72,
        plan_name: "Sports Mobile Only - 3 Months",
        cable: "SHOWMAX",
        price: "12,000.00",
    },
    {
        cable_plan: 73,
        plan_name: "Sports Only",
        cable: "SHOWMAX",
        price: "3,200.00",
    },
    {
        cable_plan: 74,
        plan_name: "Sports Only 3 months",
        cable: "SHOWMAX",
        price: "9,600.00",
    },
    {
        cable_plan: 75,
        plan_name: "Full Sports Mobile Only - 3 months",
        cable: "GOTV",
        price: "16,200.00",
    },
]


export const BillsServices = [
    {
        title: `Instant Airtime to Cash`,
        content: `Convert your airtime to cash in seconds using the new and improved Airtime to cash service.`,
        image: img31
    },
    {
        title: `Airtime Topup`,
        content: `Purchase airtime for MTN, Glo, 9mobile & Airtel at the best possible / discounted rates.`,
        image: img33
    },
    {
        title: `Data Purchase`,
        content: `We offer the best rates on data purchase for all available networks in Nigeria.`,
        image: img30
    },
    {
        title: `Bills Payments`,
        content: `Pay for your Tv/Cable (Dstv, Gotv, etc.), Internet, Electricity & other merchants using the Quick Direct app.`,
        image: img29
    },
    {
        title: `Airtime Payments`,
        content: `Send and receive payments, pay bills & Receive & send cash using Quick Direct.`,
        image: img34
    },
    {
        title: `Earn & Redeem Data to Cash`,
        content: `Every single purchase earn you Data bundle, which are redeemable to cash into your bank account instantly .`,
        image: img32
    },
]

export const UserDashboardMajors = [
    { title: 'Add money', link: '/fund-account', image: img26 },
    { title: 'Redeem GB reward', link: '/redeem-reward', image: img27 },
    { title: 'Send money', link: '/send-money', image: img28 },
]

export const AdminDashboard = [
    { title: "users", tag: 'users', Icon: MdPeopleAlt, bg: 'bg-green-400', url: `` },
    { title: "service transactions", tag: 'servicetrans', Icon: BiBarChartAlt, bg: 'bg-blue-400', url: `` },
    { title: "services", tag: 'services', Icon: SlFolderAlt, bg: 'bg-purple-400', url: `` },
    { title: "total investmented", tag: 'investments', Icon: FaChartLine, bg: 'bg-zinc-400', url: `` },
    { title: "shortlets", tag: 'shortlets', Icon: SlHome, bg: 'bg-sub', url: `` },
    { title: "pickups", tag: 'pickups', Icon: BiCar, bg: 'bg-teal-400', url: `` },
    { title: "shares requests", tag: 'sharesrequests', Icon: SlCallIn, bg: 'bg-red-400', url: `` },
    { title: "investment plans", tag: 'plans', Icon: MdFolderOpen, bg: 'bg-green-400', url: `` },
    { title: "newsletters", tag: 'newsletters', Icon: SlBell, bg: 'bg-sub', url: `` },
]

export const DriveList = [
    {
        img: img7,
        title: `Dependability`,
        content: `(Dependable service): We pride ourselves on being your dependable financial institution`
    },
    {
        img: img6,
        title: `Social responsibility`,
        content: `One of our key focuses is to be socially responsible and give back to our community.`
    },
    {
        img: img9,
        title: `Client-focused`,
        content: `Customer satisfaction is at the heart of our service delivery.`
    },
    {
        img: img11,
        title: `Quality service delivery`,
        content: `We continually go above and beyond in delivering impeccable services`
    },
    {
        img: img10,
        title: `Mutual Respect`,
        content: `We want to ensure a mutually respectful relationship with our clients.`
    },
    {
        img: img8,
        title: `Teamwork`,
        content: `We work as a team to deliver value to clients and stakeholders.`
    },
]

export const PwanPlan = [
    {
        img: max,
        title: `${siteNameShort} istock fund`,
        content: `Grow your money with our safe and flexible investment product. You can withdraw at any time you need your money.`
    },
    {
        img: max,
        title: `${siteNameShort} midas fund`,
        content: `Grow your money with our safe and flexible investment product. You can withdraw at any time you need your money.`
    },
    {},
    {
        img: max,
        title: `${siteNameShort} prime fund`,
        content: `Grow your money with our safe and flexible investment product. You can withdraw at any time you need your money.`
    },
]

export const WhyPwan = [
    {
        img: safety,
        color: 'bg-purple-200/60',
        title: 'safety first',
        content: 'Fund safety is our most important principle.'
    },
    {
        img: returns,
        color: 'bg-orange-200/60',
        title: 'good returns',
        content: 'We achieve industry-leading returns for our customers.'
    },
    {
        img: dividends,
        color: 'bg-teal-200/60',
        title: 'daily dividends',
        content: 'You accrue dividends and gains on a daily basis.'
    },
    {
        img: compound,
        color: 'bg-red-200/60',
        title: 'compound gains',
        content: 'Our compound interest model increases your return.'
    }
];


export const AllServiceLinks = [
    {
        title: 'airtime',
        img: img12,
        url: '/airtime',
    },
    {
        title: 'data',
        img: img13,
        url: '/data'
    },
    {
        title: 'electricity',
        img: img14,
        url: '/electricity'
    },
    {
        title: 'cable',
        img: img15,
        url: '/cable'
    },
    {
        title: 'exam pin',
        img: img18,
        url: '/exam_pin'
    },
    {
        title: 'a2c',
        img: img17,
        url: '/airtime_to_cash'
    },
    {
        title: 'service transactions',
        img: lints,
        url: '/service_transactions'
    },
    {
        title: 'shortlets',
        img: img16,
        url: '/dashboard/shortlets'
    },
    {
        title: 'tours',
        img: img19,
        url: false
    },
    {
        title: 'buy/sell crypto',
        img: img20,
        url: false
    },
    {
        title: 'global transfer',
        img: img21,
        url: false
    },
]

export const HomeAboutUs = [
    {
        img: works,
        title: 'How it Works',
        content: 'We help you grow your money and distribute daily compound interest to all our customers.'
    },
    {
        img: calc,
        title: 'Our Model',
        content: 'We carefully and diligently use our expertise to grow your money using safe, high return investments.'
    },
    {
        img: model,
        title: 'Investment Calculator',
        content: 'Our investment calculator simulates the daily growth of your money.'
    },
]

export const HomeNavs = [
]

export const HeadStaffs = [
    // {
    //     img: lil,
    //     name: 'Lordwilliams Greatness',
    //     title: 'CEO / founder',
    //     link: '/williams'
    // },
    {
        img: lil1,
        name: 'Austine R N Okonkwo',
        title: 'Co. founder',
        link: ''
    },
    // {
    //     img: whoweare,
    //     name: 'james author',
    //     title: 'Chief financial officer'
    // },
    {
        img: lil3,
        name: 'ABDUL RAHEEM',
        title: 'president',
        link: ""
    },
    {
        img: lil4,
        name: 'Raphael Abala Pepple',
        title: 'GMO/MD',
        link: ""
    },
    // {
    //     img: whoweare,
    //     name: 'james author',
    //     title: 'vice president'
    // },
]

export const FaqData = [
    {
        q: `What is Quick Direct?`,
        a: `Quick Direct is a financial services firm offering innovative investment solutions, Global Money Transfer, Travel & hotel booking, apartment rentals and more to individuals, institutions, and families.`
    },

    {
        q: `What services does Quick Direct provide ?`,
        a: `Our services include investment management, financial planning, wealth management, and advisory services tailored to each client's unique needs.`
    },

    {
        q: `What is the minimum investment required ?`,
        a: `The minimum investment varies depending on the specific service or portfolio.Please contact us for more information.`
    },

    {
        q: `How do I open an account with Quick Direct ?`,
        a: `You can open an account by allocating the[Get started bottom] in the home page.`
    },

    {
        q: `What is the investment process like ?`,
        a: `Our investment process involves understanding your goals, risk tolerance, and preferences, followed by creating a customized portfolio, ongoing monitoring, and regular updates.`
    },

    {
        q: `How do I access my account information ?`,
        a: `You can access your account information through our secure online portal or by contacting our client services team.`
    },

    {
        q: `What is Quick Direct's investment philosophy?`,
        a: `Our investment philosophy is centered around a long - term approach, diversification, and a commitment to excellence.`
    },

    {
        q: `How do I contact Quick Direct ?`,
        a: `You can contact us through our website, phone, email, or by visiting our office.`
    },

    {
        q: `Is my personal and financial information secure ?`,
        a: `Yes, we prioritize the security and confidentiality of your information, using robust measures to protect your data.`
    },

    {
        q: `What if I have a complaint or concern ?`,
        a: `We value your feedback and concerns.Please contact our client services team, and we will address your issue promptly and fairly.`
    },

    {
        q: `How do I stay updated on market insights and news ?`,
        a: `We publish regular market updates, insights, and news on our website and social media channels.You can also subscribe to our newsletter or follow us on social media.`
    }
]