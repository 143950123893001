import { MenuItem } from '@mui/material'
import { Apis, Authposturl } from 'components/Apis'
import Formbutton from 'components/utils/Formbutton'
import Forminput from 'components/utils/Forminput'
import { ErrorAlert, NairaSign, SuccessAlert } from 'components/utils/functions'
import Loading from 'components/utils/Loading'
import Selectinput from 'components/utils/Selectinput'
import React, { useState } from 'react'
import { FaMinus } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

const ServicesTags = [
    "DATA",
    "AIRTIME",
    "CABLE",
]

const Serviceheaders = [
    "Plan ID",
    "Plan Name",
    "Amount",
    "Selling price",
    "Cashback",
    "",
    "",
]

export default function ServiceForm({singleData}) {
    const [forms, setForms] = useState({
        serviceid: '',
        servicename: '',
        percentage: 0,
        cashback: 0,
    })
    const [screen, setScreen] = useState(singleData ? 3 : 1)
    const [tagname, setTagname] = useState('')
    const [loading, setLoading] = useState(false)
    const [plans, setPlans] = useState([])
    const navigate = useNavigate()
    const [planform, setPlanform] = useState({
        id: "",
        planid: '',
        planname: '',
        amount: 0,
        sell: 0,
        cashback: 0,
    })
    const [singleplan, setSingleplan] = useState({})
    const [servicetag, setServicetag] = useState("")


    const handleForms = (e) => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }

    const handlePlanforms = (e) => {
        setPlanform({ ...planform, [e.target.name]: e.target.value })
    }

    function HandleTag(e) {
        const val = e.target.value
        if(val) {
            setTagname(val)
            setScreen(2)
        }else {
            setTagname('')
            setScreen(1)
        }
    }

    function HandleServiceTag(e) {
        const val = e.target.value
        const findTag = singleData.tags.find(ele => ele.serviceid === val)
        if(val) {
            setTagname(singleData.name)
            setServicetag(val)
            setScreen(2)
            setForms({
                serviceid: findTag.serviceid,
                servicename: findTag.servicename,
                percentage: findTag.percentage,
                cashback: findTag.cashback,
            })
            setPlans(findTag.plans)
        }else {
            setTagname('')
            setServicetag('')
            setScreen(1)
            setForms({
                serviceid: '',
                servicename: '',
                percentage: 0,
                cashback: 0,
            })
            setPlans([])
        }
    }

    function AddPlanform() {
        if (!planform.planid) return ErrorAlert('Plan ID is required')
        if (!planform.planname) return ErrorAlert('Plan Name is required')
        if (!planform.amount) return ErrorAlert('Plan Original amount is required')
        if (!planform.sell) return ErrorAlert('Amount to sell plan is required')
        const findPlan = plans.find(ele => ele.planid === planform.planid)
        if (!singleplan?.planid) {
            if (findPlan) return ErrorAlert('Plan already exists')
            setPlans([
                 ...plans, planform
            ])
        } else {
            const hosted = plans.map(ele => {
                if (ele.planid === singleplan.planid) {
                    return {
                        ...ele,
                        planid: planform.planid,
                        planname: planform.planname,
                        amount: planform.amount,
                        sell: planform.sell,
                        cashback: planform.cashback,
                    }
                }
                return ele
            })
            setPlans(hosted)
        }
        setPlanform({
            id: "",
            planid: '',
            planname: '',
            amount: 0,
            sell: 0,
            cashback: 0,
        })
        setSingleplan({})
    }

    function DeletePlan(id) {
        const hosted = plans.filter(ele => ele.planid !== id)
        setPlans(hosted)
    }

    function HandleSinglePlan(item) {
        setPlanform(item)
        setSingleplan(item)
    }

    async function HandleSubmission() {
        if (!tagname) return ErrorAlert('Service Tag is required')
        if (!forms.serviceid) return ErrorAlert('Service ID is required')
        if (!forms.servicename) return ErrorAlert('Service Name is required')

            setLoading(true)
        try {
            const dataToSend = {
                tagname: tagname,
                ...forms,
                tag: singleData ? 'update' : 'create',
                plans: plans,
                id: singleData?.id ?? ""
            }
            const response = await Authposturl(Apis.ncwallet.manage_services, dataToSend)
            if (response.status !== 200) return ErrorAlert(response.error)
            SuccessAlert(response.msg)
            return navigate(`/admin/ncwallet-services`)
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoading(false)
        }
    }
    if(screen === 1) return (
        <div className="bg-white p-5 mt-5">
            <Selectinput content="Service Tag" value={tagname} onChange={HandleTag}>
                {ServicesTags.map((item, index) => (
                    <MenuItem key={index} value={item}>{item}</MenuItem>
                ))}
            </Selectinput>
        </div>
    )
    if(screen === 3) return (
        <div className="bg-white p-5 mt-5">
            <Selectinput content="Service" value={servicetag} onChange={HandleServiceTag}>
                {singleData?.tags?.map((item, index) => (
                    <MenuItem key={index} value={item.serviceid}>{item.servicename}</MenuItem>
                ))}
            </Selectinput>
        </div>
    )
    if(screen === 2) return (
        <div className='p-5 bg-white mt-5'>
            {loading && <Loading /> }
            <div>
                <div className="bg-sub/10 font-medium mb-5 border border-sub py-2 px-3 rounded-md">
                <div className="">Refer to <a href="https://www.ncwallet.africa/pricing" rel="noreferrer" target='_blank' className="text-blue-600 underline">Ncwallet africa pricing</a> for all services and plans informations</div>
                <div className="">Kindly note that wrong information provided, could be the reason this service doesn't work as expected</div>
                </div>
                <div className="flex items-center justify-between">
                    <div className='flex items-center gap-2'>
                    {!singleData ? <div className=""> <button className="text-blue-700" onClick={() => {setScreen(1); setTagname('')}}> Back </button> </div> :
                    <div className=""> <button className="text-blue-700" onClick={() => {setScreen(3); setServicetag('')}}> Back </button> </div>}
                    <FaMinus />
                    <div className='font-bold'>{tagname}</div>
                    </div>
                    <div className="w-fit ml-auto">
                    <Formbutton title="Submit" handleSubmission={HandleSubmission} loading={loading} />
                    </div>
                </div>
                <div className="font-bold mt-5"> Service Details</div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                    <Forminput content="Service ID" formName={"serviceid"} value={forms.serviceid} onChange={handleForms} />
                    <Forminput content="Service Name" formName={"servicename"} value={forms.servicename} onChange={handleForms} />
                    {[ServicesTags[1]].includes(tagname) && <>
                        <Forminput content="Service Percentage (%) (Optional)" formName={"percentage"} value={forms.percentage} onChange={handleForms} />
                        <Forminput content="Service Cashback (%)" formName={"cashback"} value={forms.cashback} onChange={handleForms} />
                    </>}
                </div>
               {![ServicesTags[1]].includes(tagname) && <div className="">
                    <div className="font-bold mt-5">Add Service Plans</div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                        <Forminput content="Plan ID" formName={"planid"} value={planform.planid} onChange={handlePlanforms} />
                        <Forminput content="Plan Name" formName={"planname"} value={planform.planname} onChange={handlePlanforms} />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                        <Forminput content="Plan Amount" formName={"amount"} value={planform.amount} onChange={handlePlanforms} />
                        <Forminput content="Selling price" formName={"sell"} value={planform.sell} onChange={handlePlanforms} />
                        <Forminput content="Cashback" formName={"cashback"} value={planform.cashback} onChange={handlePlanforms} />
                    </div>
                    <div className="w-fit ml-auto">
                        <Formbutton handleSubmission={AddPlanform} title={singleplan?.planid ? "Update" : "+ Add"} />
                    </div>

                    <div className="font-bols mt-5 overflow-x-auto scrollsdown">
                        <table className="w-full">
                            <thead>
                                <tr>
                                    {Serviceheaders.map((item, index) => (
                                        <td className="text-sm font-bold p-2 truncate" key={index}>{item}</td>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {plans.map((item, index) => (
                                    <tr className="border-b truncate" key={index}>
                                        <td className="p-2">{item.planid}</td>
                                        <td className="p-2">{item.planname}</td>
                                        <td className="p-2">{NairaSign}{item.amount?.toLocaleString()}</td>
                                        <td className="p-2">{NairaSign}{item.sell?.toLocaleString()}</td>
                                        <td className="p-2">{NairaSign}{item.cashback?.toLocaleString()}</td>
                                        <td className="p-2"> <button
                                            onClick={() => HandleSinglePlan(item)}
                                            className='capitalize border border-slate-400 hover:bg-main hover:text-white transition-all py-1 px-2 rounded-md'>edit</button> </td>
                                        <td className="p-2"> <button
                                            onClick={() => DeletePlan(item.planid)}
                                            className='capitalize border border-slate-400 hover:bg-main hover:text-white transition-all py-1 px-2 rounded-md'>delete</button> </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>}
            </div>
        </div>
    )
}
