
import React, { useState } from 'react'
import Forminput from 'components/utils/Forminput'
import ShortletFormNavs from './ShortletFormNavs'
import { FaArrowLeft } from 'react-icons/fa6'
import { ErrorAlert, Errors, GetAmountMinusPercent, NairaSign, PickupUsersFront, RedirectLinks, Swals } from 'components/utils/functions'
import Formbutton from 'components/utils/Formbutton'
import { Apis, Posturl } from 'components/Apis'

const HomeShortletForm = ({ shortlet, closeView, usertag, tagged }) => {
    const [loading, setLoading] = useState(false)
    const [screen, setScreen] = useState(1)
    const [forms, setForms] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        checkin: '',
        checkinTime: '',
        checkout: '',
        checkoutTime: '',
        country: '',
        state: '',
        address: '',
        comment: '',
        ispickup: '',
        pickuplevel: '',
    })
    const [pickuplevel, setPickuplevel] = useState({})
    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }
    const FirstValidation = () => {
        if (!forms.firstname) return ErrorAlert('First name is required')
        if (!forms.lastname) return ErrorAlert('Last name is required')
        if (!forms.email) return ErrorAlert('Email address is required')
        if (!forms.phone) return ErrorAlert('Phone Number is required')
        if (!forms.ispickup) return ErrorAlert('Select your pickup preference')
        setScreen(prev => prev + 1)
    }
    const SecondValidation = () => {
        if (!forms.checkin) return ErrorAlert('Check in Date is required')
        if (!forms.checkinTime) return ErrorAlert('Check in Time is required')
        if (!forms.checkout) return ErrorAlert('Check out Date is required')
        if (!forms.checkoutTime) return ErrorAlert('Check out Time is required')
        if (!forms.country) return ErrorAlert('Country of residence is required')
        if (!forms.state) return ErrorAlert('State / City of residence is required')
        if (!forms.address) return ErrorAlert('Official Address is required')
        setScreen(prev => prev + 1)
    }
    const HandleSubmission = async e => {
        e.preventDefault()
        const formdata = {
            ...forms,
            tag: usertag,
            pageurl: RedirectLinks.generalpickup_url,
            shortlet: shortlet.id,
            pickuplevel: forms.ispickup === 'yes' ? forms.pickuplevel : null,
            tagged: tagged,
            pricing: forms.ispickup === 'yes' ? GetAmountMinusPercent(shortlet.price, shortlet.discount) + pickuplevel.price :  GetAmountMinusPercent(shortlet.price, shortlet.discount)
        }
        setLoading(true)
        try {
            const response = await Posturl(Apis.feeds.request_pickup, formdata)
            if (response.status === 200) {
                closeView()
                Swals('Request Recieved', response.msg, 'success')
            } else {
                return ErrorAlert(`${response.msg}`)
            }
        } catch (error) {
            return Errors(error)
        } finally {
            setLoading(false)
        }
    }
    return (
        <form onSubmit={HandleSubmission}>
            <div className="font-bold text-2xl mb-1 pb-5 capitalize mt-5 border-b flex items-center gap-3">{screen > 1 && <FaArrowLeft onClick={() => setScreen(prev => prev - 1)} className="cursor-pointer" />} pickup details</div>
            <ShortletFormNavs
                screen={screen}
            />
            <div className="max-h-[60dvh] overflow-y-auto scrolls pb-10 px-3">
                {screen === 1 && <>
                    <div className="mb-3">
                        <div className="">First Name</div>
                        <Forminput formName={'firstname'} value={forms.firstname} onChange={handleForms} label={'First Name'} />
                    </div>
                    <div className="mb-3">
                        <div className="">Last Name</div>
                        <Forminput formName={'lastname'} value={forms.lastname} onChange={handleForms} label={'Last Name'} />
                    </div>
                    <div className="mb-3">
                        <div className="">Email Address</div>
                        <Forminput formName={'email'} value={forms.email} onChange={handleForms} label={'Email Address'} />
                    </div>
                    <div className="mb-3">
                        <div className="">Phone Number</div>
                        <Forminput formName={'phone'} value={forms.phone} onChange={handleForms} label={'Phone Number'} />
                    </div>
                    <div className="mb-3">
                        <div className="">Request for a pickup driver</div>
                        <Forminput formtype='select' formName={'ispickup'} value={forms.ispickup} onChange={handleForms}>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </Forminput>
                    </div>
                  {forms.ispickup === 'yes' &&  <div className="mb-10">
                        <div className="font-bold">Select Pickup Options</div>
                        {shortlet.levels.map((ele, index) => (
                            <div 
                            onClick={() => {setForms({...forms, pickuplevel: ele.id}); setPickuplevel(ele)}}
                            className="border p-2 rounded-lg mb-1 cursor-pointer hover:scale-105 transition-all" key={index}>
                                <div className="flex items-center gap-2">
                                    <div>
                                    <div className={`w-5 h-5 rounded-full border ${forms.pickuplevel === ele.id ? 'bg-blue-600' : 'bg-slate-300'}`}></div>
                                    </div>
                                    <div className="">
                                        <div className="grid grid-cols-5">
                                            <div className="col-span-3 font-bold">{ele.title}</div>
                                            <div className="col-span-2 text-right">{NairaSign}{ele.price?.toLocaleString()}</div>
                                        </div>
                                        <div className="text-sm">{ele.content}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>}
                    <div className="w-fit ml-auto">
                        <button onClick={() => FirstValidation()} type="button" className="bg-main py-2.5 px-5 rounded-lg text-white capitalize text-sm">next</button>
                    </div>
                </>}

                {screen === 2 && <>
                    <div className="">Check in time & date</div>
                    <div className="grid grid-cols-5 gap-3">
                        <div className="mb-3 col-span-3">
                            <Forminput type="date" value={forms.checkin} formName="checkin" onChange={handleForms} />
                        </div>
                        <div className="mb-3 col-span-2">
                            <Forminput type="time" value={forms.checkinTime} formName="checkinTime" onChange={handleForms} />
                        </div>
                    </div>
                    <div className="">Check out time & date</div>
                    <div className="grid grid-cols-5 gap-3">
                        <div className="mb-3 col-span-3">
                            <Forminput type="date" value={forms.checkout} formName="checkout" onChange={handleForms} />
                        </div>
                        <div className="mb-3 col-span-2">
                            <Forminput type="time" value={forms.checkoutTime} formName="checkoutTime" onChange={handleForms} />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 mb-3">
                        <div className="">
                            <div className="">Country</div>
                            <Forminput formName={'country'} value={forms.country} onChange={handleForms} label={'Country'} />
                        </div>
                        <div className="">
                            <div className="">State</div>
                            <Forminput formName={'state'} value={forms.state} onChange={handleForms} label={'State'} />
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="">Address</div>
                        <Forminput formName={'address'} value={forms.address} onChange={handleForms} formtype='textarea' label={'Address'} />
                    </div>
                    <div className="w-fit ml-auto">
                        <button onClick={() => SecondValidation()} type="button" className="bg-main py-2.5 px-5 rounded-lg text-white capitalize text-sm">next</button>
                    </div>
                </>}
                {screen === 3 && <>
                    <div className="grid grid-cols-2 p-2 border-b text-sm">
                        <div className="text-slate-500">Shortlet Enquired</div>
                        <div className="font-semibold text-right">{shortlet.title}</div>
                    </div>
                    <div className="grid grid-cols-2 p-2 border-b text-sm">
                        <div className="text-slate-500">Total Pricing</div>
                      {forms.ispickup === 'yes' ?  <div className="font-semibold text-right">{NairaSign}{(GetAmountMinusPercent(shortlet.price, shortlet.discount) + pickuplevel.price)?.toLocaleString()}</div> :
                        <div className="font-semibold text-right">{NairaSign}{GetAmountMinusPercent(shortlet.price, shortlet.discount)}</div>}
                    </div>
                    <div className="grid grid-cols-2 p-2 border-b text-sm">
                        <div className="text-slate-500">Need a pick up driver</div>
                        <div className="font-semibold text-right capitalize">{forms.ispickup}</div>
                    </div>
                   {forms.ispickup === 'yes' && <div className="grid grid-cols-2 p-2 border-b text-sm">
                        <div className="text-slate-500">Pickup Preference</div>
                        <div className="">
                            <div className="text-right font-bold">{pickuplevel.title}</div>
                            <div className="text-right">{NairaSign}{pickuplevel.price?.toLocaleString()}</div>
                            <div className="text-right">{pickuplevel.content}</div>
                        </div>
                    </div>}
                    <div className="grid grid-cols-2 p-2 border-b text-sm">
                        <div className="text-slate-500">full name</div>
                        <div className="font-semibold text-right">{forms.firstname} {forms.lastname}</div>
                    </div>
                    <div className="grid grid-cols-2 p-2 border-b text-sm">
                        <div className="text-slate-500">Phone Number</div>
                        <div className="font-semibold text-right">{forms.phone}</div>
                    </div>
                    <div className="grid grid-cols-2 p-2 border-b text-sm">
                        <div className="text-slate-500">Email Address</div>
                        <div className="font-semibold text-right">{forms.email}</div>
                    </div>
                    <div className="grid grid-cols-2 p-2 border-b text-sm">
                        <div className="text-slate-500">Check In</div>
                        <div className="font-semibold text-right">{forms.checkin} {forms.checkinTime}</div>
                    </div>
                    <div className="grid grid-cols-2 p-2 border-b text-sm">
                        <div className="text-slate-500">Check Out</div>
                        <div className="font-semibold text-right">{forms.checkout} {forms.checkoutTime}</div>
                    </div>
                    <div className="grid grid-cols-2 p-2 border-b text-sm">
                        <div className="text-slate-500">Nationality</div>
                        <div className="font-semibold text-right">{forms.country}, {forms.state}</div>
                    </div>
                    <div className="grid grid-cols-2 p-2 border-b text-sm">
                        <div className="text-slate-500">Address</div>
                        <div className="font-semibold text-right">{forms.address}</div>
                    </div>
                    <div className="grid grid-cols-2 p-2 border-b text-sm">
                        <div className="text-slate-500">Comment</div>
                        <div className="font-semibold text-right">{forms.comment || 'Nil'}</div>
                    </div>
                    <div className="mt-3">
                        <div className="">Comment (Optional)</div>
                        <Forminput formName={'comment'} value={forms.comment} onChange={handleForms} formtype='textarea' label={'Leave a comment'} />
                    </div>
                    <div className="w-fit ml-auto">
                        <Formbutton loading={loading} title="submit contact" />
                    </div>
                </>}
            </div>
        </form>
    )
}

export default HomeShortletForm

