import React, { useEffect, useState } from 'react'
import ALayout from 'components/admin/ALayout'
import ShortletForm from './ShortletForm'
import { Apis, Authgeturl, imgurl } from 'components/Apis'
import { Errors } from 'components/utils/functions'
import { useParams } from 'react-router-dom'

const EditShortlet = () => {
  const [loading, setLoading] = useState(false)
  const [shortlet, setShortlet] = useState({})
  const [imgArr, setImageArr] = useState([])
  const {id} = useParams()
  useEffect(() => {
    const fetchShortlet = async () => {
      setLoading(true)
      try {
        const res = await Authgeturl(`${Apis.feeds.single_shortlet}/${id}`)
        if (res.status === 200) {
          const imgArr = []
          res.msg.media.map((item, index) => {
            const data = {
              img: item.image,
              name: item.image,
              id: item.id,
              image: null
            }
            return imgArr.push(data)
          })
          setImageArr(imgArr)
          return setShortlet(res.msg)
        }
      } catch (error) {
        Errors(`${error}`)
      } finally {
        setLoading(false)
      }
    }
    fetchShortlet()
  }, [])
  return (
    <ALayout>
     {!loading && <ShortletForm itemdata={shortlet} imgs={imgArr} />}
    </ALayout>
  )
}

export default EditShortlet
