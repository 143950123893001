
import React, { useCallback, useEffect, useState } from 'react'
import ALayout from 'components/admin/ALayout'
import { Errors } from 'components/utils/functions'
import { Apis, Authgeturl } from 'components/Apis'
import moment from 'moment'
import ManagersModal from './ManagersModal'

const Tableheaders = [
    "S/N",
    "Name",
    "Plan",
    "Email",
    "Phone",
    "Whatsapp",
    "date created",
]

export default function Managers () {
    const [managers, setManagers] = useState([])
    const [loading, setLoading] = useState(false)
    const [single, setSingle] = useState({})
    const [view, setView] = useState(false)
    const [plans, setPlans] = useState([])

    const FetchAllPlans = useCallback(async () => {
        try {
            const res = await Authgeturl(Apis.investments.all_investment_manager)
            const response = await Authgeturl(Apis.investments.all_plans)
            if (res.status === 200) {
                setManagers(res.msg)
                return setPlans(response.msg)
            }
        } catch (error) {
            Errors(error)
        } finally {
            setLoading(false)
        }
    }, [])
    useEffect(() => { FetchAllPlans() }, [FetchAllPlans])

    const handleSingle = val => {
        setSingle(val)
        setView(!view)
    }
  return (
    <ALayout>
        <div className="">
            {view && <ManagersModal
                item={single}
                closeView={() => setView(!view)}
                plans={plans}
                refetch={() => { FetchAllPlans(); setView(!view) }}
            />}
            <div className="mt-10">
                <div className="w-fit ml-auto mb-6"> <button 
                onClick={() => {setView(!view); setSingle({})}}
                className="bg-main text-white py-3 px-4 capitalize rounded-lg">new category</button> </div>
                {!loading && <table className='table table-auto w-full bg-white p-3 text-sm'>
                    <thead>
                        <tr className='bg-slate-700 text-white'>
                            {Tableheaders.map((ele, i) => (
                                <td className='capitalize p-3' key={i}>{ele}</td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {managers.map((item, i) => {
                            const findPlan = plans.find(ele => ele.id === item.plan)
                            return (
                                <tr 
                                key={i}
                                className='cursor-pointer border-b border-r border-l'
                                onClick={() => handleSingle(item)}
                                >
                                    <td className='p-3 capitalize'>{i + 1}</td>
                                    <td className='p-3 capitalize'>{item.name}</td>
                                    <td className='p-3 capitalize'>{findPlan?.title}</td>
                                    <td className='p-3 capitalize'>{item.email}</td>
                                    <td className='p-3 capitalize'>{item.phone}</td>
                                    <td className='p-3 capitalize'>{item.dailcode}{item.whatsapp}</td>
                                    <td className='p-3'>{moment(item.createdAt).format('DD-MM-YYYY hh:ss A')}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>}
            </div>
        </div>
    </ALayout>
  )
}

