import { useState } from "react";
import ModalLayout from "components/utils/ModalLayout";
import Forminput from "components/utils/Forminput";
import { ErrorAlert, NairaSign, SuccessAlert } from "components/utils/functions";
import Formbutton from "components/utils/Formbutton";
import { Apis, Authposturl, Authputurl } from "components/Apis";


export default function AssetModal({ closeView , pagedata, refetchData}) {
    const [loading, setLoading] = useState(false)
    const [forms, setForms] = useState({
        baseprice: pagedata?.baseprice || '',
        basepercent: pagedata?.basepercent || '',
        sellingpercent: pagedata?.sellingpercent || '',
        sellingprice: pagedata?.sellingprice || '',
        trend: pagedata?.trend || '',
        title: pagedata?.title || '',
    })

    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }

    const SubmitForm = async (e) => {
        e.preventDefault()
        if(!forms.title) return ErrorAlert('Asset title is required')
        if(!forms.basepercent) return ErrorAlert('Asset base percentage is required')
        if(!forms.basepercent) return ErrorAlert('Asset base price is required')
        if(!forms.sellingpercent) return ErrorAlert('Asset selling percentage is required')
        if(!forms.sellingprice) return ErrorAlert('Asset selling price is required')
        setLoading(true)
    const formdata = {
        ...forms,
        id: pagedata?.id ? pagedata.id : ''
    }
        try {
            const response = !pagedata?.id ? await Authposturl(Apis.investments.create_asset, formdata) : await Authputurl(Apis.investments.update_asset, formdata)
            if(response.status === 200) {
                SuccessAlert(response.msg)
                refetchData()
                closeView()
            }else {
                ErrorAlert(`${response.msg}`)
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        }finally {
            setLoading(false)
        }
    }

    const handleSellingPercent = val => {
        if (!forms.baseprice) return ErrorAlert('Provide base price')
        if (!forms.basepercent) return ErrorAlert('Provide base percentage')
        if (val.length > 0) {
            const sum = parseFloat(forms.baseprice) + ((parseFloat(forms.baseprice) * val) / 100)
            const trendValue = forms.sellingpercent - forms.basepercent
            let trend;
            if(trendValue > 0) {
                trend = `${trendValue}`
            }else {
                trend = `${trendValue}`
            }
            setForms({
                ...forms,
                sellingprice: sum?.toFixed(9),
                trend: parseFloat(trend)?.toFixed(2)
            })
        } else {
            setForms({ ...forms, sellingprice: '' })
        }
    }

    return (
        <ModalLayout closeView={closeView}>
            <div className="p-6">
                <form onSubmit={SubmitForm}>
                        <div className="">
                            <div className="">Asset Title</div>
                            <Forminput type="text" formName="title" value={forms.title} onChange={handleForms} />
                        </div>
                    <div className="grid grid-cols-2 gap-3 mb-2">
                        <div className="">
                            <div className="">Base Percentage</div>
                            <Forminput type="number" formName="basepercent" value={forms.basepercent} onChange={handleForms} />
                        </div>
                        <div className="">
                            <div className="">Base Price</div>
                            <Forminput type="number" formName="baseprice" value={forms.baseprice} onChange={handleForms} />
                        </div>
                    </div>
                    <div className="mb-3 font-bold">Your Selling percentage will determine what holders will see</div>
                    <div className="grid grid-cols-2 gap-3 mb-2">
                        <div className="">
                            <div className="">Selling Percentage</div>
                            <Forminput type="number" onKeyup={e => handleSellingPercent(e.target.value)} formName="sellingpercent" value={forms.sellingpercent} onChange={handleForms} />
                        </div>
                        <div className="">
                            <div className="">Selling Price</div>
                            <Forminput type="number" formName="sellingprice" value={forms.sellingprice} onChange={handleForms} />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 gap-3 mb-2 lg:grid-cols-2">
                        <div className="">
                            <div className="grid grid-cols-2 p-1">
                                <div className="text-sm">Selling Percent</div>
                                <div className="text-sm text-right">{forms.sellingpercent}%</div>
                            </div>
                            <div className="grid grid-cols-2 p-1">
                                <div className="text-sm">Market Trend</div>
                                <div className="text-sm text-right">{forms.trend}%</div>
                            </div>
                            <div className="grid grid-cols-2 p-1">
                                <div className="text-sm">Purchasing Power</div>
                                <div className="text-sm text-right"> {forms.sellingprice} to {NairaSign}1 </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-10 ml-auto w-fit">
                        <Formbutton title="Submit" loading={loading} />
                    </div>
                </form>
            </div>
        </ModalLayout>
    )
}