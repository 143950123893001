import React, { useCallback, useEffect, useState } from 'react'
import ALayout from 'components/admin/ALayout'
import AHeader from 'components/admin/AHeader'
import { Apis, Authgeturl, imgurl } from 'components/Apis'
import { Errors } from 'components/utils/functions'
import FeedModal from './FeedModal'
import Imaged from 'components/utils/Imaged'
import Pagination from 'components/utils/Pagination'
import { useSearchParams } from 'react-router-dom'

const AllFeeds = () => {
    const [loading, setLoading] = useState(false)
    const [view, setView] = useState(false)
    const [feed, setFeed] = useState({})
    const [feeds, setFeeds] = useState({})
    const [query, setQuery] = useSearchParams()

    const FetchAllFeed = useCallback( async () => {
        setLoading(true)
        try {
            const res = await Authgeturl(`${Apis.feeds.all_feeds}?p=${query.get('p') ?? 1}`)
            if (res.status === 200) {
                setFeeds(res)
            }
        } catch (error) {
            Errors(error)
        }finally {
            setLoading(false)
        }
    }, [query])
    useEffect(() => {
        FetchAllFeed()
    }, [FetchAllFeed])

    const handleSingles = val => {
        setFeed(val)
        setView(!view)
    }
    const ChangePage = num => {
        setQuery({...query, p: num })
    }

    if(loading) return (
        <ALayout>
            <div className="">
                <AHeader
                    title="total newsfeeds"
                    total={feeds.total ?? 0}
                    url={`/admin/feeds/new`}
                    link={`create feed`}
                />
                <div className="mt-10">
                    {new Array(5).fill().map((item, i) => (
                        <div key={i} className="mb-3 rounded-lg h-16 bg-slate-200"></div>
                    )) }
                </div>
            </div>
        </ALayout>
    )
    return (
        <ALayout>
            {view && <FeedModal
            data={feed}
            closeView={() => setView(!view)}
            refetch={() => {FetchAllFeed(); setView(!view)}}
            />}
            <div className="">
                <AHeader
                    title="total newsfeeds"
                    total={feeds.total}
                    url={`/admin/feeds/new`}
                    link={`create feed`}
                />
                <div className="mt-10">
                    {feeds.data?.map((item, i) => (
                        <div onClick={() => handleSingles(item)} className="bg-white p-2 mb-2 cursor-pointer" key={i}>
                            <div className="grid grid-cols-5">
                                <div className="col-span-3">
                                    <div className="text-blue-600 text-sm">{item.title}</div>
                                    <div className='text-sm' dangerouslySetInnerHTML={{ __html: item.content.slice(0, 100) }} />
                                </div>
                                <div className="col-span-2 w-fit ml-auto">
                                    {item.image && <Imaged src={item.image} alt="" className="w-10 h-10 object-cover" /> }
                                </div>
                            </div>
                        </div>
                    ))}
                    <Pagination 
                    page={feeds.p}
                    perPage={feeds.page_size}
                    total={feeds.total}
                    onChange={ChangePage}
                    />
                </div>
            </div>
        </ALayout>
    )
}

export default AllFeeds