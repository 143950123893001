import React, { useState } from 'react'
import { SlMenu, SlPower } from 'react-icons/sl'
import ConfirmationModal from '../utils/ConfirmationModal'
import { useSelector } from 'react-redux'
import { ErrorAlert, SuccessAlert, tokenName } from '../utils/functions'
import { Apis, Authposturl } from '../Apis'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { LuUser2 } from 'react-icons/lu'

const ANavbar = ({ handleSideToggle }) => {
  const { user } = useSelector(state => state.data)
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleSubmission = async () => {
    setLoading(true)
    try {
      const res = await Authposturl(Apis.user.logout)
      if(res.status === 200) {
        Cookies.set(tokenName, res.token)
        SuccessAlert(res.msg)
        navigate('/login')
      }else {
        ErrorAlert(res.msg)
      }
    } catch (error) {
      ErrorAlert(error)
    }finally {
      setLoading(false)
    }
  }
  return (
    <div>
      {show && <ConfirmationModal
        closeView={() => setShow(!show)}
        mainbg="bg-red-500"
        loading={loading}
        handleSubmission={handleSubmission}
        title={`Hi ${user.firstname}, Are you aure you want to logout`}
      />}
      <div className="flex items-center justify-between gap-5">
        <div className="">
        <div className="cursor-pointer lg:hidden text-xl"><SlMenu onClick={handleSideToggle} /></div>
        </div>
        <div className="">
          <div className="flex items-center justify-end gap-5">
            <button onClick={() => setShow(!show)} className="cursor-pointer text-xl"> <SlPower /> </button>
            <button className="cursor-pointer text-xl flex items-center bg-slate-200/60 border rounded-lg py-2 px-3"> <LuUser2 /> <span className='text-sm'>{user.firstname}</span> </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ANavbar