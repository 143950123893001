import { useRef, useState } from "react";
import { FaRegPaperPlane, FaTimes } from "react-icons/fa";
import { FiPaperclip } from "react-icons/fi";
import { Apis, Authposturl, SocketConnect } from "components/Apis";
import { ErrorAlert } from "components/utils/functions";
import Imaged from "components/utils/Imaged";

export default function ChatForm({ sendMessage, roomid, email, usertype }) {
    const [content, setContent] = useState('')
    const [image, setImage] = useState({
        img: '',
        file: ''
    })
    const imgref = useRef()
    const active = image.file ? true : content.length > 0 ? true : false
    const SendChatMessage = async () => {
        const forms = {
            content,
            roomid,
            sender: email,
            usertype
        }
        const formdata = new FormData()
        formdata.append('content', content)
        formdata.append('roomid', roomid)
        formdata.append('usertype', usertype)
        formdata.append('sender', email)
        formdata.append('image', image.file)
        const response = await Authposturl(Apis.feeds.send_chat_message, formdata)
        if (response.status === 200) {
            sendMessage(forms)
            setContent('')
            setImage({
                img: null,
                file: null
            })
            imgref.current = null
        }else {
            return ErrorAlert(`${response.msg}`)
        }
    }
    const DetectTyping = (e) => {
        const value = e.target.value
        if (value && value.length > 0) {
            SocketConnect.emit('user-typing')
        } else {
            SocketConnect.emit('user-not-typing')
        }
    }

    const UploadImage = (e) => {
        const file = e.target.files[0]
        if (file) {
            if(file.size > 1000000) {
                ErrorAlert('file upload must not exceed 1MB')
                return imgref.current = null
            }
            if(!file.type.includes('image/')) {
                ErrorAlert('file upload must be a valid image type')
                return imgref.current = null
            }
            setImage({
                img: URL.createObjectURL(file),
                file
            })
        }
    }
    const CancelFileUpload = () => {
        setImage({
            img: null,
            file: null
        })
        imgref.current = null
    }
    return (
        <div className="relative">
            {image.img && <div className="absolute bottom-14 left-0 w-full h-[83dvh] lg:h-[69.8dvh] bg-black/90 backdrop-blur-sm">
                <div className="relative w-full h-full">
                    <div className="w-full h-[90%] relative">
                        <Imaged src={image.img} alt="" className="w-full h-full" />
                    </div>
                    <div className="text-white text-center mt-4">Add Caption below</div>
                    <div 
                    onClick={CancelFileUpload}
                    title="Cancel File Upload"
                    className="absolute bottom-3 text-3xl right-3 text-white cursor-pointer"> <FaTimes /> </div>
                </div>
            </div>}
            <div className="flex items-center gap-8 px-5 pt-2 lg:py-1 bg-transparent rounded-bl-lg rounded-br-lg bg-white">
                <div>
                    <label className="text-xl cursor-pointer">
                        <FiPaperclip />
                        <input type="file" hidden onChange={UploadImage} ref={imgref} />
                    </label>
                </div>
                <div className="flex items-center border rounded-full w-full px-1">
                <textarea
                    value={content}
                    onKeyUp={DetectTyping}
                    onChange={e => setContent(e.target.value)}
                    placeholder="Enter message..."
                    className="w-full text-sm bg-transparent border-none py-1 px-2 !outline-none rounded-full resize-none !focus:outline-none focus:border-none !shadow-transparent scrolls"></textarea>
                {active ? <button onClick={SendChatMessage} className={`text-xl bg-main p-2.5 text-white rounded-full`}> <FaRegPaperPlane /> </button> :
                <button disabled={true} className={`text-xl bg-slate-400 p-2.5 text-white rounded-full`}> <FaRegPaperPlane /> </button>}
                </div>
            </div>
        </div>
    )
}