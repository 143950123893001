import { BiCheck } from "react-icons/bi"
import bot from 'assets/main-images/bot.svg'
import { useSelector } from "react-redux"
import moment from "moment"
import { imgurl } from "components/Apis"
import Imaged from "components/utils/Imaged"
import ChatFeedback from "./ChatFeedback"
import { useState } from "react"

export default function ChatMessages({ messages }) {
    const { user } = useSelector(state => state.data)
    const [views, setViews] = useState(true)

    return (
        <>
            {messages?.messages?.length < 1 && <div className="flex flex-col items-center justify-center mb-4">
                <Imaged src={bot} alt="" className="w-16" />
                <div className="font-bold text-center">Quick Direct</div>
            </div>}
            {messages?.messages?.map((item, index) => (
                item.chattype === 'info' ? <div className="text-center my-1 chatfoil bg-orange-100/60 w-fit px-5 mx-auto py-2 text-sm rounded-lg" key={index}>{item.content}</div> :
                    item.chattype === 'chat' && item.sender !== user.email ?
                        <div key={index}>
                            <div className="text-center my-1">{item.chatdate !== null ? item.chatdate === moment().format('DD/MM/YYYY') ? 'Today' : item.chatdate : ''}</div>
                            <div className="mt-1 msg text-sm w-fit max-w-[95%] lg:max-w-[92%] chatfoil">
                                <div className="relative p-2 bg-slate-300/40 rounded-tr-xl rounded-tl-xl rounded-br-xl">
                                    {item.image && <div className="w-full h-44">
                                        <Imaged src={`${imgurl}/chats/${item.image}`} alt="" className="w-full h-full rounded-lg object-contain" />
                                    </div>}
                                    <div className="text-sm font-light whitespace-pre-wrap">{item.content}</div>
                                </div>
                                <div className="flex items-center pl-2 text-xs"><BiCheck className="text-lg" /> {moment(item.createdAt).format('h:mmA')}  </div>
                            </div>
                        </div> :
                        item.chattype === 'chat' && <div key={index}>
                            <div className="text-center  my-1">{item.chatdate !== null ? item.chatdate === moment().format('DD/MM/YYYY') ? 'Today' : item.chatdate : ''}</div>
                            <div className="mt-1 msg ml-auto text-sm w-fit max-w-[95%] lg:max-w-[92%] chatfoil">
                                <div className="relative p-2 rounded-tr-xl rounded-tl-xl rounded-bl-xl bg-teal-300/40">
                                    {item.image && <div className="w-full h-44">
                                        <Imaged src={`${imgurl}/chats/${item.image}`} alt="" className="w-full h-full rounded-lg object-contain" />
                                    </div>}
                                    <div className="text-sm font-light whitespace-pre-wrap">{item.content}</div>
                                </div>
                                <div className="flex items-center justify-end pl-2 text-xs">{moment(item.createdAt).format('h:mmA')}<BiCheck className="text-lg" />   </div>
                            </div>
                        </div>
            ))}
            {user.email !== messages?.admin?.email && messages.active === 'no' && views &&
                <ChatFeedback
                    setViews={setViews}
                    messages={messages}
                />}
        </>
    )
}