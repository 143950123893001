
import React, { Suspense } from 'react'
import UserLayout from 'components/user/UserLayout'
const MyTransactions = React.lazy(() => import('./Compos/MyTransactions'))

const Transactions = () => {
    return (
        <UserLayout title="transactions">
            <div className="dark:text-slate-100">
                <Suspense>
                    <MyTransactions tag='trans' limit="all" />
                </Suspense>
            </div>
        </UserLayout>
    )
}

export default Transactions