import React from 'react'
import Footer from 'components/general/Footer'
import Header from 'components/general/Header'
import FaqComponent from 'components/general/FaqComponent'
import { Helmet } from 'react-helmet-async'

export default function Faqs() {
    return (
        <>
        <Helmet prioritizeSeoTags>
            <title>Quick Direct | FAQs</title>
            <link rel="notImportant" href="https://www.quickdirect.com.ng/faqs" />
            <meta name="whatever" value="notImportant" />
            <link rel="canonical" href="https://www.quickdirect.com.ng/faqs" />
            <meta property="og:title" content="Quick Direct | FAQs" />
            <meta property="og:url" content="https://www.quickdirect.com.ng/faqs" />
            <meta property="og:image" content="https://www.quickdirect.com.ng/main.png" />
            <meta property="og:type" content="website" />
            <meta property="og:description" content="Quick Direct is a financial services firm offering innovative investment solutions, Global Money Transfer, Travel & hotel booking, apartment rentals and more to individuals, institutions, and families." />
        </Helmet>
        <div className='bg-slate-50'>
            <Header />
            <div className="w-11/12 mx-auto mb-20 mt-10 lg:w-10/12 bg-white p-5 lg:p-10">

            <div className="font-bold text-xl lg:text-3xl my-5">Frequently Asked Questions</div>
                <div className="">
                    <FaqComponent />
                </div>
            </div>
            <Footer />
        </div>
        </>
    )
}
