import { useQuery } from '@tanstack/react-query'
import { Image } from 'antd'
import { Apis, geturl } from 'components/Apis'
import AllSponsors from 'components/general/AllSponsors'
import ContactForm from 'components/general/ContactForm'
import Footer from 'components/general/Footer'
import Header from 'components/general/Header'
import moment from 'moment'
import React from 'react'
import { SlGlobe } from 'react-icons/sl'
import { useParams } from 'react-router-dom'

export default function NewsfeedsPage() {
    const { id } = useParams()

    const { data, isLoading } = useQuery({
        queryKey: ['newsfeeds', id],
        queryFn: async () => {
            const response = await geturl(`${Apis.feeds.single_feed}/${id}`)
            return await response.msg
        },
    })

    if (isLoading) return (
        <div>
            <Header />
            loading...
            <ContactForm />
            <AllSponsors />
            <Footer />
        </div>
    )
    return (
        <div>
            <Header />
            <div className="w-11/12 mx-auto lg:w-10/12 my-10">
            <div className="font-bold text-3xl mb-2">{data.title}</div>
            <div className="text-zinc-500 mb-10 flex items-center gap-2"> <SlGlobe /> Posted: {moment(data.createdAt).format('Do MMMM YYYY')}</div>
            <div className="h-auto w-full">
                <Image src={`${data.image}`} className='w-full h-full' />
            </div>
            <div className="">
                <div className="" dangerouslySetInnerHTML={{__html: `${data.content}`}} />
            </div>
            </div>
            <ContactForm />
            <AllSponsors />
            <Footer />
        </div>
    )
}
