import React, { useState } from 'react'
import SSidebar from './SSidebar'
import SNavbar from './SNavbar'

const SLayout = ({ children }) => {
    const [togs, setTogs] = useState(false)
    return (
        <div>
            {togs && <div className="fixed top-0 left-0 w-[50vw] h-screen z-10">
                <SSidebar 
                handleToggle={() => setTogs(!togs)}
                />
            </div>}
            <div className="flex items-center w-full h-screen">
                <div className="h-screen w-0 lg:w-[18%] relative">
                <SSidebar 
                handleToggle={() => setTogs(!togs)}
                />
                </div>
                <div className="h-screen w-screen lg:w-[82%] ml-auto bg-slate-50">
                    <div className="bg-white p-3 h-[10dvh]">
                        <SNavbar
                            handleSideToggle={() => setTogs(!togs)}
                        />
                    </div>
                    <div className="p-3 h-[90dvh] overflow-y-auto overflow-x-hidden scrolls py-10">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SLayout