
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { SlClock } from 'react-icons/sl';
import { useParams } from 'react-router-dom';
import { ClientInvestmentStatus, DurationTypes, NairaSign } from 'components/utils/functions';
import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import StockSellout from './StockSellout';

function IstockBoard({ activeplan, plan }) {
    const [num, setNum] = useState("00h:00m:00s");
    const { id } = useParams();
    const dispatch = useDispatch()
    const [loads, setLoads] = useState(true)
    const queryClient = useQueryClient();


    useEffect(() => {
        let interval;

        interval = setInterval(async () => {
            // Get current time
            const currentTime = moment();

            // Calculate the difference between the given moment time and the current time
            const diff = moment(new Date(activeplan?.endDate)).diff(currentTime);

            if (diff <= 0) {
                if (loads) {
                    setLoads(false)
                    if (loads) {
                        queryClient.refetchQueries({ queryKey: [`invest-layout-${id}`] }, { force: true });
                        setTimeout(() => {
                            queryClient.refetchQueries({ queryKey: [`invest-layout-${id}`] }, { force: true });
                        }, 500);
                    }
                }
                clearInterval(interval);
                setNum("00:00:00");
                return;
            }

            // Convert the difference to a countdown format
            const duration = moment.duration(diff);
            // const years = Math.floor(duration.asYears());
            // const months = Math.floor(duration.asMonths());
            // const days = Math.floor(duration.asDays());
            // const hours = Math.floor(duration.asHours());
            // const minutes = duration.minutes();
            // const seconds = duration.seconds();
            const years = duration.years();
            const months = duration.months();
            const days = duration.days();
            const hours = duration.hours();
            const minutes = duration.minutes();
            const seconds = duration.seconds();

            // Format hours, minutes, and seconds to ensure they have two digits
            const formattedYears = years < 10 ? "0" + years : years;
            const formattedMonths = months < 10 ? "0" + months : months;
            const formattedDays = days < 10 ? "0" + days : days;
            const formattedHours = hours < 10 ? "0" + hours : hours;
            const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
            const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

            // Return the countdown time in the specified format
            let fullTimed;
            if (activeplan?.durationType === DurationTypes.years) { fullTimed = `${formattedYears}y:${formattedMonths}m:${formattedDays}d:${formattedHours}h:${formattedMinutes}m:${formattedSeconds}s` }
            if (activeplan?.durationType === DurationTypes.months) { fullTimed = `${formattedMonths}m:${formattedDays}d:${formattedHours}h:${formattedMinutes}m:${formattedSeconds}s` }
            if (activeplan?.durationType === DurationTypes.days) { fullTimed = `${formattedDays}d:${formattedHours}h:${formattedMinutes}m:${formattedSeconds}s` }
            if ([DurationTypes.hours, DurationTypes.minutes].includes(activeplan?.durationType)) { fullTimed = `${formattedHours}h:${formattedMinutes}m:${formattedSeconds}s` }

            setNum(fullTimed);
            setLoads(true)
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [dispatch, id, activeplan, loads]);

    const InvestProps = () => {
        const cs1 = "text-2xl md:text-3xl font-bold capitalize"
        if (activeplan === undefined || activeplan?.status === ClientInvestmentStatus.false) return (<>
            <div className={`${cs1} text-red-400`}> Inactive</div>
            <div className="text-sm flex items-center gap-1 text-white"> <SlClock />Currently not active</div>
        </>)
        if (activeplan?.status === ClientInvestmentStatus.active) return (<>
            <div className={`${cs1} text-green-400 flex items-center gap-1`}><div className="dloader"></div> {activeplan?.status}</div>
            {/* <div className="text-sm flex items-center gap-1 text-white"> <SlClock /> ends: {activeplan?.timed}</div> */}
            <div className="text-sm flex items-center gap-1 text-white"> <SlClock /> ends: {num}</div>
        </>)
        if (activeplan?.status === ClientInvestmentStatus.sell_in) return (<>
            <div className={`${cs1} text-sub`}>{activeplan?.status}</div>
            <div className="text-sm flex items-center gap-1 text-white"> <SlClock /> Waiting for a sell order</div>
        </>)
        if (activeplan?.status === ClientInvestmentStatus.sell_out) return (
            <StockSellout
                cs1={cs1}
                activeplan={activeplan}
            />
        )
    }

    return (
        <div>
            <div className="grid grid-cols-2 gap-5 w-11/12 mx-auto mt-3">
                <div className="flex flex-col items-center gap-2 mb-5">
                    <div className="font-bold text-center text-sm text-slate-200 capitalize">Interest Earned</div>
                    <div className="text-2xl md:text-3xl text-white">{NairaSign}<span className="font-bold">{plan.totalEarned?.toLocaleString() || 0}</span> </div>
                    <div className="text-green-400 font-light text-xs md:text-sm">+{NairaSign}{plan.profitUpdate?.toLocaleString() || 0} <span className="text-white capitalize">last update today</span> </div>
                </div>
                <div className="flex flex-col items-center gap-2 mb-5">
                    <div className="font-bold text-center text-sm text-slate-200 capitalize">investment Status</div>
                    <InvestProps />
                </div>
            </div>
        </div>
    )

}

export default IstockBoard;
