import React, { useState } from 'react'
import Formbutton from 'components/utils/Formbutton'

const SellOffStockButton = ({handleResult}) => {
    const [loading, setLoading] = useState(false)
    const [view, setView] = useState(false)

    const handleSubmission = () => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
            handleResult()
            setView(!view)
        }, 2000);
    }
  return (
    <>
    <Formbutton padding="py-2 px-7" handleSubmission={handleSubmission} title='sell' bg='bg-red-500' loading={loading} font="capitalize text-xs" />
    </>
  )
}

export default SellOffStockButton