import React, { useEffect, useState } from 'react'
import ASidebar from './ASidebar'
import ANavbar from './ANavbar'
import { BiMessage } from 'react-icons/bi'
import { useQuery } from '@tanstack/react-query'
import { Apis, Authgeturl, SocketConnect } from '../Apis'
import { useSelector } from 'react-redux'

const ALayout = ({ children }) => {
    const [togs, setTogs] = useState(false)
    const {user} = useSelector(state => state.data)

    const { data, isLoading, refetch } = useQuery({
        queryKey: ['my-connected-rooms'],
        queryFn: async () => {
            const response = await Authgeturl(Apis.feeds.my_connected_rooms)
            return response.msg
        }
    })

    useEffect(() => {
        // once a room is opened, signal should be sent to the aval admin
        if(user.status === 'online') {
            SocketConnect.on('room-signal', () => refetch())
        }
    }, [SocketConnect])

    return (
        <div className='overflow-hidden'>
            {togs && <div className="fixed top-0 left-0 w-[50vw] h-screen z-10">
                <ASidebar
                    handleToggle={() => setTogs(!togs)}
                />
            </div>}
            <div className="flex items-center w-full h-screen">
                <div className="h-screen w-0 lg:w-[18%] relative">
                    <ASidebar
                        handleToggle={() => setTogs(!togs)}
                    />
                </div>
                <div className="h-screen w-screen lg:w-[82%] ml-auto bg-slate-50 pb-20">
                    <div className="bg-white p-5">
                        <ANavbar
                            handleSideToggle={() => setTogs(!togs)}
                        />
                    </div>
                    <div className="px-3 h-[92.1dvh] overflow-y-auto overflow-x-hidden scrolls pt-3 pb-20">
                        {children}
                    </div>

                    {/* indicates active chat room to the admin connected */}
                   {!isLoading && data?.length > 0 && <div className="fixed bottom-20 right-3">
                        <div className="relative hover:scale-110 transition-all">
                            <div className="absolute -top-2 -right-2 bg-red-500 animate-bounce text-white rounded-full text-xs border-2 border-white py-1 px-1.5">{data?.length < 10 ? `0${data?.length}` : data?.length}</div>
                            <button className='bg-main text-white rounded-full p-3 text-3xl'>
                                <BiMessage />
                            </button>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default ALayout