
import ALayout from 'components/admin/ALayout'
import { Apis, AuthDelete, Authgeturl, Authputurl } from 'components/Apis'
import moment from 'moment'
import AHeader from 'components/admin/AHeader'
import { useQuery } from '@tanstack/react-query'
import Spinner from 'components/utils/Spinner'
import { useNavigate } from 'react-router-dom'
import ModalLayout from 'components/utils/ModalLayout'
import { useState } from 'react'
import { SlArrowRight } from 'react-icons/sl'
import { ErrorAlert, FindDuplicates, NairaSign, SuccessAlert } from 'components/utils/functions'
import { FaArrowLeftLong } from 'react-icons/fa6'
import Formbutton from 'components/utils/Formbutton'
import { MenuItem } from '@mui/material'
import Selectinput from 'components/utils/Selectinput'

const Tableheaders = [
  "Name",
  "Services",
  "Order of appearance",
  "Date created",
  "",
  "",
  "",
]

const TagHeaders = [
  "Plan ID",
  "Plan Name",
  "Plan Amount",
  "Amount to Sell",
  "Cashback",
]


function SingleAssignForm({ item, data, SendSignal }) {
  const [form, setForm] = useState(item.tag ?? '')
  function SeekAssignment(name, tag) {
    setForm(tag)
    const dataSend = {
      name,
      tag
    }
    SendSignal(dataSend)
  }
  return (
    <div className="grid grid-cols-5 gap-5 !text-sm p-3">
      <div className="col-span-3 md:col-span-4">{item.name}</div>
      <div className="col-span-2 md:col-span-1">
        <Selectinput value={form} onChange={e => SeekAssignment(item.name, e.target.value)}>
          {data.map((ele, i) => (
            <MenuItem key={i} value={i + 1}>{i + 1}</MenuItem>
          ))}
        </Selectinput>
      </div>
    </div>
  )
}


export default function ManageNcwalletServices() {
  const navigate = useNavigate()
  const [singles, setSingles] = useState({})
  const [singleTags, setSingleTags] = useState(null)
  const [isDelete, setIsDelete] = useState(null)
  const [loading, setLoading] = useState(false)
  const [assign, setAssign] = useState(false)
  const [allForms, setAllForms] = useState([])

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['admin-all-ncwallet-services'],
    queryFn: async () => {
      const res = await Authgeturl(Apis.ncwallet.all_services)
      return res.data
    }
  })

  async function HandleDelete(id) {
    try {
      setLoading(true)
      const response = await AuthDelete(`${Apis.ncwallet.delete_services}/${id}`)
      if (response.status !== 200) return ErrorAlert(response.msg)
      SuccessAlert(response.msg)
      setIsDelete(null)
      return refetch()
    } catch (error) {
      ErrorAlert(`${error.message}`)
    } finally {
      setLoading(false)
    }
  }

  async function ReassignServiceFunc() {
    //check if more service has the same id tags 
    const checks = FindDuplicates(allForms)
    console.log(checks)
    if (checks.length > 1) {
      return ErrorAlert('You cannot reassign a service to with the same order of appearance')
    }
    try {
      setLoading(true)
      const sendData = {
        services: allForms
      }
      const response = await Authputurl(`${Apis.ncwallet.re_assign_orders}`, sendData)
      if (response.status!== 200) return ErrorAlert(response.msg)
      SuccessAlert(response.msg)
      setAssign(false)
      return refetch()
    } catch (error) {
      ErrorAlert(`${error.message}`)
    } finally {
      setLoading(false)
    }
  }

  function SendSignal(value) {
    const findForm = allForms.find(ele => ele.name === value.name)
    if (!findForm) {
      setAllForms([...allForms, value])
    } else {
      const checks = allForms.map(ele => {
        if (ele.name === value.name) {
          return {
            ...ele,
            tag: value.tag
          }
        }
        return ele
      })
      setAllForms(checks)
    }
  }

  if (isLoading) return (
    <ALayout>
      <div className="">
        <AHeader
          title="Api Wallet Balance"
          total={`${0}`}
          url={false}
        />
        <div className="mt-10 flex flex-col items-center justify-center gap-5">
          <Spinner />
          <div className="animate-pulse">Loading Data....</div>
        </div>
      </div>
    </ALayout>
  )

  return (
    <ALayout>
      {assign && <ModalLayout closeView={() => setAssign(false)}>
        <div className="">
          <div className="font-bold p-5 border-b">
            <div className="text-xl">Re-assign Services</div>
          </div>
          <div className="p-5">
            {data?.map((item, index) => (
              <SingleAssignForm SendSignal={SendSignal} key={index} item={item} data={data} />
            ))}
            <div className="grid grid-cols-2">
              <div></div>
              <Formbutton handleSubmission={ReassignServiceFunc} title="submit" />
            </div>
          </div>
        </div>
      </ModalLayout>}
      {isDelete && <ModalLayout closeView={() => setIsDelete(null)}>
        <div className="w-11/12 mx-auto">
          <div className="p-5 uppercase text-red-600">delete service</div>
          <div className="text-center">Are you sure you want to delete this service?</div>
          <div className="grid grid-cols-2 gap-10 border-t pt-5 mt-3 mb-4">
            <Formbutton handleSubmission={() => setIsDelete(null)} title="Cancel" bg="bg-slate-400" />
            <Formbutton handleSubmission={() => HandleDelete(isDelete)} loading={loading} title="Yes, Delete" bg="bg-red-600" />
          </div>
        </div>
      </ModalLayout>}
      {singles?.id && <ModalLayout closeView={() => setSingles({})}>
        {!singleTags && <div className="">
          <div className="p-4">
            <div className="font-bold text-lg">{singles.name}</div>
          </div>
          {singles.tags.map((item, index) => (
            <div key={index}>
              <div
                onClick={() => setSingleTags(item)}
                className="flex items-center justify-between cursor-pointer hover:bg-slate-100 transition-all p-2 border-b last:border-none">
                <div>
                  <div className="uppercase font-bold">{item.servicename}</div>
                  {singles.name === 'AIRTIME' && <>
                    <div className="">Fee: {item.percentage}%</div>
                    <div className="">Cashback: {item.cashback}%</div>
                  </>}
                </div>
                <SlArrowRight />
              </div>
            </div>
          ))}
        </div>}
        {singleTags && <>
          <div className="">
            <div className="p-4">
              <div className="font-bold text-lg flex items-center uppercase gap-3"> <FaArrowLeftLong className='cursor-pointer' onClick={() => setSingleTags(null)} /> {singleTags.servicename}</div>
            </div>
            <div className="p-5">
              <table className="w-full">
                <thead>
                  <tr>
                    {TagHeaders.map((item, index) => (
                      <td key={index} className="truncate border-b text-sm p-2">{item}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {singleTags.plans.length > 0 && singleTags.plans.map((item, index) => (
                    <tr key={index}>
                      <td className='p-2 truncate border-b text-sm'>{item.planid}</td>
                      <td className='p-2 truncate border-b text-sm'>{item.planname}</td>
                      <td className='p-2 truncate border-b text-sm'>{NairaSign}{item.amount?.toLocaleString()}</td>
                      <td className='p-2 truncate border-b text-sm'>{NairaSign}{item.sell?.toLocaleString()}</td>
                      <td className='p-2 truncate border-b text-sm'>{NairaSign}{item.cashback?.toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {singleTags.plans.length < 1 && <div className='text-sm text-center text-slate-500 my-5'>No Plans found...</div>}
          </div>
        </>}
      </ModalLayout>}
      <div className="">
        <AHeader
          title="Total Virtual Accounts"
          total={`${data?.length ?? 0}`}
          link='New Service'
          url={`/admin/ncwallet-services/new`}
        />
        <div className="w-fit ml-auto mt-6">
          <Formbutton title="Re-assign" handleSubmission={() => setAssign(true)} />
        </div>
        <div className="mt-6 overflow-x-auto scrollsdown">
          <table className='table table-auto w-full bg-white p-3 text-sm'>
            <thead>
              <tr className='bg-slate-700 text-white'>
                {Tableheaders.map((ele, i) => (
                  <td className='capitalize p-3 truncate' key={i}>{ele}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((item, i) => (
                <tr
                  key={i}
                  className='border-b border-r hover:bg-slate-50 transition-all border-l'>
                  <td className='p-3 truncate'>{item.name}</td>
                  <td className='p-3 truncate'>{item.tags.length}</td>
                  <td className='p-3 truncate'>{item.tag}</td>
                  <td className='p-3 truncate'>{moment(item.creatdAt).format('DD-MM-YYYY hh:ss A')}</td>
                  <td> <button onClick={() => setSingles(item)} className="border border-slate-400 py-1 px-3 rounded-sm text-sm hover:bg-main hover:text-white">view</button> </td>
                  <td> <button onClick={() => navigate(`/admin/ncwallet-services/${item.id}/edit`)} className="border border-slate-400 py-1 px-3 rounded-sm text-sm hover:bg-main hover:text-white">edit</button> </td>
                  <td> <button onClick={() => setIsDelete(item.id)} className="border border-slate-400 py-1 px-3 rounded-sm text-sm hover:bg-main hover:text-white">delete</button> </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </ALayout>
  )
}

