
import React, { useEffect, useState } from 'react'
import UserLayout from 'components/user/UserLayout'
import Forminput from 'components/utils/Forminput'
import { ErrorAlert, Errors, NairaSign, Swals } from 'components/utils/functions'
import Formbutton from 'components/utils/Formbutton'
import ServiceConfirmation from './ServiceConfirmation'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Apis, Authgeturl, Authposturl } from 'components/Apis'
import { dispatchUser } from 'app/reducer'
import ConfirmationModal from 'components/utils/ConfirmationModal'

const A2C = () => {
    const [agree, setAgree] = useState(false)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.data)
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [loads, setLoads] = useState(true)
    const { pathname } = useLocation()
    const tag = pathname.split('/')[1]
    const [data, setData] = useState({})
    const [forms, setForms] = useState({
        Service: "",
        Amount: "",
        Number: "",
        actual: "",
    })
    const [single, setSingle] = useState({
        status: false,
        amount: '',
        actual: '',
        paymethod: '',
        service: '',
        data: {},
        cashback: '',
        product: '',
        amountToPay: '',
    })

    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        const fetchService = async () => {
            try {
                const response = await Authgeturl(`${Apis.serviceapi.display}/a2c`)
                if (response.status === 200) return setData(response.msg)
            } catch (error) {
                Errors(`${error.message}`)
            } finally {
                setLoads(false)
            }
        }
        fetchService()
    }, [])

    const handleSubmission = async (e) => {
        e.preventDefault()
        if (!forms.Service) return ErrorAlert('Select your preferred network')
        if (!forms.Number) return ErrorAlert('Provide a valid phone number')
        if (!forms.Amount) return ErrorAlert('Provide a valid amount')
        const tag = data?.tag?.plans.find(ele => ele.serviceid === forms.Service)
        const title = ` Kindly confirm your request to send ${tag.servicetype} worth ${NairaSign}${single.amount?.toLocaleString()} from ${single.Number} to be credited ${NairaSign}${(single.amount - (single.amount * data.tag?.charges) / 100)?.toLocaleString()} into your account`
        setSingle({
            ...single,
            status: true,
            serv: forms.Service,
            pplan: data.tag?.id,
            product: tag.servicetype,
            title
        })
    }

    const submitRequest = async () => {
        if(single.amount < 500) return ErrorAlert(`Enter amount above ${NairaSign}500`)
        const formbody = {
            serv: forms.Service,
            pplan: data.tag?.id,
            ...single,
            paymethod: 'a2c'
        }
        setLoader(true)
        try {
            const response = await Authposturl(Apis.serviceapi.generate_invoice, formbody)
            if (response.status === 200) {
                const invoicebody = {
                    invoiceid: response.msg
                }
                const result = await Authposturl(Apis.serviceapi.purchase_airtime_to_cash, invoicebody)
                if (result.status === 200) {
                    dispatch(dispatchUser(result.user))
                    Swals('Successful purchase', result.msg, 'success')
                    return navigate('/service_transactions')
                } else {
                    Swals('Error Purchasing Airtime', `${result.msg}`, 'error')
                }
            } else {
                Swals('Error Purchasing Airtime', `${response.msg}`, 'error')
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoader(false)
        }
    }

    const handleNumber = e => {
        const val = e.target.value
        setForms({...forms, Number: val })
        setSingle({...single, Number: val })
    }
    
    const handlePriceFee = e => {
        const val = parseFloat(e)
        if (e.length > 0) {
            const actual = val
            const sum = val
            const priced = 1
            setSingle({
                ...single,
                amount: sum,
                amountToPay: val - (val * data.tag?.charges) / 100,
                actual,
                cashback: priced
            })
        } else {
            setSingle({
                ...single,
                status: false,
                amount: '',
                amountToPay: "",
            })
        }
    }
    return (
        <UserLayout title="Airtime 2 Cash">
            {!agree && <div className="fixed top-0 left-0 z-10 flex items-center justify-center w-full h-screen bg-black/60">
                <div className="w-11/12 max-w-lg p-5 bg-white rounded-xl">
                    <div className="mb-3 text-sm font-light">
                        <span className="font-bold text-red-600">NOTE:</span> You are responsible for any Airtime you sell to us. By selling to us, you can confirm that it rightfully belongs to you and that it was gotten through a legitimate source.
                    </div>

                    <div className="mb-3 text-sm font-light">Tranzit-Tech will not be held responsible for any fraudulent airtime being sold to her.</div>

                    <div className="mb-3 text-sm font-light">In a situation where fraudulent cases arise as a result of the airtime you sold to us, we would be compelled to give out your transaction details to the bodies involved for necessary investigation.</div>

                    <div className="mb-3 text-sm font-light"> In a situation where a client allows his transaction to pass through another client before getting to us, Tranzit-Tech wishes to state it categorically that the last person that had the direct contact with us will bear the full responsibility of the airtime he or she sent.</div>

                    <div className="mb-3 text-sm font-light">If by any means the airtime involved is faulted/fraudulent, we might be obligated not to give out the value of the airtime to your wallet.</div>
                    <div className="mt-5">
                        <button onClick={() => setAgree(true)} className="w-full px-4 py-3 text-sm text-white capitalize rounded-lg bg-main">proceed</button>
                    </div>
                </div>
            </div>}
            <div className="w-11/12 mx-auto">
                {single.status && <ConfirmationModal
                    loading={loader}
                    title={single.title}
                    handleSubmission={submitRequest}
                    closeView={() => setSingle({ ...single, status: false })}
                />}
                <div className="p-3 bg-white">
                    <form onSubmit={handleSubmission}>
                        <div className="relative mb-4">
                            <div className="">Select Service</div>
                            <Forminput value={forms.Service} formName={'Service'} onChange={handleForms} formtype='select'>
                                {data.tag?.plans?.map((ele, index) => (
                                    <option disabled={ele.aval === 'true' ? false : true} value={ele.serviceid} key={index}>{ele.servicetype}</option>
                                ))}
                            </Forminput>
                        </div>
                        <div className="mb-4">
                            <div className="">Phone Number to transfer from</div>
                            <Forminput formName={'Number'} value={forms.Number} onChange={handleNumber} />
                        </div>
                        <div className="mb-2">
                            <div className="">Amount ({NairaSign})</div>
                            <Forminput onKeyup={e => handlePriceFee(e.target.value)} label={'0.00'} formName={'Amount'} value={forms.Amount} onChange={handleForms} />
                        </div>
                        <Formbutton title="proceed" loading={loading} />
                    </form>
                </div>
            </div>
        </UserLayout>
    )
}

export default A2C
