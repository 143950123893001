import React from 'react'

const SinglePerson = ({ data, selected, handleSelection }) => {
    const workingHard = () => {
        handleSelection(data.id)
    }
    return (
        <div>
            <div className="grid grid-cols-2 p-2">
                <div className="">
                    <div className="text-sm">{data.firstname} {data.lastname}</div>
                    <div className="text-sm">{data.email}</div>
                </div>
                <div onClick={workingHard} className={`w-5 h-5 rounded-lg ${selected.includes(data.id) ? 'bg-blue-700' : 'bg-slate-200'} ml-auto cursor-pointer`}></div>
            </div>
        </div>
    )
}

export default SinglePerson