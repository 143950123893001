import React, { useState } from 'react'
import SideViewModalLayout from 'components/utils/SideViewModalLayout'
import Formbutton from 'components/utils/Formbutton'
import { ErrorAlert, Errors, SuccessAlert } from 'components/utils/functions'
import { Apis, Authposturl, Authputurl } from 'components/Apis'
import Forminput from 'components/utils/Forminput'

const ManageCategories = ({ closeView, refetch, item }) => {
    const [loading, setLoading] = useState(false)
    const [view, setView] = useState(1)
    const [title, setTitle] = useState(item?.title || '')

    const SubmitForm = async (e) => {
        e.preventDefault()
        if (!title) return ErrorAlert('category title is required')
        const forms = {
            title,
            id: item?.id
        }
        setLoading(true)
        try {
            const response = item?.title ? await Authputurl(Apis.feeds.update_category, forms) : await Authposturl(Apis.feeds.create_category, forms)
            if (response.status === 200) {
                SuccessAlert(response.msg)
                return refetch()
            } else {
                ErrorAlert(`${response.msg}`)
            }
        } catch (e) {
            Errors(e)
        } finally {
            setLoading(false)
        }
    }
    const SubmitDeleteRequest = async (e) => {
        e.preventDefault()
        const forms = {
            id: item?.id
        }
        setLoading(true)
        try {
            const response = await Authposturl(Apis.feeds.delete_category, forms)
            if (response.status === 200) {
                SuccessAlert(response.msg)
                return refetch()
            } else {
                ErrorAlert(`${response.msg}`)
            }
        } catch (e) {
            Errors(e)
        } finally {
            setLoading(false)
        }
    }
    return (
        <SideViewModalLayout closeView={closeView}>
            {view === 1 && <div className="p-4 mt-10">
                <div className="font-bold text-xl mb-5">{item?.id ? 'Update Category' : 'Add New Category'}
                  {item?.id &&  <button
                        onClick={() => setView(2)}
                        className="bg-red-500 ml-3 text-white py-2 px-5 rounded-md text-sm capitalize">delete</button>}
                </div>
                <form>
                    <div className="mb-4">
                        <Forminput
                            formName={'title'}
                            label={'Title'}
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                        />
                    </div>
                    <Formbutton
                        handleSubmission={SubmitForm}
                        loading={loading}
                        title="Submit"
                    />
                </form>
            </div>}
            {view === 2 && <div className="p-4 mt-10">
                <div className="font-bold text-xl mb-5">Delete <span className="text-red-400">{item.title}</span> </div>
                <div className="text-center mb-10">Are you sure you want to delete this category?</div>
                <form>
                    <Formbutton
                        handleSubmission={SubmitDeleteRequest}
                        loading={loading}
                        title="proceed"
                    />
                </form>
            </div>}
        </SideViewModalLayout>
    )
}

export default ManageCategories
