
import React, { useState } from 'react'
import UserLayout from 'components/user/UserLayout'
import Forminput from 'components/utils/Forminput'
import Formbutton from 'components/utils/Formbutton'
import { Apis, Authgeturl, Authposturl } from 'components/Apis'
import { ErrorAlert, NairaSign, Swals } from 'components/utils/functions'
import { dispatchUser } from 'app/reducer'
import { useDispatch, useSelector } from 'react-redux'
import ServiceConfirmation from './ServiceConfirmation'
import { useQuery } from '@tanstack/react-query'
import Spinner from 'components/utils/Spinner'
import { ServiceImages } from 'components/utils/PageUtils'
import SuccessModal from 'components/utils/SuccessModal'
import { usePDF } from 'react-to-pdf'


export default function Data() {
    const [loading, setLoading] = useState(false)
    const [loader, setLoader] = useState(false)
    const [open, setOpen] = useState({ status: false, data: {} })
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.data)
    const [plans, setPlans] = useState([])
    const [forms, setForms] = useState({
        network: '',
        Action: "DATA",
        Service: "",
        Serviceid: '',
        Plan: "",
        Number: "",
        actual: '',
    })
    const [single, setSingle] = useState({
        status: false,
        cashback: 0,
        amount: 0,
        paymethod: '',
        service: '',
        data: {},
        product: '',
        amountToPay: 0,
    })

    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }

    const date = new Date()

    const { toPDF, targetRef } = usePDF({
        filename: `${date.getFullYear()}_${date.getTime()}.pdf`,
        canvas: {
            useCORS: true,
        },
    });



    const handleSubmission = async e => {
        e.preventDefault()
        if (forms.actual > user.account?.balance) return ErrorAlert('Insufficient balance to proceed')
        if (!forms.Serviceid) return ErrorAlert('Select your preferred network')
        if (!single.Plan) return ErrorAlert('Select a Bundle to purchase')
        if (!forms.Number) return ErrorAlert('Provide a valid phone number')


        const dataToStore = {
            plan_name: single.plan_name,
            amountToPay: single.amountToPay,
            product: single.product,
            network: forms.network,
            cashback: single.cashback,
            amount: single.amount,
            Serviceid: forms.Serviceid,
            Plan: single.Plan,
            Number: forms.Number,
            status: true,
        }
        setSingle(dataToStore)
    }

    const PurchaseDataBundle = async (value) => {
        setLoader(true)
        try {
            const result = await Authposturl(Apis.ncwallet.purchase_data, value)
            if (result.status !== 200) return Swals('Request Failed', `${result.msg}`, 'error')

            setSingle({
                ...single,
                status: false,
                Plan: ""
            })
            setOpen({
                status: true,
                data: result
            })
            dispatch(dispatchUser(result.user))
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoader(false)
        }
    }



    const { data, isLoading } = useQuery({
        queryKey: [`all-data-services-for-users-ncwallet`],
        queryFn: async () => {
            const response = await Authgeturl(`${Apis.ncwallet.all_services}?name=DATA`)
            return response.data[0]
        }
    })

    const handleService = (val) => {
        if (val) {
            const itemService = data.tags.find(ele => ele.id === val)

            setForms({
                ...forms,
                network: itemService.servicename,
                Serviceid: itemService.id,
            })
            setSingle({
                ...single,
                Plan: ""
            })
            setLoading(true)
            setTimeout(() => {
                setPlans(itemService.plans)
                setLoading(false)
            }, 500);

        } else {
            setForms({
                ...forms,
                network: '',
                Serviceid: '',
            })
        }
    }

    const handlePlans = e => {
        const val = e
        if (val) {
            const item = data.tags.find(ele => ele.id === forms.Serviceid).plans.find(pl => pl.id === val)
            setSingle({
                ...single,
                amount: item.amount,
                amountToPay: item.sell,
                actual: item.amount,
                Plan: item.id,
                cashback: item.cashback,
                plan_name: item.planname
            })
        } else {
            setSingle({
                ...single,
                status: false,
                amount: '',
                Plan: '',
                amountToPay: "",
            })
        }
    }

    if (isLoading) return (
        <UserLayout title="Data Purchase">
            <div className="w-11/12 mx-auto mt-10">
                <div className="p-3 bg-white">
                    <div className="">
                        {new Array(5).fill(0).map((ele, index) => (
                            <div className="bg-slate-200 h-16 w-full animate-pulse mb-3" key={index}></div>
                        ))}
                    </div>
                    <div className="mt-10">
                        <div className="">
                            <div className="mt-5 mb-2 text-xl font-bold">Check Data Balance</div>
                            <div className='mb-1'>Note: MTN SME = *461*4#</div>
                            <div className='mb-1'>Note: MTN SME2 = *323*4#</div>
                            <div className='mb-1'>Note: MTN CG = *460*260#</div>
                            <div className='mb-1'>Note: Glo = *323#</div>
                            <div className='mb-1'>Note: Airtel = *323#</div>
                            <div className='mb-1'>Note: 9mobile = *228#</div>
                        </div>
                    </div>
                </div>
            </div>
        </UserLayout>
    )

    return (
        <UserLayout title="Data Purchase">

            {open.status && <SuccessModal
                closeview={() => setOpen({ status: false, data: {} })}
                title={'Data Purchased Successfully'}
                content2={open.data.msg}
                content={open.data.msg}
                cashback={`${single.cashback}MB`}
                DownloadPDF={() => toPDF()}
                Dismiss={'/service_transactions'}
            />}
            {single.status && <ServiceConfirmation
                loading={loader}
                productTag="Data"
                single={single}
                setSingle={setSingle}
                user={user}
                PurchaseService={PurchaseDataBundle}
                closeView={() => setSingle({ ...single, status: false })}
            />}
            <div ref={targetRef}>
                {open.status ? <div>
                    <div className="grid grid-cols-2 gap-4 py-2 mt-5">
                        <div className="text-sm">Designated Number</div>
                        <div className="text-sm text-right">{single.Number}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 py-2">
                        <div className="text-sm">Network</div>
                        <div className="text-sm text-right uppercase">{single.plan_name} {single.network}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 py-2">
                        <div className="text-sm">Amount Paid</div>
                        <div className="text-sm text-right uppercase">{NairaSign}{single.amountToPay?.toLocaleString()}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 py-2">
                        <div className="text-sm">Credit Type</div>
                        <div className="text-sm text-right uppercase">data purchse</div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 py-2">
                        <div className="text-sm">to Purchase</div>
                        <div className="text-sm text-right uppercase">data [{single.plan_name}]</div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 py-2">
                        <div className="text-sm">Transaction Date</div>
                        <div className="text-sm text-right uppercase">{open.data?.tag?.datetime}</div>
                    </div>
                </div> : ''}
            </div>
            <div className="w-11/12 mx-auto mt-10">
                <div className="p-3 bg-white">
                    <form onSubmit={handleSubmission}>
                        <div className="relative mb-4">
                            <div className="">Select Service</div>
                            <div className="grid grid-cols-4">
                                {data?.tags?.map((item, index) => {
                                    const image = ServiceImages.find(ele => ele.network?.toLowerCase() === item.servicename?.toLowerCase())
                                    return (
                                        <div
                                            onClick={() => handleService(item.id)}
                                            className={`flex flex-col ${forms.network === item.servicename ? 'bg-orange-100 font-bold' : 'bg-slate-100'} w-11/12 mx-auto items-center justify-center gap-2 px-3 py-2 rounded-md cursor-pointer`} key={index}>
                                            <img src={image.image} alt="" className="size-10" />
                                            <div className="">{item?.servicename}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        {loading && <Spinner />}
                        {forms.network && <div className="relative mb-4">
                            <div className="">Select Bundle</div>
                            <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-3">
                                {plans.map((ele, index) => (
                                    <div
                                        onClick={() => handlePlans(ele.id)}
                                        key={index} className="flex items-center flex-col cursor-pointer scale-100 hover:scale-105 transition-all">
                                        <div className={`${single.Plan === ele.id ? 'bg-sub text-white' : 'bg-zinc-300 text-zinc-600'} w-10/12 text-center py-1.5 px-3 z-10 isolate rounded-tr-lg font-semibold rounded-tl-lg text-sm md:text-base capitalize`}>{ele.planname}</div>
                                        <div className={`font-bold w-full px-4 py-1 text-center ${single.Plan === ele.id ? 'bg-amber-300' : 'bg-zinc-400'} card-label text-xs`}>{ele.planname} + {ele.cashback}MB</div>
                                        <div className={`${single.Plan === ele.id ? 'bg-sub text-white' : 'bg-zinc-300 text-zinc-600'} w-10/12 text-center py-1.5 px-3 z-10 isolate rounded-br-lg rounded-bl-lg text-sm md:text-base`}>{NairaSign}{ele.sell?.toLocaleString()}</div>
                                    </div>
                                ))}
                            </div>
                        </div>}
                        <div className="mb-4">
                            <div className="">Phone Number</div>
                            <Forminput type="number" formName={'Number'} value={forms.Number} onChange={handleForms} />
                        </div>
                        <Formbutton title="purchase data" />
                    </form>
                    <div className="mt-10">
                        <div className="">
                            <div className="mt-5 mb-2 text-xl font-bold">Check Data Balance</div>
                            <div className='mb-1'>Note: MTN SME = *461*4#</div>
                            <div className='mb-1'>Note: MTN SME2 = *323*4#</div>
                            <div className='mb-1'>Note: MTN CG = *460*260#</div>
                            <div className='mb-1'>Note: Glo = *323#</div>
                            <div className='mb-1'>Note: Airtel = *323#</div>
                            <div className='mb-1'>Note: 9mobile = *228#</div>
                        </div>
                    </div>
                </div>
            </div>
        </UserLayout>
    )
}
