import React from 'react'
import { Route, Routes } from 'react-router-dom'
import UserPrivacy from './privates/UserPrivacy'
import AdminPrivacy from './privates/AdminPrivacy'
import SuperadminPrivacy from './privates/SuperadminPrivacy'
import PartnerPrivacy from './privates/PartnerPrivacy'
import { AdminPages, FormPages, GeneralPages, PartnersPages, SuperAdminPages, UserPages } from './routes'
import { Toaster } from 'react-hot-toast'




const superAdmin = '/superadmin/'
const admin = '/admin/'


const App = () => {
  
  return (
    <>
      <Routes>
        {/* partners routes */}
        {GeneralPages.map((item, index) => (
          <Route key={index} path={item.path} element={<item.component />} />
        ))}

        {/* partners routes */}
        {FormPages.map((item, index) => (
          <Route key={index} path={item.path} element={<item.component />} />
        ))}

        {/* partners routes */}
        {PartnersPages.map((item, index) => (
          <Route key={index} path={item.path} element={<PartnerPrivacy> <item.component /> </PartnerPrivacy>} />
        ))}

        {/* user routes */}
        {UserPages.map((item, index) => (
          <Route key={index} path={item.path} element={<UserPrivacy> <item.component /> </UserPrivacy>} />
        ))}

        {/* admin routes */}
        {AdminPages.map((item, index) => (
          <Route key={index} path={admin + item.path} element={<AdminPrivacy> <item.component /> </AdminPrivacy>} />
        ))}

        {/* =======================  super admin ===================== */}
        {SuperAdminPages.map((item, index) => (
          <Route key={index} path={superAdmin + item.path} element={<SuperadminPrivacy> <item.component /> </SuperadminPrivacy>} />
        ))}

      </Routes>
      <Toaster />
    </>
  )
}

export default App