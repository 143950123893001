import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { SlClock } from 'react-icons/sl'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DurationTypes } from 'components/utils/functions';

export default function StockSellout({cs1, activeplan}) {
    const [num, setNum] = useState("00:00:00");
    const { id } = useParams();
    const dispatch = useDispatch()
    const [loads, setLoads] = useState(true)
    const queryClient = useQueryClient();
    const {plan} = useSelector(state => state.data)



    useEffect(() => {
        let interval;

        interval = setInterval(async () => {
            // Get current time
            const currentTime = moment();

            // Calculate the difference between the given moment time and the current time
            const diff = moment(new Date(activeplan?.sellDuration)).diff(currentTime);

            if (diff <= 0) {
                if(loads) {
                    setLoads(false)
                    if (loads) {
                        queryClient.refetchQueries({queryKey: [`invest-layout-${id}`]}, {force: true});
                        setTimeout(() => {
                            queryClient.refetchQueries({queryKey: [`invest-layout-${id}`]}, {force: true});
                        }, 500);
                    }
                }
                clearInterval(interval);
                setNum("00:00:00");
                return;
            }

            // Convert the difference to a countdown format
            const duration = moment.duration(diff);
            // const years = Math.floor(duration.asYears());
            // const months = Math.floor(duration.asMonths());
            // const days = Math.floor(duration.asDays());
            // const hours = Math.floor(duration.asHours());
            // const minutes = duration.minutes();
            // const seconds = duration.seconds();
            const years = duration.years();
            const months = duration.months();
            const days = duration.days();
            const hours = duration.hours();
            const minutes = duration.minutes();
            const seconds = duration.seconds();

            // Format hours, minutes, and seconds to ensure they have two digits
            const formattedYears = years < 10 ? "0" + years : years;
            const formattedMonths = months < 10 ? "0" + months : months;
            const formattedDays = days < 10 ? "0" + days : days;
            const formattedHours = hours < 10 ? "0" + hours : hours;
            const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
            const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

            // Return the countdown time in the specified format
            let fullTimed;
            if (plan.plans?.sellDurationType === DurationTypes.years) { fullTimed = `${formattedYears}y:${formattedMonths}m:${formattedDays}d:${formattedHours}h:${formattedMinutes}m:${formattedSeconds}s` }
            if (plan.plans?.sellDurationType === DurationTypes.months) { fullTimed = `${formattedMonths}m:${formattedDays}d:${formattedHours}h:${formattedMinutes}m:${formattedSeconds}s` }
            if (plan.plans?.sellDurationType === DurationTypes.days) { fullTimed = `${formattedDays}d:${formattedHours}h:${formattedMinutes}m:${formattedSeconds}s` }
            if ([DurationTypes.hours, DurationTypes.minutes].includes(plan.plans?.sellDurationType)) { fullTimed = `${formattedHours}h:${formattedMinutes}m:${formattedSeconds}s` }

            setNum(fullTimed);
            setLoads(true)
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [dispatch, id, activeplan, loads]);
    
    return (
        <>
            <div className={`${cs1} text-sub`}>{activeplan?.status}</div>
            <div className="text-sm flex items-center gap-1 text-white"> <SlClock />  ends: {num}</div>
        </>
    )
}
