import React, { useEffect, useState } from 'react'
import UserLayout from 'components/user/UserLayout'
import { useParams } from 'react-router-dom'
import { Apis, Authgeturl, imgurl } from 'components/Apis'
import { Errors, NairaSign } from 'components/utils/functions'
import LoadImage from 'components/utils/LoadImage'
import { FaMapMarkerAlt } from 'react-icons/fa'
import moment from 'moment'
import { useQuery } from '@tanstack/react-query'

const MyPickupPage = () => {
    const [item, setItem] = useState({})
    const [loading, setLoading] = useState(true)
    const [activeimg, setActiveImage] = useState('')
    const [itemData, setItemData] = useState([])
    const { id } = useParams()

    const { data, isLoading } = useQuery({
        queryKey: [`pickup-order-user-item-${id}`],
        queryFn: async () => {
            const response = await Authgeturl(`${Apis.feeds.pickup_order}/${id}`)
            if (response.status === 200) {
                const data = response.msg
                const finals = data.detail?.media[0]?.image
                setActiveImage(finals)

                const levs = data.detail?.levels.find(ele => ele.id === data.pickuplevel)
                setItemData([
                    {
                        title: `check in`,
                        content: `${data.checkin} ${data.checkinTime}`
                    },
                    {
                        title: `check out`,
                        content: `${data.checkout} ${data.checkoutTime}`
                    },
                    {
                        title: `tracking ID`,
                        content: `${data.trackid}`
                    },
                    {
                        title: `order status`,
                        content: `${data.status}`
                    },
                    {
                        title: `contact line`,
                        content: `${data.phone}`
                    },
                    {
                        title: `shortlet at`,
                        content: `${NairaSign}${data?.detail?.price?.toLocaleString()} ${data.detail?.pricetag}`
                    },
                    {
                        title: `ordered at`,
                        content: `${NairaSign}${data.pricing?.toLocaleString()}`
                    },
                    {
                        title: `discount at`,
                        content: `${data.detail?.discount}%`
                    },
                    {
                        title: `Need a driver`,
                        content: `${data.ispickup === 'yes' ? 'Yes' : 'No'}`
                    },
                    {
                        title: data.pickuplevel ? `Pickup preference` : null,
                        content: data.pickuplevel ?  `${levs.title} (${NairaSign}${levs.price?.toLocaleString()})` : null
                    },
                    {
                        title: data.pickuplevel ? ` ` : null,
                        content: data.pickuplevel ?  `${levs.content}` : null
                    },
                    {
                        title: `address`,
                        content: `${data?.address}`
                    },
                    {
                        title: `initiated order on`,
                        content: `${moment(data.createdAt).format('Do MMMM YYYY h:mma')}`
                    },
                ])
                return response.msg
            }
        }
    })

    if (isLoading) {
        return (
            <div>
                <div className="bg-slate-200 h-[16rem]"></div>
                <div className="w-full overflow-x-auto mt-3">
                    <div className="w-fit flex gap-3 items-center justify-center mx-auto">
                        {new Array(5).fill(0).map((item, i) => (
                            <div className="h-[10rem] w-[10rem] bg-slate-200 animate-pulse" key={i}></div>
                        ))}
                    </div>
                </div>
                <div className="bg-slate-200 h-10 w-3/4 mt-3"></div>
                <div className="bg-slate-200 h-10 mt-3"></div>
            </div>
        )
    }

    const handleClicking = (data) => {
        setActiveImage(data)
    }

    return (
        <UserLayout title={`${data.detail?.title?.slice(0, 20)}...`}>
            <div className="w-11/12 mx-auto">
                <div className='grid grid-cols-1 lg:grid-cols-8 gap-10'>
                    <div className="h-fit lg:col-span-3">
                        <div className="">
                            <div className="font-bold text-xl mb-3 bg-main text-white p-3">Order Info.</div>
                            <div className="border bg-white mt-3">
                                {itemData.length > 0 && itemData?.map((ele, index) => (
                                   ele.title && <div key={index} className="grid grid-cols-2 p-2 border-b last:border-none">
                                        <div className="text-sm font-bold capitalize">{ele.title}</div>
                                        <div className="text-sm text-right">{ele.content}</div>
                                    </div>
                                ))}
                            </div>
                            {/* <div className="bg-white p-4 shadow-xl">
                                <div className="grid grid-cols-2 p-1">
                                    <div className="text-sm font-bold">Check in</div>
                                    <div className="text-sm text-right">{data.checkin} {data.checkinTime}</div>
                                </div>
                                <div className="grid grid-cols-2 p-1">
                                    <div className="text-sm font-bold">Check out</div>
                                    <div className="text-sm text-right">{data.checkout} {data.checkoutTime}</div>
                                </div>
                                <div className="grid grid-cols-2 p-1">
                                    <div className="text-sm font-bold">Tracking ID</div>
                                    <div className="text-sm text-right break-words">{data.trackid}</div>
                                </div>
                                <div className="grid grid-cols-2 p-1">
                                    <div className="text-sm font-bold">Order Status</div>
                                    <div className="text-sm text-right">{data.status}</div>
                                </div>
                                {data.phone && <div className="grid grid-cols-2 p-1">
                                    <div className="text-sm font-bold">Contact Line</div>
                                    <div className="text-sm text-right">{data.phone}</div>
                                </div>}
                                <div className="grid grid-cols-2 p-1">
                                    <div className="text-sm font-bold">Shortlet at</div>
                                    <div className="text-sm text-right">{NairaSign}{item?.detail?.price?.toLocaleString()} {data.detail?.pricetag}</div>
                                </div>
                                <div className="grid grid-cols-2 p-1">
                                    <div className="text-sm font-bold">Ordered at</div>
                                    <div className="text-sm text-right">{NairaSign}{data.pricing?.toLocaleString()}</div>
                                </div>
                                <div className="grid grid-cols-2 p-1">
                                    <div className="text-sm font-bold">Discount at</div>
                                    <div className="text-sm text-right">{item?.detail?.discount}%</div>
                                </div>
                                {data.address && <>
                                    <div className="grid grid-cols-2 p-1">
                                        <div className="text-sm font-bold">Pick up Fee</div>
                                        <div className="text-sm text-right">{NairaSign}{item?.detail?.pickupfee?.toLocaleString()}</div>
                                    </div>
                                    <div className="grid grid-cols-2 p-1">
                                        <div className="text-sm font-bold">Address</div>
                                        <div className="text-sm text-right">{item?.address}</div>
                                    </div>
                                </>}
                                <div className="grid grid-cols-2 p-1">
                                    <div className="text-sm font-bold">Initiated Order on</div>
                                    <div className="text-sm text-right">{moment(data.createdAt).format('Do MMMM YYYY h:mma')}</div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="lg:col-span-5">
                        <div className="relative">
                            <LoadImage src={`${activeimg}`} width={'full'} height={'20rem'} className="w-full h-[30rem] object-cover" />
                            <div className="absolute top-2 rounded-md right-3 text-xs bg-sub/80 text-white backdrop-blur-md py-2 px-3 capitalize">{data.aval === 'yes' ? 'available' : 'not available'}</div>
                        </div>
                        <div className="w-full overflow-x-auto mt-3 scrollsdown">
                            <div className="w-fit flex gap-3 items-center justify-center mx-auto">
                                {data.detail?.media?.length > 0 && data.detail?.media?.map((ele, index) => (
                                    <div className="w-28 h-28" key={index}>
                                        <LoadImage src={ele.image} width={'100%'} height={'7rem'} className="w-28 h-28 object-cover" handleActive={handleClicking} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="font-bold text-2xl mt-3">{data.detail?.title}</div>
                        <div className="flex items-center gap-2 text-slate-500 mb-5">
                            <div className="">Posted: {moment(data.detail?.createdAt).fromNow()}</div>,
                            <div className="flex items-center gap-2"> <FaMapMarkerAlt /> {data.detail?.country}, {data.detail?.state} </div>
                        </div>
                        <div className='whitespace-pre-wrap break-words' dangerouslySetInnerHTML={{ __html: data.detail?.description }} />

                    </div>
                </div>
            </div>
        </UserLayout>
    )
}

export default MyPickupPage