import React from 'react'
import ALayout from 'components/admin/ALayout'
import PlanForm from './PlanForm'

const CreatePlan = () => {
  return (
    <ALayout>
        <PlanForm />
    </ALayout>
  )
}

export default CreatePlan