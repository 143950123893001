import { useState } from "react";
import { ErrorAlert, NairaSign } from "components/utils/functions";
import SharesAssetModal from "./SharesAssetModal";
import { Apis, Authgeturl } from "components/Apis";
import moment from "moment";
import ALayout from "components/admin/ALayout";
import { useQuery } from "@tanstack/react-query";
import Spinner from "components/utils/Spinner";


const Tableheaders = [
    "S/N",
    "content",
]


export default function ManageShareAsset() {
    const [view, setView] = useState({
        status: false,
        data: {}
    })

    const { data, isLoading, refetch } = useQuery({
        queryKey: ['shares-on-super-admin'],
        queryFn: async () => {
            try {
                const response = await Authgeturl(Apis.shares.all_share_asset)
                if (response.status === 200) return response.msg
                ErrorAlert(`${response.msg}`)
            } catch (error) {
                ErrorAlert(`${error.message}`)
            }
        }
    })
    if (isLoading) return (
        <ALayout>
            <div className="flex flex-col items-center justify-center gap-3">
                <Spinner />
                <div className="animate-pulse">Loading Data...</div>
            </div>
        </ALayout>
    )
    const item = data

    if (!data?.title) return (
        <ALayout>
        {view.status && <SharesAssetModal
            refetchData={() => refetch()}
            pagedata={view.data}
            closeView={() => setView({ ...view, status: false })}
        />}
        <div className="mb-5 text-xl text-center font-bold">Manage Direct Quick Asset</div>
            <div className="mx-auto mt-10 w-fit">
                <button
                    onClick={() => setView({ status: true, data: {} })}
                    className="px-5 py-3 text-sm text-white capitalize rounded-lg bg-main">create asset</button>
            </div>
        </ALayout>
    )
    return (
        <ALayout>
            {view.status && <SharesAssetModal
                refetchData={() => refetch()}
                pagedata={view.data}
                closeView={() => setView({ ...view, status: false })}
            />}
            <div className="mb-5 text-xl text-center font-bold">Manage Quick direct Asset</div>
            {Object.keys(item).length < 1 &&
                <div className="mx-auto mt-10 w-fit">
                    <button
                        onClick={() => setView({ status: true, data: {} })}
                        className="px-5 py-3 text-sm text-white capitalize rounded-lg bg-main">create asset</button>
                </div>}
            {Object.keys(item).length < 1 && <div className="mt-10 text-center text-slate-500">You have not added any asset to manage yet</div>}

            <div>
                <div className="bg-white p-4">
                    <div className="grid grid-cols-2 gap-3 p-3 border-b">
                        <div className="font-light">Asset</div>
                        <div className="font-bold text-right capitalize">{item.title} <span className="uppercase">({item.shorts})</span> </div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 p-3 border-b">
                        <div className="font-light">Base Pricing for each shares</div>
                        <div className="font-bold text-right">{NairaSign}{item.pricing}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 p-3 border-b">
                        <div className="font-light">Total Shares in Treasury</div>
                        <div className="font-bold text-right">{item.total?.toLocaleString()} ({item.totalText})</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 p-3 border-b">
                        <div className="font-light">Authorized Shares to Sell Out</div>
                        <div className="font-bold text-right">{item.authorized?.toLocaleString()} ({item.authorizedText})</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 p-3 border-b">
                        <div className="font-light">Shares Sold Out</div>
                        <div className="font-bold text-right">{item.sold?.toLocaleString()}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 p-3 border-b">
                        <div className="font-light">Available Shares to Sell</div>
                        <div className="font-bold text-right">{(item.authorized - item.sold)?.toLocaleString()}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 p-3 border-b">
                        <div className="font-light">Min. Shares purchase</div>
                        <div className="font-bold text-right">{item.min}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 p-3 border-b">
                        <div className="font-light">Max. Shares purchase</div>
                        <div className="font-bold text-right">{item.max}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 p-3 border-b">
                        <div className="font-light">Date Created</div>
                        <div className="font-bold text-right">{moment(item.createdAt).format('DD/MM/YYYY h:mm:sA')}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 p-3">
                        <div className="font-light">Date Last Updated</div>
                        <div className="font-bold text-right">{moment(item.updatedAt).format('DD/MM/YYYY h:mm:sA')}</div>
                    </div>
                    <div className="mt-10 ml-auto w-fit">
                        <button
                            onClick={() => setView({ status: true, data: item })}
                            className="px-5 py-3 text-sm text-white capitalize rounded-lg bg-main">manage</button>
                    </div>
                </div>
                <table className='table table-auto w-full bg-white p-3 text-sm'>
                    <thead>
                        <tr className='bg-slate-700 text-white'>
                            {Tableheaders.map((ele, i) => (
                                <td className='capitalize p-3' key={i}>{ele}</td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {item.sharecontents?.map((ele, i) => (
                            <tr
                                key={i}
                                className='cursor-pointer border-b border-r border-l'
                            >
                                <td className='p-3'>{i + 1}</td>
                                <td className='p-3'>{ele.content}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </ALayout>
    )
}