import React, { useState } from 'react'
import SideViewModalLayout from 'components/utils/SideViewModalLayout'
import { Apis, Authputurl, imgurl } from 'components/Apis'
import { ErrorAlert, NairaSign, SuccessAlert } from 'components/utils/functions'
import Forminput from 'components/utils/Forminput'
import Formbutton from 'components/utils/Formbutton'
import Imaged from 'components/utils/Imaged'


const StatusOptions = [
    'processing',
    'completed',
]

const PickupModal = ({ closeView, item, refetch }) => {
    const [screen, setScreen] = useState(1)
    const [forms, setForms] = useState({
        status: item?.status || '',
    })
    const [loading, setLoading] = useState(false)

    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }

    const handleUpdating = async e => {
        e.preventDefault()
        if(!forms.status) return ErrorAlert(`Select a valid status`)

        setLoading(true)
        try {
            const formdata = {
                ...forms,
                id: item.id,
            }
            const response = await Authputurl(Apis.feeds.update_pickup_requests, formdata)
            if (response.status === 200) {
                SuccessAlert(`${response.msg}`)
                refetch()
            } else {
                return ErrorAlert(`${response.msg}`)
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoading(false)
        }
    }
    return (
        <SideViewModalLayout closeView={closeView}>
            {screen === 2 && <div className="p-3 mt-10">
                <form onSubmit={handleUpdating}>
                    <div className="border-b py-3 mb-5 mt-5">Kindly confirm your request to update this pickup status!.</div>
                    <div className="mb-4">
                        <Forminput formtype='select' value={forms.status} onChange={handleForms} formName={'status'}>
                            {StatusOptions.map((ele, index) => (
                                <option value={ele} key={index}>{ele}</option>
                            ))}
                        </Forminput>
                    </div>
                <div className="w-fit mt-5 ml-auto">
                    <Formbutton title="update" loading={loading} />
                </div>
                </form>
            </div>}
            {screen === 1 && <div className="p-3 mt-10">
                <div className="my-5 w-fit ml-auto">
                    <button
                        onClick={() => setScreen(2)}
                        className="bg-main text-white py-3 px-5 rounded-lg text-sm capitalize">update</button>
                </div>
                <div className="bg-slate-200 mb-5">
                    <div className="font-bold text-xl p-3 border-b border-black">Pick up Details</div>
                    <div className="p-2">
                        <div className="text-sm">Tracking ID</div>
                        <div className="font-bold uppercase">{item.trackid}</div>
                    </div>
                    <div className="p-2">
                        <div className="text-sm">Driver Needed</div>
                        <div className="font-bold uppercase">{item.ispickup}</div>
                    </div>
                    <div className="p-2">
                        <div className="text-sm">Check in Date</div>
                        <div className="font-bold">{item.checkin} {item.checkinTime}</div>
                    </div>
                    <div className="p-2">
                        <div className="text-sm">Check out Date</div>
                        <div className="font-bold">{item.checkout} {item.checkoutTime}</div>
                    </div>
                    <div className="p-2">
                        <div className="text-sm">Pick up Name</div>
                        <div className="font-bold">{item.firstname} {item.lastname}</div>
                    </div>
                    <div className="p-2">
                        <div className="text-sm">Pick up Email Address</div>
                        <div className="font-bold">{item.email}</div>
                    </div>
                    <div className="p-2">
                        <div className="text-sm">Pick up Contact</div>
                        <div className="font-bold">{item.phone}</div>
                    </div>
                    <div className="p-2">
                        <div className="text-sm">Pick up Address</div>
                        <div className="font-bold">{item.address}</div>
                    </div>
                    <div className="p-2">
                        <div className="text-sm">Comment</div>
                        <div className="font-bold">{item.comment || '--'}</div>
                    </div>
                </div>
                <div className="bg-slate-200 mb-5">
                    <div className="font-bold text-xl p-3 border-b border-black">Shortlet Details</div>
                    <div className="p-2 bg-slate-300">
                        <Imaged src={`${imgurl}/shortlets/${item.detail?.img1}`} alt="" className="w-full h-32 object-contain" />
                    </div>
                    <div className="p-2">
                        <div className="text-sm">Title</div>
                        <div className="font-bold">{item.detail?.title}</div>
                    </div>
                    <div className="p-2">
                        <div className="text-sm">Location</div>
                        <div className="font-bold capitalize">{item.detail?.country}, {item.detail?.state}</div>
                    </div>
                    <div className="p-2">
                        <div className="text-sm">Pricing</div>
                        <div className="font-bold capitalize"> {NairaSign}{parseFloat(item.detail?.price)?.toLocaleString()}, {item.detail?.pricetag}</div>
                    </div>
                    <div className="p-2">
                        <div className="text-sm">Discount</div>
                        <div className="font-bold">{item.detail?.discount}%</div>
                    </div>
                    <div className="p-2">
                        <div className="text-sm">Purchasing price at Enquiry</div>
                        <div className="font-bold">{NairaSign}{item.pricing?.toLocaleString()}</div>
                    </div>
                </div>
                <div className="bg-slate-200 mb-5">
                    <div className="font-bold text-xl p-3 border-b border-black">User Details</div>
                    <div className="p-2">
                        <div className="text-sm">User Name</div>
                        <div className="font-bold">{item.firstname} {item.lastname}</div>
                    </div>
                    <div className="p-2">
                        <div className="text-sm">User Email Address</div>
                        <div className="font-bold">{item.email}</div>
                    </div>
                    <div className="p-2">
                        <div className="text-sm">User Contact</div>
                        <div className="font-bold">{item.phone}</div>
                    </div>
                    <div className="p-2">
                        <div className="text-sm">Nationality</div>
                        <div className="font-bold">{item.country}, {item.state}</div>
                    </div>
                </div>
            </div>}
        </SideViewModalLayout>
    )
}

export default PickupModal
