

import React, { useState } from 'react'
import ModalLayout from 'components/utils/ModalLayout'
import { SlLock } from 'react-icons/sl'
import Forminput from 'components/utils/Forminput'
import Formbutton from 'components/utils/Formbutton'
import { ErrorAlert, Errors, NairaSign, SuccessAlert } from 'components/utils/functions'
import { Apis, Authposturl } from 'components/Apis'
import { useDispatch } from 'react-redux'
import { dispatchUser } from 'app/reducer'

export default function SellOffShares({ data, closeView }) {
    const [quantity, setQuantity] = useState('')
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [screen, setScreen] = useState(1)
    const SendRequest = e => {
        e.preventDefault()
        if (!quantity) return ErrorAlert('Provide the quantity of shares to sell')
        const qyt = parseInt(quantity)
        if (data.user?.sholder?.sellDuration === null) return ErrorAlert(`Cannot place a share order at the moment.`)
        if (qyt < data.user?.sholder?.minsell) return ErrorAlert(`Order failed, cannot sell below ${data.user?.sholder?.minsell} shares`)
        if (qyt > data.user?.sholder?.maxsell) return ErrorAlert(`Order failed, cannot sell above ${data.user?.sholder?.minsell} shares`)
        if (data.transfers.length > 0) {
            const findActive = data.transfers.find(ele => ele.status === 'active')
            if (findActive) return ErrorAlert('Cannot place a sell order, you still have an active sell request in progress')
        }
    setScreen(2)
    }
    const ConfirmRequest = async e => {
        e.preventDefault()
        setLoading(true)
        const formdata = {
            classid: data.user?.sholder?.id, 
            amount: quantity * data.asset?.pricing, 
            quantity
        }
        try {
            const response = await Authposturl(Apis.shares.sell_off_shares, formdata)
            if(response.status === 200) {
                SuccessAlert(`${response.msg}`)
                dispatch(dispatchUser(response.details))
                return closeView()
            }else {
                ErrorAlert(`${response.msg}`)
            }
        } catch (error) {
            Errors(`${error.message}`)
        }finally {
            setLoading(false)
        }
    }
    return (
        <ModalLayout closeView={closeView}>
            {data.user?.sholder?.sellDuration === null ? <div className="flex items-center justify-center gap-5 flex-col">
                <SlLock className='text-5xl mt-10 text-slate-500' />
                <div className="text-center font-semibold text-lg mb-5 text-slate-500">Cannot Place a sell order at the moment</div>
            </div> :
                <>
                   {screen === 1 && <div className="p-4">
                        <div className="font-bold text-2xl pb-2 border-b mb-2">Place a sell order</div>
                        <form onSubmit={SendRequest}>
                            <div className="">Quantity of Shares to sell</div>
                            <Forminput formName={'quantity'} value={quantity} type="number" onChange={e => setQuantity(e.target.value)} />
                            <div className="flex items-center justify-end">Available: {parseInt(data.user.totalshares)?.toLocaleString()}({data.asset?.shorts})</div>
                            <div className="mt-5">
                                <Formbutton title="Proceed" />
                            </div>
                        </form>
                    </div>}
                   {screen === 2 && <div className="p-4">
                        <div className="font-bold text-2xl pb-2 border-b mb-2">Confirm your sell order</div>
                        <form onSubmit={ConfirmRequest}>
                            <div className="text-center my-3">Kindly confirm you are about to sell your shares of <span className="fond-bold">{parseInt(quantity)?.toLocaleString()}({data.asset?.shorts})</span> at the value of {NairaSign}{parseInt(quantity * data.asset?.pricing)?.toLocaleString()}</div>
                            <div className="mt-5 flex items-center justify-between gap-20">
                                <button
                                type="button"
                                onClick={() => setScreen(1)}
                                className="bg-slate-300 py-3 px-5 rounded-md capitalize">cancel</button>
                                <Formbutton title="Confirm Request" loading={loading} />
                            </div>
                        </form>
                    </div>}
                </>
            }
        </ModalLayout>
    )
}

