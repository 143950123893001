
import React from 'react'
import { NairaSign } from 'components/utils/functions'
import { BsCheck } from 'react-icons/bs'
import moment from 'moment'
import { SlClock } from 'react-icons/sl'
import { GoArrowRight } from 'react-icons/go'

export default function SinglePrimeHistory({ item, handleSingle }) {
    return (
        <div className="border rounded-lg p-2 bg-white dark:bg-slate-600 mb-3 hover:scale-105 transition-all shadow-lg cursor-pointer" onClick={() => handleSingle(item)}>
            <div className="grid grid-cols-6">
                <div className="text-xs flex col-span-4 items-center gap-2 capitalize bg-slate-100 dark:bg-slate-800 w-fit p-2 rounded-md mb-3"> <SlClock />time left: {item.timed}</div>
                {moment().isAfter(item.endDate) ?
                    item.sold !== 'no' ?
                        <div className='col-span-2'>
                            <div className="text-green-600 dark:text-green-300 flex items-center gap-2 text-sm justify-end capitalize">concluded <BsCheck className='text-xl' /> </div>
                        </div> :
                        null :
                    ''}
                <div className="flex items-center gap-2 justify-end col-span-2 text-blue-600">View <GoArrowRight /> </div>
            </div>
            <div className="grid grid-cols-2 gap-2 p-1.5 text-sm">
                <div className="">Capital Invested</div>
                <div className="text-right">{NairaSign}{item.amount?.toLocaleString()}</div>
            </div>
            <div className="grid grid-cols-2 gap-2 p-1.5 text-sm">
                <div className="">Return on investment</div>
                <div className="text-right">{item.percent}%</div>
            </div>
            <div className="grid grid-cols-2 gap-2 p-1.5 text-sm">
                <div className="">Profit at maturity</div>
                <div className="text-right">{NairaSign}{item.returnProfit?.toLocaleString()}</div>
            </div>
            <div className="grid grid-cols-2 gap-2 p-1.5 text-sm">
                <div className="">Time of Purchase</div>
                <div className="text-right">{moment(item.createdAt).format('DD-MM-YYYY hh:mmA')}</div>
            </div>
        </div>
    )
}


