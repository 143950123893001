
import React, { useEffect } from 'react'
import UserLayout from 'components/user/UserLayout'
import { useQuery } from '@tanstack/react-query'
import { Apis, Authgeturl, Authposturl } from 'components/Apis'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ErrorAlert, Swals } from 'components/utils/functions'
import loads from 'assets/main-images/loads.gif'
import failed from 'assets/main-images/failed.gif'
import cards from 'assets/main-images/cards.gif'
import { dispatchUser } from 'app/reducer'
import { useDispatch } from 'react-redux'
import Imaged from 'components/utils/Imaged'

const ServiceTags = [
  "data",
  "airtime",
  "cable",
  "electricity"
]

export default function VerifyServicePay() {
  const [search, setSearch] = useSearchParams()
  const params = search.get('transRef')
  const status = search.get('status')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id, service } = useParams()
  useEffect(() => {
    if (!ServiceTags.includes(service)) return navigate('/dashboard')
  }, [])

  const { data, isLoading, isError, error } = useQuery({
    queryKey: [`verify-service-direct-funding`],
    queryFn: async () => {
      if (status !== 'PAID') return { status: 400, msg: `Unable to verify transaction` }
      const response = await Authgeturl(`${Apis.ercas.verify_account_deposit}/${params}`)
      if (response.status === 200) {
        const invoicebody = {
          invoiceid: parseInt(id)
        }
        let result;
        try {
          if (service === ServiceTags[0]) {
            result = await Authposturl(Apis.mobilenig.purchase_data, invoicebody)
          }
          if (service === ServiceTags[1]) {
            result = await Authposturl(Apis.mobilenig.purchase_airtime, invoicebody)
          }
          if (service === ServiceTags[2]) {
            result = await Authposturl(Apis.serviceapi.purchase_cable, invoicebody)
          }
          if (service === ServiceTags[3]) {
            result = await Authposturl(Apis.serviceapi.purchase_electric, invoicebody)
          }
          if (result.status === 200) {
            dispatch(dispatchUser(result.user))
            Swals('Successful purchase', result.msg, 'success')
            return navigate('/service_transactions')
          } if (result.status === 201) {
            dispatch(dispatchUser(result.user))
            Swals('Purchase Processing', result.msg, 'info')
            return navigate('/service_transactions')
          } else {
            Swals('Error Purchasing Airtime', `${result.msg}`, 'error')
          }
          // if (result.status === 200) {
          //   dispatch(dispatchUser(result.user))
          //   Swals('Successful purchase', result.msg, 'success')
          //    navigate('/service_transactions')
          // } else {
          //   navigate(`/${service}`)
          //   Swals('Error Purchasing Service', `${result.msg}`, 'error')
          // }
        } catch (error) {
          ErrorAlert(`${error.message}`)
        }
        return response.msg
      }
    }
  })
  if (data?.status === 400) {
    return (
      <UserLayout title="Verification failed">
        <div className="w-11/12 mx-auto py-10 foil lg:py-20 flex items-center justify-center flex-col gap-3">
          <div className="">
            <Imaged src={failed} alt="" className="w-auto h-[20rem]" />
          </div>
          <div className="font-bold text-2xl text-red-600">Verification failed</div>
          <div className="font-light text-center w-4/5">We are unable to complete your request, kindly try again later</div>
        </div>
      </UserLayout>
    )
  }
  if (isLoading) return <UserLayout title="Verifying Payment...">
    <div className="w-11/12 mx-auto py-10 foil lg:py-20 flex items-center justify-center flex-col gap-3">
      <div className="">
        <Imaged src={loads} alt="" className="w-auto h-[20rem]" />
      </div>
      <div className="font-bold text-2xl text-sub">Verification in progress...</div>
      <div className="font-light text-center w-4/5">Please wait while we verify your payment...</div>
    </div>
  </UserLayout>
  if (isError) {
    return (
      <UserLayout title="Verification Error">
        <div className="w-11/12 mx-auto py-10 foil lg:py-20 flex items-center justify-center flex-col gap-3">
          <div className="">
            <Imaged src={failed} alt="" className="w-auto h-[20rem]" />
          </div>
          <div className="font-bold text-2xl text-red-600">Verification failed</div>
          <div className="font-light text-center w-4/5">{error}</div>
        </div>
      </UserLayout>
    )
  }

  return (
    <UserLayout title="Verify Payment">
      <div className="w-11/12 mx-auto bg-white py-10 lg:py-20 foil shadow-2xl flex items-center justify-center flex-col gap-3">
        <div className="">
          <Imaged src={cards} alt="" className="w-auto h-[20rem]" />
        </div>
        <div className="font-bold text-2xl text-green-600">Verification Completed</div>
        <div className="font-light text-center w-4/5">Payment completed, redirecting you shortly...</div>
      </div>
    </UserLayout>
  )
}

