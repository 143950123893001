import moment from "moment";
import { useEffect, useState } from "react";
import { BiSolidUser } from "react-icons/bi";
import { SocketConnect } from "components/Apis";
import { SlCamera } from "react-icons/sl";
import { FaCamera } from "react-icons/fa6";
import { useSelector } from "react-redux";


export default function AllChatUsers({ users, OpenRoom }) {
    const {user} = useSelector(state => state.data)
    // const [typing, setTyping] = useState('')
    // useEffect(() => {
    //     SocketConnect.on('user-currently-typing', () => {
    //         setTyping('typing...')
    //     })
    //     SocketConnect.on('user-currently-not-typing', () => {
    //         setTyping('')
    //     })
    // }, [SocketConnect])

    const handleLastMessage = (item) => {
        return (
            item.messages?.length > 0 ?
                (item.messages[item.messages?.length - 1]?.content === '' && item.messages[item.messages?.length - 1]?.image !== null) ?
                item.messages[item.messages?.length - 1]?.sender === user.email ? 
                <div className="flex items-center gap-1">You: <FaCamera /></div> :
                <FaCamera /> :
                    `${item.messages[item.messages?.length - 1]?.content?.slice(0, 30)}...` :
                item.email
        )
    }
    return (
        <div>
            <div className="flex flex-col">
                {users?.length > 0 ? users.map((item, index) => (
                    <div
                        onClick={() => OpenRoom(item)}
                        className="border-b p-2 grid grid-cols-8 hover:bg-slate-100 transition-all cursor-pointer" key={index}>
                        <div className="flex gap-2 col-span-6">
                            <div className="text-2xl rounded-full p-2.5 bg-slate-200 text-slate-500 w-fit h-fit"> <BiSolidUser /> </div>
                            <div className="text-sm">
                                <div className="font-semibold">{item.name}</div>
                                <div className="text-xs">{handleLastMessage(item)}</div>
                            </div>
                        </div>
                        <div className="col-span-2 flex flex-col gap-2">
                            <div className="text-right text-xs"> {item.messages?.length > 0 ? moment(item.messages[item.messages?.length - 1]?.createedAt).format('DD/MM/YYYY') : ''} </div>
                            <div className="w-fit ml-auto"> <div className={`w-3 h-3 rounded-full ${item.active === 'yes' ? 'bg-green-400' : 'bg-gray-400'}`}></div> </div>
                        </div>
                    </div>
                )) : <div className="text-center mt-10">There are no chats messages recorded yet!...</div> }
            </div>
        </div>
    )
}