import { useState } from "react"
import Footer from "components/general/Footer"
import Header from "components/general/Header"
import { Apis, geturl } from "components/Apis"
import { SlExclamation } from "react-icons/sl"
import ShortletFilter from "./ShortletFilter/ShortletFilter"
import { MdOutlineFilterList } from "react-icons/md"
import MobileFilter from "./ShortletFilter/MobileFilter"
import SingleShortletData from "./SingleShortletData"
import ImageModal from "components/user/ImageModal"
import { useQuery } from "@tanstack/react-query"
import { Helmet } from "react-helmet-async"

const HomeShortlets = () => {
    const [items, setItems] = useState([])
    const [mobilefilter, setMobilefilter] = useState(false)
    const [stats, setStats] = useState({
        minprice: 0,
        maxprice: 1
    })
    const [view, setView] = useState({
        img: null,
        status: false
    })

    let { data, isLoading } = useQuery({
        queryKey: [`home-shortlets-all`],
        queryFn: async () => {
            const response = await geturl(Apis.feeds.all_shortlet)
            if (response.status === 200) {
                const findMax = response.msg.reduce((max, product) => (product?.price > max ? product?.price : max), response.msg[0]?.price);
                const findMin = response.msg.reduce((min, product) => (product?.price < min ? product?.price : min), response.msg[0]?.price);
                setStats({
                    ...stats,
                    minprice: parseInt(findMin),
                    maxprice: parseInt(findMax)
                })
                setItems(response.msg)
                return response.msg
            }
        }
    })

    const OpenImageModal = val => {
        setView({
            img: val,
            status: true
        })
    }

    const Signal = values => {
        setItems(values)
    }

    if (!isLoading && data?.length < 1) return (
        <div>
            <Header />
            <div className="">
                <div className="w-11/12 mx-auto lg:w-10/12 mb-20 mt-10">
                    <div className="grid grid-cols-1 lg:grid-cols-7 gap-5">
                        <div className="hidden lg:block lg:col-span-2">
                            <div className="h-[35rem] bg-slate-200"></div>
                        </div>
                        <div className="lg:col-span-5">
                            <div className="">
                                <div className='flex flex-col items-center gap-4 my-32'>
                                    <SlExclamation className='text-5xl text-zinc-400' />
                                    <div className="text-center text-zinc-500">No Shortlets uploaded at the moment</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )

    if (isLoading) return (
        <div className="bg-slate-100">
            <Header />
            <div className="w-11/12 mx-auto lg:w-10/12 mb-20 mt-10">
                <div className="grid grid-cols-1 lg:grid-cols-7 gap-5">
                    <div className="hidden lg:block lg:col-span-2">
                    </div>
                    <div className="lg:col-span-5">
                        <div className="">
                            <div className="grid grid-cols-1 gap-4">
                                {new Array(10).fill(0).map((item, i) => (
                                    <div className="h-[10rem] bg-slate-200 animate-pulse" key={i}></div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )

    return (
        <>
        <Helmet prioritizeSeoTags>
            <title>Quick Direct | Shortlets</title>
            <link rel="notImportant" href="https://www.quickdirect.com.ng/shortlets" />
            <meta name="whatever" value="notImportant" />
            <link rel="canonical" href="https://www.quickdirect.com.ng/shortlets" />
            <meta property="og:title" content="Quick Direct | Shortlets" />
            <meta property="og:url" content="https://www.quickdirect.com.ng/shortlets" />
            <meta property="og:image" content="https://www.quickdirect.com.ng/main.png" />
            <meta property="og:type" content="website" />
            <meta property="og:description" content="Discover premium short-term rental options with Quick Direct. Perfect for business trips, vacations, and more, our shortlets offer comfort, convenience, and style in prime locations. Book your ideal stay today!" />
        </Helmet>
        <div className="bg-slate-100">
            {view.status && <ImageModal
                img={view.img}
                closeView={() => setView({ ...view, status: false })}
            />}
            {mobilefilter && <MobileFilter
                shortlets={data}
                stats={stats}
                border={true}
                Signal={Signal}
                closeView={() => setMobilefilter(!mobilefilter)}
            />}
            <Header />
            <div className="w-11/12 mx-auto lg:w-10/12 mb-20 mt-10">
                <div className="grid grid-cols-1 lg:grid-cols-7 gap-5">
                    <div className="hidden lg:block lg:col-span-2">
                        <ShortletFilter
                            stats={stats}
                            border={true}
                            shortlets={data}
                            Signal={Signal}
                        />
                    </div>
                    <div className="lg:col-span-5">
                        <div className="">
                            <div
                                onClick={() => setMobilefilter(!mobilefilter)}
                                className="flex lg:hidden items-center gap-2 mb-5 w-fit cursor-pointer">
                                <MdOutlineFilterList className='text-2xl' />
                                <div className="">Filter</div>
                            </div>
                            {items.length > 0 && <div className="">
                                {items.map((item, index) => (
                                    <SingleShortletData
                                        url={`/shortlets`}
                                        item={item}
                                        key={index}
                                        OpenImageModal={OpenImageModal}
                                    />
                                ))}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        </>
    )
}

export default HomeShortlets