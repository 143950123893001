
import React, { useState } from 'react'
import SideViewModalLayout from 'components/utils/SideViewModalLayout'
import Formbutton from 'components/utils/Formbutton'
import { ErrorAlert, Errors, SuccessAlert } from 'components/utils/functions'
import { Apis, Authposturl, Authputurl, imgurl } from 'components/Apis'
import Forminput from 'components/utils/Forminput'
import { FaPlus } from 'react-icons/fa'
import Imaged from 'components/utils/Imaged'

export default function ManageLogoModal({ closeView, refetch, item }) {
    const [loading, setLoading] = useState(false)
    const [view, setView] = useState(1)
    const [title, setTitle] = useState(item?.title || '')
    const [logo, setLogo] = useState({
        img: item?.image ? `${imgurl}/logos/${item.image}` : null,
        image: null
    })

    const HandleUploads = e => {
        const file = e.target.files[0]
        if (file && file.size > 1000000) return ErrorAlert('Image size must not exceed 1MB')
        if (file && !file.type.startsWith('image/')) return ErrorAlert('Upload a valid image file')
        setLogo({
            img: URL.createObjectURL(file),
            image: file
        })
    }

    const SubmitForm = async (e) => {
        e.preventDefault()
        if (!logo.image) return ErrorAlert('provide a valid Logo')
        const forms = new FormData()
        forms.append('image', logo.image)
        forms.append('tag',  item?.image ? 'update' : 'create')
        forms.append('id', item?.id || "")

        setLoading(true)
        try {
            const response = await Authposturl(Apis.feeds.manage_partners_logo, forms)
            if (response.status === 200) {
                SuccessAlert(response.msg)
                return refetch()
            } else {
                ErrorAlert(`${response.msg}`)
            }
        } catch (e) {
            Errors(e)
        } finally {
            setLoading(false)
        }
    }
    const SubmitDeleteRequest = async (e) => {
        e.preventDefault()
        const forms = {
            id: item?.id,
            tag: "delete"
        }
        setLoading(true)
        try {
            const response = await Authposturl(Apis.feeds.manage_partners_logo, forms)
            if (response.status === 200) {
                SuccessAlert(response.msg)
                return refetch()
            } else {
                ErrorAlert(`${response.msg}`)
            }
        } catch (e) {
            Errors(e)
        } finally {
            setLoading(false)
        }
    }
    return (
        <SideViewModalLayout closeView={closeView}>
            {view === 1 && <div className="p-4 mt-10">
                <div className="font-bold text-xl mb-5">{item?.id ? 'Update Logo' : 'Add New Logo'}
                    {item?.id && <button
                        onClick={() => setView(2)}
                        className="bg-red-500 ml-3 text-white py-2 px-5 rounded-md text-sm capitalize">delete</button>}
                </div>
                <form>
                    <div className="mb-4">
                        <label className='cursor-pointer'>
                           {!logo.img ? <div className="w-full h-44 border border-dashed border-slate-600 mb-10 flex items-center justify-center">
                                <FaPlus />
                            </div> : 
                            <Imaged src={logo.img} alt="" className="block w-full h-44 border border-dashed border-slate-600 object-contain" />
                            }
                            <input type="file" hidden onChange={HandleUploads} />
                        </label>
                    </div>
                    <Formbutton
                        handleSubmission={SubmitForm}
                        loading={loading}
                        title="Submit"
                    />
                </form>
            </div>}
            {view === 2 && <div className="p-4 mt-10">
                <div className="font-bold text-xl mb-5">Delete <span className="text-red-400">{item.title}</span> </div>
                <div className="text-center mb-10">Are you sure you want to delete this logo?</div>
                <form>
                    <Formbutton
                        handleSubmission={SubmitDeleteRequest}
                        loading={loading}
                        title="proceed"
                    />
                </form>
            </div>}
        </SideViewModalLayout>
    )
}

