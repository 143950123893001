import { useState } from "react";
import ModalLayout from "components/utils/ModalLayout";
import { ErrorAlert, SuccessAlert } from "components/utils/functions";
import Forminput from "components/utils/Forminput";
import Formbutton from "components/utils/Formbutton";
import { Apis, Authposturl, Authputurl } from "components/Apis";

export default function ShareclassModal({ closeView, refetch, pageData }) {
    const [forms, setForms] = useState({
        title: pageData?.title || '',
        minbuy: pageData?.minbuy || '',
        maxbuy: pageData?.maxbuy || '',
        minsell: pageData?.minsell || '',
        maxsell: pageData?.maxsell || '',
        sellDuration: pageData?.sellDuration || '',
        sellDurationType: pageData?.sellDurationType || '',
    })
    const [screen, setScreen] = useState(1)
    const [loading, setLoading] = useState(false)
    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }
    const handleSubmit = async e => {
        e.preventDefault()
        if (!forms.title) return ErrorAlert('title must be provided')
        if (!forms.minbuy) return ErrorAlert('minimum purchase must be provided')
        if (!forms.maxbuy) return ErrorAlert('maximum purchase must be provided')
        if (!forms.minsell) return ErrorAlert('minimum sell out must be provided')
        if (!forms.maxsell) return ErrorAlert('maximum sell out must be provided')
        if (!forms.sellDuration) return ErrorAlert('provide the duration for selling shares again')
        if (!forms.sellDurationType) return ErrorAlert('provide the duration type for selling shares again')
        setLoading(true)
        const formdata = {
            ...forms,
            id: pageData?.id ? pageData.id : ''
        }
        try {
            const response = !pageData?.id ? await Authposturl(Apis.shares.create_share_class, formdata) : await Authputurl(Apis.shares.update_share_class, formdata)
            if (response.status === 200) {
                SuccessAlert(response.msg)
                closeView()
                return refetch()
            } else {
                ErrorAlert(`${response.msg}`)
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoading(false)
        }
    }
    const ProceedToDelete = async () => {
        setLoading(true)
        const formdata = {
            id: pageData?.id ? pageData.id : ''
        }
        try {
            const response = await Authposturl(Apis.shares.delete_share_class, formdata)
            if (response.status === 200) {
                SuccessAlert(response.msg)
                closeView()
                return refetch()
            } else {
                ErrorAlert(`${response.msg}`)
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoading(false)
        }
    }
    return (
        <ModalLayout closeView={closeView}>
            {screen === 2 && <>
                <div className="p-6">
                    <div className="flex flex-wrap items-center justify-center gap-2 mt-10 mb-10">Are you sure you want to <div className="font-bold text-red-600 uppercase">delete</div> this share class</div>
                    <div className="grid grid-cols-2 gap-10">
                        <button onClick={() => setScreen(1)} className="px-5 py-3 capitalize rounded-lg bg-slate-300 w-fit">cancel</button>
                        <Formbutton handleSubmission={ProceedToDelete} title={'Confirm request'} loading={loading} />
                    </div>
                </div>
            </>}
            {screen === 1 && <>
                {pageData?.id && <div className="mt-10 ml-5">
                    <button onClick={() => setScreen(2)} className="px-5 py-3 text-white bg-red-500 rounded-lg">delete</button>
                </div>}
                <form onSubmit={handleSubmit} className="p-5 mt-5">
                    <div className="p-3 mb-3 font-bold border-b">Manage Sharesholding Class Group</div>
                    <div className="mb-3">
                        <div className="">Title</div>
                        <Forminput formName={'title'} value={forms.title} onChange={handleForms} />
                    </div>
                    <div className="mb-3">
                        <div className="">Min. Buy in</div>
                        <Forminput formName={'minbuy'} value={forms.minbuy} onChange={handleForms} />
                    </div>
                    <div className="mb-3">
                        <div className="">Max. Buy in</div>
                        <Forminput formName={'maxbuy'} value={forms.maxbuy} onChange={handleForms} />
                    </div>
                    <div className="mb-3">
                        <div className="">Min. Sell out</div>
                        <Forminput formName={'minsell'} value={forms.minsell} onChange={handleForms} />
                    </div>
                    <div className="mb-3">
                        <div className="">Max. Sell out</div>
                        <Forminput formName={'maxsell'} value={forms.maxsell} onChange={handleForms} />
                    </div>
                    <div className="">
                        <div className="text-sm">Sell shares Duration</div>
                        <Forminput
                            formName="sellDuration"
                            label="Sell Shares Duration"
                            type="number"
                            onChange={handleForms}
                            value={forms.sellDuration}
                        />
                    </div>
                    <div className="">
                        <div className="text-sm">Sell shares duration Type</div>
                        <Forminput
                            formName="sellDurationType"
                            label="Sell Shares duration type"
                            onChange={handleForms}
                            value={forms.sellDurationType}
                            formtype='select'
                        >
                            <option value="minutes">In Minutes</option>
                            <option value="hours">In Hours</option>
                            <option value="days">In Days</option>
                            <option value="months">In Months</option>
                        </Forminput>
                    </div>
                    <div className="w-full">
                        <Formbutton title="Submit" loading={loading} />
                    </div>
                </form>
            </>}
        </ModalLayout>
    )
}