import React from 'react'
import FeedForm from './FeedForm'
import ALayout from 'components/admin/ALayout'

const CreateFeeds = () => {
  return (
    <ALayout>
        <FeedForm />
    </ALayout>
  )
}

export default CreateFeeds