
import React, { useCallback, useEffect, useState } from 'react'
import ALayout from 'components/admin/ALayout'
// import ShortletModal from './ShortletModal';
import { Apis, Authgeturl } from 'components/Apis';
import { Errors, NairaSign } from 'components/utils/functions';
import AHeader from 'components/admin/AHeader';
import moment from 'moment';
import PickupModal from './PickupModal';

const Tableheaders = [
  "Driver Needed",
  "Purchasing price at enquiry",
  "pickup contact",
  "enquiried shortlet",
  "check in date/time",
  "check out date/time",
  "initiated by",
  "date created",
  ""
]

const AllPickups = () => {
  const [plans, setPlans] = useState([])
  const [loading, setLoading] = useState(false)
  const [single, setSingle] = useState({})
  const [view, setView] = useState(false)

  const FetchAllPlans = useCallback(async () => {
      try {
          const res = await Authgeturl(Apis.feeds.all_pickup_requests)
          if (res.status === 200) return setPlans(res.msg)
      } catch (error) {
          Errors(`${error}`)
      } finally {
          setLoading(false)
      }
  }, [])
  useEffect(() => { FetchAllPlans() }, [FetchAllPlans])

  const handleSingle = val => {
      setSingle(val)
      setView(!view)
  }
  return (
    <ALayout>
        {view && <PickupModal
            item={single}
            closeView={() => setView(!view)}
            refetch={() => { FetchAllPlans(); setView(!view) }}
        />}
        <div className="">
            <AHeader
                title="pickup requests"
                total={plans.length}
                url={false}
            />
            <div className="mt-10 overflow-x-auto scrollsdown">
                {!loading && <table className='table table-auto w-full bg-white p-3 text-sm'>
                    <thead>
                        <tr className='bg-slate-700 text-white'>
                            {Tableheaders.map((ele, i) => (
                                <td className='capitalize p-3' key={i}>{ele}</td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {plans.length > 0 && plans.map((item, i) => (
                            <tr 
                            key={i}
                            className='cursor-pointer border-b border-r border-l'
                            onClick={() => handleSingle(item)}
                            >
                                <td className='p-3 uppercase'>{item.ispickup}</td>
                                <td className='p-3'>{NairaSign}{item.pricing?.toLocaleString()}</td>
                                <td className='p-3'>{item.phone?.slice(0, 5)}..</td>
                                <td className='p-3'>{item.detail.title}</td>
                                <td className='p-3'>{item.checkin} {item.checkinTime}</td>
                                <td className='p-3'>{item.checkout} {item.checkoutTime}</td>
                                <td className='p-3'>{item?.firstname}, {item?.lastname}</td>
                                <td className='p-3'>{moment(item.createdAt).format('DD-MM-YYYY hh:ss A')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>}
            </div>
        </div>
    </ALayout>
  )
}

export default AllPickups
