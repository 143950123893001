import React, { useState } from 'react'
import ModalLayout from 'components/utils/ModalLayout'
import { ErrorAlert, NairaSign } from 'components/utils/functions'
import { useSelector } from 'react-redux'

const PayOptions = {
    balance: 'balance',
    bank: 'bank',
}
export default function PaymentSelection({ closeView, SendInvestment, data, PayWithBank }) {
    const { user } = useSelector(state => state.data)
    const [payMethod, setPayMethod] = useState('')
    const SelectMethod = val => {
        if (payMethod !== val) {
            setPayMethod(val)
        } else {
            setPayMethod('')
        }
    }

    const PurchaseWithBalance = () => {
        if (!payMethod) return ErrorAlert(`Select a payment method`)
        if (payMethod === PayOptions.bank) {
            PayWithBank(payMethod)
        } else {
            SendInvestment(payMethod)
        }
    }
    return (
        <ModalLayout closeView={closeView}>
            <div className=" p-3 overflow-x-hidden">
                <div className="p-3 border-b">Select Payment Method</div>
                <div className="mb-10">
                    <div className="">
                        <div className="text-right text-red-600">-{NairaSign}{data.amount?.toLocaleString()}</div>
                        <div className="text-right text-sm">Will be charged from your account / bank</div>
                    </div>
                    <div
                        onClick={() => SelectMethod(PayOptions.balance)}
                        className="border rounded-lg p-2 mt-10 hover:scale-105 transition-all w-11/12 mx-auto cursor-pointer">
                        <div className="flex gap-2">
                            <div className="">
                                <div className={`w-5 h-5 rounded-full ${payMethod === PayOptions.balance ? 'bg-blue-600' : 'bg-slate-300'}`}></div>
                            </div>
                            <div className="w-full">
                                <div className="">
                                    <div className="font-bold">Account Balance</div>
                                    <div className="text-sm">Available Balance: {NairaSign}{user.currbal?.toLocaleString()}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        onClick={() => SelectMethod(PayOptions.bank)}
                        className="border rounded-lg p-2 hover:scale-105 transition-all w-11/12 mx-auto mt-2 cursor-pointer">
                        <div className="flex gap-2">
                            <div className="">
                                <div className={`w-5 h-5 rounded-full ${payMethod === PayOptions.bank ? 'bg-blue-600' : 'bg-slate-300'}`}></div>
                            </div>
                            <div className="w-full">
                                <div className="">
                                    <div className="font-bold">Bank Transfer </div>
                                    <div className="text-sm">Withdraw directly from my bank account</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="bg-main w-full text-white py-3 px-4 rounded-lg" onClick={PurchaseWithBalance}>
                    Invest
                </button>
            </div>
        </ModalLayout>
    )
}

