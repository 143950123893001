import { Apis, Authputurl } from 'components/Apis'
import Formbutton from 'components/utils/Formbutton'
import Forminput from 'components/utils/Forminput'
import ModalLayout from 'components/utils/ModalLayout'
import { ErrorAlert, NairaSign, SingleServiceStatus, SuccessAlert, WithdrawStatusOrder } from 'components/utils/functions'
import moment from 'moment'
import React, { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { BsCopy } from 'react-icons/bs'

const Orders = ["processing", 'cancelled', 'completed']

export default function CashbackWithdrawModal({ closeView, data, refetch }) {
    const [forms, setForms] = useState({
        status: data.status || '',
        note: ''
    })
    const [screen, setScreen] = useState(1)
    const [loading, setLoading] = useState(false)

    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }

    const handleSubmission = async e => {
        e.preventDefault()
        if (!forms.status) return ErrorAlert('Select a valid withdrawal status')
        if (forms.status === WithdrawStatusOrder.cancelled && !forms.note) return ErrorAlert('Provide reason for cancellation')
        const formData = {
            ...forms,
            id: data.id
        }
        setLoading(true)
        try {
            const response = await Authputurl(`${Apis.mobilenig.manage_cashback_withdrawals}`, formData)
            if (response.status !== 200) return ErrorAlert(`${response.message}`)
            SuccessAlert(`${response.message}`)
            refetch()
            return closeView()
        } catch (error) {
            ErrorAlert(`${error}`)
        } finally {
            setLoading(false)
        }
    }
    return (
        <ModalLayout closeView={closeView}>
            <div className="px-4 pb-4 pt-10">
                {screen === 2 && <div>
                    <div className="font-bold mb-3">Update Withdrawal Request</div>
                    <form onSubmit={handleSubmission}>
                        <Forminput formtype='select' formName={'status'} value={forms.status} onChange={handleForms}>
                            {Orders.map((item, index) => (
                                <option value={item} key={index}>{item}</option>
                            ))}
                        </Forminput>
                        {forms.status === WithdrawStatusOrder.cancelled && <div className="">
                            <div className="">Reason for cancelling withdrawal</div>
                            <Forminput formName={'note'} value={forms.note} onChange={handleForms} />
                        </div>}
                        <Formbutton title="Submit" loading={loading} />
                    </form>
                </div>}
                {screen === 1 &&
                    <div>
                        <div className="w-2/5">
                            <button
                                onClick={() => setScreen(2)}
                                className='w-full capitalize py-2 px-4 rounded-lg bg-main text-white font-light'>update</button>
                        </div>
                        <div className="grid grid-cols-2 gap-5 p-2">
                            <div className="font-bold">User:</div>
                            <div className="text-right ">{data.owner?.firstname} {data.owner?.lastname}</div>
                        </div>
                        <div className="grid grid-cols-2 gap-5 p-2">
                            <div className="font-bold">Status:</div>
                            <div className={`${SingleServiceStatus(data.status)} w-fit ml-auto capitalize`}>{data.status}</div>
                        </div>
                        <div className="grid grid-cols-2 gap-5 p-2">
                            <div className="font-bold">Amount:</div>
                            <div className="text-right ">{NairaSign}{data.amount?.toLocaleString()}</div>
                        </div>
                        <div className="grid grid-cols-2 gap-5 p-2">
                            <div className="font-bold">Payment Method:</div>
                            <div className="text-right uppercase">{data.options}</div>
                        </div>
                        <div className="grid grid-cols-2 gap-5 p-2">
                            <div className="font-bold">Bank Name:</div>
                            <div className="text-right uppercase">{data.bankname}</div>
                        </div>
                        <div className="grid grid-cols-2 gap-5 p-2">
                            <div className="font-bold">Account Name:</div>
                            <div className="text-right uppercase">{data.accname}</div>
                        </div>
                        <div className="grid grid-cols-2 gap-5 p-2">
                            <div className="font-bold">Account Number:</div>
                            <CopyToClipboard text={data.accnumber}
                                onCopy={() => SuccessAlert('Account number copied')}
                            >
                                <div className="flex items-center ml-auto w-fit cursor-pointer">
                                    <div className="">{data.accnumber}</div>
                                    <BsCopy />
                                </div>
                            </CopyToClipboard>
                        </div>
                        <div className="grid grid-cols-2 gap-5 p-2">
                            <div className="font-bold">Description:</div>
                            <div className="text-sm text-right">{data.content}</div>
                        </div>
                        <div className="grid grid-cols-2 gap-5 p-2">
                            <div className="font-bold">Email Address:</div>
                            <div className="text-sm text-right">{data.owner?.email}</div>
                        </div>
                        <div className="grid grid-cols-2 gap-5 p-2">
                            <div className="font-bold">Phone Number:</div>
                            <div className="text-sm text-right">{data.owner?.dailcode}{data.owner?.phone}</div>
                        </div>
                        <div className="grid grid-cols-2 gap-5 p-2 break-words">
                            <div className="font-bold">Reference:</div>
                            <div className="text-sm text-right">{data.ref}</div>
                        </div>
                        <div className="grid grid-cols-2 gap-5 p-2 break-words">
                            <div className="font-bold">Date Initiated:</div>
                            <div className="text-sm text-right">{moment(data.createdAt).format('DD-MM-YYYY hh:mm A')}</div>
                        </div>
                    </div>
                }
            </div>
        </ModalLayout>
    )
}
