import React, { useState } from 'react'
import ModalLayout from 'components/utils/ModalLayout'
import { ErrorAlert, Errors, SuccessAlert } from 'components/utils/functions'
import Formbutton from 'components/utils/Formbutton'
import { Apis, Authputurl } from 'components/Apis'
import { useDispatch } from 'react-redux'
import { dispatchPlan } from 'app/reducer'

export default function InvestAgreeSlip({ tag, id, closeView, refetch }) {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const handleAgreement = async () => {
        const forms = {
            walletid: id
        }
        setLoading(true)
        try {
            const response = await Authputurl(Apis.investments.agree_to_investment, forms)
            if (response.status === 200) {
                SuccessAlert(response.msg)
                dispatch(dispatchPlan(response.msg))
                refetch()
            } else {
                ErrorAlert(response.msg)
            }
        } catch (error) {
            Errors(`${error.message}`)
        } finally {
            setLoading(false)
        }
    }
    return (
        <ModalLayout closeView={closeView}>
            <div className="border-b font-bold p-4 text-lg">Deed of Agreement</div>
            <div className="w-11/12 mx-auto py-4">
                
                <div className='font-thin leading-9'>This Deed of Agreement ("Agreement") is entered into between [Quick Direct] ("Company") and the undersigned investor  with the mutual intent to govern the terms and conditions of investment in the Quick Direct Prime Investment sector.</div>

                <div className='mt-3 font-bold'>1. Parties:</div>
                <div className='font-thin leading-9'>The Company, Quick Direct, is a financial services firm offering investment opportunities in various sectors, including the Quick Direct Prime, Midas, and istock Investment sector.</div>
                <div className='font-thin leading-9'>The Investor is an individual or entity interested in investing in the Quick Direct Investment products sector offered by Quick Direct.</div>

                <div className='mt-3 font-bold'>2. Investment Terms:</div>
                <div className='font-thin leading-9'>2.1 The Investor agrees to invest a specified amount in the Quick Direct Investment products sector, as outlined in the investment proposal provided by Quick Direct.</div>
                <div className='font-thin leading-9'>2.2 The investment amount, duration, expected returns, and other relevant terms are detailed in the investment proposal, which forms an integral part of this Agreement.</div>

                <div className='mt-3 font-bold'>3. Representations and Warranties:</div>
                <div className='font-thin leading-9'>3.1 The Investor represents and warrants that they have full legal capacity and authority to enter into this Agreement and perform their obligations hereunder.</div>
                <div className='font-thin leading-9'>3.2 The Investor acknowledges that they have received, reviewed, and understood all relevant documentation, including the investment proposal, risk disclosure statements, and terms and conditions.</div>

                <div className='mt-3 font-bold'>4. Risk Disclosure:</div>
                <div className='font-thin leading-9'>4.1 The Investor acknowledges that all investments carry inherent risks, and past performance is not indicative of future results.</div>
                <div className='font-thin leading-9'>4.2 The Investor understands and accepts the risks associated with investing in the Quick Direct Investment products sector, including but not limited to market volatility, economic fluctuations, and unforeseen events.</div>

                <div className='mt-3 font-bold'>5. Confidentiality:</div>
                <div className='font-thin leading-9'>5.1 The Investor agrees to maintain the confidentiality of all proprietary information disclosed by Quick Direct, including investment strategies, financial data, and business plans.</div>
                <div className='font-thin leading-9'>5.2 The Investor shall not disclose or use any confidential information for purposes other than evaluating and participating in the investment opportunity.</div>

                <div className='mt-3 font-bold'>6. Governing Law and Jurisdiction:</div>
                <div className='font-thin leading-9'>6.1 This Agreement shall be governed by and construed in accordance with the laws of [Jurisdiction].</div>
                <div className='font-thin leading-9'>6.2 Any disputes arising out of or in connection with this Agreement shall be resolved through arbitration in accordance with the rules of [Arbitration Institution].</div>

                <div className='mt-3 font-bold'>7. Miscellaneous:</div>
                <div className='font-thin leading-9'>7.1 This Agreement constitutes the entire understanding between the Parties concerning the subject matter hereof and supersedes all prior agreements and understandings, whether written or oral.</div>
                <div className='font-thin leading-9'>7.2 Any modifications or amendments to this Agreement must be made in writing and signed by both Parties.</div>
                <div className='font-thin leading-9'>7.3 This Agreement may be executed in counterparts, each of which shall be deemed an original but all of which together shall constitute one and the same instrument.</div>

                <div className='font-thin leading-9'>In Witness Whereof, the Parties hereto have executed this Deed of Agreement as of the date first above written.</div>

                <div className='font-thin leading-9'>Quick Direct</div>
                <div className='font-thin leading-9'>By: [Founder/CEO] (LORDWILLIAMS EA GREATNEES)</div>
                <div className="w-fit ml-auto">
                    <Formbutton title="Agree" loading={loading} handleSubmission={handleAgreement} />
                </div>
            </div>
        </ModalLayout>
    )
}

