import React, { useState } from 'react'
import UserLayout from 'components/user/UserLayout'
import { NairaSign } from 'components/utils/functions'
import { Apis, Authgeturl } from 'components/Apis'
import Spinner from 'components/utils/Spinner'
import { useNavigate } from 'react-router-dom'
import { BiSolidBellRing } from 'react-icons/bi'
import moment from 'moment'
import { FaArrowRight } from 'react-icons/fa'
import { SlMagnifier } from 'react-icons/sl'
import { useQuery } from '@tanstack/react-query'
import Imaged from 'components/utils/Imaged'
import emptyImage from "assets/main-images/empty.png"


const MyPickups = () => {
    const [form, setForm] = useState('')
    const [items, setItems] = useState([])
    const [items2, setItems2] = useState([])
    const navigate = useNavigate()

    const { data, isLoading } = useQuery({
        queryKey: ['my-pickups'],
        queryFn: async () => {
            const response = await Authgeturl(Apis.feeds.pickup_requests)
            setItems(response.msg)
            setItems2(response.msg)
            return response.msg
        }
    })

    if (isLoading) {
        return (
            <UserLayout>
                <div className="w-fit mx-auto mt-20"> <Spinner /> </div>
            </UserLayout>
        )
    }

    const handleSearch = e => {
        const value = e.target.value
        if (value.length > 0) {
            const findData = items2.filter(ele => ele.trackid.toLowerCase().includes(value.toLowerCase()))
            if (findData.length > 0) {
                setItems(findData)
            } else {
                setItems([])
            }
        } else {
            setItems(data)
        }
    }
    if (items.length < 1) return (
        <UserLayout footer={false} title="My Orders">
            <div className="flex items-center justify-center flex-col h-[50vh]">
                <Imaged src={emptyImage} className="size-20" />
                <div className="text-slate-500 text-sm">No records found</div>
            </div>
        </UserLayout>
    )
    return (
        <UserLayout footer={false} title="My Orders">
            <div className="">
                <div className="w-11/12 mx-auto mb-5">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="text-3xl font-bold ">My Shortlet Orders</div>
                        <div className="relative">
                            <div className="absolute top-4 left-2"> <SlMagnifier /> </div>
                            <input
                                value={form}
                                onKeyUp={handleSearch}
                                onChange={e => setForm(e.target.value)}
                                placeholder='Enter Tracking ID'
                                className='border bg-white py-2.5 pl-8 rounded-lg outline-none w-full' />
                        </div>
                    </div>
                </div>
                <div className="bg-white py-4">
                    <ol className="relative border-l border-gray-200 dark:border-gray-300 w-11/12 mx-auto">
                        {items.length > 0 && items.map((item, index) => (
                            <li key={index} className="mb-10 ml-6 cursor-pointer" onClick={() => navigate(`/pages/orders/${item.id}`)}>
                                <span className="absolute flex items-center text-sm text-slate-400 dark:text-white justify-center w-8 h-8 bg-slate-100 rounded-full -left-4 ring-4 ring-white dark:ring-slate-600 dark:bg-green-400">
                                    <BiSolidBellRing />
                                </span>
                                <div className="grid grid-cols-5">
                                    <div className="col-span-3">
                                        <h3 className="leading-tight font-medium text-sm capitalize"> {item.detail?.title}</h3>
                                        <div className="text-xs font-medium capitalize">{item.detail?.country}, {item.detail?.state}</div>
                                        <div className="flex items-center gap-2 text-xs">
                                            <span>Check in</span>
                                            <span>{item.checkin} {item.checkinTime}</span>
                                        </div>
                                        <div className="flex items-center gap-2 text-xs">
                                            <span>Check out</span>
                                            <span>{item.checkout} {item.checkoutTime}</span>
                                        </div>
                                    </div>
                                    <div className="w-fit ml-auto col-span-2">
                                        <div className="text-sm text-right">{NairaSign}{item.pricing?.toLocaleString()}</div>
                                        <div className="text-sm text-right mb-2">{moment(item.createdAt).format('DD-MM-YYYY')}</div>
                                        <button className='w-full flex justify-end'> <FaArrowRight /> </button>
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 gap-2 text-xs py-1">
                                    <div className='font-bold'>Status:</div>
                                    <div className='text-right uppercase break-words'>{item.status}</div>
                                </div>
                                <div className="grid grid-cols-2 gap-2 text-xs py-1">
                                    <div className='font-bold'>Tracking ID:</div>
                                    <div className='text-right uppercase break-words'>{item.trackid}</div>
                                </div>
                            </li>
                        ))}
                    </ol>
                </div>
            </div>
        </UserLayout>
    )
}

export default MyPickups