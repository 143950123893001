
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { SlClock } from 'react-icons/sl';
import { useParams } from 'react-router-dom';
import { ClientInvestmentStatus, DurationTypes, NairaSign } from 'components/utils/functions';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

export default function MidasBoard({ activeplan, plan }) {
    const [num, setNum] = useState("00h:00m:00s");
    const { id } = useParams();
    const [loads, setLoads] = useState(true)
    const queryClient = useQueryClient();
    // const subplan = plan.plans?.subplan?.find(ele => ele.id === activeplan?.subplan)
    const dispatch = useDispatch()

    // useeffect for getting the main duration count down
    useEffect(() => {
        let interval;

        interval = setInterval(async () => {
            // Get current time
            const currentTime = moment();

            // Calculate the difference between the given moment time and the current time
            const diff = moment(new Date(activeplan?.endDate)).diff(currentTime);

            if (diff <= 0) {
                if (loads) {
                    queryClient.refetchQueries({ queryKey: [`invest-layout-${id}`] }, { force: true });
                    setTimeout(() => {
                        queryClient.refetchQueries({ queryKey: [`invest-layout-${id}`] }, { force: true });
                    }, 500);
                }
                clearInterval(interval);
                setNum("00h:00m:00s");
                setLoads(false)
                return;
            }

            // Convert the difference to a countdown format
            const duration = moment.duration(diff);
            const years = duration.years();
            const months = duration.months();
            const days = duration.days();
            // const years = Math.floor(duration.asYears());
            // const months = Math.floor(duration.asMonths());
            // const days = Math.floor(duration.asDays());
            const hours = duration.hours();
            // const hours = Math.floor(duration.asHours());
            const minutes = duration.minutes();
            const seconds = duration.seconds();

            // Format hours, minutes, and seconds to ensure they have two digits
            const formattedYears = years < 10 ? "0" + years : years;
            const formattedMonths = months < 10 ? "0" + months : months;
            const formattedDays = days < 10 ? "0" + days : days;
            const formattedHours = hours < 10 ? "0" + hours : hours;
            const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
            const formattedSeconds = seconds < 10 ? "0" + seconds : seconds;

            // Return the countdown time in the specified format
            let fullTimed;
            if (activeplan?.durationType === DurationTypes.years) { fullTimed = `${formattedYears}y:${formattedMonths}m:${formattedDays}d:${formattedHours}h:${formattedMinutes}m:${formattedSeconds}s` }
            if (activeplan?.durationType === DurationTypes.months) { fullTimed = `${formattedMonths}m:${formattedDays}d:${formattedHours}h:${formattedMinutes}m:${formattedSeconds}s` }
            if (activeplan?.durationType === DurationTypes.days) { fullTimed = `${formattedDays}d:${formattedHours}h:${formattedMinutes}m:${formattedSeconds}s` }
            if ([DurationTypes.hours, DurationTypes.minutes].includes(activeplan?.durationType)) { fullTimed = `${formattedHours}h:${formattedMinutes}m:${formattedSeconds}s` }

            setNum(fullTimed);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [dispatch, id, activeplan]);
    
    return (
        <div>
            <div className="grid grid-cols-2 gap-5 w-11/12 mx-auto">
                <div className="flex flex-col items-center gap-2 mb-5">
                    <div className="font-bold text-center text-sm text-slate-200 capitalize">total NGN portfolio balance</div>
                    <div className="text-2xl md:text-3xl text-white">{NairaSign}<span className="font-bold">{(plan.totalEarned + (activeplan ? (activeplan?.returns - plan.totalEarned) : 0) || 0)?.toLocaleString() || 0}</span> </div>
                    <div className="text-green-400 font-light text-xs md:text-sm">+{NairaSign}{parseFloat(plan.profitUpdate)?.toLocaleString() || 0} <span className="text-white capitalize">last update today</span> </div>
                    {/* <div className="text-sm flex items-center gap-1 text-white"> <SlClock /> {activeplan?.status === ClientInvestmentStatus.active ? <>Next top-up: {num}</> : 'Currently not active'} </div> */}
                </div>
                <div className="flex flex-col items-center gap-2 mb-5">
                    <div className="font-bold text-center text-sm text-slate-200 capitalize">investment Status</div>
                    <div className={`text-2xl md:text-3xl font-bold ${activeplan?.status === ClientInvestmentStatus.active ? 'text-green-400' : 'text-red-400'} flex items-center gap-1`}> {activeplan?.status === ClientInvestmentStatus.active ? <><div className="dloader"></div> Active</> : 'Inactive'}</div>
                    <div className="text-sm flex items-center gap-1 text-white"> <SlClock /> {activeplan?.status === ClientInvestmentStatus.active ? <>time left: {num}</> : 'Currently not active'} </div>
                    {activeplan?.autoRenew === 'yes' && <div className="flex items-center gap-3 text-white">Auto Renew: <span className={`${activeplan?.autoRenew === 'yes' ? 'text-green-400' : 'text-red-400'} uppercase font-bold`}>{activeplan?.autoRenew}</span> </div>}
                </div>
            </div>
        </div>
    )

}

