import React from 'react'
import ModalLayout from '../utils/ModalLayout'
import Imaged from '../utils/Imaged'

const ImageModal = ({img, closeView}) => {
  return (
    <ModalLayout closeView={closeView}>
        <Imaged src={img} alt="" className='w-full h-[80dvh] object-cover' />
    </ModalLayout>
  )
}

export default ImageModal