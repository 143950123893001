import React, { useEffect, useState } from 'react'
import UserLayout from 'components/user/UserLayout'
import { Apis, Authgeturl } from 'components/Apis'
import { Errors } from 'components/utils/functions'
import SingleNotification from './SingleNotification'
import Spinner from 'components/utils/Spinner'
import Pagination from 'components/utils/Pagination'
import { useSearchParams } from 'react-router-dom'

const Notifications = () => {
    const [feeds, setFeeds] = useState({})
    const [loading, setLoading] = useState(true)
    const [query, setQuery] = useSearchParams()
    
    useEffect(() => {
        const FetchNotifies = async () => {
            setLoading(true)
            try {
                const res = await Authgeturl(`${Apis.notify.my_notifies}?${query.get('p') ? query.get('p') : ''}`)
                if(res.status === 200) {
                    setFeeds(res)
                }
            } catch (error) {
                Errors(error)
            }finally {
                setLoading(false)
            }
        }
        FetchNotifies()
    }, [query])

    const handlePages = val => {
        setQuery(`?p=${val}`)
    }

    

    if(loading) return (
        <UserLayout title={'notifications'}>
            <div className="dark:text-blue-100 w-11/12 mx-auto">
                <div className="text-xl pt-3 px-4 font-bold">Latest Notifications</div>
                <div className="text-sm px-4 pb-4">Showing (0) in total</div>
                 <div className="w-fit mx-auto"> <Spinner /> </div> 
            </div>
        </UserLayout>
    )


    return (
        <UserLayout title={'notifications'}>
            <div className="dark:text-blue-100 w-11/12 mx-auto">
                <div className="text-xl pt-3 px-4 font-bold">Latest Notifications</div>
                <div className="text-sm px-4 pb-4">Showing ({feeds.data.length}) of {feeds.total}</div>
               <div className="bg-white px-5 mx-auto dark:bg-slate-600 py-3 rounded-lg border">
                    <div className=" mx-auto">
                        <ol className="relative border-l border-gray-200 dark:border-gray-700">
                            {feeds.total > 0 && feeds.data?.map((item, i) => (
                               <SingleNotification key={i} item={item} />
                            ))}
                        </ol>
                        <Pagination 
                        page={feeds.p}
                        total={feeds.total}
                        perPage={feeds.page_size}
                        onChange={handlePages}
                        />
                    </div>
                </div>
            </div>
        </UserLayout>
    )
}

export default Notifications