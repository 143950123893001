import React, { useEffect, useRef } from 'react'
import { FaTimes } from 'react-icons/fa'

const SideViewModalLayout = ({children, closeView, side='right'}) => {
    const togref = useRef()
    useEffect(() => {
        if(togref) {
            window.addEventListener('click', e => {
                togref.current !== null && !togref.current.contains(e.target) && closeView()
            }, true)
        }
    })
  return (
    <div className='fixed h-screen bg-black/60  backdrop-blur-md top-0 left-0 w-full z-50'>
        <div ref={togref} className={`bg-white pb-28 overflow-y-auto scrolls ${side === 'right' ? 'ml-auto' : 'mr-auto'} h-screen foil transition-all w-[70vw] lg:w-[30vw] relative`}>
            <div onClick={() => closeView()} className="absolute top-2 right-2 bg-slate-100 text-slate-700 rounded-full p-2 cursor-pointer"> <FaTimes /> </div>
            {children}
        </div>
    </div>
  )
}

export default SideViewModalLayout