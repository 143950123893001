import React, { useState } from 'react'
import Formbutton from 'components/utils/Formbutton'
import { Link, useNavigate } from 'react-router-dom'
import Forminput from 'components/utils/Forminput'
import { ErrorAlert, Errors, Roles, SuccessAlert, tokenName } from 'components/utils/functions'
import { Apis, Posturl } from 'components/Apis'
import Cookies from 'js-cookie'
import { decodeToken } from 'react-jwt'
import GeneratePin from './GeneratePin'
import mail from 'assets/images/mailc.png'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import logo from 'assets/main-images/logo.png'
import Imaged from 'components/utils/Imaged'
import { Helmet } from 'react-helmet-async'


const Login = () => {
    const [loading, setLoading] = useState(false)
    const [otpmsg, setOtpmsg] = useState('Resend OTP')
    const [code, setCode] = useState('')
    const [screen, setScreen] = useState(1)
    const [pass, setPass] = useState(false)
    const PassIcon = pass ? FaEyeSlash : FaEye
    const [pins, setPins] = useState('')
    const navigate = useNavigate()
    const [forms, setForms] = useState({
        email: '',
        password: ''
    })

    const handleForms = e => {
        setForms({
            ...forms,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmission = async (e) => {
        e.preventDefault()
        if (!forms.email) return ErrorAlert('provide your email address')
        if (!forms.password) return ErrorAlert('provide your password')
        const data = {
            ...forms
        }
        setLoading(true)
        try {
            const res = await Posturl(Apis.user.login_account, data)
            if (res.status === 200) {
                SuccessAlert(res.msg)
                const value = decodeToken(res.token)
                const user = Roles.find(ele => ele.user === value.role)
                if (user) {
                    Cookies.set(tokenName, res.token)
                    setTimeout(() => {
                        navigate(`${user.url}`)
                    }, 1000)
                }
            } else if (res.status === 403) {
                // this error shows user has not created an account pin yet
                setScreen(2)
                ErrorAlert(res.msg)
            } else if (res.status === 409) {
                setScreen(4)
                ErrorAlert(res.msg)
            } else {
                ErrorAlert(res.msg)
            }
        } catch (error) {
            Errors(error)
        } finally {
            setLoading(false)
        }
    }
    const firstConfirmation = (vals) => {
        setLoading(true)
        setPins(vals)
        setTimeout(() => {
            setLoading(false)
            setScreen(3)
        }, 2000);
    }

    const secondConfirmation = async (vals) => {
        if (vals !== pins) return ErrorAlert('Wrong Pins detected!..')
        setLoading(true)
        const data = {
            pin: pins,
            email: forms.email
        }
        try {
            const res = await Posturl(Apis.user.create_account_pin, data)
            if (res.status === 200) {
                setScreen(1)
                SuccessAlert(res.msg)
            } else {
                ErrorAlert(`${res.msg}`)
            }
        } catch (error) {
            Errors(`${error}`)
        } finally {
            setLoading(false)
        }
    }
    const handleEmailVerificaton = async () => {
        const data = { code }
        setLoading(true)
        try {
            const res = await Posturl(Apis.user.accept_registration_code, data)
            if (res.status === 200) {
                setScreen(1)
                SuccessAlert(res.msg)
            } else {
                ErrorAlert(res.msg)
            }
        } catch (error) {
            Errors(`${error}`)
        } finally {
            setLoading(false)
        }
    }

    const ResendCode = async () => {
        if (!forms.email) return ErrorAlert('provide a valid Email Address')
        const data = {
            email: forms.email
        }
        setLoading(true)
        try {
            setOtpmsg('sending...')
            const res = await Posturl(Apis.user.resend_otp, data)
            if (res.status === 200) {
                setOtpmsg('code sent!.')
                SuccessAlert(res.msg)
                setTimeout(() => {
                    setOtpmsg('Resend OTP')
                }, 1500);
            } else {
                Errors(res.msg)
            }
        } catch (error) {
            Errors(`${error}`)
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
        <Helmet prioritizeSeoTags>
            <title>Quick direct | Login</title>
            <link rel="notImportant" href="https://www.quickdirect.com.ng/login" />
            <meta name="whatever" value="notImportant" />
            <link rel="canonical" href="https://www.quickdirect.com.ng/login" />
            <meta property="og:title" content="Quick Direct | Login" />
            <meta property="og:url" content="https://www.quickdirect.com.ng/login" />
            <meta property="og:image" content="https://www.quickdirect.com.ng/main.png" />
            <meta property="og:type" content="website" />
            <meta property="og:description" content="Log in and manage your Quick direct assets" />
        </Helmet>
        <div className='bg-zinc-50'>
            <div className="h-screen">
                <div className="grid grid-cols-1 lg:grid-cols-5">
                    <div className="bg-main hidden lg:block h-screen col-span-2">
                    <div className="bgs w-full h-full mix-blend-overlay"></div>
                    </div>
                    <div className="flex items-center bg-gradient-to-b to-white from-blue-200 lg:from-white lg:to-white flex-col justify-center h-screen col-span-3">
                        <div className="w-11/12 max-w-xl mx-auto shadow-2xl rounded-lg p-6 bg-white text-sm">
                            <div className="w-fit ml-auto p-2 mb-5 rounded-lg">
                                <Imaged src={logo} alt="" className="h-10" />
                            </div>
                            {screen === 1 && <form onSubmit={handleSubmission}>
                                <div className="text-2xl font-semibold mb-5 border-b pb-3">Log into yo<span className='text-sub'>ur account</span> </div>
                                <Forminput label="Enter Email Address" placeholder='name@example.com' title='email' value={forms.email} formName="email" onChange={handleForms} />
                                <div className="relative">
                                    <Forminput label="Enter Password" type={pass ? 'text' : 'password'} placeholder='********' title='password' value={forms.password} formName="password" onChange={handleForms} />
                                    <div className="absolute top-2 right-3 cursor-pointer text-2xl text-slate-400" onClick={() => setPass(!pass)}> <PassIcon /> </div>
                                </div>
                                <div className="">
                                    <Formbutton loading={loading} title='Login Account' />
                                </div>
                                <div className="grid grid-cols-2 my-5">
                                    <div className="">
                                        <label>
                                            <input type="checkbox" />
                                            <span className="ml-3">Remember me</span>
                                        </label>
                                    </div>
                                    <div className="text-right">
                                        <Link to="/forgot-password" className='text-blue-600' href="">Forgot password?</Link>
                                    </div>
                                </div>
                            </form>}
                            {screen === 2 && <GeneratePin
                                title="Setup your Account Pin"
                                buttonText="continue"
                                continueSetup={firstConfirmation}
                                loading={loading}
                            />}

                            {screen === 3 && <GeneratePin
                                title="Confirm Account Pin"
                                buttonText="Confirm Pin"
                                loading={loading}
                                continueSetup={secondConfirmation}
                            />}
                            {screen === 4 && <form className="space-y-6 relative">
                                <div className="w-28 mx-auto">
                                    <Imaged src={mail} alt="" className="w-full animate-bounce" />
                                </div>
                                <div className="text-center">We sent a 6 digit code to <span className="text-sub">{forms.email.slice(0, 3)}******{forms.email.slice(-10)}</span> please enter the code and paste here to complete your verification </div>
                                <div className="mt-6 w-3/5 mx-auto">
                                    <Forminput
                                        label="Verification Code"
                                        type="text"
                                        formName="code"
                                        value={code}
                                        onChange={e => setCode(e.target.value)}
                                    />
                                </div>
                                <div className="my-5 text-center flex items-center justify-center gap-3">Didn't recieve any code? <div onClick={ResendCode} className="text-sub cursor-pointer">{otpmsg}</div> </div>
                                <div className="w-fit mx-auto">
                                    <Formbutton handleSubmission={handleEmailVerificaton} title='verify email' bg='bg-sub' loading={loading} />
                                </div>
                            </form>}
                            <div className="mt-5">
                                <div className="">Don't have an account? <Link to="/signup" className="text-blue-600">Create account.</Link> </div>
                                <div className="mt-3">  <Link className='text-blue-600' to="/">Back home</Link> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Login