
import React, { useState } from 'react'
import UserLayout from 'components/user/UserLayout'
import { SlWallet } from 'react-icons/sl'
import Formbutton from 'components/utils/Formbutton'
import { ErrorAlert, Errors, NairaSign, SuccessAlert } from 'components/utils/functions'
import { useDispatch, useSelector } from 'react-redux'
import { dispatchPlan, dispatchUser } from 'app/reducer'
import { Apis, Authposturl } from 'components/Apis'
import Confirmation from '../../Compos/Confirmation'
import InvestLayout from '../../Compos/InvestLayout'
import PaymentSelection from '../PaymentSelection'
import Loading from 'components/utils/Loading'
import { useNavigate } from 'react-router-dom'
import PrimeHistory from './PrimeHistory'

export default function TradePrime() {
  const [loading, setLoading] = useState(false)
  const [active, setActive] = useState(false)
  const [details, setDetails] = useState({})
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { plan, user } = useSelector(state => state.data)
  const [text, setText] = useState('')
  const [view, setView] = useState(false)
  const [screen, setScreen] = useState(true)
  const [open, setOpen] = useState({
    status: false,
    data: {}
  })
  const [forms, setForms] = useState({
    amount: '',
    error: false,
    message: '',
  })

  const handleSubmission = async () => {
    if (!forms.amount) return ErrorAlert('Specify the amount to invest')
    if (forms.error) return ErrorAlert('You have an error to fix before making a trade')
    if (forms.amount.length < 1) return ErrorAlert('You have an invalid purchase amount entry')
    if (forms.amount < plan.plans?.minDept) return ErrorAlert(`Purchasing amount must be up to ${NairaSign}${plan.plans?.minDept?.toLocaleString()}`)
    if (forms.amount > user.currbal) return ErrorAlert(`Unable to complete investment: Insufficient account balance`)
    const data = {
      stockid: plan.id,
      amount: parseFloat(forms.amount),
      tag: 'prime'
    }
    setOpen({
      status: true,
      data
    })
  }

  const handlePricing = val => {
    const amount = parseFloat(forms.amount)
    if (val.length > 0) {
      setActive(true)
      setDetails({
        capital: amount,
        returns: amount + (plan.plans?.percent / 100 * amount)
      })
    } else {
      setActive(false)
    }
  }


  const PurchaseWithBalance = async (val) => {
    setOpen({
      ...open, status: false
    })
    const formdata = {
      ...open.data,
      payMethod: val
    }
    setLoading(true)
    try {
      // generate an invoice
      const response = await Authposturl(Apis.investments.generate_plan_invoice, formdata)
      if (response.status === 200) {
        const pkdata = {
          invoice: response.msg
        }
        const res = await Authposturl(Apis.investments.purchase_investment, pkdata)
        if (res.status === 200) {
          setText(res.text)
          dispatch(dispatchPlan(res.stock))
          dispatch(dispatchUser(res.user))
          setForms({
            ...forms,
            amount: ''
          })
          setView(!view)
          SuccessAlert(res.msg)
          navigate(`/investment/${plan.id}/prime`)
        } else {
          ErrorAlert(res.msg)
        }
      } else {
        ErrorAlert(response.msg)
      }
    } catch (error) {
      Errors(error)
    } finally {
      setLoading(false)
    }
  }
  const PurchaseWithBank = async (val) => {
      setOpen({
          ...open, status: false
      })
      const formdata = {
          ...open.data,
          payMethod: val
      }
      setLoading(true)
      try {
          // generate an invoice
          const response = await Authposturl(Apis.investments.generate_plan_invoice, formdata)
          if (response.status === 200) {
              const url = window.location.origin
              const pkdata = {
                  url: `${url}/very-investment-purchase/${response.msg}/${plan.id}/prime`,
                  amount: open.data.amount,
              }
              const res = await Authposturl(Apis.ercas.initiate_account_deposit, pkdata)
              if (res.msg.requestSuccessful) {
                  window.location = res.msg.responseBody.checkoutUrl
              } else {
                  setLoading(false)
                  ErrorAlert(`${res.msg.responseMessage}`)
              }
          } else {
              setLoading(false)
              ErrorAlert(`${response.msg}`)
          }
      } catch (error) {
          setLoading(false)
          Errors(`${error.message}`)
      }
  }

  return (
    <UserLayout title="Trade Prime" navlink={`/investment/${plan.id}/prime`} >
      {open.status && <PaymentSelection
        SendInvestment={PurchaseWithBalance}
        PayWithBank={PurchaseWithBank}
        data={open.data}
        closeView={() => setOpen({ ...open, status: false })}
      />}
      {loading && <Loading />}
      <InvestLayout>
        {view && <Confirmation title="Buy Fund Successfully" texts={text} closeView={() => setView(!view)} />}
        <div className="w-11/12 mx-auto">
          <div className=" dark:text-slate-100">
            <div className="w-fit ml-auto mb-10">
              <button onClick={() => setScreen(!screen)} className={`capitalize py-3 px-5 rounded-lg text-sm shadow-xl ${!screen ? 'bg-main text-white' : 'bg-main text-white'}`}>{!screen ? 'Close' : 'investment history'}</button>
            </div>
            {screen && <>
              <div className="">
                <div>
                  <div className="flex items-center relative mt-5">
                    <SlWallet className='absolute top-2 left-2 text-2xl' />
                    <input type="text" value={forms.amount} onKeyUp={e => handlePricing(e.target.value)} onChange={e => setForms({ ...forms, amount: e.target.value })} className={`input !pl-10 dark:bg-slate-600 dark:placeholder:text-white ${forms.error ? '!border-red-500' : '!border-slate-500'}`} placeholder={`Enter Amount(${NairaSign})`} />
                    {forms.error ? <div className="text-xs text-red-600 absolute -bottom-6 left-0">{forms.message}</div> : null}
                  </div>
                  <div className="bg-red-50 border border-red-200 mt-6 p-3 rounded-lg">
                    <div className="text-sm grid grid-cols-2">
                      <div className="">Minimum Deposit</div>
                      <div className="text-right">{NairaSign}{plan.plans?.minDept?.toLocaleString()}</div>
                    </div>
                  </div>
                  {active && <div className="mt-10">
                    <div className="font-bold">Purchase Summary</div>
                    <div className="grid grid-cols-2 p-3 border-b">
                      <div className="">Investment Capital</div>
                      <div className="text-right"> {NairaSign} {details.capital?.toLocaleString()}</div>
                    </div>
                    <div className="grid grid-cols-2 p-3 border-b">
                      <div className="">Returns on investment</div>
                      <div className="text-right"> {NairaSign} {details.returns?.toLocaleString()}</div>
                    </div>
                    <div className="grid grid-cols-2 p-3 border-b">
                      <div className="">Profit in percentage</div>
                      <div className="text-right"> {plan.plans?.percent}%</div>
                    </div>
                  </div>}
                  <div className="w-3/6 mx-auto mt-7">
                    <Formbutton handleSubmission={handleSubmission} title='Proceed with payment method' font="uppercase text-base py-4" />
                  </div>
                </div>
              </div>
            </>}

            {!screen && <div className="">
              <PrimeHistory />
            </div>}
          </div>
        </div>
      </InvestLayout>
    </UserLayout>
  )
}


