
import UserLayout from 'components/user/UserLayout'
import { BanksLogo } from 'components/utils/Banks'
import { siteName, SuccessAlert } from 'components/utils/functions'
import Imaged from 'components/utils/Imaged'
import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { AiOutlineFieldNumber } from 'react-icons/ai'
import { BsBank, BsCopy } from 'react-icons/bs'
import { RiAccountPinCircleLine } from 'react-icons/ri'
import { useSelector } from 'react-redux'

export default function FundAccount() {
    const { user } = useSelector(state => state.data)
    return (
        <UserLayout title="Add Money">

            <div className="w-[98%] mx-auto">
                <div className="bg-white mb-5 p-3 h-fit rounded-lg w-[98%] mt-5 mx-auto text-sm md:text-base">
                    <div className="">
                        <div className="flex items-center gap-3 py-3 border-b border-dotted">
                            <div className='text-xl text-sub bg-sub/10 p-3 rounded-lg'><AiOutlineFieldNumber /></div>
                            <div>
                                <div className="text-slate-400">Quickdirect Account Number</div>
                                <div className="font-bold text-xl">{user.account?.account_number ?? `*************`}</div>
                            </div>
                        </div>
                        <div className="flex items-center gap-3 py-3 border-b border-dotted">
                            <div className='text-xl text-sub bg-sub/10 p-3 rounded-lg'><RiAccountPinCircleLine /></div>
                            <div>
                                <div className="text-slate-400">Bank</div>
                                <div className="font-bold text-xl">{user.account?.bank_name ?? `*************`}</div>
                            </div>
                        </div>
                        <div className="flex items-center gap-3 py-3 border-b border-dotted">
                            <div className='text-xl text-sub bg-sub/10 p-3 rounded-lg'><BsBank /></div>
                            <div>
                                <div className="text-slate-400">Account Name</div>
                                <div className="font-bold text-xl">{user.account?.account_name ?? `*************`}</div>
                            </div>
                        </div>
                       {user.account?.status && <div className="mt-5">
                            <CopyToClipboard
                                text={`${user.account?.account_name} \n ${user.account?.account_number} \n ${user.account?.bank_name}`}
                                onCopy={() => SuccessAlert("Account details copied")}
                            >
                                <div className="flex cursor-pointer items-center gap-2 bg-main text-white py-2 px-4 rounded-lg w-fit">
                                    <span>Copy Details</span> <BsCopy />
                                </div>
                            </CopyToClipboard>
                        </div>}
                    </div>
                </div>
                <div className="bg-white p-3 h-fit rounded-lg mb-5 w-[98%] mx-auto text-sm md:text-base">
                    <div className="font-bold mb-5">Add money via bank transfer in just 3 steps</div>
                    <div className="flex gap-2 mb-2"> <span className="text-sub font-bold">1</span> <span>Copy the account details above - <span className="text-sub">{user.account?.account_number}</span> is your {siteName} account number</span> </div>
                    <div className="flex gap-2 mb-2"> <span className="text-sub font-bold">2</span> <span>Open the bank app you want to transfer money from</span> </div>
                    <div className="flex gap-2 mb-2"> <span className="text-sub font-bold">3</span> <span>Transfer the details amount to your {siteName} account</span> </div>
                </div>
                <div className="">
                    <div className="font-bold text-base mb-3 mt-5">Fund your account with any of the following banks</div>
                    <div className="flex items-center gap-3 flex-wrap">
                        {BanksLogo.map((item, index) => (
                            <Imaged key={index} src={item.logo} className="h-8 w-auto" />
                        ))}
                        <div className="text-sub text-sm">And more....</div>
                    </div>
                </div>
            </div>
        </UserLayout>
    )
}
