
import { NairaSign } from 'components/utils/functions'
import ModalLayout from 'components/utils/ModalLayout'

export default function ServiceTransactionsModal ({closeView, data}) {
  return (
    <ModalLayout closeView={closeView}>
        <div className={` p-4 border-l-8 pt-10  ${data.status === 'Failed' ? 'border-red-500 bg-red-50/70' : 'border-green-500 bg-green-50/70'}`}>
            <div className="grid grid-cols-2 gap-5 p-2">
                <div className="font-bold">User:</div>
                <div className="text-right ">{data.suser?.firstname} {data.suser?.lastname}</div>
            </div>
            <div className="grid grid-cols-2 gap-5 p-2">
                <div className="font-bold">Purchase:</div>
                <div className="text-right ">{data.action === 'ELE' ? 'Electricity' : data.action}</div>
            </div>
            <div className="grid grid-cols-2 gap-5 p-2">
                <div className="font-bold">Status:</div>
                <div className="text-right ">{data.status}</div>
            </div>
            <div className="grid grid-cols-2 gap-5 p-2">
                <div className="font-bold">Amount:</div>
                <div className="text-right ">{NairaSign}{data.amount?.toLocaleString()}</div>
            </div>
            <div className="grid grid-cols-2 gap-5 p-2">
                <div className="font-bold">Pre. Balance:</div>
                <div className="text-right ">{NairaSign}{data.prebal?.toLocaleString()}</div>
            </div>
            <div className="grid grid-cols-2 gap-5 p-2">
                <div className="font-bold">Post Balance:</div>
                <div className="text-right ">{NairaSign}{data.postbal?.toLocaleString()}</div>
            </div>
            <div className="grid grid-cols-2 gap-5 p-2">
                <div className="font-bold">Payment Method:</div>
                <div className="text-right uppercase">{data.paymethod}</div>
            </div>
           {data.cashback && <div className="grid grid-cols-2 gap-5 p-2">
                <div className="font-bold">Cashback:</div>
                <div className="text-right ">{NairaSign}{data.cashback}</div>
            </div>}
           {data.token && <div className="grid grid-cols-2 gap-5 p-2">
                <div className="font-bold">Token:</div>
                <div className="text-right ">{data.token}</div>
            </div>}
            <div className="grid grid-cols-2 gap-5 p-2">
                <div className="font-bold">Description:</div>
                <div className="text-sm text-right">{data.desc}</div>
            </div>
            <div className="grid grid-cols-2 gap-5 p-2">
                <div className="font-bold">Api Response:</div>
                <div className="text-sm text-right">{data.response}</div>
            </div>
            <div className="grid grid-cols-2 gap-5 p-2">
                <div className="font-bold">Email Address:</div>
                <div className="text-sm text-right">{data.suser?.email}</div>
            </div>
            <div className="grid grid-cols-2 gap-5 p-2">
                <div className="font-bold">Phone Number:</div>
                <div className="text-sm text-right">{data.suser?.dailcode}{data.suser?.phone}</div>
            </div>
            <div className="grid grid-cols-2 gap-5 p-2 break-words">
                <div className="font-bold">Reference:</div>
                <div className="text-sm text-right">{data.ref}</div>
            </div>
            <div className="grid grid-cols-2 gap-5 p-2 break-words">
                <div className="font-bold">Date Initiated:</div>
                <div className="text-sm text-right">{data.date} {data.time}</div>
            </div>
        </div>
    </ModalLayout>
  )
}

