import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { Apis, Authgeturl } from 'components/Apis'
import { useParams } from 'react-router-dom'
import { Errors } from 'components/utils/functions'
import ALayout from 'components/admin/ALayout'
import FeedForm from './FeedForm'

const EditFeed = () => {
    const { id } = useParams()
    const [loading, setLoading] = useState(false)
    const [item, setItem] = useState({})

    const FetchSingleFeed = useCallback(async () => {
        setLoading(true)
        try {
            const res = await Authgeturl(`${Apis.feeds.single_feed}/${id}`)
            setLoading(false)
            if(res.status === 200) return setItem({...res.msg})
        } catch (error) {
            setLoading(false)
            Errors(error)
        }
    }, [])
    useEffect(() => {
        FetchSingleFeed()
    }, [FetchSingleFeed]) 
    return (
        <ALayout>
            {loading && <div>Loading...</div> }
          {!loading && <FeedForm feed={item} /> }
        </ALayout>
    )
}

export default EditFeed