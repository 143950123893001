import React, { useEffect, useRef, useState } from 'react'
import { Apis, Authgeturl, Authputurl } from 'components/Apis'
import { useDispatch } from 'react-redux'
import { dispatchAlerts, dispatchPlans, dispatchServices, dispatchUser } from 'app/reducer'
import { useNavigate } from 'react-router-dom'
import { viewBal } from 'components/utils/functions'
import { useQuery } from '@tanstack/react-query'
import { Box, LinearProgress } from '@mui/material'

const UserPrivacy = ({ children }) => {
  const dispatch = useDispatch()
  const [login, setLogin] = useState(false)
  const navigate = useNavigate()
  const [inactive, setInactive] = useState(false);
  const inactiveCount = useRef(0)
  const activeCount = useRef(0)
  const [loads, setLoads] = useState(true)

   useQuery({
    queryKey: [`user-layout`],
    queryFn: async () => {
      const local = JSON.parse(localStorage.getItem(viewBal))
      if (!local) {
        localStorage.setItem(viewBal, JSON.stringify('true'))
      }
      setLogin(true)
      const res = await Authgeturl(Apis.user.profile)
      const resInvest = await Authgeturl(Apis.investments.all_plans)
      const noteRes = await Authgeturl(Apis.notify.my_notifies)
      const servRes = await Authgeturl(Apis.ncwallet.all_services)
      if (res.status === 200) {
        if (res.msg.role === 'user') {
          dispatch(dispatchUser(res.msg))
          dispatch(dispatchPlans(resInvest.msg))
          dispatch(dispatchAlerts(noteRes))
          dispatch(dispatchServices(servRes.data))
        } else {
          navigate('/login')
        }
        return res.msg
      } else {
        setLogin(false)
        navigate('/login')
      }
    }
  })

  const timeout = useRef(null)

  // useEffect(() => {
  //   timeout.current = setTimeout(async () => {
  //     setInactive(true);
  //     inactiveCount.current += 1
  //     activeCount.current = 0
  //     if(inactiveCount.current < 2) {
  //         const data = {
  //           status: "offline"
  //         }
  //          await Authputurl(Apis.user.change_activity_status, data)
  //     }
  //   }, 50000); // 5 minutes

  //   document.addEventListener('mousemove', resetTimeout);
  //   document.addEventListener('keypress', resetTimeout);

  //   return () => {
  //     clearTimeout(timeout);
  //     document.removeEventListener('mousemove', resetTimeout);
  //     document.removeEventListener('keypress', resetTimeout);
  //   };
  // }, [inactive]);

  const resetTimeout = async () => {
    setInactive(false);
    activeCount.current += 1
    inactiveCount.current = 0
    if(activeCount.current < 2) {
        const data = {
          status: "online"
        }
         await Authputurl(Apis.user.change_activity_status, data)
    }
    clearTimeout(timeout);
    setTimeout(() => {
      setInactive(true);
    }, 50000); // 5 minutes
  };

  React.useLayoutEffect(() => {
    (() => {
      setTimeout(() => {
        setLoads(false)
      }, 3000);
    })()
  }, [])

  if (loads) return (
    <div>
        <div className="flex items-center h-screen">
            <div className="hidden lg:block w-[23%] bg-white border-r h-full pt-10">
                    <div className="bg-slate-200 h-24 w-24 mb-10 mx-auto rounded-full"></div>
                {new Array(5).fill(0).map((ele, index) => (
                    <div className="bg-slate-200 h-14 mb-2 w-11/12 mx-auto rounded-lg" key={index}></div>
                ))}
            </div>
            <div className="w-full ml-auto bg-slate-100 h-screen">
                <div className="flex items-center justify-between bg-white p-3">
                    <div className="">
                        <div className="w-10 h-10 bg-slate-200 rounded-lg"></div>
                    </div>
                    <div className="flex items-center gap-2">
                        <div className="w-10 h-10 bg-slate-200 rounded-lg"></div>
                        <div className="w-10 h-10 bg-slate-200 rounded-lg"></div>
                        <div className="w-10 h-10 bg-slate-200 rounded-lg"></div>
                    </div>
                </div>
                <div className="h-[91.1dvh] flex items-center w-4/5 max-w-xl mx-auto justify-center pb-10 overflow-y-auto">
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                </div>
            </div>
        </div>
    </div>
)
  if (login) return children
}

export default UserPrivacy