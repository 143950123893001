
import React from 'react'
import Lottie from 'lottie-react'
import SuccessLogo from "assets/new/Success.json"
import GiftLogo from "assets/new/Gift.json"
import ModalLayout from './ModalLayout'
import { BsDownload, BsShareFill } from 'react-icons/bs'
import { ErrorAlert, SuccessAlert } from './functions'
import { Link } from 'react-router-dom'


export default function SuccessModal(props) {
  function ShareData() {
    navigator.share({
      title: props.title,
      text: props.content2,
    })
     .then(() => SuccessAlert('Share was successful.'))
     .catch((error) => ErrorAlert(`${error}`));
  }

  return (
    <ModalLayout closeView={props.closeview}>
      <div className="bg-slate-50">
        <div className="w-11/12 max-w-2xl mx-auto py-5 px-2">
          <div className="">
            <Lottie
              animationData={SuccessLogo}
              className="w-auto h-[12rem]"
              loop={true}
            />
          </div>
          <div className="text-center text-2xl font-extrabold text-green-600">{props.title}</div>
          <div className="text-center text-slate-500 text-sm mb-5 font-medium" dangerouslySetInnerHTML={{ __html: props.content }} />
          <div className="flex mt-3 items-center justify-center text-xs md:text-sm gap-3">
            <button onClick={ShareData} className="bg-white p-2 rounded-lg flex items-center gap-3">
              <div className="bg-blue-100/70 text-lg p-3 rounded-lg text-blue-500"> <BsShareFill /> </div>
              <div className="truncate">Share receipt</div>
            </button>
            <button onClick={props.DownloadPDF} className="bg-white p-2 rounded-lg flex items-center gap-3">
              <div className="bg-blue-100/70 text-lg p-3 rounded-lg text-blue-500"> <BsDownload /> </div>
              <div className="truncate">Download receipt</div>
            </button>
          </div>
          {props.cashback && <div className="text-green-900 bg-green-100/70 mt-5 py-3 px-2 rounded-lg text-sm">
            <div className="flex items-center gap-2">
            <div>
            <Lottie
              animationData={GiftLogo}
              className="w-auto h-[5rem]"
              loop={true}
            />
            </div>
              <div className="border-b border-green-300 py-2">Congratulations, you have earned {props.cashback} Data reward on your transaction</div>
            </div>
          </div>}

          <Link to={props.Dismiss}>
          <div className="text-cyan-600 bg-cyan-100 text-center py-3 px-4 rounded-lg font-bold mt-5">Dismiss</div>
          </Link>
        </div>
      </div>
    </ModalLayout>
  )
}
