
import { FaCheck } from 'react-icons/fa'
import React, { useEffect, useRef, useState } from 'react'

export default function FilterByDiscount({shortlets, Signal}) {
    const dataArr = []
    const [active, setActive] = useState('all')

    const ToggleDiscounts = val => {
        if(val === 'all') {
            setActive('all')
            return Signal(shortlets)
        }
        const output = []
        shortlets.map(ele => {
            if(ele.discount === val.discount) {
                output.push(ele)
            }
        })
        Signal(output)
        setActive(val.discount)
    }
    
    
    useEffect(() => {
        const setups = () => {
            let dataId = 0
            shortlets.map((item) => {
                return dataArr.push({ id: dataId++, discount: item.discount })
            })
            const unique2 = dataArr.filter((obj, index) => {
                return index === dataArr.findIndex(o => obj.discount === o.discount);
            });
        }
        setups()
    }, [])

    const handleDuplicates = () => {
        let dataId = 0
        shortlets.map((item) => {
            return dataArr.push({ id: dataId++, discount: item.discount})
        })
        const unique2 = dataArr.filter((obj, index) => {
            return index === dataArr.findIndex(o => obj.discount === o.discount);
        });
        return unique2.map((item, i) => {
            return (
                <div className="py-2" key={i}>
                    <div onClick={() => ToggleDiscounts(item)} className="flex items-center gap-2 cursor-pointer">
                        <div className={`w-5 h-5 border rounded-md flex items-center justify-center ${active === item.discount ? 'bg-mainblue text-white' : 'text-slate-500 border-slate-400'}`}> {active === item.discount ? <FaCheck className='text-[0.8rem]' /> : null} </div>
                        <div className="text-[0.9rem] font-medium capitalize">{item.discount}%</div>
                    </div>
                </div>
            )
        })
    }

    return (
        <div>
            <div className="mt-3">
                <div className="py-2">
                    <div onClick={() => ToggleDiscounts('all')} className="flex items-center gap-2 cursor-pointer">
                        <div className="">
                            <div className={`w-5 h-5 border rounded-md flex items-center justify-center ${active === 'all' ? 'bg-mainblue text-white' : 'text-slate-500 border-slate-400'}`}> {active.length < 1 ? <FaCheck className='text-[0.9rem]' /> : null} </div>
                        </div>
                        <div className="text-[0.8rem] font-medium">{'All'}</div>
                    </div>
                    <div className="text-right text-[0.8rem] font-medium"> </div>
                </div>
                {handleDuplicates()}
            </div>
        </div>
    )
}
