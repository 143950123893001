import { useState } from "react";
import ModalLayout from "components/utils/ModalLayout";
import Formbutton from "components/utils/Formbutton";
import { ErrorAlert, SuccessAlert } from "components/utils/functions";
import { Apis, Authposturl } from "components/Apis";
import Forminput from "components/utils/Forminput";


export default function ManagePin({ closeView }) {
    const [screen, setScreen] = useState(1)
    const [loading, setLoading] = useState(false)
    const [text, setText] = useState('')
    const [forms, setForms] = useState({
        code: '',
        pin: ''
    })
    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }

    const sendVerificationMail = async () => {
        setLoading(true)
        try {
            const response = await Authposturl(Apis.user.send_pin_verification)
            if (response.status === 200) {
                setScreen(2)
                setText(response.msg)
            } else {
                ErrorAlert(`${response.msg}`)
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoading(false)
        }
    }

    const SubmitRequest = async (e) => {
        e.preventDefault()
        if(!forms.code) return ErrorAlert('Provide an account verification code')
        if(!forms.pin) return ErrorAlert('Provide your new account pin')
        setLoading(true)
        try {
            const response = await Authposturl(Apis.user.verify_and_update_pin, forms)
            if (response.status === 200) {
                SuccessAlert(response.msg)
                closeView()
            } else {
                ErrorAlert(`${response.msg}`)
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoading(false)
        }
    }
    return (
        <ModalLayout closeView={closeView}>
            <div className="">
                <div className="border-b p-4 mb-3">Change Account Pin</div>
            </div>
           {text && <div className="border border-green-600/50 bg-green-200/40 mb-3 text-green-700 rounded-lg w-11/12 mx-auto text-sm p-2">{text} </div>}
            {screen === 2 && <>
                <form className="w-11/12 mx-auto" onSubmit={SubmitRequest}>
                    <div className="mb-3">
                        <div className="">Provide Code sent to your mail</div>
                        <Forminput formName="code" value={forms.code} onChange={handleForms} label={'Verification code'} />
                    </div>
                    <div className="mb-3">
                        <div className="">Provide your new Account Pin</div>
                        <Forminput formName="pin" value={forms.pin} onChange={handleForms} label={'Account Pin'} min={1} max={6} />
                    </div>
                    <div className="my-5">
                        <Formbutton title="Confirm changes" loading={loading} />
                    </div>
                    <div className="text-sm text-zinc-500 mb-5">These are one of the ways we ensure your account is safe from third parties and fraudulent users</div>
                </form>
            </>}
            {screen === 1 && <>
                <div className="w-11/12 mx-auto">
                    <div className="text-base font-bold">Verify your email address before proceeding to update your account pin</div>
                    <div className="my-5">
                        <Formbutton title="Verify Email" loading={loading} handleSubmission={sendVerificationMail} />
                    </div>
                    <div className="text-sm text-zinc-500 mb-5">These are one of the ways we ensure your account is safe from third parties and fraudulent users</div>
                </div>
            </>}
        </ModalLayout>
    )
}