import React, { useState } from 'react'
import SideViewModalLayout from 'components/utils/SideViewModalLayout'
import { ErrorAlert, Errors, NairaSign, SuccessAlert } from 'components/utils/functions'
import { Link } from 'react-router-dom'
import Formbutton from 'components/utils/Formbutton'
import { Apis, Authposturl } from 'components/Apis'

const PlanModal = ({ closeView, plan, refetch }) => {
    const [loading, setLoading] = useState(false)

    const handleDeleting = async () => {
        setLoading(true)
        try {
            const res = await Authposturl(Apis.investments.delete_plan, {id: plan.id})
            setLoading(false)
            if(res.status === 200) {
                SuccessAlert(res.msg)
                refetch()
            }else {
                ErrorAlert(res.msg)
            }
        } catch (error) {
            Errors(error)
        }
    }
    return (
        <SideViewModalLayout closeView={closeView}>
            <div className="">
                <div className="">
                    <div className="bg-main text-blue-100 capitalize rounded-br-[50rem] p-5">
                        <div className="text-sm w-5/6">{plan.title}</div>
                    </div>
                    <div className="p-2">
                        <div className="grid grid-cols-2 p-2">
                            <div className="text-sm capitalize">percentage Returns</div>
                            <div className="text-sm text-right">{plan.percent}%</div>
                        </div>
                        <div className="grid grid-cols-2 p-2">
                            <div className="text-sm capitalize">min. Dept.</div>
                            <div className="text-sm text-right">{NairaSign}{plan.minDept?.toLocaleString()} </div>
                        </div>
                        <div className="grid grid-cols-2 p-2">
                            <div className="text-sm capitalize">max. Dept.</div>
                            <div className="text-sm text-right">{NairaSign}{plan.maxDept?.toLocaleString()} </div>
                        </div>
                        <div className="grid grid-cols-2 p-2">
                            <div className="text-sm capitalize">min. Withd.</div>
                            <div className="text-sm text-right">{NairaSign}{plan.minWithd?.toLocaleString()} </div>
                        </div>
                        <div className="grid grid-cols-2 p-2">
                            <div className="text-sm capitalize">max. Withd.</div>
                            <div className="text-sm text-right">{plan.maxWithd}% </div>
                        </div>
                        <div className="grid grid-cols-2 p-2">
                            <div className="text-sm capitalize">return capital</div>
                            <div className="text-sm text-right"> {plan.returnCapital} </div>
                        </div>
                        <div className="grid grid-cols-2 p-2">
                            <div className="text-sm capitalize">Referral commission</div>
                            <div className="text-sm text-right">{plan.refbonus}% </div>
                        </div>
                        <div className="grid grid-cols-2 mt-5 mb-4">
                            <div className="">
                                <Formbutton 
                                bg="bg-red-600"
                                loading={loading}
                                handleSubmission={handleDeleting}
                                title="delete"
                                />
                            </div>
                            <Link to={`/admin/plans/edit/${plan.slug}/${plan.id}`} className='bg-slate-200 rounded-lg capitalize text-center py-3 px-4 w-fit ml-auto'>edit plan</Link>
                        </div>
                    </div>
                </div>
            </div>
        </SideViewModalLayout>
    )
}

export default PlanModal