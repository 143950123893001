import React from 'react'
import SideViewModalLayout from 'components/utils/SideViewModalLayout'
import ShortletFilter from './ShortletFilter'

const MobileFilter = ({ closeView, shortlets, stats, Signal }) => {
    return (
        <SideViewModalLayout closeView={closeView} side="left">
            <div className="overflow-x-hidden pt-10 px-3">
                <ShortletFilter
                stats={stats}
                Signal={Signal}
                border={false}
                    shortlets={shortlets}
                />
            </div>
        </SideViewModalLayout>
    )
}

export default MobileFilter