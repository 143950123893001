
import React, { useState } from 'react'
import Forminput from 'components/utils/Forminput'
import { ErrorAlert, Errors, InvestSlots, NairaSign, SuccessAlert } from 'components/utils/functions'
import Formbutton from 'components/utils/Formbutton'
import { useDispatch, useSelector } from 'react-redux'
import { dispatchPlan, dispatchUser } from 'app/reducer'
import { Apis, Authposturl } from 'components/Apis'
import ModalLayout from 'components/utils/ModalLayout'
import BankOptions from 'components/utils/BankOptions'

export default function WithdrawOrder({ closeView }) {
  const [loading, setLoading] = useState(false)
  const { plan } = useSelector(state => state.data)
  const dispatch = useDispatch()
  const [single, setSingle] = useState({title: '', amount: 0})
  const [forms, setForms] = useState({
    tag: '',
    bankname: '',
    accname: '',
    accnumber: '',
    amount: '',
    options: 'bank'
  })

  const handleForms = e => {
    setForms({ ...forms, [e.target.name]: e.target.value })
  }


  const SpendCash = async () => {
    if (plan.plans?.title === InvestSlots.istock && !forms.tag) return ErrorAlert('Select where you want to withdraw from')
    if (!forms.bankname) return ErrorAlert('Select your preferred bank')
    if (!forms.accname) return ErrorAlert('provide account name')
    if (!forms.accnumber) return ErrorAlert('provide account number')
    if (!forms.amount) return ErrorAlert('provide amount to withdraw')
    if (forms.amount < 1) return ErrorAlert(`provide a valid withdrawable amount`)
    if (plan.plans?.title !== InvestSlots.istock && forms.amount > plan.totalEarned) return ErrorAlert(`Insufficient funds`)
    if (plan.plans?.title === InvestSlots.istock && forms.amount > single.amount) return ErrorAlert(`Insufficient ${single.title}`)
    const data = {
      ...forms,
      walletid: plan.id
    }
    setLoading(true)
    try {
      const res = await Authposturl(Apis.investments.open_withdraw_order, data)
      if (res.status === 200) {
        SuccessAlert(res.msg)
        dispatch(dispatchPlan(res.wallet))
        dispatch(dispatchUser(res.user))
        return closeView()
      } else {
        ErrorAlert(res.msg)
      }
    } catch (error) {
      Errors(error)
    } finally {
      setLoading(false)
    }
  }
  const setup = val => {
    setForms({
      ...forms,
      bankname: val.bank_name
    })
  }

  const handleSelection = e => {
    const value = e.target.value
    setForms({...forms, tag: value})
    if(value === 'balance') {
        setSingle({
            title: plan.plans?.balanceName,
            amount: plan.balance
        })
    }else if(value === 'profit') {
        setSingle({
            title: plan.plans?.profitName,
            amount: plan.profit
        })
    }else {
        setSingle({title: '', amount: 0})
    }
}

  return (
    <ModalLayout closeView={closeView}>
      <div className='mb-6 rounded-lg p-3'>
        <div className="font-bold">Open a withdrawal order</div>
      {plan.plans?.title !== InvestSlots.istock &&  <div className="text-sm text-zinc-500">Available Profit {NairaSign}{plan.totalEarned?.toLocaleString()} </div>}
        <div className='mb-10'></div>
        {plan.plans?.title === InvestSlots.istock && <div className="mb-3">
          <div className="text-sm">Where do you want to withdraw from</div>
          <Forminput
          formName={'tag'}
            value={forms.tag}
            onChange={handleSelection}
            formtype='select'
          >
            <option value="balance">{plan.plans?.balanceName}</option>
            <option value="profit">{plan.plans?.profitName}</option>
          </Forminput>
        {single.title &&  <div className='flex items-center w-11/12 mx-auto -mt-5 mb-5 justify-between gap-5 text-sm text-slate-500'>{single.title}: <span className='text-black font-semibold text-lg'>{NairaSign}{single.amount?.toLocaleString()}</span> </div>}
        </div>}
        <div className="mb-4 relative">
          <div className="">Commercial Institution</div>
          <BankOptions defaultValue={forms.bankname} title="--Select--" setup={setup} />
        </div>
        <div className="mb-3">
          <div className="text-sm">Account Holder Name</div>
          <Forminput
            value={forms.accname}
            formName={'accname'}
            onChange={handleForms}
          />
        </div>
        <div className="mb-3">
          <div className="text-sm">Account Number</div>
          <Forminput
            value={forms.accnumber}
            formName={'accnumber'}
            onChange={handleForms}
          />
        </div>
        <div className="mb-3">
          <div className="text-sm">Amount to withdraw ({NairaSign})</div>
          <Forminput
            value={forms.amount}
            onChange={handleForms}
            formName={'amount'}
            label={'0.00'}
            type="number"
          />
        </div>
        <Formbutton
          handleSubmission={SpendCash}
          loading={loading}
          title={'withdraw'}
        />
      </div>
    </ModalLayout>
  )
}

