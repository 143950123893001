

import React, { useCallback, useEffect, useState } from 'react'
import ALayout from 'components/admin/ALayout'
// import ShortletModal from './ShortletModal';
import { Apis, Authgeturl } from 'components/Apis';
import { Errors, NairaSign } from 'components/utils/functions';
import AHeader from 'components/admin/AHeader';
import moment from 'moment';
import KycModal from './KycModal';

const Tableheaders = [
    "User",
    "Status",
    "date created",
    ""
]

export default function AllPManageKycUploadsickups() {
    const [plans, setPlans] = useState([])
    const [loading, setLoading] = useState(false)
    const [single, setSingle] = useState({})
    const [view, setView] = useState(false)

    const FetchAllPlans = useCallback(async () => {
        try {
            const res = await Authgeturl(Apis.user.all_kyc_uploads)
            if (res.status === 200) return setPlans(res.msg)
        } catch (error) {
            Errors(`${error}`)
        } finally {
            setLoading(false)
        }
    }, [])
    useEffect(() => { FetchAllPlans() }, [FetchAllPlans])

    const handleSingle = val => {
        setSingle(val)
        setView(!view)
    }
    return (
        <ALayout>
            {view && <KycModal
                item={single}
                closeView={() => setView(!view)}
                refetch={() => { FetchAllPlans(); setView(!view) }}
            />}
            <div className="">
                <AHeader
                    title="pickup requests"
                    total={plans.length}
                    url={false}
                />
                <div className="mt-10">
                    {!loading && <table className='table table-auto w-full bg-white p-3 text-sm'>
                        <thead>
                            <tr className='bg-slate-700 text-white'>
                                {Tableheaders.map((ele, i) => (
                                    <td className='capitalize p-3' key={i}>{ele}</td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {plans.length > 0 && plans.map((item, i) => (
                                <tr
                                    key={i}
                                    className='cursor-pointer border-b border-r border-l'
                                    onClick={() => handleSingle(item)}
                                >
                                    <td className='p-3 uppercase'>{item.acc?.firstname} {item.acc?.lastname}</td>
                                    <td className='p-3 uppercase'>{item.status}</td>
                                    <td className='p-3'>{moment(item.createdAt).format('DD-MM-YYYY hh:ss A')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>}
                </div>
            </div>
        </ALayout>
    )
}

