import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FaTimes } from 'react-icons/fa'
import { SlArrowDown, SlArrowUp } from 'react-icons/sl'
import { useSelector } from 'react-redux'

const links = [
    {
        title: 'dashboard',
        role: ['admin', 'superadmin'],
        subs: [
            {
                title: 'home',
                url: '/admin',
            },
            {
                title: 'WP virtual accounts',
                url: '/admin/virtual_accounts',
            },
            {
                title: 'WP Settings',
                url: '/admin/wp-settings',
            }
        ]
    },
    // {
    //     title: 'Service API',
    //     role: ['superadmin'],
    //     subs: [
    //         {
    //             title: 'Api Wallet History',
    //             url: '/admin/api-wallet-history',
    //         }
    //     ]
    // },
    {
        title: 'Shareholdings',
        role: ['superadmin'],
        subs: [
            {
                title: 'manage coin asset',
                url: '/admin/asset',
            },
            {
                title: 'share class',
                url: '/admin/share/class',
            },
            {
                title: 'shares requests',
                url: '/admin/share/requests',
            },
            {
                title: 'manage shares asset',
                url: '/admin/share/asset',
            },
            {
                title: 'manage shareholdings',
                url: '/admin/share/holders',
            },
        ]
    },
    {
        title: 'management',
        role: ['admin', 'superadmin'],
        subs: [
            {
                title: 'Assign Admins',
                url: '/admin/assign-users',
            },
            {
                title: 'Departments',
                url: '/admin/manage-departments',
            },
        ]
    },
    {
        title: 'workshops',
        role: ['admin', 'superadmin'],
        subs: [
            {
                title: 'investment plans',
                url: '/admin/plans',
            },
            {
                title: 'account plans',
                url: '/admin/account-plans',
            },
            {
                title: 'newsfeeds',
                url: '/admin/feeds',
            },
            {
                title: 'shortlets',
                url: '/admin/shortlets',
            },
            {
                title: 'categories',
                url: '/admin/categories',
            },
            {
                title: 'services',
                url: '/admin/services',
            },
            {
                title: 'ncwallet services',
                url: '/admin/ncwallet-services',
            },
            {
                title: 'Data Rewards',
                url: '/admin/data-rewards',
            },
            {
                title: 'Airtime Cashbacks',
                url: '/admin/airtime-cashbacks',
            },
            {
                title: 'Investment managers',
                url: '/admin/investment_managers',
            },
        ]
    },
    {
        title: 'histories',
        role: ['admin', 'superadmin'],
        subs: [
            {
                title: 'users',
                url: '/admin/all-users',
            },
            {
                title: 'All Withdrawals',
                url: '/admin/all-withdrawals',
            },
            {
                title: 'All Deposits',
                url: '/admin/all-deposits',
            },
            {
                title: 'Investment withdrawal requests',
                url: '/admin/withdrawal-orders',
            },
            {
                title: 'Account withdrawal requests',
                url: '/admin/withdrawal-requests',
            },
            {
                title: 'pickup details',
                url: '/admin/pickups',
            },
            {
                title: 'service transactions',
                url: '/admin/transactions/services',
            },
            {
                title: 'cashbacks withdrawals',
                url: '/admin/transactions/cashbacks',
            },
            {
                title: 'investments',
                url: '/admin',
            },
            {
                title: 'feedbacks',
                url: '/admin',
            },
            {
                title: 'kyc uploads',
                url: '/admin/manage-kyc',
            },
        ]
    },
    {
        title: 'settings',
        role: ['admin', 'superadmin'],
        subs: [
            {
                title: 'Partners Logo',
                url: '/admin/partners-logo',
            },
            {
                title: 'Chat Support',
                url: '/admin/chat-support',
            },
            {
                title: 'profile',
                url: '/admin/profile',
            },
            {
                title: 'send notifications',
                url: '/admin/send-notification',
            },
            {
                title: 'bulk emails',
                url: '/admin',
            },
        ]
    },
]
const ASidebar = ({ handleToggle }) => {
    const [active, setActive] = useState('')
    const {user} = useSelector(state => state.data)
    
    const handleActive = val => {
        if(active !== val) {
            setActive(val)
        }else {
            setActive('')
        }
    }
    return (
        <div>
            <div className="relative h-screen py-10 overflow-x-hidden overflow-y-auto bg-slate-700 scrolls lefts">
                <div onClick={handleToggle} className="absolute p-2 text-xl text-white border rounded-lg cursor-pointer lg:hidden top-3 right-3 w-fit">
                    <FaTimes />
                </div>
                <div className="flex flex-col items-start mt-10">
                    {links.map((data, i) => (
                     data.role.includes(user.role) &&    <div className="w-full mb-2" key={i}>
                            <div 
                            onClick={() => handleActive(i)}
                            className="px-3 py-2.5 font-medium capitalize mb-2 text-slate-300 cursor-pointer hover:bg-slate-600 flex items-center justify-between">
                                <div>{data.title}</div>
                               {active !== i ? <SlArrowDown /> : <SlArrowUp />}
                            </div>
                           {active === i && <div className="flex flex-col bg-slate-600 w-11/12 mx-auto items-center">
                                {data.subs.map((item, k) => (
                                 <Link to={`${item.url}`} className='w-full px-5 py-2 text-sm capitalize text-slate-100 hover:bg-slate-500' key={k}>{item.title}</Link>
                                ))}
                            </div>}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ASidebar