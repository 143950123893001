


import React, { useState } from 'react'
import UserLayout from 'components/user/UserLayout'
import img from 'assets/images/img10.png'
import { useNavigate, useParams } from 'react-router-dom'
import { SlWallet } from 'react-icons/sl'
import Formbutton from 'components/utils/Formbutton'
import { ErrorAlert, Errors, NairaSign, SuccessAlert } from 'components/utils/functions'
import { useDispatch, useSelector } from 'react-redux'
import StockDetails from '../../Compos/StockDetails'
import BuyHistory from '../../Compos/BuyHistory'
import { dispatchPlan } from 'app/reducer'
import { Apis, Authposturl } from 'components/Apis'
import Confirmation from '../../Compos/Confirmation'
import InvestLayout from '../../Compos/InvestLayout'
import Imaged from 'components/utils/Imaged'

export default function TradeIstock() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { plan } = useSelector(state => state.data)
    const [text, setText] = useState('')
    const [view, setView] = useState(false)
    const [screen, setScreen] = useState(true)
    const [forms, setForms] = useState({
        quantity: '',
        error: false,
        message: '',
    })
    const [amount, setAmount] = useState('')

    const handleSubmission = async () => {
        if (!forms.quantity) return ErrorAlert('Specify the quantity of stock to purchase')
        if (forms.error) return ErrorAlert('You have an error to fix before purchasing a stock')
        if (amount < 1) return ErrorAlert('You have an invalid purchase amount entry')
        const data = {
            stockid: plan.id,
            amount: parseFloat(amount),
            quantity: parseFloat(forms.quantity),
            tag: 'istock'
        }
        setLoading(true)
        try {
            const res = await Authposturl(Apis.investments.purchase_stock, data)
            if (res.status === 200) {
                setText(res.text)
                dispatch(dispatchPlan(res.stock))
                setAmount('')
                setForms({
                    ...forms,
                    quantity: ''
                })
                setView(!view)
                SuccessAlert(res.msg)
                // navigate(`/investment/${plan.id}/istock`)
            } else {
                ErrorAlert(`${res.msg}`)
            }
        } catch (error) {
            Errors(`${error}`)
        } finally {
            setLoading(false)
        }
    }

    const handleAmount = val => {
        const qyt = parseInt(val)
        if (val.length > 0) {
            const sum = parseInt(qyt * plan.plans.unitPrice)
            const cap = parseInt(plan.stockCards)
            setAmount(sum)
            if (qyt <= cap) {
                if (sum <= plan.balance) {
                    setForms({
                        ...forms,
                        error: false,
                    })
                } else {
                    setForms({
                        ...forms,
                        error: true,
                        message: `Insufficient Balance`
                    })
                }
            } else {
                setForms({
                    ...forms,
                    error: true,
                    message: `maximum quantity of stocks to purchase exceeded`
                })
            }
        } else {
            setAmount(0)
            setForms({
                ...forms,
                error: false
            })
        }
    }
    return (
        <UserLayout title="Trade Istock Funds" paddings={false} navlink={`/investment/${plan.id}/istock`}>
            <InvestLayout>
                {view && <Confirmation title="Buy Fund Successfully" texts={text} closeView={() => setView(!view)} />}
                <div className="w-11/12 mx-auto">
                <div className=" dark:text-slate-100 mt-10">
                    <div className="mb-10 ml-auto w-fit">
                        <button onClick={() => setScreen(!screen)} className={`capitalize py-3 px-5 rounded-lg text-sm shadow-xl bg-main text-white`}>{!screen ? 'Close' : 'stock history'}</button>
                    </div>
                    {screen && <>
                        <div className="p-3 border rounded-lg bg-slate-100 dark:bg-slate-600">
                            <div className="grid grid-cols-5">
                                <div className="col-span-3">
                                    <div className="text-sm">unit price per fund</div>
                                    <div className="text-xl font-medium">{NairaSign}{parseFloat(plan.plans?.unitPrice)?.toLocaleString()}</div>
                                    <div className="text-sm">number of fund cards</div>
                                    <div className="text-xl font-medium">{plan.stockCards}</div>
                                </div>
                                <div className="col-span-2">
                                    <Imaged src={img} alt="" className="w-full" />
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div>
                                <div className="relative flex items-center mt-5">
                                    <SlWallet className='absolute text-2xl top-2 left-2' />
                                    <input type="text" value={forms.quantity} onKeyUp={e => handleAmount(e.target.value)} onChange={e => setForms({ ...forms, quantity: e.target.value })} className={`input !pl-10 dark:bg-slate-600 dark:placeholder:text-white ${forms.error ? '!border-red-500' : '!border-slate-500'}`} placeholder='Purchase quantity' />
                                    {forms.error ? <div className="absolute left-0 text-xs text-red-600 -bottom-6">{forms.message}</div> : null}
                                </div>
                                <div className="grid grid-cols-2 pt-6 pb-4 mt-3">
                                    <div className="border-r">
                                        <div className="text-base text-center">{amount?.toLocaleString() || 0}</div>
                                        <div className="text-sm text-center capitalize">total price({NairaSign})</div>
                                    </div>
                                    <div className="">
                                        <div className="text-base text-center">{plan.balance?.toLocaleString()}</div>
                                        <div className="text-sm text-center capitalize">available balance({NairaSign})</div>
                                    </div>
                                </div>
                                <div className="w-3/6 mx-auto mt-7">
                                    <Formbutton handleSubmission={handleSubmission} title='buy' loading={loading} font="uppercase text-base py-4" />
                                </div>
                            </div>
                        </div>
                    </>}

                    {!screen && <div className="">
                        <BuyHistory />
                    </div>}
                </div>
                </div>
            </InvestLayout>
        </UserLayout>
    )
}

