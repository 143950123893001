
import UserLayout from 'components/user/UserLayout'
import React, { useState } from 'react'
import InvestLayout from '../../Compos/InvestLayout'
import { useSelector } from 'react-redux'
import MidasHistory from './MidasHistory'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { Apis, Authgeturl } from 'components/Apis'
import { BiSolidBellRing } from 'react-icons/bi'
import { NairaSign, WithdrawStatusOrder } from 'components/utils/functions'
import { SlExclamation } from 'react-icons/sl'
import ModalLayout from 'components/utils/ModalLayout'

const ActiveButtons = [
    {
        title: 'investments',
    },
    {
        title: 'withdrawals',
    },
]

export default function ManageMidasHistory() {
    const [active, setActive] = useState(ActiveButtons[0].title)
    const { plan } = useSelector(state => state.data)
    const [single, setSingle] = useState({
        status: false,
        data: {},
    })


    const { data, isLoading } = useQuery({
        queryKey: [`orders-${plan.id}`],
        queryFn: async () => {
            const response = await Authgeturl(`${Apis.investments.all_user_withdraw_orders}/${plan.id}`)
            return response.msg
        }
    })

    if (isLoading) return (
        <div>Loading...</div>
    )

    const handleSingle = (item) => {
        setSingle({
            status: true,
            data: item
        })
    }

    const CheckStat = (status) => {
        if (status === WithdrawStatusOrder.pending) return <div className="bg-slate-500 text-white w-fit ml-auto py-1.5 px-4 rounded-md text-xs capitalize">{status}</div>
        if (status === WithdrawStatusOrder.cancelled) return <div className="bg-red-600 w-fit ml-auto text-white py-1.5 px-4 rounded-md text-xs capitalize">{status}</div>
        if (status === WithdrawStatusOrder.completed) return <div className="bg-green-500 w-fit ml-auto text-white py-1.5 px-4 rounded-md text-xs capitalize">{status}</div>
    }

    return (
        <UserLayout title={`Manage Investments`} navlink={`/investment/${plan.id}/midas`} paddings={false}>
            <div className={`${single.status ? '' : 'hidden'}`}>
                <ModalLayout closeView={() => setSingle({ ...single, status: false })}>
                    <div className="">
                        <div className="border-b p-4">
                            <div className="font-bold text-xl capitalize">{single.data.title}</div>
                            <div className="text-red-500 font-bold text-xl">-{NairaSign}{single.data.amount?.toLocaleString()}</div>
                        </div>
                        <div className="w-11/12 mx-auto py-4">
                            <div className="grid grid-cols-5 p-2.5 border-b text-sm">
                                <div className="capitalize col-span-2 font-light text-slate-500">amount</div>
                                <div className="col-span-3 text-right">{NairaSign}{single.data.amount?.toLocaleString()}</div>
                            </div>
                            <div className="grid grid-cols-5 p-2.5 border-b text-sm">
                                <div className="capitalize col-span-2 font-light text-slate-500">bank</div>
                                <div className="col-span-3 text-right">{single.data.bankname}</div>
                            </div>
                            <div className="grid grid-cols-5 p-2.5 border-b text-sm">
                                <div className="capitalize col-span-2 font-light text-slate-500">recipient</div>
                                <div className="col-span-3 ">
                                    <div className="text-right capitalize">{single.data.accname}</div>
                                    <div className="text-right text-sm font-light">{single.data.accnumber}</div>
                                </div>
                            </div>
                            <div className="grid grid-cols-5 p-2.5 border-b text-sm">
                                <div className="capitalize col-span-2 font-light text-slate-500">created on</div>
                                <div className="col-span-3 text-right">{moment(single.data.createdAt).format('Do MMM YYYY hh:mm A')}</div>
                            </div>
                            <div className="grid grid-cols-5 p-2.5 border-b text-sm">
                                <div className="capitalize col-span-2 font-light text-slate-500">status</div>
                                <div className="col-span-3 text-right">{CheckStat(single.data.status)}</div>
                            </div>
                            <div className="grid grid-cols-5 p-2.5 border-b text-sm">
                                <div className="capitalize col-span-2 font-light text-slate-500">reference</div>
                                <div className="col-span-3 text-right break-words">{single.data.ref}</div>
                            </div>
                            <div className="grid grid-cols-5 p-2.5 border-b text-sm">
                                <div className="capitalize col-span-2 font-light text-slate-500">content</div>
                                <div className="col-span-3 text-right break-words">{single.data.content}</div>
                            </div>
                        </div>
                    </div>
                </ModalLayout>
            </div>
            <InvestLayout>
                <div className='w-11/12 mx-auto my-5'>
                    <div className="grid grid-cols-2 mb-5">
                        {ActiveButtons.map((ele, i) => (
                            <button
                                key={i}
                                onClick={() => setActive(ele.title)}
                                className={`border text-sm capitalize py-4 ${active === ele.title ? 'bg-main text-white' : 'bg-white'}`}>{ele.title}</button>
                        ))}
                    </div>
                    {active === ActiveButtons[0].title && <>
                        <MidasHistory />
                    </>}
                    {active === ActiveButtons[1].title && <div className='bg-white p-3'>
                        {data.length > 0 ? <ol className="relative border-l border-gray-200 dark:border-gray-300">
                            {data.map((item, k) => (
                                <li key={k} className="mb-10 ml-6 cursor-pointer" onClick={() => handleSingle(item)}>
                                    <span className="absolute flex items-center text-sm text-slate-400 dark:text-white justify-center w-8 h-8 bg-slate-100 rounded-full -left-4 ring-4 ring-white dark:ring-slate-600 dark:bg-green-400">
                                        <BiSolidBellRing />
                                    </span>
                                    <div className="grid grid-cols-5">
                                        <div className="col-span-3">
                                            <h3 className="leading-tight font-medium text-sm capitalize"> {item.title}</h3>
                                            <div className="text-xs font-medium">{moment(item.createdAt).format('DD/MM/YYYY hh:mm:ss A')}</div>
                                            <div className="text-xs font-medium">REF: {item.ref}</div>
                                        </div>
                                        <div className="w-fit ml-auto col-span-2">
                                            <div className="text-right uppercase text-sm">{item.options}</div>
                                            <span className="py-1.5 px-2 rounded-lg text-sm capitalize">{item.status}</span>
                                            <div className={`text-right text-sm `}>{NairaSign}{item.amount?.toLocaleString()}</div>
                                        </div>
                                    </div>
                                    <div className="text-xs text-left"> {item.content} </div>
                                </li>
                            ))}

                        </ol> :
                            <div className="flex items-center justify-center gap-2 text-zinc-500 dark:text-zinc-300 text-sm"> <SlExclamation /> You have no withdrawal order history</div>}
                    </div>}
                </div>
            </InvestLayout>
        </UserLayout>
    )
}


