import moment from 'moment'
import React, { useEffect } from 'react'
import { BiSolidBellRing } from 'react-icons/bi'
import { Errors } from 'components/utils/functions'
import { Apis, Authposturl } from 'components/Apis'
import {useDispatch} from 'react-redux'
import { dispatchAlerts } from 'app/reducer'
import {useNavigate} from 'react-router-dom'

const SingleNotification = ({item}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        const ResolveReadings = async () => {
            try {
                if(item.read === 'false') {
                    const body = {
                        id: item.id
                    }
                    const response = await Authposturl(Apis.notify.read_note, body)
                    if(response.status === 200) {
                        dispatch(dispatchAlerts(response))
                    }
                }
            } catch (error) {
                Errors(`${error.message}`)
            }
        }
        ResolveReadings()
    }, [])

    const RedirectUser = () => {
        if(item.url) {
            navigate(`${item.url}`)
        }
    }
  return (
    <li onClick={RedirectUser} className={`mb-10 ml-6 ${item.url ? 'cursor-pointer' : ''}`}>
    <span className={`absolute flex items-center text-sm ${item.read === 'true' ? 'text-slate-400 dark:text-white bg-slate-100 ring-white dark:ring-slate-600' : 'text-blue-100 dark:text-white bg-blue-500 ring-white dark:ring-blue-600'} justify-center w-8 h-8  rounded-full -left-4 ring-4 dark:bg-green-400`}>
        <BiSolidBellRing />
    </span>
    <div className="grid grid-cols-2">
        <div className="">
            <h3 className="leading-tight font-bold text-sm capitalize"> {item.title} </h3>
        </div>
        <div className="">
            <div className="text-xs text-right font-medium">{moment(item.createdAt).format('DD-MM-YYYY h:sA')}</div>
        </div>
    </div>
    <div className="text-xs text-left mr-2"> {item.content} <span className="text-blue-500">{item.url ? 'click here' : ''}</span> </div>
</li>
  )
}

export default SingleNotification