import { useEffect, useState } from "react";
import UserLayout from "components/user/UserLayout";
import { Apis, Authgeturl, Authposturl } from "components/Apis";
import { ErrorAlert, SuccessAlert } from "components/utils/functions";
import Spinner from "components/utils/Spinner";
import GeneralSharesForm from "components/general/GeneralSharesForm";


export default function UserShare() {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true)
    const [view, setView] = useState(1)
    
    useEffect(() => {
        const FetchAsset = async () => {
            setLoading(true)
            try {
                const response = await Authgeturl(Apis.shares.my_shares)
                if(response.status === 200) {
                    setData(response.msg)
                    setView(response.msg !== null ? 2 : 1)
                }else {
                     ErrorAlert(`${response.msg}`)
                }
            } catch (error) {
                ErrorAlert(`${error.message}`)
            }finally {
                setLoading(false)
            }
        }
        FetchAsset()
    }, [])

    const RequestShareHolding = async () => {
        setLoading(true)
        try {
            const response = await Authposturl(Apis.shares.request_shareholding)
            if(response.status === 200) {
                SuccessAlert('Request Sent')
                setView(2)
            }
            else{
                return ErrorAlert(`${response.msg}`)
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        }finally {
            setLoading(false)
        }
    }
    
    if(loading) return (
        <UserLayout>
            <div className="mx-auto w-fit">
                <Spinner />
            </div>
        </UserLayout>
    )
    
    if(!loading) return (
        <UserLayout>
            <GeneralSharesForm />
        </UserLayout>
    )
}