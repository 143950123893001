

import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { SlExclamation } from 'react-icons/sl'
import SingleMidasHistory from './SingleMidasHistory'
import { Errors, NairaSign } from 'components/utils/functions'
import { Apis, Authgeturl } from 'components/Apis'
import { useQuery } from '@tanstack/react-query'
import ModalLayout from 'components/utils/ModalLayout'
import moment from 'moment'

export default function MidasHistory() {
    const { plan } = useSelector(state => state.data)
    const [single, setSingle] = useState({
        status: false,
        data: {}
    })

    const { data, isLoading, error, isError, refetch } = useQuery({
        queryKey: [`buy-history-${plan.id}`],
        queryFn: async () => {
            const res = await Authgeturl(`${Apis.investments.my_purchases}/${plan.id}/${plan.plans.id}`)
            if (res.status === 200) {
                return res.msg
            }
        }
    })
    if (isLoading) {
        return (
            <>
                {new Array(6).fill().map((item, i) => (
                    <div key={i} className="bg-slate-200 dark:bg-slate-600 h-[5rem] mb-3 rounded-lg"></div>
                ))}
            </>
        )
    }
    if (isError) {
        return (
            <>
                <div className="text-center">{error}</div>
            </>
        )
    }
    const handleSingle = item => {
        setSingle({
            data: item,
            status: true
        })
    }
    return (
        <div>
        <div className={`${single.status ? '' : 'hidden'}`}>
            <ModalLayout closeView={() => setSingle({ ...single, status: false })}>
                <div className="p-4 border-b">
                    <div className="font-bold">Investment Details</div>
                    <div className="flex items-center gap-2 text-sm"> <span className="font-light">Current</span>: <span className=' text-green-500'> {NairaSign} <span className="text-xl">{(single.data.returnProfit + single.data.amount)?.toLocaleString()}</span> </span> </div>
                </div>
                <div className="w-11/12 mx-auto py-3">
                    <div className="grid grid-cols-2 gap-3 p-2">
                        <div className="text-sm">Amount Invested</div>
                        <div className="text-sm text-right">{NairaSign}{single.data.amount?.toLocaleString()}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 p-2">
                        <div className="text-sm">Expected Returns</div>
                        <div className="text-sm text-right">{NairaSign}{(single.data.expectedReturns - single.data.amount)?.toLocaleString()}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 p-2">
                        <div className="text-sm">Accumulated Returns</div>
                        <div className="text-sm text-right">{NairaSign}{single.data.returnProfit?.toLocaleString()}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 p-2">
                        <div className="text-sm">Reserved</div>
                        <div className="text-sm text-right">{NairaSign}{(single.data.returnProfit + single.data.amount)?.toLocaleString()}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 p-2">
                        <div className="text-sm">Return on investment</div>
                        <div className="text-sm text-right">{single.data.percent}%</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 p-2">
                        <div className="text-sm">Time Laps</div>
                        <div className="text-sm text-right">{single.data.timed}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 p-2">
                        <div className="text-sm">Duration</div>
                        <div className="text-sm text-right">{single.data.duration} {single.data.duration === '1' ? `${single.data.durationType?.slice(0, -1)}` : `${single.data.durationType}`}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 p-2">
                        <div className="text-sm">Start Date</div>
                        <div className="text-sm text-right">{moment(single.data.startDate).format('DD-MM-YYYY hh:mma')}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 p-2">
                        <div className="text-sm">Due Date</div>
                        <div className="text-sm text-right">{moment(single.data.endDate).format('DD-MM-YYYY hh:mma')}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 p-2">
                        <div className="text-sm">Date Initiated</div>
                        <div className="text-sm text-right">{moment(single.data.createdAt).format('DD-MM-YYYY hh:mma')}</div>
                    </div>
                </div>
            </ModalLayout>
        </div>
            <div className="">
                {data.length < 1 ? <>
                    <div className="flex items-center justify-center gap-2 text-zinc-500 dark:text-zinc-300 text-sm"> <SlExclamation /> You have no purchase history</div>
                </> : data.map((item, i) => (
                    <SingleMidasHistory
                    handleSingle={handleSingle}
                        item={item}
                        key={i}
                    />
                ))}
            </div>
        </div>
    )
}


