
import React, { useLayoutEffect, useRef, useState, useEffect } from 'react'
import { Apis, Authgeturl } from 'components/Apis'
import {useDispatch} from 'react-redux'
import { dispatchUser } from 'app/reducer'
import {useNavigate} from 'react-router-dom'
import Cookies from 'js-cookie'
import { tokenName } from 'components/utils/functions'

export default function PartnerPrivacy({children}) {
  const dispatch = useDispatch()
  const [login, setLogin] = useState(false)
  const navigate = useNavigate()
  const [inactive, setInactive] = useState(false);
  const inactiveCount = useRef(0)
  const activeCount = useRef(0)


    useLayoutEffect(() => {
        const FetchProfile = async () => {
          try {
            const res = await Authgeturl(Apis.shares.shares_profiler)
            if(res.status === 200) {
                setLogin(true)
                dispatch(dispatchUser(res.msg))
            }else {
              setLogin(false)
              navigate('/partnership')
            }
           } catch (error) {
              setLogin(false)
              navigate('/partnership')
            }
        }
        FetchProfile()
    }, [])
    let timeout
  
    useEffect(() => {
      timeout = setTimeout(async () => {
        setInactive(true);
        inactiveCount.current += 1
        activeCount.current = 0
        if (inactiveCount.current < 2) {
          navigate('/partnership')
          Cookies.remove(tokenName)
        }
      }, 50000); // 5 minutes
  
      document.addEventListener('mousemove', resetTimeout);
      document.addEventListener('keypress', resetTimeout);
  
      return () => {
        clearTimeout(timeout);
        document.removeEventListener('mousemove', resetTimeout);
        document.removeEventListener('keypress', resetTimeout);
      };
    }, [inactive]);
  
    const resetTimeout = async () => {
      setInactive(false);
      activeCount.current += 1
      inactiveCount.current = 0
      // if (activeCount.current < 2) {
      //   const data = {
      //     status: "online"
      //   }
      //   await Authputurl(Apis.user.change_activity_status, data)
      // }
      clearTimeout(timeout);
      const newTimeout = setTimeout(() => {
        setInactive(true);
      }, 50000); // 5 minutes
    };
  
  if(login) return children
}
