import { useSelector } from "react-redux";
import Footer from "components/general/Footer";
import Header from "components/general/Header";
import * as React from 'react';
import { ChartContainer } from '@mui/x-charts';
import { LinePlot, MarkPlot } from '@mui/x-charts/LineChart';
import { SlInfo } from "react-icons/sl";
import { BiDollar } from "react-icons/bi";
import { ConfigProvider, Progress } from 'antd';
import { FaEllipsisH } from "react-icons/fa";
import logo from 'assets/main-images/main.png'
import { GetPercentFromDigit, GetPercentFromDigit2 } from "components/utils/functions";
import SellOffShares from "./SellOffShares";
import { BarChart } from '@mui/x-charts/BarChart';
import Box from '@mui/material/Box';
import { SparkLineChart } from '@mui/x-charts';


import moment from "moment";
import Imaged from "components/utils/Imaged";



export default function PartnerHome() {
    const { user } = useSelector(state => state.data)
    const [transfers, setTransfers] = React.useState({
        amounts: [],
        tags: [],
        status: false,
        total: 0,
        pricing: 0
    })
    const [histories, setHistories] = React.useState([])
    const [open, setOpen] = React.useState({
        data: {},
        status: false,
    })
    const [views, setViews] = React.useState(false)

    const OpenSellOff = () => {
        setOpen({
            data: user,
            status: true
        })
    }


    React.useEffect(() => {
        const FetchTransfers = () => {
            const amounts = [], tags = []
            let total = 0, pricing = 0
            user.transfers.map(ele => {
                total += ele.quantity
                pricing += ele.amount
                amounts.push(ele.amount)
                tags.push(`${moment(ele.createdAt).format('DD/MM/YYYY hh:ma')}`)
            })
            setTransfers({
                amounts,
                tags,
                status: true,
                total,
                pricing
            })

            // shares histories 
            const history = [0]
            user.histories.map(ele => {
                return history.push(parseFloat(ele.amount))
            })

            setHistories(history)
        }
        FetchTransfers()
    }, [user])


    // const pData = [0, 6400, 1398, -9800, -3908, -8000,  4800, 8800, 4300, 7500];
    const xLabels = [
        'Page A',
        'Page B',
        'Page C',
        'Page D',
        'Page E',
        'Page F',
        'Page G',
    ];


    return (
        <>
            {open.status && <SellOffShares
                data={open.data}
                closeView={() => setOpen({ ...open, status: false })}
            />}
            <div className="bg-zinc-100">
                <Header />
                <div className="bg-main py-5 px-6 text-white">
                    <div className="grid grid-cols-5 mb-10">
                        <div className="col-span-4">
                            <div className="flex gap-3 items-center">
                                <div className="bg-white rounded-md py-3 px-2.5"> <Imaged src={logo} alt="" className="w-8" /> </div>
                                <div className="">
                                    <div className="flex  items-center text-xl">
                                        {user.user?.totalshares?.toLocaleString()}({user.asset?.shorts})
                                    </div>
                                    <div className="flex  items-center text-base white">
                                        <BiDollar />{(user.user?.totalshares * user.asset?.pricing)?.toLocaleString()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-1 w-fit ml-auto  text-xl"> <FaEllipsisH /> </div>
                    </div>
                    <Box sx={{ flexGrow: 1 }}>
                        <SparkLineChart data={histories} height={100} />
                    </Box>
                </div>
                <div className="w-11/12 mx-auto py-10">
                    <div className="grid grid-cols-1 lg:grid-cols-9 gap-5">
                        <div className="lg:col-span-6">
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
                                <div className="lg:col-span-2">
                                    <div className="bg-main py-5 px-6 rounded-lg text-white">
                                        <div className="grid grid-cols-5">
                                            <div className="col-span-4">
                                                <div className="flex gap-3 items-center">
                                                    <div className="bg-white rounded-full py-3 px-2.5"> <Imaged src={logo} alt="" className="w-8" /> </div>
                                                    <div className="text-sm">
                                                        <div className=" capitalize">Authorized sellable shares</div>
                                                        <div className="capitalize">{user.asset?.title}(<span className="uppercase">{user.asset?.shorts}</span>)</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-span-1 w-fit ml-auto  text-xl"> <FaEllipsisH /> </div>
                                        </div>
                                        <div className="grid grid-cols-5 mt-10">
                                            <div className="col-span-3">
                                                <div className="flex  items-center text-2xl">
                                                    {user.asset?.authorized?.toLocaleString()}{user.asset?.shorts}
                                                </div>
                                                <div className="flex  items-center text-lg text-green-300">
                                                    <BiDollar />{(user.asset?.authorized * user.asset?.pricing)?.toLocaleString()}
                                                </div>
                                            </div>
                                            <div className="col-span-2 w-fit ml-auto">
                                                <ConfigProvider
                                                    theme={{
                                                        components: {
                                                            Progress: {
                                                                circleTextColor: "rgba(255, 255, 255)"
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <Progress
                                                        type="dashboard"
                                                        size={80}
                                                        strokeWidth={10}
                                                        trailColor="#999"
                                                        strokeLinecap="round"
                                                        strokeColor={{ '0%': 'lightgreen', '100%': 'lightgreen' }}
                                                        percent={GetPercentFromDigit(user.asset?.authorized, user.asset.total)} />
                                                </ConfigProvider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="bg-white text-main py-5 px-6 rounded-lg">
                                        <div className="grid grid-cols-5">
                                            <div className="col-span-4">
                                                <div className="flex gap-3 items-center">
                                                    <div className="bg-main rounded-full py-3.5 px-2.5"> <Imaged src={logo} alt="" className="w-8" /> </div>
                                                    <div className="text-sm">
                                                        <div className=" capitalize">My Shares Portfolio</div>
                                                        <div className="capitalize">{user.asset?.title}(<span className="uppercase">{user.asset?.shorts}</span>)</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-span-1 w-fit ml-auto  text-xl"> <FaEllipsisH /> </div>
                                        </div>
                                        <div className="flex items-center justify-between mt-6">
                                            <div className="">
                                                <div className="flex  items-center text-2xl">
                                                    {user.user?.totalshares?.toLocaleString()}{user.asset?.shorts}
                                                </div>
                                                <div className="flex  items-center text-lg text-green-400">
                                                    <BiDollar />{(user.user?.totalshares * user.asset?.pricing)?.toLocaleString()}
                                                </div>
                                            </div>
                                            <div className="">
                                                <Progress strokeWidth={8} type="dashboard" size={73} percent={GetPercentFromDigit(user.user?.totalshares, user.asset.authorized)} />
                                                <div className="text-sm text-right">{GetPercentFromDigit2(user.user?.totalshares, user.asset.authorized)}%</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="bg-white text-main py-5 px-6 rounded-lg">
                                        <div className="grid grid-cols-5">
                                            <div className="col-span-4">
                                                <div className="flex gap-3 items-center">
                                                    <div className="bg-main rounded-full py-3.5 px-2.5"> <Imaged src={logo} alt="" className="w-8" /> </div>
                                                    <div className="text-sm">
                                                        <div className=" capitalize">Sold Shares</div>
                                                        <div className="capitalize">{user.asset?.title}(<span className="uppercase">{user.asset?.shorts}</span>)</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-span-1 w-fit ml-auto  text-xl"> <FaEllipsisH /> </div>
                                        </div>
                                        <div className="flex items-center justify-between mt-6">
                                            <div className="">
                                                <div className="flex  items-center text-2xl">
                                                    {user.asset?.sold?.toLocaleString()}{user.asset?.shorts}
                                                </div>
                                                <div className="flex  items-center text-lg text-green-400">
                                                    <BiDollar />{(user.asset?.sold * user.asset?.pricing)?.toLocaleString()}
                                                </div>
                                            </div>
                                            <div className="">
                                                <Progress strokeWidth={8} type="dashboard" size={73} percent={GetPercentFromDigit(user.asset?.sold, user.asset.authorized)} />
                                                <div className="text-right text-sm">{GetPercentFromDigit2(user.asset?.sold, user.asset.authorized)}%</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="lg:col-span-2">
                                    {/* <div className="">

                                        <div className="bg-white rounded-lg">
                                            <div className="grid grid-cols-5 w-11/12 mx-auto pt-3">
                                                <div className="col-span-3">
                                                    <div className="font-bold capitalize">Stock market value</div>
                                                    <div className="font-bold uppercase">{user.asset?.shorts} <span className="capitalize text-slate-400 font-light">{user.asset?.title}</span>  </div>
                                                </div>
                                                <div className="col-span-2">
                                                    <div className="flex  items-center justify-end text-2xl text-main font-bold">
                                                        {(user.asset?.authorized * user.asset?.pricing)?.toLocaleString()}({user.asset?.shorts})
                                                    </div>
                                                    <div className="flex  items-center justify-end text-xl text-zinc-500 font-light">
                                                        <BiDollar />{(user.asset?.authorized * user.asset?.pricing)?.toLocaleString()}
                                                    </div>
                                                </div>
                                            </div>
                                            <ChartContainer
                                                width={300}
                                                height={200}
                                                series={[{ type: 'line', data: pData }]}
                                                xAxis={[{ scaleType: 'point', data: xLabels }]}
                                                sx={{
                                                    '.MuiLineElement-root': {
                                                        stroke: '#8884d8',
                                                        strokeWidth: 2,
                                                    },
                                                    '.MuiMarkElement-root': {
                                                        stroke: '#8884d8',
                                                        scale: '0.6',
                                                        fill: '#fff',
                                                        strokeWidth: 2,
                                                    },
                                                }}
                                                disableAxisListener
                                            >
                                                <LinePlot />
                                                <MarkPlot />
                                            </ChartContainer>
                                        </div>
                                    </div> */}
                                    <div className="mt-3">

                                        <div className="bg-white rounded-lg overflow-x-auto scrollsdown">
                                            <div className="grid grid-cols-5 w-11/12 mx-auto pt-3">
                                                <div className="col-span-3">
                                                    <div className="font-bold capitalize">My Sell Orders</div>
                                                    <div className="font-bold uppercase">{user.asset?.shorts} <span className="capitalize text-slate-400 font-light">{user.asset?.title}</span>  </div>
                                                </div>
                                                <div className="col-span-2">
                                                    <div className="flex  items-center justify-end text-2xl text-main font-bold">
                                                        {(transfers.total)?.toLocaleString()}({user.asset?.shorts})
                                                    </div>
                                                    <div className="flex  items-center justify-end text-xl text-zinc-500 font-light">
                                                        <BiDollar />{(transfers.pricing)?.toLocaleString()}
                                                    </div>
                                                </div>
                                            </div>
                                            {user.transfers.length > 0 && transfers.status &&

                                                <BarChart
                                                    width={500}
                                                    height={300}
                                                    series={[
                                                        { data: transfers.amounts, label: 'Sell Orders', id: 'pvId' },
                                                    ]}
                                                    xAxis={[{ data: transfers.tags, scaleType: 'band' }]}
                                                />
                                                //    <ChartContainer
                                                //         width={500}
                                                //         height={300}
                                                //         series={[{ data: transfers.amounts, label: 'uv', type: 'bar' }]}
                                                //         xAxis={[{ scaleType: 'band', data: transfers.tags }]}
                                                //     >
                                                //         <BarPlot />
                                                //     </ChartContainer>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lg:col-span-3">
                            <div className="bg-white p-3 rounded-lg">
                                <div className="">
                                    <div className="grid grid-cols-6 p-2 border-t mt-3 pt-3">
                                        <div className="col-span-2 text-zinc-500 text-sm">Full Name</div>
                                        <div className="col-span-4 text-right text-sm font-semibold">{user.user?.firstname} {user.user?.lastname}</div>
                                    </div>
                                    <div className="grid grid-cols-6 p-2">
                                        <div className="col-span-2 text-zinc-500 text-sm">Email Address</div>
                                        <div className="col-span-4 text-right text-sm font-semibold break-words">{user.user?.email}</div>
                                    </div>
                                    <div className="grid grid-cols-6 p-2">
                                        <div className="col-span-2 text-zinc-500 text-sm">Phone Number</div>
                                        <div className="col-span-4 text-right text-sm font-semibold">{user.user?.phone}</div>
                                    </div>
                                    <div className="grid grid-cols-6 p-2">
                                        <div className="col-span-2 text-zinc-500 text-sm">Nationality</div>
                                        <div className="col-span-4 text-right text-sm font-semibold">{user.user?.nationality}, {user.user?.state}</div>
                                    </div>
                                    <div className="grid grid-cols-6 p-2">
                                        <div className="col-span-2 text-zinc-500 text-sm">Account Status</div>
                                        <div className="col-span-4 text-right text-sm font-semibold">{user.user?.approved ? 'Approved' : '---'}</div>
                                    </div>
                                    <div className="grid grid-cols-6 p-2">
                                        <div className="col-span-2 text-zinc-500 text-sm">Official Address</div>
                                        <div className="col-span-4 text-right text-sm font-semibold">{user.user?.address}</div>
                                    </div>
                                    <div className="grid grid-cols-6 p-2">
                                        <div className="col-span-2 text-zinc-500 text-sm">Date Issued</div>
                                        <div className="col-span-4 text-right text-sm font-semibold">{user.user?.dateissued}</div>
                                    </div>
                                    <div className="grid grid-cols-6 p-2">
                                        <div className="col-span-2 text-zinc-500 text-sm">Certificate No.</div>
                                        <div className="col-span-4 text-right text-sm font-semibold break-words">{user.user?.certno}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white p-3 mt-3 rounded-lg">
                                <div className="text-red-700 border-b border-red-600 mb-4 pb-2 text-center">Shares sold cannot be recovered</div>
                                <button
                                    onClick={OpenSellOff}
                                    className="bg-red-700 text-white capitalize py-3 w-full rounded-lg text-sm">Sell Off shares</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
