import React, { useState } from 'react'
import img from 'assets/images/img6.gif'
import img2 from 'assets/images/img7.webp'
import img3 from 'assets/images/img8.webp'
import logo from 'assets/main-images/main.png'
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import Imaged from 'components/utils/Imaged'


const cs = 'p-1 rounded-full bg-slate-400'
const cs2 = 'p-1 w-10 rounded-full bg-sub'

const Welcome = () => {
    const [zone, setZone] = useState(1)
    const navigate = useNavigate()

    const handleBack = () => {
        setZone(prev => prev - 1)
    }

    const handleNext = () => {
        if (zone === 3) return navigate('/login')
        setZone(prev => prev + 1)
    }
    return (
        <div className='bg-zinc-100'>
            <div className="w-10/12 max-w-xl mx-auto h-screen bg-white p-5">
                <div className="h-[80dvh]">
                            <div className="w-fit ml-auto pt-5">
                                <Imaged src={logo} alt="" className="h-10" />
                            </div>
                    {zone === 1 && <div className="">
                        <div className="flex flex-col justify-center h-[75dvh] items-center gap-7">
                            <Imaged src={img} alt="" className="" />
                            <div className="flex items-center gap-2">
                                <div className={`${cs2}`}></div>
                                <div className={`${cs}`}></div>
                                <div className={`${cs}`}></div>
                            </div>
                            <div className="text-center text-zinc-700">Rise invest your money into the best dollar investments around the globe</div>
                        </div>
                    </div>}
                    {zone === 2 && <div className="">
                        <div className="flex flex-col justify-center h-[85dvh] items-center gap-7">
                            <Imaged src={img2} alt="" className="" />
                            <div className="flex items-center gap-2">
                                <div className={`${cs}`}></div>
                                <div className={`${cs2}`}></div>
                                <div className={`${cs}`}></div>
                            </div>
                            <div className="text-center text-zinc-700">Rise invest your money into the best dollar investments around the globe</div>
                        </div>
                    </div>}
                    {zone === 3 && <div className="">
                        <div className="flex flex-col justify-center h-[85dvh] items-center gap-7">
                            <Imaged src={img3} alt="" className="" />
                            <div className="flex items-center gap-2">
                                <div className={`${cs}`}></div>
                                <div className={`${cs}`}></div>
                                <div className={`${cs2}`}></div>
                            </div>
                            <div className="text-center text-zinc-700">Rise invest your money into the best dollar investments around the globe</div>
                        </div>
                    </div>}
                </div>
                <div className="h-[10dvh]">
                    <div className="flex items-center justify-between mb-16 w-full">
                        {zone !== 1 ? <button onClick={handleBack} className="bg-main rounded-lg shadow-xl capitalize py-3 px-4 flex items-center gap-8 text-white font-medium"> <FiArrowLeft /> back</button> : <div></div>}
                        <button onClick={handleNext} className="bg-main rounded-lg shadow-xl capitalize py-3 px-4 flex items-center gap-8 text-white font-medium">{zone !== 3 ? 'next' : 'login'} <FiArrowRight /> </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Welcome