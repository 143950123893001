
import React from 'react'
import SLayout from 'components/superadmin/SLayout'
import ProfileComponent from 'components/utils/ProfileComponent'

const SProfile = () => {
  return (
    <SLayout>
        <ProfileComponent  />
    </SLayout>
  )
}

export default SProfile