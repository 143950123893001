


import { useQuery } from '@tanstack/react-query'
import { Apis, Authgeturl, Authposturl } from 'components/Apis'
import UserLayout from 'components/user/UserLayout'
import Formbutton from 'components/utils/Formbutton'
import Forminput from 'components/utils/Forminput'
import { CatchError, ErrorAlert, NairaSign, Swals } from 'components/utils/functions'
import { CableImages } from 'components/utils/PageUtils'
import Spinner from 'components/utils/Spinner'
import React, { useState } from 'react'
import { PiSealCheckFill } from 'react-icons/pi'
import { useDispatch, useSelector } from 'react-redux'
import { usePDF } from 'react-to-pdf'
import ServiceConfirmation from './ServiceConfirmation'
import SuccessModal from 'components/utils/SuccessModal'
import { dispatchUser } from 'app/reducer'

export default function Cable() {
    const [loading, setLoading] = useState(false)
    const [loader, setLoader] = useState(false)
    const [info, setInfo] = useState({})
    const [open, setOpen] = useState({ status: false, data: {} })
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.data)
    const [plans, setPlans] = useState([])
    const [forms, setForms] = useState({
        network: '',
        Action: "CABLE",
        Service: "",
        Serviceid: '',
        Plan: "",
        Number: "",
        actual: '',
    })
    const [single, setSingle] = useState({
        status: false,
        cashback: 0,
        amount: 0,
        paymethod: '',
        service: '',
        data: {},
        product: '',
        amountToPay: 0,
    })

    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }

    const date = new Date()

    const { toPDF, targetRef } = usePDF({
        filename: `${date.getFullYear()}_${date.getTime()}.pdf`,
        canvas: {
            useCORS: true,
        },
    });


    const { data, isLoading } = useQuery({
        queryKey: [`allncwallet-data-services-for-users`],
        queryFn: async () => {
            const response = await Authgeturl(`${Apis.ncwallet.all_services}?name=CABLE`)
            return response.data[0]
        }
    })

    function handleServiceSelection(val) {
        if (val) {
            const itemService = data.tags.find(ele => ele.id === val)
            setForms({
                ...forms,
                network: itemService.servicename,
                Serviceid: itemService.id,
            })
            setSingle({
                ...single,
                Plan: ""
            })
            setLoading(true)
            setTimeout(() => {
                setPlans(itemService.plans)
                setLoading(false)
            }, 500);

        } else {
            setForms({
                ...forms,
                network: '',
                Serviceid: '',
            })
        }
    }

    const handlePlans = e => {
        const val = e
        if (val) {
            const item = data.tags.find(ele => ele.id === forms.Serviceid).plans.find(pl => pl.id === val)
            setSingle({
                ...single,
                amount: item.amount,
                amountToPay: item.sell,
                actual: item.amount,
                Plan: item.id,
                cashback: item.cashback,
                plan_name: item.planname
            })
        } else {
            setSingle({
                ...single,
                status: false,
                amount: '',
                Plan: '',
                amountToPay: "",
            })
        }
    }

    const HandleCableNumberValidation = async e => {
        e.preventDefault()
        if (!forms.Serviceid) return ErrorAlert('Select your preferred network')
        if (!single.Plan) return ErrorAlert('Select a Bundle to purchase')
        if (!forms.Number) return ErrorAlert('Provide a valid cable number')
        const item = data.tags.find(ele => ele.id === forms.Serviceid)
        setLoader(true)
        const preData = {
            cable_id: item.serviceid,
            cable_number: forms.Number
        }
        try {
            const response = await Authposturl(Apis.ncwallet.cable_validation, preData)
            setInfo(response.data)
        } catch (error) {
            ErrorAlert(CatchError(error))
        } finally {
            setLoader(false)
        }
    }

    function ProceedToContinue() {
        if (forms.actual > user.account?.balance) return ErrorAlert('Insufficient balance to proceed')

            const item = data.tags.find(ele => ele.id === forms.Serviceid)
            const itemPlan = data.tags.find(ele => ele.id === forms.Serviceid).plans.find(pl => pl.id === single.Plan)
        const dataToStore = {
            ...single,
            ...forms,
            status: true,
            servid: itemPlan.id,
            pplan: item.servicename,
            Number: forms.Number,
            product: itemPlan.planname,
        }
        setSingle(dataToStore)
    }

    async function PurchaseCablePurchase(values) {
        setLoader(true)
        try {
            const result = await Authposturl(Apis.ncwallet.purchase_cable, values)
            if (result.status !== 200) return Swals('Request Failed', `${result.msg}`, 'error')

            setSingle({
                ...single,
                status: false
            })
            setOpen({
                status: true,
                data: result
            })
            dispatch(dispatchUser(result.user))
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoader(false)
        }
    }

    if (isLoading) return (
        <UserLayout title="Data Purchase">
            <div className="w-11/12 mx-auto mt-10">
                <div className="p-3 bg-white">
                    <div className="">
                        {new Array(5).fill(0).map((ele, index) => (
                            <div className="bg-slate-200 h-16 w-full animate-pulse mb-3" key={index}></div>
                        ))}
                    </div>
                </div>
            </div>
        </UserLayout>
    )

    return (
        <UserLayout title="Cable subscription">
            {open.status && <SuccessModal
                closeview={() => setOpen({ status: false, data: {} })}
                title={'Cable Purchased Successfully'}
                content2={open.data.msg}
                content={open.data.msg}
                cashback={`${single.cashback}MB`}
                DownloadPDF={() => toPDF()}
                Dismiss={'/service_transactions'}
            />}
            {single.status && <ServiceConfirmation
                loading={loader}
                productTag="Cable"
                single={single}
                setSingle={setSingle}
                user={user}
                PurchaseService={PurchaseCablePurchase}
                closeView={() => setSingle({ ...single, status: false })}
            />}
            <div ref={targetRef}>
                {open.status ? <div>
                    <div className="grid grid-cols-2 gap-4 py-2 mt-5">
                        <div className="text-sm">Cable Number</div>
                        <div className="text-sm text-right">{single.Number}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 py-2">
                        <div className="text-sm">Cable Plan</div>
                        <div className="text-sm text-right uppercase">{single.product} {single.network}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 py-2">
                        <div className="text-sm">Amount Paid</div>
                        <div className="text-sm text-right uppercase">{NairaSign}{single.amountToPay?.toLocaleString()}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 py-2">
                        <div className="text-sm">Credit Type</div>
                        <div className="text-sm text-right uppercase">Cable purchase</div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 py-2">
                        <div className="text-sm">to Purchase</div>
                        <div className="text-sm text-right uppercase">Cable [{single.product}]</div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 py-2">
                        <div className="text-sm">Transaction Date</div>
                        <div className="text-sm text-right uppercase">{open.data?.tag?.datetime}</div>
                    </div>
                </div> : ''}
            </div>
            <div className="w-[97%] p-3 mx-auto bg-white mt-10">
                <form onSubmit={HandleCableNumberValidation}>
                    <div className="relative mb-4">
                        <div className="">Select Service</div>
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
                            {data?.tags?.map((item, index) => {
                                const image = CableImages.find(ele => ele.cable_name?.toLowerCase() === item.servicename?.toLowerCase())
                                return (
                                    <div
                                        onClick={() => handleServiceSelection(item.id)}
                                        className={`flex flex-col ${forms.network === item.serviename ? 'border-sub scale-110 transition-all font-bold' : 'border-slate-100'} bg-white border-4 w-11/12 mx-auto items-center justify-center gap-2 px-3 py-2 rounded-md cursor-pointer`} key={index}>
                                        <img src={image.image} alt="" className={`h-10 w-auto`} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {loading && <Spinner />}
                    {forms.network && <div className="relative mb-4">
                        <div className="">Select Bundle</div>
                        <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-3">
                            {plans.map((ele, index) => (
                                <div
                                    onClick={() => handlePlans(ele.id)}
                                    key={index} className="flex items-center flex-col cursor-pointer scale-100 hover:scale-105 transition-all">
                                    <div className={`${single.Plan === ele.id ? 'bg-sub text-white' : 'bg-zinc-300 text-zinc-600'} w-10/12 text-center py-1.5 px-3 z-10 isolate rounded-tr-lg font-semibold rounded-tl-lg text-sm md:text-base capitalize`}>{ele.planname}</div>
                                    <div className={`font-bold w-full px-4 py-1 text-center ${single.Plan === ele.id ? 'bg-amber-300' : 'bg-zinc-400'} card-label text-xs`}>{ele.planname} + {ele.cashback}MB</div>
                                    <div className={`${single.Plan === ele.id ? 'bg-sub text-white' : 'bg-zinc-300 text-zinc-600'} w-10/12 text-center py-1.5 px-3 z-10 isolate rounded-br-lg rounded-bl-lg text-sm md:text-base`}>{NairaSign}{ele.sell?.toLocaleString()}</div>
                                </div>
                            ))}
                        </div>
                    </div>}
                    {!info?.customer_name ? <>
                        <div className="mb-4">
                            <div className="">Cable Number</div>
                            <Forminput type="number" formName={'Number'} value={forms.Number} onChange={handleForms} />
                        </div>
                        <Formbutton title="Verify Cable Number" loading={loader} />
                    </> : <>
                        <div className="text-sm text-green-800 uppercase py-2 px-3 rounded-lg bg-green-200/40 border border-green-500 flex mb-3 items-center gap-2"> <PiSealCheckFill /> {info.customer_name}</div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 sm:gap-10 mt-5">
                            <Formbutton title="Back" type="button" handleSubmission={() => setInfo({})} bg="bg-slate-400" loading={loader} />
                            <Formbutton title="Continue" type="button" handleSubmission={ProceedToContinue} loading={loader} />
                        </div>
                    </>}
                </form>
            </div>
        </UserLayout>
    )
}
