import Footer from "components/general/Footer";
import Header from "components/general/Header";
import GeneralSharesForm from "components/general/GeneralSharesForm";
import LoginShares from "components/general/LoginShares";
import { useState } from "react";
import { Bounce, JackInTheBox } from "react-awesome-reveal";
import { ErrorAlert, NairaSign } from "components/utils/functions";
import { Helmet } from "react-helmet-async";
import { Apis, geturl } from "components/Apis";
import ContactForm from "components/general/ContactForm";
import AllSponsors from "components/general/AllSponsors";
import { useQuery } from "@tanstack/react-query";


export default function Partnership() {
    const [views, setViews] = useState(false)

    const { data, isLoading } = useQuery({
        queryKey: ['shares', 'all_share_asset'],
        queryFn: async () => {
            try {
                const response = await geturl(Apis.shares.all_share_asset)
                if (response.status === 200) return response.msg
            } catch (error) {
                ErrorAlert(`${error.message}`)
            }
        }
    })

    return (
        <div>
            <Helmet prioritizeSeoTags>
                <title>Quick Direct | Partnership</title>
                <link rel="notImportant" href="https://www.quickdirect.com.ng/partnership" />
                <meta name="whatever" value="notImportant" />
                <link rel="canonical" href="https://www.quickdirect.com.ng/partnership" />
                <meta property="og:title" content="Quick Direct | Partnership" />
                <meta property="og:url" content="https://www.quickdirect.com.ng/partnership" />
                <meta property="og:image" content="https://www.quickdirect.com.ng/main.png" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Welcome to Quick Direct, where expert guidance meets collaborative growth. Our stock partnership program is designed to empower investors with cutting-edge strategies and unparalleled support." />
            </Helmet>
            {views && <LoginShares
                closeView={() => setViews(!views)}
            />}
            <Header />
            <div className="overflow-x-hidden">
                <div className="wavy -z-10 h-fit">
                    <div className="bg-white/60">
                        <div className="grid w-11/12 grid-cols-1 gap-5 lg:gap-10 mx-auto my-2 lg:grid-cols-2 lg:w-10/12">
                            <div className="flex flex-col justify-center w-full h-full pt-5 z-20">
                                <JackInTheBox><div className="mb-4 text-5xl font-extrabold lg:text-7xl">Buy Quick Direct <span className="text-white bg-gradient-to-tr from-main to-mainblue py-1 px-4 rounded-lg text-4xl lg:text-6xl">Shares.</span> </div></JackInTheBox>
                                <JackInTheBox><div className="text-2xl lg:text-3xl mb-10 text-slate-600 font-bold">Own a Slice of our Company</div></JackInTheBox>
                                <div className="my-5 lg:my-10">
                                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 lg:gap-10">
                                        <div className="flex flex-col gap-5 lg:col-span-2">
                                            <Bounce>
                                                <div className="">
                                                <a href="#x" className="w-fit">
                                                    <div className="bg-main hover:scale-105 text-white text-lg py-5 w-full rounded-lg transition-all text-center">Buy Shares</div>
                                                </a>
                                                </div>
                                            </Bounce>
                                            <Bounce>
                                                <button onClick={() => setViews(!views)} className="hover:scale-105 py-5 rounded-lg w-full text-lg bg-sub text-white transition-all">Manage Shares</button>
                                            </Bounce>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sm:-mt-0 h-[60vh] md:h-[90vh] shs">
                            </div>
                        </div>
                    </div>
                </div>
                {!isLoading && <div className="">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="bg-slate-100">
                            <div className="flex flex-col gap-5 w-11/12 mx-auto py-10 lg:py-20">
                                <div className="text-3xl font-bold mb-2">Interested in {data?.title} Shares?</div>
                                {data?.sharecontents?.map((item, index) => (
                                    <div className="" key={index}>
                                        <div className="">{item.content}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="bg-main !text-white" id="x">
                            <div className="w-11/12 mx-auto py-10 lg:py-20">
                                <div className="w-full text-3xl lg:text-4xl font-extrabold">Become a Share Holder Today</div>
                                <div className="mb-10 font-light">We are excited to have you onboard as a share holder. kindly follow the steps below and open a partnership with us within seconds.</div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                                    <div className="bg-slate-700/80 rounded-lg py-3 px-7 border">
                                        <div className="text-orange-300 font-bold">Public Shares</div>
                                        <div className="font-bold text-2xl break-words">{data?.authorizedText || '--'}</div>
                                    </div>
                                    <div className="bg-slate-700/80 rounded-lg py-3 px-7 border">
                                        <div className="text-orange-300 font-bold">Per Shares</div>
                                        <div className="font-bold text-2xl break-words">{NairaSign}{data?.pricing?.toLocaleString() || '--'}</div>
                                    </div>
                                    <div className="bg-slate-700/80 rounded-lg py-3 px-7 border">
                                        <div className="text-orange-300 font-bold">Minimum Purchase</div>
                                        <div className="font-bold text-2xl break-words">{data?.min?.toLocaleString() || '--'} shares</div>
                                    </div>
                                    <div className="bg-slate-700/80 rounded-lg py-3 px-7 border">
                                        <div className="text-orange-300 font-bold">Maximum Purchase</div>
                                        <div className="font-bold text-2xl break-words">{data?.max?.toLocaleString() || '--'} shares</div>
                                    </div>
                                </div>
                                <div>
                                    <GeneralSharesForm
                                        shares={data}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                <ContactForm />
                <AllSponsors />
            </div>
            <Footer />
        </div>
    )
}