import React, { useState } from 'react'
import { SlArrowDown, SlArrowUp } from 'react-icons/sl'
import { FaqData } from '../utils/PageUtils'

export default function FaqComponent() {
    const [active, setActive] = useState(0)
    const handleActive = val => {
        if (active !== val) {
            setActive(val)
        } else {
            setActive('')
        }
    }
    return (
        <div>
            {FaqData.map((item, index) => (
                <div className="border-b last:border-none" key={index}>
                    <div
                        onClick={() => handleActive(index)}
                        className={`flex items-center justify-between gap-3 cursor-pointer p-3 ${active === index ? 'bg-main text-white' : ' hover:bg-slate-100'}`}>
                        <div className="font-semibold text-sm">{item.q}</div>
                        <div className=""> {active === index ? <SlArrowUp /> : <SlArrowDown />} </div>
                    </div>
                    {active === index && <div className="px-5 pb-5 pt-4 font-thin text-slate-600">{item.a}</div>}
                </div>
            ))}
        </div>
    )
}
