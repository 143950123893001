
import React, { useState } from 'react'
import Formbutton from 'components/utils/Formbutton'
import { ErrorAlert, Errors, SuccessAlert } from 'components/utils/functions'
import { Apis, Authposturl } from 'components/Apis'
import Forminput from 'components/utils/Forminput'
import ModalLayout from 'components/utils/ModalLayout'


export default function DatarewardModal({ closeView, refetch, item }) {
    const [loading, setLoading] = useState(false)
    const [view, setView] = useState(1)
    const [data, setData] = useState({
        bundle: item?.bundle || '',
        amount: item?.amount || '',
    })
    const handleForms = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const SubmitForm = async (e) => {
        e.preventDefault()
        if (!data.bundle) return ErrorAlert('Bundle is required')
        if (!data.amount) return ErrorAlert('Amount to receive is required')
        const forms = {
            ...data,
            id: item?.id || '',
            tag: item?.id ? 'update' : 'create'
        }

        setLoading(true)
        try {
            const response = await Authposturl(Apis.mobilenig.manage_datarewards, forms)
            if (response.status === 200) {
                SuccessAlert(response.msg)
                return refetch()
            } else {
                ErrorAlert(`${response.msg}`)
            }
        } catch (e) {
            Errors(e)
        } finally {
            setLoading(false)
        }
    }
    const SubmitDeleteRequest = async (e) => {
        e.preventDefault()
        const forms = {
            id: item?.id,
            tag: "delete"
        }
        setLoading(true)
        try {
            const response = await Authposturl(Apis.mobilenig.manage_datarewards, forms)
            if (response.status === 200) {
                SuccessAlert(response.msg)
                return refetch()
            } else {
                ErrorAlert(`${response.msg}`)
            }
        } catch (e) {
            Errors(e)
        } finally {
            setLoading(false)
        }
    }
    return (
        <ModalLayout closeView={closeView}>
            {view === 1 && <div className="p-4 mt-10">
                <div className="font-bold text-xl mb-5">{item?.id ? 'Update Reward' : 'Add New Reward'}
                    {item?.id && <button
                        onClick={() => setView(2)}
                        className="bg-red-500 ml-3 text-white py-2 px-5 rounded-md text-sm capitalize">delete</button>}
                </div>
                <form>
                    <div className="mb-4">
                        <div className="text-sm">Bundle (MB)</div>
                        <Forminput label={'Bundle'} formName={'bundle'} value={data.bundle} onChange={handleForms} />
                    </div>
                    <div className="mb-4">
                        <div className="text-sm">Amount to Receive</div>
                        <Forminput label={'Amount to receive'} formName={'amount'} value={data.amount} onChange={handleForms} />
                    </div>
                    <Formbutton
                        handleSubmission={SubmitForm}
                        loading={loading}
                        title="Submit"
                    />
                </form>
            </div>}
            {view === 2 && <div className="p-4 mt-10">
                <div className="font-bold text-xl mb-5">Delete <span className="text-red-400">{item.bundle}MB</span> reward </div>
                <div className="text-center mb-10">Are you sure you want to delete this reward?</div>
                <form>
                    <Formbutton
                        handleSubmission={SubmitDeleteRequest}
                        loading={loading}
                        title="proceed"
                    />
                </form>
            </div>}
        </ModalLayout>
    )
}

