

import React, { useCallback, useEffect, useState } from 'react'
import ALayout from 'components/admin/ALayout'
import AHeader from 'components/admin/AHeader'
import { Apis, Authgeturl } from 'components/Apis'
import { Errors, NairaSign, SingleServiceStatus } from 'components/utils/functions'
import moment from 'moment'
import Spinner from 'components/utils/Spinner'
import WithdrawOrderModal from './WithdrawOrderModal'

const Tableheaders = [
    "user",
    "amount",
    "tag",
    "withdrawal option",
    "status",
    "date created",
]

export default function AllWithdrawOrders () {
    const [plans, setPlans] = useState([])
    const [loading, setLoading] = useState(false)
    const [single, setSingle] = useState({})
    const [view, setView] = useState(false)

    const FetchAllPlans = useCallback(async () => {
        setLoading(true)
        try {
            const res = await Authgeturl(Apis.investments.all_withdraw_orders)
            if (res.status === 200) return setPlans(res.msg)
        } catch (error) {
            Errors(error)
        } finally {
            setLoading(false)
        }
    }, [])
    useEffect(() => { FetchAllPlans() }, [FetchAllPlans])

    const handleSingle = val => {
        setSingle(val)
        setView(!view)
    }
    return (
        <ALayout>
            {view && <WithdrawOrderModal
                data={single}
                closeView={() => setView(!view)}
                refetch={() => { FetchAllPlans(); setView(!view) }}
            />}
            <div className="">
                <AHeader
                    title="total investment witdrawal orders"
                    total={plans.length}
                    url={`/admin/plans/new`}
                    link={`create plan`}
                />
                <div className="overflow-x-auto scrollsdown">
                <div className="mt-10 w-full">
                    {!loading && <table className='table table-auto w-full bg-white p-3 text-sm'>
                        <thead>
                            <tr className='bg-slate-700 text-white'>
                                {Tableheaders.map((ele, i) => (
                                    <td className='capitalize p-3' key={i}>{ele}</td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {plans.map((item, i) => (
                                <tr 
                                key={i}
                                className='cursor-pointer border-b border-r border-l'
                                onClick={() => handleSingle(item)}
                                >
                                    <td className='p-3 uppercase'>{item.order?.firstname} {item.order?.lastname}</td>
                                    <td className='p-3'>{NairaSign}{item.amount?.toLocaleString()}</td>
                                    <td className='p-3'>{item.title}</td>
                                    <td className='p-3 uppercase'>{item.options}</td>
                                    <td className={`p-3 capitalize ${SingleServiceStatus(item.status)}`}>{item.status}</td>
                                    <td className='p-3'>{moment(item.createdAt).format('DD-MM-YYYY hh:ss A')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>}
                    {loading && <Spinner /> }
                </div>
                </div>
            </div>
        </ALayout>
    )
}


