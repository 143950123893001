import React, { useState } from 'react'
import SideViewModalLayout from 'components/utils/SideViewModalLayout'
import { Apis, Authposturl, imgurl } from 'components/Apis'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { ErrorAlert, Errors, SuccessAlert } from 'components/utils/functions'
import Formbutton from 'components/utils/Formbutton'
import Imaged from 'components/utils/Imaged'

const FeedModal = ({ closeView, refetch, data }) => {
    const [loading, setLoading] = useState(false)

    const handleDelete = async () => {
        setLoading(true)
        try {
            const res  = await Authposturl(Apis.feeds.delete_feed, {id: data.id})
            setLoading(false)
            if(res.status === 200) {
                SuccessAlert(res.msg)
                refetch()
            }else {
                ErrorAlert(res.msg)
            }
        } catch (error) {
            setLoading(false)
            Errors(error)
        }
    }
    return (
        <SideViewModalLayout closeView={closeView}>
            <div className="">
                <div className="mb-4">
                    {data.image && <Imaged src={data.image} alt="" className="w-full h-auto" />}
                    {!data.image && <div>&nbsp;</div>}
                </div>
                <div className="p-4">
                    <div className="text-base font-medium">{data.title}</div>
                    <div className="text-sm">Posted: {moment(data.createdAt).format('DD-MM-YYYY h:sA')}</div>
                    <div className='text-sm mt-6' dangerouslySetInnerHTML={{ __html: data.content }} />
                    <div className="grid grid-cols-2 py-8 px-3">
                        <Formbutton 
                        title="delete"
                        loading={loading}
                        handleSubmission={handleDelete}
                        font="capitalize py-3 px-4 rounded-full"
                        bg="bg-red-600"
                        />
                        <Link to={`/admin/feeds/edit/${data.slug}/${data.id}`} className='bg-slate-300 text-sm capitalize py-3 px-4 w-fit rounded-lg ml-auto font-medium'>update feed</Link>
                    </div>
                </div>
            </div>
        </SideViewModalLayout>
    )
}

export default FeedModal