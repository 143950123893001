
import ALayout from 'components/admin/ALayout'
import { NairaSign } from 'components/utils/functions'
import { Apis, Authgeturl } from 'components/Apis'
import moment from 'moment'
import AHeader from 'components/admin/AHeader'
import { useQuery } from '@tanstack/react-query'
import Spinner from 'components/utils/Spinner'
import { useNavigate } from 'react-router-dom'

const Tableheaders = [
    "account number",
    "account name",
    "balance",
    "phone",
    "bvn",
    "email",
    "created at",
    "updated at",
    "settlement type",
    "notification status",
    "Settlement accountno",
    "Settment bankcode",
    "nin",
    "id card url"

]


export default function AllVirtualAccounts() {
    const navigate = useNavigate()

    const { data, isLoading } = useQuery({
        queryKey: ['wp-all-virtual-accounts'],
        queryFn: async () => {
            const res = await Authgeturl(Apis.wireless.all_virtual_accounts)
            return res
        }
    })

    if (isLoading) return (
        <ALayout>
            <div className="">
                <AHeader
                    title="Api Wallet Balance"
                    total={`${0}`}
                    url={false}
                />
                <div className="mt-10 flex flex-col items-center justify-center gap-5">
                    <Spinner />
                    <div className="animate-pulse">Loading Data....</div>
                </div>
            </div>
        </ALayout>
    )

    return (
        <ALayout>
            <div className="">
                <AHeader
                    title="Total Virtual Accounts"
                    total={`${data?.data?.length ?? 0}`}
                    url={false}
                />
                <div className="mt-10 overflow-x-auto scrollsdown">
                    <table className='table table-auto w-full bg-white p-3 text-sm'>
                        <thead>
                            <tr className='bg-slate-700 text-white'>
                                {Tableheaders.map((ele, i) => (
                                    <td className='capitalize p-3 truncate' key={i}>{ele}</td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data?.data?.map((item, i) => (
                                <tr
                                    onClick={() => navigate(`/admin/virtual_account/${item.account_number}`)}
                                    key={i}
                                    className='border-b border-r cursor-pointer hover:bg-slate-100 transition-all border-l'>
                                    <td className='p-3 truncate'>{item.account_number}</td>
                                    <td className='p-3 truncate'>{item.account_name}</td>
                                    <td className='p-3 truncate'>{NairaSign}{item.balance?.toLocaleString()}</td>
                                    <td className='p-3 truncate'>{item.phone}</td>
                                    <td className='p-3 truncate'>{item.bvn}</td>
                                    <td className='p-3 truncate'>{item.email}</td>
                                    <td className='p-3 truncate'>{moment(item.created_at).format('DD-MM-YYYY hh:ss A')}</td>
                                    <td className='p-3 truncate'>{moment(item.updated_at).format('DD-MM-YYYY hh:ss A')}</td>
                                    <td className='p-3 truncate'>{item.settlement_type}</td>
                                    <td className='p-3 truncate'>{item.notification_status}</td>
                                    <td className='p-3 truncate'>{item.settlement_accountno ?? "NIL"}</td>
                                    <td className='p-3 truncate'>{item.settlement_bankcode ?? "NIL"}</td>
                                    <td className='p-3 truncate'>{item.nin ?? "NIL"}</td>
                                    <td className='p-3 truncate'>{item.id_card_url ?? "NIL"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </ALayout>
    )
}

