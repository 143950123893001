

import { dispatchUser } from 'app/reducer'
import UserLayout from 'components/user/UserLayout'
import BankOptions from 'components/utils/BankOptions'
import Formbutton from 'components/utils/Formbutton'
import Forminput from 'components/utils/Forminput'
import { ErrorAlert, NairaSign } from 'components/utils/functions'
import Spinner from 'components/utils/Spinner'
import React, { useState } from 'react'
import { FaArrowRight } from 'react-icons/fa6'
import { PiSealCheckFill } from 'react-icons/pi'
import { useDispatch } from 'react-redux'
import ConfirmTransaction from './Compos/ConfirmTransaction'
import SuccessModal from 'components/utils/SuccessModal'
import { usePDF } from "react-to-pdf";
import BeneficiaryModal from './Compos/BeneficiaryModal'

import { useQuery } from '@tanstack/react-query'
import { Apis, Authgeturl, Authposturl } from 'components/Apis'
import { BeneficiaryColorCodes, siteName } from 'components/utils/functions'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import logo from "assets/main-images/fav.png"
import banklogo from "assets/main-images/bn.png"
import Imaged from 'components/utils/Imaged'
import { SlArrowRight } from 'react-icons/sl'
import { ApiCountryFlags } from 'components/utils/CountryCodes'


const NiergiaOptions = [
    {
        img: logo,
        title: `${siteName} User Account`,
        content: `Send directly to another ${siteName} user account seamlessly`,
        tag: 'across-accounts'
    },
    {
        img: banklogo,
        title: 'NGN Bank Accounts',
        content: 'Send to a bank account',
        tag: 'across-banks'
    }
]



const AmountOptions = [
    500, 1000, 2000, 3000, 5000, 10000, 15000, 20000
]


function SendMoneyToSomeone({ title, bank_account_number, bank_name, account_name, bank_code }) {
    const [load2, setLoad2] = useState(false)
    const [load3, setLoad3] = useState(false)
    const [active, setActive] = useState({ status: false, data: {} })
    const [confirms, setConfirms] = useState({ status: false, data: {} })
    const [open, setOpen] = useState({ status: false, data: {} })
    const dispatch = useDispatch()
    const [details, setDetails] = useState({
        bank_code: bank_code ?? '', bank_name: bank_name ?? '', bank_account_number: bank_account_number ?? '', amount: '', narration: '', fee: 20
    })
    const [info, setInfo] = useState({
        account_name: account_name ?? ''
    })

    const date = new Date()

    const { toPDF, targetRef } = usePDF({
        filename: `${date.getFullYear()}_${date.getTime()}.pdf`,
        canvas: {
            useCORS: true,
        },
    });

    const handleDetails = e => {
        setDetails({ ...details, [e.target.name]: e.target.value })
    }

    const Setup = async (val) => {
        setDetails({ ...details, bank_name: val.bank_name, bank_code: val.bank_code })
    }

    async function handleAccountNumber(e) {
        const value = e.target.value
        setDetails({ ...details, bank_account_number: value })
        setInfo({})
        setLoad3(true)
        try {
            if (value.length === 10) {

                const sendForm = {
                    bank_code: details.bank_code, account_number: value
                }
                const response = await Authposturl(Apis.wireless.verify_external_bank, sendForm)
                if (response.status !== 200) return ErrorAlert(`${response.msg}`)
                setInfo(response.msg)
            }
        } catch (error) {
            ErrorAlert(`Wrong account details supplied, check and try again`)
        } finally {
            setLoad3(false)
        }
    }

    function OpenNextModal(values) {
        setConfirms({
            ...confirms,
            status: false
        })
        setOpen({ ...open, data: values })
        SpendCash(values)
    }

    function ConfirmSpendCash() {
        if (!details.bank_name) return ErrorAlert('Provide Banking institute')
        if (!details.bank_code) return ErrorAlert('Provide Banking institute code')
        if (!details.bank_account_number) return ErrorAlert('Provide account number')
        if (!details.amount) return ErrorAlert('Provide amount to send across')
        if (!details.narration) return ErrorAlert('Provide reason for transaction')
        const sendData = {
            ...details,
            account_name: info?.account_name,
            title: "Transaction Successful",
            content: `<div>Congratulations your transfer of <span style="color: #16a34a;font-weight:bold;">${NairaSign}${details.amount.toLocaleString()}</span> to <span style="color: #16a34a;font-weight:bold;">${info.account_name}</span>  was successful</div>`,
            content2: `Congratulations your transfer of ${NairaSign}${details.amount.toLocaleString()} to ${info.account_name}  was successful`
        }
        setConfirms({
            status: true,
            data: sendData
        })
    }

    function sendActiveUser(values) {
        setDetails({ ...details, bank_code: values.bank_code, bank_name: values.bank_name, bank_account_number: values.account_number })
        setInfo({
            account_name: values.fullname
        })
    }
    const SpendCash = async (values) => {
        setLoad2(true)
        try {
            const response = await Authposturl(Apis.wireless.virtual_to_bank, values)
            if (![201, 200].includes(response.status)) return ErrorAlert(`${response.msg}`)
            await new Promise((resolve, reject) => setTimeout(resolve, 3000))
            if (response.status === 200) {
                dispatch(dispatchUser(response.user))
            }
            setOpen({ ...open, status: true })
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoad2(false)
        }
    }
    return (
        <UserLayout navlink={'?'} title={title}>
            {open.status && <SuccessModal
                closeview={() => setOpen({ status: false, data: {} })}
                title={confirms.data.title}
                content2={confirms.data.content2}
                content={confirms.data.content}
                cashback={false}
                DownloadPDF={() => toPDF()}
            />}
            {confirms.status && <ConfirmTransaction
                data={confirms.data}
                onClick={OpenNextModal}
                closeview={() => setConfirms({ status: false, data: {} })}
            />}
            {active.status && <BeneficiaryModal
                closeview={() => setActive({ ...active, status: false })}
                setActive={setActive}
                active={active.data}
                sendActiveUser={sendActiveUser}
            />}
            <div ref={targetRef}>
                {open.status ? <div>
                    <div className="grid grid-cols-5 py-1.5">
                        <div className="col-span-2 text-slate-500">Bank</div>
                        <div className="col-span-3 text-right font-medium">{confirms.data.bank_name}</div>
                    </div>
                    <div className="grid grid-cols-5 py-1.5">
                        <div className="col-span-2 text-slate-500">Account Number</div>
                        <div className="col-span-3 text-right font-medium">{confirms.data.bank_account_number}</div>
                    </div>
                    <div className="grid grid-cols-5 py-1.5">
                        <div className="col-span-2 text-slate-500">Name</div>
                        <div className="col-span-3 text-right font-medium">{confirms.data.account_name}</div>
                    </div>
                    <div className="grid grid-cols-5 py-1.5">
                        <div className="col-span-2 text-slate-500">Amount</div>
                        <div className="col-span-3 text-right font-medium">{NairaSign}{confirms.data.amount.toLocaleString()}</div>
                    </div>
                    <div className="grid grid-cols-5 py-1.5 mb-5">
                        <div className="col-span-2 text-slate-500">Narration</div>
                        <div className="col-span-3 text-right font-medium"> {confirms.data.narration} </div>
                    </div>
                    <div className="grid grid-cols-5 py-1.5 mb-5">
                        <div className="col-span-2 text-slate-500">Status</div>
                        <div className="col-span-3 text-right font-medium"> Successful </div>
                    </div>
                </div> : ''}
            </div>
            <div className="bg-white p-3 rounded-lg w-[98%] mt-5 mx-auto">

                <div className="border-b pb-3 mb-3 pt-5">
                    <div className="font-bold text-2xl">{title}</div>
                    <div className="text-sm text-slate-500">Money sent across will be debited from your wallet instantly, kindly confirm your actions before proceeding.</div>
                </div>

                <div className=''>
                    <div className='mb-10'></div>
                    <div className="grid grid-cols-2">
                        <div className="font-bold text-lg">Beneficiaries</div>
                        <div
                            onClick={() => setActive({ ...active, status: true })}
                            className="flex justify-end items-center gap-2 text-sm cursor-pointer w-fit ml-auto text-mainblue">Select <FaArrowRight /> </div>
                    </div>
                    <div className="mb-4 relative">
                        <div className="">Commercial Institution</div>
                        <BankOptions defaultValue={details?.bank_name} title={details?.bank_name ? details?.bank_name : "--Select--"} setup={Setup} />
                    </div>
                    <div className="mb-3">
                        <div className="text-sm">Account Number</div>
                        <Forminput
                            value={details.bank_account_number}
                            formName={'bank_account_number'}
                            onChange={handleAccountNumber}
                        />
                    </div>
                    {load3 && <div className=""> <Spinner /> </div>}
                    {info?.account_name && <div className="text-sm text-green-800 uppercase py-2 px-3 rounded-lg bg-green-200/40 border border-green-500 flex mb-3 items-center gap-2"> <PiSealCheckFill /> {info.account_name}</div>}

                    <div className="">
                        <div className="text-sm">Amount to withdraw ({NairaSign})</div>
                        <Forminput
                            value={details.amount}
                            onChange={handleDetails}
                            formName={'amount'}
                            label={'0.00'}
                            type="number"
                        />
                    </div>
                    <div className="overflow-x-auto scrollsdown">
                        <div className="flex items-center gap-3 w-fit py-4">
                            {AmountOptions.map((item, index) => (
                                <button
                                    onClick={() => setDetails({ ...details, amount: item })}
                                    key={index} className={`border rounded-md py-2 px-3 hover:scale-110 hover:border-slate-500 hover:shadow-xl hover:font-bold transition-all text-sm truncate ${details.amount === item ? 'scale-110 shadow-xl font-bold border-slate-500' : ''}`}>{NairaSign}{item.toLocaleString()}</button>
                            ))}
                        </div>
                    </div>
                    <div className="text-xs md:text-sm text-slate-500">You will be charged <span className="text-sub">{NairaSign}{details.fee}</span>, on this transaction</div>
                    <div className="mb-3">
                        <div className="text-sm">Reason</div>
                        <Forminput
                            value={details.narration}
                            onChange={handleDetails}
                            formName={'narration'}
                            label={'Provide a narration'}
                        />
                    </div>
                    <Formbutton
                        disable={details.bank_account_number.length === 10 ? false : true}
                        handleSubmission={ConfirmSpendCash}
                        loading={load2}
                        title={'withdraw'}
                    />
                </div>

            </div>
        </UserLayout>
    )
}


function SendMoneyToPeer({ title, bank_account_number, bank_name, account_name, bank_code }) {
    const [load2, setLoad2] = useState(false)
    const [load3, setLoad3] = useState(false)
    const [active, setActive] = useState({ status: false, data: {} })
    const [confirms, setConfirms] = useState({ status: false, data: {} })
    const [open, setOpen] = useState({ status: false, data: {} })
    const dispatch = useDispatch()
    const [details, setDetails] = useState({
        bank_code: bank_code ?? '', bank_name: bank_name ?? '', bank_account_number: bank_account_number ?? '', amount: '', narration: '', fee: 20
    })
    const [info, setInfo] = useState({
        account_name: account_name ?? ''
    })

    const date = new Date()

    const { toPDF, targetRef } = usePDF({
        filename: `${date.getFullYear()}_${date.getTime()}.pdf`,
        canvas: {
            useCORS: true,
        },
    });

    const handleDetails = e => {
        setDetails({ ...details, [e.target.name]: e.target.value })
    }


    async function handleAccountNumber(e) {
        const value = e.target.value
        setDetails({ ...details, bank_account_number: value })
        setInfo({})
        setLoad3(true)
        try {
            if (value.length === 11) {

                const sendForm = {
                    contact: value
                }
                const response = await Authposturl(Apis.wireless.find_peer, sendForm)
                if (response.status !== 200) return ErrorAlert(`${response.msg}`)
                setInfo(response.msg)
            }
        } catch (error) {
            ErrorAlert(`Wrong account details supplied, check and try again`)
        } finally {
            setLoad3(false)
        }
    }

    function OpenNextModal(values) {
        setConfirms({
            ...confirms,
            status: false
        })
        setOpen({ ...open, data: values })
        SpendCash(values)
    }

    function ConfirmSpendCash() {
        if (!details.bank_account_number) return ErrorAlert('Provide account number')
        if (!details.amount) return ErrorAlert('Provide amount to send across')
        if (!details.narration) return ErrorAlert('Provide reason for transaction')
        const sendData = {
            ...details,
            account_name: info?.account_name,
            title: "Transaction Successful",
            content: `<div>Congratulations your transfer of <span style="color: #16a34a;font-weight:bold;">${NairaSign}${details.amount.toLocaleString()}</span> to <span style="color: #16a34a;font-weight:bold;">${info.account_name}</span>  was successful</div>`,
            content2: `Congratulations your transfer of ${NairaSign}${details.amount.toLocaleString()} to ${info.account_name}  was successful`
        }
        setConfirms({
            status: true,
            data: sendData
        })
    }

    function sendActiveUser(values) {
        setDetails({ ...details, bank_code: values.bank_code, bank_name: values.bank_name, bank_account_number: values.account_number })
    }

    const SpendCash = async (values) => {
        const dataToSend = {
            amount: values.amount, narration: values.narration, contact: values.bank_account_number
        }
        setLoad2(true)
        try {
            const response = await Authposturl(Apis.wireless.peer_to_peer, dataToSend)
            if (![201, 200].includes(response.status)) return ErrorAlert(`${response.msg}`)
            await new Promise((resolve, reject) => setTimeout(resolve, 3000))
            if (response.status === 200) {
                dispatch(dispatchUser(response.user))
            }
            setOpen({ ...open, status: true })
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoad2(false)
        }
    }
    return (
        <UserLayout navlink={'?'} title={title}>
            {open.status && <SuccessModal
                closeview={() => setOpen({ status: false, data: {} })}
                title={confirms.data.title}
                content2={confirms.data.content2}
                content={confirms.data.content}
                cashback={false}
                Dismiss={'/transactions'}
                DownloadPDF={() => toPDF()}
            />}
            {confirms.status && <ConfirmTransaction
                data={confirms.data}
                onClick={OpenNextModal}
                closeview={() => setConfirms({ status: false, data: {} })}
            />}
            {active.status && <BeneficiaryModal
                closeview={() => setActive({ ...active, status: false })}
                setActive={setActive}
                active={active.data}
                sendActiveUser={sendActiveUser}
            />}
            <div ref={targetRef}>
                {open.status ? <div>
                    <div className="grid grid-cols-5 py-1.5">
                        <div className="col-span-2 text-slate-500">Bank</div>
                        <div className="col-span-3 text-right font-medium">{confirms.data.bank_name}</div>
                    </div>
                    <div className="grid grid-cols-5 py-1.5">
                        <div className="col-span-2 text-slate-500">Account Number</div>
                        <div className="col-span-3 text-right font-medium">{confirms.data.bank_account_number}</div>
                    </div>
                    <div className="grid grid-cols-5 py-1.5">
                        <div className="col-span-2 text-slate-500">Name</div>
                        <div className="col-span-3 text-right font-medium">{confirms.data.account_name}</div>
                    </div>
                    <div className="grid grid-cols-5 py-1.5">
                        <div className="col-span-2 text-slate-500">Amount</div>
                        <div className="col-span-3 text-right font-medium">{NairaSign}{confirms.data.amount.toLocaleString()}</div>
                    </div>
                    <div className="grid grid-cols-5 py-1.5 mb-5">
                        <div className="col-span-2 text-slate-500">Narration</div>
                        <div className="col-span-3 text-right font-medium"> {confirms.data.narration} </div>
                    </div>
                    <div className="grid grid-cols-5 py-1.5 mb-5">
                        <div className="col-span-2 text-slate-500">Status</div>
                        <div className="col-span-3 text-right font-medium"> Successful </div>
                    </div>
                </div> : ''}
            </div>
            <div className="bg-white p-3 rounded-lg w-[98%] mt-5 mx-auto">

                <div className="border-b pb-3 mb-3 pt-5">
                    <div className="font-bold text-2xl">{title}</div>
                    <div className="text-sm text-slate-500">Money sent across will be debited from your wallet instantly, kindly confirm your actions before proceeding.</div>
                </div>

                <div className=''>
                    <div className="mb-3">
                        <div className="text-sm">Phone Number</div>
                        <Forminput
                        placeholder="Ex. 070 0000 0000"
                            value={details.bank_account_number}
                            formName={'bank_account_number'}
                            onChange={handleAccountNumber}
                        />
                    </div>
                    {load3 && <div className=""> <Spinner /> </div>}
                    {info?.account_name && <div className="text-sm text-green-800 uppercase py-2 px-3 rounded-lg bg-green-200/40 border border-green-500 flex mb-3 items-center gap-2"> <PiSealCheckFill /> {info.account_name}</div>}

                    <div className="">
                        <div className="text-sm">Amount to withdraw ({NairaSign})</div>
                        <Forminput
                            value={details.amount}
                            onChange={handleDetails}
                            formName={'amount'}
                            label={'0.00'}
                            type="number"
                        />
                    </div>
                    <div className="mb-3">
                        <div className="text-sm">Reason</div>
                        <Forminput
                            value={details.narration}
                            onChange={handleDetails}
                            formName={'narration'}
                            label={'Provide a narration'}
                        />
                    </div>
                    <Formbutton
                        disable={details.bank_account_number.length === 11 ? false : true}
                        handleSubmission={ConfirmSpendCash}
                        loading={load2}
                        title={'withdraw'}
                    />
                </div>

            </div>
        </UserLayout>
    )
}



export default function SendMoney() {
    const [searchParams,] = useSearchParams()
    const search = searchParams.get('send')
    const navigate = useNavigate()
    const NigeriaLogo = ApiCountryFlags.find(ele => ele.name === "ng")
    const { data, isLoading } = useQuery({
        queryKey: ['fetch-all-beneficiaries'],
        queryFn: async () => {
            return await Authgeturl(`${Apis.user.my_beneficiaries}?page_size=10`)
        }
    })


    function HandlePageScreens(tag) {
        navigate(`?send=${tag}`)
    }

    if (search === NiergiaOptions[0].tag) {
        return <SendMoneyToPeer title={`Send Across ${siteName} Accounts`} />
    }

    if (search === NiergiaOptions[1].tag) {
        return <SendMoneyToSomeone title={`Send Across Local Banks`} />
    }

    if (search?.startsWith('account')) {
        const accountNumber = search.split('_')[1]
        const findAccount = data?.data?.find(ele => ele.account_number === accountNumber)
        const sendForm = {
            bank_code: findAccount?.bank_code, bank_name: findAccount?.bank_name, bank_account_number: findAccount?.account_number, account_name: findAccount.fullname
        }
        return <SendMoneyToSomeone title={`Send to Beneficiary`} {...sendForm} />
    }

    return (
        <UserLayout title="Send & Transfer money">
            <div className="w-[96%] mx-auto mt-5">
                <div className="flex items-center justify-between p-3">
                    <div className="font-bold uppercase text-sm">Send again</div>
                   {data?.total > 0 && <div className="">
                        <Link to="/all-beneficiaries" className="text-blue-600 font-medium">View all</Link>
                    </div>}
                </div>
                <div className="bg-white p-3">
                    {isLoading && <div className="text-center">Loading...</div>}
                    {!isLoading && data?.total > 0 ? <div className="w-full overflow-x-auto scrollsdown">
                        <div className="w-fit flex items-center gap-3">
                            {data?.data?.map((item, index) => (
                                <Link key={index} to={`?send=account_${item.account_number}`}>
                                    <div className="flex items-center flex-col">
                                        <div className={`rounded-full size-12 grid justify-center items-center text-3xl font-extrabold ${BeneficiaryColorCodes[index]} text-white`}>{item?.fullname.charAt(0)}</div>
                                        <div className="text-center text-xs truncate">{item.fullname?.slice(0, 15)}...</div>
                                        <div className="text-center text-xs truncate">{item.bank_name?.slice(0, 10)}...</div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div> : <div className="w-full overflow-x-auto scrollsdown">
                        <div className="w-fit flex items-center gap-3">
                            {new Array(10).fill(0).map((item, index) => (
                                <div key={index}>
                                    <div className="flex items-center flex-col">
                                        <div className={`rounded-full size-12 grid justify-center items-center text-3xl font-extrabold bg-slate-200 text-white`}></div>
                                        <div className="text-center bg-slate-200 w-full h-2 mt-1 rounded-sm"></div>
                                        <div className="text-center bg-slate-200 w-3/5 h-2 mt-1 rounded-sm"></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>}
                </div>

                <div className="mt-5 mb-3 font-bold uppercase text-sm flex items-center gap-2">Send within nigeria {NigeriaLogo && <Imaged src={NigeriaLogo.data} className="size-6" />} </div>
                <div className="bg-white p-3">
                    <div className="">
                        {NiergiaOptions.map((item, index) => (
                            <div
                                onClick={() => HandlePageScreens(item.tag)}
                                key={index} className="grid grid-cols-7 mb-3 py-3 cursor-pointer items-center border-b last:border-none">
                                <div className="col-span-6">
                                    <div className="flex item-center gap-2">
                                        <div className="p-2 rounded-md bg-slate-100 self-center"> <Imaged src={item.img} className="h-8 w-8 object-contain" /> </div>
                                        <div className="">
                                            <div className="font-bold">{item.title}</div>
                                            <div className="text-xs text-slate-500">{item.content}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-1 w-fit self-center ml-auto"> <SlArrowRight /> </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </UserLayout>
    )
}
