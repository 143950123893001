
import { useLayoutEffect, useState } from 'react'
import { Apis, Authgeturl } from 'components/Apis'
import {useDispatch} from 'react-redux'
import { dispatchUser } from 'app/reducer'
import {useNavigate} from 'react-router-dom'

const SuperadminPrivacy = ({children}) => {
  const dispatch = useDispatch()
  const [login, setLogin] = useState(false)
  const navigate = useNavigate()
    useLayoutEffect(() => {
        const FetchProfile = async () => {
          try {
            const res = await Authgeturl(Apis.user.profile)
            if(res.status === 200) {
              if(res.msg.role === 'superadmin') {
                setLogin(true)
                dispatch(dispatchUser(res.msg))
              }else {
                navigate('/login')
              }
            }else {
              setLogin(false)
              navigate('/login')
            }
           } catch (error) {
              setLogin(false)
              navigate('/login')
            }
        }
        FetchProfile()
    }, [])
  if(login) return children
}

export default SuperadminPrivacy