import { useQuery } from '@tanstack/react-query'
import AHeader from 'components/admin/AHeader'
import ALayout from 'components/admin/ALayout'
import { Apis, Authgeturl } from 'components/Apis'
import { Errors, NairaSign } from 'components/utils/functions'
import ModalLayout from 'components/utils/ModalLayout'
import Spinner from 'components/utils/Spinner'
import moment from 'moment'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

const Tableheaders = [
    "reference",
    "amount",
    "charge",
    "created_at",
    "destination_account",
    "transaction_type_name"
]

const TransactionType = (val) => {
    if(val.toLowerCase() === 'debit') return `bg-sub py-1 px-3 text-white rounded-md text-sm`
    if(val.toLowerCase() === 'credit') return `bg-green-500 py-1 px-3 text-white rounded-md text-sm`
}

export default function VcTransactionHistory() {
    const { id } = useParams()
    const [view, setView] = useState({
        status: false,
        data: {}
    })

    const { data, isLoading } = useQuery({
        queryKey: [`wp-all-virtual-account-histories-${id}`],
        queryFn: async () => {
            try {
                const res = await Authgeturl(`${Apis.wireless.all_vc_transactions}/${id}`)
                return res
            } catch (error) {
                Errors(error)
            }
        }
    })
    if (isLoading) return (
        <ALayout>
            <div className="">
                <AHeader
                    title="Api Wallet Balance"
                    total={`${0}`}
                    url={false}
                />
                <div className="mt-10 flex flex-col items-center justify-center gap-5">
                    <Spinner />
                    <div className="animate-pulse">Loading Data....</div>
                </div>
            </div>
        </ALayout>
    )

    return (
        <ALayout>
            {view.status && <ModalLayout closeView={() => setView({ status: false, data: {} })}>
                <div className="p-5">
                <div className="p-5 flex items-center gap-2">
                    <div className="text-xl font-bold">Transaction Details</div>
                    <div className={`${TransactionType(view.data.transaction_type_name)}`}>{view.data.transaction_type_name}</div>
                </div>
                <div className="grid grid-cols-2 gap-5 p-2">
                    <div className="">Reference</div>
                    <div className="text-right">{view.data.reference}</div>
                </div>
                <div className="grid grid-cols-2 gap-5 p-2">
                    <div className="">Amount</div>
                    <div className="text-right">{NairaSign}{parseFloat(view.data.amount)?.toLocaleString()}</div>
                </div>
                <div className="grid grid-cols-2 gap-5 p-2">
                    <div className="">Charge</div>
                    <div className="text-right">{NairaSign}{parseFloat(view.data.charge)?.toLocaleString()}</div>
                </div>
                <div className="grid grid-cols-2 gap-5 p-2">
                    <div className="">Balance before</div>
                    <div className="text-right">{NairaSign}{parseFloat(view.data.balance_before)?.toLocaleString()}</div>
                </div>
                <div className="grid grid-cols-2 gap-5 p-2">
                    <div className="">Balance after</div>
                    <div className="text-right">{NairaSign}{parseFloat(view.data.balance_after)?.toLocaleString()}</div>
                </div>
                <div className="grid grid-cols-2 gap-5 p-2">
                    <div className="">final Amount</div>
                    <div className="text-right">{NairaSign}{parseFloat(view.data.final_amount)?.toLocaleString()}</div>
                </div>
                <div className="grid grid-cols-2 gap-5 p-2">
                    <div className="">Naration</div>
                    <div className="text-right">{view.data.naration}</div>
                </div>
                <div className="grid grid-cols-2 gap-5 p-2">
                    <div className="">Transaction Date</div>
                    <div className="text-right">{moment(view.data.created_at).format('DD-MM-YYYY hh:ss A')}</div>
                </div>
                </div>
            </ModalLayout>}
            <div className="">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                    <AHeader
                        title="Total Credits"
                        total={`${NairaSign}${data.summary?.credti?.toLocaleString() ?? 0}`}
                        url={false}
                    />
                    <AHeader
                        title="Total Debits"
                        total={`${NairaSign}${data.summary?.debit?.toLocaleString() ?? 0}`}
                        url={false}
                    />
                </div>
                <div className="mt-10 overflow-x-auto scrollsdown">
                    <table className='table table-auto w-full bg-white p-3 text-sm'>
                        <thead>
                            <tr className='bg-slate-700 text-white'>
                                {Tableheaders.map((ele, i) => (
                                    <td className='capitalize p-3 truncate' key={i}>{ele}</td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data?.data?.map((item, i) => (
                                <tr
                                onClick={() => setView({status: true, data: item})}
                                    key={i}
                                    className='border-b border-r cursor-pointer hover:bg-slate-100 transition-all border-l'>
                                    <td className='p-3 truncate'>{item.reference}</td>
                                    <td className='p-3 truncate'>{NairaSign}{parseFloat(item.amount)?.toLocaleString()}</td>
                                    <td className='p-3 truncate'>{NairaSign}{parseFloat(item.charge)?.toLocaleString()}</td>
                                    <td className='p-3 truncate'>{moment(item.created_at).format('DD-MM-YYYY hh:ss A')}</td>
                                    <td className='p-3 truncate'>{item.destination_account}</td>
                                    <td className='p-3 truncate'>{item.transaction_type_name ?? "NIL"}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </ALayout>
    )
}

