import React from 'react'

const Forminput = ({ label, type, refid = null, content, onChange, onKeyup, formName, value, formtype = 'text', children, min, max, ...props }) => {

    return (
        <div className="relative mb-4">
            <div className="">{content}</div>
            {formtype === 'text' ?
                <input onKeyUp={onKeyup} {...props} autoComplete='false' minLength={min} maxLength={max} ref={refid} type={type} name={formName} value={value} className="input" placeholder={label} onChange={onChange} /> :
                formtype === 'textarea' ?
                <textarea  {...props} autoComplete='false' ref={refid} name={formName} value={value} className="input" placeholder={label} onChange={onChange}></textarea> :
                formtype === 'select' ?
                    <select {...props} name={formName} value={value} className="inputselect" onChange={onChange}>
                        <option value="">--Select--</option>
                        {children}
                    </select> :
                        <textarea {...props} onChange={onChange} name={formName} value={value} className='resize-none input scrolls' placeholder={label}></textarea> 

            }
        </div>
    )
}

export default Forminput

