



import React, { useRef, useState } from 'react'
import UserLayout from 'components/user/UserLayout'
import { ClientInvestmentStatus, ErrorAlert, NairaSign, SuccessAlert } from 'components/utils/functions'
import { FaEllipsisV, FaSignOutAlt } from 'react-icons/fa'
import { SlClock, SlOptions } from 'react-icons/sl'
import { BsBarChartFill } from 'react-icons/bs'
import { Link, useParams } from 'react-router-dom'
import InvestLayout from '../../Compos/InvestLayout'
import { useDispatch, useSelector } from 'react-redux'
import WithdrawOrder from '../WithdrawOrder'
import InvestAgreeSlip from '../../Compos/InvestAgreeSlip'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Apis, Authgeturl, Authposturl } from 'components/Apis'
import { MdHeadsetMic } from 'react-icons/md'
import InvestManager from '../../Compos/InvestManager'
import MidasBoard from './MidasBoard'
import { TfiReload } from 'react-icons/tfi'
import { IoStatsChart } from 'react-icons/io5'
import { dispatchActivePlan, dispatchPlan } from 'app/reducer'

export default function Midas() {
    const queryClient = useQueryClient();
    const togref = useRef()
    const togref1 = useRef()
    const [screen, setScreen] = useState({
        status: false,
        tag: null
    })
    const { plan, activeplan } = useSelector(state => state.data)
    const [stat, setStat] = useState(false)
    const [md, setMd] = useState(false)
    const { id } = useParams()
    const dispatch = useDispatch()

    const { isLoading, refetch } = useQuery({
        queryKey: [`get-investment-agree-${id}`],
        queryFn: async () => {
            const res = await Authgeturl(`${Apis.investments.stock}/${id}`)
            if (res.status === 200) {
                if (res.msg.agree === 'no') {
                    setStat(true)
                } else {
                    setStat(false)
                }
            }
            return res.msg
        }
    })

    const handleScreens = (val) => {
        setScreen({
            status: true,
            tag: val
        })
    }

    if (isLoading) return (
        <div></div>
    )

    const ReloadInvestment = () => {
        togref.current.classList.add('anti-clockwise')
        setTimeout(() => {
            togref.current.classList.remove('anti-clockwise')
            if (activeplan?.id) {
                // queryClient.refetchQueries([`invest-layout-${id}`]);
                queryClient.refetchQueries({ queryKey: [`invest-layout-${id}`] }, { force: true });
                setTimeout(() => {
                    queryClient.refetchQueries({ queryKey: [`invest-layout-${id}`] }, { force: true });
                }, 500);
            } else {
                SuccessAlert(`No Active Investment found`)
            }
        }, 1000)
    }

    const handleReinvestment = async () => {
        togref1.current.classList.add('anti-clockwise')
        setTimeout(async () => {
            try {
                const response = await Authposturl(`${Apis.investments.reinvest}/${plan.id}`)
                if (response.status === 200) {
                    SuccessAlert(`Re-invesment successfully activated`)
                    queryClient.refetchQueries({ queryKey: [`invest-layout-${id}`] }, { force: true });
                    setTimeout(() => {
                        queryClient.refetchQueries({ queryKey: [`invest-layout-${id}`] }, { force: true });
                    }, 500);
                } else {
                    ErrorAlert(response.msg)
                }
            } catch (error) {
                ErrorAlert(`${error.message}`)
            } finally {
                togref1.current.classList.remove('anti-clockwise')
            }
        }, 1000);
    }

    return (
        <UserLayout title="Midas Funds" paddings={false}>
            {screen.status && <WithdrawOrder
                closeView={() => setScreen({ ...screen, status: false })}
            />}
            {stat && <InvestAgreeSlip refetch={refetch} closeView={() => SuccessAlert('agree to our terms of usage')} tag={'prime'} id={plan.id} />}

            <InvestLayout>
                <div className="mainbg py-5 relative">
                    <div className="flex items-center justify-end gap-10 mb-2 w-11/12 mx-auto">
                        <div className="">
                            <div className="text-white text-2xl cursor-pointer" onClick={() => setMd(prev => !prev)}>
                                <MdHeadsetMic />
                            </div>
                            {md && <InvestManager manager={plan.plans?.md[0]} closeView={() => setMd(false)} />}
                        </div>
                        <div className="">
                            <Link to={`/investment/${plan.id}/midas/manage`}
                                className="text-white text-xl"> <FaEllipsisV /> </Link>
                        </div>
                        <div ref={togref} className='text-2xl text-white cursor-pointer' onClick={ReloadInvestment}>
                            <TfiReload />
                        </div>
                    </div>
                    <MidasBoard
                        activeplan={activeplan}
                        plan={plan}
                    />
                </div>
                <div className="">
                    <div className="">

                        <div className=" dark:bg-slate-600 bg-white rounded-lg px-3 pb-3 pt-10">
                            <div className="flex items-center justify-end gap-5">
                                <button
                                    onClick={handleReinvestment}
                                    className="py-2 px-4 rounded-lg bg-main text-white text-sm capitalize font-bold flex items-center gap-3"> <IoStatsChart /> Re-invest <div ref={togref1}><TfiReload /></div> </button>
                                <button
                                    onClick={() => handleScreens('withdraw')}
                                    className="py-2 px-4 rounded-lg border text-sm capitalize font-bold flex items-center gap-3"> <FaSignOutAlt /> withdraw</button>
                            </div>
                            <div className="flex items-center justify-between border-b py-2">
                                <div className="text-sm capitalize">Investment statistics</div>
                                <div className=""> <SlOptions /> </div>
                            </div>
                            <div className="grid grid-cols-2 py-3 border-b">
                                <div className="text-sm capitalize">total earnings</div>
                                <div className="text-right text-sm"> {NairaSign}{(plan.totalEarned + (activeplan ? activeplan.returnProfit : 0))?.toLocaleString() || 0}</div>
                            </div>
                            <div className="grid grid-cols-2 py-3 border-b">
                                <div className="text-sm capitalize">total invested</div>
                                <div className="text-right text-sm">{NairaSign}{plan.totalDept?.toLocaleString() || 0}</div>
                            </div>
                            <div className="grid grid-cols-2 py-3 border-b">
                                <div className="text-sm capitalize">total withdrawn</div>
                                <div className="text-right text-sm"> {NairaSign}{plan.totalWithd?.toLocaleString() || 0} </div>
                            </div>
                        </div>
                        <div className="mt-5 flex text-white items-center justify-center w-10/12 mx-auto shadow-xl">
                            <Link to={`/investment/${id}/midas/trade`} className='w-full bg-slate-700 cursor-pointer text-center py-3 rounded-lg shadow-xl uppercase flex items-center justify-center gap-3 text-xs md:text-sm px-4 md:px-6'>create / track investment <BsBarChartFill /> </Link>
                        </div>
                    </div>
                </div>
            </InvestLayout>

        </UserLayout>
    )
}


