import React, { useState } from 'react'
import UserLayout from 'components/user/UserLayout'
import { SlClock } from 'react-icons/sl'
import ImageModal from 'components/user/ImageModal'
import { Apis, Authgeturl } from 'components/Apis'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { useQuery } from '@tanstack/react-query'
import Imaged from 'components/utils/Imaged'

const FeedPage = () => {
    const [view, setView] = useState(false)
    const [single, setSingle] = useState('')
    const { id } = useParams()

    const { data, isLoading } = useQuery({
        queryKey: [`single-user-feed-${id}`],
        queryFn: async () => {
            const res = await Authgeturl(`${Apis.feeds.single_feed}/${id}`)
            if (res.status === 200) return res.msg
        }
    })
    
    if (isLoading) {
        return (
            <>
                <div className="p-3">
                    <div className="bg-slate-300 dark:bg-slate-700 h-[2rem] w-3/5 ml-auto"></div>
                </div>
                <div className="bg-slate-300 dark:bg-slate-700 h-[15rem]"></div>
                <div className="p-3">
                    <div className="bg-slate-300 dark:bg-slate-700 h-[2rem] mb-3 w-2/3"></div>
                    <div className="bg-slate-300 dark:bg-slate-700 h-[2rem] mb-3"></div>
                    <div className="bg-slate-300 dark:bg-slate-700 h-[2rem] mb-3"></div>
                </div>
            </>
        )
    }

    const handleViewing = val => {
        setView(!view)
        setSingle(val)
    }
    return (
        <UserLayout title="article">
            {view && <ImageModal
                closeView={() => setView(!view)}
                img={single}
            />}
            <div className="w-11/12 mx-auto dark:text-slate-100">
                <div className="">
                    <div className="grid grid-cols-2 mb-3">
                        <div className=""></div>
                        <div className="text-sm text-right flex items-center gap-2 justify-end"> <SlClock /> {moment(data.createdAt).format('DD-MM-YYYY h:sA')} </div>
                    </div>
                    <div className=""> {data.image ? <Imaged onClick={() => handleViewing(data.image)} src={data.image} alt="" className="w-full max-h-[20rem] cursor-pointer object-cover" /> : ''} </div>
                    <div className="text-sm text-blue-600 dark:text-blue-300 pt-3 whitespace-pre-wrap text-justify"> {data.title} </div>
                    <div className="text-sm mt-5 whitespace-pre-wrap text-justify" dangerouslySetInnerHTML={{ __html: data.content }} />
                </div>
            </div>
        </UserLayout>
    )
}

export default FeedPage