
import React, { useCallback, useEffect, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { BiMessage, BiSolidMessage } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { ErrorAlert, Errors, SuccessAlert } from 'components/utils/functions'
import { Apis, Authgeturl, Authposturl, Authputurl, SocketConnect } from 'components/Apis'
import AllChatUsers from './AllChatUsers'
import ChatComponent from '../../user/ChatSupport/ChatComponent'
import { FaArrowLeft } from 'react-icons/fa6'
import { MdOutlineMessage, MdPeopleAlt } from 'react-icons/md'
import { SlLogout } from 'react-icons/sl'

export default function AllChats() {
    const [screen, setScreen] = useState(1)
    const [views, setViews] = useState(false)
    const [loading, setLoading] = useState(true)
    const { user } = useSelector(state => state.data)
    const [single, setSingle] = useState({})
    const [data, setData] = useState([])


    const OpenChatBox = () => {
        setViews(true)

    }
    const GetChats = useCallback(async (stat) => {
        setLoading(true)
        try {
            const response = await Authgeturl(Apis.feeds.all_rooms)
            if (response.status === 200) {
                setData(response.msg)
                if (stat === 'new') {
                    if (response.msg?.length < 1) return setScreen(0)
                    if (single?.id && response.msg?.length > 0) return setScreen(1)
                }
            }
        } catch (error) {
            Errors(`${error.message}`)
        } finally {
            setLoading(false)
        }
    }, [data])

    // listen for a room 
    useEffect(() => {
        SocketConnect.on('room-joined', () => { GetChats('new') })
        SocketConnect.on('all-chats', () => { GetChats('old') })
        SocketConnect.on('connected-room', (value) => {
            if (value.status === 'old') {
                GetChats('old')
            } else {
                GetChats('new')
            }
        })
    }, [SocketConnect])

    const OpenRoom = async (item) => {
        // join a room
        // check if agent is already connected and the stale time it took to join the room
        try {
            const formdata = {
                roomid: item.id,
                agent: user.tagged
            }
            if (item.agent === null) {
                const response = await Authputurl(Apis.feeds.agent_join_room, formdata)
                if (response.status !== 200) return ErrorAlert(`${response.msg}`)
            }
            const rumData = {
                roomid: `room-${item.id}`
            }
            SocketConnect.emit('send-chat-message', rumData)
            await GetChats('old')
            const roomdata = { room: `room-${item.id}` }
            SocketConnect.emit('join-room', roomdata)
            return sendSignal(item.id)
        } catch (error) {
            Errors(`${error.message}`)
        }
    }

    const sendSignal = async (id) => {
        const response = await Authgeturl(Apis.feeds.all_rooms)
        if (response.status === 200) {
            setData(response.msg)
            const getSingle = response.msg.find(ele => ele.id === id)
            if (getSingle) {
                setSingle(getSingle)
            }
            setScreen(2)
        }
    }
    const ConfirmChatEndSession = async (id) => {
        try {
            const formdata = {
                roomid: single.id,
                tag: single.admin?.email
            }
            const response = await Authposturl(Apis.feeds.end_chat_session, formdata)
            if (response.status === 200) {
                sendSignal(id)
                const itemdata = {
                    roomid: `room-${single.id}`
                }
                SocketConnect.emit('end_chat_session', itemdata)
                setScreen(1)
            }
        } catch (error) {
            Errors(`${error.message}`)
        }
    }
    const EndChatSession = async () => {
        setScreen(3)
    }
    return (
        <>
            <div className="mt-5 border-t pt-5 mb-2">
                <button
                    onClick={() => { GetChats('new'); OpenChatBox() }}
                    className="bg-main text-white p-3 5 rounded-lg w-full shadow-2xl active:scale-90 flex items-center justify-between"> <span>Proceed</span> <BiMessage className='text-3xl' /> </button>
            </div>
            {views && <div className='fixed top-0 left-0 w-full h-screen z-30 bg-black/40 lg:pr-3'>
                <div className={`w-full lg:w-[30rem] bg-white h-screen lg:h-[85dvh] lg:ml-auto lg:mt-[5.5rem] lg:rounded-lg`}>
                    <div className="bg-main p-3 lg:rounded-tr-lg lg:rounded-tl-lg">
                        <div className="flex items-center justify-between">
                            <div className="">
                                {screen !== 1 && <button
                                    onClick={() => setScreen(1)}
                                    className='text-2xl text-white'> <FaArrowLeft /> </button>}
                            </div>
                            <div className="flex items-center gap-8">
                                <button
                                    title="Close Chat Window"
                                    onClick={() => setViews(prev => !prev)}
                                    className='p-2 rounded-full bg-white'> <FaTimes /> </button>
                                {screen !== 1 && single.active === 'yes' && <button
                                    title='End Chat'
                                    onClick={() => EndChatSession(single)}
                                    className='text-white text-2xl rounded-full'> <SlLogout /> </button>}
                            </div>
                        </div>
                    </div>
                    {screen === 0 && <>
                        <div className="w-full h-[83dvh] lg:h-[69.8dvh] flex-col gap-10 relative">
                            <div className="flex items-center justify-center w-full h-full flex-1 flex-col text-slate-500">
                                <MdPeopleAlt className='text-5xl' />
                                <div className="">No live chat support requests yet!..</div>
                            </div>
                        </div>
                    </>}
                    {screen === 1 && <>
                        <div className="w-full h-[95dvh] pb-20 lg:h-[69.8dvh] overflow-y-auto scrolls flex-col gap-10 relative">
                            {loading ?
                                new Array(5).fill(0).map((item, index) => (
                                    <div className="bg-slate-200 h-16 mt-3 rounded-lg" key={index}></div>
                                ))
                                : <AllChatUsers
                                    OpenRoom={OpenRoom}
                                    users={data} />}
                        </div>
                    </>}
                    {screen === 2 && <ChatComponent
                        data={single}
                        email={user.email}
                        usertype="staff"
                        sendSignal={sendSignal}
                    />}
                    {screen === 3 && <>
                        <div className="w-full h-[83dvh] lg:h-[69.8dvh] flex-col gap-10 relative">
                            <div className="flex items-center justify-center w-full h-full flex-1 gap-10 flex-col text-slate-500">
                                <div className="text-center">Kindly confirm your request to end current chat</div>
                                <div className="flex items-center gap-10">
                                    <button onClick={() => sendSignal(single.id)} className="bg-slate-400 text-white py-1.5 px-5 rounded-md text-sm">Cancel</button>
                                    <button onClick={() => ConfirmChatEndSession(single.id)} className="bg-main text-white py-1.5 px-5 rounded-md text-sm">Proceed</button>
                                </div>
                            </div>
                        </div>
                    </>}
                </div>
            </div>}
        </>
    )
}


