
import React, { Suspense, useState } from 'react'
import UserLayout from 'components/user/UserLayout'
import { BsEyeFill, BsEyeSlashFill } from 'react-icons/bs'
import avatar from 'assets/images/avatar.png'
import { MdVerified } from 'react-icons/md'
import { FaArrowRight, FaAsterisk } from 'react-icons/fa'
import { NairaSign, Swals, viewBal } from 'components/utils/functions'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { AllServiceLinks, UserDashboardMajors } from 'components/utils/PageUtils'
import { imgurl } from 'components/Apis'
import Imaged from 'components/utils/Imaged'
import { VscUnverified } from 'react-icons/vsc'
const MyTransactions = React.lazy(() => import('./Compos/MyTransactions'))

const Dashboard = () => {
  const { user, services } = useSelector(state => state.data)
  const [viewBalance, setViewBalance] = useState(JSON.parse(localStorage.getItem(viewBal)) || 'false')
  const BalIcon = viewBalance === 'false' ? BsEyeFill : BsEyeSlashFill
  // const shortletService = AllServiceLinks.find(ele => ele.title.startsWith('shortlet'))
  const servicetandh = AllServiceLinks.find(ele => ele.title.startsWith('tours'))
  const servicecrypto = AllServiceLinks.find(ele => ele.title.startsWith('buy/sell crypto'))
  const serviceglobal = AllServiceLinks.find(ele => ele.title.startsWith('global transfer'))

  const ToggleBalanceView = () => {
    const local = JSON.parse(localStorage.getItem(viewBal))
    if (local === 'false') {
      localStorage.setItem(viewBal, JSON.stringify('true'))
      setViewBalance('true')
    } else {
      localStorage.setItem(viewBal, JSON.stringify('false'))
      setViewBalance('false')
    }
  }


  return (
    <UserLayout title="home" bgmain="">
      <div className="w-11/12 mx-auto">
        <div className="flex gap-2 mb-3">
          <div className=""><Imaged src={user?.image ? `${imgurl}/profile/${user?.image}` : avatar} alt="" className="w-16 h-16 rounded-full object-cover" /></div>
              <div>
                <div>Hi</div>
              <div className="font-bold flex uppercase text-sm">
                {user.firstname} {user.lastname} {user.account?.status ? <MdVerified className="text-blue-600" /> : <VscUnverified className="text-red-600" /> }
              </div>
              </div>
        </div>
        <div className="bg-main px-3 py-6 rounded-lg mb-3">
          <div className="w-11/12 mx-auto flex items-center text-white justify-between mb-2">
            <BalIcon className='text-2xl cursor-pointer' onClick={ToggleBalanceView} />
            <Link className='text-sm flex items-center justfiy-end gap-2' to={`/transactions`}>Transactions <FaArrowRight /> </Link>
          </div>
          <div className="grid grid-cols-3 gap-2">
            <div className="flex flex-col items-center justify-center">
              <div className="font-bold text-xl text-white">
                {viewBalance === 'true' ? `${NairaSign}${user.account?.balance === 0 ? '0.00' : user.account?.balance?.toLocaleString() || 0}` :
                  <div className="flex items-center !text-sm justify-center">
                    {new Array(6).fill(0).map((ele, i) => (
                      <FaAsterisk key={i} className=' mb-2' />
                    ))}
                  </div>
                }
              </div>
              <div className="text-orange-300 text-xs md:text-sm">Available Balance</div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <div className="font-bold text-xl text-white">
                {viewBalance === 'true' ? <span> {`${user.coinbal === 0 ? '0.00' : user.coinbal?.toLocaleString() || 0}`} <sup>/MB</sup> </span> :
                  <div className="flex items-center !text-sm justify-center">
                    {new Array(6).fill(0).map((ele, i) => (
                      <FaAsterisk key={i} className=' mb-2' />
                    ))}
                  </div>
                }
              </div>
              <div className="text-orange-300 text-xs md:text-sm">Total GB rewarded</div>
            </div>
            <div className="flex flex-col items-center justify-center">
              <div className="font-bold text-xl text-white">
                {viewBalance === 'true' ? `${0}` :
                  <div className="flex items-center !text-sm justify-center">
                    {new Array(6).fill(0).map((ele, i) => (
                      <FaAsterisk key={i} className=' mb-2' />
                    ))}
                  </div>
                }
              </div>
              <div className="text-orange-300 text-xs md:text-sm">Incentive Reward</div>
            </div>
          </div>
        </div>
        <Suspense>
          <MyTransactions tag="home" limit={1} />
        </Suspense>

        <div className="px-3 py-2 rounded-lg bg-white mb-3 border">
          <div className="grid grid-cols-3">
            {UserDashboardMajors.map((item, index) => (
              <Link key={index} to={`${item.link}`} className="flex group hover:scale-110 flex-col items-center justify-center gap-2 rounded-lg py-4 transition-all">
                <img src={item.image} alt="" className="h-8 lg:h-10 w-auto" />
                <div className="text-xs lg:text-sm group-hover:text-sub group-hover:font-semibold">{item.title}</div>
              </Link>
            ))}
          </div>
        </div>


        <div className="mx-auto mb-3 dark:bg-slate-800">
          <div className="">
            {<div className="grid grid-cols-4 gap-2">
              {services?.map((item, index) => {
                const fixed = AllServiceLinks.find(ele => ele.title === item.name?.toLowerCase())
                return (
                  <Link to={`${fixed.url}`} key={index} className='flex flex-col border group hover:scale-110 transition-all items-center justify-center gap-2 px-2 py-1 bg-white rounded-lg'>
                    <Imaged src={fixed.img} alt="" className="object-contain w-10 h-10" />
                    <div className="text-xs group-hover:text-sub group-hover:font-semibold text-center capitalize">{fixed.title}</div>
                  </Link>
                )
              })}
              {/* <Link to={`${shortletService.url}`} className='flex flex-col items-center group hover:scale-110 transition-all break-words border justify-center gap-1 px-2 py-3 bg-white rounded-lg'>
                <Imaged src={shortletService.img} alt="" className="object-contain w-12 h-12" />
                <div className="text-xs text-center group-hover:text-sub group-hover:font-semibold capitalize truncate">{shortletService.title}</div>
              </Link> */}
              <Link to={``} onClick={() => !servicetandh.url ? Swals('Coming Soon.', 'Travels and Hotels coming soon!...', 'info') : ''} className='flex flex-col items-center group hover:scale-110 transition-all break-words border justify-center gap-1 px-2 py-4 bg-white rounded-lg relative'>
                <Imaged src={servicetandh.img} alt="" className="object-contain w-12 h-12" />
                <div className="text-xs text-center group-hover:text-sub group-hover:font-semibold capitalize truncate">{servicetandh.title}</div>
                <div className="text-[0.6rem] font-medium absolute top-1 right-1 bg-green-300/30 text-green-700 py-1 px-2 rounded-lg">Coming soon</div>
              </Link>
              <Link to={``} onClick={() => !servicecrypto.url ? Swals('Coming Soon.', 'Buy / Sell Crypto coming soon!...', 'info') : ''} className='flex flex-col items-center group hover:scale-110 transition-all break-words border justify-center gap-1 px-2 py-4 relative bg-white rounded-lg col-span-2'>
                <Imaged src={servicecrypto.img} alt="" className="object-contain w-12 h-12" />
                <div className="text-xs text-center group-hover:text-sub group-hover:font-semibold capitalize truncate">{servicecrypto.title}</div>
                <div className="text-[0.6rem] font-medium absolute top-1 right-1 bg-green-300/30 text-green-700 py-1 px-2 rounded-lg">Coming soon</div>
              </Link>
              <Link to={``} onClick={() => !serviceglobal.url ? Swals('Coming Soon.', 'Global transfers coming soon!...', 'info') : ''} className='flex flex-col items-center group hover:scale-110 transition-all break-words border justify-center gap-1 px-2 py-4 bg-white relative rounded-lg col-span-2'>
                <Imaged src={serviceglobal.img} alt="" className="object-contain w-12 h-12" />
                <div className="text-xs text-center group-hover:text-sub group-hover:font-semibold capitalize truncate">{serviceglobal.title}</div>
                <div className="text-[0.6rem] font-medium absolute top-1 right-1 bg-green-300/30 text-green-700 py-1 px-2 rounded-lg">Coming soon</div>
              </Link>
            </div>}
          </div>
        </div>
      </div>
    </UserLayout>
  )
}

export default Dashboard

