import ALayout from 'components/admin/ALayout'
import { Apis, Authposturl } from 'components/Apis'
import Formbutton from 'components/utils/Formbutton'
import Forminput from 'components/utils/Forminput'
import { Swals } from 'components/utils/functions'
import React, { useState } from 'react'

export default function WPsettings() {
    const [url, setUrl] = useState( "https://server.quickdirect.com.ng/v3/webhook")
    const [loading, setLoading] = useState(0)
    const [forms, setForms] = useState({
        account_number: '',
        mode: '',
    })

    async function HandleWebhook(e) {
        e.preventDefault()
        setLoading(1)
        try {
            const response = await Authposturl(Apis.wireless.wp_account_settings, {url, tag: 'url'})
            if (response.status !== 200) return Swals('Request Failed', `${response.msg}`, 'error')
            Swals('Request Success', `${response.msg}`, 'success')
        } catch (error) {
            Swals('Request Failed', `${error.message}`, 'error')
        } finally {
            setLoading(0)
        }
    }

    async function HandleMode(e) {
        e.preventDefault()
        setLoading(2)
        const formTag = {
            account_number: forms.account_number,
            mode: 0,
            tag: 'mode'
        }
        try {
            const response = await Authposturl(Apis.wireless.wp_account_settings, formTag)
            if (response.status !== 200) return Swals('Request Failed', `${response.msg}`, 'error')
            Swals('Request Success', `${response.msg}`, 'success')
        } catch (error) {
            Swals('Request Failed', `${error.message}`, 'error')
        } finally {
            setLoading(0)
        }
    }
    return (
        <ALayout>
            <div className="w-[97%] mb-5 mx-auto p-5 bg-white shadow-lg">
                <div className="bg-sub/10 text-sm text-sub border border-sub p-3 rounded-md font-medium mb-5"> <span className="font-semibold">NOTE:</span> changing this field will have greate impact on all deposits coming into the platform fro extrenal institutions</div>
                <form onSubmit={HandleWebhook}>
                    <Forminput content="Webhook URI" value={url} onChange={e => setUrl(e.target.value)} />
                    <div className="grid grid-cols-2">
                        <div></div>
                        <div> <Formbutton title="Submit" loading={loading === 1 ? true : false} /> </div>
                    </div>
                </form>
            </div>
            {/* <div className="w-[97%] mb-5 mx-auto p-5 bg-white shadow-lg">
                <div className="bg-sub/10 text-sm text-sub border border-sub p-3 rounded-md font-medium mb-5"> <span className="font-semibold">NOTE:</span> changing this fields will have greate impact on the inflow and outflow of transactions on the designated account number carrier</div>
                <form onSubmit={HandleMode}>
                    <Forminput content="Account Number" value={forms.account_number} onChange={e => setForms({...forms, account_number: e.target.value})} />
                    <div className="grid grid-cols-2">
                        <div></div>
                        <div> <Formbutton title="Submit" loading={loading === 2 ? true : false} /> </div>
                    </div>
                </form>
            </div> */}
        </ALayout>
    )
}
