import { useQuery } from '@tanstack/react-query'
import { Apis, Posturl, geturl } from 'components/Apis'
import AllSponsors from 'components/general/AllSponsors'
import ContactForm from 'components/general/ContactForm'
import Footer from 'components/general/Footer'
import Header from 'components/general/Header'
import Spinner from 'components/utils/Spinner'
import { Swals } from 'components/utils/functions'
import loads from 'assets/main-images/loads.gif'
import failed from 'assets/main-images/failed.gif'
import cards from 'assets/main-images/cards.gif'
import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Imaged from 'components/utils/Imaged'
import Lottie from 'lottie-react'
import SuccessLogo from 'assets/json/Success.json'
import ErrorLogo from 'assets/json/Error.json'

export default function VerifyPartnership() {
    const [search,] = useSearchParams()
    const params = search.get('transRef')
    const status = search.get('status')
    const navigate = useNavigate()


    const { data, isLoading, isError, error } = useQuery({
        queryKey: [`verify-shares-partnership-payment`],
        queryFn: async () => {
            if (status !== 'PAID') return { status: 400, msg: `Unable to verify transaction` }
            const response = await geturl(`${Apis.ercas.verify_account_deposit}/${params}`)
            if (response.status === 200) {
                const res = await Posturl(Apis.shares.verify_shares_payment, response.msg)
                if ([200, 201].includes(res.status)) {
                    await new Promise((resolve, reject) => { setTimeout(resolve, 7000) })
                    setTimeout(() => {
                        navigate('/partnership')
                    }, 3000);
                } else {
                    Swals('Request Error', res.msg, 'error')
                }
                return res
            }
        }
    })
    if (data?.status === 400) {
        return (
            <>
                <Header />
                <div className="bg-slate-100/70">
                    <div className="flex flex-col lg:flex-row items-center justify-center gap-10 w-11/12 mx-auto py-20">
                        <div className="relative">
                            <Lottie
                                className='w-[13rem]'
                                animationData={ErrorLogo}
                                loop={false}
                            />
                        </div>
                        <div className="">
                            <div className="font-bold text-3xl lg:text-4xl text-red-600">Verification Failed</div>
                            <div className="font-light text-center w-full lg:w-4/5 text-zinc-600">We are unable to complete your request, kindly try again later</div>
                        </div>
                    </div>
                </div>
                <ContactForm />
                <AllSponsors />
                <Footer />
            </>
        )
    }
    if (isLoading) return (
        <>
            <Header />
            <div className="bg-slate-100/70">
                <div className="py-28 flex flex-col items-center justify-center gap-5">
                    <Spinner />
                    <div className="text-slate-600 animate-pulse">Verifying your transaction, do hold on....</div>
                </div>
            </div>
            <ContactForm />
            <AllSponsors />
            <Footer />
        </>
    )
    if (isError) {
        return (
            <>
                <Header />
                <div className="bg-slate-100/70">
                    <div className="flex flex-col lg:flex-row items-center justify-center gap-10 w-11/12 mx-auto py-20">
                        <div className="relative">
                            <Lottie
                                className='w-[13rem]'
                                animationData={ErrorLogo}
                                loop={false}
                            />
                        </div>
                        <div className="">
                            <div className="font-bold text-3xl lg:text-4xl text-red-600">Verification Failed</div>
                            <div className="font-light text-center w-full lg:w-4/5 text-zinc-600">{error}</div>
                        </div>
                    </div>
                </div>
                {/* <div className="w-11/12 mx-auto py-10 foil lg:py-20 flex items-center justify-center flex-col gap-3">
                    <div className="">
                        <Imaged src={failed} alt="" className="w-auto h-[20rem]" />
                    </div>
                    <div className="font-bold text-2xl text-red-600">Verification failed</div>
                    <div className="font-light text-center w-4/5">{error}</div>
                </div> */}
                <ContactForm />
                <AllSponsors />
                <Footer />
            </>
        )
    }

    if(data.status === 201) return (
        <div>
            <Header />
            <div className="bg-slate-100/70">
                <div className="flex flex-col lg:flex-row items-center justify-center gap-10 w-11/12 mx-auto py-20">
                    <div className="relative">
                        <Lottie
                            className='w-[10rem]'
                            animationData={SuccessLogo}
                            loop={false}
                        />
                    </div>
                    <div className="">
                        <div className="font-bold text-3xl lg:text-4xl text-green-700 text-center lg:text-left">Account Approved</div>
                        <div className="text-zinc-500 font-medium w-full lg:w-3/5 mt-5 text-center lg:text-left">Hi Dear <span className="text-blue-600">{data.msg?.email}</span>, {data.msg}</div>
                    </div>
                </div>
            </div>
            <ContactForm />
            <AllSponsors />
            <Footer />
        </div>
    )

    return (
        <div>
            <Header />
            <div className="bg-slate-100/70">
                <div className="flex flex-col lg:flex-row items-center justify-center gap-10 w-11/12 mx-auto py-20">
                    <div className="relative">
                        <Lottie
                            className='w-[10rem]'
                            animationData={SuccessLogo}
                            loop={false}
                        />
                    </div>
                    <div className="">
                        <div className="font-bold text-3xl lg:text-4xl text-green-700 text-center lg:text-left">Payment Verification Successful</div>
                        <div className="text-zinc-500 font-medium w-full lg:w-3/5 mt-5 text-center lg:text-left">Hi Dear <span className="text-blue-600">{data.msg.email}</span>, your request has been successfully approved, kindly check your mail for further information. </div>
                    </div>
                </div>
            </div>
            <ContactForm />
            <AllSponsors />
            <Footer />
        </div>
    )
}
