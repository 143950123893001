import { Apis, Authgeturl, Authposturl } from 'components/Apis'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { dispatchActivePlan, dispatchPlan } from 'app/reducer'
import PageLoader from 'components/utils/PageLoader'
import moment from 'moment'
import { useQuery } from '@tanstack/react-query'

const InvestLayout = ({ children }) => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const {isLoading, isError, error, data} = useQuery({
        queryKey: [`invest-layout-${id}`],
        queryFn: async () => {
            const res = await Authgeturl(`${Apis.investments.stock}/${id}`)
            if (res.status === 200) {
                // check for investment index 0 stock cards 
                const checkDuration = moment().isAfter(moment(new Date(res.msg.stockTime)))
                if (checkDuration && res.msg.stockCards <= 0) {
                    const data = {
                        stockid: id
                    }
                    await Authposturl(Apis.investments.refill_stock, data)
                }
                const plan = res.msg
                const planres = await Authgeturl(`${Apis.investments.my_purchases}/${plan.id}/${plan.plans.id}`)
                const findActive = planres.msg.find(ele => ele.status !== 'false')
                if (planres.status === 200) {
                    dispatch(dispatchPlan(res.msg))
                    dispatch(dispatchActivePlan(findActive))
                    return plan
                }
            }
        },
        staleTime: 0
    })
    
    if(isLoading) return <PageLoader />
    if(isError) return (
        <div className='text-center'>{error}</div>
    )
    
    return (
        children
    )
}

export default InvestLayout