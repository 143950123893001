
import React, { useCallback, useEffect, useState } from 'react'
import ALayout from 'components/admin/ALayout'
import { Errors } from 'components/utils/functions'
import { Apis, Authgeturl, imgurl } from 'components/Apis'
import moment from 'moment'
import ManageLogoModal from './ManageLogoModal'
import Imaged from 'components/utils/Imaged'

const Tableheaders = [
    "S/N",
    "image",
    "date created",
]

export default function ManagePartnersLogo (){
    const [plans, setPlans] = useState([])
    const [loading, setLoading] = useState(false)
    const [single, setSingle] = useState({})
    const [view, setView] = useState(false)

    const FetchAllPlans = useCallback(async () => {
        try {
            const res = await Authgeturl(Apis.feeds.all_partners_logo)
            if (res.status === 200) return setPlans(res.msg)
        } catch (error) {
            Errors(error)
        } finally {
            setLoading(false)
        }
    }, [])
    useEffect(() => { FetchAllPlans() }, [FetchAllPlans])

    const handleSingle = val => {
        setSingle(val)
        setView(!view)
    }
  return (
    <ALayout>
        <div className="">
            {view && <ManageLogoModal
                item={single}
                closeView={() => setView(!view)}
                refetch={() => { FetchAllPlans(); setView(!view) }}
            />}
            <div className="mt-10">
                <div className="w-fit ml-auto mb-6"> <button 
                onClick={() => {setView(!view); setSingle({})}}
                className="bg-main text-white py-3 px-4 capitalize rounded-lg">add logo</button> </div>
                {!loading && <table className='table table-auto w-full bg-white p-3 text-sm'>
                    <thead>
                        <tr className='bg-slate-700 text-white'>
                            {Tableheaders.map((ele, i) => (
                                <td className='capitalize p-3' key={i}>{ele}</td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {plans.map((item, i) => (
                            <tr 
                            key={i}
                            className='cursor-pointer border-b border-r border-l'
                            onClick={() => handleSingle(item)}
                            >
                                <td className='p-3 capitalize'>{i + 1}</td>
                                <td className='p-3 capitalize'> <Imaged src={`${imgurl}/logos/${item.image}`} className="w-10 h-10 object-contain" /> </td>
                                <td className='p-3'>{moment(item.createdAt).format('DD-MM-YYYY hh:ss A')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>}
            </div>
        </div>
    </ALayout>
  )
}
