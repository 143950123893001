// import UserLayout from "components/user/UserLayout";
// import { NairaSign, SuccessAlert, Swals } from "components/utils/functions";
// import React, { useState } from "react";
// import { BsCopy, BsPeopleFill, BsPersonFill } from "react-icons/bs";
// import { FaUsers } from "react-icons/fa";
// import { FaArrowRight } from "react-icons/fa6";
// import { LuNetwork } from "react-icons/lu";
// import { SlGlobe } from "react-icons/sl";
// import { Link } from "react-router-dom";
// import { Pagination, Autoplay, Navigation } from "swiper/modules";
// import { Swiper, SwiperSlide } from "swiper/react";
// import ConfirmSwitch from "./ConfirmSwitch";
// import { useQuery } from "@tanstack/react-query";
// import { Apis, Authgeturl } from "components/Apis";
// import Spinner from "components/utils/Spinner";
// import CopyToClipboard from "react-copy-to-clipboard";
// import { useSelector } from "react-redux";
// import moment from "moment";
// import Imaged from "components/utils/Imaged";
// import emptyImage from "assets/main-images/empty.png"


// export default function Biznet() {
//   const [activeAccount, setActiveAccount] = useState({
//     index: 0,
//     data: {},
//   });
//   const [account, setAccount] = useState({})
//   const { user } = useSelector(state => state.data)
//   const [views, setViews] = useState({
//     status: false,
//     data: {}
//   });
//   const [transfers, setTransfers] = useState({})

//   const { data, isLoading, refetch } = useQuery({
//     queryKey: ['biznet'],
//     queryFn: async () => {
//       const response = await Authgeturl(Apis.user.all_account_plan)
//       const instance = await Authgeturl(Apis.user.profile_account_plan)
//       const trans = await Authgeturl(`${Apis.user.account_plan_transactions}?p=1&page_size=5`)
//       setAccount(instance.data)
//       setTransfers(trans)
//       let result, indexed = 0;
//       if (instance?.data) {
//         indexed = instance.data?.account?.id
//         result = instance.data?.account
//       } else {
//         indexed = response.data[response.data.length - 1]?.id
//         result = response.data[response.data.length - 1]
//       }
//       setActiveAccount({
//         index: indexed,
//         data: result
//       })
//       return response
//     },
//   })

//   if (isLoading) return (
//     <UserLayout title="BizNet">
//       <div className="w-fit mx-auto mt-20">
//         <Spinner />
//       </div>
//     </UserLayout>
//   )


//   const OpenWithdrawal = () => {
//     Swals('Unavailable', 'Withdrawals are not available at the moment', 'info')
//   }


//   return (
//     <UserLayout title="BizNet">
//       {views.status && <ConfirmSwitch
//         account={activeAccount.data}
//         refetch={refetch}
//         closeView={() => setViews({ ...views, status: false })}
//       />}
//       <div className="w-11/12 mx-auto">
//         <div className="">
//           <div className="">
//             <Swiper
//               slidesPerView={1}
//               spaceBetween={10}
//               centeredSlides={false}
//               slidesPerGroupSkip={1}
//               grabCursor={true}
//               keyboard={{
//                 enabled: true,
//               }}
//               breakpoints={{
//                 992: {
//                   slidesPerView: 2,
//                   slidesPerGroup: 1,
//                 },
//               }}
//               // scrollbar={true}
//               //   navigation={true}
//               loop={false}
//               pagination={{
//                 clickable: true,
//               }}
//               modules={[Autoplay, Pagination, Navigation]}
//               className="mySwiper"
//             >
//               <SwiperSlide>
//                 <div className="pt-6 text-sm h-fit mb-10">
//                   <div className="border p-4 rounded-xl bg-white bg-card relative">
//                     <div className="text-white -mt-10 w-fit mx-auto px-4 py-2 bg-main border-4 border-white rounded-lg">
//                       {account?.account?.title?.toUpperCase() ?? `No current Plan`}
//                     </div>
//                     <div className="text-center text-3xl lg:text-4xl font-bold mt-5">

//                       {NairaSign}{account?.balance?.toLocaleString() ?? 0}
//                     </div>
//                     <div className="text-center text-zinc-600 text-sm">
//                       Current Balance
//                     </div>
//                     <div className="w-fit mx-auto">
//                       <CopyToClipboard
//                         text={user.refid}
//                         onCopy={() => SuccessAlert("Referral code copied")}
//                       >
//                         <div className="flex cursor-pointer items-center gap-2">
//                           <span>Referral ID: {user.refid}</span> <BsCopy />
//                         </div>
//                       </CopyToClipboard>
//                     </div>
//                     <div className="mt-5">
//                       <div className="grid grid-cols-2 gap-5 p-2 border rounded-lg mb-1 backdrop-blur-sm">
//                         <div className="">Total Recieved</div>
//                         <div className="text-right font-bold">
//                           {NairaSign}0
//                         </div>
//                       </div>
//                       <div className="grid grid-cols-2 gap-5 p-2 border rounded-lg mb-1 backdrop-blur-sm">
//                         <div className="">Total Withdrawn</div>
//                         <div className="text-right font-bold">
//                           {NairaSign}0
//                         </div>
//                       </div>
//                       <div className="grid grid-cols-2 gap-5 p-2 border rounded-lg mb-1 backdrop-blur-sm">
//                         <div className="">Total Earned (Level 1)</div>
//                         <div className="flex items-center justify-end font-bold gap-2">
//                           {NairaSign}{transfers?.details?.com1Amount?.toLocaleString() || 0} <div className="font-extralight flex items-center">( <span className="font-bold">{transfers?.details?.com1Total}</span> <BsPersonFill /> )</div>
//                         </div>
//                       </div>
//                       <div className="grid grid-cols-2 gap-5 p-2 border rounded-lg mb-1 backdrop-blur-sm">
//                         <div className="">Total Earned (Level 2)</div>
//                         <div className="flex items-center justify-end font-bold gap-2">
//                           {NairaSign}{transfers?.details?.com2Amount?.toLocaleString() || 0} <div className="font-extralight flex items-center">( <span className="font-bold">{transfers?.details?.com2Total}</span> <BsPeopleFill /> )</div>
//                         </div>
//                       </div>
//                       <div className="grid grid-cols-2 gap-5 p-2 border rounded-lg mb-1 backdrop-blur-sm">
//                         <div className="">Total Earned (Level 3)</div>
//                         <div className="flex items-center justify-end font-bold gap-2">
//                           {NairaSign}{transfers?.details?.com3Amount?.toLocaleString() || 0} <div className="font-extralight flex items-center">( <span className="font-bold">{transfers?.details?.com3Total}</span> <FaUsers /> )</div>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="w-fit ml-auto mt-5">
//                       <button
//                         onClick={OpenWithdrawal}
//                         className="bg-main text-white py-3 px-5 rounded-lg">Withdraw</button>
//                     </div>
//                     <div className="flex items-center gap-2 justify-end mt-3 lg:hidden">Slide to view plans <FaArrowRight /> </div>
//                   </div>
//                 </div>
//               </SwiperSlide>
//               <SwiperSlide>
//                 <div className="pt-6 pb-10 text-sm">
//                   <div className="pt-5 mb-2 h-[24rem] overflow-y-auto scrolls">
//                     <div className="border-4 border-green-700 p-4 rounded-xl bg-white mb-4 bg-card2">
//                       <div className="text-white -mt-10 w-fit uppercase mx-auto px-4 py-2 bg-main border-4 border-white rounded-lg">
//                         {activeAccount.data?.title ?? ''}
//                       </div>
//                       <div className="text-center text-zinc-600 text-sm">
//                         Current Account
//                       </div>
//                       <div className="mt-5 p-3 backdrop-blur-sm text-sm">
//                         <div className="grid grid-cols-5 gap-5 p-2 border-b">
//                           <div className="col-span-4">Registration Fee</div>
//                           <div className="text-right col-span-1 font-bold">
//                             {NairaSign}{activeAccount.data?.fee?.toLocaleString() ?? 0}
//                           </div>
//                         </div>
//                         <div className="grid grid-cols-5 gap-5 p-2 border-b">
//                           <div className="col-span-4">Direct Airtime Discount</div>
//                           <div className="text-right col-span-1 font-bold">
//                             {activeAccount.data?.airtimeDiscount ?? 0}%
//                           </div>
//                         </div>
//                         <div className="grid grid-cols-5 gap-5 p-2 border-b">
//                           <div className="col-span-4">Direct Data Discount</div>
//                           <div className="text-right col-span-1 font-bold">
//                             {activeAccount.data?.dataDiscount ?? 0}%
//                           </div>
//                         </div>
//                         <div className="grid grid-cols-5 gap-5 p-2 border-b">
//                           <div className="col-span-4">
//                             Commission from direct agent
//                           </div>
//                           <div className="text-right col-span-1 font-bold">
//                             {activeAccount.data?.directDiscount} %
//                           </div>
//                         </div>
//                         <div className="grid grid-cols-5 gap-5 p-2 border-b">
//                           <div className="col-span-4">
//                             <div className="">Commission from level 1 agent sales</div>
//                             <div className="grid grid-cols-2 gap-5">
//                               <div className="">Data: {activeAccount.data?.com1data}%</div>
//                               <div className="">Airtime: {activeAccount.data?.com1airtime}% </div>
//                             </div>
//                           </div>
//                           <div className="flex items-center justify-end gap-2 col-span-1 font-bold">
//                             <BsPersonFill />
//                           </div>
//                         </div>
//                         <div className="grid grid-cols-5 gap-5 p-2 border-b">
//                           <div className="col-span-4">
//                             <div> Commission from level 2 agent sales</div>
//                             <div className="grid grid-cols-2 gap-5">
//                               <div className="">Data: {activeAccount.data?.com2data}%</div>
//                               <div className="">Airtime: {activeAccount.data?.com2airtime}% </div>
//                             </div>
//                           </div>
//                           <div className="flex items-center justify-end gap-2 col-span-1 font-bold">
//                             <BsPeopleFill />
//                           </div>
//                         </div>
//                         <div className="grid grid-cols-5 gap-5 p-2 border-b">
//                           <div className="col-span-4">
//                             <div>Commission from level 3 agent sales </div>
//                             <div className="grid grid-cols-2 gap-5">
//                               <div className="">Data: {activeAccount.data?.com3data}%</div>
//                               <div className="">Airtime: {activeAccount.data?.com3airtime}% </div>
//                             </div>
//                           </div>
//                           <div className="flex items-center justify-end gap-2 col-span-1 font-bold">
//                             <FaUsers />
//                           </div>
//                         </div>
//                         <div className="grid grid-cols-5 gap-5 p-2">
//                           <div className="col-span-1"></div>
//                           <div className="col-span-4">
//                             {activeAccount.data && <div
//                               onClick={() => setViews({ status: true, data: activeAccount.data })}
//                               className="bg-main w-fit ml-auto text-white text-xs py-2 px-4 rounded-lg cursor-pointer">
//                               Activate Plan
//                             </div>}
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="">
//                     <div className="grid grid-cols-3 gap-3">
//                       {data?.data.map((item, index) => (
//                         <div
//                           onClick={() => setActiveAccount({ index: item.id, data: item })}
//                           key={index}
//                           className={`flex flex-col md:flex-row items-center py-2 px-4 rounded-lg cursor-pointer border ${activeAccount.index === item.id
//                             ? "bg-main text-white"
//                             : "bg-white"
//                             } gap-2`}
//                         >
//                           <div className="">
//                             <div
//                               className={`h-5 w-5 rounded-full ${activeAccount.index === item.id
//                                 ? "bg-sub"
//                                 : "bg-slate-100"
//                                 }`}
//                             ></div>
//                           </div>
//                           <div className="text-sm">
//                             <div className="font-bold truncate uppercase">
//                               {item.title}
//                             </div>
//                             <div className="flex items-center">
//                               {NairaSign}{item.fee.toLocaleString()}
//                               <FaArrowRight className="ml-3" />
//                             </div>
//                           </div>
//                         </div>
//                       ))}
//                     </div>
//                   </div>
//                 </div>
//               </SwiperSlide>
//             </Swiper>
//           </div>
//           <div className="flex items-center justify-between gap-10 mb-3">
//             <div className="font-bold text-xl">Transaction history</div>
//             <div className="text-right text-blue-600">
//               <Link to="/biznet/transactions">View all</Link>
//             </div>
//           </div>
//           {transfers.total < 1 &&
//             <div className="flex items-center justify-center flex-col">
//               <Imaged src={emptyImage} className="size-20" />
//               <div className="text-slate-500 text-sm">No records found</div>
//             </div>}
//           {transfers.total > 0 && transfers?.data?.map((item, index) => (
//             <div className="text-sm border bg-white rounded-xl mb-1 p-4" key={index}>
//               <div className="grid grid-cols-5">
//                 <div className="col-span-4">
//                   <div className="flex gap-3 items-center">
//                     <div className="bg-main text-white rounded-full p-2"> <LuNetwork /> </div>
//                     <div className="">
//                       <div className="font-bold text-lg">{item.title}</div>
//                       <div className="text-sm font-medium">{item.service}</div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="col-span-1">
//                   <div className={`text-green-600 font-bold text-right`}>
//                     {NairaSign}
//                     {item.amount}
//                   </div>
//                   <div className="flex items-center justify-end gap-1 text-sm"> <SlGlobe className="text-xs" /> {moment(item.createdAt).format('Do MMM YYYY')}</div>
//                 </div>
//               </div>
//               <div className="text-sm border-t">{item.content}</div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </UserLayout>
//   );
// }

import UserLayout from "components/user/UserLayout";
import { NairaSign, SuccessAlert, Swals } from "components/utils/functions";
import React, { useState } from "react";
import { BsCopy, BsPeopleFill, BsPersonFill } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { LuNetwork } from "react-icons/lu";
import { SlGlobe } from "react-icons/sl";
import { Link } from "react-router-dom";
import ConfirmSwitch from "./ConfirmSwitch";
import { useQuery } from "@tanstack/react-query";
import { Apis, Authgeturl } from "components/Apis";
import Spinner from "components/utils/Spinner";
import CopyToClipboard from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import moment from "moment";
import Imaged from "components/utils/Imaged";
import emptyImage from "assets/main-images/empty.png"


export default function Biznet() {
  const [activeAccount, setActiveAccount] = useState({
    index: 0,
    data: {},
  });
  const [open, setOpen] = useState(false)
  const [account, setAccount] = useState({})
  const { user } = useSelector(state => state.data)
  const [views, setViews] = useState({
    status: false,
    data: {}
  });
  const [transfers, setTransfers] = useState({})

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['biznet'],
    queryFn: async () => {
      const response = await Authgeturl(Apis.user.all_account_plan)
      const instance = await Authgeturl(Apis.user.profile_account_plan)
      const trans = await Authgeturl(`${Apis.user.account_plan_transactions}?p=1&page_size=5`)
      setAccount(instance.data)
      setTransfers(trans)
      let result, indexed = 0;
      if (instance?.data) {
        indexed = instance.data?.account?.id
        result = instance.data?.account
      } else {
        indexed = response.data[response.data.length - 1]?.id
        result = response.data[response.data.length - 1]
      }
      setActiveAccount({
        index: indexed,
        data: result
      })
      return response
    },
  })

  if (isLoading) return (
    <UserLayout title="BizNet">
      <div className="w-fit mx-auto mt-20">
        <Spinner />
      </div>
    </UserLayout>
  )


  const OpenWithdrawal = () => {
    Swals('Unavailable', 'Withdrawals are not available at the moment', 'info')
  }


  return (
    <UserLayout title="BizNet">
      {views.status && <ConfirmSwitch
        account={activeAccount.data}
        refetch={refetch}
        closeView={() => setViews({ ...views, status: false })}
      />}
      <div className="w-11/12 mx-auto">
        <div className="">

         {!open && <div className="pt-6 text-sm h-fit my-10">
            <div className="border p-4 rounded-xl bg-white bg-card relative">
              <div className="text-white -mt-10 w-fit mx-auto px-4 py-2 bg-main border-4 border-white rounded-lg">
                {account?.account?.title?.toUpperCase() ?? `No current Plan`}
              </div>
              <div className="text-center text-3xl lg:text-4xl font-bold mt-5">

                {NairaSign}{account?.balance?.toLocaleString() ?? 0}
              </div>
              <div className="text-center text-zinc-600 text-sm">
                Current Balance
              </div>
              <div className="w-fit mx-auto">
                <CopyToClipboard
                  text={user.refid}
                  onCopy={() => SuccessAlert("Referral code copied")}
                >
                  <div className="flex cursor-pointer items-center gap-2">
                    <span>Referral ID: {user.refid}</span> <BsCopy />
                  </div>
                </CopyToClipboard>
              </div>
              <div className="mt-5">
                <div className="grid grid-cols-2 gap-5 p-2 border rounded-lg mb-1 backdrop-blur-sm">
                  <div className="">Total Recieved</div>
                  <div className="text-right font-bold">
                    {NairaSign}0
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-5 p-2 border rounded-lg mb-1 backdrop-blur-sm">
                  <div className="">Total Withdrawn</div>
                  <div className="text-right font-bold">
                    {NairaSign}0
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-5 p-2 border rounded-lg mb-1 backdrop-blur-sm">
                  <div className="">Total Earned (Level 1)</div>
                  <div className="flex items-center justify-end font-bold gap-2">
                    {NairaSign}{transfers?.details?.com1Amount?.toLocaleString() || 0} <div className="font-extralight flex items-center">( <span className="font-bold">{transfers?.details?.com1Total}</span> <BsPersonFill /> )</div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-5 p-2 border rounded-lg mb-1 backdrop-blur-sm">
                  <div className="">Total Earned (Level 2)</div>
                  <div className="flex items-center justify-end font-bold gap-2">
                    {NairaSign}{transfers?.details?.com2Amount?.toLocaleString() || 0} <div className="font-extralight flex items-center">( <span className="font-bold">{transfers?.details?.com2Total}</span> <BsPeopleFill /> )</div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-5 p-2 border rounded-lg mb-1 backdrop-blur-sm">
                  <div className="">Total Earned (Level 3)</div>
                  <div className="flex items-center justify-end font-bold gap-2">
                    {NairaSign}{transfers?.details?.com3Amount?.toLocaleString() || 0} <div className="font-extralight flex items-center">( <span className="font-bold">{transfers?.details?.com3Total}</span> <FaUsers /> )</div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between mt-5">
                <button
                  onClick={OpenWithdrawal}
                  className="bg-main text-white py-3 px-5 rounded-lg">Withdraw</button>
                <div
                  onClick={() => setOpen(prev => !prev)}
                  className="flex items-center gap-2 justify-end mt-3 cursor-pointer lg:hidden capitalize"> view plans <FaArrowRight /> </div>
              </div>
            </div>
          </div>}
          {/* ========================================== */}
          {open && <div className="pt-6 pb-10 text-sm">
            <div onClick={() => setOpen(prev => !prev)} className="flex items-center gap-2 cursor-pointer w-fit"> <FaArrowLeft /> Back</div>
            <div className="pt-5 mb-2">
              <div className="border-4 border-green-700 p-4 rounded-xl bg-white mb-4 bg-card2">
                <div className="text-white -mt-10 w-fit uppercase mx-auto px-4 py-2 bg-main border-4 border-white rounded-lg">
                  {activeAccount.data?.title ?? '--Select Plan--'}
                </div>
                <div className="text-center text-zinc-600 text-sm">
                  Current Account
                </div>
                <div className="mt-5 p-3 backdrop-blur-sm text-sm">
                  <div className="grid grid-cols-5 gap-5 p-2 border-b">
                    <div className="col-span-4">Registration Fee</div>
                    <div className="text-right col-span-1 font-bold">
                      {NairaSign}{activeAccount.data?.fee?.toLocaleString() ?? 0}
                    </div>
                  </div>
                  <div className="grid grid-cols-5 gap-5 p-2 border-b">
                    <div className="col-span-4">Direct Airtime Discount</div>
                    <div className="text-right col-span-1 font-bold">
                      {activeAccount.data?.airtimeDiscount ?? 0}%
                    </div>
                  </div>
                  <div className="grid grid-cols-5 gap-5 p-2 border-b">
                    <div className="col-span-4">Direct Data Discount</div>
                    <div className="text-right col-span-1 font-bold">
                      {activeAccount.data?.dataDiscount ?? 0}%
                    </div>
                  </div>
                  <div className="grid grid-cols-5 gap-5 p-2 border-b">
                    <div className="col-span-4">
                      Commission from direct agent
                    </div>
                    <div className="text-right col-span-1 font-bold">
                      {activeAccount.data?.directDiscount ?? 0} %
                    </div>
                  </div>
                  <div className="grid grid-cols-5 gap-5 p-2 border-b">
                    <div className="col-span-4">
                      <div className="">Commission from level 1 agent sales</div>
                      <div className="grid grid-cols-2 gap-5">
                        <div className="">Data: {activeAccount.data?.com1data ?? 0}%</div>
                        <div className="">Airtime: {activeAccount.data?.com1airtime ?? 0}% </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-end gap-2 col-span-1 font-bold">
                      <BsPersonFill />
                    </div>
                  </div>
                  <div className="grid grid-cols-5 gap-5 p-2 border-b">
                    <div className="col-span-4">
                      <div> Commission from level 2 agent sales</div>
                      <div className="grid grid-cols-2 gap-5">
                        <div className="">Data: {activeAccount.data?.com2data ?? 0}%</div>
                        <div className="">Airtime: {activeAccount.data?.com2airtime ?? 0}% </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-end gap-2 col-span-1 font-bold">
                      <BsPeopleFill />
                    </div>
                  </div>
                  <div className="grid grid-cols-5 gap-5 p-2 border-b">
                    <div className="col-span-4">
                      <div>Commission from level 3 agent sales </div>
                      <div className="grid grid-cols-2 gap-5">
                        <div className="">Data: {activeAccount.data?.com3data ?? 0}%</div>
                        <div className="">Airtime: {activeAccount.data?.com3airtime ?? 0}% </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-end gap-2 col-span-1 font-bold">
                      <FaUsers />
                    </div>
                  </div>
                  <div className="grid grid-cols-5 gap-5 p-2">
                    <div className="col-span-1"></div>
                    <div className="col-span-4">
                      {activeAccount.data && <div
                        onClick={() => setViews({ status: true, data: activeAccount.data })}
                        className="bg-main w-fit ml-auto text-white text-xs py-2 px-4 rounded-lg cursor-pointer">
                        Activate Plan
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="grid grid-cols-3 gap-3">
                {data?.data.map((item, index) => (
                  <div
                    onClick={() => setActiveAccount({ index: item.id, data: item })}
                    key={index}
                    className={`flex flex-col md:flex-row items-center py-2 px-4 rounded-lg cursor-pointer border ${activeAccount.index === item.id
                      ? "bg-main text-white"
                      : "bg-white"
                      } gap-2`}
                  >
                    <div className="">
                      <div
                        className={`h-5 w-5 rounded-full ${activeAccount.index === item.id
                          ? "bg-sub"
                          : "bg-slate-100"
                          }`}
                      ></div>
                    </div>
                    <div className="text-sm">
                      <div className="font-bold truncate uppercase">
                        {item.title}
                      </div>
                      <div className="flex items-center">
                        {NairaSign}{item.fee.toLocaleString()}
                        <FaArrowRight className="ml-3" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>}
          {/* ====================================== */}
          <div className="flex items-center justify-between gap-10 mb-3">
            <div className="font-bold text-xl">Transaction history</div>
            <div className="text-right text-blue-600">
              <Link to="/biznet/transactions">View all</Link>
            </div>
          </div>
          {transfers.total < 1 &&
            <div className="flex items-center justify-center flex-col">
              <Imaged src={emptyImage} className="size-20" />
              <div className="text-slate-500 text-sm">No records found</div>
            </div>}
          {transfers.total > 0 && transfers?.data?.map((item, index) => (
            <div className="text-sm border bg-white rounded-xl mb-1 p-4" key={index}>
              <div className="grid grid-cols-5">
                <div className="col-span-4">
                  <div className="flex gap-3 items-center">
                    <div className="bg-main text-white rounded-full p-2"> <LuNetwork /> </div>
                    <div className="">
                      <div className="font-bold text-lg">{item.title}</div>
                      <div className="text-sm font-medium">{item.service}</div>
                    </div>
                  </div>
                </div>
                <div className="col-span-1">
                  <div className={`text-green-600 font-bold text-right`}>
                    {NairaSign}
                    {item.amount}
                  </div>
                  <div className="flex items-center justify-end gap-1 text-sm"> <SlGlobe className="text-xs" /> {moment(item.createdAt).format('Do MMM YYYY')}</div>
                </div>
              </div>
              <div className="text-sm border-t">{item.content}</div>
            </div>
          ))}
        </div>
      </div>
    </UserLayout>
  );
}
