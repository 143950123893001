import React from 'react'
import UserLayout from 'components/user/UserLayout'
import PickUpPage from '../../general/HomeShortlets/PickUpPage'
import { PickupUsersFront } from 'components/utils/functions'
import { useSelector } from 'react-redux'

const SingleShort = () => {
    const {user} = useSelector(state => state.data)
  return (
    <UserLayout title="Shortlet">
        <PickUpPage
        tagged={user.tagged}
        usertag={PickupUsersFront.user}
        />
    </UserLayout>
  )
}

export default SingleShort