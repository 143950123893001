import { useQuery } from '@tanstack/react-query'
import { Apis, Authgeturl } from 'components/Apis'
import UserLayout from 'components/user/UserLayout'
import { BeneficiaryColorCodes } from 'components/utils/functions'
import Imaged from 'components/utils/Imaged'
import React from 'react'
import { PiDotBold } from 'react-icons/pi'
import { Link } from 'react-router-dom'
import emptyImage from "assets/main-images/empty.png"

export default function AllBeneficiaries() {
    const { data, isLoading } = useQuery({
        queryKey: ['fetch-all-beneficiaries'],
        queryFn: async () => {
            return await Authgeturl(`${Apis.user.my_beneficiaries}?page_size=10`)
        }
    })
    if (isLoading) return (
        <UserLayout title="All Beneficiaries">
            <div className="flex flex-col items-center">
                {new Array(20).fill(0).map((item, index) => (
                    <div className="px-3 pt-3 hover:bg-slate-100 transition-all w-full" key={index}>
                        <div className="flex items-center gap-2">
                            <div>
                                <div className={`rounded-full size-12 grid justify-center items-center text-3xl font-extrabold bg-slate-200 text-white`}></div>
                            </div>
                            <div className={`${index < data?.total - 1 ? 'border-b' : ''} pb-3 w-full`}>
                                <div className="bg-slate-200 w-full h-4 mt-1 rounded-sm"></div>
                                <div className="bg-slate-200 w-3/5 h-4 mt-1 rounded-sm"></div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </UserLayout>
    )
    return (
        <UserLayout title="All Beneficiaries">

            <div className="">
                {isLoading && <div className="text-center">Loading...</div>}
                {!isLoading && data?.total > 0 ? <>
                    <div className="flex flex-col items-center bg-white">
                        {data?.data?.map((item, index) => (
                            <Link className="px-3 pt-3 hover:bg-slate-100 transition-all w-full" key={index} to={`/send-money?send=account_${item.account_number}`}>
                                <div className="flex items-center gap-2">
                                    <div>
                                        <div className={`rounded-full size-12 grid justify-center items-center text-3xl font-extrabold ${BeneficiaryColorCodes[Math.floor(Math.random() * BeneficiaryColorCodes.length)]} text-white`}>{item?.fullname.charAt(0)}</div>
                                    </div>
                                    <div className={`${index < data?.total - 1 ? 'border-b' : ''} pb-3 w-full`}>
                                        <div className="text-base truncate font-bold">{item.fullname}</div>
                                        <div className="text-sm truncate flex items-center">{item.bank_name} <PiDotBold className="text-base" /> {item.account_number?.slice(-4)}</div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </> : <>
                <div className="">
                    <div className="flex items-center justify-center flex-col">
                        <Imaged src={emptyImage} className="size-20" />
                        <div className="text-slate-500 text-sm">No records found</div>
                    </div>
                </div>
                </>}
            </div>

        </UserLayout>
    )
}
