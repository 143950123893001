
import React from 'react'
import ALayout from 'components/admin/ALayout'
import ProfileComponent from 'components/utils/ProfileComponent'

const AProfile = () => {
  return (
    <ALayout>
        <ProfileComponent />
    </ALayout>
  )
}

export default AProfile