
import React, { useState } from 'react'
import ModalLayout from 'components/utils/ModalLayout'
import Formbutton from 'components/utils/Formbutton'
import { ErrorAlert } from 'components/utils/functions'
import { SlArrowRight } from 'react-icons/sl'
import { FaWallet } from 'react-icons/fa'
import Forminput from 'components/utils/Forminput'
import Loading from 'components/utils/Loading'
import { ServiceImages } from 'components/utils/PageUtils'

export default function DataServiceConfirmation({ closeView, single, setSingle, mainbg, user, loading, productTag, InitiateRedeemData }) {
    const [active,] = useState(false)
    const [code, setCode] = useState('')
    const [btn, setBtn] = useState(true)
    const [screen, setScreen] = useState(1)
    const [paymethod, setPaymethod] = useState('')
    const payOptions = [
        'reward',
    ]
    const imageData = ServiceImages.find(ele => [single.product.toLowerCase(), single.service.toLowerCase()].includes(ele.network.toLowerCase()))

    const handlePayToggle = val => {
        if (paymethod !== val) {
            setBtn(false)
            setPaymethod(val)
            setSingle({
                ...single,
                paymethod: val
            })
        } else {
            setBtn(true)
            setPaymethod('')
            setSingle({
                ...single,
                paymethod: ''
            })
        }
    }

    const validateRequest = () => {
        if (!paymethod) return ErrorAlert('Select a payment method')
        setScreen(2)

    }
    const handleCode = val => {
        setCode(val)
        setSingle({
            ...single,
            code: val
        })
    }

    const confirmRequest = () => {
        if (!code) return ErrorAlert('Provide your transaction pin')
        const dataToStore = {
            network_id: single.Serviceid,
            data_plan: single.plan_id,
            phone_number: single.Number,
            pin: single.code,
            unit: single.unit,
        }
        return InitiateRedeemData(dataToStore)
    }

    return (
        <ModalLayout closeView={closeView}>
            {loading && <Loading />}
            <div className="w-10/12 pt-10 mx-auto">
                {imageData?.image && <div className="w-fit mx-auto">
                    <img src={`${imageData?.image ?? ''}`} alt="" className="size-20" />
                </div>}
                <div className="font-bold text-center text-3xl"> {single.product}</div>

                <div className="grid grid-cols-2 gap-4 py-2 mt-5">
                    <div className="text-sm">Designated Number</div>
                    <div className="text-sm text-right">{single.Number}</div>
                </div>
                <div className="grid grid-cols-2 gap-4 py-2">
                    <div className="text-sm">Network</div>
                    <div className="text-sm text-right uppercase">{single.service}</div>
                </div>
                <div className="grid grid-cols-2 gap-4 py-2">
                    <div className="text-sm">Credit Type</div>
                    <div className="text-sm text-right uppercase">{productTag} {single.paymethod}</div>
                </div>
                <div className="grid grid-cols-2 gap-4 py-2">
                    <div className="text-sm">to Purchase</div>
                    <div className="text-sm text-right">{productTag} [{single.product.toUpperCase()}]</div>
                </div>
                {screen === 1 && <div className="pt-3 mt-3 border-t">
                    <div className="mb-5 text-lg font-bold">Payment Method</div>
                    <div className={`grid grid-cols-4 p-3 ${paymethod === payOptions[0] ? 'bg-slate-200' : ''} rounded-lg mb-3`}>
                        <div className="flex col-span-3 gap-2 cursor-pointer" onClick={() => { (active ? parseInt(single.amountToPay) : parseInt(single.amount)) > user.coinbal ? ErrorAlert('Insufficient Reward balance') : handlePayToggle(payOptions[0]) }}>
                            <div className="p-2 rounded-full bg-slate-200 h-fit w-fit"> <FaWallet className='text-xl text-sub' /> </div>
                            <div className="">
                                <div className="text-base font-bold">Reward Balance ({user.coinbal?.toLocaleString()}MB)</div>
                                {(active ? parseInt(single.amountToPay) : parseInt(single.amount)) > user.coinbal ? <div className="text-sm text-red-600">Insufficient Balance</div> :
                                    <div className="text-sm text-green-500">Good to go</div>}
                            </div>
                        </div>
                        <div className="flex items-center self-start justify-end gap-1">
                            <SlArrowRight />
                        </div>
                    </div>
                </div>}
                {screen === 2 && <div className='pt-3 mt-3 border-t'>
                    <div className="">Transaction Pin <span className="text-2xl text-red-600">*</span> </div>
                    <Forminput type="password" value={code} onChange={e => handleCode(e.target.value)} min="0" max="6" label={'****'} />
                </div>}
                <div className="pt-3 pb-5">
                    {screen === 1 && <Formbutton handleSubmission={validateRequest} disable={btn} title='proceed' bg={mainbg} loading={loading} />}
                </div>
                {screen === 2 && <div className="grid grid-cols-2 gap-20 pt-3 pb-5">
                    <button onClick={() => setScreen(1)} className="px-4 py-3 capitalize rounded-lg bg-slate-200 dark:text-slate-700">Back</button>
                    <Formbutton handleSubmission={confirmRequest} disable={btn} title='proceed' bg={mainbg} loading={loading} />
                </div>}
            </div>
        </ModalLayout>
    )
}

