

import React, { useCallback, useEffect, useState } from 'react'
import ALayout from 'components/admin/ALayout'
import AHeader from 'components/admin/AHeader'
import { Apis, Authgeturl } from 'components/Apis'
import { Errors, NairaSign, SingleServiceStatus } from 'components/utils/functions'
import moment from 'moment'
import Spinner from 'components/utils/Spinner'
import HistoryModal from './HistoryModal'
import Pagination from 'components/utils/Pagination'
import { useSearchParams } from 'react-router-dom'

const Tableheaders = [
    "user",
    "amount",
    "tag",
    "status",
    "date created",
]


export default function WithdrawalHistory() {
    const [plans, setPlans] = useState([])
    const [loading, setLoading] = useState(false)
    const [single, setSingle] = useState({})
    const [view, setView] = useState(false)
    const [query, setQuery] = useSearchParams()

    const FetchAllPlans = useCallback(async () => {
        setLoading(true)
        try {
            const res = await Authgeturl(`${Apis.user.all_withdrawals}?p=${query.get('p') || 1}`)
            if (res.status === 200) return setPlans(res)
        } catch (error) {
            Errors(error)
        } finally {
            setLoading(false)
        }
    }, [query])
    useEffect(() => { FetchAllPlans() }, [FetchAllPlans])

    const handleSingle = val => {
        setSingle(val)
        setView(!view)
    }

    const handlePage = num => {
        setQuery({...query, p: num})
    }

    if (loading) return (
        <ALayout>
            <div className="">
                <AHeader
                    title="total Users Registered"
                    total={0}
                    url={false}
                />
                <div className="overflow-x-auto scrollsdown">
                    <div className="mt-10 w-full">
                        {loading && <Spinner />}
                    </div>
                </div>
            </div>
        </ALayout>
    )
    return (
        <ALayout>
            {view && <HistoryModal
            tag="withdraw"
                data={single}
                closeView={() => setView(!view)}
                refetch={() => { FetchAllPlans(); setView(!view) }}
            />}
            <div className="">
                <AHeader
                    title="total Withdrawal History"
                    total={`Showing ${plans.data?.length} out of ${plans.total}`}
                    url={false}
                />
                <div className="overflow-x-auto scrollsdown mt-10">
                    <div className="w-full">
                        <table className='table table-auto w-full bg-white p-3 text-sm'>
                            <thead>
                                <tr className='bg-slate-700 text-white'>
                                    {Tableheaders.map((ele, i) => (
                                        <td className='capitalize p-3' key={i}>{ele}</td>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {plans.data?.map((item, i) => (
                                    <tr
                                        key={i}
                                        className='cursor-pointer border-b border-r border-l'
                                        onClick={() => handleSingle(item)}
                                    >
                                    <td className='p-3 uppercase'>{item.user_file?.firstname} {item.user_file?.lastname}</td>
                                    <td className='p-3'>{NairaSign}{item.amount?.toLocaleString()}</td>
                                    <td className='p-3'>{item.title}</td>
                                    <td className={`p-3 capitalize ${SingleServiceStatus(item.status)}`}>{item.status}</td>
                                    <td className='p-3'>{moment(item.createdAt).format('DD-MM-YYYY hh:ss A')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <Pagination 
                onChange={handlePage}
                page={plans.p}
                perPage={plans.page_size}
                total={plans.total}
                />
            </div>
        </ALayout>
    )
}


