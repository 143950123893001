import { useQuery } from '@tanstack/react-query'
import { Apis, Authgeturl } from 'components/Apis'
import ModalLayout from 'components/utils/ModalLayout'
import Spinner from 'components/utils/Spinner'
import { PiUserBold } from 'react-icons/pi'

export default function BeneficiaryModal(props) {
    const { data, isLoading } = useQuery({
        queryKey: ['get-user-beneficiaries'],
        queryFn: async () => {
            const response = await Authgeturl(Apis.user.my_beneficiaries)
            return response
        }
    })
    if (isLoading) return (
        <ModalLayout closeView={props.closeview}>
            <div className="w-fit mx-auto"> <Spinner /> </div>
        </ModalLayout>
    )

    function HandleActiveUser(user) {
        props.setActive({active: true, data: user})
        props.sendActiveUser(user)
        props.closeview()
    }
    return (
        <ModalLayout closeView={props.closeview}>
            <div className="">
                <div className="bg-slate-100 p-3">
                    <div className="font-bold text-2xl">Beneficiaries...</div>
                </div>
                {data?.data?.map((user, index) => (
                    <div className="text-sm flex items-center gap-2 p-2 border-b last:border-none" key={index}>
                        <div className="text-2xl text-sub p-1 rounded-full border border-sub">
                            <PiUserBold />
                        </div>
                        <div className="">
                            <div className="font-bold uppercase">{user.fullname}</div>
                            <div className="">{user.bank_name}</div>
                            <div className="">*******{user.account_number?.slice(-4)}</div>
                        </div>
                        <div 
                        onClick={() => HandleActiveUser(user)}
                        className={`uppercase w-fit ml-auto font-bold text-xs cursor-pointer px-2 py-1.5 border rounded-md border-slate-400 hover:text-white hover:bg-sub transition-all hover:border-sub hover:scale-90 ${props.active?.account_number === user.account_number && 'bg-sub transition-all text-white border-sub scale-90'}`}>Select</div>
                    </div>
                ))}
            </div>
        </ModalLayout>
    )
}
