import React from 'react'
import ALayout from 'components/admin/ALayout'
import ShortletForm from './ShortletForm'

const NewShortlet = () => {
  return (
    <ALayout>
      <ShortletForm itemdata={null} imgs={[]} />
    </ALayout>
  )
}

export default NewShortlet
