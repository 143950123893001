import React, {useState } from 'react'
import { ErrorAlert, NairaSign } from 'components/utils/functions'
import Confirmation from './Confirmation'
import { Apis, Authgeturl, Authposturl } from 'components/Apis'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import SinglePurchase from './SinglePurchase'
import ConfirmationModal from 'components/utils/ConfirmationModal'
import { dispatchPlan } from 'app/reducer'
import { SlExclamation } from 'react-icons/sl'
import { useQuery } from '@tanstack/react-query'
import ModalLayout from 'components/utils/ModalLayout'

const BuyHistory = () => {
    const [view, setView] = useState(false)
    const [text, setText] = useState('')
    const [shows, setShows] = useState(false)
    const [open, setOpen] = useState({
        status: false,
        data: {}
    })
    const [loads, setLoads] = useState(false)
    const { plan } = useSelector(state => state.data)
    const dispatch = useDispatch()
    // const [data, setData] = useState([])
    const [single, setSingle] = useState({})

    const sellStock = (item) => {
        setShows(!shows)
        setSingle(item)
    }

    const SellOffStock = async () => {
        if (moment().isAfter(new Date(single.endDate))) {
            //pass the stockid and the stock wallet id
            const data = {
                walletid: plan.id,
                investid: single.id
            }
            setLoads(true)
            try {
                const res = await Authposturl(Apis.investments.sell_stock, data)
                if (res.status === 200) {
                    setText(res.msg)
                    setView(!view)
                    setShows(!shows)
                    dispatch(dispatchPlan(res.stock))
                    refetch()
                } else {
                    ErrorAlert(res.msg)
                }
            } catch (error) {
                ErrorAlert(`${error}`)
            } finally {
                setLoads(false)
            }
        } else {
            ErrorAlert('Cannot sell stock at the moment')
        }
    }
    const { data, isLoading, error, isError, refetch } = useQuery({
        queryKey: [`buy-history-${plan.id}`],
        queryFn: async () => {
            const res = await Authgeturl(`${Apis.investments.my_purchases}/${plan.id}/${plan.plans.id}`)
            if (res.status === 200) {
                return res.msg
            }
        }
    })
    if (isLoading) {
        return (
            <>
                {new Array(6).fill().map((item, i) => (
                    <div key={i} className="bg-slate-200 dark:bg-slate-600 h-[5rem] mb-3 rounded-lg"></div>
                ))}
            </>
        )
    }
    if(isError) {
        return (
            <>
            <div className="text-center">{error}</div>
            </>
        )
    }

    const handleSingle = item => {
        setOpen({
            status: true,
            data: item
        })
    }


    return (
        <div>
            {view && <Confirmation title="Sell Order Succesfully Initiated" texts={text} closeView={() => setView(!view)} />}

            {shows && <ConfirmationModal
                closeView={() => setShows(!shows)}
                loading={loads}
                mainbg='bg-green-600'
                title="Confirm you are about to sell off this stock"
                handleSubmission={SellOffStock}
            />}
            <div className={`${open.status ? '' : 'hidden'}`}>
                <ModalLayout closeView={() => setOpen({ ...open, status: false })}>
                    <div className="p-4 border-b">
                        <div className="font-bold">Investment Details</div>
                        <div className="flex items-center gap-2 text-sm"> <span className="font-light">Current</span>: <span className=' text-green-500'> {NairaSign} <span className="text-xl">{(open.data.returnProfit + open.data.amount)?.toLocaleString()}</span> </span> </div>
                    </div>
                    <div className="w-11/12 mx-auto py-3">
                        <div className="grid grid-cols-2 gap-3 p-2">
                            <div className="text-sm">Amount Invested</div>
                            <div className="text-sm text-right">{NairaSign}{open.data.amount?.toLocaleString()}</div>
                        </div>
                        <div className="grid grid-cols-2 gap-3 p-2">
                            <div className="text-sm">Accumulated Returns</div>
                            <div className="text-sm text-right">{NairaSign}{open.data.returnProfit?.toLocaleString()}</div>
                        </div>
                        <div className="grid grid-cols-2 gap-3 p-2">
                            <div className="text-sm">Reserved</div>
                            <div className="text-sm text-right">{NairaSign}{(open.data.returnProfit + open.data.amount)?.toLocaleString()}</div>
                        </div>
                        <div className="grid grid-cols-2 gap-3 p-2">
                            <div className="text-sm">Stock quantity bought</div>
                            <div className="text-sm text-right">{open.data.quantity}</div>
                        </div>
                        <div className="grid grid-cols-2 gap-3 p-2">
                            <div className="text-sm">Return on investment</div>
                            <div className="text-sm text-right">{open.data.percent}%</div>
                        </div>
                        <div className="grid grid-cols-2 gap-3 p-2">
                            <div className="text-sm">Time Laps</div>
                            <div className="text-sm text-right">{open.data.timed}</div>
                        </div>
                        <div className="grid grid-cols-2 gap-3 p-2">
                            <div className="text-sm">Duration</div>
                            <div className="text-sm text-right">{open.data.duration} {open.data.duration === '1' ? `${open.data.durationType?.slice(0, -1)}` : `${open.data.durationType}`}</div>
                        </div>
                        <div className="grid grid-cols-2 gap-3 p-2">
                            <div className="text-sm">Auto Renewed</div>
                            <div className="text-sm text-right uppercase">{open.data.autoRenew}</div>
                        </div>
                        <div className="grid grid-cols-2 gap-3 p-2">
                            <div className="text-sm">Sellable</div>
                            <div className="text-sm text-right uppercase">{open.data.sellable}</div>
                        </div>
                        <div className="grid grid-cols-2 gap-3 p-2">
                            <div className="text-sm">Sold</div>
                            <div className="text-sm text-right uppercase">{open.data.sold}</div>
                        </div>
                        <div className="grid grid-cols-2 gap-3 p-2">
                            <div className="text-sm">Start Date</div>
                            <div className="text-sm text-right">{moment(new Date(open.data.startDate)).format('DD-MM-YYYY hh:mma')}</div>
                        </div>
                        <div className="grid grid-cols-2 gap-3 p-2">
                            <div className="text-sm">Due Date</div>
                            <div className="text-sm text-right">{moment(new Date(open.data.endDate)).format('DD-MM-YYYY hh:mma')}</div>
                        </div>
                        <div className="grid grid-cols-2 gap-3 p-2">
                            <div className="text-sm">Date Initiated</div>
                            <div className="text-sm text-right">{moment(new Date(open.data.createdAt)).format('DD-MM-YYYY hh:mma')}</div>
                        </div>
                    </div>
                </ModalLayout>
            </div>
            <div className="">
                {data.length < 1 ? <>
                    <div className="flex items-center justify-center gap-2 text-zinc-500 dark:text-zinc-300 text-sm"> <SlExclamation /> You have no purchase history</div>
                </> : data.map((item, i) => (
                    <SinglePurchase
                    handleSingle={handleSingle}
                        item={item}
                        key={i}
                        handleResult={sellStock}
                    />
                ))}
            </div>
        </div>
    )
}

export default BuyHistory
