import React, { useLayoutEffect, useState } from 'react'
import logo from 'assets/main-images/logo.png'
import { Link } from 'react-router-dom'
import { SlArrowUp, SlEnvolope, SlPhone } from 'react-icons/sl'
import { FaFacebookF, FaInstagram, FaWhatsapp } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import Formbutton from '../utils/Formbutton'
import { BackToTop, ErrorAlert, Errors, SuccessAlert } from '../utils/functions'
import { Apis, Posturl } from '../Apis'
import { Bounce, Slide, JackInTheBox, Rotate, Zoom } from 'react-awesome-reveal'
import Imaged from '../utils/Imaged'

const quicklinks = [
    {
        title: 'partnership',
        url: '/partnership'
    },
    {
        title: 'about',
        url: '/about-us'
    },
    {
        title: 'terms and conditions',
        url: '/terms-and-conditions'
    },
    {
        title: 'faqs',
        url: '/faqs'
    },
    {
        title: 'privacy',
        url: '/privacy-policy'
    },
]

const Footer = () => {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [views, setViews] = useState(false)

    const handleSubscribe = async e => {
        e.preventDefault()
        if (!email) return ErrorAlert('Provide a valid email address')
        const form = { email }
        setLoading(true)
        try {
            const response = await Posturl(Apis.feeds.newsletter_subscription, form)
            if (response.status === 200) {
                SuccessAlert(response.msg)
                setEmail('')
            } else {
                ErrorAlert(response.msg)
            }
        } catch (error) {
            Errors(`${error.message}`)
        } finally {
            setLoading(false)
        }
    }

    useLayoutEffect(() => {
        document.querySelector('body').onscroll = function () { myFunction() };
    }, [])

    function myFunction() {
        if (document.body.scrollTop > 250 || document.documentElement.scrollTop > 250) {
            setViews(true)
        } else {
            setViews(false)
        }
    }


    return (
        <>
            {views && <div
                onClick={BackToTop}
                className="fixed z-10 bottom-[10rem] right-5 cursor-pointer bg-sub text-white p-3 rounded-md shadow-xl"> <SlArrowUp /> </div>}

            <div className='relative bgbg'>
                {/* <div className="absolute top-0 left-0 w-full h-full bgbg opacity-10" style={{ backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}></div> */}
                <div className="pt-10 pb-20 bg-main/100">
                    <div className="z-10 grid w-11/12 grid-cols-1 gap-10 mx-auto lg:grid-cols-4">
                        <div className="col-span-1">
                            <div className="bg-white p-2 w-fit rounded-md">
                                <Bounce><Imaged src={logo} alt="" className="w-auto h-12" /></Bounce>
                            </div>
                            <div className="flex items-center gap-4 mt-10">
                                <Rotate><div className="p-2 text-xl text-white rounded-lg bg-slate-500"> <FaFacebookF /> </div></Rotate>
                                <Rotate><div className="p-2 text-xl text-white rounded-lg bg-slate-500"> <FaInstagram /> </div></Rotate>
                                <Rotate><div className="p-2 text-xl text-white rounded-lg bg-slate-500"> <FaXTwitter /> </div></Rotate>
                                <Rotate><div className="p-2 text-xl text-white rounded-lg bg-slate-500"> <FaWhatsapp /> </div></Rotate>
                            </div>
                        </div>
                        <div className="col-span-3">
                            <div className="grid grid-cols-1 gap-5 lg:grid-cols-3">
                                <div className="text-slate-200">
                                    <JackInTheBox> <div className="mb-5 text-2xl font-bold text-white">NewsLetters</div></JackInTheBox>
                                    <Zoom> <div className="text-xl">Subscribe to our weekly newsletter for inspiring content that'll spur you to success</div></Zoom>
                                    <form onSubmit={handleSubscribe} className='relative mt-10 flex flex-col gap-5'>
                                        <input value={email} onChange={e => setEmail(e.target.value)} type="email" placeholder='Email address' className='w-full p-3 text-black bg-white border-none outline-none' />
                                        <Formbutton title={'Subscribe'} loading={loading} bg="bg-sub" />
                                    </form>
                                </div>
                                <div className="">
                                    <Bounce><div className="mb-6 text-3xl font-bold text-white">Quick links</div></Bounce>
                                    <div className="flex flex-col">
                                        {quicklinks.map((item, index) => (
                                            <Link
                                                onClick={BackToTop}
                                                to={`${item.url}`} key={index} className='py-2.5 capitalize text-slate-200 text-xl'> <Bounce>{item.title}</Bounce> </Link>
                                        ))}
                                    </div>
                                </div>
                                <div className="text-slate-200">
                                    <JackInTheBox><div className="mb-6 text-3xl font-bold text-white">Get in touch</div></JackInTheBox>
                                    {/* <div className="flex gap-3 mb-6">
                                    <Slide><SlGlobe className='text-2xl text-sub' /></Slide>
                                    <Slide direction="right"><div className="">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Animi, explicabo?</div></Slide>
                                </div> */}
                                    <div className="flex gap-3 mb-6">
                                        <Slide><SlEnvolope className='text-2xl text-sub' /></Slide>
                                        <Slide direction="right"><div className="">support@quickdirect.com.ng</div></Slide>
                                    </div>
                                    <div className="flex gap-3 mb-6">
                                        <Slide><SlPhone className='text-2xl text-sub' /></Slide>
                                        <Slide direction="right"><div className="">+23470695050040</div></Slide>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer
