import React from 'react'
import UserLayout from 'components/user/UserLayout'
import ProfileComponent from 'components/utils/ProfileComponent'

const Profile = () => {
  return (
    <UserLayout title={'Profile'}>
      <ProfileComponent />
    </UserLayout>
  )
}

export default Profile