
import React, { useState } from 'react'
import SideViewModalLayout from 'components/utils/SideViewModalLayout'
import Formbutton from 'components/utils/Formbutton'
import { ErrorAlert, Errors, SuccessAlert } from 'components/utils/functions'
import { Apis, Authposturl, Authputurl } from 'components/Apis'
import Forminput from 'components/utils/Forminput'
import DailOptions from 'components/utils/DailOption'

export default function ManagersModal({ closeView, refetch, item, plans }) {
    const [loading, setLoading] = useState(false)
    const [view, setView] = useState(1)
    const [forms, setForms] = useState({
        name: item?.name || '',
        email: item?.email || '',
        phone: item?.phone || '',
        whatsapp: item?.whatsapp || '',
        planid: item?.plan || '',
        dailcode: item?.dailcode || '+234',
    })

    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }

    const SubmitForm = async (tag) => {
        if (!forms.planid) return ErrorAlert('Select an investment plan')
        if (!forms.name) return ErrorAlert('Manager name is required')
        if (!forms.email) return ErrorAlert('Manager email is required')
        if (!forms.phone) return ErrorAlert('Manager phone is required')
        if (!forms.dailcode) return ErrorAlert('Country Dail code is required')
        if (!forms.whatsapp) return ErrorAlert('Manager whatsapp is required')
        const formdata = {
            ...forms,
            tag
        }
        setLoading(true)
        try {
            const response = await Authposturl(Apis.investments.investment_manager, formdata)
            if (response.status === 200) {
                SuccessAlert(response.msg)
                return refetch()
            } else {
                ErrorAlert(`${response.msg}`)
            }
        } catch (e) {
            Errors(e)
        } finally {
            setLoading(false)
        }
    }
    return (
        <SideViewModalLayout closeView={closeView}>
            {view === 1 && <div className="p-4 mt-10">
                <div className="font-bold text-xl mb-5">{item?.id ? 'Update Category' : 'Add New Category'}
                    {item?.id && <button
                        onClick={() => setView(2)}
                        className="bg-red-500 ml-3 text-white py-2 px-5 rounded-md text-sm capitalize">delete</button>}
                </div>
                <form>
                    {!item?.name && <div className="mb-4">
                        <Forminput
                            formtype="select"
                            formName={'planid'}
                            value={forms.planid}
                            onChange={handleForms}
                        >
                            {plans.map((ele, index) => (
                                <option value={ele.id} key={index}>{ele.title}</option>
                            ))}
                        </Forminput>
                    </div>}
                    <div className="mb-4">
                        <Forminput
                            formName={'name'}
                            label={'Name'}
                            value={forms.name}
                            onChange={handleForms}
                        />
                    </div>
                    <div className="mb-4">
                        <Forminput
                            formName={'email'}
                            label={'Email Address'}
                            value={forms.email}
                            onChange={handleForms}
                        />
                    </div>
                    <div className="mb-4">
                        <Forminput
                            formName={'phone'}
                            label={'Phone Number'}
                            value={forms.phone}
                            onChange={handleForms}
                        />
                    </div>
                    <div className="mb-4">
                        <div className="">Phone Number</div>
                        <div className="grid grid-cols-8 gap-3">
                            <div className="col-span-3">
                                <DailOptions
                                    defaultValue={forms.dailcode}
                                    setup={(val) => setForms({ ...forms, dailcode: val.dial_code })}
                                />
                                {/* <Forminput formtype="select" formName="dailcode" value={forms.dailcode} onChange={handleForms}>
                                    {PhoneCodes.map((item, i) => (
                                        <option value={item.dial_code} key={i}>{item.dial_code}</option>
                                    ))}
                                </Forminput> */}
                            </div>
                            <div className="col-span-5">
                                <Forminput label="" placeholder='' title='number' value={forms.whatsapp} formName="whatsapp" onChange={handleForms} />
                            </div>
                        </div>
                    </div>
                    <Formbutton
                        type='button'
                        handleSubmission={() => SubmitForm(item?.name ? 'update' : 'create')}
                        loading={loading}
                        title="Submit"
                    />
                </form>
            </div>}
            {view === 2 && <div className="p-4 mt-10">
                <div className="font-bold text-xl mb-5">Delete <span className="text-red-400">{item.name}</span> </div>
                <div className="text-center mb-10">Are you sure you want to delete {item.name} as manager?</div>
                <form>
                    <Formbutton
                        handleSubmission={() => SubmitForm('delete')}
                        loading={loading}
                        title="proceed"
                    />
                </form>
            </div>}
        </SideViewModalLayout>
    )
}


