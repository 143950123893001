import React, { useState } from 'react'
import Forminput from '../utils/Forminput'
import Formbutton from '../utils/Formbutton'
import { ErrorAlert, SuccessAlert } from '../utils/functions'
import { Apis, Posturl } from '../Apis'

export default function ContactForm() {
    const [loading, setLoading] = useState(false)
    const [forms, setForms] = useState({
        firstname: "",
        lastname: "",
        email: "",
        subject: "",
        message: "",
    })
    const handleForms = e => {
        setForms({...forms, [e.target.name]: e.target.value })
    }
    const handleSubmission = async (e) => {
        e.preventDefault()
        if(!forms.firstname) return ErrorAlert('First name is required')
        if(!forms.lastname) return ErrorAlert('Last name is required')
        if(!forms.email) return ErrorAlert('Email address is required')
        if(!forms.subject) return ErrorAlert('Subject of contact is required')
        if(!forms.message) return ErrorAlert('Your message is required')
        setLoading(true)
        try {
            const response = await Posturl(Apis.user.upload_feedback_from_client, forms)
            if(response.status === 200) {
                SuccessAlert(response.msg)
                setForms({
                    firstname: "",
                    lastname: "",
                    email: "",
                    subject: "",
                    message: "",
                })
                return
            }else {
                ErrorAlert(`${response.msg}`)
                return
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        }finally {
            setLoading(false)
        }
    }
  return (
    <div className='w-11/12 mx-auto lg:w-10/12 my-20'>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-20">
            <div className="flex flex-col justify-center">
                <div className="font-bold text-4xl lg:text-5xl">Get in touch</div>
                <div className="mb-10 text-slate-500 lg:w-10/12 text-xl font-light">Our friendly support team would love to hear from you.</div>
                <form onSubmit={handleSubmission}>
                    <div className="grid grid-cols-2 gap-5">
                        <Forminput label={'First name'} value={forms.firstname} onChange={handleForms} formName={'firstname'} />
                        <Forminput label={'Last name'} value={forms.lastname} onChange={handleForms} formName={'lastname'} />
                    </div>
                    <Forminput label={'Email address'} value={forms.email} onChange={handleForms} formName={'email'} />
                    <Forminput label={'Subject'} value={forms.subject} onChange={handleForms} formName={'subject'} />
                    <Forminput formtype='textarea' label={'Enter message here...'} formName={'message'} value={forms.message} onChange={handleForms}></Forminput>
                    <div className="w-fit ml-auto">
                        <Formbutton title="Submit request" loading={loading} />
                    </div>
                </form>
            </div>
            <div className="contacts h-[30rem] lg:h-[40rem] rounded-2xl"></div>
        </div>
    </div>
  )
}
