import { BarChart, PieChart, pieArcLabelClasses } from "@mui/x-charts";
import SLayout from "components/superadmin/SLayout";
import { useQuery } from "@tanstack/react-query";
import { Apis, Authgeturl } from "components/Apis";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';


ChartJS.register(ArcElement, Tooltip, Legend);


export default function PortalHome() {
    const shareData = useQuery({
        queryKey: ['s-all-shares-001'],
        queryFn: async () => {
            const response = await Authgeturl(Apis.shares.all_share_asset)
            if (response.status === 200) {
                return response.msg[0]
            }
        }
    })
    const shares = shareData?.data
    const dataSet = {
        labels: ['Total Shares', 'Sold Shares', 'Authorized Shares'],
        datasets: [
            {
                // label: '# of Votes',
                data: [shares?.total, shares?.sold, shares?.authorized],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };


    const data = [
        { value: shares?.total, label: 'Total' },
        { value: shares?.sold, label: 'Sold' },
        { value: shares?.authorized, label: 'Authorized' },
    ];

    const size = {
        width: 400,
        height: 200,
    };


    return (
        <SLayout>
            <div className="grid grid-cols-1 gap-10 md:grid-cols-2">
                <div className="p-3 bg-white rounded-lg">
                    <Doughnut data={dataSet} />
                </div>
                <div className="p-3 bg-white rounded-lg">
                    <div className="w-2/5 px-3 py-2 font-semibold rounded-md bg-slate-200">Shares</div>
                    <div className="mt-2 border-t">
                        <PieChart
                            series={[
                                {
                                    arcLabel: (item) => `${item.value}`,
                                    // arcLabel: (item) => `${item.label} (${item.value})`,
                                    arcLabelMinAngle: 45,
                                    data,
                                },
                            ]}
                            sx={{
                                [`& .${pieArcLabelClasses.root}`]: {
                                    fill: 'white',
                                    fontWeight: 'bold',
                                    fontSize: 14,
                                },
                            }}
                            {...size}
                        />
                    </div>
                    <div className="flex flex-wrap items-center gap-3">
                        <div className="flex items-center gap-2 px-3 py-2 text-xs rounded-lg bg-slate-200">{shares?.total?.toLocaleString()}(Total Shares)</div>
                        <div className="flex items-center gap-2 px-3 py-2 text-xs rounded-lg bg-slate-200">{shares?.authorized?.toLocaleString()}(Authorized Shares)</div>
                        <div className="flex items-center gap-2 px-3 py-2 text-xs rounded-lg bg-slate-200">{shares?.sold?.toLocaleString()}(Sold Shares)</div>
                    </div>
                </div>
                <div className="p-3 bg-white rounded-lg">
                    <div className="">
                        <BarChart
                            xAxis={[
                                {
                                    id: 'barCategories',
                                    data: ['bar A', 'bar B', 'bar C'],
                                    scaleType: 'band',
                                },
                            ]}
                            series={[
                                {
                                    data: [2, 5, 3],
                                },
                            ]}
                            width={500}
                            height={300}
                        />
                    </div>
                </div>
                <div className="p-3 bg-white rounded-lg"></div>
                <div className="p-3 bg-white rounded-lg"></div>
            </div>
        </SLayout>
    )
}