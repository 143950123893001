import React, { useEffect, useState } from 'react'
import { Apis, Authgeturl } from 'components/Apis'
import { Errors, NairaSign } from 'components/utils/functions'
import Spinner from 'components/utils/Spinner'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'
import ALayout from 'components/admin/ALayout'
import Pagination from 'components/utils/Pagination'
import AHeader from 'components/admin/AHeader'
import ServiceTransactionsModal from './ServiceTransactionsModal'

const Tableheaders = [
    "User",
    "Amount",
    "Purchase",
    "Status",
    "Reference",
    "Message",
    "date initiated",
  ]

  export default function AllServiceTransactions() {
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState({})
    const [query, setQuery] = useSearchParams()
    const [views, setViews] = useState({
        status: false,
        data: {}
    })

    useEffect(() => {
        const FetchOrders = async () => {
            setLoading(true)
            try {
                const response = await Authgeturl(`${Apis.mobilenig.admin_all_transactions}?p=${query.get('p') ?? 1}`)
                if (response.status === 200) return setItems(response)
            } catch (error) {
                return Errors(`${error.message}`)
            } finally {
                setLoading(false)
            }
        }
        FetchOrders()
    }, [query])

    const ChangePage = num => {
        setQuery({ ...query, p: num })
    }

    const handleSingle = (val) => {
        setViews({...views, status: true, data: val })
    }
    if (loading) return (
        <ALayout title="Service Transactions">
            <div className="">
                <div className="w-11/12 mx-auto mb-5 text-3xl font-bold">All Service Transactions</div>
                <div className="mx-auto mt-20 w-fit"> <Spinner /> </div>
            </div>
        </ALayout>
    )
    return (
        <ALayout title="Service Transactions">
          {views.status &&  <ServiceTransactionsModal 
           data={views.data}
            closeView={() => setViews({...views, status: false})}
            />}
                <div className="">
                    <div className="mb-5 text-xl font-bold">All Service Transactions</div>
                    <AHeader
                        total={`${items.data?.length} of ${items.total}`}
                        tag=""
                        url={false}
                    />
                    <div className="mt-10 overflow-x-auto scrollsdown">
                        <table className='table w-full p-3 text-sm bg-white table-auto'>
                            <thead>
                                <tr className='text-white bg-slate-700'>
                                    {Tableheaders.map((ele, i) => (
                                        <td className='p-3 capitalize' key={i}>{ele}</td>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {items.data.length > 0 && items.data.map((item, i) => (
                                    <tr
                                        key={i}
                                        className='border-b border-l border-r cursor-pointer'
                                        onClick={() => handleSingle(item)}
                                    >
                                        <td className='p-3 truncate'>{item.suser?.firstname} {item.suser?.lastname}</td>
                                        <td className='p-3'>{NairaSign}{item.amount?.toLocaleString()}</td>
                                        <td className='p-3'>{item.action === 'ELE' ? 'Electricity' : item.action}</td>
                                        <td className='p-3 uppercase'>{item.status}</td>
                                        <td className='p-3 uppercase'>{item.ref}</td>
                                        <td className='p-3 uppercase truncate'>{item.desc?.slice(0, 50)}..</td>
                                        <td className='p-3 truncate'>{moment(item.createdAt).format('DD-MM-YYYY hh:ss A')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                        <Pagination
                            total={items.total}
                            perPage={items.page_size}
                            page={items.p}
                            onChange={ChangePage}
                        />
                </div>
        </ALayout>
    )
}

