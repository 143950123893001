import ALayout from 'components/admin/ALayout'
import React from 'react'
import ServiceForm from './ServiceForm'
import { FaArrowLeftLong } from 'react-icons/fa6'
import { Link } from 'react-router-dom'

export default function NewNcwalletService() {
  return (
    <ALayout>
      <div className="font-bold text-xl flex items-center gap-3"> <Link to="/admin/ncwallet-services"><FaArrowLeftLong /></Link> Create New Service</div>
      <ServiceForm singleData={null} />
    </ALayout>
  )
}
