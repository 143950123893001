import React, { useEffect, useState } from 'react'
import UserLayout from 'components/user/UserLayout'
import Forminput from 'components/utils/Forminput'
import Formbutton from 'components/utils/Formbutton'
import { ErrorAlert, Errors, GetAmountMinusPercent, NairaSign, Swals } from 'components/utils/functions'
import { Apis, Authgeturl, Authposturl } from 'components/Apis'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import DailOptions from 'components/utils/DailOption'

const RequestPickup = () => {
    const { id } = useParams()
    const [search, setSearch] = useSearchParams()
    const { user } = useSelector(state => state.data)
    const navigate = useNavigate()
    const [item, setItem] = useState({})
    const [forms, setForms] = useState({
        firstname: user?.firstname || '',
        lastname: user?.lastname || '',
        email: user?.email || '',
        dailcode: "+234",
        phone: '',
        address: '',
    })
    const [loading, setLoading] = useState(false)

    const handleForms = (e) => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        const fetchShortlets = async () => {
            try {
                const response = await Authgeturl(`${Apis.feeds.single_shortlet}/${id}`)
                if (response.status === 200) {
                    return setItem(response.msg)
                }
            } catch (error) {
                Errors(`${error}`)
            } finally {
                //
            }
        }
        fetchShortlets()
    }, [])

    const handleSubmission = async (e) => {
        e.preventDefault()
        if (!forms.firstname) return ErrorAlert('First Name is required')
        if (!forms.lastname) return ErrorAlert('Last Name is required')
        if (!forms.email) return ErrorAlert('Email address is required')
        if (!forms.dailcode) return ErrorAlert('Country dail code is required')
        if (!forms.phone) return ErrorAlert('Phone Number is required')
        if (!forms.address) return ErrorAlert('Pick up address is required')
        const formdata = {
            ...forms,
            phone: `${forms.dailcode}${forms.phone}`,
            shortlet: id,
            checkin: search.get('checkin'),
            checkout: search.get('checkout'),
            checkinTime: search.get('checkinTime'),
            checkoutTime: search.get('checkoutTime'),
            pricing: parseFloat(item.price - (item.price * item.discount / 100) + item.pickupfee),
        }
        setLoading(true)
        try {
            const response = await Authposturl(Apis.feeds.request_pickup, formdata)
            if (response.status === 200) {
                setForms({
                    firstname: '',
                    lastname: '',
                    email: '',
                    dailcode: "+234",
                    phone: '',
                    address: '',
                })
                navigate(`/dashboard/shortlets/${id}/pickup`)
                Swals('Request Recieved', response.msg, 'success')
            } else {
                return ErrorAlert(`${response.msg}`)
            }
        } catch (error) {
            return Errors(error)
        } finally {
            setLoading(false)
        }
    }
    return (
        <UserLayout>
            <div className="w-11/12 mx-auto">
                <div className="">
                    <div className="font-bold text-2xl mb-2">Pick up form</div>
                    <div className="mb-3 text-sm text-zinc-500">All informations are confidential and will assist our drivers in picking you up at your specified designation.</div>
                    <form onSubmit={handleSubmission}>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                            <div className="">
                                <div className="">First Name</div>
                                <Forminput value={forms.firstname} formName={'firstnam'} onChange={handleForms} />
                            </div>
                            <div className="">
                                <div className="">Last Name</div>
                                <Forminput value={forms.lastname} formName={'lastnam'} onChange={handleForms} />
                            </div>
                        </div>
                        <div className="">
                            <div className="">Email Address</div>
                            <Forminput value={forms.email} formName={'emai'} onChange={handleForms} />
                        </div>
                        <div className="">
                            <div className="">Whatsapp Contact</div>
                            <div className="grid grid-cols-5 gap-3">
                                <div className="col-span-1">
                                    <DailOptions
                                        defaultValue={forms.dailcode}
                                        setup={(val) => setForms({ ...forms, dailcode: val.dial_code })}
                                    />
                                </div>
                                <div className="col-span-4">
                                    <Forminput label="" placeholder='' title='number' value={forms.phone} formName="phone" onChange={handleForms} />
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="">Pick up Address</div>
                            <Forminput formtype='textarea' value={forms.address} formName={'address'} onChange={handleForms} />
                        </div>
                        {Object.keys(item).length > 0 && <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div></div>
                            <div>
                                <div className="grid grid-cols-2">
                                    <div className="">{item.pricetag}</div>
                                    <div className="text-right">{NairaSign}{(item.price)?.toLocaleString()}</div>
                                </div>
                                <div className="grid grid-cols-2">
                                    <div className="">Discount</div>
                                    <div className="text-right">{(item.discount)?.toLocaleString()}%</div>
                                    {/* <div className="">{NairaSign}{GetAmountMinusPercent(item.price, item.discount)?.toLocaleString()}</div> */}
                                </div>
                                <div className="grid grid-cols-2">
                                    <div className="">Pick up Fare</div>
                                    <div className="text-right">{NairaSign}{(item.pickupfee)?.toLocaleString()}</div>
                                </div>
                                <div className="grid grid-cols-2">
                                    <div className="">Total cost</div>
                                    <div className="text-right">{NairaSign}{parseFloat(item.price - (item.price * item.discount / 100) + item.pickupfee)?.toLocaleString()}</div>
                                </div>
                            </div>
                        </div>}
                        <div className="w-fit ml-auto mt-5">
                            <Formbutton loading={loading} title='Request Pickup' />
                        </div>
                    </form>
                </div>
            </div>
        </UserLayout>
    )
}

export default RequestPickup
