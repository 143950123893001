

import React, { useRef, useState } from 'react'
import UserLayout from 'components/user/UserLayout'
import Forminput from 'components/utils/Forminput'
import BankOptions from 'components/utils/BankOptions'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6'
import { ErrorAlert, Swals, siteMail } from 'components/utils/functions'
import { SlCloudUpload } from 'react-icons/sl'
import { Apis, Authposturl } from 'components/Apis'
import Formbutton from 'components/utils/Formbutton'
import { useDispatch, useSelector } from 'react-redux'
import img from 'assets/main-images/lady.png'
import { MdVerified } from 'react-icons/md'
import { dispatchUser } from 'app/reducer'
import Imaged from 'components/utils/Imaged'


export default function UploadKyc() {
    const [screen, setScreen] = useState(0)
    const [loading, setLoading] = useState(false)
    const screenTitle = screen === 0 ? `` : screen === 1 ? `Provide your Bank Verification Number (BVN)` : screen === 2 ? `Provide your National Identification Number (NIN)` : `Provide Next of Kin Information (KIN)`
    const [forms, setForms] = useState({
        bvn_number: '',
        bank_code: '',
        bank_name: '',
        nin_number: '',
        nin_image: '',
        kin_name: '',
        kin_relationship: '',
        kin_phone: '',
        kin_email: '',
        kin_address: '',
        kin_city: '',
        kin_state: '',
        kin_zip: '',
        kin_country: ''
    })
    const { user } = useSelector(state => state.data)
    const [image, setImage] = useState(null)
    const imgref = useRef()
    const dispatch = useDispatch()

    const handleUploads = e => {
        const file = e.target.files[0]
        if (file.size > 5000000) {
            imgref.current = null
            return ErrorAlert('File uploaded must not exceed 5MB')
        }
        if (!file.type.startsWith('image/')) {
            imgref.current = null
            return ErrorAlert('File uploaded must be a valid image format (jpg, png, jpeg)')
        }
        setImage(URL.createObjectURL(file))
        setForms({
            ...forms,
            nin_image: file
        })
    }

    const handleForms = e => {
        setForms({
            ...forms,
            [e.target.name]: e.target.value
        })
    }
    const handleNext = async () => {
        if (screen === 1) {
            if (!forms.bvn_number) return ErrorAlert('Bank verification number is required')
            if (!forms.bank_code) return ErrorAlert('Select your bvn registered bank')
        }
        if (screen === 2) {
            if (!forms.nin_number) return ErrorAlert('NIN number is required')
            if (!forms.nin_image) return ErrorAlert('Upload the front cover of your NIN')
        }
        if (screen === 3) {
            if (!forms.kin_name) return ErrorAlert('Next of kin full name is required')
            if (!forms.kin_phone) return ErrorAlert('Next of kin phone contact is required')
            if (!forms.kin_email) return ErrorAlert('Next of kin email address is required')
            if (!forms.kin_address) return ErrorAlert('Next of kin address is required')
            if (!forms.kin_country) return ErrorAlert('Next of kin nationality is required')
            if (!forms.kin_state) return ErrorAlert('Next of kin state of residence is required')
            if (!forms.kin_city) return ErrorAlert('Next of kin residing city is required')
            if (!forms.kin_zip) return ErrorAlert(`zip code of kin's address is required`)
            if (!forms.kin_relationship) return ErrorAlert('Provide relationship between next of kin')
        }
        if (screen > 2) {
            const data = new FormData()
            data.append('bvn_number', forms.bvn_number)
            data.append('bank_code', forms.bank_code)
            data.append('bank_name', forms.bank_name)
            data.append('nin_number', forms.nin_number)
            data.append('nin_image', forms.nin_image)
            data.append('kin_name', forms.kin_name)
            data.append('kin_relationship', forms.kin_relationship)
            data.append('kin_phone', forms.kin_phone)
            data.append('kin_email', forms.kin_email)
            data.append('kin_address', forms.kin_address)
            data.append('kin_city', forms.kin_city)
            data.append('kin_state', forms.kin_state)
            data.append('kin_zip', forms.kin_zip)
            data.append('kin_country', forms.kin_country)
            setLoading(true)
            try {
                const response = await Authposturl(Apis.user.upload_kyc, data)
                if (response.status === 200) {
                    Swals('Request sent', `${response.msg}`, 'success')
                    dispatch(dispatchUser(response.message))
                    setImage(null)
                    setScreen(0)
                    imgref.current = null
                    setForms({
                        bvn_number: '',
                        bank_code: '',
                        bank_name: '',
                        nin_number: '',
                        nin_image: '',
                        kin_name: '',
                        kin_relationship: '',
                        kin_phone: '',
                        kin_email: '',
                        kin_address: '',
                        kin_city: '',
                        kin_state: '',
                        kin_zip: '',
                        kin_country: ''
                    })
                } else {
                    Swals('Request failed', `${response.msg}`, 'error')
                }
            } catch (error) {
                ErrorAlert(`${error.message}`)
            } finally {
                setLoading(false)
            }
        }
        if (screen > 2) return false
        setScreen(prev => prev + 1)
    }
    const handleBack = () => {
        setScreen(prev => prev - 1)
    }

    const setup = val => {
        setForms({
            ...forms,
            bank_code: val.bank_code,
            bank_name: val.bank_name
        })
    }

    const KycCompo = () => {
        if(user.kycstatus === 'pending') return <div className="text-right">Current KYC Status: <span className="uppercase text-sub">{user.kycstatus}...</span> </div>
        if(user.kycstatus === 'retry') return <div className="text-right">Current KYC Status: <span className="uppercase text-slate-500">{user.kycstatus}</span> </div>
        if(user.kycstatus === 'suspended') return <div className="text-right">Current KYC Status: <span className="uppercase text-red-500">{user.kycstatus}</span> </div>
        if(user.kycstatus === null) return <div className="text-right"> </div>
    }
    return (
        <UserLayout title="KYC Uploads">
            {user.kycstatus === 'approved' && <div className="w-11/12 mx-auto lg:w-10/12">
                <div className="flex items-center justify-center flex-col">
                    <div className="">
                        <Imaged src={img} alt="" className="h-[20rem] w-[20rem] object-contain bg-white p-3 rounded-full shadow-2xl" />
                    </div>
                    <div className="text-xl mt-10 flex items-center gap-2 flex-wrap justify-center">Your KYC upload has been successfully <span className="text-green-400 capitalize  flex items-center gap-2">approved <MdVerified /> </span> </div>
                </div>
            </div>}
            {[null, 'retry', 'pending', 'suspended'].includes(user.kycstatus) && <div className="w-11/12 lg:w-10/12 mx-auto">
                <div className="font-bold text-2xl mb-5">{screenTitle}</div>
                <div className="">
                    {screen === 0 && <div className='foil bg-white p-5'>
                        <div className="p-5 bg-slate-100 rounded-lg">
                            <div className="font-bold text-red-600">Disclaimer</div>
                            <div>1. Wrong information can lead to an immediate account suspension</div>
                            <div>2. if a fault is found in one document, every other documents will be faulted as well</div>
                            <div>3. Updates upon submission will be treated accordingly
                                <div className=""> <span className="font-bold">Pending:</span> Kyc uploaded has not been attended to and as such your submission status indicates pending.. </div>
                                <div className=""> <span className="font-bold">Suspended:</span> Kyc uploaded has been suspended due to suspicious information detected upon submission </div>
                                <div className=""> <span className="font-bold">Retry:</span> Kyc uploaded has a fault detected and as such you are prompted to provide a fresh submission of your document. </div>
                                <div className=""> <span className="font-bold">Approved:</span> Kyc uploaded has  been successfully approved.</div>
                            </div>
                            <div>For further assistance, you can reach out to us on  {siteMail}</div>
                        </div>
                    </div>}
                    {screen === 1 && <div className='foil bg-white p-5'>
                        <div className="">
                            <div className="mb-4">
                                <div className="">BVN Number</div>
                                <Forminput label={'BVN'} onChange={handleForms} value={forms.bvn_number} formName={'bvn_number'} />
                            </div>
                            <div className="mb-4 relative">
                                <div className="">BVN Registered Bank</div>
                                <BankOptions defaultValue={forms.bank_name} title="--Select--" setup={setup} />
                            </div>
                        </div>
                    </div>}
                    {screen === 2 && <div className='foil bg-white p-5'>
                        <div className="">
                            <div className="mb-4">
                                <div className="">NIN Number</div>
                                <Forminput label={'NIN'} onChange={handleForms} value={forms.nin_number} formName={'nin_number'} />
                            </div>
                            <div className="mb-4">
                                <div className="">Upload your NIN Front Page</div>
                                <label>
                                    {!image ? <div className="border border-slate-400 gap-2 border-dashed w-full h-44 flex items-center justify-center">
                                        <SlCloudUpload />
                                        <div className="capitalize">upload</div>
                                    </div> :
                                        <div className="w-full h-44 border border-slate-400 border-dashed bg-slate-200">
                                            <Imaged src={image} alt="" className="w-full h-full object-contain" />
                                        </div>
                                    }
                                    <input type="file" onChange={handleUploads} ref={imgref} hidden />
                                </label>
                            </div>
                        </div>
                    </div>}
                    {screen === 3 && <div className='foil bg-white p-5'>
                        <div className="">
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
                                <div className="mb-4">
                                    <div className="">Full Name</div>
                                    <Forminput label={'Full Name'} onChange={handleForms} value={forms.kin_name} formName={'kin_name'} />
                                </div>
                                <div className="mb-4">
                                    <div className="">Phone Number</div>
                                    <Forminput label={'Phone Number'} onChange={handleForms} value={forms.kin_phone} formName={'kin_phone'} />
                                </div>
                            </div>
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
                                <div className="mb-4">
                                    <div className="">Email Address</div>
                                    <Forminput label={'Email'} onChange={handleForms} value={forms.kin_email} formName={'kin_email'} />
                                </div>
                                <div className="mb-4">
                                    <div className="">Address</div>
                                    <Forminput label={'Address'} onChange={handleForms} value={forms.kin_address} formName={'kin_address'} />
                                </div>
                            </div>
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
                                <div className="mb-4">
                                    <div className="">Country</div>
                                    <Forminput label={'Country'} onChange={handleForms} value={forms.kin_country} formName={'kin_country'} />
                                </div>
                                <div className="mb-4">
                                    <div className="">State of residence</div>
                                    <Forminput label={'State'} onChange={handleForms} value={forms.kin_state} formName={'kin_state'} />
                                </div>
                            </div>
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
                                <div className="mb-4">
                                    <div className="">City</div>
                                    <Forminput label={'City'} onChange={handleForms} value={forms.kin_city} formName={'kin_city'} />
                                </div>
                                <div className="mb-4">
                                    <div className="">Zip Code</div>
                                    <Forminput label={'Zip'} onChange={handleForms} value={forms.kin_zip} formName={'kin_zip'} />
                                </div>
                            </div>
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
                                <div className="mb-4">
                                    <div className="">Relationship with Kin</div>
                                    <Forminput label={'Relationship'} onChange={handleForms} value={forms.kin_relationship} formName={'kin_relationship'} />
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className="bg-white p-5">
                        <KycCompo />
                    </div>
                   {[null, 'retry'].includes(user.kycstatus) &&  <div className="flex items-center justify-between bg-white p-5">
                        {screen > 0 ? <button
                            onClick={handleBack}
                            className="capitalize flex items-center gap-2"> <FaArrowLeft />back </button> : <div></div>}
                        <div className="w-fit ml-auto">
                            {screen <= 3 && <Formbutton handleSubmission={handleNext} title={screen > 2 ? 'submit' : 'next'} loading={loading} />}
                        </div>
                    </div>}
                </div>
            </div>}
        </UserLayout>
    )
}


