import toast from "react-hot-toast";
import moment from 'moment'
import Swal from 'sweetalert2'

export const NairaSign = `₦`
export const tokenName = 'Quickdirect-session'
export const siteName = 'Quickdirect'
export const siteNameShort = 'Quickdirect'
export const siteMail = 'support@quickdirect.com.ng'
export const siteContact = '+2340708606050504'
export const viewBal = 'bssl'

export const CatchError = (error) => {
    return `${error.response.data.msg}`
}

export const Swals = (title, text, icon) => {
    return Swal.fire({
        title,
        text,
        icon,
        showConfirmButton: false,
    })
}

export const ErrorAlert = (message) => {
    return toast.error(message, {
        className: 'text-sm',
    });
}

export const SuccessAlert = (message) => {
    return toast.success(message, {
        className: 'text-sm',
    });
}

export const Errors = (message) => {
    return ErrorAlert(message)
}
export const Roles = [
    {
        user: 'superadmin',
        url: '/admin'
    },
    {
        user: 'admin',
        url: '/admin'
    },
    {
        user: 'user',
        url: '/dashboard'
    },
]
export const FindDuplicates = (arr) => {
    // Create an object to track occurrences of each id
    const occurrences = arr.reduce((acc, item) => {
        acc[item.tag] = (acc[item.tag] || 0) + 1;
        return acc;
    }, {});

    // Filter the array to only include items with duplicate tags
    return arr.filter(item => occurrences[item.tag] > 1);
};

export const EditorModules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],

        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'align': [] }],

    ],
}

export const BackToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    })
}

export const NoAmountService = [
    "data",
    "cable"
]


export function DateDiff(startDate, endDate, tag) {
    return moment(new Date(endDate)).fromNow()
}

export const GetServiceTotalPayout = (isAmount, amount, price, percent) => {
    let sum;
    if (isAmount) {
        sum = parseFloat(amount) * percent / 100 + parseFloat(amount)
    } else {
        sum = parseFloat(price) * percent / 100 + parseFloat(price)
    }
    return parseFloat(sum)?.toLocaleString()
}

export const GetPercentFromAmount = (amount, percent) => {
    const sum = amount * percent / 100
    return parseFloat(sum)?.toLocaleString()
}

export const GetAmountMinusPercent = (amount, percent) => {
    const sum = amount - (amount * percent / 100)
    return parseFloat(sum)
}

export const GetPercentFromDigit = (amount, total) => {
    const amt = parseInt(amount)
    const sum = ((amt / total) * 100)
    return sum.toFixed(2)
}

export const GetPercentFromDigit2 = (amount, total) => {
    const amt = parseInt(amount)
    const sum = ((amt / total) * 100)
    return sum.toFixed(5)
}


export function generateRandomNumbersAndAlphabets(length) {
    // Define arrays for numbers and alphabets
    var numbers = [];
    var alphabets = [];
    // Populate numbers array with numbers 0 to 9
    for (var i = 0; i <= 9; i++) {
        numbers.push(i);
    }
    // Populate alphabets array with alphabets A to Z
    for (var j = 65; j <= 90; j++) {
        alphabets.push(String.fromCharCode(j));
    }

    // Combine numbers and alphabets arrays
    var combinedArray = numbers.concat(alphabets);

    // Shuffle the combined array using Fisher-Yates shuffle algorithm
    for (var k = combinedArray.length - 1; k > 0; k--) {
        var randomIndex = Math.floor(Math.random() * (k + 1));
        var temp = combinedArray[k];
        combinedArray[k] = combinedArray[randomIndex];
        combinedArray[randomIndex] = temp;
    }

    // Return the required length of the shuffled array
    const sliced = combinedArray.slice(0, length)
    let result = ''
    sliced.map(ele => {
        return result += ele
    })

    return result
}


export const ScrollChatBottom = () => {
    const msgbox = document.querySelector('.msgbox')
    if (msgbox) {
        msgbox.scrollTo({
            top: msgbox.scrollHeight,
            // behavior: "smooth",
        });
    }
}


export const PickupUsersFront = {
    general: 'general',
    user: 'user',
}

export const RedirectLinks = {
    generalpickup_url: `/shortlets/order`,
    userpickup_url: `/pages/order`,
}

export const ClientInvestmentStatus = {
    active: 'active',
    inactive: 'inactive',
    sell_in: 'sell-in',
    sell_out: 'sell-out',
    buy_in: 'buy-in',
    buy_in_out: 'buy-out',
    sold: 'sold',
    completed: 'completed',
    false: 'false',
    true: 'true',
}

export const WithdrawStatusOrder = {
    completed: 'completed',
    processing: 'processing',
    cancelled: 'cancelled'
}

export const InvestSlots = {
    istock: 'istock',
    midas: 'midas',
    prime: 'prime',
}

export const DurationTypes = {
    seconds: 'seconds',
    minutes: 'minutes',
    hours: 'hours',
    days: 'days',
    months: 'months',
    years: 'years',
}

export const ServiceTransactionStatus = (status) => {
    let output;
    status === "Failed" ?
        output = 'text-red-400 dark:text-red-600 bg-red-100 ring-red-300 dark:ring-red-300' :
        status === 'Cancelled' ?
            output = 'text-slate-400 dark:text-slate-600 bg-slate-100 ring-slate-300 dark:ring-slate-300' :
            status === 'Processing' ?
                output = 'text-orange-400 dark:text-orange-600 bg-orange-100 ring-orange-300 dark:ring-orange-300' :
                output = 'text-green-400 dark:text-green bg-green-100 ring-green-300 dark:ring-green-300'

    return output
}

export const SingleServiceStatus = (status) => {
    let output;
    status.toLowerCase() === 'failed' ?
        output = 'border-red-500 bg-red-100/70 rounded-md font-medium py-1 px-2 text-red-600' :
        status.toLowerCase() === 'cancelled' ?
            output = 'border-slate-500 bg-slate-100/70 rounded-md font-medium py-1 px-2 text-slate-600' :
            status.toLowerCase() === 'processing' ?
                output = 'border-orange-500 bg-orange-100/70 rounded-md font-medium py-1 px-2 text-orange-600' :
                output = 'border-green-500 bg-green-100/70 rounded-md font-medium py-1 px-2 text-green-600'
    return output
}


export const BeneficiaryColorCodes = [
    'bg-sub',
    'bg-main',
    'bg-green-600',
    'bg-sub',
    'bg-mainblue',
    'bg-yellow',
    'bg-sub',
    'bg-main',
    'bg-green-600',
    'bg-mainblue',
    'bg-yellow',
]