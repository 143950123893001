
import React, { useCallback, useEffect, useState } from 'react'
import ALayout from 'components/admin/ALayout'
import AHeader from 'components/admin/AHeader'
import { Apis, Authgeturl } from 'components/Apis'
import { Errors, NairaSign } from 'components/utils/functions'
import moment from 'moment'
import Spinner from 'components/utils/Spinner'
import AccountPlanModal from './AccountPlanModal'

const Tableheaders = [
    "Title",
    "Registration Fee",
    "Direct Discount",
    "Commission 1 Airtime",
    "Commission 1 Data",
    "Commission 2 Airtime",
    "Commission 2 Data",
    "Commission 3 Airtime",
    "Commission 3 Data",
    "Status",
    "date created",
]

export default function AllAccountPlans () {
    const [plans, setPlans] = useState({})
    const [loading, setLoading] = useState(false)
    const [single, setSingle] = useState({})
    const [view, setView] = useState(false)

    const FetchAllPlans = useCallback(async () => {
        setLoading(true)
        try {
            const res = await Authgeturl(Apis.user.all_account_plan)
            if (res.status === 200) return setPlans(res)
        } catch (error) {
            Errors(error)
        } finally {
            setLoading(false)
        }
    }, [])
    useEffect(() => { FetchAllPlans() }, [FetchAllPlans])

    const handleSingle = val => {
        setSingle(val)
        setView(!view)
    }
    if(loading) return (
        <ALayout>
            <div className="">
                <AHeader
                    title="total Account Plans"
                    total={0}
                    url={false}
                />
                <div className="overflow-x-auto scrollsdown">
                <div className="mt-10 w-full">
                    {loading && <Spinner /> }
                </div>
                </div>
            </div>
        </ALayout>
    )
    return (
        <ALayout>
            {view && <AccountPlanModal
                data={single}
                closeView={() => setView(!view)}
                refetch={() => { FetchAllPlans(); setView(!view) }}
            />}
            <div className="">
                <AHeader
                    title="total Account Plans"
                    total={plans.total}
                    url={false}
                    tagtitle='New Account Plan'
                    tag="button"
                    handleClick={() => {setView(true); setSingle({})}}
                />
                <div className="overflow-x-auto scrollsdown">
                <div className="mt-10 w-full">
                    <table className='table table-auto w-full bg-white p-3 text-sm'>
                        <thead>
                            <tr className='bg-slate-700 text-white'>
                                {Tableheaders.map((ele, i) => (
                                    <td className='capitalize p-3 truncate' key={i}>{ele}</td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {plans.data?.map((item, i) => (
                                <tr 
                                key={i}
                                className='cursor-pointer border-b border-r border-l'
                                onClick={() => handleSingle(item)}
                                >
                                    <td className='p-3 truncate uppercase'>{item.title}</td>
                                    <td className='p-3'>{NairaSign}{item.fee?.toLocaleString()}</td>
                                    <td className='p-3'>{item.directDiscount}%</td>
                                    <td className='p-3'>{item.com1airtime}%</td>
                                    <td className='p-3'>{item.com1data}%</td>
                                    <td className='p-3'>{item.com2airtime}%</td>
                                    <td className='p-3'>{item.com2data}%</td>
                                    <td className='p-3'>{item.com3airtime}%</td>
                                    <td className='p-3'>{item.com3data}%</td>
                                    <td className='p-3 truncate'>{item.status === 'true' ? 'Active' : 'Not Active'}</td>
                                    <td className='p-3 truncate'>{moment(item.createdAt).format('DD-MM-YYYY hh:ss A')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
        </ALayout>
    )
}


