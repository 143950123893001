
import React, { useState } from 'react'
import UserLayout from 'components/user/UserLayout'
import Forminput from 'components/utils/Forminput'
import { ErrorAlert, NairaSign, Swals } from 'components/utils/functions'
import Formbutton from 'components/utils/Formbutton'
import { Apis, Authgeturl, Authposturl } from 'components/Apis'
import { useDispatch, useSelector } from 'react-redux'
import { dispatchUser } from 'app/reducer'
import ServiceConfirmation from './ServiceConfirmation'
import { useQuery } from '@tanstack/react-query'
import { ServiceImages } from 'components/utils/PageUtils'
import SuccessModal from 'components/utils/SuccessModal'
import { usePDF } from 'react-to-pdf'


export default function Airtime() {
    const [loading,] = useState(false)
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.data)
    const [open, setOpen] = useState({ status: false, data: {} })
    const [loader, setLoader] = useState(false)
    const [items, setItems] = useState([])
    const [topups, setTopups] = useState({})
    const [forms, setForms] = useState({
        Service: "",
        Serviceid: "",
        Amount: "",
        Number: "",
        network: '',
        actual: "",
    })
    const [single, setSingle] = useState({
        status: false,
        amount: 0,
        actual: '',
        paymethod: '',
        service: '',
        data: {},
        cashback: 0,
        product: '',
        amountToPay: 0,
    })

    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }


    const date = new Date()

    const { toPDF, targetRef } = usePDF({
        filename: `${date.getFullYear()}_${date.getTime()}.pdf`,
        canvas: {
            useCORS: true,
        },
    });



    const { data, isLoading } = useQuery({
        queryKey: [`all-airtime-data-ncwallet`],
        queryFn: async () => {
            const response = await Authgeturl(`${Apis.ncwallet.all_services}?name=AIRTIME`)
            const result = await Authgeturl(Apis.serviceapi.all_airtime_cashback)
            setItems(result.msg)
            return response.data[0]
        }
    })

    const handleSubmission = async (e) => {
        e.preventDefault()
        if (!forms.network) return ErrorAlert('Select your preferred network')
        if (!forms.Number) return ErrorAlert('Provide a valid phone number')
        if (!topups.pay && !forms.Amount) return ErrorAlert('Provide a valid amount')

        let dataToStore;
        if (topups?.id) {
            dataToStore = {
                ...single,
                ...forms,
                amount: topups.price,
                amountToPay: topups.pay,
                actual: topups.price,
                cashback: topups.cashback,
                status: true,
                product: forms.network,
            }
        } else {
            dataToStore = {
                ...single,
                ...forms,
                status: true,
                product: forms.network
            }
        }

        setSingle(dataToStore)
    }

    async function PurchaseAirtimeSub(value) {
        setLoader(true)
        try {
            const result = await Authposturl(Apis.ncwallet.purchase_airtime, value)
            if (result.status !== 200) return Swals('Request Failed', `${result.msg}`, 'error')

            setSingle({
                ...single,
                status: false
            })
            setOpen({
                status: true,
                data: result
            })
            dispatch(dispatchUser(result.user))
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoader(false)
        }
    }

    const handlePriceFee = e => {
        const val = parseFloat(e)
        if (e.length > 0) {
            const item = data.tags.find(ele => ele.id === forms.Serviceid)
            const actual = val
            const sum = (val * item.percentage) / 100 + val
            const cash = (val * item.cashback) / 100

            setSingle({
                ...single,
                amount: sum,
                amountToPay: sum,
                actual,
                cashback: cash,
            })
        } else {
            setSingle({
                ...single,
                status: false,
                amount: '',
                amountToPay: "",
            })
        }
    }
    const SelectTopup = (item) => {
        if (topups?.price !== item.price) {
            setSingle({
                ...single,
                amount: item.price,
                amountToPay: item.pay,
                actual: item.price,
                cashback: item.cashback
            })
            setTopups(item)
            setForms({
                ...forms,
                Amount: item.pay
            })
        } else {
            setTopups({})
            setForms({
                ...forms,
                Amount: ""
            })
        }
    }

    const handleServiceSelection = (item) => {
        if (item) {
            const itemService = data.tags.find(ele => ele.id === item)
            setForms({
                ...forms,
                network: itemService.servicename,
                Serviceid: itemService.id,
                plan_name: itemService.servicename,
                Amount: "",
                Number: "",
                actual: "",
            })
            setSingle({
                ...single,
                amount: "",
                amountToPay: "",
                actual: "",
                cashback: "",
            })
        } else {
            setForms({ ...forms, Service: '' })
        }
    }
    if (isLoading) return (
        <UserLayout title="Airtime Purchase">
            <div className="w-11/12 mx-auto mt-10">
                <div className="p-3 bg-white">
                    <div className="">
                        {new Array(5).fill(0).map((ele, index) => (
                            <div className="bg-slate-200 h-16 w-full animate-pulse mb-3" key={index}></div>
                        ))}
                    </div>
                </div>
            </div>
        </UserLayout>
    )

    return (
        <UserLayout title="Airtime Purchase">
            {open.status && <SuccessModal
                closeview={() => setOpen({ status: false, data: {} })}
                title={'Airtime Purchased Successfully'}
                content2={open.data.msg}
                content={open.data.msg}
                cashback={`${single.cashback}MB`}
                DownloadPDF={() => toPDF()}
                Dismiss={'/service_transactions'}
            />}
            {single.status && <ServiceConfirmation
                loading={loader}
                productTag="Airtime"
                single={single}
                setSingle={setSingle}
                user={user}
                PurchaseService={PurchaseAirtimeSub}
                closeView={() => setSingle({ ...single, status: false })}
            />}
            <div ref={targetRef}>
                {open.status ? <div>
                    <div className="grid grid-cols-2 gap-4 py-2 mt-5">
                        <div className="text-sm">Designated Number</div>
                        <div className="text-sm text-right">{single.Number}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 py-2">
                        <div className="text-sm">Network</div>
                        <div className="text-sm text-right uppercase">{single.network}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 py-2">
                        <div className="text-sm">Amount Paid</div>
                        <div className="text-sm text-right uppercase"> {NairaSign}{single.amountToPay?.toLocaleString()} </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 py-2">
                        <div className="text-sm">Credit Type</div>
                        <div className="text-sm text-right uppercase">Airtime purchase</div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 py-2">
                        <div className="text-sm">to Purchase</div>
                        <div className="text-sm text-right uppercase">Airtime {NairaSign}{single.amount?.toLocaleString()}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 py-2">
                        <div className="text-sm">Transaction Date</div>
                        <div className="text-sm text-right uppercase">{open.data?.tag?.datetime}</div>
                    </div>
                </div> : ''}
            </div>
            <div className="w-11/12 mx-auto mt-10">
                <div className="px-3 py-5 bg-white mb-5">
                    <div className="flex items-center justify-between border-b">
                        <div className="font-extrabold text-lg capitalize">top up</div>
                    </div>
                    <div className="mt-3">Choose an amount</div>
                    <div className="grid grid-cols-3 gap-3 mt-3">
                        {items.map((ele, i) => (
                            <div
                                onClick={() => SelectTopup(ele)}
                                className={`${topups.price === ele.price ? 'bg-teal-500' : 'bg-slate-200/50'} cursor-pointer hover:scale-105 border transition-all rounded-lg px-2 py-2 flex flex-col items-center justify-center`} key={i}>
                                <div className={`${topups.price === ele.price ? 'text-white' : 'text-main'} text-2xl font-bold`}> <span className="text-base">{NairaSign}</span>{ele.price.toLocaleString()}</div>
                                <div className={`${topups.price === ele.price ? 'text-white' : 'text-zinc-500'}`}>pay {NairaSign}{ele.pay.toLocaleString()}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="p-3 bg-white">
                    <form onSubmit={handleSubmission}>
                        <div className="relative mb-4">
                            <div className="">Select Service</div>
                            <div className="grid grid-cols-4">
                                {data?.tags?.map((item, index) => {
                                    const image = ServiceImages.find(ele => ele.network?.toLowerCase() === item.servicename?.toLowerCase())

                                    return (
                                        <div
                                            onClick={() => handleServiceSelection(item.id)}
                                            className={`flex flex-col ${forms.network === item.servicename ? 'bg-orange-100 font-bold' : 'bg-slate-100'} w-11/12 mx-auto items-center justify-center gap-2 px-3 py-2 rounded-md cursor-pointer`} key={index}>
                                            <img src={image.image} alt="" className="size-10" />
                                            <div className="">{item?.servicename}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="mb-4">
                            <div className="">Phone Number</div>
                            <Forminput type="number" formName={'Number'} value={forms.Number} onChange={handleForms} />
                        </div>
                        {!topups?.price && <div className="mb-2">
                            <div className="">Amount ({NairaSign})</div>
                            <Forminput type="number" onKeyup={e => handlePriceFee(e.target.value)} label={'0.00'} formName={'Amount'} value={forms.Amount} onChange={handleForms} />
                        </div>}
                        <Formbutton title="subscribe now" loading={loading} />
                    </form>
                </div>
            </div>
        </UserLayout>
    )
}
