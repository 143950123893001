
import React, { useState } from 'react'
import Forminput from 'components/utils/Forminput'
import Formbutton from 'components/utils/Formbutton'
import { Apis, Authgeturl, Authposturl } from 'components/Apis'
import { ErrorAlert, NairaSign } from 'components/utils/functions'
import { dispatchUser } from 'app/reducer'
import { useDispatch, useSelector } from 'react-redux'
import DataServiceConfirmation from './DataServiceConfirmation'
import { useQuery } from '@tanstack/react-query'
import Spinner from 'components/utils/Spinner'
import { NCWALLETAFRICA_DATA, ServiceImages } from 'components/utils/PageUtils'
import SuccessModal from 'components/utils/SuccessModal'
import { usePDF } from 'react-to-pdf'

export default function RedeemData() {
    const [loading, setLoading] = useState(false)
    const [loader, setLoader] = useState(false)
    const [open, setOpen] = useState({ status: false, data: {} })
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.data)
    const [plans, setPlans] = useState([])
    const [forms, setForms] = useState({
        network: '',
        Action: "DATA",
        Service: "",
        Serviceid: '',
        Plan: "",
        Number: "",
        actual: ''
    })
    const [single, setSingle] = useState({
        status: false,
        cashback: 0,
        amount: 0,
        paymethod: '',
        service: '',
        data: {},
        product: '',
        amountToPay: 0,
    })

    const date = new Date()

    const { toPDF, targetRef } = usePDF({
        filename: `${date.getFullYear()}_${date.getTime()}.pdf`,
        canvas: {
            useCORS: true,
        },
    });


    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }
    const handleSubmission = async e => {
        e.preventDefault()
        if (forms.actual > user.coinbal) return ErrorAlert('Insufficient data bundle to proceed')
        if (!forms.Serviceid) return ErrorAlert('Select your preferred network')
        if (!forms.Plan) return ErrorAlert('Select a Bundle to purchase')
        if (!forms.Number) return ErrorAlert('Provide a valid phone number')

        const tag = NCWALLETAFRICA_DATA.find(ele => ele.plan_id === forms.Plan)
        const dataToStore = {
            ...forms,
            ...single,
            status: true,
            plan_id: tag.plan_id,
            pplan: tag.data_type,
            Number: forms.Number,
            product: tag.plan_size,
            service: tag.network
        }

        setSingle(dataToStore)
    }

    const InitiateRedeemData = async (value) => {
        setLoader(true)
        try {
            const result = await Authposturl(Apis.ncwallet.redeem_data_from_bundle, value)
            setSingle({
                ...single,
                status: false
            })
            setOpen({
                status: true,
                data: result
            })
            dispatch(dispatchUser(result.user))
        } catch (error) {
            ErrorAlert(`${error.message}`)
        } finally {
            setLoader(false)
        }
    }



    const { data, isLoading } = useQuery({
        queryKey: [`all-data-services-for-users`],
        queryFn: async () => {
            const response = await Authgeturl(`${Apis.mobilenig.all_services}?query=data`)
            return response.msg
        }
    })

    const handleService = (val) => {
        if (val) {
            const itemService = ServiceImages.find(ele => ele.network_id === val)
            
            setForms({
                ...forms,
                network: itemService.network,
                Serviceid: itemService.network_id,
            })
            setLoading(true)
            setTimeout(() => {
                const output = NCWALLETAFRICA_DATA.filter(ele => ele.network === itemService.network)
                setPlans(output)
                setLoading(false)
            }, 500);

        } else {
            setForms({
                ...forms,
                network: '',
                Serviceid: '',
            })
        }
    }

    const handlePlans = e => {
        const val = e
        const item = NCWALLETAFRICA_DATA.find(ele => ele.plan_id === val)
        if (val) {
            const itemPrice = parseFloat(item.plan_price.split(' ')[1])
            const planSize = parseFloat(item.plan_size.split(' ')[0])
            const converter = item.plan_size.split(' ')[item.plan_size.split(' ').length - 1]
            let unit;
            if (converter === 'GB') {
                unit = 1024 * planSize
            } else {
                unit = planSize
            }
            if (unit > user.coinbal) return ErrorAlert('Insufficient data bundle to proceed')
            const actual = itemPrice
            const sum = (itemPrice * data.charges) / 100 + itemPrice
            const priced = (itemPrice * data.cashback) / 100
            setForms({
                ...forms,
                Plan: val,
                actual: itemPrice
            })
            setSingle({
                ...single,
                amount: unit,
                amountToPay: sum,
                actual,
                Plan: val,
                unit,
                cashback: priced
            })
        } else {
            setForms({
                ...forms,
                Plan: "",
                actual: ''
            })
            setSingle({
                ...single,
                status: false,
                amount: '',
                Plan: '',
                amountToPay: "",
            })
        }
    }

    if (isLoading) return (
        <div className="p-3 bg-white">
            <div className="">
                {new Array(5).fill(0).map((ele, index) => (
                    <div className="bg-slate-200 h-16 w-full animate-pulse mb-3" key={index}></div>
                ))}
            </div>
        </div>
    )

    return (
        <>
            {open.status && <SuccessModal
                closeview={() => setOpen({ status: false, data: {} })}
                title={'Redeemed Successfully'}
                content2={open.data.msg}
                content={open.data.msg}
                cashback={false}
                DownloadPDF={() => toPDF()}
                Dismiss={'/service_transactions'}
            />}
            {single.status && <DataServiceConfirmation
                loading={loader}
                productTag="Data"
                single={single}
                setSingle={setSingle}
                user={user}
                InitiateRedeemData={InitiateRedeemData}
                closeView={() => setSingle({ ...single, status: false })}
            />}

            <div ref={targetRef}>
                {open.status ? <div>
                    <div className="grid grid-cols-2 gap-4 py-2 mt-5">
                        <div className="text-sm">Designated Number</div>
                        <div className="text-sm text-right">{open.data?.tag?.data?.phone_number}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 py-2">
                        <div className="text-sm">Network</div>
                        <div className="text-sm text-right uppercase">{open.data?.tag?.data?.plan_name} {open.data?.tag?.data?.network}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 py-2">
                        <div className="text-sm">Credit Type</div>
                        <div className="text-sm text-right uppercase">data reward</div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 py-2">
                        <div className="text-sm">to Purchase</div>
                        <div className="text-sm text-right uppercase">data [{open.data?.tag?.data?.plan_name}]</div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 py-2">
                        <div className="text-sm">Transaction Date</div>
                        <div className="text-sm text-right uppercase">{open.data?.tag?.datetime}</div>
                    </div>
                </div> : ''}
            </div>
            <div className="w-11/12 mx-auto mt-10">
                <div className="p-3 bg-white">
                    <form onSubmit={handleSubmission}>
                        <div className="relative mb-4">
                            <div className="">Select Service</div>
                            <div className="grid grid-cols-4">
                                {ServiceImages.map((image, index) => {
                                    const item = NCWALLETAFRICA_DATA.find(ele => ele.network.toLowerCase().startsWith(image.network.toLowerCase()))
                                    return (
                                        <div
                                            onClick={() => handleService(image.network_id)}
                                            className={`flex flex-col ${forms.network === item.network ? 'bg-orange-100 font-bold' : 'bg-slate-100'} w-11/12 mx-auto items-center justify-center gap-2 px-3 py-2 rounded-md cursor-pointer`} key={index}>
                                            <img src={image.image} alt="" className="size-10" />
                                            <div className="text-sm font-bold">{item?.network}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        {loading && <Spinner />}
                        {forms.network && <div className="relative mb-4">
                            <div className="">Select Bundle</div>
                            <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-3">
                                {plans.map((ele, index) => {

                                    const itemPrice = parseFloat(ele.plan_price.split(' ')[1]?.replace(',', ''))
                                    const priced = Math.floor((itemPrice * data.cashback) / 100)
                                    return (
                                        ['SME'].includes(ele.data_type) &&
                                        <div
                                            onClick={() => handlePlans(ele.plan_id)}
                                            key={index} className="flex items-center flex-col cursor-pointer scale-100 hover:scale-105 transition-all">
                                            <div className={`${forms.Plan === ele.plan_id ? 'bg-sub text-white' : 'bg-zinc-300 text-zinc-600'} w-10/12 text-center py-1.5 px-3 z-10 isolate rounded-tr-lg font-semibold rounded-tl-lg text-sm md:text-base capitalize`}>{ele.custom ?? 'data plan'}</div>
                                            <div className={`font-bold w-full px-4 py-1 text-center ${forms.Plan === ele.plan_id ? 'bg-amber-300' : 'bg-zinc-400'} card-label text-xs`}>{ele.plan_size?.replace(' ', '')} + {priced}MB</div>
                                            <div className={`${forms.Plan === ele.plan_id ? 'bg-sub text-white' : 'bg-zinc-300 text-zinc-600'} w-10/12 text-center py-1.5 px-3 z-10 isolate rounded-br-lg rounded-bl-lg text-sm md:text-base`}>{NairaSign}{(parseFloat(ele.plan_price.split(' ')[1]?.replace(',', '')) * data.charges / 100 + parseFloat(ele.plan_price.split(' ')[1]?.replace(',', '')))?.toLocaleString()}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>}
                        <div className="mb-4">
                            <div className="">Phone Number</div>
                            <Forminput type="number" formName={'Number'} value={forms.Number} onChange={handleForms} />
                        </div>
                        <div className="mb-10">Current Reward balance: <span className="text-sub font-bold">{user.coinbal?.toLocaleString()}MB</span> </div>

                        <Formbutton title="purchase data" />
                    </form>
                </div>
            </div>
        </>
    )
}
