import { useState } from "react";
import Formbutton from "../utils/Formbutton";
import Forminput from "../utils/Forminput";
import { ErrorAlert, Errors, tokenName } from "../utils/functions";
import { Apis, Authposturl } from "../Apis";
import { decodeToken } from "react-jwt";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";


export default function LoginShares({closeView}) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [forms, setForms] = useState({
        email: '',
        certno: ''
    })
    const handleForms = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
    }
    const SharesLogin = async (e) => {
        e.preventDefault()
        if (!forms.email) return ErrorAlert('Provide your email address')
        if (!forms.certno) return ErrorAlert('Provide your certificate Number')

        setLoading(true)
        try {
            const response = await Authposturl(Apis.shares.shares_login, forms)
            if (response.status === 200) {
                Cookies.set(tokenName, response.token)
                navigate(`/eu/partner`)
            }else {
                ErrorAlert(`${response.msg}`)
            }
        } catch (error) {
            Errors(`${error.message}`)
        } finally {
            setLoading(false)
        }
    }
    return (
        <div className="fixed top-0 left-0 z-40 flex items-center justify-center w-full h-screen bg-blue-600/30 backdrop-blur-sm">
            <div className="relative w-11/12 max-w-xl p-4 bg-white rounded-lg foil">
                <div onClick={closeView} className="absolute p-2 text-sm rounded-full cursor-pointer right-3 top-3 bg-slate-200"> <FaTimes /> </div>
                <form onSubmit={SharesLogin}>
                    <div className="text-xl font-bold">Track your portfolio</div>
                    <div className="pb-4 mb-10 text-sm font-light border-b text-slate-500">You do not have to go through the stress of creating account to track your portfolio. we've got you covered.</div>
                    <div className="mb-4">
                        <div className="">Email Address</div>
                        <Forminput formName={'email'} value={forms.email} onChange={handleForms} label="Email Address" />
                    </div>
                    <div className="mb-4">
                        <div className="">Certificate Number</div>
                        <Forminput formName={'certno'} value={forms.certno} onChange={handleForms} label="Certificate No." />
                    </div>
                    <div className="mt-5 ml-auto w-fit">
                        <Formbutton title="proceed" loading={loading} />
                    </div>
                    <div className="pt-3 mt-5 text-sm border-t text-slate-500"> <span className="font-extrabold text-red-600">Disclaimer: </span> Do not share your login credential to anyone, you will be liable to whatever happens to your account.</div>
                </form>
            </div>
        </div>
    )
}