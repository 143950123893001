




import React, { useState } from 'react'
import UserLayout from 'components/user/UserLayout'
import { useNavigate } from 'react-router-dom'
import { SlWallet } from 'react-icons/sl'
import Formbutton from 'components/utils/Formbutton'
import { ErrorAlert, Errors, NairaSign, SuccessAlert } from 'components/utils/functions'
import { useDispatch, useSelector } from 'react-redux'
import { dispatchPlan, dispatchUser } from 'app/reducer'
import { Apis, Authposturl } from 'components/Apis'
import Confirmation from '../../Compos/Confirmation'
import InvestLayout from '../../Compos/InvestLayout'
import MidasHistory from './MidasHistory'
import PaymentSelection from '../PaymentSelection'
import Loading from 'components/utils/Loading'

export default function TradeMidas() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { plan, user } = useSelector(state => state.data)
    const [text, setText] = useState('')
    const [view, setView] = useState(false)
    const navigate = useNavigate()
    const [open, setOpen] = useState({
        status: false,
        data: {}
    })
    const [activebtn, setActivebtn] = useState(0)
    const [dura, setDura] = useState({})
    const [screen, setScreen] = useState(true)
    const [forms, setForms] = useState({
        amount: '',
        error: false,
        message: '',
    })
    const [renew, setRenew] = useState(false)

    const handleSubmission = async () => {
        if (!forms.amount) return ErrorAlert('Specify an amount')
        if(parseFloat(forms.amount) < plan.plans?.minDept) return ErrorAlert(`Amount must be up to ${NairaSign}${plan.plans?.minDept?.toLocaleString()}`)
        if (forms.error) return ErrorAlert('You have an error to fix before making a trade')
        if (forms.amount < 1) return ErrorAlert('You have an invalid purchase amount entry')
        if (!dura?.title) return ErrorAlert(`Specify a duration for your plan`)
        const data = {
            stockid: plan.id,
            tag: 'midas',
            amount: parseFloat(forms.amount),
            details: dura,
            renew
        }
        setOpen({
            status: true,
            data
        })
    }

    const handleActivebtn = (val, data) => {
        const amt = parseFloat(forms.amount)
        if (!amt) {
            setActivebtn(0)
            return ErrorAlert(`Enter a valid purchase amount`)
        }
        if (activebtn !== val) {
            setActivebtn(val)
        } else {
            if (!forms.amount) { setActivebtn(0) }
        }
        setDura(data)
    }

    const PurchaseWithBalance = async (val) => {
        if (open.data.amount > user.currbal) return ErrorAlert(`Unable to complete investment: Insufficient account balance`)
        setOpen({
            ...open, status: false
        })
        const formdata = {
            ...open.data,
            payMethod: val
        }
        setLoading(true)
        try {
            // generate an invoice
            const response = await Authposturl(Apis.investments.generate_plan_invoice, formdata)
            if (response.status === 200) {
                const pkdata = {
                    invoice: response.msg
                }
                const res = await Authposturl(Apis.investments.purchase_investment, pkdata)
                if (res.status === 200) {
                    setText(res.text)
                    dispatch(dispatchPlan(res.stock))
                    dispatch(dispatchUser(res.user))
                    setForms({
                        ...forms,
                        amount: ''
                    })
                    setView(!view)
                    setActivebtn(0)
                    SuccessAlert(res.msg)
                    navigate(`/investment/${plan.id}/midas`)
                } else {
                    ErrorAlert(res.msg)
                }
            } else {
                ErrorAlert(response.msg)
            }
        } catch (error) {
            Errors(error)
        } finally {
            setLoading(false)
        }
    }

    const PurchaseWithBank = async (val) => {
        setOpen({
            ...open, status: false
        })
        const formdata = {
            ...open.data,
            payMethod: val
        }
        setLoading(true)
        try {
            // generate an invoice
            const response = await Authposturl(Apis.investments.generate_plan_invoice, formdata)
            if (response.status === 200) {
                const url = window.location.origin
                const pkdata = {
                    url: `${url}/very-investment-purchase/${response.msg}/${plan.id}/midas`,
                    amount: open.data.amount,
                }
                const res = await Authposturl(Apis.ercas.initiate_account_deposit, pkdata)
                if (res.msg.requestSuccessful) {
                    window.location = res.msg.responseBody.checkoutUrl
                } else {
                    setLoading(false)
                    ErrorAlert(`${res.msg.responseMessage}`)
                }
            } else {
                setLoading(false)
                ErrorAlert(`${response.msg}`)
            }
        } catch (error) {
            setLoading(false)
            Errors(`${error.message}`)
        }
    }

    return (
        <UserLayout title="Trade Midas" navlink={`/investment/${plan.id}/midas`} >
            {open.status && <PaymentSelection
                SendInvestment={PurchaseWithBalance}
                PayWithBank={PurchaseWithBank}
                data={open.data}
                closeView={() => setOpen({ ...open, status: false })}
            />}
            {loading && <Loading />}
            <InvestLayout>
                {view && <Confirmation title="Investment Successfully confirmed" texts={text} closeView={() => setView(!view)} />}
                <div className=" dark:text-slate-100 w-11/12 mx-auto">
                    <div className="w-fit ml-auto mb-10">
                        <button onClick={() => setScreen(!screen)} className={`capitalize py-3 px-5 rounded-lg text-sm shadow-xl ${!screen ? 'bg-main text-white' : 'bg-main text-white'}`}>{!screen ? 'Close' : 'investment history'}</button>
                    </div>
                    {screen && <>
                        <div className="w-11/12 mx-auto">
                            <div>
                                <div className="flex items-center relative mt-5">
                                    <SlWallet className='absolute top-2 left-2 text-2xl' />
                                    <input type="text" value={forms.amount} onChange={e => setForms({ ...forms, amount: e.target.value })} className={`input !pl-10 dark:bg-slate-600 dark:placeholder:text-white ${forms.error ? '!border-red-500' : '!border-slate-500'}`} placeholder={`Enter Amount(${NairaSign})`} />
                                    {forms.error ? <div className="text-xs text-red-600 absolute -bottom-6 left-0">{forms.message}</div> : null}
                                </div>
                                <div className="flex items-center justify-between gap-10">
                                    <div className="text-sm">Minimum Deposit</div>
                                    <div className="font-bold text-sub">{NairaSign}{plan.plans?.minDept?.toLocaleString()}</div>
                                </div>
                                <div className="mt-5 mb-2">ROI and Duration</div>
                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                                    {plan.plans?.subplan?.map((item, i) => (
                                        <button onClick={() => handleActivebtn(i + 1, item)} key={i} className={`${activebtn === i + 1 ? 'bg-[#f0e1cc] border-2 border-slate-400' : 'bg-slate-200 border-2 border-slate-400'} py-3 px-4 rounded-md font-medium`}>
                                            <div className="font-bold text-lg text-left">{item.title}</div>
                                            <div className="grid grid-cols-2">
                                                <div className="text-left">Topup </div>
                                                <div className="text-right"> <span className="text-sub">{item.topup}%</span> every {item.topupDuration} {item.topupDuration === 1 ? `${item.topupDurationType.slice(0, -1)}` : `${item.topupDurationType}`}</div>
                                            </div>
                                            <div className="grid grid-cols-2">
                                                <div className="text-left">Duration </div>
                                                <div className="text-right"> {item.duration} {item.duration === 1 ? `${item.durationType.slice(0, -1)}` : `${item.durationType}`}</div>
                                            </div>
                                        </button>
                                    ))}
                                </div>
                                <div className="grid grid-cols-2 py-3 border-b">
                                    <div className="text-sm capitalize">Auto Invest</div>
                                    <div className="w-fit ml-auto">
                                        <div className="relative h-5 w-10 rounded-full bg-slate-200">
                                            <div onClick={() => setRenew(!renew)} className={`absolute -top-[0.2rem] transition-all cursor-pointer w-6 h-6 rounded-full ${renew ? 'bg-sub right-0' : 'bg-slate-400 left-0'}`}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-3/6 mx-auto mt-7">
                                    <Formbutton handleSubmission={handleSubmission} title='Proceed with payment method' font="uppercase text-base py-4" />
                                </div>
                            </div>
                        </div>
                    </>}

                    {!screen && <div className="">
                        <MidasHistory />
                    </div>}
                </div>
            </InvestLayout>
        </UserLayout>
    )
}


