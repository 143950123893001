import { useQuery } from '@tanstack/react-query'
import { Apis, geturl } from 'components/Apis'
import AllSponsors from 'components/general/AllSponsors'
import ContactForm from 'components/general/ContactForm'
import Footer from 'components/general/Footer'
import Header from 'components/general/Header'
import Imaged from 'components/utils/Imaged'
import moment from 'moment'
import { SlClock, SlGlobe } from 'react-icons/sl'

export default function HomeNews() {
    const { data, isLoading } = useQuery({
        queryKey: ['page-news-feeds'],
        queryFn: async () => {
            const result = await geturl(Apis.feeds.featured_news)
            return result.msg
        }
    })
    if (isLoading) return (
        <div>
            <Header />
            <div className="news-card w-full h-[30dvh] md:h-[60dvh]"></div>
            <div className='w-11/12 mx-auto mb-20 -mt-8 md:-mt-20'>
                {/* <Bounce><div className="mb-2 text-3xl font-bold text-center capitalize lg:text-5xl">News Feeds</div></Bounce>
                <Bounce><div className="mb-10 text-xl text-zinc-500 font-light text-center">Get all up to dates live news </div></Bounce> */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                    {new Array(6).fill(0).map((ele, index) => (
                        <div key={index} className="h-fit w-full bg-slate-100 p-5 rounded-lg animate-pulse">
                            <div className="h-[15rem] bg-slate-200 rounded-lg"></div>
                            <div className="mt-5">
                                <div className="h-[2rem] bg-slate-200 rounded-lg mb-3"></div>
                                <div className="h-[2rem] w-3/4 bg-slate-200 rounded-lg mb-3"></div>
                                <div className="h-[2rem] w-3/5 bg-slate-200 rounded-lg mb-3"></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <ContactForm />
            <AllSponsors />
            <Footer />
        </div>
    )

    return (
        <div>
            <Header />
            <div className="news-card w-full h-[30dvh] md:h-[60dvh]"></div>
            <div className='w-11/12 mx-auto mb-20 -mt-8 md:-mt-20'>
                {/* <Bounce><div className="mb-2 text-3xl font-bold text-center capitalize lg:text-5xl">News Feeds</div></Bounce>
                <Bounce><div className="mb-10 text-xl text-zinc-500 font-light text-center">Get all up to dates live news </div></Bounce> */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                    {data?.map((item, index) => (
                        <a href={`${item.titletag === 'feed' ? `/newsfeed/${item.slug}/${item.id}` : item.url}`} rel="noreferrer" target="_blank" className="mb-3" key={index}>
                            <div className="overflow-hidden h-[18rem] w-full rounded-lg">
                                <Imaged src={`${item.titletag === 'feed' ? item.image : item.images[0]?.url}`} className="h-full w-full object-cover rounded-lg hover:brightness-75 hover:scale-110 transition-all" />
                            </div>
                            <div className="mt-5">
                                <div className="font-bold text-xl">{item.title}</div>
                                <div className="flex items-center gap-2 text-sub text-sm"> <SlGlobe /> {item.titletag === 'feed' ? 'Not Featured' : 'Featured News'} - <div className='text-zinc-500 flex items-center gap-2'> <SlClock /> {moment(item.createdAt).format('Do MMMM YYYY')}</div> </div>

                                <div className="flex flex-wrap mt-4">
                                    <div className="font-extralight text-zinc-500 whitespace-break-spaces" dangerouslySetInnerHTML={{ __html: `${item.titletag === 'feed' ? item.content?.slice(0, 70) : item.description}` }} />
                                    <div className='text-blue-500'>Read more...</div>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
            <ContactForm />
            <AllSponsors />
            <Footer />
        </div>
    )
}
