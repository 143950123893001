import React, { useState } from 'react'
import SideViewModalLayout from 'components/utils/SideViewModalLayout'
import { Apis, Authposturl, imgurl } from 'components/Apis'
import { Link } from 'react-router-dom'
import { ErrorAlert, Errors, NairaSign, SuccessAlert } from 'components/utils/functions'
import Formbutton from 'components/utils/Formbutton'
import LoadImage from 'components/utils/LoadImage'

const ShortletModal = ({ closeView, item, refetch }) => {
    const [screen, setScreen] = useState(1)
    const [loading, setLoading] = useState(false)
    const finals = `${item.media[0]?.image}`
    const [activeimg, setActiveImage] = useState(finals)


    const handleClicking = (data) => {
        setActiveImage(data)
    }


    const handleSubmission = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const response = await Authposturl(Apis.feeds.delete_shortlet, { id: item.id })
            if (response.status === 200) {
                SuccessAlert(response.msg)
                refetch()
                return closeView()
            } else {
                ErrorAlert(response.msg)
            }
        } catch (e) {
            Errors(`${e}`)
        } finally {
            setLoading(false)
        }
    }
    return (
        <SideViewModalLayout closeView={closeView}>
            {screen === 1 && <div className="p-4 mt-10">
                <div className="mt-5">
                    <div className="grid grid-cols-2 gap-5 mb-6">
                        <Link to={`/admin/shortlets/edit/${item.id}`} className="bg-main capitalize py-2 px-5 text-sm text-white">update</Link>
                        <button
                            onClick={() => setScreen(2)}
                            className="bg-red-600 capitalize py-2 px-5 text-sm text-white">delete</button>
                    </div>
                </div>
                <div className="mb-5">
                    <div className="">
                        <LoadImage src={`${activeimg}`} width={'100%'} height={'3rem'} className="w-full h-44 object-cover" />
                    </div>
                    <div className="w-full overflow-x-auto scrollsdown mt-3">
                        <div className="w-fit flex items-center gap-3">
                            {item.media?.length > 0 && item.media?.map((ele, index) => (
                                <div className="w-20 h-12" key={index}>
                                    <LoadImage src={ele.image} width={'100%'} height={'7rem'} className="w-20 h-12 object-cover" handleActive={handleClicking} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="mb-3 border-b pb-3">
                    <div className="font-bold text-lg">Title</div>
                    <div className="">{item.title}</div>
                </div>
                <div className="mb-3 border-b pb-3">
                    <div className="font-bold text-lg">Price</div>
                    <div className="">{item.price}</div>
                </div>
                <div className="mb-3 border-b pb-3">
                    <div className="font-bold text-lg">Price Tag</div>
                    <div className="">{item.pricetag}</div>
                </div>
                <div className="mb-3 border-b pb-3">
                    <div className="font-bold text-lg">Category</div>
                    <div className="">{item.cats?.title}</div>
                </div>
                <div className="mb-3 border-b pb-3">
                    <div className="font-bold text-lg">Country</div>
                    <div className="">{item.country}, {item.state}</div>
                </div>
                <div className="mb-3 border-b pb-3">
                    <div className="font-bold text-lg">Description</div>
                    <div className="whitespace-pre-wrap font-light" dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
                <div className="font-bold text-lg">Shortlet Levels</div>
                <div className="">
                    {item.levels?.map((ele, index) => (
                        <div key={index} className="border-b p-2">
                            <div className="font-bold">{index + 1}. {ele.title}({NairaSign}{ele.price})</div>
                            <div className="">{ele.content}</div>
                        </div>
                    ))}
                </div>
                <div className="font-bold text-lg">Shortlet Addons</div>
                <div className="">
                    {item.addons?.map((ele, index) => (
                        <div key={index} className="border-b p-2">
                            <div className="font-light">{index + 1}. {ele.content}</div>
                        </div>
                    ))}
                </div>
            </div>}
            {screen === 2 && <div className="p-4 mt-10">
                <div className="mb-5">Delete <span className="text-red-500">{item.title}</span> </div>
                <div className="text-center mb-10">Are you sure you want to delete this shortlet</div>
                <Formbutton
                    title="Delete"
                    loading={loading}
                    handleSubmission={handleSubmission}
                />
            </div>}
        </SideViewModalLayout>
    )
}

export default ShortletModal
