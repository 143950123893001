import React, { useState } from 'react'
import SellOffStockButton from './SellOffStockButton'
import { ClientInvestmentStatus, DateDiff, Errors, NairaSign, SuccessAlert } from 'components/utils/functions'
import { BsCheck } from 'react-icons/bs'
import moment from 'moment'
import { SlClock } from 'react-icons/sl'
import { useSelector } from 'react-redux'
import { Apis, Authposturl } from 'components/Apis'
import { GoArrowRight } from 'react-icons/go'

const SinglePurchase = ({ item, handleResult, handleSingle }) => {
    const { plan } = useSelector(state => state.data)
    const [renew, setRenew] = useState(item.autoRenew ? true : false)


    const handleAutoRenew = async (tag) => {
        try {
            const data = {
                invest: plan.id,
                tag: !tag ? 'no' : 'yes'
            }
            const res = await Authposturl(Apis.investments.autorenew, data)
            if (res.status === 200) {
                SuccessAlert(res.msg)
            }
        } catch (error) {
            Errors(`${error}`)
        }
    }

    const InvestStatus = () => {
        if (item.status === ClientInvestmentStatus.false) return (
            <div className="grid grid-cols-6">
                <div className="text-xs flex col-span-4 items-center gap-2 capitalize bg-slate-100 dark:bg-slate-800 w-fit p-2 rounded-md mb-3"> <SlClock />ended: {item.timed}</div>
                <div className='col-span-2'>
                    <div className="text-red-600 dark:text-red-300 flex mt-3 items-center gap-2 text-sm justify-end capitalize">sold <BsCheck className='text-xl' /> </div>
                </div>
            </div>
        )
        if (item.status === ClientInvestmentStatus.active) return (
            <div className="grid grid-cols-7">
                <div className="text-xs flex col-span-4 items-center gap-2 capitalize bg-slate-100 dark:bg-slate-800 w-fit p-2 rounded-md mb-3"> <SlClock />ends: {item.timed}</div>
                <div className='col-span-3'>
                    <div className="text-green-500 dark:text-red-300 flex items-center gap-2 text-sm justify-end capitalize"> Stock trade in progress</div>
                </div>
            </div>
        )
        if (item.status === ClientInvestmentStatus.sell_in) return (
            <div className="grid grid-cols-6">
                <div className="text-xs flex col-span-4 items-center gap-2 capitalize bg-slate-100 dark:bg-slate-800 w-fit p-2 rounded-md mb-3"> <SlClock />ended: {item.timed}</div>
                <div className="w-fit ml-auto col-span-2">
                    <SellOffStockButton
                        handleResult={() => handleResult(item)}
                    />
                </div>
            </div>
        )
        if (item.status === ClientInvestmentStatus.sell_out) return (
            <div className="grid grid-cols-7">
                <div className="text-xs flex col-span-4 items-center gap-2 capitalize bg-slate-100 dark:bg-slate-800 w-fit p-2 rounded-md mb-3"> <SlClock />ends: {item.selltimed}</div>
                <div className='col-span-3'>
                    <div className="text-green-500 dark:text-red-300 flex items-center gap-2 text-sm justify-end capitalize">Opened sell order in progress</div>
                </div>
            </div>
        )
    }

    return (
        <div className="border rounded-lg p-2 bg-white dark:bg-slate-600 mb-3 shadow-lg hover:scale-105">
            <div className="flex items-center gap-10">
                <div className="w-full">
                    {InvestStatus()}
                </div>
                <div className="">
                    <button onClick={() => handleSingle(item)}>
                        <div className="flex items-center gap-2 justify-end col-span-2 text-blue-600">View <GoArrowRight /> </div>
                    </button>
                </div>
            </div>
            <div className={`grid ${plan.plans?.title === 'istock' ? 'grid-cols-3' : 'grid-cols-2'} mb-2 gap-5`}>
                {plan.plans?.title === 'istock' && <div className="">
                    <div className="text-base text-center font-bold">{item.quantity}</div>
                    <div className="text-xs capitalize text-center">stock purchase quantity</div>
                </div>}
                <div className="">
                    <div className="text-base text-center font-bold">{NairaSign}{item.amount.toLocaleString()}</div>
                    <div className="text-xs capitalize text-center">amount spent</div>
                </div>
                <div className="">
                    <div className="text-base text-center font-bold">{NairaSign}{item.returnProfit.toLocaleString()}</div>
                    <div className="text-xs capitalize text-center">expected earnings</div>
                </div>
            </div>
            <div className="w-11/12">
                <div className="flex items-center justify-end capitalize gap-2 mb-1">
                    <div className="text-xs">Total selling funds</div>
                    <div className="text-xs font-bold">{NairaSign}{item.amount?.toLocaleString()}</div>
                </div>
                <div className="flex items-center justify-end capitalize gap-2">
                    <div className="text-xs">purchased time</div>
                    <div className="text-xs font-bold">{moment(item.createdAt).format('DD-MM-YYYY hh:mma')}</div>
                </div>
            </div>
        </div>
    )
}

export default SinglePurchase
