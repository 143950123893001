import React, { useEffect, useState } from 'react'
import PlanForm from './PlanForm'
import { useParams } from 'react-router-dom'
import { Apis, Authgeturl } from 'components/Apis'
import { Errors } from 'components/utils/functions'
import ALayout from 'components/admin/ALayout'

const EditPlan = () => {
  const { id } = useParams()
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const Fetchsingle = async () => {
      setLoading(true)
      try {
        const res = await Authgeturl(`${Apis.investments.single_plan}/${id}`)
        if (res.status === 200) {
          setData(res.msg)
          setLoading(false)
        }
      } catch (error) {
        Errors(error)
      } finally {
        setLoading(false)
      }
    }
    Fetchsingle()
  }, [])
  return (
    <ALayout>
    {loading && <div className="text-sm">Loading...</div> }
      {!loading && <PlanForm plan={data} />}
    </ALayout>
  )
}

export default EditPlan