
import React, { useState } from 'react'


const FilterAvailable = ({shortlets, Signal}) => {
    const [active, setActive] = useState('all')


    const handleActive = val => {
        if(val === 'all') return Signal(shortlets)
        const output = []
        shortlets.map(ele => {
            if(ele.aval === val) {
                output.push(ele)
            }
        })
        Signal(output)
        setActive(val)
    }
    return (
        <>
            <div className='grid grid-cols-2 mt-5 mb-6'>
                <div onClick={() => handleActive('all')} className="flex items-center gap-3 cursor-pointer">
                    <div className={`w-4 h-4 ${active === 'all' ? 'bg-mainblue' : 'bg-slate-300'} rounded-lg`}></div>
                    <div className="capitalize">All</div>
                </div>
                <div onClick={() => handleActive('yes')} className="flex items-center gap-3 cursor-pointer">
                    <div className={`w-4 h-4 ${active === 'yes' ? 'bg-mainblue' : 'bg-slate-300'} rounded-lg`}></div>
                    <div className="capitalize">available</div>
                </div>
            </div>
            <div onClick={() => handleActive('no')} className="flex items-center gap-3 cursor-pointer">
                <div className={`w-4 h-4 ${active === 'no' ? 'bg-mainblue' : 'bg-slate-300'} rounded-lg`}></div>
                <div className="capitalize">not-available</div>
            </div>
        </>
    )
}

export default FilterAvailable
