import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import ALayout from 'components/admin/ALayout'
import AllChats from './AllChats'
import { ErrorAlert, Errors, SuccessAlert } from 'components/utils/functions'
import { Apis, Authgeturl, Authposturl, SocketConnect } from 'components/Apis'
import AllChatUsers from './AllChatUsers'
import { BiPencil, BiSolidUser } from 'react-icons/bi'
import moment from 'moment'
import { useQuery } from '@tanstack/react-query'
import { PiPen } from 'react-icons/pi'
import { useSelector } from 'react-redux'
import Forminput from 'components/utils/Forminput'
import Formbutton from 'components/utils/Formbutton'
import { FaTimes } from 'react-icons/fa'

export default function ChatSupportAdmin() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [staffs, setStaffs] = useState([])
    const [views, setViews] = useState(false)
    const { user } = useSelector(state => state.data)
    const ViewsIcon = !views ? BiPencil : FaTimes
    const [forms, setForms] = useState({
        aval: user.aval || 'yes',
        info: user.info ||''
    })
    const handleForms = e => {
        setForms({
            ...forms,
            [e.target.name]: e.target.value
        })
    }

    const SubmitAvailability = async (e) => {
        e.preventDefault()
        if(!forms.aval) return ErrorAlert('Select your availability status')
        if(forms.aval === 'no' && !forms.info) return ErrorAlert('Provide info on when you will be available')
        setLoading(true)
        try {
            const response = await Authposturl(Apis.user.update_chat_aval, forms)
            if(response.status === 200) {
                SuccessAlert(response.msg)
                refetch()
                SocketConnect.emit('admin-activity-staff')
                setViews(false)
            }else {
                ErrorAlert(response.msg)
            }
        } catch (error) {
            ErrorAlert(`${error.message}`)
        }finally {
            setLoading(false)
        }
    }

    const { isLoading, refetch } = useQuery({
        queryKey: ['admin-staffs-rooms'],
        queryFn: async () => {
            const response = await Authgeturl(Apis.feeds.all_rooms)
            const res = await Authgeturl(Apis.user.all_admin_staffs)
            setStaffs(res.msg)
            setData(response.msg)
            return res.msg
        }
    })
    useEffect(() => {
        SocketConnect.on('admin-activity-staff-self', () => refetch())
    }, [SocketConnect])

    const ToggleAvailability = item => {
        setViews(prev => !prev)
    }

    if (isLoading) return (
        <ALayout>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                <div className="">
                    <div className="bg-white p-3 rounded mb-5">
                        <div className="text-center">Track All Active Chats from a spot</div>
                        <div className="bg-slate-200 h-20 rounded-lg"></div>
                    </div>
                    <div className="bg-white p-3 rounded mb-5">
                        <div className="font-bold text-xl border-b pb-3">All Staffs Activity Status</div>
                        <div className="">
                            {new Array(5).fill(0).map((item, index) => (
                                <div className="bg-slate-200 h-16 mt-3 rounded-lg" key={index}></div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="bg-white p-3 rounded">
                    <div className="font-bold text-xl border-b pb-3 mb-3">All Chat Summaries</div>
                    {new Array(5).fill(0).map((item, index) => (
                        <div className="bg-slate-200 h-16 mt-3 rounded-lg" key={index}></div>
                    ))}
                </div>
            </div>
            {/* <AllChats /> */}
        </ALayout>
    )
    return (
        <ALayout>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                <div className="">
                    <div className="bg-white p-3 rounded mb-5">
                        <div className="text-center">Track All Active Chats from a spot</div>
                        <AllChats />
                    </div>
                    <div className="bg-white p-3 rounded mb-5">
                        <div className="font-bold text-xl border-b pb-3">All Staffs Activity Status</div>
                        <div className="">
                            {staffs.length > 0 ? staffs.map((item, index) => (
                                <div key={index}>
                                    <div
                                        className={`p-2 grid grid-cols-9 mb-2 hover:bg-slate-100/70 transition-all border-l-4 ${item.aval === 'yes' ? 'border-green-500' : 'border-slate-500'}`}>
                                        <div className="flex gap-2 col-span-5">
                                            <div className="text-2xl rounded-full p-2.5 bg-slate-200 text-slate-500 w-fit h-fit"> <BiSolidUser /> </div>
                                            <div className="text-sm">
                                                <div className="font-bold">{item.firstname} {item.lastname}</div>
                                                <div className="text-sm text-zinc-500">{item.staff?.name}</div>
                                               {item.aval === 'no' && <div className="text-sm text-sub">{item.info}</div>}
                                            </div>
                                        </div>
                                        <div className="col-span-3 flex flex-col gap-2">
                                            <div className="text-right text-xs"> {item.lastlogin}</div>
                                            <div className={`text-right capitalize text-sm ${item.aval === 'yes' ? 'text-green-500' : 'text-slate-500'}`}> {item.aval === 'yes' ? 'Available' : 'Not Available'} </div>
                                        </div>
                                        {item.id === user.tagged && <div className='flex items-center justify-center h-full'>
                                            <button
                                                onClick={() => ToggleAvailability(item)}
                                                className={`${views ? 'bg-sub' : 'bg-main'} text-white p-1.5 rounded-lg`}> <ViewsIcon /> </button>
                                        </div>}
                                    </div>
                                    {views && <div className="w-11/12 mx-auto mt-3">
                                        <form onSubmit={SubmitAvailability}>
                                            <Forminput formtype='select' formName={'aval'} value={forms.aval} onChange={handleForms}>
                                                <option value="yes">Available</option>
                                                <option value="no">Not Available</option>
                                            </Forminput>
                                           {forms.aval === 'no' && <>
                                           <div className="">Provide when you will be available</div>
                                           <Forminput formName={'info'} value={forms.info} onChange={handleForms} />
                                           </>}
                                            <div className="">
                                                <Formbutton title="save" loading={loading} />
                                            </div>
                                        </form>
                                    </div>}
                                </div>
                            )) : <div className="text-center mt-10">No Staff has been assigned to a department yet!...</div>}
                        </div>
                    </div>
                </div>
                <div className="bg-white p-3 rounded">
                    <div className="font-bold text-xl border-b pb-3 mb-3">All Chat Summaries</div>
                    <AllChatUsers
                        OpenRoom={() => null}
                        users={data} />
                </div>
            </div>
            {/* <AllChats /> */}
        </ALayout>
    )
}
