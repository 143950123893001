
import React, { useState } from 'react'
import Formbutton from 'components/utils/Formbutton'
import { ErrorAlert, Errors, NairaSign, SuccessAlert } from 'components/utils/functions'
import { Apis, Authposturl } from 'components/Apis'
import Forminput from 'components/utils/Forminput'
import ModalLayout from 'components/utils/ModalLayout'

export default function AirtimeCashbackModal({ closeView, refetch, item }) {
    const [loading, setLoading] = useState(false)
    const [view, setView] = useState(1)
    const [data, setData] = useState({
        cashback: item?.cashback || '',
        pay: item?.pay || '',
        price: item?.price || '',
    })
    const handleForms = e => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const SubmitForm = async (e) => {
        e.preventDefault()
        if (!data.cashback) return ErrorAlert('Cashback is required')
        if (!data.price) return ErrorAlert('Price is required')
        if (!data.pay) return ErrorAlert('Amount to pay is required')
        const forms = {
            ...data,
            id: item?.id || '',
            tag: item?.id ? 'update' : 'create'
        }

        setLoading(true)
        try {
            const response = await Authposturl(Apis.serviceapi.manage_airtime_cashback, forms)
            if (response.status === 200) {
                SuccessAlert(response.msg)
                return refetch()
            } else {
                ErrorAlert(`${response.msg}`)
            }
        } catch (e) {
            Errors(e)
        } finally {
            setLoading(false)
        }
    }
    const SubmitDeleteRequest = async (e) => {
        e.preventDefault()
        const forms = {
            id: item?.id,
            tag: "delete"
        }
        setLoading(true)
        try {
            const response = await Authposturl(Apis.serviceapi.manage_airtime_cashback, forms)
            if (response.status === 200) {
                SuccessAlert(response.msg)
                return refetch()
            } else {
                ErrorAlert(`${response.msg}`)
            }
        } catch (e) {
            Errors(e)
        } finally {
            setLoading(false)
        }
    }
    return (
        <ModalLayout closeView={closeView}>
            {view === 1 && <div className="p-4 mt-10">
                <div className="font-bold text-xl mb-5">{item?.id ? 'Update Cashback' : 'Add New Cashback'}
                    {item?.id && <button
                        onClick={() => setView(2)}
                        className="bg-red-500 ml-3 text-white py-2 px-5 rounded-md text-sm capitalize">delete</button>}
                </div>
                <form>
                    <div className="mb-4">
                        <div className="text-sm">Cashback ({NairaSign})</div>
                        <Forminput label={'Cashback'} formName={'cashback'} value={data.cashback} onChange={handleForms} />
                    </div>
                    <div className="mb-4">
                        <div className="text-sm">Actual Amount</div>
                        <Forminput label={'Price'} formName={'price'} value={data.price} onChange={handleForms} />
                    </div>
                    <div className="mb-4">
                        <div className="text-sm">Amount to Pay</div>
                        <Forminput label={'Amount to pay'} formName={'pay'} value={data.pay} onChange={handleForms} />
                    </div>
                    <Formbutton
                        handleSubmission={SubmitForm}
                        loading={loading}
                        title="Submit"
                    />
                </form>
            </div>}
            {view === 2 && <div className="p-4 mt-10">
                <div className="font-bold text-xl mb-5">Delete <span className="text-red-400">{item.title}</span> </div>
                <div className="text-center mb-10">Are you sure you want to delete this cashback?</div>
                <form>
                    <Formbutton
                        handleSubmission={SubmitDeleteRequest}
                        loading={loading}
                        title="proceed"
                    />
                </form>
            </div>}
        </ModalLayout>
    )
}

