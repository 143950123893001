import React from 'react'
import Footer from 'components/general/Footer'
import Header from 'components/general/Header'
import { Helmet } from 'react-helmet-async'

export default function Terms() {
    return (
        <>
        <Helmet prioritizeSeoTags>
                <title>Quick Direct | Terms and Conditions</title>
                <link rel="notImportant" href="https://www.quickdirect.com.ng/terms-and-conditions" />
                <meta name="whatever" value="notImportant" />
                <link rel="canonical" href="https://www.quickdirect.com.ng/terms-and-conditions" />
                <meta property="og:title" content="Quick Direct | Terms and Conditions" />
                <meta property="og:url" content="https://www.quickdirect.com.ng/terms-and-conditions" />
                <meta property="og:image" content="https://www.quickdirect.com.ng/main.png" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="Our mission is to build long-term relationships with our clients, helping them navigate the complex financial landscape and achieve success." />
            </Helmet>
        <div className='bg-slate-50'>
            <Header />
            <div className="w-11/12 mx-auto mb-20 mt-10 lg:w-10/12 bg-white p-5 lg:p-10">
                <div className="font-bold text-4xl mb-5">TERMS AND CONDITIONS OF Quick Direct</div>

                <div className='font-bold text-2xl mt-5'>1. DEFINITIONS</div>

                <p className='ml-7'>- "Quick Direct" refers to Quick Direct Ltd., its subsidiaries, and affiliates.</p>
                <p className='ml-7'>- "Services" include investment management, advisory, and related financial services.</p>
                <p className='ml-7'>- "Client" or "You" refers to the individual or entity receiving Services.</p>
                <p className='ml-7'>- "Agreement" means these Terms and Conditions.</p>

                <div className='font-bold text-2xl mt-5'>2. SCOPE OF SERVICES</div>

                <p className='ml-7'>- Quick Direct provides investment management and advisory Services to Clients.</p>
                <p className='ml-7'>- Services are tailored to each Client's needs and goals.</p>

                <div className='font-bold text-2xl mt-5'>3. CLIENT OBLIGATIONS</div>

                <p className='ml-7'>- Provide accurate and complete information.</p>
                <p className='ml-7'>- Ensure sufficient funds for investments.</p>
                <p className='ml-7'>- Notify Quick Direct of changes in personal or financial circumstances.</p>

                <div className='font-bold text-2xl mt-5'>4. RISK DISCLAIMER</div>

                <p className='ml-7'>- Investing carries inherent risks, including market volatility and potential losses.</p>
                <p className='ml-7'>- Quick Direct does not guarantee investment returns or performance.</p>

                <div className='font-bold text-2xl mt-5'>5. FEES AND COMPENSATION</div>

                <p className='ml-7'>- Fees are as agreed upon in the Client Agreement or as specified in our Fee Schedule.</p>
                <p className='ml-7'>- Quick Direct may receive commissions or rebates from third-party providers.</p>

                <div className='font-bold text-2xl mt-5'>6. CONFIDENTIALITY AND PRIVACY</div>

                <p className='ml-7'>- Quick Direct maintains confidentiality and privacy of Client information.</p>
                <p className='ml-7'>- Exceptions include legal requirements, regulatory requests, or with Client consent.</p>

                <div className='font-bold text-2xl mt-5'>7. INTELLECTUAL PROPERTY</div>

                <p className='ml-7'>- Quick Direct retains ownership of all intellectual property, including investment strategies and materials.</p>

                <div className='font-bold text-2xl mt-5'>8. TERMINATION</div>

                <p className='ml-7'>- Either party may terminate the Agreement with written notice.</p>
                <p className='ml-7'>- Termination does not affect existing obligations or liabilities.</p>

                <div className='font-bold text-2xl mt-5'>9. GOVERNING LAW AND JURISDICTION</div>

                <p className='ml-7'>- This Agreement is governed by [Quick Direct ] laws.</p>
                <p className='ml-7'>- Disputes will be resolved through [law stand of Nigeria LSN].</p>

                <div className='font-bold text-2xl mt-5'>10. ENTIRE AGREEMENT</div>

                <p className='ml-7'>- This Agreement constitutes the entire understanding between parties.</p>
                <p className='ml-7'>- Amendments must be in writing and signed by both parties.</p>

                <div className='font-bold text-2xl mt-5'>11. EFFECTIVE DATE</div>

                <p className='ml-7'>- This Agreement takes effect on [24 March 2022] and remains in force until terminated.</p>

                <p> By using Quick Direct's Services, Clients acknowledge acceptance of these Terms and Conditions.</p>
            </div>
            <Footer />
        </div>
        </>
    )
}
