
import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment';
import AHeader from 'components/admin/AHeader';
import Spinner from 'components/utils/Spinner';
import { Errors } from 'components/utils/functions';
import { Apis, Authgeturl } from 'components/Apis';
import ShareclassModal from './ShareclassModal';
import ALayout from 'components/admin/ALayout';

const Tableheaders = [
  "title",
  "min. buy",
  "max. buy",
  "min. sell",
  "max. sell",
  "Sell Duration",
  "date created",
]

export default function ManageShareclass() {
  const [plans, setPlans] = useState([])
  const [loading, setLoading] = useState(true)
  const [single, setSingle] = useState({})
  const [view, setView] = useState(false)

  const FetchShareClasses = useCallback(async () => {
    setLoading(true)
      try {
          const res = await Authgeturl(Apis.shares.all_share_class)
          if (res.status === 200) return setPlans(res.msg)
      } catch (error) {
          Errors(`${error}`)
      } finally {
          setLoading(false)
      }
  }, [])
  useEffect(() => { FetchShareClasses() }, [FetchShareClasses])

  const handleSingle = val => {
      setSingle(val)
      setView(!view)
  }
  return (
    <ALayout>
        {view &&  <ShareclassModal 
        closeView={() => setView(!view)}
        refetch={() => FetchShareClasses()}
        pageData={single}
        /> }
        <div className="">
            <div className="mb-5 text-xl font-bold">Shares Class</div>
            <AHeader
                tagtitle="new Share classes"
                total={plans.length}
                tag="button"
                url={false}
                handleClick={() => {setView(!view); setSingle({})}}
            />
            <div className="mt-10">
                {!loading && <table className='table w-full p-3 text-sm bg-white table-auto'>
                    <thead>
                        <tr className='text-white bg-slate-700'>
                            {Tableheaders.map((ele, i) => (
                                <td className='p-3 capitalize' key={i}>{ele}</td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {plans.length > 0 && plans.map((item, i) => (
                            <tr 
                            key={i}
                            className='border-b border-l border-r cursor-pointer'
                            onClick={() => handleSingle(item)}
                            >
                                <td className='p-3 uppercase'>{item.title}</td>
                                <td className='p-3 uppercase'>{item.minbuy}</td>
                                <td className='p-3 uppercase'>{item.maxbuy}</td>
                                <td className='p-3 uppercase'>{item.minsell}</td>
                                <td className='p-3 uppercase'>{item.maxsell}</td>
                                <td className='p-3 uppercase'>{item.sellDuration} {parseInt(item.sellDuration) === 1 ? item.sellDurationType?.slice(0, -1) : item.sellDurationType}</td>
                                <td className='p-3'>{moment(item.createdAt).format('DD-MM-YYYY hh:ss A')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>}
                {loading && <Spinner /> }
            </div>
        </div>
    </ALayout>
  )
}


