

import React, { useState } from 'react'
import ModalLayout from 'components/utils/ModalLayout'
import Formbutton from 'components/utils/Formbutton'
import { ErrorAlert, NairaSign, SuccessAlert } from 'components/utils/functions'
import { FaWallet } from 'react-icons/fa'
import Forminput from 'components/utils/Forminput'
import Loading from 'components/utils/Loading'
import { CableImages, ServiceImages } from 'components/utils/PageUtils'
import { AiOutlineFieldNumber } from 'react-icons/ai'
import { RiAccountPinCircleLine } from 'react-icons/ri'
import { BsBank, BsCopy } from 'react-icons/bs'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FaArrowRight } from 'react-icons/fa6'

export default function ServiceConfirmation({ closeView, titletag, single, setSingle, mainbg, PurchaseService, user, loading, setLoading, productTag }) {
    const [active,] = useState(false)
    const [views, setViews] = useState(false)
    const [code, setCode] = useState('')
    const [btn, setBtn] = useState(true)
    const [screen, setScreen] = useState(1)
    const [paymethod, setPaymethod] = useState('')
    const payOptions = [
        'balance',
        'bank',
    ]
    let imageData;
    if(productTag === 'Cable') {
        imageData = CableImages.find(ele => single.network.toLowerCase() === ele.cable_name.toLowerCase())
    } else {
        imageData = ServiceImages.find(ele =>ele.network.toLowerCase() === single.network.toLowerCase())
    }
    
    const handlePayToggle = val => {
        if (paymethod !== val) {
            setBtn(false)
            setPaymethod(val)
            setSingle({
                ...single,
                paymethod: val
            })
        } else {
            setBtn(true)
            setPaymethod('')
            setSingle({
                ...single,
                paymethod: ''
            })
        }
    }

    const validateRequest = () => {
        if (!paymethod) return ErrorAlert('Select a payment method')
        setScreen(2)

    }
    const handleCode = val => {
        setCode(val)
        setSingle({
            ...single,
            code: val
        })
    }

    const confirmRequest = async () => {
        if (!code) return ErrorAlert('Provide your transaction pin')
        const dataToStore = {
            useCashback: `${active}`,
            amount: parseInt(single.amount),
            amountToPay: parseInt(single.amountToPay),
            cashback: parseInt(single.cashback),
            network_id: single.Serviceid,
            plan_name: single.plan_name,
            data_plan: single.Plan ?? single.network,
            phone_number: single.Number,
            pin: single.code,
        }
        return PurchaseService(dataToStore)
    }
    
    return (
        <ModalLayout closeView={closeView}>
            {loading && <Loading />}
            <div className="w-10/12 pt-10 mx-auto">
                {imageData?.image && <div className="w-fit mx-auto">
                    <img src={`${imageData?.image ?? ''}`} alt="" className="size-20" />
                </div>}
                <div className="font-bold text-center"> <span className="text-xl">{NairaSign}</span> <span className="text-3xl">{parseInt(single.amountToPay)?.toLocaleString()}</span> </div>
                {active && <div className="text-center text-slate-500"> <del>{NairaSign}{parseInt(single.amount)?.toLocaleString()}</del> </div>}
                <div className="grid grid-cols-2 gap-4 py-2 mt-5">
                    <div className="text-sm">Product</div>
                    <div className="text-sm text-right">{single.product?.replace(' ', '')}</div>
                </div>
                <div className="grid grid-cols-2 gap-4 py-2">
                    <div className="text-sm">Amount to pay</div>
                    <div className="text-sm text-right">{NairaSign}{parseInt(single.amountToPay)?.toLocaleString()}</div>
                </div>
                <div className="grid grid-cols-2 gap-4 py-2">
                    <div className="text-sm">to Purchase</div>
                    <div className="text-sm text-right uppercase">{productTag} [{single?.plan_name}]</div>
                </div>
                <div className="grid grid-cols-2 gap-4 py-2">
                    <div className="text-sm capitalize">network</div>
                    <div className="text-sm text-right">{single.network}</div>
                </div>
                <div className="grid grid-cols-2 gap-4 py-2">
                    <div className="text-sm">Bonus to earn</div>
                    <div className="text-sm font-medium text-right text-green-500">+{parseInt(single.cashback)?.toLocaleString()}MB</div>
                </div>
                {screen === 1 && <div className="pt-3 mt-3 border-t">
                    <div className="mb-5 text-lg font-bold">Payment Method</div>
                    <div className={`grid grid-cols-4 p-3 ${paymethod === payOptions[0] ? 'bg-slate-200' : ''} rounded-lg`}>
                        <div className="flex col-span-3 gap-2 cursor-pointer" onClick={() => { (active ? parseInt(single.amountToPay) : parseInt(single.amount)) > user.account?.balance ? setViews(prev => !prev) : handlePayToggle(payOptions[0]) }}>
                            <div className="p-2 rounded-full bg-slate-200 h-fit w-fit"> <FaWallet className='text-xl text-sub' /> </div>
                            <div className="">
                                <div className="text-base font-bold">Balance ({NairaSign}{user.account?.balance?.toLocaleString() ?? 0})</div>
                                {(active ? parseInt(single.amountToPay) : parseInt(single.amount)) > user.account?.balance ? <div className="text-sm text-red-600">Insufficient Balance</div> :
                                    <div className="text-sm text-green-500">Good to go</div>}
                            </div>
                        </div>
                        <div className="flex items-center self-start justify-end gap-1">
                            {(active ? parseInt(single.amountToPay) : parseInt(single.amount)) > user.account?.balance && <button onClick={() => setViews(prev => !prev)} className={views ? 'text-red-600' : 'text-green-500'}>{views ? 'Close' : 'Top-up'}</button>}
                            <FaArrowRight className='text-sm text-slate-500 mt-2' />
                        </div>
                    </div>

                    {views && <div className="bg-slate-50 p-3 rounded-lg text-sm">
                        <div className="flex items-center gap-3 py-3 border-b-2 border-dotted border-slate-300">
                            <div className='text-xl text-sub bg-sub/10 p-3 rounded-lg'><AiOutlineFieldNumber /></div>
                            <div className="w-full">
                                <div className="text-slate-500">Quickdirect Account Number</div>
                                {user.account?.status ? <div className="flex items-center justify-between gap-5">
                                    <div className="font-bold text-base">{user.account?.account_number}</div>
                                    <CopyToClipboard
                                        text={user.account?.account_number}
                                        onCopy={() => SuccessAlert("Account Number copied")}>
                                        <BsCopy className="cursor-pointer" />
                                    </CopyToClipboard>
                                </div> : <div className="">*************</div>}
                            </div>
                        </div>
                        <div className="flex items-center gap-3 py-3 border-b-2 border-dotted border-slate-300">
                            <div className='text-xl text-sub bg-sub/10 p-3 rounded-lg'><RiAccountPinCircleLine /></div>
                            <div className="w-full">
                                <div className="text-slate-500">Bank</div>
                                {user.account?.status ? <div className="flex items-center justify-between gap-5">
                                    <div className="font-bold text-base">{user.account?.bank_name}</div>
                                    <CopyToClipboard
                                        text={user.account?.bank_name}
                                        onCopy={() => SuccessAlert("Bank copied")}>
                                        <BsCopy className="cursor-pointer" />
                                    </CopyToClipboard>
                                </div> : <div className="">**********</div>}
                            </div>
                        </div>
                        <div className="flex items-center gap-3 py-3 border-b-2 border-dotted border-slate-300">
                            <div className='text-xl text-sub bg-sub/10 p-3 rounded-lg'><BsBank /></div>
                            <div className="w-full">
                                <div className="text-slate-500">Account Name</div>
                                {user.account?.status ? <div className="flex items-center justify-between gap-5">
                                    <div className="font-bold text-base">{user.account?.account_name}</div>
                                    <CopyToClipboard
                                        text={user.account?.account_name}
                                        onCopy={() => SuccessAlert("Account Name copied")}>
                                        <BsCopy className="cursor-pointer" />
                                    </CopyToClipboard>
                                </div> : <div className="">**********</div>}
                            </div>
                        </div>
                    </div>}
                </div>}
                {screen === 2 && <div className='pt-3 mt-3 border-t'>
                    <div className="">Transaction Pin <span className="text-2xl text-red-600">*</span> </div>
                    <Forminput type="password" value={code} onChange={e => handleCode(e.target.value)} min="0" max="6" label={'****'} />
                </div>}
                <div className="pt-3 pb-5">
                    {screen === 1 && <Formbutton handleSubmission={validateRequest} disable={btn} title='proceed' bg={mainbg} loading={loading} />}
                </div>
                {screen === 2 && <div className="grid grid-cols-2 gap-20 pt-3 pb-5">
                    <button onClick={() => setScreen(1)} className="px-4 py-3 capitalize rounded-lg bg-slate-200 dark:text-slate-700">Back</button>
                    <Formbutton handleSubmission={confirmRequest} disable={btn} title='proceed' bg={mainbg} loading={loading} />
                </div>}
            </div>
        </ModalLayout>
    )
}
