import React from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Backbutton = ({link}) => {
    return (
        <Link to={link} className="flex ml-5 items-center gap-2 capitalize font-medium">
            <FaArrowLeft />
            back
        </Link>
    )
}

export default Backbutton